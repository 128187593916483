
import RestClient from "../../../libs/api/RestClient";
import { Methods } from '../../../libs/api/Methods';
import { BIGDATA_CONSTANT } from '../../../common/constant';

class DAL {


    constructor() {

    }

    getUISettingAccountContext() {
        let url = BIGDATA_CONSTANT.url.api + 'accounts/users/current/org/config/gui/get';
        var restClient = new RestClient(Methods.GET, url);
        return new Promise((resolve, reject) => {
            restClient.execute({}, {}).then((response: any) => {
                resolve(response.data)
            }, (error: any) => {
                reject(error);
            });
        });
    }

    getUIDataSettingAccountContext() {
        let url = BIGDATA_CONSTANT.url.api + 'accounts/users/current/org/config/data/get';
        var restClient = new RestClient(Methods.GET, url);
        return new Promise((resolve, reject) => {
            restClient.execute({}, {}).then((response: any) => {
                resolve(response.data)
            }, (error: any) => {
                reject(error);
            });
        });
    }

    saveUiData(data: any) {


        let url = BIGDATA_CONSTANT.url.api + 'mngmnt-client/orgs/config/data/save';
        var restClient = new RestClient(Methods.POST, url);
        return new Promise((resolve, reject) => {
            restClient.execute({}, data).then((response: any) => {
                resolve(response.data)
            }, (error: any) => {
                reject(error);
            });
        });
    }

    saveThemeColor(color: string) {


        let url = BIGDATA_CONSTANT.url.api + 'mngmnt-client/orgs/config/gui/save';
        var restClient = new RestClient(Methods.POST, url);
        return new Promise((resolve, reject) => {
            restClient.execute({}, {
                "theme": "",
                "themeColor": color
            }).then((response: any) => {
                resolve(response.data)
            }, (error: any) => {
                reject(error);
            });
        });
    }



}

export const UISettingDAL = new DAL()
