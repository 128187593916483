import { Button, Drawer } from 'antd'
import React from 'react'
import ReactJson from 'react-json-view'
import { IState } from '../redux/reducer'
import { useSelector } from '../redux/store'

interface ICommonErrorProps {
    visible: boolean
    onClose: () => void
}

const DrawerCommonError = (props: ICommonErrorProps) => {
    const errors = useSelector((state: IState) => state.ui.commonError)
    return (
        <Drawer
            title="ERROR"
            visible={props.visible}
            onClose={() => {
                props.onClose()
            }}
            width={800}
            zIndex={999999}
        >

            <ReactJson src={errors || {}} />
            <div
                style={{
                    position: 'absolute',
                    right: 0,
                    bottom: 0,
                    width: '100%',
                    borderTop: '1px solid #e9e9e9',
                    padding: '10px 16px',
                    background: '#fff',
                    textAlign: 'right',
                    zIndex: 1
                }}
            >
                <Button type="default" onClick={() => {
                    props.onClose()
                }}>Close</Button>
            </div>
        </Drawer>
    )
}

export default DrawerCommonError