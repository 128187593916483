import { Button, Col, Form, Input, Row, Select } from 'antd'
import React from 'react'
import { O2OFormItem } from '../../../../shares/O2OFormItem'
import { FormComponentProps } from 'antd/lib/form'
import { InternalPermissionDAL } from '../DAL/InternalPermissionDAL'

interface Iprops extends FormComponentProps {
    loading: boolean
    onSearch: (value: any) => void
    commonActionFromDALV2: any
}
const FilterInternalPermission = Form.create<Iprops>()((props: Iprops) => {
    const [allPermission, setAllPermission] = React.useState<string[]>([])
    const loadAllPermission = async () => {
        const result = await props.commonActionFromDALV2(InternalPermissionDAL.allPermission)
        try {
            const response = await result
            if (response.success) {
                setAllPermission(response.results)
            } else {
                setAllPermission([])
            }
        } catch (error) {
            setAllPermission([])
        }
    }

    const onSearch = () => {
        props.form.validateFields((err, values) => {
            if (err) return
            props.onSearch(values)
        })
    }

    React.useEffect(() => {
        loadAllPermission()
    }, [])

    return (
        <Form colon={false} hideRequiredMark={true} title="Search" >
            <Row gutter={[5, 5]} type="flex">
                <Col xs={10}>
                    <O2OFormItem label={"Permission"} style={{ flexGrow: 1 }}>
                        {props.form.getFieldDecorator("permission", {

                        })(
                            <Select allowClear
                                placeholder={"Select one"}
                                showSearch
                                showArrow={true}
                                optionFilterProp='children'
                                filterOption={(input: any, option: any) => {
                                    return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                }}
                            >
                                {allPermission && allPermission.length ? allPermission.map((item: string) => {
                                    return (
                                        <Select.Option key={item} value={item} >
                                            {item}
                                        </Select.Option>
                                    )
                                }) : null}
                            </Select>
                        )}
                    </O2OFormItem>
                </Col>
                <Col xs={5} style={{ flexGrow: 1 }}>
                    <O2OFormItem label={"User Id"} >
                        {props.form.getFieldDecorator("userId", {

                        })(
                            <Input.Search placeholder='Enter' onPressEnter={onSearch} />
                        )}
                    </O2OFormItem>
                </Col>
                <Col xs={5} style={{ flexGrow: 1 }}>
                    <O2OFormItem label={"Organization Id"} >
                        {props.form.getFieldDecorator("orgId", {

                        })(
                            <Input.Search placeholder='Enter' onPressEnter={onSearch} />
                        )}
                    </O2OFormItem>
                </Col>
                <Col>
                    <O2OFormItem label={<span></span>}>
                        <Button icon="search" onClick={onSearch} type="primary">Search</Button>
                    </O2OFormItem>
                </Col>
            </Row>
        </Form>
    )
})

export default FilterInternalPermission