import _ from "lodash";
import { Methods, RestClient } from "o2o_layout/lib/common/rest";
import { BIGDATA_CONSTANT } from "../../../common/constant";
import { toQuery } from "../../../common/helper";

export type TypeDTOZMALiveVersion = {
    freeText?: string,
    page?: number
    pageSize?: number
}

export type TypeDTOEditHeaderZMALiveVersion = {
    status: string,
    version: number
}

export type TypeDTOAddZMALiveVersion = {
    appId: string,
    appName: string,
    status: string,
    version: number
}

export interface TypeCriteriaZaloMiniApp {
    page: number;
    pageSize: number;
    connectionIds?: string[];
    freeText?: string;
    orgId?: string;
    scroll?: number;
    scrollId?: string;
    status?: string[];
    templateIds?: string[];
    toSyncedTime?: number;
    categories?: string[];
    tags?: string[];
}

class DAL {
    getAllZaloMiniAppAvailable(criterial: TypeCriteriaZaloMiniApp, orderBy: boolean) {
        const { pageSize = 9 } = criterial ? criterial : { pageSize: 9 }
        let url = BIGDATA_CONSTANT.url.console + `mngmnt-client/supported-zalo-miniapps/search?pageSize=${pageSize}&orderBy=${orderBy ? "appName:DESC" : "appName:ASC"}`
        let paramsUrl = new URLSearchParams();
        if (criterial && criterial.page) {
            const p = criterial.page - 1;
            paramsUrl.append('page', "" + p);
        }
        if (criterial && (criterial.page)) {
            url = url + "&" + paramsUrl.toString();
        }
        var restClient = new RestClient(Methods.POST, url);
        return new Promise((resolve, reject) => {
            restClient.execute({}, criterial ? criterial : {}).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }
    search(dto: TypeDTOZMALiveVersion,) {
        const { page, pageSize } = dto
        const obParams = {
            page: page || 0,
            pageSize: pageSize || 10
        }
        const queryParams = toQuery(_.omitBy(obParams, _.isNil))
        let url = BIGDATA_CONSTANT.url.console + `mngmnt/connections/zma/live-version/search?${queryParams}`;
        var restClient = new RestClient(Methods.POST, url);
        return new Promise((resolve, reject) => {
            restClient.execute({}, dto).then((response: any) => {
                resolve(response.data)
            }, (error: any) => {
                reject(error);
            });
        });
    }
    edit(dto: TypeDTOEditHeaderZMALiveVersion, id: string) {
        let url = BIGDATA_CONSTANT.url.console + `mngmnt/connections/zma/live-version/${id}/edit`;
        var restClient = new RestClient(Methods.POST, url);
        return new Promise((resolve, reject) => {
            restClient.execute({}, dto).then((response: any) => {
                resolve(response.data)
            }, (error: any) => {
                reject(error);
            });
        });
    }
    add(dto: TypeDTOAddZMALiveVersion) {
        let url = BIGDATA_CONSTANT.url.console + `mngmnt/connections/zma/live-version/add`;
        var restClient = new RestClient(Methods.POST, url);
        return new Promise((resolve, reject) => {
            restClient.execute({}, dto).then((response: any) => {
                resolve(response.data)
            }, (error: any) => {
                reject(error);
            });
        });
    }
    delete(id: string) {
        let url = BIGDATA_CONSTANT.url.console + `mngmnt/connections/zma/live-version/${id}/delete`;
        var restClient = new RestClient(Methods.POST, url);
        return new Promise((resolve, reject) => {
            restClient.execute({}, {}).then((response: any) => {
                resolve(response.data)
            }, (error: any) => {
                reject(error);
            });
        });
    }

}
export const ZMALiveVersionDAL = new DAL()