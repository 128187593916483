export const LOADING_SHOW_SCREEN = "LOADING_SHOW_SCREEN"
export const LOADING_HIDE_SCREEN = "LOADING_HIDE_SCREEN"
export const SET_GLOBAL_MESSAGE = "SET_GLOBAL_MESSAGE"
export const UPDATE_LOCALE = "UPDATE_LOCALE"

export const FETCH_LOGIN_INFORMATION = "FETCH_LOGIN_INFORMATION"
export const UPDATE_AUTHEN = "UPDATE_AUTHEN"
export const CHECK_AUTHEN = "CHECK_AUTHEN"
export const GET_ME = "GET_ME"

export const UPDATE_USERS_FB_OAUTH =  "UPDATE_USERS_FB_OAUTH"
export const UPDATE_USERS_FB_PAGES =  "UPDATE_USERS_FB_PAGES"
export const UPDATE_FB_POSTS = "UPDATE_FB_POSTS"
export const UPDATE_FB_POST_COMMENTS = "UPDATE_FB_POST_COMMENTS"
export const MAP_ALL_PAGE = "MAP_ALL_PAGE"
export const UPDATE_LIST_INBOX = "UPDATE_LIST_INBOX"
export const UPDATE_INBOX_DETAIL = "UPDATE_INBOX_DETAIL"
export const RESET_INBOX_DETAIL = "RESET_INBOX_DETAIL"
export const UPDATE_REACTION = "UPDATE_REACTION"
export const LOAD_MORE_REACTION = "LOAD_MORE_REACTION"
export const LOADMORE_LIST_INBOXS = "LOADMORE_LIST_INBOXS"
export const LOADMORE_MESSAGES = "LOADMORE_MESSAGES"
export const UPDATE_SYNC_FACEBOOK = "UPDATE_SYNC_FACEBOOK"

// UI
export const SHOW_SIDER_MAIN_LAYOUT = "SHOW_SIDER_MAIN_LAYOUT"
export const UPDATE_ERROR = "UPDATE_ERROR"