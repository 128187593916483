import * as React from 'react';

import Helmet from 'react-helmet';
import { Row, Col, Layout, Typography, Empty } from 'antd';
import CommonTypeProps from '../../common/CommonTypeProps';
import { DashboardPageLayout } from '../../layout/dashboard/DashboardPageLayout';


interface UI403Props extends CommonTypeProps {

}
// const { SubMenu } = Menu

export class UI403Component extends React.Component<UI403Props, any> {

    formRef: any

    state = {

    }

    componentDidMount = () => {

    }



    renderTheme() {

        return (
            <DashboardPageLayout {...this.props} selectedLeftNav="1">
                <div className="dashboard-page">

                    <Helmet>
                        <title> 403</title>
                        <meta name="description" content="Facebook Connector 403" />
                    </Helmet>

                    <Row gutter={20} type="flex" justify="space-between" style={{ marginBottom: 10 }} align="middle">
                        <Col>
                            <Typography.Title className="o2o-title-grey">
                                 403 Forbidden
                            </Typography.Title>

                        </Col>

                    </Row>

                    <Layout>



                        <Layout style={{ padding: '0 24px 24px' }}>
                            <Row type="flex" justify="space-between" gutter={10}>
                                <Col span={12} offset={6}>

                                    <Empty
                                       
                                        imageStyle={{
                                            height: 100,
                                            margin: 25
                                        }}
                                        
                                        description={
                                            <div>
                                            
                                                <Typography.Text>
                                                    Sorry, you're not allowed in here.
                                                    <br/> Please contact IT
                                                </Typography.Text>
                                            </div>

                                        }
                                    >
                                    

                                    </Empty>


                                </Col>

                            </Row>
                        </Layout>
                    </Layout>

                </div>
            </DashboardPageLayout>
        )
    }

    render() {
        return (
            this.renderTheme()
        );
    }
}

