
import { Response } from './Response';
import { Methods } from './Methods';
import PubSub from 'pubsub-js' 

var axios = require('axios');

const axiosInstance = axios.create({
    timeout: 20000,
});
axiosInstance.defaults.withCredentials = true
export default class RestClient {

    public method: string;
    public url: string;

    constructor(method: string, url: string) {
        this.method = method;
        this.url = url;
    }

    execute(headers: {}, params: any) {

        return new Promise((resolve, reject) => {

            let response = new Response({});
            let _headers = {
                
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }

            if (headers != undefined && headers != null) {
                _headers = { ..._headers, ...headers }
            }


            if (this.method === Methods.POST || this.method == Methods.PUT) {
                params = JSON.stringify(params);
            }

            let api = null;

            if (this.method == Methods.GET) {
                api = axiosInstance[this.method.toLowerCase()](this.url, { headers: _headers, params: params })
            } else {
                if(this.method === Methods.DELETE){
                    api = axiosInstance[this.method.toLowerCase()](this.url, {data: params, headers: _headers})
                }else{
                    api = axiosInstance[this.method.toLowerCase()](this.url, params, { headers: _headers })
                }
                
            }

            api.then((resp: any) => {
                // console.log(resp)
            
                if(resp.headers && resp.headers.extend_token){
                    const extend = resp.headers.extend_token == "true" ? true : false;
                    PubSub.publish('EXTEND_TOKEN', extend);
                }
                
                response.status = resp.status;
                var contentType = resp.headers['content-type'];
                if (contentType && contentType == "application/json") {
                    return resp.data;
                } else {
                    return resp.data;
                }
            })
                .then((results: any) => {
                    
                    if (response.status == 200 || response.status == 201 || response.status == 202 || results.success) {
                        
                        response.data = results;
                        resolve(response);
                    } else {
                        response.error = true;
                        response.message = results.message || results.error || 'Unhandle error';
                        // console.log("REST CLIENT ERROR", results)
                        reject(response);
                    }
                })
                .catch((error: any) => {
                    
                    if (!error.response) {
                        // network error
                        response.status = 10000;
                        response.data = {
                            message: error.message
                        }
                        reject(response);
                    } else {
                        try {

                            response.status = error.response.status
                            response.data = error.response.data

                            reject(response);
                        }
                        catch (e) {
                            reject(error);
                        }
                    }


                });
        });

    }
}
