import { BIGDATA_CONSTANT } from "../../common/constant";
import RestClient from "../../libs/api/RestClient";
import { Methods } from "../../libs/api/Methods";
// import { UserDAL } from "../../dal/UserDAL";
import { TypeOrganization } from 'o2o_layout/lib/common/model';

export type typeCriteriaSearchOrgs = {
    codes: string[],
    industries: string[]
    accountTypes: string[],
    freeText: string,
    page: number,
    pageSize: number
    order: string,
}


class Orgs {

    list(criterial?: typeCriteriaSearchOrgs) {

        let url = BIGDATA_CONSTANT.url.api + `mngmnt/orgs/search?pageSize=${criterial ? criterial.pageSize || 10 : 10}`;

        let paramsUrl = new URLSearchParams();

        if (criterial && criterial.page) {
            const p = criterial.page - 1;
            paramsUrl.append('page', "" + p);
        }
        if (criterial && criterial.order) {
            paramsUrl.append('orderBy', criterial.order);
        }
        if (criterial && (criterial.page || criterial.order)) {
            url = url + "&" + paramsUrl.toString();
        }

        var restClient = new RestClient(Methods.POST, url);

        // var user: UserModel  = this.getUserFromLocalStorage();
        var headers = {
            // token: user.token,
            // api_key: user.apiKey
        };
        const body = criterial ? criterial : {}

        return new Promise((resolve, reject) => {
            restClient.execute(headers, body).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });

    }

    create(criterial: TypeOrganization) {

        let url = BIGDATA_CONSTANT.url.api + 'mngmnt/orgs/';
        var restClient = new RestClient(Methods.POST, url);

        // var user: UserModel  = this.getUserFromLocalStorage();
        var headers = {
            // token: user.token,
            // api_key: user.apiKey
        };

        return new Promise((resolve, reject) => {
            restClient.execute(headers, criterial).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });

    }

    createUserOrg(criterial: TypeOrganization) {

        let url = BIGDATA_CONSTANT.url.api + 'accounts/users/current/orgs/create'
        var restClient = new RestClient(Methods.POST, url);

        // var user: UserModel  = this.getUserFromLocalStorage();
        var headers = {
            // token: user.token,
            // api_key: user.apiKey
        };
        criterial.accountType = "Trial"

        return new Promise((resolve, reject) => {
            restClient.execute(headers, criterial).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }
    listCurrentUserOrg() {

        let url = BIGDATA_CONSTANT.url.api + 'accounts/users/current/orgs';


        var restClient = new RestClient(Methods.GET, url);

        // var user: UserModel  = this.getUserFromLocalStorage();
        var headers = {
            // token: user.token,
            // api_key: user.apiKey
        };

        return new Promise((resolve, reject) => {
            restClient.execute(headers, {}).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });

    }


    update(criteria: TypeOrganization) {

        let url = BIGDATA_CONSTANT.url.api + 'mngmnt/orgs/' + criteria.id + '/';
        var restClient = new RestClient(Methods.POST, url);

        // var user: UserModel  = this.getUserFromLocalStorage();
        var headers = {
            // token: user.token,
            // api_key: user.apiKey
        };

        return new Promise((resolve, reject) => {
            restClient.execute(headers, criteria).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });

    }


    updateOrgByAdminClient(criteria: TypeOrganization, isSadmin?: boolean) {

        let url = !isSadmin ? BIGDATA_CONSTANT.url.api + 'mngmnt-client/orgs/update?id=' + criteria.id : BIGDATA_CONSTANT.url.api + 'mngmnt/orgs/' + criteria.id + "/";
        var restClient = new RestClient(Methods.POST, url);

        var headers = {
        };

        return new Promise((resolve, reject) => {
            restClient.execute(headers, criteria).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });

    }


    delete(id: string, otp_code: string) {

        let url = BIGDATA_CONSTANT.url.api + 'mngmnt/orgs/' + id + '/deletep';
        var restClient = new RestClient(Methods.POST, url);

        // var user: UserModel  = this.getUserFromLocalStorage();
        var headers = {
            otp_code: otp_code
            // token: user.token,
            // api_key: user.apiKey
        };

        return new Promise((resolve, reject) => {
            restClient.execute(headers, {}).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });

    }

    getWebtagging(id: string) {
        let url = BIGDATA_CONSTANT.url.api + `mngmnt/orgs/setup/${id}/web-tagging/get`
        var restClient = new RestClient(Methods.GET, url);

        var headers = {
        };

        return new Promise((resolve, reject) => {
            restClient.execute(headers, {}).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }
    getModelStorage(id: string) {
        let url = BIGDATA_CONSTANT.url.api + `mngmnt/orgs/setup/${id}/model-storage/get`
        var restClient = new RestClient(Methods.GET, url);

        var headers = {
        };

        return new Promise((resolve, reject) => {
            restClient.execute(headers, {}).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }
    getCronJob(id: string) {
        let url = BIGDATA_CONSTANT.url.api + `mngmnt/orgs/setup/${id}/cronjob-config/get`
        var restClient = new RestClient(Methods.GET, url);

        var headers = {

        };

        return new Promise((resolve, reject) => {
            restClient.execute(headers, {}).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }
    saveWebtagging(id: string, dto: any) {
        let url = BIGDATA_CONSTANT.url.api + `mngmnt/orgs/setup/${id}/web-tagging/save`
        var restClient = new RestClient(Methods.POST, url);

        var headers = {
        };

        return new Promise((resolve, reject) => {
            restClient.execute(headers, dto).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }
    saveCronjob(id: string, dto: any) {
        let url = BIGDATA_CONSTANT.url.api + `mngmnt/orgs/setup/${id}/cronjob-config/save`
        var restClient = new RestClient(Methods.POST, url);

        var headers = {
        };

        return new Promise((resolve, reject) => {
            restClient.execute(headers, dto).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }
    saveModelStorage(id: string, dto: any) {
        let url = BIGDATA_CONSTANT.url.api + `mngmnt/orgs/setup/${id}/model-storage/save`
        var restClient = new RestClient(Methods.POST, url);

        var headers = {
        };

        return new Promise((resolve, reject) => {
            restClient.execute(headers, dto).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }
    rerunPermissionSystemGroup(orgId: string) {
        let url = BIGDATA_CONSTANT.url.api + `mngmnt/permission/system-groups/${orgId}/init`
        var restClient = new RestClient(Methods.GET, url);

        var headers = {
        };

        return new Promise((resolve, reject) => {
            restClient.execute(headers, {}).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }
    updateStatus(id: string, status: string) {
        let url = BIGDATA_CONSTANT.url.api + `mngmnt/orgs/${id}/status?status=${status}`
        var restClient = new RestClient(Methods.POST, url);

        var headers = {
        };

        return new Promise((resolve, reject) => {
            restClient.execute(headers, {}).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }
    getAcceptedDomain(id: string) {
        let url = BIGDATA_CONSTANT.url.api + `mngmnt/orgs/setup/${id}/general-settings/get`
        var restClient = new RestClient(Methods.GET, url);

        var headers = {
        };

        return new Promise((resolve, reject) => {
            restClient.execute(headers, {}).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }
    saveAcceptedDomain(id: string, dto: {
        actionBy: string,
        allowedDomains: string[]
    }) {
        let url = BIGDATA_CONSTANT.url.api + `mngmnt/orgs/setup/${id}/general-settings/save`
        var restClient = new RestClient(Methods.POST, url);

        var headers = {
        };

        return new Promise((resolve, reject) => {
            restClient.execute(headers, dto).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }

    initGroupPermission(orgId: string, dto: any) {
        let url = BIGDATA_CONSTANT.url.api + `mngmnt/orgs/group-permissions/${orgId}/_init`
        var restClient = new RestClient(Methods.POST, url);

        return new Promise((resolve, reject) => {
            restClient.execute({}, dto).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }
}

export const OrgsDAL = new Orgs()
