import * as React from "react"
import { getPrimaryColor } from '../common/helper';
import { Tag } from 'antd';
import { TagProps } from "antd/lib/tag";
import { UISetting } from 'o2o_layout/lib/common/model';

interface IProps extends TagProps {
    uiSetting: UISetting,
    children: any,
    style?: React.CSSProperties
}

export const TagPrimaryColor = (props: IProps) => {
    let style: React.CSSProperties = {...{
        color: getPrimaryColor(props.uiSetting),
        background: "#fff",
        borderColor: getPrimaryColor(props.uiSetting)
    }, ...props.style}
    return (
        <Tag {...props} style={style} >
            {props.children}
        </Tag>
    )
}