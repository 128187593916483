import { Methods, RestClient } from "o2o_layout/lib/common/rest";
import { toQuery } from "../../../../common/helper";
import { BIGDATA_CONSTANT } from "../../../../common/constant";

export type TypeDTOChangelogSearch = {
    page?: number,
    pageSize?: number
}

class DAL {
    search(dto: TypeDTOChangelogSearch) {
        const q = toQuery({
            page: dto.page || 0,
            pageSize: dto.pageSize || 10,
        })
        let url = BIGDATA_CONSTANT.url.console + `mngmnt-client/orgs/logs/system/search?${q}`;
        var restClient = new RestClient(Methods.POST, url);
        return new Promise((resolve, reject) => {
            restClient.execute({}, dto).then(
                (response: any) => {
                    const user: any = response.data;
                    resolve(user);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    }
}
export const ChangelogDAL = new DAL()