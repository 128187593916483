import { Col, Form, Input, Modal, Row, Spin } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import React from 'react'
import ReactOtpInput from 'react-otp-input'
import IconKey from "../static/icon/icon-key.svg"

interface IConfirmModal extends FormComponentProps {
    visible: boolean,
    onClose: () => void
    okModal: (e: any, form: any) => any
    loading: boolean
    okText: string
    error?: string
    type?: "delete" | "view" | 'add-key'
}

export const Confirm2FA = Form.create<IConfirmModal>()((props: IConfirmModal) => {
    React.useEffect(() => {
        if (!props.visible) {
            props.form.resetFields()
        }
    }, [props.visible])

    const renderTitle = () => {
        if (props.type === "delete") {
            return <span style={{ marginLeft: 10 }}>To delete data, you need to provide 2FA code</span>
        } else if (props.type === "view") {
            return <span style={{ marginLeft: 10 }}>To view data, you need to provide 2FA code</span>
        } else if (props.type === "add-key") {
            return <span style={{ marginLeft: 10 }}>To add key, you need to provide 2FA code</span>
        } else {
            return <span style={{ marginLeft: 10 }}>To export data, you need to provide 2FA code</span>
        }
    }

    return (
        <Modal
            title={
                <>
                    <img src={IconKey} alt="icon-key" style={{ marginRight: 5 }} />
                    2FA Confirmation
                </>
            }
            visible={props.visible}
            onCancel={props.onClose}
            okButtonProps={{
                disabled: props.form.getFieldValue("codeOTP") ? props.form.getFieldValue("codeOTP").length == 6 ? false : true : true
            }}
            okText={props.okText}
            onOk={(e: any) => {
                props.okModal(e, props.form)
            }}
            centered

        >
            <Spin spinning={props.loading}>
                <Form layout="horizontal" hideRequiredMark colon={false}>
                    <Row gutter={[5, 5]} type="flex" >
                        <Col xs={24}>

                            <Form.Item label={renderTitle()}>
                                {props.form.getFieldDecorator("codeOTP", {
                                    rules: [{
                                        required: true,
                                        message: "Please enter the code"
                                    }]
                                })(
                                    <Input style={{ width: "100%", display: "none" }} placeholder="Open your authentication app and enter the code for ByteTech" />
                                )}
                                <ReactOtpInput
                                    isInputNum
                                    inputStyle={{ width: 48, borderRadius: 4, borderColor: "#ccc", borderStyle: "solid", height: 48, padding: "1px 2px", margin: "0px 10px", fontSize: 32, lineHeight: 40, color: "#000000" }}
                                    value={props.form.getFieldValue("codeOTP")}
                                    onChange={(otp: any) => {
                                        props.form.setFieldsValue({ codeOTP: otp })
                                        if (otp.length == 6) {
                                            props.okModal(undefined, props.form)
                                        }
                                    }}
                                    numInputs={6}
                                    separator={<span>-</span>}
                                />
                                {props.error ? (
                                    <p style={{ color: "red", marginLeft: 10 }}>{props.error}</p>
                                ) : null}

                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </Modal >
    )
})
