
import { Methods, RestClient } from 'o2o_layout/lib/common/rest';
import { BIGDATA_CONSTANT } from '../../../../common/constant';

export type TypeCriteriaSearchUserToken = {
    
        page?: number,
        order?: string,

      
 }


class UT {
    logoutId(id: string) {
        let url = BIGDATA_CONSTANT.url.api + 'logout/by-id?id='+id;
        var restClient = new RestClient(Methods.POST, url);
        var headers = {};
        var body = {  }
        return new Promise((resolve, reject) => {
            restClient.execute(headers, body).then((response: any) => {
                // BigDataConsole("DAL LOGIN RESULT", response);
                const user: any = response.data;
                // localStorage.setItem(BIGDATA_CONSTANT.store.key, JSON.stringify(response.data.result));
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }

    extendToken() {
        let url = BIGDATA_CONSTANT.url.api + 'authen/token/extend';
        var restClient = new RestClient(Methods.GET, url);
        var headers = {};
        var body = {  }
        return new Promise((resolve, reject) => {
            restClient.execute(headers, body).then((response: any) => {
                // BigDataConsole("DAL LOGIN RESULT", response);
                const user: any = response.data;
                // localStorage.setItem(BIGDATA_CONSTANT.store.key, JSON.stringify(response.data.result));
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }

    


    list(criterial: TypeCriteriaSearchUserToken) {

        let url = BIGDATA_CONSTANT.url.api + 'accounts/users/tokens?pageSize=15';

        let paramsUrl = new URLSearchParams();

        if (criterial && criterial.page) {
            const p = criterial.page - 1;
            paramsUrl.append('page', "" + p);
        }
        if (criterial && criterial.order) {
            paramsUrl.append('orderBy', criterial.order);
        }
        if (criterial && (criterial.page || criterial.order)) {
            url = url + "&" + paramsUrl.toString();
        }

        var restClient = new RestClient(Methods.POST, url);

        // var user: UserModel  = this.getUserFromLocalStorage();
        var headers = {
            // token: user.token,
            // api_key: user.apiKey
        };
        

        return new Promise((resolve, reject) => {
            restClient.execute(headers, criterial).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });

    }
    updateStatusApiAccess(status: boolean){
        let url = BIGDATA_CONSTANT.url.api + `accounts/users/current/api-key/status?disabled=${status}`;
        var restClient = new RestClient(Methods.GET, url);
        var headers = {};
        var body = {  }
        return new Promise((resolve, reject) => {
            restClient.execute(headers, body).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }

    uploadAvatar(file: File) {
        let url = BIGDATA_CONSTANT.url.api + `accounts/users/current/update/_avatar`;
        var restClient = new RestClient(Methods.POST, url);
        const formData = new FormData();
        formData.append('file', file)
        var headers = {
            'Content-Type': 'multipart/form-data'
        };
        console.log(file)
        console.log(formData.has);
        return new Promise((resolve, reject) => {
            restClient.execute(headers, formData).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }


}

export const UserTokenDAL = new UT()
