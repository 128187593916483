import { UPDATE_ERROR } from '../../../../common/actionType/actionType';
import { Response } from '../../../../libs/api/Response';
import { IActionCommonResponse, UPDATE_COMMON_RESPONSE } from '../../../../redux/reducer/commonResponseReducer';
import { IActionUI } from '../../../../redux/reducer/uiReducer';
import { IPermissionGroupCriteria, PermissionDAL } from '../dal/PermissionDAL';
import { GET_ALL_PERMISSION_GROUP, GET_PERMISSION, GET_PERMISSION_GROUP, GET_PERMISSION_GROUP_CONTEXT, IActionAllPermissionGroup, IActionPermission, IActionPermissionGroup, IActionPermissionGroupContext } from '../reducer/permissionReducer';

export function searchPermission() {
    return (dispatch: any) => {


        return new Promise((resolve, reject) => {

            PermissionDAL.searchPermission()
                .then(orgs => {
                    dispatch({
                        type: GET_PERMISSION,
                        payload: orgs
                    } as IActionPermission)
                    resolve(orgs);
                }, (error: Response) => {
                    if (error.status == 401 || error.status == 403) {
                        dispatch({
                            type: UPDATE_ERROR,
                            errorStatus: error.status,
                            errorResponse: error.data
                        } as IActionUI)
                    }
                    reject(error);
                });
        });

    }
}


export function createPermissionGroup(c: any) {
    return (dispatch: any) => {


        return new Promise((resolve, reject) => {

            PermissionDAL.createPermissionGroup(c)
                .then(orgs => {
                    dispatch({
                        type: UPDATE_COMMON_RESPONSE,
                        payload: orgs
                    } as IActionCommonResponse)
                    resolve(orgs);
                }, (error: Response) => {
                    if (error.status == 401 || error.status == 403) {
                        dispatch({
                            type: UPDATE_ERROR,
                            errorStatus: error.status,
                            errorResponse: error.data
                        } as IActionUI)
                    }
                    reject(error);
                });
        });

    }
}



export function updatePermissionGroup(id: string,c: any) {
    return (dispatch: any) => {


        return new Promise((resolve, reject) => {

            PermissionDAL.updatePermissionGroup(id,c)
                .then(orgs => {
                    dispatch({
                        type: UPDATE_COMMON_RESPONSE,
                        payload: orgs
                    } as IActionCommonResponse)
                    resolve(orgs);
                }, (error: Response) => {
                    if (error.status == 401 || error.status == 403) {
                        dispatch({
                            type: UPDATE_ERROR,
                            errorStatus: error.status,
                            errorResponse: error.data
                        } as IActionUI)
                    }
                    reject(error);
                });
        });

    }
}


export function deletePermissionGroup(ids: string) {
    return (dispatch: any) => {


        return new Promise((resolve, reject) => {

            PermissionDAL.deletePermissionGroups(ids)
                .then(orgs => {
                    dispatch({
                        type: UPDATE_COMMON_RESPONSE,
                        payload: orgs
                    } as IActionCommonResponse)
                    resolve(orgs);
                }, (error: Response) => {
                    if (error.status == 401 || error.status == 403) {
                        dispatch({
                            type: UPDATE_ERROR,
                            errorStatus: error.status,
                            errorResponse: error.data
                        } as IActionUI)
                    }
                    reject(error);
                });
        });

    }
}



export function assignUsersToPermissionGroups(uids: string[], pgid: string[]) {
    return (dispatch: any) => {


        return new Promise((resolve, reject) => {

            PermissionDAL.assignUsersToPermissionGroups(uids, pgid)
                .then(orgs => {
                    dispatch({
                        type: UPDATE_COMMON_RESPONSE,
                        payload: orgs
                    } as IActionCommonResponse)
                    resolve(orgs);
                }, (error: Response) => {
                    if (error.status == 401 || error.status == 403) {
                        dispatch({
                            type: UPDATE_ERROR,
                            errorStatus: error.status,
                            errorResponse: error.data
                        } as IActionUI)
                    }
                    reject(error);
                });
        });

    }
}




export function saveUsersToPermissionGroups(uids: string[], pgid: string[]) {
    return (dispatch: any) => {


        return new Promise((resolve, reject) => {

            PermissionDAL.saveUsersToPermissionGroups(uids, pgid)
                .then(orgs => {
                    dispatch({
                        type: UPDATE_COMMON_RESPONSE,
                        payload: orgs
                    } as IActionCommonResponse)
                    resolve(orgs);
                }, (error: Response) => {
                    if (error.status == 401 || error.status == 403) {
                        dispatch({
                            type: UPDATE_ERROR,
                            errorStatus: error.status,
                            errorResponse: error.data
                        } as IActionUI)
                    }
                    reject(error);
                });
        });

    }
}


export function searchPermissionGroup(criteria: IPermissionGroupCriteria ) {
    return (dispatch: any) => {


        return new Promise((resolve, reject) => {

            PermissionDAL.searchPermissionGroup(criteria)
                .then(orgs => {
                    dispatch({
                        type: GET_PERMISSION_GROUP,
                        payload: orgs
                    } as IActionPermissionGroup)
                    resolve(orgs);
                }, (error: Response) => {
                    if (error.status == 401 || error.status == 403) {
                        dispatch({
                            type: UPDATE_ERROR,
                            errorStatus: error.status,
                            errorResponse: error.data
                        } as IActionUI)
                    }
                    reject(error);
                });
        });

    }
}
export function searchAllPermissionGroup() {
    return (dispatch: any) => {


        return new Promise((resolve, reject) => {

            PermissionDAL.searchAllPermissionGroup()
                .then(orgs => {
                    dispatch({
                        type: GET_ALL_PERMISSION_GROUP,
                        payload: orgs
                    } as IActionAllPermissionGroup)
                    resolve(orgs);
                }, (error: Response) => {
                    if (error.status == 401 || error.status == 403) {
                        dispatch({
                            type: UPDATE_ERROR,
                            errorStatus: error.status,
                            errorResponse: error.data
                        } as IActionUI)
                    }
                    reject(error);
                });
        });

    }
}



export function searchPermissionGroupContext(q: string) {
    return (dispatch: any) => {


        return new Promise((resolve, reject) => {

            PermissionDAL.searchPermissionGroupContext(q)
                .then(orgs => {
                    dispatch({
                        type: GET_PERMISSION_GROUP_CONTEXT,
                        payload: orgs
                    } as IActionPermissionGroupContext)
                    resolve(orgs);
                }, (error: Response) => {
                    if (error.status == 401 || error.status == 403) {
                        dispatch({
                            type: UPDATE_ERROR,
                            errorStatus: error.status,
                            errorResponse: error.data
                        } as IActionUI)
                    }
                    reject(error);
                });
        });

    }
}

