import { Button, Col, Form, Input, Row, Select, Table, Tooltip, Typography } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import { ColumnProps } from 'antd/lib/table'
import { isEmpty, isObject } from 'lodash'
import moment from "moment"
import { InfoList, LongTextTd, O2OCustomPaging } from 'o2o_layout'
import React, { useEffect, useState } from 'react'
import { useAsyncRetry } from 'react-use'
import { ICommonPayload } from '../../../../common/CommonTypeProps'
import { BIGDATA_CONSTANT } from '../../../../common/constant'
import { getCurrentURL } from '../../../../common/helper'
import DrawerViewJSON from '../../../../shares/DrawerViewJSON'
import { O2OFormItem } from '../../../../shares/O2OFormItem'
import SuggestionAccount from '../../permisions/components/SuggestionAccount'
import { IConsoleUsersActivityProps } from '../ConsoleUsersActivityComponent'
import { AccountLogsDAL } from './DAL'
import { AccountLogs, TypeAccountLogs } from './model'


interface IFilterAccountLogsComponentProps extends FormComponentProps {
    commonActionFromDALV2: any
    onSearch: (payload: any) => void
    loading: boolean
}

const FilterAccountLogsComponent = Form.create<IFilterAccountLogsComponentProps>()(
    ({ commonActionFromDALV2, form, onSearch, loading }: IFilterAccountLogsComponentProps) => {

        const onSubmit = () => {
            form.validateFields((err: any, values: any) => {
                if (!err) {
                    onSearch(values)
                }
            })
        }

        return <Form hideRequiredMark colon={false}>
            <Row type='flex' gutter={[5, 5]}>
                <Col sm={24} lg={12} xl={5} style={{ flexGrow: 1 }}>
                    <O2OFormItem label="Info">
                        {form.getFieldDecorator("freeText", {

                        })(<Input onPressEnter={onSubmit} allowClear placeholder='Enter' />)}
                    </O2OFormItem>
                </Col>
                <Col sm={24} lg={12} xl={5} style={{ flexGrow: 1 }}>
                    <O2OFormItem label="Actions">
                        {form.getFieldDecorator("actions", {

                        })(<Select showArrow allowClear maxTagCount={1} maxTagTextLength={10} mode="tags" placeholder="Select(s)" ></Select>)}
                    </O2OFormItem>
                </Col>
                <Col sm={24} lg={12} xl={5} style={{ flexGrow: 1 }}>
                    <O2OFormItem label="Action By">
                        {form.getFieldDecorator("actionBys", {

                        })(<Select showArrow allowClear maxTagCount={1} maxTagTextLength={10} mode="tags" placeholder="Select(s)" ></Select>)}
                    </O2OFormItem>
                </Col>
                <Col sm={24} lg={12} xl={5} style={{ flexGrow: 1 }}>
                    <SuggestionAccount
                        maxTagTextLength={8}
                        commonActionFromDALV2={commonActionFromDALV2}
                        form={form}
                        keyField={"userIds"}
                        label="User"
                        mode={"multiple"}
                        placeholder="Select one"
                        onSelect={(e: string) => {
                        }}
                        visible={true}
                    />
                </Col>
                <Col>
                    <O2OFormItem label=" ">
                        <Row type='flex' align={"middle"}>
                            <Button loading={loading} onClick={onSubmit} type='primary' icon='search'>Search</Button>
                        </Row>
                    </O2OFormItem>
                </Col>

            </Row>
        </Form>
    }
)














interface IAccountLogsComponentProps extends IConsoleUsersActivityProps {

}


const TYPE_DISPATCH_CRITERIA = "TYPE_DISPATCH_CRITERIA"

const initCriteria: ICommonPayload<any> = {
    loading: false,
    type: TYPE_DISPATCH_CRITERIA,
    payload: {} as any,
}

const reducerCriteria = (state: ICommonPayload<any>, action: ICommonPayload<any>) => {
    switch (action.type) {
        case TYPE_DISPATCH_CRITERIA:
            return { ...state, ...action }
        default:
            throw new Error()
    }
}

const AccountLogsComponent = ({ commonActionFromDALV2, updateMetaTitle, updateMeta, location }: IAccountLogsComponentProps) => {
    const [criteria, dispatchCriteria] = React.useReducer(reducerCriteria, initCriteria)

    const [selectedRecord, setSelectedRecord] = useState(undefined as undefined | TypeAccountLogs)
    const [visibleViewJSON, setVisibleViewJSON] = useState(false)
    const [dataObjectRecord, setDataObjectRecord] = useState(undefined as any)


    useEffect(() => {
        dispatchCriteria({
            loading: false,
            type: TYPE_DISPATCH_CRITERIA,
            payload: { page: 0, pageSize: 10 } as any,
        })
        return () => {
            dispatchCriteria({
                loading: false,
                type: TYPE_DISPATCH_CRITERIA,
                payload: {} as any,
            })
        }
    }, [])

    React.useEffect(() => {
        updateMetaTitle({ title: BIGDATA_CONSTANT.trackTitle.Activities.AccountLogs })
        const timer = setTimeout(() => {
            updateMeta({
                name: BIGDATA_CONSTANT.trackTitle.Activities.AccountLogs,
                title: BIGDATA_CONSTANT.trackTitle.Activities.AccountLogs,
                app: "CONSOLE",
                url: getCurrentURL(location)
            })
        }, 5000)

        return () => {
            clearTimeout(timer)
        }
    }, [])



    const load = async () => {
        const result = commonActionFromDALV2(AccountLogsDAL.search, criteria.payload)
        try {
            const response: AccountLogs = await result
            if (response.success) {
                return response
            } else {
                return new AccountLogs()
            }
        } catch (error) {
            return new AccountLogs()
        }
    }

    const stateAccountLogs = useAsyncRetry(async () => {
        if (!isEmpty(criteria.payload)) {
            const data = await load()
            return data
        }
        return new AccountLogs()
    }, [criteria])

    const accountLogs: AccountLogs = stateAccountLogs.value || new AccountLogs()

    const columns: ColumnProps<TypeAccountLogs>[] = [
        {
            title: "#",
            key: "index",
            width: 40,
            align: "center",
            ellipsis: true,
            render: (text: any, record: TypeAccountLogs, index: number) => {
                return index + 1
            }
        },
        {
            title: "Action At",
            key: "actionAt",
            width: 120,
            ellipsis: true,
            render: (text: any, record: TypeAccountLogs, index: number) => {
                return record.actionAt ? <Tooltip title={moment(record.actionAt).format("LLLL")}>
                    {moment(record.actionAt).fromNow()}
                </Tooltip> : '--/--'
            }
        },
        {
            title: "Action By",
            key: "actionBy",
            width: 150,
            ellipsis: true,
            render: (text: any, record: TypeAccountLogs, index: number) => {
                return <LongTextTd text={record.actionBy} />
            }
        },
        {
            title: "Action",
            key: "actionText",
            width: 200,
            ellipsis: true,
            render: (text: any, record: TypeAccountLogs, index: number) => {
                return <LongTextTd text={record.action} />
            }
        },
        {
            title: "UserId",
            key: "user",
            width: 200,
            ellipsis: true,
            render: (text: any, record: TypeAccountLogs, index: number) => {
                return <LongTextTd text={record.userId} />
            }
        },
        {
            title: "Object",
            key: "object",
            width: 70,
            ellipsis: true,
            render: (text: any, record: TypeAccountLogs, index: number) => {
                return <Button onClick={() => {
                    let object = {}
                    try {
                        if (record.object && isObject(JSON.parse(record.object))) {
                            object = JSON.parse(record.object)
                        } else {
                            object = {}
                        }
                    } catch (error) {
                        object = {}
                    }
                    setDataObjectRecord(object)
                    setVisibleViewJSON(true)
                }} type='link' size='small'>View</Button>
            }
        },
        {
            title: "Reason",
            key: "reason",
            ellipsis: true,
            render: (text: any, record: TypeAccountLogs, index: number) => {
                return <LongTextTd text={record.reason}/>
            }
        },
        {
            title: "Action",
            fixed: "right",
            width: 70,
            key: "action",
            ellipsis: true,
            render: (text: any, record: TypeAccountLogs, index: number) => {
                return <Row type='flex' align={"middle"}>
                    <Button type='link' size='small' icon='select' onClick={() => {
                        setVisibleViewJSON(true)
                        setSelectedRecord(record)
                    }} />
                </Row>
            }
        }
    ]

    return (
        <Row type='flex' gutter={[10, 10]}>
            <Col xs={24}>
                <Typography.Title level={4}>
                    Account Logs
                </Typography.Title>
            </Col>
            <Col xs={24}>
                <FilterAccountLogsComponent
                    loading={stateAccountLogs.loading}
                    onSearch={(payload: any) => {
                        dispatchCriteria({
                            loading: false,
                            type: TYPE_DISPATCH_CRITERIA,
                            payload: { ...criteria.payload, ...{ page: 0 }, ...payload } as any,
                        })
                    }}
                    commonActionFromDALV2={commonActionFromDALV2}
                />
            </Col>
            <Col xs={24}>
                <InfoList
                    loading={stateAccountLogs.loading}
                    refresh={stateAccountLogs.retry}
                    list={accountLogs}
                />
            </Col>
            <Col xs={24}>
                <Table
                    loading={stateAccountLogs.loading}
                    columns={columns}
                    dataSource={accountLogs.results.content}
                    rowKey="id"
                    size="small"
                    scroll={{ x: 'calc(500px + 50%)' }}
                    pagination={false}
                />
            </Col>
            <Col xs={24}>
                <O2OCustomPaging
                    list={accountLogs}
                    onChange={(e: any) => {
                        dispatchCriteria({
                            loading: false,
                            type: TYPE_DISPATCH_CRITERIA,
                            payload: { ...criteria.payload, ...{ page: e - 1 } } as any,
                        })
                    }}
                    onChangePageSize={(e: any) => {
                        dispatchCriteria({
                            loading: false,
                            type: TYPE_DISPATCH_CRITERIA,
                            payload: { ...criteria.payload, ...{ page: 0, pageSize: e } } as any,
                        })
                    }}
                />
            </Col>


            <DrawerViewJSON
                visible={visibleViewJSON}
                data={selectedRecord || dataObjectRecord}
                onClose={() => {
                    setDataObjectRecord(undefined)
                    setSelectedRecord(undefined)
                    setVisibleViewJSON(false)
                }}
            />
        </Row>
    )
}

export default AccountLogsComponent
