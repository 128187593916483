import React from 'react'
import { Breadcrumb, Button, Col, Divider, Dropdown, Form, Icon, Input, Menu, Modal, Row, Table, Tooltip, Typography, notification } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import { useAsyncRetry } from 'react-use'
import { InfoList, LongTextTd, O2OCustomPaging } from 'o2o_layout'
import { ColumnProps } from 'antd/lib/table'
import { O2OFormItem } from '../../../shares/O2OFormItem'
import CommonTypeProps, { ICommonPayload } from '../../../common/CommonTypeProps'
import { PermistionResponse, TypeCriteriaPermission, TypePermistion } from '../permisions/model/permissions'
import DrawerViewJSON from '../../../shares/DrawerViewJSON'
import moment from 'moment'
import DrawerAddUpdateComponent from './drawer/DrawerAddUpdateComponent'
import { ClickParam } from 'antd/lib/menu'
import { BIGDATA_CONSTANT } from '../../../common/constant'
import { getCurrentURL, getPrimaryColor } from '../../../common/helper'
import { useSelector } from '../../../redux/store'
import { IState } from '../../../redux/reducer'
import { DashboardPageLayout } from '../../../layout/dashboard/DashboardPageLayout'
import { MetaHeader } from '../../../shares/MetaHeader'
import { Link } from 'react-router-dom'
import { SAPermission } from './dal/DAL'
import _ from 'lodash'

interface Iprops extends CommonTypeProps {

}

interface IFormFilterProps extends FormComponentProps {
    onSearch: (values: any) => void
}

const FormFilterComponent = Form.create<IFormFilterProps>()((props: IFormFilterProps) => {
    const { getFieldDecorator } = props.form

    const onSearch = () => {
        props.form.validateFields((err: any, values: any) => {
            if (!err) {
                props.onSearch(values)
            }
        })
    }

    return (
        <Form colon={false} layout="horizontal" title="Search">
            <Row gutter={[5, 5]} type="flex" justify="start">
                <Col xs={10} >
                    <O2OFormItem label="Info">
                        {getFieldDecorator('query', {
                        })(
                            <Input.Search onPressEnter={onSearch} placeholder="Enter" allowClear></Input.Search>
                        )}
                    </O2OFormItem>
                </Col>
                <Col>
                    <O2OFormItem label={<span style={{ color: "#fff" }}>Search</span>}>
                        <Button onClick={onSearch} type="primary" icon="search">Search</Button>
                    </O2OFormItem>
                </Col>
            </Row>
        </Form>
    )
})

const initCriteria: ICommonPayload<TypeCriteriaPermission> = {
    loading: false,
    type: "",
    payload: { page: 0, pageSize: 10 } as TypeCriteriaPermission,
}

const SEARCH_PERMISSION = "SEARCH_PERMISSION"

const reducerCriteria = (state: ICommonPayload<TypeCriteriaPermission>, action: ICommonPayload<TypeCriteriaPermission>) => {
    switch (action.type) {
        case SEARCH_PERMISSION:
            return { ...state, ...action }
        default:
            throw new Error()
    }
}

const SuperPermissionComponent = (props: Iprops) => {
    const [criteria, dispatchCriteria]: [ICommonPayload<TypeCriteriaPermission>, (action: ICommonPayload<TypeCriteriaPermission>) => void] = React.useReducer(reducerCriteria, initCriteria)
    const [visibleViewJSON, setVisibleViewJSON] = React.useState(false)
    const [visibleAddUpdate, setVisibleAddUpdate] = React.useState(false)
    const [selectedRecord, setSelectedRecord] = React.useState({} as TypePermistion | undefined)


    const uiSetting = useSelector((state: IState) => state.uiSetting)

    const loadData = async () => {
        const result: Promise<any> = props.commonActionFromDALV2(SAPermission.search, criteria.payload)
        try {
            const response: any = await result
            if (response.success) {
                return response
            } else {
                return new PermistionResponse()
            }
        } catch (error) {
            return new PermistionResponse()
        }
    }

    const statePersmission = useAsyncRetry(async () => {
        if (criteria.payload) {
            const data: PermistionResponse = await loadData()
            return data
        }
        return new PermistionResponse()
    }, [criteria])

    const permissionResponse: PermistionResponse = statePersmission.value ? statePersmission.value : new PermistionResponse()

    const onDelete = async (record: TypePermistion) => {
        const result = await props.commonActionFromDALV2(SAPermission.delete, record.id)
        try {
            const payload = await result
            if (payload.success) {
                setTimeout(() => {
                    statePersmission.retry()
                }, 1000);
                notification.success({
                    message: BIGDATA_CONSTANT.message.default.title.success,
                    description: BIGDATA_CONSTANT.message.notification.success.delete,
                    icon: <Icon type="check-circle" style={{ color: getPrimaryColor(props.uiSetting) }} />
                })
            } else {
                notification.error({
                    message: BIGDATA_CONSTANT.message.default.title.success,
                    description: _.get(payload, "message", "Something went wrong"),
                })
            }
        } catch (error) {
            notification.error({
                message: BIGDATA_CONSTANT.message.default.title.success,
                description: _.get(error, "message", "Something went wrong"),
            })
        }
    }

    const columns: ColumnProps<{}>[] = [
        {
            title: "#",
            key: "index",
            sorter: false,
            width: 50,
            render: (text: any, record: TypePermistion, index: number) => {
                return index + 1
            }
        }, {
            title: "Name",
            key: "name",
            ellipsis: true,
            width: 300,
            render: (text: any, record: TypePermistion, index: number) => {
                return <LongTextTd text={record.name} />
            },
        }, {
            title: "Key",
            key: "key",
            width: 150,
            ellipsis: true,
            render: (text: any, record: TypePermistion, index: number) => {
                return <LongTextTd text={record.id} />
            },
        }, {
            title: "Category",
            key: "category",
            ellipsis: true,
            width: 150,
            render: (text: any, record: TypePermistion, index: number) => {
                return <LongTextTd text={record.category} />
            },
        }, {
            title: "Description",
            key: "description",
            ellipsis: true,
            width: 200,
            render: (text: any, record: TypePermistion, index: number) => {
                return <LongTextTd text={record.description} />
            },
        }, {
            title: "Creation Time",
            key: "creation_time",
            ellipsis: true,
            width: 150,
            render(text: any, record: TypePermistion, index: number) {
                return record.createdAt ? (
                    <Tooltip title={`Created by ${record.createdBy} at ${moment(record.createdAt).format("DD/MM/YYYY HH:mm:ss")}`}>
                        {moment(record.createdAt).fromNow()}
                    </Tooltip>
                ) : "--/--"
            },
        }, {
            title: "Updated Time",
            width: 150,
            key: "modification_time",
            ellipsis: true,
            render(text: any, record: TypePermistion, index: number) {
                return record.updatedAt ? (
                    <Tooltip title={`Updated by ${record.updatedBy} at ${moment(record.updatedAt).format("DD/MM/YYYY HH:mm:ss")}`}>
                        {moment(record.updatedAt).fromNow()}
                    </Tooltip>
                ) : "--/--"
            },
        }, {
            title: "Action",
            key: "action",
            fixed: "right",
            width: 100,
            render(text: any, record: TypePermistion, index: number) {
                return (
                    <>
                        <Button size="small" type="link" icon="edit" onClick={() => {
                            setVisibleAddUpdate(true)
                            setSelectedRecord(record)
                        }}></Button>

                        <Divider type="vertical" />

                        <Dropdown
                            overlay={
                                <Menu
                                    onClick={(e: ClickParam) => {
                                        if (e.key == "delete") {
                                            Modal.confirm({
                                                title: BIGDATA_CONSTANT.message.default.titleConfirm,
                                                content: <>Are you sure that you want to delete <strong>{record.name}</strong>?</>,
                                                okText: "Delete",
                                                icon: <Icon type="question-circle" style={{ color: getPrimaryColor(uiSetting) }} />,
                                                onOk: async () => {
                                                    await onDelete(record)
                                                }
                                            })
                                        } else if (e.key == "viewjson") {
                                            setVisibleViewJSON(true);
                                            setSelectedRecord(record);
                                        }
                                    }}
                                >
                                    <Menu.Item key='viewjson'>
                                        <Button size='small' type='link' icon='eye' >
                                            View JSON
                                        </Button>
                                    </Menu.Item>

                                    <Menu.Item key='delete'>
                                        <Button size='small' type='link' icon='delete'  >
                                            Delete
                                        </Button>
                                    </Menu.Item>
                                </Menu>
                            }
                        >
                            <Button size='small' type='link' icon='more' />
                        </Dropdown>
                    </>
                )
            },
        }
    ]

    const renderBreadCrumb = () => {
        return (
            <Row type="flex" justify="space-between" align="middle" style={{ marginBottom: -15 }}>
                <Col>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Link to="/">Home</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>Super Admin</Breadcrumb.Item>
                        <Breadcrumb.Item>Permission</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
                <Col>
                    <Button type='primary' icon='plus-circle' onClick={() => {
                        setVisibleAddUpdate(true)
                        setSelectedRecord(undefined)
                    }}>New Permission</Button>
                </Col>
            </Row>
        )
    }

    React.useEffect(() => {
        props.updateMetaTitle({ title: BIGDATA_CONSTANT.trackTitle.SPPermission })
        const timer = setTimeout(() => {
            props.updateMeta({
                name: BIGDATA_CONSTANT.trackTitle.SPPermission,
                title: BIGDATA_CONSTANT.trackTitle.SPPermission,
                app: "ACCOUNT",
                url: getCurrentURL(props.location)
            })
        }, 5000)

        return () => {
            clearTimeout(timer)
        }
    }, [])

    return (
        <DashboardPageLayout  {...props} selectedLeftNav="permission" selectedSubMenu="console-super" >
            <div className="org-page">
                <MetaHeader title={props.ui.meta.title} />
                <div className="contentLayout">
                    {renderBreadCrumb()}
                    <br />
                    <div className="containerInner">
                        <Row gutter={[5, 5]} type='flex'>
                            <Col xs={24}>
                                <Typography.Title level={4}>Permission</Typography.Title>
                            </Col>
                            <Col xs={24}>
                                <FormFilterComponent
                                    onSearch={(values: any) => {
                                        dispatchCriteria({
                                            loading: false,
                                            payload: {
                                                ...criteria.payload,
                                                ...{ page: 0, ...values },
                                            },
                                            type: SEARCH_PERMISSION,
                                        });
                                    }}
                                />
                            </Col>

                            <Col xs={24}>
                                <InfoList
                                    loading={statePersmission.loading}
                                    list={permissionResponse}
                                    refresh={statePersmission.retry}
                                />
                            </Col>

                            <Col xs={24}>
                                <Table
                                    loading={statePersmission.loading}
                                    columns={columns}
                                    dataSource={permissionResponse.results.content}
                                    rowKey="id"
                                    size="small"
                                    pagination={false}
                                    scroll={{ x: 'calc(500px + 50%)' }}
                                />
                            </Col>

                            <Col xs={24}>
                                <O2OCustomPaging
                                    list={permissionResponse}
                                    onChange={(e: number) => {
                                        dispatchCriteria({
                                            loading: false,
                                            payload: { ...criteria.payload, ...({ page: e } as any) },
                                            type: SEARCH_PERMISSION,
                                        });
                                    }}
                                    onChangePageSize={(e: number) => {
                                        dispatchCriteria({
                                            loading: false,
                                            payload: { ...criteria.payload, ...({ page: 0, pageSize: e } as any) },
                                            type: SEARCH_PERMISSION,
                                        });
                                    }}
                                />
                            </Col>

                            <DrawerAddUpdateComponent
                                commonActionFromDALV2={props.commonActionFromDALV2}
                                onClose={() => {
                                    setSelectedRecord(undefined)
                                    setVisibleAddUpdate(false)
                                }}
                                visible={visibleAddUpdate}
                                item={selectedRecord}
                                onSuccess={() => {
                                    setTimeout(() => {
                                        statePersmission.retry()
                                    }, 1000)
                                }}
                            />

                            <DrawerViewJSON
                                data={selectedRecord}
                                onClose={() => {
                                    setSelectedRecord(undefined)
                                    setVisibleViewJSON(false)
                                }}
                                visible={visibleViewJSON}
                            />
                        </Row>
                    </div>

                </div>
            </div>

        </DashboardPageLayout>
    )
}

export default SuperPermissionComponent