import { Button } from 'antd'
import React from 'react'

interface IProps {
    onClick: () => void
}

function ButtonBackDrawer({ onClick }: IProps) {
    return (
        <Button size='small' icon='left-circle' type='link' onClick={onClick}></Button>
    )
}

export default ButtonBackDrawer