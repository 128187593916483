import { includes as _includes } from "lodash"
import { BIGDATA_CONSTANT } from "./constant";
import { TypeUserPermission, UISetting } from 'o2o_layout/lib/common/model';
import { Location } from "history";
import { find as _find, isEmpty as _isEmpty } from "lodash";

import _ from "lodash";
export const BigDataConsole = (message: string, data: any) => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    return console.log(message, data)
  } else {
    return false;
  }
}
export const BigDataDebugger = (): any => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    debugger;
  } else {
    return false;
  }
}

// https://medium.com/trabe/avoid-updates-on-unmounted-react-components-2fbadab17ad2
export const cancelablePromise = (promise: any) => {
  let hasCanceled = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      (value: any) => (hasCanceled ? reject({ isCanceled: true, value }) : resolve(value)),
      (error: any) => reject({ isCanceled: hasCanceled, error }),
    );
  });

  return {
    promise: wrappedPromise,
    cancel: () => (hasCanceled = true),
  };
};

export const serializeObjectToURLParameter = (obj = {}) => {
  return Object.keys(obj).map(function (key) {
    return key + '=' + encodeURIComponent(obj[key]);
  }).join('&');
}

export const canAccess = (permisions: string[], userRoles: string[]) => {
  let flag = false;

  permisions.forEach((per: string) => {
    if (_includes(userRoles, per)) {
      flag = true;
      return;
    }
  });

  return flag
}

export const checkPermission = (permissionCheck: string, userPermission: TypeUserPermission): boolean => {
  if (userPermission && !_isEmpty(userPermission)) {
    let permissions: string[] = userPermission.ctxPermissions ? userPermission.ctxPermissions : [];
    let checkExistPermisson: string | undefined = _find(permissions, (item: string) => item == permissionCheck)
    if (checkExistPermisson) {
      return true
    }
    return false
  }
  return false
}




export const getPrimaryColor = (setting: UISetting) => {
  let defaultColor = localStorage.getItem("mini-dynamic-antd-theme-color") ? localStorage.getItem("mini-dynamic-antd-theme-color") : BIGDATA_CONSTANT.primaryColor
  if (setting && setting.themeColor) {
    return setting.themeColor
  }
  return defaultColor ? defaultColor : BIGDATA_CONSTANT.primaryColor
}



export function hexToRgbA(hex: string, a: number) {
  var c: any;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')},${a})`;
  }
  throw new Error('Bad Hex');
}

export const getUTCGMTOffset = (gmt: number) => {
  if (gmt != undefined) {

    if (gmt >= 0 && gmt < 10) {
      return `+0${gmt}:00`
    } else if (gmt >= 10 && gmt <= 14) {
      return `+${gmt}:00`
    } else if (gmt < 0 && gmt > -10) {
      return `-0${gmt}:00`
    } else if (gmt <= -10) {
      return `-${gmt}:00`
    } else {
      return ("+00:00")
    }
  }
  return ("+00:00")
}


export const getCurrentURL = (location: Location<any>) => {
  const pathName = location.pathname.substring(1)
  const search = location.search
  return `${BIGDATA_CONSTANT.url.apps.REACT_APP_ACCOUNT_APP}${pathName}${search || ""}`
}

const buildParams: any = (prefix: any, val: any, top: boolean) => {
  if (_.isUndefined(top)) top = true;
  if (_.isArray(val)) {
    return _.map(val, function (value, key) {
      return buildParams(top ? key : prefix + '[]', value, false);
    }).join('&');
  } else if (_.isObject(val)) {
    return _.map(val, function (value, key) {
      return buildParams(top ? key : prefix + '[' + key + ']', value, false);
    }).join('&');
  } else {
    return encodeURIComponent(prefix) + '=' + encodeURIComponent(val);
  }
};

/**
* toQuery(_.omitBy({
*  a: "",
*  b: undefined,
*  c: null,
*  d: "d",
*  e: 1
*}, _.isNil))
* @return string query
*/


export const toQuery = (obj: any) => {
  return buildParams('', obj);
}