import { O2OCommonContainer } from "../../../common/container/O2OContainer";
import { IState } from "../../../redux/reducer";
import InternalPermissionComponent from "./InternalPermissionComponent";



function mapStateToProps(rootState: IState) {
    return {

    };
}


function mapDispatchToProps(dispatch: any) {
    return {

    };
}

export const InternalPermissionContainer = O2OCommonContainer(InternalPermissionComponent, mapStateToProps, mapDispatchToProps)