import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { IState } from '../../redux/reducer/index';
import { setGlobalLoading, setGlobalMessage, updateLocale, showSiderMainLayout, commonActionFromDAL, commonActionFromDALV2, updateMeta, updateMetaTitle } from '../actions/CommonAction';
import logo from '../../static/image/logo_fb.png';
import { userLogout, userLogoutAll } from '../../modules/login/actions/LoginAction';
import { getUISettingCtx, saveThemeColor, getUIDataSettingCtx, saveUIData } from '../../modules/setting/action/UISettingAction';


function mapStateToProps(rootState: IState) {
    return {  
        ui: rootState.ui,
        routing: rootState.routing,
        locale: rootState.locale,
        logo: logo,
        user: rootState.user,
        commonResponse: rootState.commonResponse,
        authen: rootState.authen,
        uiSetting: rootState.uiSetting,
        uiDataSetting: rootState.uiDataSetting
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        setGlobalLoading: bindActionCreators(setGlobalLoading, dispatch),
        setMessage: bindActionCreators(setGlobalMessage, dispatch),
        showSiderMainLayout: bindActionCreators(showSiderMainLayout, dispatch),
        updateLocale: bindActionCreators(updateLocale, dispatch),
        userLogout: bindActionCreators(userLogout, dispatch),
        userLogoutAll: bindActionCreators(userLogoutAll, dispatch),
        getUISettingCtx: bindActionCreators(getUISettingCtx, dispatch),
        getUIDataSettingCtx: bindActionCreators(getUIDataSettingCtx, dispatch),
        saveThemeColor: bindActionCreators(saveThemeColor, dispatch),
        saveUIData: bindActionCreators(saveUIData, dispatch),
        commonActionFromDAL: bindActionCreators(commonActionFromDAL, dispatch),
        commonActionFromDALV2: bindActionCreators(commonActionFromDALV2, dispatch),
        updateMeta: bindActionCreators(updateMeta, dispatch),
        updateMetaTitle: bindActionCreators(updateMetaTitle, dispatch)

    };
}



/**
 * Khai báo một số props và các action dùng chung cho các component 
 * UI: chứa các thông tin như trạng thái loading, global message..
 */
export const O2OCommonContainer: any = (component: any, sProps: any, dProps: any) => {
    const container = connect<any, any>(
        mapStateToProps,
        mapDispatchToProps
    )(component)
    return connect<any, any>(
        sProps,
        dProps
    )(container)

}   
