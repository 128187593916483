import { Alert, Button, Col, Form, Icon, Input, Modal, Row, Spin, Typography, message } from 'antd';
import * as React from 'react';
import { UI } from "../../redux/model";


import "./login.scss";

import { FormComponentProps } from 'antd/lib/form';
import Helmet from 'react-helmet';
import { LandingPage } from '../../layout/landing/LandingPage';
// import { Link } from 'react-router-dom'
import { Location } from 'history';
import _ from 'lodash';
import { changeAntdTheme, generateThemeColor } from 'mini-dynamic-antd-theme';
import { TypeStatusUserWhenLoginSuccess, User } from 'o2o_layout/lib/common/model';
import queryString from 'query-string';
import { GoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { FormattedMessage } from 'react-intl';
import { ICancelablePromise } from '../../common/ICanceldPromise';
import { typeGetMe, typeUpdateAuthen } from '../../common/actions/CommonAction';
import { BIGDATA_CONSTANT } from '../../common/constant';
import { cancelablePromise } from '../../common/helper';
import { TypeLangs } from '../../redux/reducer/localeReducer';
import { userIcon } from '../../shares/DMPIcon';
import { Footer } from '../../shares/Footer';
import { typeUserLogin } from './actions/LoginAction';

import OtpInput from 'react-otp-input';
import { createBreakpoint } from 'react-use';
import { UserDAL } from '../../dal/UserDAL';
import IconByteTech from "./../../static/image/logo-pangocdp.jpg";

const useBreakpoint = createBreakpoint({ XL: 1280, L: 768, S: 350 });

export const DivIconByteTech = () => {
    const breakpoint = useBreakpoint();
    return <div style={{
        textAlign: "center",
        marginBottom: breakpoint == "S" ? -20 : -40,
        borderRadius: "50%"
       
    }}>
        <a href='https://bytetech.io' target='_blank'>
            <img src={IconByteTech} style={{ width: 80, borderRadius: "50%" }} />
        </a>
    </div >
}

interface LoginComponentProps extends FormComponentProps {
    getCurrentUser: any,
    ui: UI,
    setGlobalLoading: any,
    setMessage: any,
    locale: any,
    updateLocale: any,
    login: typeUserLogin,
    updateAuthen: typeUpdateAuthen,
    routing: any,
    history: any,
    logo: any,
    match: any,
    companyName: string,
    getMe: typeGetMe,
    user: User,
    userLogin2ft: any,
    location: Location
    commonActionFromDALV2: any
}


interface Confirm2FAProps extends FormComponentProps {
    loading: boolean,
    visible: boolean,
    hideModal: any,
    okModal: any
}

const Confirm2FA = (props: Confirm2FAProps) => {
    const { loading } = props
    const { getFieldDecorator } = props.form
    return (
        <Modal
            title={<React.Fragment><Icon type="key" /> Two-factor authentication</React.Fragment>}
            centered
            visible={props.visible}
            onOk={(e: any) => {
                props.okModal(e, props.form)
            }}
            okButtonProps={{
                disabled: props.form.getFieldValue("code") ? props.form.getFieldValue("code").length == 6 ? false : true : true
            }}
            onCancel={props.hideModal}
            okText="Verify"
        >
            <Spin spinning={loading}>
                <Row type="flex" gutter={[20, 20]}>

                    <Col xs={24}>
                        <Form layout="horizontal" hideRequiredMark colon={false}>

                            <Form.Item label={<span style={{marginLeft: 10}}>Open your authentication app and enter the code for ByteTech</span>}>
                                {getFieldDecorator("code", {
                                    rules: [{
                                        required: true,
                                        message: "Please enter the code"
                                    }]
                                })(
                                    <Input style={{ width: "100%", display: "none" }} placeholder="Open your authentication app and enter the code for ByteTech" />
                                )}
                                <OtpInput
                                    isInputNum
                                    inputStyle="inputStyle"
                                    value={props.form.getFieldValue("code")}
                                    onChange={(otp: any)=>{
                                        props.form.setFieldsValue({code: otp})
                                        if(otp.length == 6){
                                            props.okModal(undefined, props.form)
                                        }
                                    }}
                                    numInputs={6}
                                    separator={<span>-</span>}
                                />

                            </Form.Item>


                        </Form>

                    </Col>
                </Row>
            </Spin>
        </Modal>
    )
}

const Confirm2FAForm = Form.create<Confirm2FAProps>()(Confirm2FA)



class LoginComponentForm extends React.Component<LoginComponentProps, any> implements ICancelablePromise {

    state = {
        loading: false,
        showMessage: false,
        message: '',
        url: '',
        showModalTOTP: false,
        token: "",
        intervalID: 0
    }

    componentDidMount() {
        this.updateLang()
        let query: any = queryString.parse(this.props.location.search);
        if (query && !_.isEmpty(query)) {
            let url: string = query.url
            if (url) {
                this.setState({ url: url })
            }
        }
        changeAntdTheme(
            generateThemeColor(BIGDATA_CONSTANT.primaryColor)
        )
        const intervalID = setInterval(() => {
            this.recapchaloop()
        }, 60 * 1000);
        this.setState({ intervalID: intervalID })
        
    }

    recapchaloop = () => {
        if(!this.capcha)
            return;
        const { executeRecaptcha } = (this.capcha)
        if (!executeRecaptcha) {
            console.log('Recaptcha has not been loaded');
            return;
        }
        executeRecaptcha('register').then((token: any) => {
            this.setState({
                token: token
            })
        })
    }

    updateLang = () => {
        let qString = this.props.location.search;
        const parseQuery = queryString.parse(qString)

        if (parseQuery.lang) {
            // debugger
            const lang: TypeLangs = parseQuery.lang == 'vi' || parseQuery.lang == 'en' ? parseQuery.lang : 'vi';
            this.props.updateLocale(lang)
        }
    }

    successChangeLang = (key: string) => {
        let qString = this.props.location.search;
        const parseQuery = queryString.parse(qString)
        parseQuery.lang = key;
        qString = queryString.stringify(parseQuery);
        this.props.history.push({ pathname: this.props.location.pathname, search: qString })
    }

    pendingPromises: any[] = [];

    componentWillUnmount = () =>{
        this.pendingPromises.map((p: any) => p.cancel());
        clearInterval(this.state.intervalID);

    }
        

    appendPendingPromise = (promise: any) =>
        this.pendingPromises = [...this.pendingPromises, promise];

    removePendingPromise = (promise: any) =>
        this.pendingPromises = this.pendingPromises.filter(p => p !== promise);



    handleSubmit = (e: any) => {
        e.preventDefault();
        this.props.form.validateFields(async (err: any, values: any) => {
            if (!err) {

                this.setState({ loading: true, showMessage: false })
                if (this.state.token) {

                    let ipData;
                    let userAgent = window.navigator.userAgent
                    
                    try {   
                        const ip = await this.props.commonActionFromDALV2(UserDAL.getIP);
                        ipData = await(ip)
                    } catch (error) {
                        
                    }
                
                    const wrappedPromise = cancelablePromise(this.props.login(values.userName, values.password, this.state.token, {
                        "P-IP-ACCESS": ipData ? ipData.data || "" : "",
                        "P-USR-AGENT": userAgent
                    }));
                    this.appendPendingPromise(wrappedPromise);
                    //chổ này cần sửa lại
                    wrappedPromise.promise
                        .then((login: any) => {

                            this.setState({ loading: false, showMessage: false })

                            this.props.getMe()
                                .then(() => {
                                    if (login.success) {
                                        if (login.result.status == "choose_org_ctx" as TypeStatusUserWhenLoginSuccess) {
                                            if (this.state.url) {
                                                this.props.history.push({
                                                    pathname: "/companies/select",
                                                    search: queryString.stringify({
                                                        url: this.state.url,
                                                        lang: queryString.parse(this.props.location.search).lang
                                                    })
                                                })
                                            } else {
                                                this.props.history.push({
                                                    pathname: "/companies/select",
                                                    search: queryString.stringify({
                                                        target: "my-account-info",
                                                        lang: queryString.parse(this.props.location.search).lang
                                                    })
                                                })
                                            }

                                        } else if (login.result.status == "authenticated" as TypeStatusUserWhenLoginSuccess) {
                                            if (this.state.url) {
                                                window.location.href = this.state.url;
                                            } else {
                                                this.props.history.push({
                                                    pathname: "/dashboard",
                                                    search: queryString.stringify({
                                                        lang: queryString.parse(this.props.location.search).lang
                                                    })
                                                })
                                            }

                                        } else if (login.result.status == "totp_required" as TypeStatusUserWhenLoginSuccess) {
                                            this.setState({
                                                showModalTOTP: true
                                            }, () => {

                                            })
                                        }
                                    }else if(!login.success && login.message === "Your captcha is incorect"){
                                        const { executeRecaptcha } = (this.capcha)

                                        if (!executeRecaptcha) {
                                            return;
                                        }
                                        executeRecaptcha('register').then((token: any) => {
                                            this.setState({
                                                token: token
                                            })
                                        })
                                    }



                                })

                        })
                        .then(() => {
                            this.removePendingPromise(wrappedPromise)
                        })
                        .catch(errorInfo => {
                            const { executeRecaptcha } = (this.capcha)

                            if (!executeRecaptcha) {
                                return;
                            }
                            executeRecaptcha('register').then((token: any) => {
                                this.setState({
                                    token: token
                                })
                            })
                            if (!errorInfo.isCanceled) {

                                this.setState({ loading: false, showMessage: true, message: errorInfo.error.data.message })
                            }
                        });
                } else {
                    this.setState({ loading: false, showMessage: true, message: "Capcha is required" })
                }


            } else {
                this.setState({ loading: false, showMessage: false })
            }
        });
    }


    showOrHideAlert = () => this.state.showMessage ? <Form.Item><Alert message={this.state.message} type="error" showIcon /></Form.Item> : null

    render401 = () => {
        if (this.props.ui.errorStatus == 401) {
            // debugger
            if (this.props.match.path !== '/') {
                return <Form.Item><Alert message="Your session has expired" type="warning" showIcon /></Form.Item>
            }
        }
        return null
    }

    renderTheme() {
        const { getFieldDecorator } = this.props.form;
        return (
            <div >
                <div >
                    <LandingPage {...this.props} showHeader={false} >
                        <div>
                            <Helmet>
                                <title>My Account - ByteTech - Sign in Page</title>
                                <meta name="description" content="My Account - ByteTech - Login Page" />
                            </Helmet>

                            <DivIconByteTech />

                            <Form autoComplete='off' onSubmit={this.handleSubmit} className="landing-form login-form-acc" colon={false} hideRequiredMark={true}>
                                <Form.Item>
                                    <Typography.Title className="o2o-title-gray " style={{ fontSize: 26 }}>
                                        Sign in
                                    </Typography.Title>
                                </Form.Item>
                                {
                                    this.render401()
                                }
                                <Form.Item label="Username">
                                    {getFieldDecorator('userName', {
                                        rules: [{ required: true, message: this.props.locale.message["app.login.username.required"] }],
                                    })(
                                        <Input prefix={<Icon component={userIcon} style={{ color: 'rgba(0,0,0,.5)' }} />} placeholder="your-email@domain.com" autoComplete="off"/>
                                    )}
                                </Form.Item>
                                <Form.Item label="Password">
                                    {getFieldDecorator('password', {
                                        rules: [{ required: true, message: this.props.locale.message["app.login.password.required"] }],
                                    })(
                                        <Input.Password prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.5)' }} />} type="password" placeholder="************" autoComplete="off"/>
                                    )}
                                </Form.Item>
                                <Form.Item>
                                    <GoogleReCaptcha
                                        
                                        action="register" onVerify={(token: any) => {
                                            this.setState({
                                                token: token
                                            })
                                        }} />
                                </Form.Item>
                               
                                <Form.Item>

                                    <Button
                                        htmlType="submit"
                                        type="primary"
                                        block
                                        icon=""
                                        loading={this.state.loading}
                                        disabled={!this.state.token}
                                    >
                                        <FormattedMessage id="app.login.button.logins"
                                            defaultMessage="Sign In" />
                                    </Button>

                                </Form.Item>

                                {this.showOrHideAlert()}



                                <Form.Item>
                                    <Typography.Text>Don't have an account? <a href="https://pangocdp.com/vi/lien-he/" target='_blank'>Contact Us</a></Typography.Text>
                                </Form.Item>

                            </Form>
                        </div>
                        <br />


                        <Confirm2FAForm
                            hideModal={() => {
                                this.setState({
                                    showModalTOTP: false
                                })
                                const { executeRecaptcha } = (this.capcha)

                                        if (!executeRecaptcha) {
                                            return;
                                        }
                                        executeRecaptcha('register').then((token: any) => {
                                            this.setState({
                                                token: token
                                            })
                                        })
                            }}
                            loading={this.props.ui.isLoading}
                            okModal={(e: any, form: any) => {
                                
                                if(e){
                                    e.preventDefault()
                                }
                                form.validateFields((err: any, values: any) => {
                                    if (err) {
                                        return;
                                    }
                                    this.props.setGlobalLoading(true)
                                    this.props.userLogin2ft(values.code, this.props.user.id)
                                        .then((e: any) => {
                                            this.props.setGlobalLoading(false)
                                            if (e.success) {
                                                if (e.result.status == "choose_org_ctx") {
                                                    if (this.state.url) {
                                                        this.props.history.push({
                                                            pathname: "/companies/select",
                                                            search: queryString.stringify({
                                                                url: this.state.url,
                                                                lang: queryString.parse(this.props.location.search).lang
                                                            })
                                                        })
                                                    } else {
                                                        this.props.history.push({
                                                            pathname: "/companies/select",
                                                            search: queryString.stringify({
                                                                lang: queryString.parse(this.props.location.search).lang
                                                            })
                                                        })
                                                    }
                                                } else {
                                                    if (this.state.url) {
                                                        window.location.href = this.state.url;
                                                    } else {
                                                        this.props.history.push({
                                                            pathname: "/dashboard",
                                                            search: queryString.stringify({
                                                                lang: queryString.parse(this.props.location.search).lang
                                                            })
                                                        })
                                                    }
                                                }

                                            } else {
                                                message.warning(e.message ? e.message : "Invalid code")
                                            }
                                        })
                                        .catch(() => {
                                            this.props.setGlobalLoading(false)
                                            message.warning("Invalid code")
                                        })

                                })
                            }}

                            visible={this.state.showModalTOTP
                            }
                        />

                        <Footer />
                    </LandingPage>
                </div>




            </div>
        )
    }

    capcha: any
    render() {
        return (
            <GoogleReCaptchaProvider
                reCaptchaKey={BIGDATA_CONSTANT.reCapchaSiteKey}
                language={this.props.locale}
                ref={(e: any) => {
                    this.capcha = e
                }}
            >
                {this.renderTheme()}
            </GoogleReCaptchaProvider>
        );
    }
}

export const LoginComponent = Form.create<LoginComponentProps>()(LoginComponentForm);

