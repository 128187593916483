import React from 'react'
import { Select, Spin } from 'antd'
import { WrappedFormUtils } from 'antd/lib/form/Form'
import _ from 'lodash'
import { O2OFormItem } from '../../../shares/O2OFormItem'
import { DistinctFields } from './model'
import { ExportsHistoryDAL } from './DAL'

interface Iprops {
    commonActionFromDALV2: any
    label: string
    keyField: string
    form: WrappedFormUtils<any>
    placeholder: string
    type: string
}
const DistinctFieldComponent = (props: Iprops) => {
    const [distinctFields, setDistinctFields] = React.useState<DistinctFields>(new DistinctFields())
    const [loading, setLoading] = React.useState(false as boolean)

    const loadDistinctByField = async () => {
        setLoading(true)
        const result = await props.commonActionFromDALV2(ExportsHistoryDAL.distinct, props.type)
        try {
            const response = await result
            setLoading(false)

            if (response.success) {
                setDistinctFields(response)
            } else {
                setDistinctFields(new DistinctFields())
            }
        } catch (error) {
            setLoading(false)

            setDistinctFields(new DistinctFields())
        }
    }

    React.useEffect(() => {
        loadDistinctByField()
    }, [])

    return (
        <Spin spinning={loading}>
            <O2OFormItem label={props.label} colon={false}>
                {props.form.getFieldDecorator(props.keyField, {

                })(
                    <Select
                        showArrow
                        mode='multiple'
                        maxTagCount={1}
                        allowClear
                        loading={loading}
                        placeholder={props.placeholder}
                        showSearch
                        filterOption={(input: any, option: any) => {
                            return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }}
                        optionFilterProp="children"

                    >

                        {distinctFields.success && distinctFields.result.results[props.type] ? Object.keys(distinctFields.result.results[props.type]).map((item: string) => {
                            return (
                                <Select.Option key={item} value={item} >
                                    {`${item} (${distinctFields.result.results[props.type][item]})`}
                                </Select.Option>
                            )
                        }) : null}
                    </Select>
                )}
            </O2OFormItem>
        </Spin>
    )
}

export default DistinctFieldComponent