import { Alert, Button, Col, Divider, Form, Input, Modal, Row, Tooltip } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import React, { useState } from "react";
import { useAsyncRetry, useDebounce } from 'react-use';
import { O2OFormItem } from '../../../shares/O2OFormItem';
import { ConsoleUsersDAL } from '../ConsoleUserDAL';
import { PolicyPasswordCheck } from './ChangePassword';



interface Props {
    commonActionFromDAL: any
    form: WrappedFormUtils<any>
    errors: any
    visible?: boolean
}


export const CheckPolicyPasswordInput = (props: Props) => {


    const [q, setQ] = React.useState(undefined as undefined | string);

    const [loadingGeneratePassword, setLoadingGeneratePassword] = useState(false)
    const [showModal, setShowModal] = useState(false)

    const { getFieldDecorator, setFieldsValue, getFieldValue, resetFields } = props.form

    const password = getFieldValue("password")

    const statePolicyPasswword = useAsyncRetry(async () => {
        if (q) {
            const data = await loadPolicyPassword();
            props.errors(data.result.errors)
            return data
        }
        return new PolicyPasswordCheck()
    }, [q])

    const loadPolicyPassword = async () => {
        const result: Promise<PolicyPasswordCheck> = props.commonActionFromDAL(ConsoleUsersDAL, "checkPasswordPolicy", q)
        try {
            const payload: PolicyPasswordCheck = await result;
            return payload
        } catch (error) {
            return new PolicyPasswordCheck()
        }
    }

    const [,] = useDebounce(
        () => {
            if (password != undefined) {
                setQ(password)
            }
        },
        500,
        [password]
    );

    const renderError = () => {
        if (statePolicyPasswword.value && statePolicyPasswword.value.success && password) {
            if (statePolicyPasswword.value.result.errors && statePolicyPasswword.value.result.errors.length) {
                return (
                    <Form.Item>
                        <Alert
                            message=""
                            description={
                                <ul>
                                    {statePolicyPasswword.value.result.errors.map((item: string) => <li>{item}</li>)}
                                </ul>
                            }
                            type="error"
                        />
                    </Form.Item>
                )
            }
            return null
        }
        return null
    }

    const renderLevel = () => {
        if (statePolicyPasswword.value && statePolicyPasswword.value.success && password) {
            if (statePolicyPasswword.value.result.level == "Low") {
                return (
                    <Row gutter={[5, 5]} style={{ marginTop: 5 }}>
                        <Col xs={6}>
                            <Tooltip title="Low">
                                <div style={{
                                    background: "#FF445E",
                                    width: "100%",
                                    height: 8,
                                    borderRadius: 10
                                }}></div>
                            </Tooltip>

                        </Col>
                        <Col xs={6}>
                            <Tooltip title="Fair">
                                <div style={{
                                    background: "#d9d9d9",
                                    width: "100%",
                                    height: 8,
                                    borderRadius: 10
                                }}></div>
                            </Tooltip>
                        </Col>
                        <Col xs={6}>
                            <Tooltip title="Good">
                                <div style={{
                                    background: "#d9d9d9",
                                    width: "100%",
                                    height: 8,
                                    borderRadius: 10
                                }}></div>
                            </Tooltip>
                        </Col>
                        <Col xs={6}>
                            <Tooltip title="Strong">
                                <div style={{
                                    background: "#d9d9d9",
                                    width: "100%",
                                    height: 8,
                                    borderRadius: 10
                                }}></div>
                            </Tooltip>
                        </Col>

                    </Row>

                )
            } else if (statePolicyPasswword.value.result.level == "Fair") {
                return (
                    <Row gutter={[5, 5]} style={{ marginTop: 5 }}>
                        <Col xs={6}>
                            <Tooltip title="Low">
                                <div style={{
                                    background: "#FF445E",
                                    width: "100%",
                                    height: 8,
                                    borderRadius: 10
                                }}></div>
                            </Tooltip>

                        </Col>
                        <Col xs={6}>
                            <Tooltip title="Fair">
                                <div style={{
                                    background: "rgb(255 166 44)",
                                    width: "100%",
                                    height: 8,
                                    borderRadius: 10
                                }}></div>
                            </Tooltip>
                        </Col>
                        <Col xs={6}>
                            <Tooltip title="Good">
                                <div style={{
                                    background: "#d9d9d9",
                                    width: "100%",
                                    height: 8,
                                    borderRadius: 10
                                }}></div>
                            </Tooltip>
                        </Col>
                        <Col xs={6}>
                            <Tooltip title="Strong">
                                <div style={{
                                    background: "#d9d9d9",
                                    width: "100%",
                                    height: 8,
                                    borderRadius: 10
                                }}></div>
                            </Tooltip>
                        </Col>

                    </Row>

                )
            } else if (statePolicyPasswword.value.result.level == "Good") {
                return (
                    <Row gutter={[5, 5]} style={{ marginTop: 5 }}>
                        <Col xs={6}>
                            <Tooltip title="Low">
                                <div style={{
                                    background: "#FF445E",
                                    width: "100%",
                                    height: 8,
                                    borderRadius: 10
                                }}></div>
                            </Tooltip>

                        </Col>
                        <Col xs={6}>
                            <Tooltip title="Fair">
                                <div style={{
                                    background: "rgb(255 166 44)",
                                    width: "100%",
                                    height: 8,
                                    borderRadius: 10
                                }}></div>
                            </Tooltip>
                        </Col>
                        <Col xs={6}>
                            <Tooltip title="Good">
                                <div style={{
                                    background: "rgb(45, 183, 245)",
                                    width: "100%",
                                    height: 8,
                                    borderRadius: 10
                                }}></div>
                            </Tooltip>
                        </Col>
                        <Col xs={6}>
                            <Tooltip title="Strong">
                                <div style={{
                                    background: "#d9d9d9",
                                    width: "100%",
                                    height: 8,
                                    borderRadius: 10
                                }}></div>
                            </Tooltip>
                        </Col>

                    </Row>

                )
            } else if (statePolicyPasswword.value.result.level == "Strong") {
                return (
                    <Row gutter={[5, 5]} style={{ marginTop: 5 }}>
                        <Col xs={6}>
                            <Tooltip title="Low">
                                <div style={{
                                    background: "#FF445E",
                                    width: "100%",
                                    height: 8,
                                    borderRadius: 10
                                }}></div>
                            </Tooltip>

                        </Col>
                        <Col xs={6}>
                            <Tooltip title="Fair">
                                <div style={{
                                    background: "rgb(255 166 44)",
                                    width: "100%",
                                    height: 8,
                                    borderRadius: 10
                                }}></div>
                            </Tooltip>
                        </Col>
                        <Col xs={6}>
                            <Tooltip title="Good">
                                <div style={{
                                    background: "rgb(45, 183, 245)",
                                    width: "100%",
                                    height: 8,
                                    borderRadius: 10
                                }}></div>
                            </Tooltip>
                        </Col>
                        <Col xs={6}>
                            <Tooltip title="Strong">
                                <div style={{
                                    background: "rgb(135, 208, 104)",
                                    width: "100%",
                                    height: 8,
                                    borderRadius: 10
                                }}></div>
                            </Tooltip>
                        </Col>

                    </Row>

                )
            }
        }
        return null
    }

    const policyP = statePolicyPasswword && statePolicyPasswword.value ? statePolicyPasswword.value : new PolicyPasswordCheck();

    const generatePassword = async () => {
        try {
            setLoadingGeneratePassword(true)
            setShowModal(false)
            const { result, success } = await props.commonActionFromDAL(ConsoleUsersDAL, "generatePassword")
            if (success) {
                setFieldsValue({
                    password: result
                })
            }

        } catch (error) {
            console.log(error)
        } finally {
            setLoadingGeneratePassword(false)
        }
    }

    React.useEffect(() => {
        if (!props.visible) {
            setQ(undefined)
            resetFields()
        }
    }, [props.visible])

    const renderModal = () => {
        return (
            <Modal
                title="Generate Password"
                visible={showModal}
                onOk={generatePassword}
                onCancel={() => setShowModal(false)}
                confirmLoading={loadingGeneratePassword}
            >
                <span>A new password will be generated and override your input.</span>
            </Modal>
        )
    }

    const renderTheme = () => {


        let valid = (policyP.result.errors.length ? true : false)

        console.log(valid)
        return (
            <React.Fragment>

                <O2OFormItem help="" label={
                    <span>
                        Password
                        <Divider type="vertical" />
                        <Button type='link' size='small' style={{ paddingLeft: 0 }} onClick={() => setShowModal(true)}>Generate</Button>
                    </span>
                }>
                    {getFieldDecorator('password', {
                        rules: [
                            { required: true, message: "Please enter password" },
                        ],
                    })(<Input.Password autoComplete="new-password" placeholder="**************" />)}
                    {renderLevel()}
                </O2OFormItem>


                {renderError()}
                {renderModal()}

            </React.Fragment>
        )
    }
    return (
        renderTheme()
    );
}
