import { isNil, omitBy } from 'lodash';
import { IResponseBigDataVN, TypeResultsBigData } from 'o2o_layout/lib/common/interface';
import { TypeOrganization } from 'o2o_layout/lib/common/model';
import { Methods, RestClient } from 'o2o_layout/lib/common/rest';
import { BIGDATA_CONSTANT } from '../../../common/constant';
import { toQuery } from '../../../common/helper';

export type TypeInvationUser = {
    "actionAt"?: number,
    "createdAt"?: number,
    "expiredAt"?: number,
    "id"?: string,
    "invitedBy"?: string
    "orgId"?: string
    "roles"?: string[]
    "status"?: string
    "userId"?: string
    "userIdOrgIdKey"?: string
    reason?: string
    joinedOrgExpiredAt?: number
}
export class InvationUsers implements IResponseBigDataVN<TypeInvationUser>{
    organizations: { [key: string]: TypeOrganization }
    success: boolean;
    results: TypeResultsBigData<TypeInvationUser>;
    constructor() {
        this.results = {
            content: [],
            timeTaken: 0,
            currentTime: 0,
            page: {
                pageSize: 0,
                currentPage: 0,
                totalRecords: 0,
                totalPages: 0
            },
            extras: null
        }
        this.success = false
    }
}


class DAL {


    searchAdminInvations() {
        let url = BIGDATA_CONSTANT.url.api + `mngmnt-client/accounts/invitation/join-orgs/list`

        var restClient = new RestClient(Methods.GET, url);

        return new Promise((resolve, reject) => {
            restClient.execute({}, {}).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }

    searchInvationsClientAdmin(body: any) {
        const q = toQuery(omitBy({
            page: body.page || 0,
            pageSize: body.pageSize || 10
        }, isNil))
        let url = BIGDATA_CONSTANT.url.api + `accounts/users/current/invitation/join-orgs/_search?${q}`
        var restClient = new RestClient(Methods.POST, url);

        return new Promise((resolve, reject) => {
            restClient.execute({}, body).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }

    searchInvations(dto: any) {
        const q = toQuery(omitBy({
            page: dto.page || 0,
            pageSize: dto.pageSize || 10
        }, isNil))
        let url = BIGDATA_CONSTANT.url.api + `mngmnt-client/accounts/invitation/join-orgs/search?${q}`

        var restClient = new RestClient(Methods.POST, url);

        return new Promise((resolve, reject) => {
            restClient.execute({}, dto).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }

    adminAddUserToOrg(dto: any) {
        let url = BIGDATA_CONSTANT.url.api + `mngmnt-client/accounts/invitation/join-orgs/add`;
        var restClient = new RestClient(Methods.POST, url);
        return new Promise((resolve, reject) => {
            restClient.execute({}, dto).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }

    adminRemoveUserFromOrg(id: string) {
        let url = BIGDATA_CONSTANT.url.api + `mngmnt-client/accounts/invitation/join-orgs/delete/_by_id?id=${id}`;
        var restClient = new RestClient(Methods.POST, url);
        return new Promise((resolve, reject) => {
            restClient.execute({}, {}).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }

    rejectInvitation(invitationId: string) {
        let url = BIGDATA_CONSTANT.url.api + `accounts/users/current/invitation/join-orgs/_reject?invitationId=${invitationId}`;
        var restClient = new RestClient(Methods.POST, url);
        return new Promise((resolve, reject) => {
            restClient.execute({}, {}).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }
    acceptInvitation(invitationId: string) {
        let url = BIGDATA_CONSTANT.url.api + `accounts/users/current/invitation/join-orgs/_accept?invitationId=${invitationId}`;
        var restClient = new RestClient(Methods.POST, url);
        return new Promise((resolve, reject) => {
            restClient.execute({}, {}).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }



}

export const InvationUserDAL = new DAL()
