import * as React from 'react';
import { Breadcrumb, Row, Layout, Empty, Icon, List, Spin, Typography, Drawer, Col, Badge, Divider } from 'antd';
import { Link } from 'react-router-dom';
import CommonTypeProps from '../../../common/CommonTypeProps';
import { DashboardPageLayout } from '../../../layout/dashboard/DashboardPageLayout';
import _ from 'lodash';
import { NotiMessages, TypeCriteriaSearchNotifications, TypeMessage } from 'o2o_layout/lib/common';
import { UserDAL } from '../../../dal/UserDAL';
import moment from 'moment';
import ReactJson from 'react-json-view';
import { O2OCustomPaging } from 'o2o_layout';
import { InfoList } from '../../../shares/InfoList';
import { UISetting } from 'o2o_layout/lib/common/model';
import { BIGDATA_CONSTANT } from '../../../common/constant';
import { getCurrentURL } from '../../../common/helper';
import { MetaHeader } from '../../../shares/MetaHeader';


interface Props extends CommonTypeProps {

}


const ListItemNoti = (props: {
    item: TypeMessage
    commonActionDAL: any
    renderIconMessageLevel: any
    success: any
    userDAL: any
    uiSetting: UISetting
}) => {
    const { item, renderIconMessageLevel } = props
    const [loading, setLoading] = React.useState(false)
    const markRead = () => {
        setLoading(true)
        props.commonActionDAL(props.userDAL, "markReadMessage", item.id)
            .then(() => {
                props.success(item)
                setLoading(false)
            })
            .catch(() => {
                setLoading(false)
            })
    }
    return (
        loading ? (
            <List.Item style={{}} >
                <List.Item.Meta title={
                    <span> <Icon spin={loading} type="sync" /> {item.title} </span>
                } description={
                    <div>
                        <Typography.Paragraph style={{ marginBottom: 0 }} ellipsis={true}>
                            {item.message}
                        </Typography.Paragraph>
                        <small>{moment(item.createdAt).fromNow()}</small>
                    </div>

                } />
            </List.Item>
        ) : (
                <List.Item style={{ cursor: "pointer" }} onClick={() => {
                    markRead()
                }} actions={[
                    item.status == "New" ? <Badge color="#0980EC" dot /> : null
                ]}>
                    <List.Item.Meta title={
                        <span>{renderIconMessageLevel(item)} {item.title} </span>
                    } description={
                        <div>
                            <Typography.Paragraph style={{ marginBottom: 0 }} ellipsis={true}>
                                {item.message}
                            </Typography.Paragraph>
                            <small>{moment(item.createdAt).fromNow()}</small>
                        </div>

                    } />

                </List.Item>
            )

    )
}

export const NotificationComponent = (props: Props) => {

    const [loading, setLoading] = React.useState(false)
    const [criteria, setCriteria] = React.useState({ pageSize: 10 } as TypeCriteriaSearchNotifications)
    const [notiMessage, setNotiMessage] = React.useState(new NotiMessages())

    const [selectedNoti, setSelectedNoti] = React.useState(null as TypeMessage | null)
    const [visibleDetail, setVisibleDetail] = React.useState(false)

    React.useEffect(() => {
        load()
        let timer = setTimeout(() => {
            props.updateMeta({
                name: BIGDATA_CONSTANT.trackTitle.Notification,
                title: BIGDATA_CONSTANT.trackTitle.Notification,
                app: "ACCOUNT",
                url: getCurrentURL(props.location)
              })
            clearTimeout(timer)
        }, 5000)
        return () => {
            clearTimeout(timer)
        }
    }, [])

    const load = () => {
        setLoading(true)
        props.commonActionFromDAL(UserDAL, "getMessages", criteria)
            .then((e: any) => {
                setLoading(false)
                if (e.success) {
                    setNotiMessage(e)
                } else {
                    setNotiMessage(new NotiMessages())
                }
            })
            .catch(() => {
                setLoading(false)
                setNotiMessage(new NotiMessages())
            })
    }

    const renderIconMessageLevel = (item: TypeMessage) => {
        switch (item.messageLevel) {
            case "success":
                return <Icon style={{ color: "#52c41a" }} type="check-circle" />
            case "info":
                return <Icon style={{ color: "#1890ff" }} type="info-circle" />
            case "warning":
                return <Icon style={{ color: "#faad14" }} type="warning" />
            case "error":
                return <Icon style={{ color: "#f5222d" }} type="close-circle" />
            default:
                return null
        }
    }

    const renderNoti = () => {
        return (
            <Row>
                <Col xs={24}>

                    <InfoList
                        list={notiMessage}
                        refresh={load}

                     />
                     <Divider/>
                </Col>

                <Col xs={24}>
                    <List size="small" dataSource={notiMessage.results.content} pagination={false} renderItem={(item: TypeMessage) => {
                        return (
                            <List.Item style={{ position: "relative" }}>

                                <ListItemNoti
                                    userDAL={UserDAL}
                                    success={(item: TypeMessage) => {
                                        setSelectedNoti(item)
                                        setVisibleDetail(true)
                                        setTimeout(() => {
                                            load()
                                        }, 1000);
                                    }}
                                    item={item}
                                    uiSetting={props.uiSetting}
                                    commonActionDAL={props.commonActionFromDAL}
                                    renderIconMessageLevel={renderIconMessageLevel} />

                            </List.Item>
                        )
                    }}>

                    </List>
                </Col>
                <Col xs={24}>

                    <O2OCustomPaging 
                        list={notiMessage}
                        onChange={(e: number)=>{
                            setCriteria( Object.assign(criteria, {page: e} as TypeCriteriaSearchNotifications) )
                            load()
                        }}
                        onChangePageSize={(e: number)=>{
                            setCriteria( Object.assign(criteria, {pageSize: e} as TypeCriteriaSearchNotifications) )
                            load()
                        }}
                    ></O2OCustomPaging>
                </Col>
            </Row>

        )
    }


    const renderBreadCrumb = () => {
        return (
            <Row type="flex" justify="space-between" align="middle" style={{ marginTop: 20 }}>
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/">Home</Link>
                    </Breadcrumb.Item>


                    <Breadcrumb.Item>Notification</Breadcrumb.Item>
                </Breadcrumb>

            </Row>

        )
    }

    const renderTheme = () => {


        return (
            <DashboardPageLayout  {...props} selectedLeftNav="" selectedSubMenu="" >
                <div className="org-page">

                    

                    <MetaHeader title={props.ui.meta.title} />

                    <div className="contentLayout">
                        {renderBreadCrumb()}
                        <br />
                        <div className="containerInner">
                            <Layout>
                                <Layout.Content>
                                    <Spin spinning={loading} size={'small'} >
                                        <Row type="flex">
                                            <Col xs={24} lg={12} md={20}>
                                                {notiMessage.results.content.length ? (
                                                    renderNoti()
                                                ) : <Empty />}
                                            </Col>

                                        </Row>

                                    </Spin>
                                </Layout.Content>
                            </Layout>
                        </div>
                    </div>

                    <Drawer width={500} title={selectedNoti ? (
                        <React.Fragment>{renderIconMessageLevel(selectedNoti)} {selectedNoti.title}</React.Fragment>
                    ) : "Notification"}
                        visible={visibleDetail}
                        onClose={() => {
                            setVisibleDetail(false)
                            setSelectedNoti(null)
                        }}
                    >
                        {selectedNoti ? (
                            <div>

                                <p>
                                    {selectedNoti.message}
                                </p>
                                <ReactJson collapsed={true} src={selectedNoti} />
                            </div>

                        ) : null}

                    </Drawer>

                </div>
            </DashboardPageLayout>
        )
    }

    return (
        renderTheme()
    );
}
