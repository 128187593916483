import { IState } from '../../../redux/reducer/index';
import { O2OCommonContainer } from '../../../common/container/O2OContainer';
import { PermissionGroupComponent } from './PerminssionGroupComponent';
import CommonTypeProps from '../../../common/CommonTypeProps';
import { Permissions, PermissionGroups } from './model/permissions';
import { bindActionCreators } from 'redux';
import { searchPermission, createPermissionGroup, searchPermissionGroup, updatePermissionGroup, deletePermissionGroup } from './action/PermissionAction';

function mapStateToProps(rootState: IState) {
    return {
        permissions: rootState.permissions,
        permissionGroups: rootState.permissionGroups
    };
}


function mapDispatchToProps(dispatch: any) {
    return {
        searchPermission: bindActionCreators(searchPermission, dispatch),
        createPermissionGroup: bindActionCreators(createPermissionGroup, dispatch),
        searchPermissionGroup: bindActionCreators(searchPermissionGroup, dispatch),
        updatePermissionGroup: bindActionCreators(updatePermissionGroup, dispatch),
        deletePermissionGroup: bindActionCreators(deletePermissionGroup, dispatch)
    };
}

export interface PermissionGroupsProps extends CommonTypeProps {
    permissions: Permissions,
    permissionGroups: PermissionGroups,
    searchPermission: any,
    createPermissionGroup: any,
    searchPermissionGroup: any,
    updatePermissionGroup: any,
    deletePermissionGroup: any
}

export const PermissionGroupContainer = O2OCommonContainer(PermissionGroupComponent, mapStateToProps, mapDispatchToProps)