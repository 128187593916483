import { Alert, Button, Col, Divider, Form, Icon, Row, Select, Tooltip, notification } from "antd"
import _ from "lodash"
import numeral from "numeral"
import { CommontDrawerWithForm, ICommPropsDrawerWithForm } from "o2o_layout"
import { Methods } from "o2o_layout/lib/common/rest"
import * as React from "react"
import { BIGDATA_CONSTANT } from "../common/constant"
import { O2OFormItem } from "./O2OFormItem"
import { CommonDAL } from "./dal/CommonDAL"
import { CommonNotificationSaveError, CommonNotificationSaveSuccessFully } from "./CommonNotification"

type TYPE_EXPORT_DATA = 'organization_SpAdmin' | 'account_SpAdmin' | 'account_Admin'



interface IProps {
    visible: boolean
    onClose: any
    totalRecords: number
    commonActionDALV2: any
    criteria: Object
    disableButton: boolean
    type: TYPE_EXPORT_DATA
}

export const DrawerExportDataWithout2FA = (props: IProps) => {
    const [loading, setLoading] = React.useState(false)


    const onExport = ({ form }: ICommPropsDrawerWithForm) => {
        setLoading(true)
        form.validateFields(async (err: any, values: any) => {
            if (!err) {
                try {
                    let data
                    if (props.type === 'organization_SpAdmin') {
                        data = props.commonActionDALV2(CommonDAL.buildURL,
                            BIGDATA_CONSTANT.url.api + `mngmnt/organizations/export/_by_criteria?timezone=${values["timezone"]}`,
                            props.criteria,
                            Methods.POST
                        )
                    } else if (props.type === "account_SpAdmin")  {
                        data = props.commonActionDALV2(CommonDAL.buildURL,
                            BIGDATA_CONSTANT.url.api + `mngmnt/accounts/export/_by_criteria?timezone=${values["timezone"]}`,
                            props.criteria,
                            Methods.POST
                        )
                    } else if (props.type === 'account_Admin')  {
                        data = props.commonActionDALV2(CommonDAL.buildURL,
                            BIGDATA_CONSTANT.url.api + `mngmnt-client/accounts/export/_by_criteria?timezone=${values["timezone"]}`,
                            props.criteria,
                            Methods.POST
                        )
                    }
                    else {
                        CommonNotificationSaveError()
                        return 
                    }
                    const result: any = await data;
                    setLoading(false)
                    if (result && result.success) {
                        CommonNotificationSaveSuccessFully('The export job is running. A download link will be sent to your email when the job is done.')
                        setTimeout(() => {
                            props.onClose()
                        }, 1000);
                    } else {
                        notification.error({
                            message: "Error",
                            description: _.get(result, "message", "Export failed.")
                        })
                    }
                } catch (error) {
                    notification.error({
                        message: "Error",
                        description: _.get(error, "message", "Export failed.")
                    })
                }
            }
        })

    }



    return (<CommontDrawerWithForm
        visible={props.visible}
        onClose={props.onClose}
        title="Export Confirm"
        width={600}
        content={(propsF: ICommPropsDrawerWithForm) => {
            return <Form colon={false} hideRequiredMark={true}>
                <Row gutter={[5, 5]}>
                    <Col xs={24}>
                        <b>PangoCDP</b> will process to export the data following your criteria (<b>{numeral(props.totalRecords).format("0,0")}</b> records).
                        <br />
                        The downloaded link will be sent to your email after the process is completed
                        {props.disableButton &&
                            <>
                                <Divider type="horizontal" />
                                <Alert
                                    message="* Maximum 1,000,000 records will be exported. Adjust your criteria to get your expected data."
                                    type="warning"
                                    closable
                                />
                            </>
                        }
                    </Col>

                    <Col xs={24}>
                        <O2OFormItem help={<>
                            Timezone applies to timestamp fields <Tooltip title={<>
                                With timestamp field, system will convert to date text
                                <div>format (2023-09-24 14:00:00)</div>
                            </>}>
                                <Icon type="question-circle" />
                            </Tooltip>
                        </>} label="Timezone">
                            {propsF.form.getFieldDecorator("timezone", {
                                initialValue: '7',
                            })(<Select allowClear showSearch placeholder="Select" showArrow >
                                <Select.Option value={"-12"}>GMT -12:00</Select.Option>
                                <Select.Option value={"-11"}>GMT -11:00</Select.Option>
                                <Select.Option value={"-10"}>GMT -10:00</Select.Option>
                                <Select.Option value={"-9"}>GMT -9:00</Select.Option>
                                <Select.Option value={"-8"}>GMT -8:00</Select.Option>
                                <Select.Option value={"-7"}>GMT -7:00</Select.Option>
                                <Select.Option value={"-6"}>GMT -6:00</Select.Option>
                                <Select.Option value={"-5"}>GMT -5:00</Select.Option>
                                <Select.Option value={"-4"}>GMT -4:00</Select.Option>
                                <Select.Option value={"-3"}>GMT -3:00</Select.Option>
                                <Select.Option value={"-2"}>GMT -2:00</Select.Option>
                                <Select.Option value={"-1"}>GMT -1:00</Select.Option>
                                <Select.Option value={"0"}>GMT +00:00</Select.Option>
                                <Select.Option value={"1"}>GMT +1:00</Select.Option>
                                <Select.Option value={"2"}>GMT +2:00</Select.Option>
                                <Select.Option value={"3"}>GMT +3:00</Select.Option>
                                <Select.Option value={"4"}>GMT +4:00</Select.Option>
                                <Select.Option value={"5"}>GMT +5:00</Select.Option>
                                <Select.Option value={"6"}>GMT +6:00</Select.Option>
                                <Select.Option value={"7"}>GMT +7:00</Select.Option>
                                <Select.Option value={"8"}>GMT +8:00</Select.Option>
                                <Select.Option value={"9"}>GMT +9:00</Select.Option>
                                <Select.Option value={"10"}>GMT +10:00</Select.Option>
                                <Select.Option value={"11"}>GMT +11:00</Select.Option>
                                <Select.Option value={"12"}>GMT +12:00</Select.Option>
                                <Select.Option value={"13"}>GMT +13:00</Select.Option>
                                <Select.Option value={"14"}>GMT +14:00</Select.Option>
                            </Select>)}
                        </O2OFormItem>
                    </Col>

                </Row>


            </Form>
        }}
        contentButton={(form: ICommPropsDrawerWithForm) => {
            return <>
                <Button disabled={props.disableButton} loading={loading} type="primary" onClick={() => {
                    onExport(form)
                }}>
                    Export
                </Button>

            </>
        }}
    />)

}