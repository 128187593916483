import CommonTypeProps from "../../common/CommonTypeProps";
import { O2OCommonContainer } from "../../common/container/O2OContainer";
import { IState } from "../../redux/reducer";
import { SystemSettingComponent } from "./SystemSettingComponent";


function mapStateToProps(rootState: IState) {
    return {  
       
    };
}


function mapDispatchToProps(dispatch: any) {
    return {
       
    };
}


export interface IPropsSystemSettingComponent extends CommonTypeProps {
}

export const SystemSettingContainer = O2OCommonContainer(SystemSettingComponent, mapStateToProps, mapDispatchToProps)