import { O2OCommonContainer } from "../../../common/container/O2OContainer";
import { IState } from "../../../redux/reducer";
import LoginActivityComponent from "./LoginActivityComponent";

function mapStateToProps(rootState: IState) {
    return {

    };
}


function mapDispatchToProps(dispatch: any) {
    return {

    };
}



export const LoginActivityContainer = O2OCommonContainer(LoginActivityComponent, mapStateToProps, mapDispatchToProps)