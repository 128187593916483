import { BIGDATA_CONSTANT } from '../../common/constant';
import RestClient from '../../libs/api/RestClient';
import { Methods } from '../../libs/api/Methods';


class DAL {

    

    getAllCustomFieldObjects(q?: string) {
        const query = q ?  encodeURIComponent(q) : ""
        let url = BIGDATA_CONSTANT.url.console + `managed-object/models/get/_all/_map_code?query=${query || ""}`;
        var restClient = new RestClient(Methods.GET, url);
        var headers = {};
        return new Promise((resolve, reject) => {
            restClient.execute(headers, {}).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }

}

export const CustomFieldDAL = new DAL()
