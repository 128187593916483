import { IResponseBigDataVN, TypeResultsBigData } from "o2o_layout/lib/common/interface";

export type TypeSystemVariable = {
    id?: string;
    value?: string;
    createdAt?: number;
    createdBy?: string;
    updatedAt?: number;
    updatedBy?: string;
}

export class SystemVariables implements IResponseBigDataVN<TypeSystemVariable> {
    success: boolean;
    results: TypeResultsBigData<TypeSystemVariable>;
    constructor() {
        this.success = false
        this.results = {
            content: [],
            timeTaken: 0,
            currentTime: 0,
            page: {
                pageSize: 0,
                currentPage: 0,
                totalRecords: 0,
                totalPages: 0
            },
            extras: null
        }
    }
}

export class DetailSystemVariable {
    results: TypeSystemVariable
    success: boolean
}