import { IState } from '../../../redux/reducer/index';
import { O2OCommonContainer } from '../../../common/container/O2OContainer';
import CommonTypeProps from '../../../common/CommonTypeProps';
import { InviteUsertToOrgComponent } from './InviteUsertToOrgComponent';



function mapStateToProps(rootState: IState) {
    return {  
       
    };
}


function mapDispatchToProps(dispatch: any) {
    return {
       
    };
}


export interface IPropsInviteUsertToOrgComponent extends CommonTypeProps {
}

export const InviteUserToOrgContainer = O2OCommonContainer(InviteUsertToOrgComponent, mapStateToProps, mapDispatchToProps)