


import { BIGDATA_CONSTANT } from '../../common/constant';
import RestClient from '../../libs/api/RestClient';
import { Methods } from '../../libs/api/Methods';

export type TypeDistinctDomain = "task-timer" | "tag-system" | "changelog" | "account-logs"

class DAL {
    distinctByField(domain: TypeDistinctDomain, field: string) {
        let url = ""
        if (domain == "task-timer") {
            url = BIGDATA_CONSTANT.url.console + `task-timers/${field}/distinct`;
        } else if (domain == "tag-system") {
            url = BIGDATA_CONSTANT.url.console + `tags/${field}/distinct`;
        } else if (domain == "changelog") {
            url = BIGDATA_CONSTANT.url.console + `mngmnt-client/orgs/logs/system/${field}/distinct`;
        } else if (domain == "account-logs"){
            url = BIGDATA_CONSTANT.url.api + `mngmnt-client/account-logs/${field}/distinct`;
        }
        var restClient = new RestClient(Methods.GET, url);
        var headers = {
        };
        return new Promise((resolve, reject) => {
            restClient.execute(headers, {}).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });

    }

}

export const DistinctFieldDAL = new DAL()
