// FloatingButtonWithTooltip.js
import React, { useState } from "react";
import styled from "styled-components";
import { AnimatePresence, motion } from "framer-motion";
import { Icon, Tooltip } from "antd";
import { useIdle } from "react-use";
import { IState } from "../../redux/reducer";
import { useSelector } from "../../redux/store";
import { getPrimaryColor } from "../../common/helper";
import pangoSVG from './pango.svg'
import { UISetting } from "o2o_layout/lib/common/model";

const FloatingButtonWrapper = styled.div`
position: fixed;
bottom: 30px;
right: 30px;
z-index: 100;
`;

const MainButtonWrapper = styled.div`
position: relative;
`;

const MainButton = styled(motion.button)`
background-color: #fafafa;
border: none;
border-radius: 50%;
width: 60px;
height: 60px;
color: white;
font-size: 28px;
display: flex;
justify-content: center;
align-items: center;
cursor: pointer;
box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
outline: none;
` as any;

interface IPropsMenuButton {
	uiSetting: UISetting
}

const MenuButton = styled(motion.button) <IPropsMenuButton>`
background-color: ${(props: IPropsMenuButton) => getPrimaryColor(props.uiSetting)};
border: none;
border-radius: 50%;
width: 45px;
height: 45px;
color: #fff;
font-size: 12px;
display: flex;
justify-content: center;
align-items: center;
cursor: pointer;
margin-bottom: 8px;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
outline: none;

&:hover {
	background-color: #f3f3f3;
	color: #333;
}
` as any;

const FloatingMenu = styled.div`
display: flex;
flex-direction: column;
align-items: center;
position: absolute;
bottom: 70px;
left: 5px;
` as any;

const ExpandableSupportButton = (props: { openFormCaresoft: any }) => {
	const uiSetting = useSelector((state: IState) => state.uiSetting)
	const [isOpen, setIsOpen] = useState(false);
	const isIdle = useIdle(3e3);

	React.useEffect(() => {
		if (isIdle) {
			setIsOpen(false)
		}
	}, [isIdle])

	const toggleMenu = () => {
		setIsOpen(!isOpen);
	};

	const menuItemVariants = {
		hidden: { opacity: 0, y: 30 },
		visible: (i: any) => ({
			opacity: 1,
			y: 0,
			transition: {
				delay: i * 0.2,
				duration: 0.8,
				ease: [0.25, 0.1, 0.25, 1],
			},
		}),
		exit: (i: any) => ({
			opacity: 0,
			y: 30,
			transition: {
				delay: i * 0.1,
				duration: 0.6,
				ease: [0.25, 0.1, 0.25, 1],
			},
		}),
	};

	const mainButtonVariants = {
		closed: { rotate: 0, scale: isIdle ? 0 : 1 },
		open: { rotate: 360, scale: 1.2 },
	};

	return (
		<FloatingButtonWrapper>
			{
				<MainButtonWrapper>
					<Tooltip title="Request Support" placement="left">
						<MainButton
							onClick={toggleMenu}
							variants={mainButtonVariants}
							animate={isOpen ? "open" : "closed"}
							transition={{
								type: "spring",
								stiffness: 200,
								damping: 25,
							}}
							whileTap={{ scale: 0.95 }}
						>
							<img
								style={{ width: 40 }}
								src={pangoSVG}
							/>
						</MainButton>
					</Tooltip>
				</MainButtonWrapper>
			}
			<AnimatePresence>
				{isOpen && (
					<FloatingMenu key={1}>
						<Tooltip title="Create a Ticket" placement="left">
							<MenuButton
								uiSetting={uiSetting}
								onClick={() => {
									props.openFormCaresoft()
									toggleMenu()
								}}
								initial="hidden"
								animate="visible"
								exit="exit"
								variants={menuItemVariants}
							>

								<Icon type="container" />

							</MenuButton>
						</Tooltip>
					</FloatingMenu>
				)}
			</AnimatePresence>
		</FloatingButtonWrapper>
	);
};

export default ExpandableSupportButton;
