import { IState } from '../../redux/reducer/index';
import { O2OCommonContainer } from '../../common/container/O2OContainer';
import { bindActionCreators } from 'redux';
import { userLogin, userLogin2ft } from './actions/LoginAction';
import { updateAuthen, getMe, commonActionFromDALV2 } from '../../common/actions/CommonAction';
import { LoginComponent } from './LoginComponent';

function mapStateToProps(rootState: IState) {
    return {  
        user: rootState.user,
        companyName: "Account"
    };
}


function mapDispatchToProps(dispatch: any) {
    return {
        login: bindActionCreators(userLogin, dispatch),
        updateAuthen: bindActionCreators(updateAuthen, dispatch),
        getMe: bindActionCreators(getMe, dispatch),
        userLogin2ft: bindActionCreators(userLogin2ft, dispatch),
        commonActionFromDALV2: bindActionCreators(commonActionFromDALV2, dispatch)
    };
}

export const LoginContainer = O2OCommonContainer(LoginComponent ,mapStateToProps, mapDispatchToProps)

