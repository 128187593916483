import { Button, Col, Icon, Row, Spin, Typography, notification } from 'antd'
import React, { useEffect, useState } from 'react'
import { ChromePicker, CirclePicker } from 'react-color'
import { BIGDATA_CONSTANT } from '../../../../../common/constant'
import { changeAntdTheme, generateThemeColor } from 'mini-dynamic-antd-theme'
import { CompanyDAL } from '../../DAL'
import { useDispatch, useSelector } from '../../../../../redux/store'
import { IActionUI, SHOW_COMMON_ERROR } from '../../../../../redux/reducer/uiReducer'
import { CommonNotificationSaveError } from '../../../../../shares/CommonNotification'
import { getPrimaryColor } from '../../../../../common/helper'
import { IState } from '../../../../../redux/reducer'
import _ from 'lodash'

interface Props {
    commonActionFromDALV2: any
}

type ThemeColorItem = {
    id: string
    theme: string
    themeColor: string
    updatedAt: number
    updatedBy: string
}

class ThemeColor {
    result: ThemeColorItem
    success: boolean
    constructor() {
        this.result = {} as ThemeColorItem
        this.success = false
    }

}
const CustomUI = ({ commonActionFromDALV2 }: Props) => {
    const [color, setColor] = useState<string>("")
    const [loading, setLoading] = useState<boolean>(false)

    const primaryColor = '#8e00e8'
    const uiSetting = useSelector((state: IState) => state.uiSetting)

    const dispatch = useDispatch()

    const getThemeColor = async () => {
        try {
            const result = commonActionFromDALV2(CompanyDAL.getThemeColor)
            const response: ThemeColor = await result
            if (response.success) {
                const color = _.get(response, "result.themeColor")
                setColor(color || primaryColor)
                setLoading(false)
            } else {
                setLoading(false)
                dispatch({
                    type: SHOW_COMMON_ERROR,
                    commonError: response
                } as IActionUI)
            }
        } catch (error) {
            setLoading(false)
            dispatch({
                type: SHOW_COMMON_ERROR,
                commonError: error
            } as IActionUI)
        }
    }

    const handleReset = async () => {
        try {
            setLoading(true)
            const result = commonActionFromDALV2(CompanyDAL.saveThemeColor, primaryColor)
            const response = await result
            console.log('response', response);
            notification.success({
                message: 'Success',
                description: "Change primary color successfully"
            })
            changeAntdTheme(
                generateThemeColor(BIGDATA_CONSTANT.primaryColor)
            )
            setTimeout(() => {
                window.location.reload()
            }, 1000);

        } catch (error) {
            dispatch({
                type: SHOW_COMMON_ERROR,
                commonError: error
            } as IActionUI)
        }
    }

    const handleApply = async () => {
        try {
            if (color) {
                setLoading(true)
                const result = commonActionFromDALV2(CompanyDAL.saveThemeColor, color)
                const response = await result

                if (response.success) {
                    setLoading(false)

                    notification.success({
                        message: 'Success',
                        description: "Change primary color successfully",
                        icon: <Icon type='check-circle' style={{ color: getPrimaryColor(uiSetting) }} />
                    })
                    changeAntdTheme(
                        generateThemeColor(color)
                    )
                    setTimeout(() => {
                        window.location.reload()
                    }, 1000);
                } else {
                    setLoading(false)
                    CommonNotificationSaveError(response.message)
                    changeAntdTheme(
                        generateThemeColor(BIGDATA_CONSTANT.primaryColor)
                    )
                }
            }
        } catch (error) {
            changeAntdTheme(
                generateThemeColor(BIGDATA_CONSTANT.primaryColor)
            )
            setLoading(false)
            dispatch({
                type: SHOW_COMMON_ERROR,
                commonError: error
            } as IActionUI)
        }

    }
    useEffect(() => {
        getThemeColor()
    }, [])
    return (
        <Spin spinning={loading}>
            <Row gutter={[5, 5]} type='flex'>
                <Col xs={24}>
                    <Typography.Title level={4}>Theme color</Typography.Title>
                    <Typography.Text type="secondary">
                        Choosing a color that characterizes your organization’s brand identity, this color will be applied to all pango applications for your organization.
                    </Typography.Text>
                </Col>
                <Col>
                    <p>Select a color from the list</p>
                    <CirclePicker colors={[BIGDATA_CONSTANT.primaryColor, "#673bb7", "#11d67b", "#4caf50", "#f15295", "#e91e63"]} onChange={(color: any, event: any) => {
                        changeAntdTheme(
                            generateThemeColor(color.hex)
                        )
                        setColor(color.hex)
                    }} />
                </Col>
                <Col xs={24} style={{ marginBottom: 10 }}>
                    <p>Or custom color from palette</p>
                    <ChromePicker color={color} onChangeComplete={(color: any, event: any) => {
                        changeAntdTheme(
                            generateThemeColor(color.hex)
                        )
                        setColor(color.hex)
                    }} />

                </Col>

                <Col xs={24}>
                    <Button loading={loading} type="default" style={{ marginRight: 10 }} onClick={handleReset}>Reset to default</Button>

                    <Button loading={loading} icon="save" type="primary" onClick={handleApply}>Apply</Button>
                </Col>
            </Row>
        </Spin>

    )
}

export default CustomUI