import { UISetting, UIDataSetting } from 'o2o_layout/lib/common/model';


export const GET_UISETTING = "GET_UISETTING"

const empty = (): UISetting => ({
  id: null,
  theme: null,
  themeColor: null,
  updatedAt: 0,
  updatedBy: null
});

export interface IActionUISetting {
  type: string,
  payload: UISetting
};

export const uiSettingReducer = (state = empty(), action: IActionUISetting) => {
  switch (action.type) {
    case GET_UISETTING:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};




export const GET_UI_DATA_SETTING = "GET_UI_DATA_SETTING"

const emptyData = (): UIDataSetting => ({
  id: null,
  maskingAddress: false,
  maskingEmail: false,
  maskingPhone: false,
  showMaskingData: false
});


export interface IActionUIDataSetting {
  type: string,
  payload: UIDataSetting
};

export const uiDataSettingReducer = (state = emptyData(), action: IActionUIDataSetting) => {
  switch (action.type) {
    case GET_UI_DATA_SETTING:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};