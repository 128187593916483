import * as React from 'react'

import { Provider } from 'react-redux'

import { ConnectedRouter } from 'react-router-redux'

import { store, historyApp } from './redux/store';

import MainRouter from './routes/MainRouter';
import { addLocaleData } from 'react-intl';

import locale_en from 'react-intl/locale-data/en';
import locale_vi from 'react-intl/locale-data/vi';
import { O2OIntlProvider } from './common/container/O2OIntlProvider';
import { withRouter } from 'react-router-dom';

addLocaleData([...locale_en, ...locale_vi]);

class ScrollToTop extends React.Component<any> {
  componentDidUpdate(prevProps: any) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    return this.props.children
  }
}
const ScrollRestoration = withRouter(ScrollToTop);

export const MainApplication: React.StatelessComponent<{}> = () => {
  return (
    <Provider store={store}>
      <O2OIntlProvider>
        <ConnectedRouter history={historyApp}>
          <ScrollRestoration>
            <MainRouter />
          </ScrollRestoration>

        </ConnectedRouter>
      </O2OIntlProvider>
    </Provider>
  )
}