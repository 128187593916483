import { Select } from "antd"
import { WrappedFormUtils } from "antd/lib/form/Form"
import { debounce, find, includes, isFunction, size } from "lodash"
import { IResponseHaveNotContent } from "o2o_layout/lib/common/interface"
import { useCallback, useEffect, useState } from "react"
import { O2OFormItem } from "../../../shares/O2OFormItem"
import { ConsoleUsersDAL } from "../ConsoleUserDAL"
import { TypePermissionGroup } from "../permisions/model/permissions"
import React from "react"
import { ModeOption } from "antd/lib/select"

interface ISuggestionPermisstionGroupProps {
    label?: string
    keyField: string
    initData?: string
    form: WrappedFormUtils<any>
    commonActionFromDALV2: any
    onSelect?: (permission?: TypePermissionGroup) => void
    disabledPermisions?: string[]
    mode?: ModeOption
}

class SuggestionPermisstionGroups implements IResponseHaveNotContent<TypePermissionGroup> {
    success: boolean
    results: TypePermissionGroup[]
    constructor() {
        this.success = false
        this.results = []
    }
}


const SuggestionPermisstionGroup = ({ label, form, keyField, initData, commonActionFromDALV2, onSelect, disabledPermisions, mode }: ISuggestionPermisstionGroupProps) => {
    const [query, setquery] = useState("")
    const [res, setRes] = useState(new SuggestionPermisstionGroups())

    const onSearch = (q: string) => {
        setquery(q)
    }

    const load = async () => {
        const result = commonActionFromDALV2(ConsoleUsersDAL.suggestionPermissionGroup, query)
        try {
            const response: SuggestionPermisstionGroups = await result
            if (response.success) {
                setRes(response)
            } else {
                setRes(new SuggestionPermisstionGroups())
            }
        } catch (error) {
            setRes(new SuggestionPermisstionGroups())
        }
    }

    useEffect(() => {
        load()
    }, [query])



    const debounceOnsearch = useCallback(debounce(onSearch, 500), [])

    return (
        <O2OFormItem label={label ? label : "Permission Group "}>
            {form.getFieldDecorator(keyField, {
                initialValue: initData
            })(<Select
                mode={mode || "default"}
                showSearch
                filterOption={false}
                showArrow
                placeholder="Select"
                allowClear
                onSearch={(e: any) => {
                    debounceOnsearch(e)
                }}
                onSelect={(id: string) => {
                    if (onSelect && isFunction(onSelect)) {
                        const permission = find(res.results, (p: TypePermissionGroup) => p.id === id)
                        onSelect(permission)
                    }
                    onSearch("")
                }}
            >
                {res.success && res.results ?
                    res.results.map((permission: TypePermissionGroup) => <Select.Option disabled={includes(disabledPermisions, permission.id)} key={permission.id}>
                        {permission.name} {size(permission.permissions) > 1 ? `(${size(permission.permissions)} permissions)` : `(${size(permission.permissions)} permission)`}
                    </Select.Option>)
                    : null}
            </Select>)}
        </O2OFormItem>
    )
}

export default SuggestionPermisstionGroup
