import { Methods, RestClient } from "o2o_layout/lib/common/rest";
import { BIGDATA_CONSTANT } from "../../../common/constant";
import { TypeLogsTicketCriteria } from "../model/syncTicketModel";

class DAL {
    logsTicketSearch(criterial: TypeLogsTicketCriteria) {
        const { pageSize = 10 } = criterial ? criterial : { pageSize: 10 };

        let url =
            BIGDATA_CONSTANT.url.console +
            `connections/crm-loyalty/caresoft/pango-ticket/search?pageSize=${pageSize}`;

        let paramsUrl = new URLSearchParams();

        if (criterial && criterial.page) {
            const p = criterial.page - 1;
            paramsUrl.append("page", "" + p);
        }

        if (criterial && criterial.orderBy) {
            paramsUrl.append('orderBy', criterial.orderBy);
        }

        if (criterial && (criterial.page || criterial.orderBy)) {
            url = url + "&" + paramsUrl.toString();
        }
        var restClient = new RestClient(Methods.POST, url);
        return new Promise((resolve, reject) => {
            restClient.execute({}, criterial ? criterial : {}).then(
                (response: any) => {
                    const user: any = response.data;
                    resolve(user);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    }

    generatePangoTicket(dto: any) {
        let url =
            BIGDATA_CONSTANT.url.console + `connections/crm-loyalty/caresoft/pango-ticket/add`;
        var restClient = new RestClient(Methods.POST, url);
        return new Promise((resolve, reject) => {
            restClient.execute({}, dto).then(
                (response: any) => {
                    const user: any = response.data;
                    resolve(user);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    }

}

export const ManageTicketDAL = new DAL();