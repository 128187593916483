import { bindActionCreators } from "redux";
import { O2OCommonContainer } from "../../../common/container/O2OContainer";
import { IState } from "../../../redux/reducer";
import { changePwd } from "../ConsoleUsersAction";
import { ChangePasswordComponent } from "./ChangePasswordComponent";


function mapStateToProps(rootState: IState) {
    return {
    };
}


function mapDispatchToProps(dispatch: any) {
    return {
        changePwd: bindActionCreators(changePwd, dispatch),
    };
}



export const ChangePasswordContainer = O2OCommonContainer(ChangePasswordComponent, mapStateToProps, mapDispatchToProps)