import { Button, Col, Divider, Drawer, Dropdown, Form, Input, Layout, Menu, Modal, Row, Table, Tag, Tooltip, Typography } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import { ClickParam } from 'antd/lib/menu'
import { ColumnProps } from 'antd/lib/table'
import { get, isEmpty, isString, map, omit } from 'lodash'
import moment from 'moment'
import { DrawerSetTaskTimer, InfoList, LongTextTd, O2OCustomPaging } from 'o2o_layout'
import queryString from "query-string"
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useAsyncRetry } from 'react-use'
import CommonTypeProps, { ICommonPayload } from '../../../common/CommonTypeProps'
import { BIGDATA_CONSTANT } from '../../../common/constant'
import { IActionUI, SHOW_COMMON_ERROR } from '../../../redux/reducer/uiReducer'
import { useDispatch } from '../../../redux/store'
import ButtonBackDrawer from '../../../shares/ButtonBackDrawer'
import { CommonNotificationDeleteSuccessfully, CommonNotificationSaveError, CommonNotificationUpdateSuccessfully } from '../../../shares/CommonNotification'
import DrawerViewJSON from '../../../shares/DrawerViewJSON'
import { O2OFormItem } from '../../../shares/O2OFormItem'
import { SwitchButtonConfirm } from '../../../shares/SwitchButtonConfirm'
import SuggestionOrgs from '../../users/drawer/SuggestionOrgs'
import CronjobHistoryComponent from './CronjobHistoryComponent'
import { CronjobDAL } from './DAL'
import { DrawerFormAddEditCronjob } from './DrawerAddEditCronjob'
import { Cronjobs, TypeCronjob, TypePayloadAddEditCronjob } from './model'
interface IFilterCronjobComponentProps extends FormComponentProps {
    commonActionFromDAL2: any
    onSearch: (payload: any) => void
    loading: boolean
}

const FilterCronjobComponent = Form.create<IFilterCronjobComponentProps>()(
    ({ form, commonActionFromDAL2, onSearch, loading }: IFilterCronjobComponentProps) => {

        const onSubmit = () => {
            form.validateFields((err: any, values: any) => {
                if (!err) {
                    onSearch(values)
                }
            })
        }

        return <Form hideRequiredMark colon={false}>
            <Row type='flex' align={"middle"} gutter={[5, 5]}>
                <Col sm={24} lg={12} xl={8} >
                    <O2OFormItem label="Info">
                        {form.getFieldDecorator("freeText", {

                        })(<Input onPressEnter={onSubmit} placeholder='Enter' allowClear />)}
                    </O2OFormItem>
                </Col>
                <Col sm={24} lg={12} xl={8} >
                    <SuggestionOrgs
                        required={false}
                        form={form}
                        keyField={'orgId'}
                        commonActionFromDAL2={commonActionFromDAL2}
                        visible={true}
                        lable={<span>
                            Organization
                        </span>}
                    />
                </Col>
                <Col>
                    <O2OFormItem label=" ">
                        <Button loading={loading} onClick={onSubmit} type='primary' icon='search'>Search</Button>
                    </O2OFormItem>
                </Col>
            </Row>
        </Form>
    }
)




interface ICronjobComponentProps extends CommonTypeProps {

}

const TYPE_SUBMENU_CRONJOB = {
    configuration: 'configuration',
    history: 'history'
}

const TYPE_DISPATCH_CRITERIA = "TYPE_DISPATCH_CRITERIA"

const initCriteria: ICommonPayload<any> = {
    loading: false,
    type: TYPE_DISPATCH_CRITERIA,
    payload: {} as any,
}

const reducerCriteria = (state: ICommonPayload<any>, action: ICommonPayload<any>) => {
    switch (action.type) {
        case TYPE_DISPATCH_CRITERIA:
            return { ...state, ...action }
        default:
            throw new Error()
    }
}


const CronjobComponent = (props: ICronjobComponentProps) => {
    const { location, commonActionFromDALV2 } = props
    const [criteria, dispatchCriteria] = React.useReducer(reducerCriteria, initCriteria)

    const [selectedSubMenu, setSelectedSubMenu] = React.useState("" as string)
    const [visibleAddEdit, setVisibleAddEdit] = useState(false as boolean)
    const [visibleViewJSON, setVisibleViewJSON] = useState(false)
    const [selectedRecord, setSelectedRecord] = useState(undefined as undefined | TypeCronjob)
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()

    const [visibleTask, setVisibleTask] = React.useState(false)
    const [taskTimer, setTaskTimer] = useState(undefined as any)
    const [visibleHistory, setVisibleHistory] = useState(false)

    React.useEffect(() => {
        const parsed = queryString.parse(location.search);
        if (parsed.subTab && isString(parsed.subTab)) {
            setSelectedSubMenu(parsed.subTab)
        } else {
            setSelectedSubMenu(TYPE_SUBMENU_CRONJOB.configuration)
        }
    }, [location])

    const load = async () => {
        const result = commonActionFromDALV2(CronjobDAL.search, criteria.payload)
        try {
            const response: Cronjobs = await result
            if (response.success) {
                return response
            } else {
                return new Cronjobs()
            }
        } catch (error) {
            return new Cronjobs()
        }
    }

    useEffect(() => {
        dispatchCriteria({
            loading: false,
            type: TYPE_DISPATCH_CRITERIA,
            payload: { ...criteria.payload, ...{ page: 0, pageSize: 10 } }
        })
        return () => {
            dispatchCriteria({
                loading: false,
                type: TYPE_DISPATCH_CRITERIA,
                payload: {}
            })
        }
    }, [])


    const stateCronjob = useAsyncRetry(async () => {
        if (!isEmpty(criteria.payload)) {
            const data = await load()
            return data
        }
        return new Cronjobs()
    }, [criteria.payload])

    const cronJobs: Cronjobs = stateCronjob.value || new Cronjobs()

    const columns: ColumnProps<TypeCronjob>[] = [
        {
            title: "#",
            ellipsis: true,
            key: "index",
            width: 40,
            align: "center",
            render: (text: any, record: TypeCronjob, index: number) => {
                return index + 1
            }
        },
        {
            title: "Status",
            ellipsis: true,
            key: "status",
            width: 90,
            render: (text: any, record: TypeCronjob, index: number) => {
                return <SwitchButtonConfirm
                    showText
                    status={record.status || ''}
                    callApi={async (sl: Dispatch<SetStateAction<boolean>>) => {
                        const payload = {
                            days: record.days,
                            endpointMethod: record.endpointMethod,
                            endpointUrl: record.endpointUrl,
                            extParam: record.extParam,
                            gmt: record.gmt,
                            hours: record.hours,
                            minutes: record.minutes,
                            monthDayHoursGmts: record.monthDayHoursGmts,
                            months: record.months,
                            name: record.name,
                            orgId: record.orgId,
                            status: record.status === 'Inactive' ? 'Active' : 'Inactive',
                            validFromTime: record.validFromTime,
                            validToTime: record.validToTime,
                        } as TypePayloadAddEditCronjob
                        const result = props.commonActionFromDALV2(CronjobDAL.update, get(record, 'id'), payload)
                        sl(true)
                        try {
                            const response = await result
                            if (response.success) {
                                setTimeout(() => {
                                    stateCronjob.retry()
                                }, 1000);
                                CommonNotificationUpdateSuccessfully()
                            } else {
                                CommonNotificationSaveError(response.message)
                            }
                            sl(false)
                        } catch (error) {
                            sl(false)
                            dispatch({
                                type: SHOW_COMMON_ERROR,
                                commonError: error
                            } as IActionUI)
                        }

                    }}
                    valueChecked="active"
                    valueUnchecked="inactive"

                />
            }
        },
        {
            title: "Name",
            ellipsis: true,
            key: "name",
            width: 250,
            render: (text: any, record: TypeCronjob, index: number) => {
                return <LongTextTd text={record.name} />
            }
        },
        {
            title: "Endpoint",
            ellipsis: true,
            key: "name",
            width: 250,
            render: (text: any, record: TypeCronjob, index: number) => {
                return <span>
                    <Tooltip title={`Method ${record.endpointMethod}`}>
                        <Tag>{record.endpointMethod === "GET" ? 'G' : 'P'}</Tag>
                    </Tooltip>
                    <LongTextTd text={record.endpointUrl} />
                </span>
            }
        },
        {
            title: "Organization",
            ellipsis: true,
            key: "orgnization",
            width: 200,
            render: (text: any, record: TypeCronjob, index: number) => {
                return <span>
                    <LongTextTd textDisplay={get(record, 'extParam.orgName')} text={record.orgId} />
                </span>
            }
        },
        {
            title: "Created",
            ellipsis: true,
            key: "created",
            render: (text: any, record: TypeCronjob, index: number) => {
                return record.createdAt ?
                    <Tooltip title={<>Created by {record.createdBy} at {moment(record.createdAt).format("LLLL")}</>}>
                        {
                            moment(record.createdAt).fromNow()
                        }
                    </Tooltip>
                    : '--/--'
            }
        },
        {
            title: "Action",
            ellipsis: true,
            fixed: 'right',
            key: "action",
            width: 180,
            render: (text: any, record: TypeCronjob, index: number) => {
                return <Row type='flex' align={"middle"}>
                    <Tooltip title="Edit">
                        <Button onClick={() => {
                            setVisibleAddEdit(true)
                            setSelectedRecord(record)
                        }} icon='edit' size='small' type='link' />
                    </Tooltip>

                    <Divider type="vertical" />
                    <Tooltip title="Timer" >
                        <Button onClick={() => {
                            setVisibleTask(true)
                            const days = record && record.days ? map(record.days, (value: number) => `${value}`) : []
                            const hours = record && record.hours ? map(record.hours, (value: number) => `${value}`) : []
                            const months = record && record.months ? map(record.months, (value: number) => `${value}`) : []
                            const minutes = record && record.minutes ? map(record.minutes, (value: number) => `${value}`) : []
                            const timer = {
                                days: days,
                                hours: hours,
                                months: months,
                                minutes: minutes,
                                gmt: record && record.gmt ? `${record.gmt}` : '',
                                startTime: record && record.validFromTime ? record.validFromTime : 0,
                                endTime: record && record.validToTime ? record.validToTime : 0,
                            } as { days: string[], hours: string[], months: string[], minutes: string[], gmt: string, startTime: number, endTime: number }
                            setTaskTimer(timer)
                            setSelectedRecord(record)
                        }} icon='clock-circle' size='small' type='link' />
                    </Tooltip>
                    <Divider type="vertical" />

                    <Tooltip title="Log" >
                        <Button onClick={() => {
                            setVisibleHistory(true)
                            setSelectedRecord(record)
                        }} icon='monitor' size='small' type='link' />
                    </Tooltip>
                    <Divider type="vertical" />

                    <Dropdown overlay={<Menu onClick={(e: ClickParam) => {
                        if (e.key === "viewJSON") {
                            setSelectedRecord(record)
                            setVisibleViewJSON(true)
                        } else if (e.key === "delete") {
                            Modal.confirm({
                                title: BIGDATA_CONSTANT.message.default.titleConfirm,
                                content: <>Are you sure to delete <b>{record.name}</b>?</>,
                                onOk: async () => {
                                    const result = commonActionFromDALV2(CronjobDAL.delete, get(record, 'id'))
                                    try {
                                        const response = await result
                                        if (response.success) {
                                            CommonNotificationDeleteSuccessfully()
                                            setTimeout(() => {
                                                stateCronjob.retry()
                                            }, 1000);
                                        } else {
                                            CommonNotificationSaveError(response.message)
                                        }
                                    } catch (error) {
                                        dispatch({
                                            type: SHOW_COMMON_ERROR,
                                            commonError: error
                                        } as IActionUI)
                                    }
                                }
                            })
                        }
                    }}>
                        <Menu.Item key={"viewJSON"}>
                            <Button icon='select' size='small' type='link' >View JSON</Button>
                        </Menu.Item>
                        <Menu.Item key={"delete"}>
                            <Button icon='delete' size='small' type='link' >Delete</Button>
                        </Menu.Item>
                    </Menu>}>
                        <Button icon='more' size='small' type='link' />
                    </Dropdown>
                </Row>
            }
        }
    ]

    return (
        <Layout>
            <Layout.Sider width={200} style={{ background: "#fff", minHeight: "400px", marginRight: 12 }}>
                <Menu style={{ background: "#fff", minHeight: "400px", height: "inherit" }} theme='light' selectedKeys={[selectedSubMenu]} >
                    <Menu.Item key={TYPE_SUBMENU_CRONJOB.configuration}>
                        <Link to={{
                            pathname: "console-system-setting",
                            search: `tab=cronjob&subTab=${TYPE_SUBMENU_CRONJOB.configuration}`
                        }}>
                            Configuration
                        </Link>
                    </Menu.Item>
                    <Menu.Item key={TYPE_SUBMENU_CRONJOB.history}>
                        <Link to={{
                            pathname: "console-system-setting",
                            search: `tab=cronjob&subTab=${TYPE_SUBMENU_CRONJOB.history}`
                        }}>
                            History
                        </Link>
                    </Menu.Item>
                </Menu>
            </Layout.Sider>
            <Layout.Content style={{ overflow: "hidden" }}>

                {
                    selectedSubMenu === TYPE_SUBMENU_CRONJOB.configuration &&
                    <Row type="flex" gutter={[10, 10]}>
                        <Col xs={24}>
                            <Typography.Title level={4}>
                                Configuration
                            </Typography.Title>
                        </Col>
                        <Col xl={24}>
                            <FilterCronjobComponent
                                loading={stateCronjob.loading}
                                onSearch={(payload) => {
                                    dispatchCriteria({
                                        loading: false,
                                        type: TYPE_DISPATCH_CRITERIA,
                                        payload: { ...criteria.payload, ...{ page: 0 }, ...payload }
                                    })
                                }}
                                commonActionFromDAL2={commonActionFromDALV2}
                            />
                        </Col>
                        <Col xl={24}>
                            <InfoList
                                loading={stateCronjob.loading}
                                list={cronJobs}
                                refresh={stateCronjob.retry}
                                right={<>
                                    <Button onClick={() => setVisibleAddEdit(true)} type='primary' icon='plus-circle' >New Job</Button>
                                </>}
                            />
                        </Col>
                        <Col xl={24}>
                            <Table
                                size="small"
                                loading={stateCronjob.loading || loading}
                                bordered={false}
                                columns={columns}
                                dataSource={cronJobs.results.content}
                                pagination={false}
                                scroll={{ x: 'calc(650px + 50%)' }}
                                rowKey="id"

                            />
                        </Col>
                        <Col xl={24}>
                            <O2OCustomPaging
                                list={cronJobs}
                                onChange={(e: any) => {
                                    dispatchCriteria({
                                        loading: false,
                                        type: TYPE_DISPATCH_CRITERIA,
                                        payload: { ...criteria.payload, ...{ page: e - 1 } }
                                    })
                                }}
                                onChangePageSize={(e: any) => {
                                    dispatchCriteria({
                                        loading: false,
                                        type: TYPE_DISPATCH_CRITERIA,
                                        payload: { ...criteria.payload, ...{ page: 0, pageSize: e } }
                                    })
                                }}
                            />
                        </Col>
                    </Row>
                }


                {
                    selectedSubMenu === TYPE_SUBMENU_CRONJOB.history &&
                    <CronjobHistoryComponent
                        showTitle
                        {...props}
                    />
                }

            </Layout.Content>

            <DrawerFormAddEditCronjob
                selectedRecord={selectedRecord}
                onSuccess={() => {
                    setTimeout(() => {
                        stateCronjob.retry()
                    }, 1000);
                }}
                commonActionFromDAL2={commonActionFromDALV2}
                visible={visibleAddEdit}
                onClose={() => {
                    setSelectedRecord(undefined)
                    setVisibleAddEdit(false)
                }}
            />

            <DrawerViewJSON
                data={selectedRecord}
                visible={visibleViewJSON}
                onClose={() => {
                    setSelectedRecord(undefined)
                    setVisibleViewJSON(false)
                }}
            />

            <Drawer
                bodyStyle={{ marginBottom: 100 }}
                title={<span>
                    <ButtonBackDrawer onClick={() => {
                        setVisibleHistory(false)
                        setSelectedRecord(undefined)
                    }} />
                    history
                </span>}
                visible={visibleHistory}
                onClose={() => {
                    setVisibleHistory(false)
                    setSelectedRecord(undefined)
                }}
                maskClosable={false}
                width={1200}
            >
                <CronjobHistoryComponent
                    showTitle={false}
                    configId={get(selectedRecord, 'id')}
                    {...props}
                />
            </Drawer>

            <DrawerSetTaskTimer
                defaultOrgGMT={"7"}
                visible={visibleTask}
                onClose={() => {
                    setVisibleTask(false)
                    setSelectedRecord(undefined)
                }}
                onSumit={async (values: { days: string[], hours: string[], months: string[], minutes: string[], gmt: string, scheduleTime: [moment.Moment, moment.Moment] }) => {
                    const data: any = omit(values, ["scheduleTime"])
                    const days = map(values.days, (value: string) => parseInt(value))
                    const hours = map(values.hours, (value: string) => parseInt(value))
                    const months = map(values.months, (value: string) => parseInt(value))
                    const minutes = map(values.minutes, (value: string) => parseInt(value))
                    data["validFromTime"] = values.scheduleTime[0].toDate().getTime();
                    data["validToTime"] = values.scheduleTime[1].toDate().getTime();
                    data['days'] = days
                    data['hours'] = hours
                    data['months'] = months
                    data['minutes'] = minutes
                    const timer = { ...data }
                    const payload = {
                        endpointMethod: selectedRecord ? selectedRecord.endpointMethod : '',
                        endpointUrl: selectedRecord ? selectedRecord.endpointUrl : '',
                        name: selectedRecord ? selectedRecord.name : '',
                        orgId: selectedRecord ? selectedRecord.orgId : '',
                        extParam: {
                            orgName: selectedRecord ? get(selectedRecord, 'extParam.orgName') : ''
                        },
                        status: selectedRecord ? selectedRecord.status : '',
                        ...timer
                    }
                    setLoading(true)
                    const result = commonActionFromDALV2(CronjobDAL.update, get(selectedRecord, 'id'), payload)
                    try {
                        const response = await result
                        if (response.success) {
                            setTimeout(() => {
                                stateCronjob.retry()
                            }, 1000);
                            CommonNotificationUpdateSuccessfully()
                        } else {
                            CommonNotificationSaveError(response.message)
                        }
                        setLoading(false)
                    } catch (error) {
                        setLoading(false)
                        dispatch({
                            type: SHOW_COMMON_ERROR,
                            commonError: error
                        } as IActionUI)
                    }

                }}
                taskTimer={taskTimer}
            />

        </Layout>
    )
}

export default CronjobComponent