import { Button, Drawer, Form, Input, Popconfirm } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import _, { isFunction } from 'lodash'
import { TypeConsoleUser, TypeOrganization, User } from 'o2o_layout/lib/common/model'
import React from 'react'

interface Iprops<T> extends FormComponentProps {
    visible: boolean
    onClose: () => void
    item: T
    onDelete: (record: T) => void
    type?: string
    user?: User
    openConfirm2FA?: () => void
}
const DrawerConfirmDelete = Form.create<Iprops<TypeOrganization | TypeConsoleUser>>()((props: Iprops<TypeOrganization | TypeConsoleUser>) => {
    React.useEffect(() => {
        if (!props.visible) {
            props.form.resetFields()
        }
    }, [props.visible])

    return (
        <Drawer
            title={props.type === "super-admin-user" ? "DELETE USER" : "DELETE ORGANIZATION"}
            width={600}
            visible={props.visible}
            onClose={() => {
                props.onClose()
                props.form.resetFields()
            }}
        >

            <Form colon={false} hideRequiredMark={true}>
                {props.type === "super-admin-user" ? (
                    <>
                        <p>You are about to delete the account <strong>{_.get(props.item, "id")}</strong> permanently.</p>
                        <p>
                            Confirm deletion by typing the email below: <strong>{_.get(props.item, "id")}</strong>
                        </p>
                    </>

                ) : (
                    <>
                        <p>You are about to delete the Organization: <strong>{_.get(props.item, "name")}</strong>. All users will not be able to access to this organization.</p>

                        <p>
                            Confirm deletion by typing the name below: <strong>{_.get(props.item, "name")}</strong>
                        </p>
                    </>
                )}



                <Form.Item label="">
                    {
                        props.form.getFieldDecorator("name", {
                            rules: [{
                                required: true,
                                message: "Required"
                            }]
                        })(
                            <Input placeholder={props.type === "super-admin-user" ? _.get(props.item, "id") : _.get(props.item, "name")} />
                        )
                    }
                </Form.Item>

                <div
                    style={{
                        position: 'absolute',
                        right: 0,
                        bottom: 0,
                        width: '100%',
                        borderTop: '1px solid #e9e9e9',
                        padding: '10px 16px',
                        background: '#fff',
                        textAlign: 'right',
                    }}
                >
                    <Button type="default" style={{ marginRight: 10 }} onClick={() => {
                        props.form.resetFields()
                        props.onClose()
                    }}>Cancel</Button>


                    {props.type === "super-admin-user" ? (
                        <>
                            {props.form.getFieldValue("name") !== _.get(props.item, "id") ? (
                                <Button disabled={true} icon="delete" type="primary">Delete</Button>
                            ) : (
                                <Popconfirm placement="leftTop" title="Are you sure to continue?" onConfirm={() => {
                                    props.onDelete(props.item)
                                }}>
                                    <Button icon="delete" type="primary">Delete</Button>
                                </Popconfirm>
                            )}
                        </>
                    ) : (
                        <>
                            {props.form.getFieldValue("name") !== _.get(props.item, "name") ? (
                                <Button disabled={true} icon="delete" type="primary">Delete</Button>
                            ) : (
                                <Popconfirm placement="leftTop" title={`Are you sure you want to delete the organization: ${props.item.name} ?`} onConfirm={() => {
                                    if (isFunction(props.openConfirm2FA)) {
                                        props.openConfirm2FA()    
                                    }
                                }}>
                                    <Button icon="delete" type="primary">Delete</Button>
                                </Popconfirm>
                            )}
                        </>
                    )}

                </div>

            </Form>

        </Drawer>
    )
})

export default DrawerConfirmDelete