import { UPDATE_LOCALE } from '../../common/actionType/actionType';
import enMessage from 'o2o_layout/lib/langs/en.json'
import viMessage from 'o2o_layout/lib/langs/vi.json'

import enLocal from "../../langs/en.json"
import viLocal from "../../langs/vi.json"

const messages = {
    'vi': {...viMessage, ...viLocal},
    'en': {...enMessage, ...enLocal}
};

const createEmptyUI = () => ({
    locale: 'en',
    message: messages['en']
});

export type TypeLangs = 'vi' | "en";
export interface IActionLocale {
    type: string,
    locale: TypeLangs,
};

export const localeReducer = (state = createEmptyUI(), action: IActionLocale) => {
    switch (action.type) {
        case UPDATE_LOCALE:
            return { ...state, locale: action.locale, message: messages[action.locale] };
        default:
            return state;
    }
};
