import * as React from 'react';
import { Breadcrumb, Row, Icon, Col, Form, Tabs, Button, Input, notification } from 'antd';
import { Link } from 'react-router-dom';
import CommonTypeProps from '../../../common/CommonTypeProps';
import { DashboardPageLayout } from '../../../layout/dashboard/DashboardPageLayout';
import { FormComponentProps } from 'antd/lib/form';
import { typeUpdateAcount } from '../ConsoleUsersAction';
import { ModalUpdateAdminClientOrg } from './ModalConsoleUser';
import { typeUpdateOrg } from '../../orgs/OrgsAction';
import _ from 'lodash';
import { FormUpdateUserInfo } from './FormUpdateUserInfo';
import { ChangePassword } from './ChangePassword';
import { CanAccess } from 'o2o_layout';
import { TypeEDMSendInBlue } from '../../../redux/reducer/edmSendInBlueReducer';
import { MetaHeader } from '../../../shares/MetaHeader';
import { BIGDATA_CONSTANT } from '../../../common/constant';
import { getCurrentURL, getPrimaryColor } from '../../../common/helper';


interface Props extends CommonTypeProps, FormComponentProps {
    updateAccount: typeUpdateAcount,
    updateOrgByAdminClient: typeUpdateOrg,
    changePwd: any,
    edmSendInBlue: TypeEDMSendInBlue,
    updateEDMSendInBlue: any,
    getEDMSendInBlue: any

}

interface IFormProps extends FormComponentProps {
    onSubmit: any,
    edm: TypeEDMSendInBlue
}
const FormEDMSendInBlueComponent = Form.create<IFormProps>()(
    class FormEDM extends React.Component<IFormProps, any> {
        render() {
            const { getFieldDecorator } = this.props.form

            return (
                <Form onSubmit={this.props.onSubmit} layout="vertical">

                    <Form.Item label="Name">
                        {getFieldDecorator('name', {
                            initialValue: this.props.edm.name,
                            rules: [{ required: true, message: 'Please input name!' }],
                        })(
                            <Input placeholder="name" allowClear></Input>
                        )}
                    </Form.Item>

                    <Form.Item label="Send In Blue Api Key">
                        {getFieldDecorator('apiKey', {
                            initialValue: this.props.edm.apiKey,
                            rules: [{ required: true, message: 'Please input api key!' }],
                        })(
                            <Input placeholder="api key" allowClear ></Input>
                        )}
                    </Form.Item>

                    <Form.Item>
                        <Button htmlType="submit">Save</Button>
                    </Form.Item>


                </Form>
            )
        }
    }
)
export class UserProfileForm extends React.Component<Props, any> {
    formRef: any
    formRefInfo: any
    formRefEDM: any
    formRefChangePassword: any

    state = {
        confirmDirty: false,
        visibleModalUpdateOrg: false
    };

    componentDidMount = () => {
        this.props.updateMetaTitle({ title: BIGDATA_CONSTANT.trackTitle.Profile })
        this.timer = setTimeout(() => {
            this.props.updateMeta({
                name: BIGDATA_CONSTANT.trackTitle.Profile,
                title: BIGDATA_CONSTANT.trackTitle.Profile,
                app: "ACCOUNT",
                url: getCurrentURL(this.props.location)
            })
            clearTimeout(this.timer)
        }, 5000)
    }

    timer: any

    componentWillUnmount() {
        clearTimeout(this.timer)
    }


    checkCurrentUserIsSuperAdmin = (): boolean => {
        if (this.props.user) {
            if (this.props.user.orgCtx) {
                let roles = this.props.user.orgCtx.roles;
                let isSP = _.find(roles, (e) => {
                    return e == "SUPER_ADMIN"
                });

                if (isSP) {
                    return true
                }
                return false
            }
            return false
        }
        return false;
    }

    renderBreadCrumb = () => {
        return (
            <Row type="flex" justify="space-between" align="middle" style={{ marginTop: 20 }}>
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/"><Icon type="home" /></Link>
                    </Breadcrumb.Item>

                    <Breadcrumb.Item>Profiles</Breadcrumb.Item>
                </Breadcrumb>

            </Row>

        )
    }

    submitUpdateOrg = (e: any) => {
        const form = this.formRef.props.form;

        form.validateFields((err: any, values: any) => {
            if (err) {
                return;
            }

            this.props.setGlobalLoading(true)
            let isSAdmin = this.checkCurrentUserIsSuperAdmin()
            values.id = this.props.user.orgCtx ? this.props.user.orgCtx.id : null

            this.props.updateOrgByAdminClient(values, isSAdmin)
                .then(() => {
                    if (this.props.commonResponse.success) {
                        this.props.setGlobalLoading(false)
                        this.setState({ visibleModalUpdateOrg: false })
                        notification.success({
                            message: BIGDATA_CONSTANT.message.default.title.success,
                            description: `Organization ${this.props.user.orgCtx ? this.props.user.orgCtx.organization.name : ""} updated successfully`,
                            icon: <Icon type="check-circle" style={{ color: getPrimaryColor(this.props.uiSetting) }} />
                        })
                        setTimeout(() => {
                            window.location.reload()
                        }, 200)
                    } else {
                        this.props.setGlobalLoading(false)
                        notification.error({
                            message: BIGDATA_CONSTANT.message.default.title.success,
                            description: this.props.commonResponse.message,
                        })
                        this.setState({ visibleModalUpdateOrg: false })
                    }


                })
                .catch((e: any) => {

                    this.props.setGlobalLoading(false)
                    notification.error({
                        message: BIGDATA_CONSTANT.message.default.title.error,
                        description: _.get(e.data, "message", "Something went wrong"),
                    })
                    this.setState({ visibleModalUpdateOrg: false })
                })
        })


    }





    submitUpdateInfo = (e: any) => {
        e.preventDefault();
        this.formRefInfo.props.form.validateFieldsAndScroll((err: any, values: any) => {
            if (!err) {
                let user: any = Object.assign(values, {
                    email: values.id,
                    status: 'active'
                })

                let isSPA = this.checkCurrentUserIsSuperAdmin()
                let promise: any = this.props.updateAccount(user, isSPA)
                this.props.setGlobalLoading(true)
                promise
                    .then(() => {

                        if (this.props.commonResponse.success) {

                            notification.success({
                                message: BIGDATA_CONSTANT.message.default.title.success,
                                description: `User ${this.props.user.id} updated successfully`,
                                icon: <Icon type="check-circle" style={{ color: getPrimaryColor(this.props.uiSetting) }} />
                            })


                            this.props.setGlobalLoading(false)


                        } else {
                            this.props.setGlobalLoading(false)
                            notification.error({
                                message: BIGDATA_CONSTANT.message.default.title.error,
                                description: _.get(this.props.commonResponse, "message", "Something went wrong"),
                            })
                        }

                    })
                    .catch((error: any) => {
                        notification.error({
                            message: BIGDATA_CONSTANT.message.default.title.error,
                            description: _.get(error.data, "message", "Something went wrong"),
                        })

                        this.props.setGlobalLoading(false)
                    })

            }

        });
    }




    renderTheme() {


        return (
            <DashboardPageLayout  {...this.props} selectedLeftNav="1" >
                <div className="org-page">

                    <MetaHeader title={this.props.ui.meta.title} />

                    <div className="contentLayout">
                        {this.renderBreadCrumb()}
                        <br />
                        <div className="containerInner">

                            <Row>
                                <Col xs={24}>
                                    <Tabs type="card">
                                        <Tabs.TabPane tab="info" key="1">
                                            <Row>
                                                <Col xs={24} lg={12}>
                                                    <FormUpdateUserInfo
                                                        loading={this.props.ui.isLoading}
                                                        locale={this.props.locale}
                                                        orgCtx={this.props.user.orgCtx}
                                                        wrappedComponentRef={(propsRef: any) => this.formRefInfo = propsRef}
                                                        submit={this.submitUpdateInfo}
                                                        user={this.props.user}
                                                    />
                                                    <CanAccess permission={["ADMIN", "SUPER_ADMIN"]} userRoles={this.props.user ? this.props.user.orgCtx ? this.props.user.orgCtx.roles : [] : []}>
                                                        <Button icon="edit" type="link" onClick={() => this.setState({ visibleModalUpdateOrg: true })}>
                                                            Update organization
                                                        </Button>
                                                    </CanAccess>
                                                </Col>
                                            </Row>


                                        </Tabs.TabPane>
                                        <Tabs.TabPane tab="change password" key="2">
                                            <Col xs={24} lg={12}>
                                                <ChangePassword
                                                    loading={this.props.ui.isLoading}
                                                    locale={this.props.locale}
                                                    orgCtx={this.props.user.orgCtx}
                                                    wrappedComponentRef={(propsRef: any) => this.formRefChangePassword = propsRef}
                                                    changePwd={this.props.changePwd}
                                                    user={this.props.user}
                                                    commonActionFromDAL={this.props.commonActionFromDAL}
                                                    history={this.props.history}
                                                />
                                            </Col>

                                        </Tabs.TabPane>
                                        <Tabs.TabPane tab="EDM Send In Blue" key="3">
                                            <Row>
                                                <Col xs={24} lg={12}>
                                                    <FormEDMSendInBlueComponent
                                                        wrappedComponentRef={(form: any) => this.formRefEDM = form}
                                                        edm={this.props.edmSendInBlue}
                                                        onSubmit={(e: any) => {
                                                            e.preventDefault();
                                                            this.formRefEDM.props.form.validateFieldsAndScroll((err: any, values: any) => {
                                                                if (!err) {

                                                                    this.props.setGlobalLoading(true)
                                                                    this.props.updateEDMSendInBlue(values.name, values.apiKey)
                                                                        .then(() => {
                                                                            notification.success({
                                                                                message: BIGDATA_CONSTANT.message.default.title.success,
                                                                                description: "Update API KEY SendInBlue successful",
                                                                                icon: <Icon type="check-circle" style={{ color: getPrimaryColor(this.props.uiSetting) }} />
                                                                            })
                                                                            this.props.setGlobalLoading(false)
                                                                        })

                                                                }

                                                            });
                                                        }} />
                                                </Col>
                                            </Row>
                                        </Tabs.TabPane>
                                    </Tabs>
                                </Col>
                            </Row>
                            <ModalUpdateAdminClientOrg locale={this.props.locale}
                                orgCtx={this.props.user.orgCtx}
                                loading={this.props.ui.isLoading}
                                wrappedComponentRef={(propsRef: any) => this.formRef = propsRef}
                                submit={this.submitUpdateOrg} visible={this.state.visibleModalUpdateOrg}
                                onCancel={() => this.setState({ visibleModalUpdateOrg: false })} />
                        </div>
                    </div>

                </div>
            </DashboardPageLayout>
        )
    }

    render() {
        return (
            this.renderTheme()
        );
    }
}

export const UserProfileComponent = Form.create<Props>({ name: 'profile_form' })(UserProfileForm)