import { Alert, Avatar, Button, Card, Checkbox, Col, Divider, Empty, Form, Input, Modal, Row, Tooltip, Typography } from 'antd';
import * as React from 'react';

import "./company.scss";

import { LandingPage } from '../../layout/landing/LandingPage';

import { Location } from 'history';
import _ from 'lodash';
import queryString from 'query-string';
import Helmet from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { typeGetMe } from '../../common/actions/CommonAction';
import CommonTypeProps from '../../common/CommonTypeProps';
import { CommonResponse } from '../../redux/model/commonResponse';
import { TypeLangs } from '../../redux/reducer/localeReducer';
import { typeCurrentUserOrgs } from '../../redux/reducer/selectCurrentOrgReducer';
// import { FooterLang } from '../../shares/FooterLang';
import { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';
import { FormComponentProps } from 'antd/lib/form';
import { changeAntdTheme, generateThemeColor } from 'mini-dynamic-antd-theme';
import { TypeOrganization, TypeTermOfOrg, UISetting } from 'o2o_layout/lib/common/model';
import { GoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { BIGDATA_CONSTANT } from '../../common/constant';
import { getPrimaryColor } from '../../common/helper';
import { UserDAL } from '../../dal/UserDAL';
import { Footer } from '../../shares/Footer';
import { ConsoleUsersDAL } from '../users/ConsoleUserDAL';
import "./company.scss";
import { ListCompanySelectComponent } from './ListCompanySelectComponent';
import PopoverInviteUser from '../users/company/PopoverInviteUser';

interface SelectCompaniesComponentProps extends CommonTypeProps {
    location: Location,
    getCurrentUserOrgs: any,
    currentUserOrgs: typeCurrentUserOrgs,
    selectUserOrg: any,
    commonResponse: CommonResponse,
    getMe: typeGetMe,
    getUISettingCtx: any,
    uiSetting: UISetting
}

interface IpropsFilter extends FormComponentProps {
    onSearch: any
}

const SearchCompany = Form.create<IpropsFilter>()((props: IpropsFilter) => {
    const search = (e: any) => {
        props.onSearch(e)
    }
    const searchDebounce = React.useCallback(_.debounce(search, 500), [])
    return <>
        <Form>
            <Form.Item style={{ marginBottom: 0 }}>
                {props.form.getFieldDecorator("search")(
                    <Input.Search className='input-search-company-name' placeholder='Name' allowClear onChange={(e: any) => {
                        searchDebounce(e.target.value)
                    }}></Input.Search>
                )}
            </Form.Item>
        </Form>
    </>
})

export class SelectCompaniesComponent extends React.Component<SelectCompaniesComponentProps, any> {
    state = {
        url: '',
        visibleTermAccept: false,
        term: undefined as undefined | TypeTermOfOrg,
        selectedOrgCurrentAccpt: false,
        loadingModal: false,
        checkedTerm: false,
        selectedOrgName: "",
        type: "list",
        query: "",
        token: '',
        showMessage: false,
        message: '',
        intervalID: 0,
        totalInviteUser: 0,
    }

    componentDidMount() {
        //can implement lai
        this.updateLang()
        this.getCurrentUserOrgs();
        let query: any = queryString.parse(this.props.location.search);

        if (query && !_.isEmpty(query)) {
            let url: string = query.url
            if (url) {
                this.setState({ url: url })
            }
        }
        const intervalID = setInterval(() => {
            this.recapchaloop()
        }, 60 * 1000);
        this.setState({ intervalID: intervalID })
        this.countTotalInviteByStatus()
    }

    componentWillUnmount = () => {
        clearInterval(this.state.intervalID);
    }

    recapchaloop = () => {
        if (!this.capcha)
            return;
        const { executeRecaptcha } = (this.capcha)
        if (!executeRecaptcha) {
            console.log('Recaptcha has not been loaded');
            return;
        }
        executeRecaptcha('register').then((token: any) => {
            this.setState({
                token: token
            })
        })
    }


    getCurrentUserOrgs = () => {
        this.props.getCurrentUserOrgs()
            .then(() => {
                if (!this.props.currentUserOrgs.orgs.results.length) {

                    // this.props.history.push({
                    //     pathname: "/companies/empty",
                    //     search: queryString.stringify({
                    //         lang: queryString.parse(this.props.location.search).lang
                    //     })
                    // })
                }
            })
    }

    updateLang = () => {
        let qString = this.props.location.search;
        const parseQuery = queryString.parse(qString)

        if (parseQuery.lang) {
            // debugger
            const lang: TypeLangs = parseQuery.lang == 'vi' || parseQuery.lang == 'en' ? parseQuery.lang : 'vi';
            this.props.updateLocale(lang)
        }
    }

    successChangeLang = (key: string) => {
        let qString = this.props.location.search;
        const parseQuery = queryString.parse(qString)
        parseQuery.lang = key;
        qString = queryString.stringify(parseQuery);
        this.props.history.push({ pathname: this.props.location.pathname, search: qString })
    }

    getTerm = (orgId: string) => {
        let term: TypeTermOfOrg | undefined = this.props.currentUserOrgs.orgs.terms[orgId] || undefined
        return term
    }

    continuteToCDP = async (orgId: string, target: string, callback: any) => {
        if (!this.state.token) {
            this.setState({ loading: false, showMessage: true, message: "Capcha is required." })
            window.location.reload()
            return
        }

        let ipData;
        let userAgent = window.navigator.userAgent

        try {
            const ip = await this.props.commonActionFromDALV2(UserDAL.getIP);
            ipData = await (ip)
        } catch (error) {

        }

        const header = {
            "P-IP-ACCESS": ipData ? ipData.data || "" : "",
            "P-USR-AGENT": userAgent
        }

        this.props.selectUserOrg(orgId, header, this.state.token)
            .then(() => {
                changeAntdTheme(
                    generateThemeColor(BIGDATA_CONSTANT.primaryColorDelay)
                )
                Promise.all([
                    this.props.getMe(),
                    this.props.getUISettingCtx(),
                ])
                    .then((user: any) => {
                        this.props.setGlobalLoading(false)
                        this.setState({ pendingGUI: false })
                        if (this.props.uiSetting.themeColor) {
                            changeAntdTheme(
                                generateThemeColor(this.props.uiSetting.themeColor ? this.props.uiSetting.themeColor : BIGDATA_CONSTANT.primaryColor)
                            )
                        } else {
                            changeAntdTheme(
                                generateThemeColor(BIGDATA_CONSTANT.primaryColor)
                            )
                        }
                        if (this.state.url) {
                            window.location.href = this.state.url
                        } else {
                            this.props.history.push({
                                pathname: target,
                                search: queryString.stringify({
                                    lang: queryString.parse(this.props.location.search).lang
                                })
                            })
                        }

                        if (callback && typeof callback == "function") {
                            callback()
                        }


                    })
                    .catch((error: any) => {
                        this.props.setGlobalLoading(false)
                        changeAntdTheme(
                            generateThemeColor(BIGDATA_CONSTANT.primaryColor)
                        )
                    })

            })
    }
    checkboxTerm: any
    renderModalTerm = () => {
        return <Modal
            visible={this.state.visibleTermAccept}
            title="Terms of use"
            onCancel={() => {
                this.setState({
                    visibleTermAccept: false
                })
            }}
            footer={
                <Row gutter={[5, 5]}>
                    <Col xs={24} style={{ textAlign: "start", marginBottom: 10 }} >
                        <div>
                            <Checkbox checked={this.state.checkedTerm} onChange={(e: CheckboxChangeEvent) => {
                                this.setState({
                                    checkedTerm: e.target.checked
                                })
                            }} ref={(ref: any) => this.checkboxTerm = ref} defaultChecked={this.state.selectedOrgCurrentAccpt}

                            >I Agree</Checkbox>
                        </div>
                    </Col>
                    <Col style={{ textAlign: "right" }}>
                        <Button key="back" onClick={() => {
                            this.setState({
                                visibleTermAccept: false
                            })
                        }}>
                            Close
                        </Button>
                        <Button key="submit" type="primary" disabled={!this.state.checkedTerm} loading={this.state.loadingModal} onClick={() => {
                            if (this.checkboxTerm) {
                                let checked = this.checkboxTerm.rcCheckbox.input.checked

                                if (checked) {
                                    this.setState({ loadingModal: true })
                                    this.props.commonActionFromDAL(ConsoleUsersDAL, "acceptedTermOfOrg", this.state.term ? this.state.term.orgId : "")
                                        .then(() => {
                                            this.setState({ loadingModal: false })
                                            let targetUrl = "/my-account-info";

                                            const qString = this.props.location.search;
                                            const parseQuery = queryString.parse(qString)
                                            if (parseQuery.target) {
                                                if (parseQuery.target == 'my-account-info') {

                                                } else if (parseQuery.target == 'ga') {
                                                    targetUrl += '/ga';
                                                }

                                            }

                                            this.continuteToCDP(this.state.term ? this.state.term.orgId : "", targetUrl, undefined)
                                        })



                                } else {

                                }
                            }
                        }}>
                            Continue
                        </Button>
                    </Col>
                </Row>
            }
        >
            <div style={{ height: "50vh", overflowY: "scroll" }} dangerouslySetInnerHTML={{ __html: this.state.term ? this.state.term.term : `<div><h1>Legal Notice</h1><div>This information asset and information system is the property of ${this.state.selectedOrgName}.</div><div>The use of this system is governed by IT Policies under OGR.</div><div>It must be used responsibly and for authorized purposes only.</div><p>Unauthorized or improper use of this system may result in disciplinary action and/or civil charges/criminal penalties and/or prosecution of law. By continuing to use this system you indicated your awareness and consent to these terms and conditions of use.</div></div>` }} >

            </div>
        </Modal>
    }

    selectOrg = (item: TypeOrganization, target: string, callback: any) => {


        this.props.commonActionFromDAL(ConsoleUsersDAL, "checkUserAcceptedTermOfOrg", item.id)
            .then((resultCheckAccepted: any) => {
                //check or co term hay ko?
                let termOfOrg = this.getTerm(item.id);

                this.setState({
                    term: termOfOrg,
                    selectedOrgName: item.name,
                    selectedOrgCurrentAccpt: resultCheckAccepted && resultCheckAccepted.success ? resultCheckAccepted.accepted : false
                })
                //neu org co term va status == active va check appcepted = false thi show popup
                if (termOfOrg
                    && termOfOrg.active
                    && termOfOrg.term
                    && resultCheckAccepted
                    && resultCheckAccepted.success
                    && !resultCheckAccepted.accepted
                ) {
                    this.setState({
                        visibleTermAccept: true,
                        checkedTerm: false
                    })
                    if (callback) {
                        callback()
                    }
                } else {
                    this.continuteToCDP(item.id, target, callback)
                }



            })

    }


    countTotalInviteByStatus = async () => {
        try {
            const { results, success } = await this.props.commonActionFromDALV2(UserDAL.countTotalInviteByStatus);
            if (success) {
                this.setState({
                    totalInviteUser: results
                })
            }
        } catch (error) {
            console.log(error)
        }
    }
    showOrHideAlert = () => this.state.showMessage ? <Form.Item><Alert message={this.state.message} type="error" showIcon /></Form.Item> : null

    renderTheme() {
        let targetUrl = "/my-account-info";

        const qString = this.props.location.search;
        const parseQuery = queryString.parse(qString)
        if (parseQuery.target) {
            if (parseQuery.target == 'my-account-info') {

            } else if (parseQuery.target == 'ga') {
                targetUrl += '/ga';
            }

        }

        const companies = _.sortBy(this.props.currentUserOrgs.orgs.results, (item: TypeOrganization) => item.name)

        {/*@ts-ignore */ }
        const orgUIs = this.props.currentUserOrgs.orgs.orgUIs

        const companiesSearch = _.filter(companies, (item: TypeOrganization) => {
            return (item.name + " " + item.website + " " + item.id).toLowerCase().indexOf(this.state.query.toLowerCase()) >= 0

        })

        const { avatar, name } = this.props.user

        const handleGetTwoCharacter = (name: string) => {
            const splitFullName = name.split(" ")
            if (splitFullName && splitFullName.length >= 2) {
                const result = splitFullName.slice(0, 2).map(word => word.substring(0, 1)).join('');
                return result
            }
            return name.slice(0, 2).toUpperCase()
        }

        return (
            <div>
                <LandingPage {...this.props} isSelectCompanyPage={true}>
                    <div >
                        <Helmet>
                            <title>Select Company</title>
                            <meta name="description" content="" />
                        </Helmet>

                        <Form className="landing-form1">

                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <div>
                                    <Typography.Title className="o2o-title-gray " level={1}>
                                        <FormattedMessage id="app.organization.select" />
                                    </Typography.Title>

                                    <div style={{ display: "flex", alignItems: "center", marginBottom: 15 }}>
                                        <span >
                                            Select a Company to continue
                                        </span>

                                        {this.state.totalInviteUser > 0 && (
                                            <div>
                                                <Divider type="vertical" />
                                                <PopoverInviteUser
                                                    commonActionFromDALV2={this.props.commonActionFromDALV2}
                                                    onSuccess={() => {
                                                        this.props.setGlobalLoading(true)
                                                        setTimeout(() => {
                                                            this.countTotalInviteByStatus()
                                                            this.getCurrentUserOrgs();
                                                            this.props.setGlobalLoading(false)
                                                        }, 500);
                                                    }}
                                                    totalInviteUser={this.state.totalInviteUser}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div>
                                    <Avatar src={avatar} style={{ background: getPrimaryColor(this.props.uiSetting) }} size={60} shape={"circle"}>
                                        {handleGetTwoCharacter(name ? name : "")}
                                    </Avatar>
                                </div>
                            </div>

                            {this.showOrHideAlert()}


                            <GoogleReCaptcha

                                action="register" onVerify={(token: any) => {
                                    this.setState({
                                        token: token
                                    })
                                }} />

                            {companies.length >= 5 ? (
                                <Row type='flex' align='bottom' justify='space-between' gutter={[5, 5]} style={{ marginBottom: 10 }}>

                                    <Col xs={24}>
                                        <SearchCompany onSearch={(e: string) => {
                                            this.setState({
                                                query: e
                                            })
                                        }} />
                                    </Col>

                                </Row>
                            ) : null}

                            {companies.length >= 5 ? (

                                <div>
                                    <Row type='flex' gutter={[5, 5]}>
                                        {companiesSearch.length ? (
                                            companiesSearch.map((company: TypeOrganization) => {
                                                const themeColor = orgUIs && orgUIs[company.id] && orgUIs[company.id].themeColor !== null ? orgUIs[company.id].themeColor : "#ccc"
                                                return <Col xs={12}>
                                                    <Card size='small' hoverable onClick={() => {
                                                        this.props.setGlobalLoading(true)
                                                        this.setState({
                                                            selectedOrgName: company.name
                                                        })
                                                        this.selectOrg(company, targetUrl, () => {
                                                            this.props.setGlobalLoading(false)
                                                        })
                                                    }}>
                                                        <div style={{ display: "flex", alignItems: "center", gap: 10, overflow: "hidden" }}>
                                                            <div>
                                                                {/*@ts-ignore */}
                                                                <Avatar src={company.imageLink} size={50} shape={"circle"} style={{ backgroundColor: themeColor }}>
                                                                    {handleGetTwoCharacter(company.name)}
                                                                </Avatar>
                                                            </div>

                                                            <div style={{ overflow: "hidden" }}>
                                                                <Typography.Paragraph style={{ fontSize: 17, color: "#000", marginBottom: 0 }} ellipsis={true}>
                                                                    <Tooltip title={company.name} placement='topLeft'>
                                                                        <b>{company.name}</b>
                                                                    </Tooltip>
                                                                </Typography.Paragraph>
                                                                <Typography.Text type='secondary' ellipsis={true} style={{ display: "block" }}>
                                                                    {company.website}
                                                                </Typography.Text>
                                                            </div>

                                                        </div>

                                                    </Card>
                                                </Col>
                                            })
                                        ) : (
                                            <Col xs={24}>
                                                <Empty />
                                            </Col>
                                        )}
                                    </Row>
                                </div>
                            ) : (

                                <ListCompanySelectComponent
                                    actionSelect={this.selectOrg}
                                    dataSources={companiesSearch}
                                    targetUrl={targetUrl}
                                    orgs={this.props.currentUserOrgs.orgs}
                                />
                            )}

                            <br />
                            <Form.Item>
                                <Typography.Text><Link to="/login">
                                    <FormattedMessage id="app.login.different" />
                                </Link></Typography.Text>
                            </Form.Item>
                        </Form>

                        {this.renderModalTerm()}

                        {/* <div className="landing-form-footer" >
                            <FooterLang
                                mainProps={this.props}
                                onSuccess={this.successChangeLang}
                            />
                        </div> */}

                    </div>

                    <Footer />


                </LandingPage>



            </div>
        )
    }
    capcha: any
    render() {
        return (
            <GoogleReCaptchaProvider
                reCaptchaKey={BIGDATA_CONSTANT.reCapchaSiteKey}
                language={this.props.locale}
                ref={(e: any) => {
                    this.capcha = e
                }}
            >

                {this.renderTheme()}
            </GoogleReCaptchaProvider>
        );
    }
}

