// import { UserDAL, TypeRegisterUser } from '../../../dal/UserDAL';
import { UPDATE_ERROR } from '../../../common/actionType/actionType';
import { UserDAL, TypeRegisterUser } from '../../../dal/UserDAL';
import { IActionUI } from '../../../redux/reducer/uiReducer';
import { UPDATE_COMMON_RESPONSE, IActionCommonResponse } from '../../../redux/reducer/commonResponseReducer';
export const LOGIN_DATA = 'LOGIN_DATA'

export type typeUserLogin = (username: string, password: string, captcha: string, header: any) => any
export function userLogin<typeUserLogin>(username: string, password: string, captcha: string, header: any) {
    return (dispatch: any) => {

       
        return new Promise((resolve, reject) => {
            UserDAL.login(username, password, captcha, header).then(user => {
                
                //update thong tin user 1
                dispatch({
                    type: LOGIN_DATA,
                    user: user,
                });
                // // set authen key is true de router cho phep cac route can authen co the truy cap
                // dispatch({
                //     type: UPDATE_AUTHEN,
                //     payload: {
                //         isAuth: true
                //     }
                // })
                // reset error cua app ve 200
                dispatch({
                    type: UPDATE_ERROR,
                    errorStatus: 200,
                    errorResponse: null
                } as IActionUI)

                resolve(user);
            }, error => {
                reject(error);
            });
        });

    }
}

export function userLogin2ft(code: string, username: string) {
    return (dispatch: any) => {

       
        return new Promise((resolve, reject) => {
            UserDAL.login2fa(code, username).then(user => {
               
                dispatch({
                    type: UPDATE_COMMON_RESPONSE,
                    payload: user,
                } as IActionCommonResponse);
                
                resolve(user);
            }, error => {
                reject(error);
            });
        });

    }
}


export function userLogout() {
    return (dispatch: any) => {

       
        return new Promise((resolve, reject) => {
            UserDAL.logout().then(user => {
                
                //update thong tin user 1
                dispatch({
                    type: UPDATE_COMMON_RESPONSE,
                    payload: user,
                } as IActionCommonResponse);
               
                dispatch({
                    type: UPDATE_ERROR,
                    errorStatus: 200,
                    errorResponse: null
                } as IActionUI)

                resolve(user);
            }, error => {
                reject(error);
            });
        });

    }
}
export function userLogoutAll() {
    return (dispatch: any) => {

       
        return new Promise((resolve, reject) => {
            UserDAL.logoutAll().then(user => {
                
                //update thong tin user 1
                dispatch({
                    type: UPDATE_COMMON_RESPONSE,
                    payload: user,
                } as IActionCommonResponse);
               
                dispatch({
                    type: UPDATE_ERROR,
                    errorStatus: 200,
                    errorResponse: null
                } as IActionUI)

                resolve(user);
            }, error => {
                reject(error);
            });
        });

    }
}


export function forgotPassword(email: string, captcha: string) {
    return (dispatch: any) => {

       
        return new Promise((resolve, reject) => {
            UserDAL.forgotPassword(email, captcha).then(user => {
                
                //update thong tin user 1
                dispatch({
                    type: UPDATE_COMMON_RESPONSE,
                    payload: user,
                } as IActionCommonResponse);
               
                dispatch({
                    type: UPDATE_ERROR,
                    errorStatus: 200,
                    errorResponse: null
                } as IActionUI)

                resolve(user);
            }, error => {
                reject(error);
            });
        });

    }
}

export function registerUser(data: TypeRegisterUser) {
    return (dispatch: any) => {

       
        return new Promise((resolve, reject) => {
            UserDAL.register(data).then(user => {
                
                //update thong tin user 1
                dispatch({
                    type: UPDATE_COMMON_RESPONSE,
                    payload: user,
                } as IActionCommonResponse);
               
                dispatch({
                    type: UPDATE_ERROR,
                    errorStatus: 200,
                    errorResponse: null
                } as IActionUI)

                resolve(user);
            }, error => {
                reject(error);
            });
        });

    }
}
