import { bindActionCreators } from "redux";
import { getAllCustomFieldObjects } from "../../../common/actions/CommonAction";
import { O2OCommonContainer } from "../../../common/container/O2OContainer";
import { IState } from "../../../redux/reducer";
import ExportsHistoryComponent from "./ExportsHistoryComponent";


function mapStateToProps(rootState: IState) {
    return {
        allMoM: rootState.customFieldObjects
    };
}


function mapDispatchToProps(dispatch: any) {
    return {
        getAllCustomFieldObjects: bindActionCreators(getAllCustomFieldObjects,dispatch)
    };
}





export const ExportsHistoryContainer = O2OCommonContainer(ExportsHistoryComponent, mapStateToProps, mapDispatchToProps)