import { Form, Modal, Input, Select, Icon } from 'antd';
import React from "react";
import { FormComponentProps } from 'antd/lib/form';
import { FormattedMessage } from 'react-intl';
import { TypeOrgCtx } from 'o2o_layout/lib/common/model';


interface Props extends FormComponentProps {
    visible?: boolean,
    onCreate?: any,
    onCancel?: any,
    loading?: boolean,
    locale: any,
    submit: any,
    orgCtx: TypeOrgCtx | undefined
}

export const ModalUpdateAdminClientOrg = Form.create<Props>({ name: 'form_in_modal' })(
    // eslint-disable-next-line
    class extends React.Component<Props, any> {

        renderTheme = () => {
            const { getFieldDecorator } = this.props.form
            const { orgCtx } = this.props

            return (

                <Form layout="vertical">


                    <div className="register-wrapper">
                        <Form.Item label={
                            <FormattedMessage id="app.organization.form.name" />
                        }>
                            {getFieldDecorator('name', {
                                rules: [
                                    { required: true, message: this.props.locale.message['app.organization.form.error.name'] },
                                ],
                                initialValue: orgCtx ? orgCtx.organization.name : ''
                            })(
                                <Input prefix={<Icon type="edit" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder={this.props.locale.message['app.organization.form.placeholder.name']} />
                            )}
                        </Form.Item>

                        <Form.Item label={
                            <FormattedMessage id="app.organization.form.address" />
                        }>
                            {getFieldDecorator('address', {
                                rules: [{ required: true, message: this.props.locale.message['app.organization.form.error.address'] }],
                                initialValue: orgCtx ? orgCtx.organization.address : ''
                            })(
                                <Input prefix={<Icon type="edit" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder={this.props.locale.message['app.organization.form.placeholder.address']} />
                            )}
                        </Form.Item>


                        <Form.Item label={
                            <FormattedMessage id="app.organization.form.industry" />
                        }>
                            {getFieldDecorator('industry', {
                                rules: [{ required: true, message: this.props.locale.message['app.organization.form.error.industry'] }],
                                initialValue: orgCtx ? orgCtx.organization.industry : ''
                            })(
                                <Select placeholder={this.props.locale.message['app.organization.form.placeholder.industry']} showSearch>
                                    <Select.Option key="art">Art and Entertainment</Select.Option>
                                    <Select.Option key="auto">Automotive</Select.Option>
                                </Select>
                            )}
                        </Form.Item>

                        {/* <Form.Item label={
                            <FormattedMessage id="app.organization.form.employees" />
                        }>
                            {getFieldDecorator('employees', {
                                rules: [{ required: true, message: this.props.locale.message['app.organization.form.error.employees'] }],
                                initialValue: orgCtx ? orgCtx.organization.companySize : ''
                            })(
                                <Select placeholder={this.props.locale.message['app.organization.form.placeholder.employees']} showSearch>
                                    <Select.Option key="1-5">01-05 Employees</Select.Option>
                                    <Select.Option key="5-20">05-20 Employees</Select.Option>
                                    <Select.Option key="250">> 250 Employees</Select.Option>
                                </Select>
                            )}
                        </Form.Item> */}

                        <Form.Item label="Website">
                            {getFieldDecorator('website', {
                                rules: [{ required: true, message: this.props.locale.message['app.organization.form.error.website'] }],
                                initialValue: orgCtx ? orgCtx.organization.website : ''
                            })(
                                <Input prefix={<Icon type="edit" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="https://dmp.vn" />
                            )}
                        </Form.Item>

                        <Form.Item label="Phone">
                            {getFieldDecorator('phone', {
                                rules: [{ required: true, message: this.props.locale.message['app.organization.form.error.phone'] }],
                                initialValue: orgCtx ? orgCtx.organization.phone : ''
                            })(
                                <Input prefix={<Icon type="edit" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="090 +++ ++++" />
                            )}
                        </Form.Item>

                    </div >

                </Form>


            )
        }

        render() {
            const { visible, onCancel, loading, submit } = this.props;

            return (
                <Modal
                    title="Update Organization"
                    visible={visible}
                    onOk={submit}
                    okText="Update"
                    confirmLoading={loading}
                    onCancel={onCancel}
                >


                    {this.renderTheme()}


                </Modal>
            );
        }
    },
);
