
import { Avatar, Icon, List } from "antd"
import { truncateLongText } from "o2o_layout"
import { OrganizationsNotContent, TypeOrganization } from "o2o_layout/lib/common/model"
import * as React from "react"

interface Props {
    dataSources: TypeOrganization[]
    actionSelect: (item: TypeOrganization, targetUrl: string, callback: any) => void
    targetUrl: string
    orgs: OrganizationsNotContent
}

export const ListCompanySelectComponent = (props: Props) => {
    const { dataSources, actionSelect, targetUrl } = props
    const [loading, setLoading] = React.useState(false)

    const handleGetTwoCharacter = (name: string) => {
        const splitName = name.split(" ")
        if (splitName && splitName.length >= 2) {
            const result = splitName.slice(0, 2).map(word => word.substring(0, 1)).join('');
            return result
        }
        return name.slice(0, 2).toUpperCase()
    }
    {/*@ts-ignore */ }
    const orgUIs = props.orgs.orgUIs

    return (
        <List
            loading={loading}
            className="list-company"
            dataSource={dataSources}
            renderItem={(item: TypeOrganization) => {
                const themeColor = orgUIs && orgUIs[item.id] && orgUIs[item.id].themeColor !== null ? orgUIs[item.id].themeColor : "#ccc"

                return (
                    <List.Item key={item.id} onClick={() => {
                        setLoading(true)
                        actionSelect(item, targetUrl, () => {
                            setLoading(false)
                        })
                    }}>
                        <List.Item.Meta
                            avatar={
                                <>
                                    {/*@ts-ignore */}
                                    <Avatar src={item.imageLink} size={50} shape={"circle"} style={{ backgroundColor: themeColor }}>
                                        {handleGetTwoCharacter(item.name)}
                                    </Avatar>
                                </>
                            }
                            title={<span style={{ fontSize: 17, fontWeight: 500, color: "#000" }}>{item.name}</span>}
                            description={truncateLongText(item.website || "", 40)}
                        />
                        <Icon type="right" style={{ fontSize: '20px', color: "#888" }}></Icon>
                    </List.Item>
                )
            }}
        />
    )
}