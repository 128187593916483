import { connect } from 'react-redux';
import { IState } from '../../redux/reducer/index';
import { IntlProvider } from 'react-intl';


function mapStateToProps(rootState: IState) {
    return {
        locale: rootState.locale.locale,
        messages: rootState.locale.message,
    };
}

/**
 * HOC wrapper map root state locale và message translation để các componnent khác có thể update ngôn ngữ thông
 * qua hàm update locale
 */
export const O2OIntlProvider = connect<any, any>(mapStateToProps)(IntlProvider)