import { IResponseBigDataVN, TypeResultsBigData } from "o2o_layout/lib/common/interface";

export type TypeCronjob = {
    createdAt?: number;
    createdBy?: string;
    days?: number[];
    endpointMethod?: string;
    endpointUrl?: string;
    gmt?: number;
    hours?: number[];
    id?: string;
    minutes?: number[];
    monthDayHoursGmts?: string[];
    months?: number[];
    name?: string;
    orgId?: string;
    status?: string;
    updatedAt?: number;
    updatedBy?: string;
    validFromTime?: number;
    validToTime?: number;
    extParam?: { [key: string]: string };
}



export class Cronjobs implements IResponseBigDataVN<TypeCronjob> {
    success: boolean;
    results: TypeResultsBigData<TypeCronjob>;
    constructor() {
        this.success = false
        this.results = {
            content: [],
            timeTaken: 0,
            currentTime: 0,
            page: {
                pageSize: 0,
                currentPage: 0,
                totalRecords: 0,
                totalPages: 0
            },
            extras: {}
        }
    }
}

export type TypePayloadAddEditCronjob = {
    days: number[];
    endpointMethod: string;
    endpointUrl: string;
    extParam: { [key: string]: string };
    gmt: number;
    hours: number[];
    minutes: number[];
    monthDayHoursGmts: string[];
    months: number[];
    name: string;
    orgId: string;
    status: string;
    validFromTime: number;
    validToTime: number;
}

export type TypeCronjobHistory = {
    configId?: string,
    createdAt?: number,
    createdBy?: string,
    cronjobEndTime?: number,
    cronjobStartTime?: number,
    duration?: number,
    fromTime?: number,
    id?: string,
    method?: string,
    toTime?: number,
    totalInvalidRecord?: number,
    totalRecords?: number,
    totalValidRecords?: number,
    url?: string
}

export class CronjobHistories implements IResponseBigDataVN<TypeCronjobHistory>{
    success: boolean;
    results: TypeResultsBigData<TypeCronjobHistory>;
    configs: {
        [key: string]: TypePayloadAddEditCronjob
    }
    constructor() {
        this.configs = {}
        this.success = false
        this.results = {
            content: [],
            timeTaken: 0,
            currentTime: 0,
            page: {
                pageSize: 0,
                currentPage: 0,
                totalRecords: 0,
                totalPages: 0
            },
            extras: {}
        }
    }
}
