import { Button, Col, Drawer, Form, Input, Row } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import { WrappedFormUtils } from 'antd/lib/form/Form'
import TextArea from 'antd/lib/input/TextArea'
import { useEffect, useState } from 'react'
import { IActionUI, SHOW_COMMON_ERROR } from '../../../redux/reducer/uiReducer'
import { useDispatch } from '../../../redux/store'
import ButtonBackDrawer from '../../../shares/ButtonBackDrawer'
import { CommonNotificationAddSuccessfully, CommonNotificationSaveError, CommonNotificationUpdateSuccessfully } from '../../../shares/CommonNotification'
import { Confirm2FA } from '../../../shares/Confirm2FA'
import { O2OFormItem } from '../../../shares/O2OFormItem'
import { SystemConfigurationDAL } from './DAL'
import React from 'react'
import { get } from 'lodash'
import { TypeSystemVariable } from './model'

interface IDrawerAddUpdateConfigProps extends FormComponentProps {
    onClose: () => void
    onSuccess: () => void
    visible: boolean
    selected?: TypeSystemVariable
    commonActionFromDALV2: any
}

export const DrawerAddUpdateConfig = Form.create<IDrawerAddUpdateConfigProps>()(
    ({ onClose, visible, selected, form, commonActionFromDALV2, onSuccess }: IDrawerAddUpdateConfigProps) => {
        const [loading, setLoading] = useState(false)

        const [visible2FA, setVisible2FA] = useState(false)
        const dispatch = useDispatch()

        const onSave = () => {
            form.validateFields((err: any, values: any) => {
                if (!err) {
                    setVisible2FA(true)
                }
            })
        }

        useEffect(() => {
            if (!visible) {
                form.resetFields()
            }
        }, [visible])


        return <Drawer
            bodyStyle={{ marginBottom: 100 }}
            title={<span>
                <ButtonBackDrawer onClick={onClose} />
                {selected ? "Update" : "Add"}
            </span>}
            visible={visible}
            onClose={() => {
                onClose()
            }}
            maskClosable={false}
            width={600}
        >
            <Form hideRequiredMark colon={false}>
                <Row type='flex' gutter={[10, 10]}>
                    <Col xs={24}>
                        <O2OFormItem label="Key">
                            {form.getFieldDecorator("id", {
                                initialValue: get(selected, "id"),
                                rules: [{
                                    required: true,
                                    message: "Required"
                                }]
                            })(<Input disabled={selected ? true : false} allowClear placeholder='Enter' />)}
                        </O2OFormItem>
                    </Col>
                    <Col xs={24}>
                        <O2OFormItem label="Value">
                            {form.getFieldDecorator("value", {
                                initialValue: get(selected, "value"),
                                rules: [{
                                    required: true,
                                    message: "Required"
                                }]
                            })(<TextArea rows={5} allowClear placeholder='Enter' />)}
                        </O2OFormItem>
                    </Col>
                </Row>
            </Form>

            <div
                style={{
                    position: 'absolute',
                    right: 0,
                    bottom: 0,
                    width: '100%',
                    borderTop: '1px solid #e9e9e9',
                    padding: '10px 16px',
                    background: '#fff',
                    textAlign: 'right',
                }}
            >
                <Button onClick={onClose} style={{ marginRight: 5 }}>
                    Cancel
                </Button>

                <Button onClick={onSave} type="primary" loading={loading}>
                    {selected ? "Update" : "Add"}
                </Button>

            </div>

            <Confirm2FA
                visible={visible2FA}
                onClose={() => {
                    setVisible2FA(false)
                }}
                okModal={async (e: any, f: WrappedFormUtils<any>) => {
                    setLoading(true)
                    const codeOTP = f.getFieldValue("codeOTP")
                    let result
                    if (selected) {
                        result = commonActionFromDALV2(SystemConfigurationDAL.update, form.getFieldsValue(), codeOTP)
                    } else {
                        result = commonActionFromDALV2(SystemConfigurationDAL.add, form.getFieldsValue(), codeOTP)
                    }

                    try {
                        const response = await result
                        if (response.success) {
                            onSuccess()
                            onClose()
                            if (selected) {
                                CommonNotificationUpdateSuccessfully()
                            } else {
                                CommonNotificationAddSuccessfully()
                            }
                        } else {
                            CommonNotificationSaveError(response["errorCode"])
                        }
                        setLoading(false)
                        setVisible2FA(false)
                    } catch (error) {
                        setVisible2FA(false)
                        setLoading(false)
                        dispatch({
                            type: SHOW_COMMON_ERROR,
                            commonError: error
                        } as IActionUI)
                    }
                }}
                loading={loading}
                okText={"Add"}
                type={"add-key"}
            />
        </Drawer>
    }
)
