import { O2OCommonContainer } from "../../../common/container/O2OContainer";
import { IState } from "../../../redux/reducer";
import SuperPermissionComponent from "./SuperPermissionComponent";


function mapStateToProps(rootState: IState) {
    return {

    };
}


function mapDispatchToProps(dispatch: any) {
    return {

    };
}

export const SuperPermissionContainer = O2OCommonContainer(SuperPermissionComponent, mapStateToProps, mapDispatchToProps)