export type TypeEDMSendInBlue = {
    "apiKey"?: string,
    "createdAt"?: number,
    "createdBy"?: string,
    "id"?: string,
    "name"?: string,
    "updatedAt"?: number,
    "updatedBy"?: string
}

export const UPDATE_EDM_SEND_IN_BLUE = "UPDATE_EDM_SEND_IN_BLUE";
export const GET_EDM_SEND_IN_BLUE = "GET_EDM_SEND_IN_BLUE";

const empty = (): TypeEDMSendInBlue => {
    return {} as TypeEDMSendInBlue;
}

export interface IActionEDMSendInBlue {
    type: string,
    payload: TypeEDMSendInBlue
}

export const edmSendInBlueReducer = (state = empty(), action: IActionEDMSendInBlue) => {
    switch (action.type) {
        case UPDATE_EDM_SEND_IN_BLUE: 
            return {...state, ...action.payload}
        case GET_EDM_SEND_IN_BLUE: 
            return {...state, ...action.payload}
        default:
            return state;
    }
};