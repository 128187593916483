import { TypeResultsBigData, IResponseBigDataVN, TypePageBigData } from '../../../../common/IResponseBigData';
import { IResponseHaveNotContent } from 'o2o_layout/lib/common/interface/IBigDataResponse';

export type LinkOrg = {
    id: string
    "integrationName": string,
    "linkedOrgId": string,
    "linkedOrgInfo": string,
    "linkedOrgName": string,
    "o2oCdpOrgId": string,
    apiKey: string,
    linkedSource: string,
    createdAt: number
    createdBy: string
    updatedAt: number
    updatedBy: string,
    
}

export class LinkOrgs implements IResponseBigDataVN<LinkOrg> {
    success: boolean;
    results: TypeResultsBigData<LinkOrg>

    constructor() {
        this.success = false;
        this.results = {
            content: [],
            timeTaken: 0,
            currentTime: 0,
            page: {
                pageSize: 0,
                currentPage: 0,
                totalPages: 0,
                totalRecords: 0
            } as TypePageBigData,
            extras: {}
        }
    }

    isEmpty() {
        if(this.results.content.length)
            return false;
        return true
    }
}



export class LinkOrgsNoContent implements IResponseHaveNotContent<LinkOrg>{
    success: boolean;
    results: LinkOrg[]

    constructor() {
        this.success = false;
        this.results = []
    }
}
