import { Breadcrumb, Col, Divider, Layout, Menu, notification, Row } from 'antd';
import _, { isString } from 'lodash';
import queryString from "query-string";
import * as React from 'react';
import { Link } from 'react-router-dom';
import { BIGDATA_CONSTANT } from '../../common/constant';
import { getCurrentURL } from '../../common/helper';
import { DashboardPageLayout } from '../../layout/dashboard/DashboardPageLayout';
import { MetaHeader } from '../../shares/MetaHeader';
import { FormImportSystemSystemSetting } from './FormImportSystemSetting';
import { FormAddPangoTemplate } from './manage_object/FormAddPangoTemplate';
import { SystemDAL } from './SystemDAL';
import { IPropsSystemSettingComponent } from './SystemSettingContainer';
import ZMALiveVersionComponent from './zma-live-version/ZMALiveVersionComponent';
import { FormImportListZMAJson } from './zma/FormImportListZMAJson';
import SystemConfigurationComponent from './system-configuration/SystemConfigurationComponent';
import CronjobComponent from './cronjob/CronjobComponent';

export const SystemSettingComponent = (props: IPropsSystemSettingComponent) => {

    React.useEffect(() => {
        const parsed = queryString.parse(props.location.search);
        if (_.isString(parsed["tab"])) {
            setSelectedMenu(parsed["tab"])
        } else {
            setSelectedMenu("system")
        }
        if (parsed.subTab && isString(parsed.subTab)) {
            setSelectedSubMenu(parsed.subTab)
        } else {
            if (parsed["tab"] === "zalo-mini-app") {
                setSelectedSubMenu("zma-live-version")
            } else {
                setSelectedSubMenu("support")
            }
        }
    }, [props.location])

    React.useEffect(() => {
        let timer = setTimeout(() => {
            props.updateMeta({
                name: BIGDATA_CONSTANT.trackTitle.ConsoleSystem,
                title: BIGDATA_CONSTANT.trackTitle.ConsoleSystem,
                app: "ACCOUNT",
                url: getCurrentURL(props.location)
            })
            clearTimeout(timer)
        }, 5000)

        return () => {
            clearTimeout(timer)
        }

    }, [props.location])

    const [selectedMenu, setSelectedMenu] = React.useState("");
    const [selectedSubMenu, setSelectedSubMenu] = React.useState("")

    const renderBreadCrumb = () => {
        const getBreadCumItem = () => {
            switch (selectedMenu) {
                case "system":
                    return "System"
                case "zalo-mini-app":
                    return "Zalo Mini App"
                default:
                    return null
            }
        }

        return (
            <Row type="flex" justify="space-between" align="middle" >
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/">Home</Link>
                    </Breadcrumb.Item>

                    <Breadcrumb.Item>System setting</Breadcrumb.Item>
                    {
                        selectedMenu && <Breadcrumb.Item>{getBreadCumItem()}</Breadcrumb.Item>
                    }
                </Breadcrumb>

            </Row>

        )
    }


    const renderTheme = () => {
        return (
            <DashboardPageLayout  {...props} selectedSubMenu="console-super" selectedLeftNav="menu_import" >
                <div className="org-page">

                    <MetaHeader title={props.ui.meta.title} />

                    <div className="contentLayout">
                        {renderBreadCrumb()}
                        <br />
                        <div className="containerInner">

                            <Layout style={{ minHeight: "400px" }}>
                                <Layout.Sider width={200} style={{ background: "#fff", minHeight: "400px" }}>

                                    <Menu style={{ background: "#fff", minHeight: "400px", height: "inherit" }} theme='light' selectedKeys={[selectedMenu]} >
                                        <Menu.Item key={"system"} >
                                            <Link to={{
                                                pathname: "console-system-setting",
                                                search: "tab=system"
                                            }}>
                                                System
                                            </Link>
                                        </Menu.Item>
                                        <Menu.Item key={"zalo-mini-app"}>
                                            <Link to={{
                                                pathname: "console-system-setting",
                                                search: "tab=zalo-mini-app"
                                            }}>
                                                Zalo Mini App
                                            </Link>
                                        </Menu.Item>
                                        <Menu.Item key={"cronjob"}>
                                            <Link to={{
                                                pathname: "console-system-setting",
                                                search: "tab=cronjob"
                                            }}>
                                                Cronjob
                                            </Link>
                                        </Menu.Item>
                                    </Menu>
                                </Layout.Sider>
                                <Layout.Content style={{ paddingLeft: 10, overflow: "hidden" }}>

                                    {selectedMenu === "system" && <Layout>
                                        <Layout.Sider width={200} style={{ background: "#fff", minHeight: "400px", marginRight: 12 }}>
                                            <Menu style={{ background: "#fff", minHeight: "400px", height: "inherit" }} theme='light' selectedKeys={[selectedSubMenu]} >
                                                <Menu.Item key={"support"}>
                                                    <Link to={{
                                                        pathname: "console-system-setting",
                                                        search: "tab=system&subTab=support"
                                                    }}>
                                                        Support Connection
                                                    </Link>
                                                </Menu.Item>
                                                <Menu.Item key={"segment"}>
                                                    <Link to={{
                                                        pathname: "console-system-setting",
                                                        search: "tab=system&subTab=segment"
                                                    }}>
                                                        Segment Setting
                                                    </Link>
                                                </Menu.Item>
                                                <Menu.Item key={"manage_object"}>
                                                    <Link to={{
                                                        pathname: "console-system-setting",
                                                        search: "tab=system&subTab=manage_object"
                                                    }}>
                                                        Managed Objects
                                                    </Link>
                                                </Menu.Item>
                                                <Menu.Item key={"system_configuration"}>
                                                    <Link to={{
                                                        pathname: "console-system-setting",
                                                        search: "tab=system&subTab=system_configuration"
                                                    }}>
                                                        System Configuration
                                                    </Link>
                                                </Menu.Item>
                                            </Menu>
                                        </Layout.Sider>
                                        <Layout.Content style={{ overflow: "hidden" }}>
                                            {selectedSubMenu == "support" ? (
                                                <Row gutter={[5, 10]}>
                                                    <Col xs={24}>
                                                        <FormImportSystemSystemSetting
                                                            label=''
                                                            title={"Import Support Connection"}
                                                            onSubmit={(values: any, callback: any) => {
                                                                props.commonActionFromDAL(SystemDAL, "importSupportedConnection", values.file.file)
                                                                    .then((e: any) => {
                                                                        callback()
                                                                        if (e.success) {
                                                                            notification.success({
                                                                                message: "Success",
                                                                                description: _.get(e, "message", "Import support connection successfully.")
                                                                            })
                                                                        } else {
                                                                            notification.error({
                                                                                message: "Error",
                                                                                description: _.get(e, "message", "Something was wrong.")
                                                                            })
                                                                        }
                                                                    })
                                                                    .catch(() => {
                                                                        callback()
                                                                    })
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col xs={24}>
                                                        <FormImportSystemSystemSetting
                                                            label='Enter Id'
                                                            title='Delete Support Connection'
                                                            isDeleteSupportConnection={true}
                                                            labelButton="Delete"
                                                            onSubmit={(values: any, callback: any) => {
                                                                props.commonActionFromDAL(SystemDAL, "deleteSupportConnection", values.id)
                                                                    .then((e: any) => {
                                                                        callback()
                                                                        if (e.success) {
                                                                            notification.success({
                                                                                message: "Success",
                                                                                description: _.get(e, "message", "Delete successfully.")
                                                                            })
                                                                        } else {
                                                                            notification.error({
                                                                                message: "Error",
                                                                                description: _.get(e, "message", "Something was wrong.")
                                                                            })
                                                                        }
                                                                    })
                                                                    .catch(() => {
                                                                        callback()
                                                                    })
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                            ) : null}

                                            {selectedSubMenu == "segment" ? (
                                                <Row gutter={[5, 10]}>
                                                    <Col xs={24}>
                                                        <FormImportSystemSystemSetting
                                                            label=''
                                                            title='Import Segment Meta'
                                                            onSubmit={(values: any, callback: any) => {
                                                                props.commonActionFromDAL(SystemDAL, "importSegmentMeta", values.file.file)
                                                                    .then((e: any) => {
                                                                        callback()
                                                                        if (e.success) {
                                                                            notification.success({
                                                                                message: "Success",
                                                                                description: _.get(e, "message", "Import successfully.")
                                                                            })
                                                                        } else {
                                                                            notification.error({
                                                                                message: "Error",
                                                                                description: _.get(e, "message", "Something was wrong.")
                                                                            })
                                                                        }
                                                                    })
                                                                    .catch(() => {
                                                                        callback()
                                                                    })
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col xs={24}>
                                                        <FormImportSystemSystemSetting
                                                            label='Enter Meta Id'
                                                            title='Delete Segment Meta'
                                                            isDeleteSupportConnection={true}
                                                            labelButton="Delete"
                                                            onSubmit={(values: any, callback: any) => {
                                                                props.commonActionFromDAL(SystemDAL, "deleteSegmentMeta", values.id)
                                                                    .then((e: any) => {
                                                                        callback()
                                                                        if (e.success) {
                                                                            notification.success({
                                                                                message: "Success",
                                                                                description: _.get(e, "message", "Delete successfully.")
                                                                            })
                                                                        } else {
                                                                            notification.error({
                                                                                message: "Error",
                                                                                description: _.get(e, "message", "Something was wrong.")
                                                                            })
                                                                        }
                                                                    })
                                                                    .catch(() => {
                                                                        callback()
                                                                    })
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                            ) : null}
                                            {selectedSubMenu == "manage_object" ? (
                                                <Row gutter={[5, 10]}>
                                                    <Col xs={24}>
                                                        <FormImportSystemSystemSetting
                                                            label=''
                                                            isMoM={true}
                                                            title={
                                                                <React.Fragment>
                                                                    MOM Import  <Divider type='vertical' /> <a target='_blank' href='https://docs.google.com/spreadsheets/d/1lo-tJm7UxocRNFra29IrFlOFNY20GJitNjkg2avsw8c/edit#gid=0'>Link</a>
                                                                </React.Fragment>
                                                            }
                                                            onSubmit={(values: any, callback: any) => {
                                                                props.commonActionFromDAL(SystemDAL, "importMOM", values.file.file)
                                                                    .then((e: any) => {
                                                                        callback()
                                                                        if (e.success) {
                                                                            notification.success({
                                                                                message: "Success",
                                                                                description: _.get(e, "message", "Import successfully.")
                                                                            })
                                                                        } else {
                                                                            notification.error({
                                                                                message: "Error",
                                                                                description: _.get(e, "message", "Something was wrong.")
                                                                            })
                                                                        }
                                                                    })
                                                                    .catch(() => {
                                                                        callback()
                                                                    })
                                                            }}

                                                        />
                                                    </Col>
                                                    <Col xs={24}>
                                                        <FormImportSystemSystemSetting
                                                            isMoMField={true}

                                                            label=''
                                                            title={
                                                                <React.Fragment>
                                                                    MOM Field Import  <Divider type='vertical' /> <a target='_blank' href='https://docs.google.com/spreadsheets/d/1_CQOu9oMAohRO8t27myneztEJCTzLQjTUD5WuvK62H4/edit#gid=1480763222'>Link 1</a><Divider type='vertical' /> <a target='_blank' href='https://docs.google.com/spreadsheets/d/1_9KS9ZniFwNeIyw18ainUj_0nxlrIxtU7QgQSbx_OCk/edit#gid=1139351055'>Link 2</a>
                                                                </React.Fragment>
                                                            }
                                                            onSubmit={(values: any, callback: any) => {
                                                                props.commonActionFromDAL(SystemDAL, "importMOMField", values.file.file, values.objectModel)
                                                                    .then((e: any) => {
                                                                        callback()
                                                                        if (e.success) {
                                                                            notification.success({
                                                                                message: "Success",
                                                                                description: _.get(e, "message", "Import successfully.")
                                                                            })
                                                                        } else {
                                                                            notification.error({
                                                                                message: "Error",
                                                                                description: _.get(e, "message", "Something was wrong.")
                                                                            })
                                                                        }
                                                                    })
                                                                    .catch(() => {
                                                                        callback()
                                                                    })
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col xs={24}>
                                                        <FormAddPangoTemplate
                                                            commonActionFromDALV2={props.commonActionFromDALV2}
                                                        />
                                                    </Col>
                                                    <Col xs={24}>
                                                        <FormImportSystemSystemSetting
                                                            label='Enter Id'
                                                            title='Delete Pango Template'
                                                            isDeleteSupportConnection={true}
                                                            labelButton="Delete"
                                                            onSubmit={(values: any, callback: any) => {
                                                                props.commonActionFromDAL(SystemDAL, "deletePangoTemplate", values.id)
                                                                    .then((e: any) => {
                                                                        callback()
                                                                        if (e.success) {
                                                                            notification.success({
                                                                                message: "Success",
                                                                                description: _.get(e, "message", "Delete successfully.")
                                                                            })
                                                                        } else {
                                                                            notification.error({
                                                                                message: "Error",
                                                                                description: _.get(e, "message", "Something was wrong.")
                                                                            })
                                                                        }
                                                                    })
                                                                    .catch(() => {
                                                                        callback()
                                                                    })
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                            ) : null}
                                            {
                                                selectedSubMenu === "system_configuration" ?
                                                    <SystemConfigurationComponent {...props} />
                                                    : null
                                            }
                                        </Layout.Content>
                                    </Layout>}



                                    {
                                        selectedMenu === "zalo-mini-app" && <Layout>
                                            <Layout.Sider width={200} style={{ background: "#fff", minHeight: "400px", marginRight: 12 }}>
                                                <Menu style={{ background: "#fff", minHeight: "400px", height: "inherit" }} theme='light' selectedKeys={[selectedSubMenu]} >
                                                    <Menu.Item key={"zma-live-version"}>
                                                        <Link to={{
                                                            pathname: "console-system-setting",
                                                            search: "tab=zalo-mini-app&subTab=zma-live-version"
                                                        }}>
                                                            ZMA Live Version
                                                        </Link>
                                                    </Menu.Item>
                                                    <Menu.Item key={"zma-library"}>
                                                        <Link to={{
                                                            pathname: "console-system-setting",
                                                            search: "tab=zalo-mini-app&subTab=zma-library"
                                                        }}>
                                                            ZMA Library
                                                        </Link>
                                                    </Menu.Item>
                                                </Menu>
                                            </Layout.Sider>

                                            <Layout.Content style={{ overflow: "hidden" }}>

                                                {selectedSubMenu === "zma-library" && <>
                                                    <Row gutter={[5, 10]}>
                                                        <Col xs={24}>
                                                            <FormImportListZMAJson
                                                                commonActionFromDALV2={props.commonActionFromDALV2}
                                                            />
                                                        </Col>
                                                        <Col xs={24}>
                                                            <FormImportSystemSystemSetting
                                                                label='Enter Id'
                                                                title='Delete Support Connection'
                                                                isDeleteSupportConnection={true}
                                                                labelButton="Delete"
                                                                onSubmit={(values: any, callback: any) => {
                                                                    props.commonActionFromDAL(SystemDAL, "deleteSupportConnectionZMA", values.id)
                                                                        .then((e: any) => {
                                                                            callback()
                                                                            if (e.success) {
                                                                                notification.success({
                                                                                    message: "Success",
                                                                                    description: _.get(e, "message", "Delete successfully.")
                                                                                })
                                                                            } else {
                                                                                notification.error({
                                                                                    message: "Error",
                                                                                    description: _.get(e, "message", "Something was wrong.")
                                                                                })
                                                                            }
                                                                        })
                                                                        .catch(() => {
                                                                            callback()
                                                                        })
                                                                }}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </>}

                                                {selectedSubMenu === "zma-live-version" && <ZMALiveVersionComponent {...props} />
                                                }


                                            </Layout.Content>
                                        </Layout>
                                    }



                                    {
                                        selectedMenu === "cronjob" && <CronjobComponent {...props} />
                                    }



                                </Layout.Content>
                            </Layout>




                        </div>
                    </div>

                </div>
            </DashboardPageLayout>
        )
    }



    const render = () => {
        return (
            <React.Fragment>
                {renderTheme()}
            </React.Fragment>

        );
    }

    return render()
}

