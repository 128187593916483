import React from 'react'
import { PermistionResponse, TypeCriteriaPermission, TypePermissionGroup, TypePermistion } from '../model/permissions'
import { Button, Col, Drawer, Form, Icon, Input, Row, Table } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import { O2OFormItem } from '../../../../shares/O2OFormItem'
import { ICommonPayload } from '../../../../common/CommonTypeProps'
import { useAsyncRetry } from 'react-use'
import { PermissionDAL } from '../dal/PermissionDAL'
import { InfoList, LongTextTd, O2OCustomPaging } from 'o2o_layout'
import { ColumnProps } from 'antd/lib/table'
import DrawerViewJSON from '../../../../shares/DrawerViewJSON'

interface Iprops {
    visible: boolean,
    onClose: () => void,
    item?: TypePermissionGroup
    commonActionFromDALV2: any
}

interface IFormFilterProps extends FormComponentProps {
    onSearch: (values: any) => void
    loading: boolean
}

const FormFilterComponent = Form.create<IFormFilterProps>()((props: IFormFilterProps) => {
    const { getFieldDecorator } = props.form

    const onSearch = () => {
        props.form.validateFields((err: any, values: any) => {
            if (!err) {
                props.onSearch(values)
            }
        })
    }

    return (
        <Form colon={false} layout="horizontal" title="Search">
            <Row gutter={[5, 5]} type="flex" justify="start">
                <Col xs={20} style={{ flexGrow: 1 }}>
                    <O2OFormItem label="Info">
                        {getFieldDecorator('query', {
                        })(
                            <Input.Search onPressEnter={onSearch} placeholder="Enter" allowClear></Input.Search>
                        )}
                    </O2OFormItem>
                </Col>
                <Col>
                    <O2OFormItem label={<span style={{ color: "#fff" }}>Search</span>}>
                        <Button loading={props.loading} onClick={onSearch} type="primary" icon="search">Search</Button>
                    </O2OFormItem>

                </Col>
            </Row>
        </Form>
    )
})

const initCriteria: ICommonPayload<TypeCriteriaPermission> = {
    loading: false,
    type: "",
    payload: { page: 0, pageSize: 10 } as TypeCriteriaPermission,
}

const SEARCH_PERMISSION = "SEARCH_PERMISSION"

const reducerCriteria = (state: ICommonPayload<TypeCriteriaPermission>, action: ICommonPayload<TypeCriteriaPermission>) => {
    switch (action.type) {
        case SEARCH_PERMISSION:
            return { ...state, ...action }
        default:
            throw new Error()
    }
}

const DrawerPermission = (props: Iprops) => {
    const [criteria, dispatchCriteria]: [ICommonPayload<TypeCriteriaPermission>, (action: ICommonPayload<TypeCriteriaPermission>) => void] = React.useReducer(reducerCriteria, initCriteria)
    const [visibleViewJSON, setVisibleViewJSON] = React.useState(false)
    const [selectedRecord, setSelectedRecord] = React.useState({} as TypePermistion | undefined)

    const { onClose, visible } = props

    const loadData = async () => {
        const result: Promise<any> = props.commonActionFromDALV2(PermissionDAL.searchPermissionTable, criteria.payload)
        try {
            const response: any = await result
            if (response.success) {
                return response
            } else {
                return new PermistionResponse()
            }
        } catch (error) {
            return new PermistionResponse()
        }
    }

    const statePersmission = useAsyncRetry(async () => {
        if (visible && criteria.payload) {
            const data: PermistionResponse = await loadData()
            return data
        }
        return new PermistionResponse()
    }, [criteria, visible])

    const permissionResponse: PermistionResponse = statePersmission.value ? statePersmission.value : new PermistionResponse()

    const columns: ColumnProps<{}>[] = [
        {
            title: "#",
            key: "index",
            sorter: false,
            width: 50,
            render: (text: any, record: TypePermistion, index: number) => {
                return index + 1
            }
        }, {
            title: "Name",
            key: "name",
            width: 300,
            ellipsis: true,
            render: (text: any, record: TypePermistion, index: number) => {
                return <LongTextTd text={record.name} />
            },
        }, {
            title: "Description",
            key: "description",
            ellipsis: true,
            render: (text: any, record: TypePermistion, index: number) => {
                return <LongTextTd text={record.description} />
            },
        }, {
            title: <Icon type='eye' />,
            key: "action",
            fixed: "right",
            align: "center",
            render(text: any, record: TypePermistion, index: number) {
                return (
                    <Button icon='eye' type='link' size='small' onClick={() => {
                        setVisibleViewJSON(true)
                        setSelectedRecord(record)
                    }}></Button>
                )
            },
        }
    ]

    return (
        <Drawer
            title={<>
                <Button icon="left-circle" size="small" type="link" onClick={() => {
                    onClose()
                }}></Button>
                PERMISSIONS
            </>}
            width={700}
            onClose={onClose}
            visible={visible}
            bodyStyle={{ paddingBottom: 80 }}
        >
            <Row gutter={[5, 5]} type='flex'>
                <Col xs={24}>
                    <FormFilterComponent
                        loading={statePersmission.loading}
                        onSearch={(values: any) => {
                            dispatchCriteria({
                                loading: false,
                                payload: {
                                    ...criteria.payload,
                                    ...{ page: 0, ...values },
                                },
                                type: SEARCH_PERMISSION,
                            });
                        }}
                    />
                </Col>

                <Col xs={24}>
                    <InfoList
                        loading={statePersmission.loading}
                        list={permissionResponse}
                        refresh={statePersmission.retry}
                    />
                </Col>

                <Col xs={24}>
                    <Table
                        loading={statePersmission.loading}
                        columns={columns}
                        dataSource={permissionResponse.results.content}
                        rowKey="id"
                        size="small"
                        pagination={false}
                    />
                </Col>

                <Col xs={24}>
                    <O2OCustomPaging
                        list={permissionResponse}
                        onChange={(e: number) => {
                            dispatchCriteria({
                                loading: false,
                                payload: { ...criteria.payload, ...({ page: e } as any) },
                                type: SEARCH_PERMISSION,
                            });
                        }}
                        onChangePageSize={(e: number) => {
                            dispatchCriteria({
                                loading: false,
                                payload: { ...criteria.payload, ...({ page: 0, pageSize: e } as any) },
                                type: SEARCH_PERMISSION,
                            });
                        }}
                    />
                </Col>

                <DrawerViewJSON
                    data={selectedRecord}
                    onClose={() => {
                        setSelectedRecord(undefined)
                        setVisibleViewJSON(false)
                    }}
                    visible={visibleViewJSON}
                />

                <div
                    style={{
                        zIndex: 999999,
                        position: 'absolute',
                        right: 0,
                        bottom: 0,
                        width: '100%',
                        borderTop: '1px solid #e9e9e9',
                        padding: '10px 16px',
                        background: '#fff',
                        textAlign: 'right',
                    }}
                >
                    <Button onClick={props.onClose}>Close</Button>
                </div>
            </Row>
        </Drawer>
    )
}

export default DrawerPermission