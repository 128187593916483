import { O2OResponse } from 'o2o_layout/lib/common/rest';
import { UPDATE_ERROR } from '../../common/actionType/actionType';
import { UPDATE_COMMON_RESPONSE, IActionCommonResponse } from '../../redux/reducer/commonResponseReducer';
import { IActionUI } from '../../redux/reducer/uiReducer';
import { DistinctFieldDAL, TypeDistinctDomain } from '../dal/DistinctFieldDAL';

export function distinctByField(domain: TypeDistinctDomain, field: string) {
    return (dispatch: any) => {

        return new Promise((resolve, reject) => {

            DistinctFieldDAL.distinctByField(domain, field)
                .then(orgs => {
                    dispatch({
                        type: UPDATE_COMMON_RESPONSE,
                        payload: orgs
                    } as IActionCommonResponse)
                    resolve(orgs);
                }, (error: O2OResponse) => {
                    if (error.status == 401 || error.status == 403) {
                        dispatch({
                            type: UPDATE_ERROR,
                            errorStatus: error.status,
                            errorResponse: error.data
                        } as IActionUI)
                    }
                    reject(error);
                });
        });

    }
}