import { IResponseBigDataVN, TypePageBigData, TypeResultsBigData } from 'o2o_layout/lib/common/interface';
import { IResponseHaveNotContent } from '../../../../common/IResponseBigData';

export type TypePermission = {
    "category"?: string,
    "categoryId": string,
    "id"?: string,
    "name"?: string
}

export class Permissions implements IResponseHaveNotContent<TypePermission> {
    success: boolean
    results: TypePermission[]
    constructor() {
        this.success = false
        this.results = []
    }
}

export type TypePermissionByUser = {
    id: string
    orgId: string
    userId: string
    userOrgKey: string
    permission: string
    createdBy: string
    createdAt: number
}

export type TypePermissionGroup = {
    "createdAt"?: number,
    "createdBy"?: string,
    "id": string,
    "name"?: string,
    "orgId"?: string,
    "permissions"?: string[
    ],
    "updatedAt"?: number,
    "updatedBy"?: string,
    description?: string,
    system: boolean
}

export class PermissionGroups implements IResponseBigDataVN<TypePermissionGroup> {
    success: boolean
    results: TypeResultsBigData<TypePermissionGroup>
    users: {
        [key: string]: string[]
    }
    constructor() {
        this.success = false
        this.results = {
            content: [],
            timeTaken: 0,
            currentTime: 0,
            page: {
                pageSize: 0,
                currentPage: 0,
                totalRecords: 0,
                totalPages: 0
            } as TypePageBigData,
            extras: null
        }
        this.users = {}
    }
}
export class AllPermissionGroups implements IResponseHaveNotContent<TypePermissionGroup> {
    success: boolean
    results: TypePermissionGroup[]
    constructor() {
        this.success = false
        this.results = []
    }
}


export class PermissionGroupsContext {
    success: boolean
    results: any
    constructor() {
        this.success = false
        this.results = {}
    }
}

export type TypeAccount = {
    id: string,
    name: string,
    status: string
}

export class ListAccounts implements IResponseBigDataVN<TypeAccount> {
    results: TypeResultsBigData<TypeAccount>;
    success: boolean;
    constructor() {
        this.success = false;
        this.results = {
            content: [],
            currentTime: 0,
            extras: null,
            page: {
                pageSize: 0,
                currentPage: 0,
                totalRecords: 0,
                totalPages: 0,
            } as TypePageBigData,
            timeTaken: 0,
        };
    }
}

export type TypePermistion = {
    category: string,
    categoryId: string,
    createdAt: number,
    createdBy: string,
    description: string,
    id: string,
    name: string,
    updatedAt: number,
    updatedBy: string
}


export class PermistionGroups {
    success: boolean
    results: TypePermistion[]
    constructor() {
        this.success = false
        this.results = []
    }
}

export type TypeCriteriaPermission = {
    query?: string
    pageSize: number
    page: number
}

export class PermistionResponse implements IResponseBigDataVN<TypePermistion> {
    results: TypeResultsBigData<TypePermistion>;
    success: boolean;
    constructor() {
        this.success = false;
        this.results = {
            content: [],
            currentTime: 0,
            extras: null,
            page: {
                pageSize: 0,
                currentPage: 0,
                totalRecords: 0,
                totalPages: 0,
            } as TypePageBigData,
            timeTaken: 0,
        };
    }
}

export class PermissionPangoAct {
    data: {
        "userId": string,
        "permissions": string[]
    }[]
    success: boolean
    constructor() {
        this.data = []
        this.success = false
    }

}
