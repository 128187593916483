

import { Methods, RestClient } from 'o2o_layout/lib/common/rest';
import { BIGDATA_CONSTANT } from '../../common/constant';

class DAL {

    importSupportedConnection(file: File) {
        let url = BIGDATA_CONSTANT.url.console + `mngmnt/supported-connections/_import`;
        var restClient = new RestClient(Methods.POST, url);
        const formData = new FormData();
        formData.append('file', file)

        var headers = {
            'Content-Type': 'multipart/form-data'
        };
        return new Promise((resolve, reject) => {
            restClient.execute(headers, formData).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }
    importSegmentMeta(file: File) {
        let url = BIGDATA_CONSTANT.url.console + `mngmnt/segment-metas/_import`;
        var restClient = new RestClient(Methods.POST, url);
        const formData = new FormData();
        formData.append('file', file)

        var headers = {
            'Content-Type': 'multipart/form-data'
        };
        return new Promise((resolve, reject) => {
            restClient.execute(headers, formData).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }
    importMOM(file: File) {
        let url = BIGDATA_CONSTANT.url.console + `mngmnt/managed-objects/object-models/_import`;
        var restClient = new RestClient(Methods.POST, url);
        const formData = new FormData();
        formData.append('file', file)

        var headers = {
            'Content-Type': 'multipart/form-data'
        };
        return new Promise((resolve, reject) => {
            restClient.execute(headers, formData).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }
    importMOMField(file: File, objectModel: string) {
        let url = BIGDATA_CONSTANT.url.console + `mngmnt/managed-objects/object-models/fields/_import?objectModel=${objectModel}`;
        var restClient = new RestClient(Methods.POST, url);
        const formData = new FormData();
        formData.append('file', file)

        var headers = {
            'Content-Type': 'multipart/form-data'
        };
        return new Promise((resolve, reject) => {
            restClient.execute(headers, formData).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }
    deleteSegmentMeta(id: string){
        let url = BIGDATA_CONSTANT.url.console + `mngmnt/segment-metas/delete/_by_id?id=${id}`;
        var restClient = new RestClient(Methods.POST, url);

        var headers = {
            
        };
        return new Promise((resolve, reject) => {
            restClient.execute(headers, {}).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }

    deleteSupportConnection(id: string){
        let url = BIGDATA_CONSTANT.url.console + `mngmnt/supported-connections/delete/_by_id?id=${id}`;
        var restClient = new RestClient(Methods.POST, url);

        var headers = {
            
        };
        return new Promise((resolve, reject) => {
            restClient.execute(headers, {}).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }

    importZMA(dto: any){
        let url = BIGDATA_CONSTANT.url.console + `mngmnt/supported-mini-appapp/add`;
        var restClient = new RestClient(Methods.POST, url);
        var headers = {
        };
        return new Promise((resolve, reject) => {
            restClient.execute(headers, dto).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }
    importlistZMA(dto: any){
        let url = BIGDATA_CONSTANT.url.console + `mngmnt/supported-mini-appapp/add_list`;
        var restClient = new RestClient(Methods.POST, url);
        var headers = {
        };
        return new Promise((resolve, reject) => {
            restClient.execute(headers, dto).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }
    deleteSupportConnectionZMA(id: string){
        let url = BIGDATA_CONSTANT.url.console + `mngmnt/supported-mini-appapp/delete?id=${id}`;
        var restClient = new RestClient(Methods.POST, url);
        var headers = {
        };
        return new Promise((resolve, reject) => {
            restClient.execute(headers, {}).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }
    deletePangoTemplate(id: string){
        let url = BIGDATA_CONSTANT.url.console + `mngmnt/managed-object/x-model/template/${id}/delete`;
        var restClient = new RestClient(Methods.POST, url);
        var headers = {
        };
        return new Promise((resolve, reject) => {
            restClient.execute(headers, {}).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }
    addPangoTemplate(dto: any){
        let url = BIGDATA_CONSTANT.url.console + `mngmnt/managed-object/x-model/template/add`;
        var restClient = new RestClient(Methods.POST, url);
        var headers = {
        };
        return new Promise((resolve, reject) => {
            restClient.execute(headers, dto).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }

}

export const SystemDAL = new DAL()
