import { Response } from '../../libs/api/Response';
import { UPDATE_ERROR } from '../../common/actionType/actionType';
import { IActionUI } from '../../redux/reducer/uiReducer';
import { UPDATE_COMMON_RESPONSE, IActionCommonResponse } from '../../redux/reducer/commonResponseReducer';
import { CommonResponse } from '../../redux/model/commonResponse';
import { typeCriteriaSearchAccount, ConsoleUsersDAL } from './ConsoleUserDAL';
import { UPDATE_CONSOLE_USERS, IActionConsoleUsers, UPDATE_CONSOLE_USER_DETAIL, IActionConsoleUser } from './consoleUserReducer';
import { ConsoleUsers, TypeConsoleUser } from 'o2o_layout/lib/common/model';
import { UserDAL } from '../../dal/UserDAL';
import { TypeEDMSendInBlue, UPDATE_EDM_SEND_IN_BLUE, IActionEDMSendInBlue, GET_EDM_SEND_IN_BLUE } from './edmSendInBlueReducer';
import { UserTokenDAL, TypeCriteriaSearchUserToken } from './info/dal/UserTokenDAL';
import { GET_USER_TOKENS, IActionUserTokens } from './info/reducer/userTokenReducer';
import { UserTokens } from './info/model/usertoken';


export type typeGetAccounts = (criterial?: typeCriteriaSearchAccount, isSPA?: boolean) => any
export function getAccount<typeGetAccounts>(criterial?: typeCriteriaSearchAccount, isSPA?: boolean) {
    return (dispatch: any) => {


        return new Promise((resolve, reject) => {

            ConsoleUsersDAL.list(criterial, isSPA)
                .then(accounts => {
                    dispatch({
                        type: UPDATE_CONSOLE_USERS,
                        payload: accounts as ConsoleUsers
                    } as IActionConsoleUsers)
                    resolve(accounts);
                }, (error: Response) => {
                    if (error.status == 401 || error.status == 403) {
                        dispatch({
                            type: UPDATE_ERROR,
                            errorStatus: error.status,
                            errorResponse: error.data
                        } as IActionUI)
                    }
                    reject(error);
                });
        });

    }
}

export type typeAddUsers = (criterial: TypeConsoleUser, isSPA?: boolean) => any
export function addUser<typeAddUsers>(criterial: TypeConsoleUser, isSPA?: boolean) {
    return (dispatch: any) => {


        return new Promise((resolve, reject) => {

            ConsoleUsersDAL.create(criterial, isSPA)
                .then(orgs => {
                    dispatch({
                        type: UPDATE_COMMON_RESPONSE,
                        payload: orgs as CommonResponse
                    } as IActionCommonResponse)
                    
                    resolve(orgs);
                }, (error: Response) => {
                    if (error.status == 401 || error.status == 403) {
                        dispatch({
                            type: UPDATE_ERROR,
                            errorStatus: error.status,
                            errorResponse: error.data
                        } as IActionUI)
                    }
                    reject(error);
                });
        });

    }
}

export type typeUpdateAcount = (criterial: TypeConsoleUser, isSPA?: boolean, isUser?: boolean) => any
export function updateAccount<typeUpdateAcount>(criterial: TypeConsoleUser, isSPA?: boolean, isUser?: boolean) {
    return (dispatch: any) => {


        return new Promise((resolve, reject) => {

            ConsoleUsersDAL.update(criterial, isSPA, isUser)
                .then(orgs => {
                    dispatch({
                        type: UPDATE_COMMON_RESPONSE,
                        payload: orgs as CommonResponse
                    } as IActionCommonResponse)
                    
                    resolve(orgs);
                }, (error: Response) => {
                    if (error.status == 401 || error.status == 403) {
                        dispatch({
                            type: UPDATE_ERROR,
                            errorStatus: error.status,
                            errorResponse: error.data
                        } as IActionUI)
                    }
                    reject(error);
                });
        });

    }
}
export function updateRoleAccount<typeUpdateAcount>(email: string, roles: string[], isSPA?: boolean, orgId?: string) {
    return (dispatch: any) => {


        return new Promise((resolve, reject) => {

            ConsoleUsersDAL.updateRole(email, roles, isSPA, orgId)
                .then(orgs => {
                    dispatch({
                        type: UPDATE_COMMON_RESPONSE,
                        payload: orgs as CommonResponse
                    } as IActionCommonResponse)
                    
                    resolve(orgs);
                }, (error: Response) => {
                    if (error.status == 401 || error.status == 403) {
                        dispatch({
                            type: UPDATE_ERROR,
                            errorStatus: error.status,
                            errorResponse: error.data
                        } as IActionUI)
                    }
                    reject(error);
                });
        });

    }
}

export function updateUserOrg(email: string, oid: string, roles: string[]){
    return (dispatch: any) => {


        return new Promise((resolve, reject) => {

            ConsoleUsersDAL.updateUserOrg(email, oid, roles)
                .then(orgs => {
                    dispatch({
                        type: UPDATE_COMMON_RESPONSE,
                        payload: orgs as CommonResponse
                    } as IActionCommonResponse)
                    
                    resolve(orgs);
                }, (error: Response) => {
                    if (error.status == 401 || error.status == 403) {
                        dispatch({
                            type: UPDATE_ERROR,
                            errorStatus: error.status,
                            errorResponse: error.data
                        } as IActionUI)
                    }
                    reject(error);
                });
        });

    }
}
export function changePwd(oldPassword: string, newPassword: string, confirmPassword: string){
    return (dispatch: any) => {


        return new Promise((resolve, reject) => {

            UserDAL.changePwd(oldPassword, newPassword,confirmPassword)
                .then(orgs => {
                    dispatch({
                        type: UPDATE_COMMON_RESPONSE,
                        payload: orgs as CommonResponse
                    } as IActionCommonResponse)
                    
                    resolve(orgs);
                }, (error: Response) => {
                    if (error.status == 401 || error.status == 403) {
                        dispatch({
                            type: UPDATE_ERROR,
                            errorStatus: error.status,
                            errorResponse: error.data
                        } as IActionUI)
                    }
                    reject(error);
                });
        });

    }
}
export function updateEDMSendInBlue(name: string, key: string){
    return (dispatch: any) => {


        return new Promise((resolve, reject) => {

            ConsoleUsersDAL.updateEdmSendInBlue(name, key)
                .then((orgs: any) => {
                    
                    dispatch({
                        type: UPDATE_EDM_SEND_IN_BLUE,
                        payload: orgs.result as TypeEDMSendInBlue
                    } as IActionEDMSendInBlue)
                    
                    resolve(orgs);
                }, (error: Response) => {
                    if (error.status == 401 || error.status == 403) {
                        dispatch({
                            type: UPDATE_ERROR,
                            errorStatus: error.status,
                            errorResponse: error.data
                        } as IActionUI)
                    }
                    reject(error);
                });
        });

    }
}

export function getEDMSendInBlue(name: string, key: string){
    return (dispatch: any) => {


        return new Promise((resolve, reject) => {

            ConsoleUsersDAL.getEdmSendBlue()
                .then((orgs: any) => {
                    
                    dispatch({
                        type: GET_EDM_SEND_IN_BLUE,
                        payload: orgs.result as TypeEDMSendInBlue
                    } as IActionEDMSendInBlue)
                    
                    resolve(orgs);
                }, (error: Response) => {
                    if (error.status == 401 || error.status == 403) {
                        dispatch({
                            type: UPDATE_ERROR,
                            errorStatus: error.status,
                            errorResponse: error.data
                        } as IActionUI)
                    }
                    reject(error);
                });
        });

    }
}

export function assignUserToOrg(id: string, orgId: string,roles?: string[]){
    return (dispatch: any) => {


        return new Promise((resolve, reject) => {

            ConsoleUsersDAL.assignUserToOrg(id, orgId,roles)
                .then(orgs => {
                    dispatch({
                        type: UPDATE_COMMON_RESPONSE,
                        payload: orgs as CommonResponse
                    } as IActionCommonResponse)
                    
                    resolve(orgs);
                }, (error: Response) => {
                    if (error.status == 401 || error.status == 403) {
                        dispatch({
                            type: UPDATE_ERROR,
                            errorStatus: error.status,
                            errorResponse: error.data
                        } as IActionUI)
                    }
                    reject(error);
                });
        });

    }
}

export type typeDetailAcount = (id: string, isSPA?: boolean) => any
export function detailAccount<typeDetailAcount>(id: string, isSPA?: boolean) {
    return (dispatch: any) => {


        return new Promise((resolve, reject) => {

            ConsoleUsersDAL.detail(id, isSPA)
                .then(users => {
                    dispatch({
                        type: UPDATE_CONSOLE_USER_DETAIL,
                        payload: users 
                    } as IActionConsoleUser)
                    
                    resolve(users);
                }, (error: Response) => {
                    if (error.status == 401 || error.status == 403) {
                        dispatch({
                            type: UPDATE_ERROR,
                            errorStatus: error.status,
                            errorResponse: error.data
                        } as IActionUI)
                    }
                    reject(error);
                });
        });

    }
}


export type typeResetPasswordAccount = (id: string, isSPA?: boolean) => any
export function resetPasswordAccount<typeResetPasswordAccount>(id: string, isSPA?: boolean) {
    return (dispatch: any) => {


        return new Promise((resolve, reject) => {

            ConsoleUsersDAL.resetPassword(id, isSPA)
                .then(users => {
                    dispatch({
                        type: UPDATE_COMMON_RESPONSE,
                        payload: users as CommonResponse
                    } as IActionCommonResponse)
                    
                    
                    resolve(users);
                }, (error: Response) => {
                    if (error.status == 401 || error.status == 403) {
                        dispatch({
                            type: UPDATE_ERROR,
                            errorStatus: error.status,
                            errorResponse: error.data
                        } as IActionUI)
                    }
                    reject(error);
                });
        });

    }
}



export type typeDeleteAccount = (ids: string[], isSPA?: boolean) => any
export function deleteAccount<typeDeleteAccount>(ids: string[], isSPA?: boolean) {
    return (dispatch: any) => {


        return new Promise((resolve, reject) => {

            ConsoleUsersDAL.deletes(ids, isSPA)
                .then(users => {
                    dispatch({
                        type: UPDATE_COMMON_RESPONSE,
                        payload: users as CommonResponse
                    } as IActionCommonResponse)
                    
                    
                    resolve(users);
                }, (error: Response) => {
                    if (error.status == 401 || error.status == 403) {
                        dispatch({
                            type: UPDATE_ERROR,
                            errorStatus: error.status,
                            errorResponse: error.data
                        } as IActionUI)
                    }
                    reject(error);
                });
        });

    }
}

export function deleteUserFromOrg<typeDeleteAccount>(id: string, isSPA?: boolean) {
    return (dispatch: any) => {


        return new Promise((resolve, reject) => {

            ConsoleUsersDAL.deleteUserFromOrg(id)
                .then(users => {
                    dispatch({
                        type: UPDATE_COMMON_RESPONSE,
                        payload: users as CommonResponse
                    } as IActionCommonResponse)
                    
                    
                    resolve(users);
                }, (error: Response) => {
                    if (error.status == 401 || error.status == 403) {
                        dispatch({
                            type: UPDATE_ERROR,
                            errorStatus: error.status,
                            errorResponse: error.data
                        } as IActionUI)
                    }
                    reject(error);
                });
        });

    }
}

export function deleteUserFromOrgSPADMIN(id: string, orgId: string) {
    return (dispatch: any) => {


        return new Promise((resolve, reject) => {

            ConsoleUsersDAL.deleteUserFromOrgSPADMIN(id, orgId)
                .then(users => {
                    dispatch({
                        type: UPDATE_COMMON_RESPONSE,
                        payload: users as CommonResponse
                    } as IActionCommonResponse)
                    
                    
                    resolve(users);
                }, (error: Response) => {
                    if (error.status == 401 || error.status == 403) {
                        dispatch({
                            type: UPDATE_ERROR,
                            errorStatus: error.status,
                            errorResponse: error.data
                        } as IActionUI)
                    }
                    reject(error);
                });
        });

    }
}




export function getUserTokens(criteria: TypeCriteriaSearchUserToken){
    return (dispatch: any) => {


        return new Promise((resolve, reject) => {

            UserTokenDAL.list(criteria)
                .then((orgs: any) => {
                    
                    dispatch({
                        type: GET_USER_TOKENS,
                        payload: orgs as UserTokens
                    } as IActionUserTokens)
                    
                    resolve(orgs);
                }, (error: Response) => {
                    if (error.status == 401 || error.status == 403) {
                        dispatch({
                            type: UPDATE_ERROR,
                            errorStatus: error.status,
                            errorResponse: error.data
                        } as IActionUI)
                    }
                    reject(error);
                });
        });

    }
}




export function changeApiKey() {
    return (dispatch: any) => {


        return new Promise((resolve, reject) => {

            UserDAL.changeApiKey()
                .then(users => {
                    dispatch({
                        type: UPDATE_COMMON_RESPONSE,
                        payload: users as CommonResponse
                    } as IActionCommonResponse)
                    
                    
                    resolve(users);
                }, (error: Response) => {
                    if (error.status == 401 || error.status == 403) {
                        dispatch({
                            type: UPDATE_ERROR,
                            errorStatus: error.status,
                            errorResponse: error.data
                        } as IActionUI)
                    }
                    reject(error);
                });
        });

    }
}



export function logoutById(id: string) {
    return (dispatch: any) => {


        return new Promise((resolve, reject) => {

            UserTokenDAL.logoutId(id)
                .then(users => {
                    dispatch({
                        type: UPDATE_COMMON_RESPONSE,
                        payload: users as CommonResponse
                    } as IActionCommonResponse)
                    
                    
                    resolve(users);
                }, (error: Response) => {
                    if (error.status == 401 || error.status == 403) {
                        dispatch({
                            type: UPDATE_ERROR,
                            errorStatus: error.status,
                            errorResponse: error.data
                        } as IActionUI)
                    }
                    reject(error);
                });
        });

    }
}

export function extendToken() {
    return (dispatch: any) => {


        return new Promise((resolve, reject) => {

            UserTokenDAL.extendToken()
                .then(users => {
                    dispatch({
                        type: UPDATE_COMMON_RESPONSE,
                        payload: users as CommonResponse
                    } as IActionCommonResponse)
                    
                    
                    resolve(users);
                }, (error: Response) => {
                    if (error.status == 401 || error.status == 403) {
                        dispatch({
                            type: UPDATE_ERROR,
                            errorStatus: error.status,
                            errorResponse: error.data
                        } as IActionUI)
                    }
                    reject(error);
                });
        });

    }
}