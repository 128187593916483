import { Alert, Button, Col, Drawer, Form, Icon, Input, Row, Select, notification } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import React from 'react'
import { O2OFormItem } from '../../../../shares/O2OFormItem'
import { InternalPermissionDAL } from '../DAL/InternalPermissionDAL'
import { BIGDATA_CONSTANT } from '../../../../common/constant'
import { getPrimaryColor } from '../../../../common/helper'
import { useDispatch, useSelector } from '../../../../redux/store'
import { IState } from '../../../../redux/reducer'
import { IActionUI, SHOW_COMMON_ERROR } from '../../../../redux/reducer/uiReducer'

interface Iprops extends FormComponentProps {
    visible: boolean
    onClose: () => void
    commonActionFromDALV2: any
    onSuccess: () => void
}
const DrawerGrant = Form.create<Iprops>()((props: Iprops) => {
    const [allPermission, setAllPermission] = React.useState<string[]>([])
    const [loading, setLoading] = React.useState<boolean>(false)

    const uiSetting = useSelector((state: IState) => state.uiSetting)
    const dispatch = useDispatch()
    const loadAllPermission = async () => {
        const result = await props.commonActionFromDALV2(InternalPermissionDAL.allPermission)
        try {
            const response = await result
            if (response.success) {
                setAllPermission(response.results)
            } else {
                setAllPermission([])
            }
        } catch (error) {
            setAllPermission([])
        }
    }

    const onGrant = () => {
        props.form.validateFields(async (error, values) => {
            if (error) return
            setLoading(true)
            const payload = {
                orgId: values.orgId,
                userIds: values.userIds,
                permissions: values.permissions
            }
            const result = props.commonActionFromDALV2(InternalPermissionDAL.grantPermission, payload)
            try {
                const response = await result
                if (response.success) {
                    notification.success({
                        message: BIGDATA_CONSTANT.message.default.title.success,
                        description: "Add grant permission successfully",
                        icon: <Icon type="check-circle" style={{ color: getPrimaryColor(uiSetting) }} />
                    })
                    props.onClose()
                    props.onSuccess()
                    props.form.resetFields()
                    setLoading(false)
                } else {
                    setLoading(false)
                    dispatch({
                        type: SHOW_COMMON_ERROR,
                        commonError: response,
                    } as IActionUI);
                }
            } catch (error) {
                setLoading(false)
                dispatch({
                    type: SHOW_COMMON_ERROR,
                    commonError: error,
                } as IActionUI);
            }
        })
    }


    React.useEffect(() => {
        if (props.visible) {
            loadAllPermission()
        } else {
            props.form.resetFields()
            setAllPermission([])
        }
    }, [props.visible])
    return (
        <Drawer
            visible={props.visible}
            onClose={props.onClose}
            title="GRANT"
            width={500}
        >
            <Form colon={false} hideRequiredMark={true} >
                <Row gutter={[5, 5]} type="flex">
                    <Col xs={24}>
                        <O2OFormItem label="Organization Id">
                            {props.form.getFieldDecorator("orgId", {
                                rules: [
                                    { required: true, message: "Required" }
                                ]
                            })(
                                <Input placeholder='Enter' />
                            )}
                        </O2OFormItem>
                    </Col>
                    <Col xs={24}>
                        <O2OFormItem label="User Ids">
                            {props.form.getFieldDecorator("userIds", {
                                rules: [
                                    { required: true, message: "Required" }
                                ]
                            })(
                                <Select placeholder="Select(s)" mode='tags' />
                            )}
                        </O2OFormItem>
                    </Col>
                    <Col xs={24}>
                        <O2OFormItem label="Permissions">
                            {props.form.getFieldDecorator("permissions", {

                            })(
                                <Select
                                    mode='multiple'
                                    allowClear
                                    placeholder={"Select one"}
                                    showSearch
                                    showArrow={true}
                                    optionFilterProp='children'
                                    filterOption={(input: any, option: any) => {
                                        return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                    }}
                                >
                                    {allPermission && allPermission.length ? allPermission.map((item: string) => {
                                        return (
                                            <Select.Option key={item} value={item} >
                                                {item}
                                            </Select.Option>
                                        )
                                    }) : null}
                                </Select>
                            )}
                        </O2OFormItem>
                    </Col>
                    <Col xs={24}>
                        <O2OFormItem>
                            <Alert message="If permissions are empty, Pango will revoke all permissions on selected organization and users." type="warning" />
                        </O2OFormItem>
                    </Col>
                </Row>
                <div
                    style={{
                        position: 'absolute',
                        right: 0,
                        bottom: 0,
                        width: '100%',
                        borderTop: '1px solid #e9e9e9',
                        padding: '10px 16px',
                        background: '#fff',
                        textAlign: 'right',
                    }}
                >
                    <Button style={{ marginRight: 5 }} onClick={props.onClose}>Close</Button>

                    <Button type="primary" onClick={onGrant} loading={loading}>
                        Grant
                    </Button>
                </div>
            </Form>
        </Drawer>
    )
})

export default DrawerGrant