import { Icon, notification } from "antd";
import React from "react";
import { BIGDATA_CONSTANT } from "../common/constant";
import { getPrimaryColor } from "../common/helper";
import { IState } from "../redux/reducer";
import { useSelector } from "../redux/store";

export function CommonNotificationAddSuccessfully() {
    const uiSetting = useSelector((state: IState) => state.uiSetting)
    notification.success({
        message: BIGDATA_CONSTANT.message.default.title.success,
        description: BIGDATA_CONSTANT.message.notification.success.add,
        icon: <Icon type='check-circle' style={{ color: getPrimaryColor(uiSetting) }} />
    })
}
export function CommonNotificationUpdateSuccessfully() {
    const uiSetting = useSelector((state: IState) => state.uiSetting)
    notification.success({
        message: BIGDATA_CONSTANT.message.default.title.success,
        description: BIGDATA_CONSTANT.message.notification.success.update,
        icon: <Icon type='check-circle' style={{ color: getPrimaryColor(uiSetting) }} />
    })
}
export function CommonNotificationDeleteSuccessfully() {
    const uiSetting = useSelector((state: IState) => state.uiSetting)
    notification.success({
        message: BIGDATA_CONSTANT.message.default.title.success,
        description: BIGDATA_CONSTANT.message.notification.success.delete,
        icon: <Icon type='check-circle' style={{ color: getPrimaryColor(uiSetting) }} />
    })
}
export function CommonNotificationSaveError(description?: string) {
    notification.error({
        message: BIGDATA_CONSTANT.message.default.title.error,
        description: description || BIGDATA_CONSTANT.message.notification.fail.saved,
    })
}
export function CommonNotificationSaveSuccessFully(description?: string) {
    const uiSetting = useSelector((state: IState) => state.uiSetting)
    notification.success({
        message: BIGDATA_CONSTANT.message.default.title.success,
        description: description || BIGDATA_CONSTANT.message.notification.success.saved,
        icon: <Icon type='check-circle' style={{ color: getPrimaryColor(uiSetting) }} />
    })
}