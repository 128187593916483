import { Button, Col, DatePicker, Descriptions, Divider, Form, Input, Popover, Row, Table, Tooltip, Typography } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import { ColumnProps } from 'antd/lib/table'
import _ from 'lodash'
import moment from 'moment'
import { InfoList, LongTextTd, O2OCustomPaging } from 'o2o_layout'
import React, { useEffect, useState } from 'react'
import { useAsyncRetry } from 'react-use'
import CommonTypeProps, { ICommonPayload } from '../../../../common/CommonTypeProps'
import { BIGDATA_CONSTANT } from '../../../../common/constant'
import { getCurrentURL } from '../../../../common/helper'
import { DistinctByField } from '../../../../shares/DistinctByFiled'
import DrawerViewJSON from '../../../../shares/DrawerViewJSON'
import { O2OFormItem } from '../../../../shares/O2OFormItem'
import { TagPrimaryColor } from '../../../../shares/TagPrimaryColor'
import { ChangelogDAL, TypeDTOChangelogSearch } from './DAL'
import { ChangeLogs, TypeChangeLog } from './model'

interface IFilterProps extends FormComponentProps {
    loading: boolean
    distinctByField: any
    onSearch: (payload: any) => void
}

const Filter = (props: IFilterProps) => {

    const { form: { getFieldDecorator, validateFields }, loading, onSearch } = props

    const onSubmit = () => {
        validateFields((err: any, values: any) => {
            if (!err) {
                const { time } = values
                let payload = { ..._.omit(values, "time") }
                if (_.isArray(time) && time.length === 2) {
                    const fromStartAt = moment(time[0]).toDate().getTime()
                    const toStartAt = moment(time[1]).toDate().getTime()
                    payload = { ...payload, ...{ fromStartAt: fromStartAt, toStartAt: toStartAt } }
                } else {
                    payload = { ...payload, ...{ fromStartAt: 0, toStartAt: 0 } }
                }
                onSearch(payload)
            }
        })
    }

    return <Form hideRequiredMark={true} colon={false}>

        <Row type='flex' gutter={[5, 5]}>

            <Col sm={24} md={12} xl={8} xxl={5}>
                <O2OFormItem label="Information">
                    {getFieldDecorator("freeText", {

                    })(<Input.Search allowClear onPressEnter={onSubmit} placeholder='Enter' />)}
                </O2OFormItem>
            </Col>

            <Col sm={24} md={12} xl={8} xxl={5}>
                <DistinctByField
                    api={props.distinctByField}
                    domain={'changelog'}
                    getFieldDecorator={getFieldDecorator}
                    field={'CATEGORY'}
                    placeholder={'Select(s)'}
                    formLabel={'Category'}
                    formKey={'categories'}
                />
            </Col>
            <Col sm={24} md={12} xl={8} xxl={5}>
                <DistinctByField
                    api={props.distinctByField}
                    domain={'changelog'}
                    getFieldDecorator={getFieldDecorator}
                    field={'STATUS_CODE'}
                    placeholder={'Select(s)'}
                    formLabel={'Status'}
                    formKey={'statusCodes'}
                />
            </Col>
            <Col sm={24} md={12} xl={8} xxl={5}>
                <DistinctByField
                    api={props.distinctByField}
                    domain={'changelog'}
                    getFieldDecorator={getFieldDecorator}
                    field={'OBJECT_TYPE'}
                    placeholder={'Select(s)'}
                    formLabel={'Type'}
                    formKey={'objecTypes'}
                />
            </Col>

                    <Col>
                        <O2OFormItem label={<></>}>
                            <Button loading={loading} onClick={onSubmit} type="primary" icon='search'>Search</Button>
                        </O2OFormItem>
                    </Col>

            <Col sm={24} md={12} xl={8}>
                <O2OFormItem label="Time Range">
                    {getFieldDecorator("time", {

                    })(<DatePicker.RangePicker style={{ width: "100%" }} showTime={{ defaultValue: [moment("00:00:00", "HH:mm:ss"), moment("23:59:59", "HH:mm:ss")] }} />)}
                </O2OFormItem>
            </Col>


        </Row>
    </Form>
}

const FilterChangelogComponent = Form.create<IFilterProps>()(Filter)






interface IChangelogComponentProps extends CommonTypeProps {
    distinctByField: any
}

const initCriteria: ICommonPayload<TypeDTOChangelogSearch> = {
    loading: false,
    type: "",
    payload: {} as TypeDTOChangelogSearch,
}

const reducerCriteria = (state: ICommonPayload<TypeDTOChangelogSearch>, action: ICommonPayload<TypeDTOChangelogSearch>) => {
    switch (action.type) {
        case TYPE_DISPATCH_CRITERIA:
            return { ...state, ...action }
        default:
            throw new Error()
    }
}

const TYPE_DISPATCH_CRITERIA = "TYPE_DISPATCH_CRITERIA"

const ChangelogComponent = (props: IChangelogComponentProps) => {
    const [criteria, dispatchCriteria] = React.useReducer(reducerCriteria, initCriteria)
    const [selectedRecord, setSelectedRecord] = useState(undefined as undefined | TypeChangeLog)
    const [visibleViewJSON, setVisibleViewJSON] = useState(false as boolean)
    const [dataInput, setDataInput] = useState(undefined as any)

    const load = async () => {
        const result = await props.commonActionFromDALV2(ChangelogDAL.search, criteria.payload)
        try {
            const response: ChangeLogs = await result
            if (response.success) {
                return response
            }
            return new ChangeLogs()
        } catch (error) {
            return new ChangeLogs()
        }
    }

    useEffect(() => {
        dispatchCriteria({
            loading: false,
            type: TYPE_DISPATCH_CRITERIA,
            payload: { page: 0, pageSize: 10 } as TypeDTOChangelogSearch,
        })

        return () => {
            dispatchCriteria({
                loading: false,
                type: TYPE_DISPATCH_CRITERIA,
                payload: {} as TypeDTOChangelogSearch,
            })
        }
    }, [])


    const stateChangelog = useAsyncRetry(async () => {
        if (!_.isEmpty(criteria.payload)) {
            const data: ChangeLogs = await load()
            return data
        } return new ChangeLogs()
    }, [criteria])

    const changelog = stateChangelog.value || new ChangeLogs()

    const parseInput = (input?: string) => {
        if (input) {
            try {
                let parseInput = JSON.parse(input)
                if (_.isObject(parseInput)) {
                    return parseInput
                } else {
                    return {}
                }
            } catch (error) {
                return {}
            }
        } else {
            return {}
        }
    }

    const columns: ColumnProps<TypeChangeLog>[] = [
        {
            title: '#',
            sorter: false,
            width: 40,
            align: "center",
            key: "index",
            ellipsis: true,
            render: (text: any, record: TypeChangeLog, index: number) => {
                return index + 1
            }
        },
        {
            title: 'Action At',
            sorter: false,
            width: 120,
            key: "actionAt",
            ellipsis: true,
            render: (text: any, record: TypeChangeLog, index: number) => {
                return record.endTime && record.startTime ?
                    <Popover content={<Descriptions size='small' column={1} bordered layout="horizontal">
                        <Descriptions.Item label="Start at" >{moment(record.startTime).format("LLLL")}</Descriptions.Item>
                        <Descriptions.Item label="End at">{moment(record.endTime).format("LLLL")}</Descriptions.Item>
                    </Descriptions>}>
                        {moment(record.endTime).fromNow()}
                    </Popover>
                    : "--/--"
            }
        },
        {
            title: 'Action By',
            sorter: false,
            width: 180,
            key: "actionBy",
            ellipsis: true,
            render: (text: any, record: TypeChangeLog, index: number) => {
                return <LongTextTd text={record.actionBy} />
            }
        },
        {
            title: 'Action',
            sorter: false,
            width: 200,
            key: "actionAt",
            ellipsis: true,
            render: (text: any, record: TypeChangeLog, index: number) => {
                return <LongTextTd text={record.action} />
            }
        },
        {
            title: 'Status',
            sorter: false,
            width: 100,
            key: "status",
            ellipsis: true,
            render: (text: any, record: TypeChangeLog, index: number) => {
                return <TagPrimaryColor uiSetting={props.uiSetting} children={record.statusCode} />
            }
        },
        {
            title: 'Category',
            sorter: false,
            width: 120,
            key: "category",
            ellipsis: true,
            render: (text: any, record: TypeChangeLog, index: number) => {
                return <LongTextTd text={record.category} />
            }
        },
        {
            title: 'Type',
            width: 250,
            sorter: false,
            key: "type",
            ellipsis: true,
            render: (text: any, record: TypeChangeLog, index: number) => {
                return <LongTextTd text={record.objectType} />
            }
        },
        {
            title: 'Action',
            sorter: false,
            key: "action",
            fixed: "right",
            width: 60,
            ellipsis: true,
            render: (text: any, record: TypeChangeLog, index: number) => {
                let input = parseInput(record.input)
                return <>
                    <Tooltip title="Version">
                        <Button onClick={() => {
                            setDataInput(input)
                            setVisibleViewJSON(true)
                        }} icon='eye' type='link' size='small'></Button>
                    </Tooltip>
                    <Divider type='vertical' />
                    <Tooltip title="View JSON">
                        <Button onClick={() => {
                            setSelectedRecord(record)
                            setVisibleViewJSON(true)
                        }} type="link" size="small" icon="select" />
                    </Tooltip>
                </>
            }
        }
    ]

    React.useEffect(() => {
        props.updateMetaTitle({ title: BIGDATA_CONSTANT.trackTitle.Activities.Changelog })
        const timer = setTimeout(() => {
            props.updateMeta({
                name: BIGDATA_CONSTANT.trackTitle.Activities.Changelog,
                title: BIGDATA_CONSTANT.trackTitle.Activities.Changelog,
                app: "CONSOLE",
                url: getCurrentURL(props.location)
            })
        }, 5000)

        return () => {
            clearTimeout(timer)
        }
    }, [])

    return (

        <Row gutter={[10, 10]} type='flex'>
            <Col xs={24}>
                <Typography.Title level={4}>Change Log</Typography.Title>
            </Col>

            <Col xs={24}>
                <FilterChangelogComponent
                    onSearch={(payload: any) => {
                        dispatchCriteria({
                            loading: false,
                            type: TYPE_DISPATCH_CRITERIA,
                            payload: { ...criteria.payload, ...payload } as TypeDTOChangelogSearch
                        })
                    }}
                    loading={stateChangelog.loading}
                    distinctByField={props.distinctByField}
                />
            </Col>

            <Col xs={24}>
                <InfoList
                    loading={stateChangelog.loading}
                    list={changelog}
                    refresh={stateChangelog.retry}
                />
            </Col>

            <Col xs={24}>
                <Table
                    loading={stateChangelog.loading}
                    columns={columns}
                    dataSource={changelog.results.content}
                    rowKey="id"
                    size="small"
                    pagination={false}
                    scroll={{ x: 'calc(800px + 50%)' }}
                />
            </Col>

            <Col xs={24}>
                <O2OCustomPaging
                    list={changelog}
                    onChange={(e: number) => {
                        dispatchCriteria({
                            loading: false,
                            type: TYPE_DISPATCH_CRITERIA,
                            payload: { ...criteria.payload, ...{ page: e - 1 } } as TypeDTOChangelogSearch
                        })
                    }}
                    onChangePageSize={(e: number) => {
                        dispatchCriteria({
                            loading: false,
                            type: TYPE_DISPATCH_CRITERIA,
                            payload: { ...criteria.payload, ...{ page: 0, pageSize: e } } as TypeDTOChangelogSearch
                        })
                    }}
                />
            </Col>
            <DrawerViewJSON
                visible={visibleViewJSON}
                onClose={() => {
                    setDataInput(undefined)
                    setVisibleViewJSON(false)
                    setSelectedRecord(undefined)
                }}
                data={selectedRecord || dataInput}
            />
        </Row>


    )
}

export default ChangelogComponent
