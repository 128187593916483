import { Button, Col, Form, Icon, Input, Modal, Popconfirm, Popover, Row, Select, Spin, Switch, Table, Tooltip, Typography, notification } from "antd"
import { FormComponentProps } from "antd/lib/form"
import { ColumnProps } from "antd/lib/table"
import _ from "lodash"
import { CommontDrawerWithForm, ICommPropsDrawerWithForm, LongTextTd, truncateLongText } from "o2o_layout"
import { ConsoleUsers, TypeConsoleUser } from "o2o_layout/lib/common/model"
import React, { useState } from "react"
import { BIGDATA_CONSTANT } from "../../../common/constant"
import { getPrimaryColor } from "../../../common/helper"
import { IState } from "../../../redux/reducer"
import { useSelector } from "../../../redux/store"
import { O2OFormItem } from "../../../shares/O2OFormItem"
import { TagPrimaryColor } from "../../../shares/TagPrimaryColor"
import { PermissionDAL } from "../permisions/dal/PermissionDAL"
import DrawerAssignUser from "./DrawerAssignUser"

interface IProps {
    visible: boolean
    commonActionFromDAL2: any
    user?: TypeConsoleUser
    currentUserOrg?: any[]
    consoleUsers: ConsoleUsers
    deleteUserFromOrgSPADMIN: any
    updateRoleAccount: any
    onClose: any
    assignSuccess: any
    loading: boolean
}

interface IpropsModal extends FormComponentProps {
    visible: boolean
    onClose: () => void
    updateRoleAccount: any
    user?: TypeConsoleUser
    roles?: string[]
    assignSuccess: any
    uiSetting: any
    onSuccess: (isSuccess: boolean) => void
    consoleUsers: ConsoleUsers
    selectedRecord: any
    loading: boolean
}

const ModalConfirmAssignRole = Form.create<IpropsModal>()((props: IpropsModal) => {
    let o = props.consoleUsers.orgs[props.selectedRecord.orgId]

    return (
        <Modal
            maskClosable={false}
            visible={props.visible}
            title="Select roles"
            okText="Select"
            onCancel={() => {
                props.onClose()
                props.form.resetFields()
            }}
            onOk={async () => {
                props.onSuccess(true)
                const result = props.updateRoleAccount(props.user ? props.user.id : "", props.form.getFieldValue("roles"), true, o.id)
                try {
                    const response = await result
                    if (response.success) {
                        setTimeout(() => {
                            props.assignSuccess()
                        }, 1000)
                        notification.success({
                            message: BIGDATA_CONSTANT.message.default.title.success,
                            description: "Roles updated",
                            icon: <Icon type="check-circle" style={{ color: getPrimaryColor(props.uiSetting) }} />
                        })
                        props.onSuccess(false)
                        props.onClose()
                        props.form.resetFields()
                    } else {
                        props.onSuccess(false)
                        notification.error({
                            message: BIGDATA_CONSTANT.message.default.title.error,
                            description: response.message,
                        })
                    }
                } catch (error) {
                    props.onSuccess(false)
                    notification.error({
                        message: BIGDATA_CONSTANT.message.default.title.error,
                        description: _.get(error, "message", 'Something went wrong')
                    })
                }
            }}>
            <Spin spinning={props.loading}>

                <Form hideRequiredMark={true} colon={false}>
                    <Row gutter={[5, 5]}>
                        <Col xs={24}>
                            <O2OFormItem label="Roles">
                                {props.form.getFieldDecorator("roles", {
                                    initialValue: props.roles
                                })(
                                    <Select style={{ width: "100%" }} mode="multiple" placeholder="Select(s)">
                                        <Select.Option key="ADMIN">Admin</Select.Option>
                                        <Select.Option key="API">Api</Select.Option>
                                        <Select.Option key="USER">User</Select.Option>
                                        <Select.Option key="OPER_ADMIN">Operator Admin</Select.Option>
                                    </Select>
                                )}
                            </O2OFormItem>
                        </Col>
                    </Row>
                </Form>
            </Spin>

        </Modal >
    )
})

export const DrawerSPUserAssignToCompany = (props: IProps) => {
    console.log(props.currentUserOrg);
    const [userGrantPermissions, setUserGrantPermissions] = useState<string[]>([])
    const [accountPermissions, setAccountPermissions] = useState<string[]>([])
    const [visibleAssign, setVisibleAssign] = useState<boolean>(false)
    const [loading, setLoading] = React.useState<boolean>(false)
    const [loadingAssign, setLoadingAssign] = React.useState<boolean>(false)
    const [freeText, setFreeText] = useState<string>("")
    const [visibleModal, setVisibleModal] = useState<boolean>(false)
    const [selectedRecord, setSelectedRecord] = useState<any>({})
    const [roles, setRoles] = useState<string[]>([])

    const uiSetting = useSelector((state: IState) => state.uiSetting)

    const loadGrantPermission = async () => {
        const result = props.commonActionFromDAL2(PermissionDAL.grantPermissionSA, { username: _.get(props.user, "id", "") })
        try {
            const response = await result
            if (response.success) {
                setUserGrantPermissions(response.results)
            } else {
                setUserGrantPermissions([])
            }
        } catch (error) {
            setUserGrantPermissions([])
        }
    }

    const loadHasAccountPermissionSA = async () => {
        const result = props.commonActionFromDAL2(PermissionDAL.hasAccountPermissionSA, { username: _.get(props.user, "id", "") })
        try {
            const response = await result
            if (response.success) {
                setAccountPermissions(response.results)
            } else {
                setAccountPermissions([])
            }
        } catch (error) {
            setAccountPermissions([])
        }
    }

    React.useEffect(() => {
        if (props.visible) {
            loadGrantPermission()
            loadHasAccountPermissionSA()
        } else {
            setUserGrantPermissions([])
        }
    }, [props.visible])


    const columns: ColumnProps<{}>[] = [
        {
            title: "#",
            key: "index",
            sorter: false,
            width: 45,
            render: (text: any, record: any, index: number) => {
                return index + 1
            }
        }, {
            title: "Type",
            key: "type",
            sorter: false,
            width: 150,
            render: (text: any, record: any, index: number) => {
                return <TagPrimaryColor uiSetting={uiSetting} >{record.accountType}</TagPrimaryColor>
            }
        }, {
            title: "Organization",
            key: "organization",
            sorter: false,
            width: 200,
            render: (text: any, record: any, index: number) => {
                return <LongTextTd text={record.organization} />
            }
        }, {
            title: "Role",
            key: "role",
            sorter: false,
            width: 200,
            render: (text: any, record: any, index: number) => {
                let uo = props.user && props.consoleUsers.userOrgs ? props.consoleUsers.userOrgs[props.user.id] : [];
                let uSelect = _.find(uo, (item: any) => {
                    return item.orgId == record.orgId
                });
                return uSelect && uSelect.roles && uSelect.roles.length ? (
                    <div style={{ marginLeft: -5 }}>
                        <Button size="small" type="link" icon="plus-circle" onClick={() => {
                            setVisibleModal(true)
                            setSelectedRecord(record)
                            setRoles(uSelect.roles)
                        }} />

                        {uSelect.roles.map((item: string, index: number) => {
                            return index < 2 ? <TagPrimaryColor uiSetting={uiSetting} style={{ cursor: "pointer" }} ><Tooltip title={item}>{truncateLongText(item, 14)}</Tooltip></TagPrimaryColor> : null
                        })}
                        {uSelect.roles.length > 2 ? (
                            <Popover style={{ maxWidth: 500 }} content={uSelect.roles.map((item: string) => {
                                return <TagPrimaryColor uiSetting={uiSetting} style={{ cursor: "pointer", marginBottom: "5px" }} >{item}</TagPrimaryColor>
                            })}>
                                <Button type="link" size="small" icon="ellipsis"></Button>
                            </Popover>

                        ) : null}

                    </div>
                ) : "--/--"
            }
        }, {
            title: <>
                Grant
                <Tooltip placement="topLeft" title={
                    <>
                        Assign permission: <strong>GrantPrivilege</strong> to the user. Users with this permission are able to assign permissions to others.
                    </>
                }>
                    <Icon style={{ marginLeft: 5 }} type="question-circle" />
                </Tooltip>
            </>,
            key: "grant_permission",
            width: 150,
            sorter: false,
            render: (text: any, record: any, index: number) => {
                const isUserPermission = _.find(userGrantPermissions, (item: string) => item === record.orgId)
                const renderTitle = () => {
                    return (
                        <Typography.Text>
                            {isUserPermission ? "Remove" : "Assign"} <strong>Grant Permission</strong> <Tooltip title={
                                <>
                                    Assign permission: {<strong>Grant</strong>} to the user. Users with this permission are able to assign permissions to others
                                </>
                            }>
                                <Icon type="question-circle" />
                            </Tooltip> {isUserPermission ? "from" : "to"} user <strong>{record.userId}</strong>.
                        </Typography.Text>
                    )
                }
                return (
                    <Switch onChange={(e) => {
                        Modal.confirm({
                            title: <> {e ? "Assign" : "Unassign"} Grant Permission</>,
                            content: renderTitle(),
                            okText: isUserPermission ? "Unassign" : "Assign",
                            cancelText: "Close",
                            onOk: async () => {
                                await onAssignGrantPermission(e, record.orgId, record.userId)
                            }
                        })
                    }} style={{ cursor: "default" }} checked={isUserPermission ? true : false}
                        unCheckedChildren={"Disabled"}
                        checkedChildren={"Enabled"}
                    />
                )
            }
        }, {
            title: <>
                Account
                <Tooltip placement="topLeft" title={
                    <>
                        Assign permission: <strong>AccountManagement</strong> to the user. Users with this permission are able to manage accounts.
                    </>
                }>
                    <Icon style={{ marginLeft: 5 }} type="question-circle" />
                </Tooltip>
            </>,
            key: "account",
            sorter: false,
            width: 200,
            render: (text: any, record: any, index: number) => {
                const isAccountPermission = _.find(accountPermissions, (item: string) => item === record.orgId)
                const renderTitle = () => {
                    return (
                        <Typography.Text>
                            {isAccountPermission ? `Remove` : "Assign"} <strong>Account Permission</strong> <Tooltip title={
                                <>
                                    Assign permission: {<strong>Account</strong>} to the user. Users with this permission are able to assign permissions to others
                                </>
                            }>
                                <Icon type="question-circle" />
                            </Tooltip> {isAccountPermission ? "from" : "to"} user <strong>{record.userId}</strong>.
                        </Typography.Text>
                    )
                }
                return (
                    <Switch onChange={(e) => {
                        Modal.confirm({
                            title: <> {e ? "Assign" : "Unassign"} Account Permission</>,
                            content: renderTitle(),
                            okText: isAccountPermission ? "Unassign" : "Assign",
                            cancelText: "Close",
                            onOk: async () => {
                                await onAssignAccountPermission(e, record.orgId, record.userId)
                            }
                        })
                    }} style={{ cursor: "default" }} checked={isAccountPermission ? true : false}
                        unCheckedChildren={"Disabled"}
                        checkedChildren={"Enabled"}
                    />
                )
            }
        }, {
            title: <Icon type="close-circle" />,
            key: "action",
            align: "center",
            fixed: "right",
            render: (text: any, record: any, index: number) => {
                // let o = props.consoleUsers.orgs[record.orgId]
                return (
                    <Tooltip placement="topLeft" title="Remove user from company">
                        <Popconfirm placement="topLeft" title="Are you sure to remove user from this company?" onConfirm={() => {
                            props.deleteUserFromOrgSPADMIN(props.user ? props.user.id : "", record.orgId)
                        }}>
                            <Button size="small" type="link" icon="close-circle" />
                        </Popconfirm>
                    </Tooltip>
                )
            }
        },
    ]

    const renderDrawerAssignUser = () => {
        return (
            <DrawerAssignUser
                visible={visibleAssign}
                onClose={() => setVisibleAssign(false)}
                user={props.user}
                commonActionFromDAL2={props.commonActionFromDAL2}
                assignSuccess={props.assignSuccess}
                orgIds={(dataSource || []).map((item: any)=>item.orgId)}
            />
        )
    }

    const onAssignGrantPermission = async (isAssign: boolean, orgId: string, id: string) => {
        setLoadingAssign(true)
        let result
        if (isAssign) {
            result = props.commonActionFromDAL2(PermissionDAL.assignGrantPermissionSA, orgId, { username: id })
        } else {
            result = props.commonActionFromDAL2(PermissionDAL.unassignGrantPermissionSA, orgId, { username: id })
        }
        try {
            const response = await result
            if (response.success) {
                setLoadingAssign(false)
                notification.success({
                    message: BIGDATA_CONSTANT.message.default.title.success,
                    description: isAssign ? "Assigned successfully." : "Unassigned successfully.",
                    icon: <Icon type="check-circle" style={{ color: getPrimaryColor(uiSetting) }} />
                })
                setTimeout(() => {
                    loadGrantPermission()
                }, 1000)
            } else {
                notification.error({
                    message: BIGDATA_CONSTANT.message.default.title.error,
                    description: _.get(response, "message", BIGDATA_CONSTANT.message.notification.fail.update)
                })
                setLoadingAssign(false)
            }
        } catch (error) {
            setLoadingAssign(false)
            notification.error({
                message: BIGDATA_CONSTANT.message.default.title.error,
                description: _.get(error, "message", BIGDATA_CONSTANT.message.notification.fail.update)
            })
        }
    }

    const onAssignAccountPermission = async (isAssign: boolean, orgId: string, id: string) => {
        setLoadingAssign(true)
        let result
        if (isAssign) {
            result = props.commonActionFromDAL2(PermissionDAL.assignAccountPermissionSA, orgId, { username: id })
        } else {
            result = props.commonActionFromDAL2(PermissionDAL.unassignAccountPermissionSA, orgId, { username: id })
        }
        try {
            const response = await result
            if (response.success) {
                setLoadingAssign(false)
                notification.success({
                    message: BIGDATA_CONSTANT.message.default.title.success,
                    description: isAssign ? "Assigned successfully." : "Unassigned successfully.",
                    icon: <Icon type="check-circle" style={{ color: getPrimaryColor(uiSetting) }} />
                })
                setTimeout(() => {
                    loadHasAccountPermissionSA()
                }, 1000)
            } else {
                notification.error({
                    message: BIGDATA_CONSTANT.message.default.title.error,
                    description: _.get(response, "message", BIGDATA_CONSTANT.message.notification.fail.update)
                })
                setLoadingAssign(false)
            }
        } catch (error) {
            setLoadingAssign(false)
            notification.error({
                message: BIGDATA_CONSTANT.message.default.title.error,
                description: _.get(error, "message", BIGDATA_CONSTANT.message.notification.fail.update)
            })
        }
    }

    const getDataSource = () => {
        const currentUserOrg = props.currentUserOrg && props.currentUserOrg.length && props.currentUserOrg.map((item: any) => {
            const org = props.consoleUsers.orgs.hasOwnProperty(item.orgId) ? props.consoleUsers.orgs[item.orgId] : undefined
            return {
                ...item,
                organization: org ? org.name : undefined,
                orgId: org ? org.id : undefined,
                accountType: org ? org.accountType : undefined, org
            }
        })
        return currentUserOrg
    }

    const debounceSearch = React.useCallback(_.debounce(setFreeText, 500), [])

    const searchLocal = () => {
        const data = getDataSource();
        if (freeText.trim().length > 0) {
            const newData = _.filter(data || [], (item: any) => {
                if (item.accountType || item.organization) {
                    return item.accountType.toLocaleLowerCase().includes(freeText.toLocaleLowerCase()) || item.organization.toLocaleLowerCase().includes(freeText.toLocaleLowerCase())
                }
            })
            return newData
        }
        return data
    }

    const dataSource = searchLocal()

    const renderModalAssignRole = () => {
        return (
            <ModalConfirmAssignRole
                visible={visibleModal}
                onClose={() => {
                    setVisibleModal(false)
                    setSelectedRecord({})
                }}
                updateRoleAccount={props.updateRoleAccount}
                user={props.user}
                roles={roles}
                assignSuccess={props.assignSuccess}
                uiSetting={uiSetting}
                onSuccess={(isSuccess: boolean) => {
                    setLoading(isSuccess)
                }}
                selectedRecord={selectedRecord}
                consoleUsers={props.consoleUsers}
                loading={loading}
            />
        )
    }

    return (<CommontDrawerWithForm
        width={1000}
        content={(propsF: ICommPropsDrawerWithForm) => {
            return <>
                <Spin spinning={loading || props.loading || loadingAssign}>
                    <Row type="flex" gutter={[5, 5]} style={{ marginBottom: 100 }}>
                        <Col xs={10} style={{ marginBottom: -12 }}>
                            <Input.Search
                                onChange={(e) => {
                                    debounceSearch(e.target.value)
                                }}
                                placeholder="Search..."
                                allowClear>
                            </Input.Search>
                        </Col>
                        <Col xs={24}>
                            <Row gutter={[10, 10]} type="flex" justify="space-between" align="bottom">
                                <Col>
                                    <Typography.Text>
                                        <a><strong style={{ marginLeft: 0 }}>Found {props.currentUserOrg ? props.currentUserOrg.length : 0} record(s)</strong></a>
                                    </Typography.Text>
                                </Col>
                                <Col>
                                    <Button type="primary" icon="plus-circle" onClick={() => {
                                        setVisibleAssign(true)
                                    }}>Assign</Button>
                                </Col>

                            </Row>
                        </Col>
                        <Col xs={24}>
                            <Table
                                className="o2o-custom-paging"
                                loading={props.loading}
                                columns={columns}
                                dataSource={dataSource || []}
                                rowKey="id"
                                size="small"
                                pagination={{pageSize: 10, size: "large"}}
                            ></Table>
                        </Col>
                        {renderDrawerAssignUser()}
                        {renderModalAssignRole()}
                    </Row>
                </Spin>

            </>
        }}
        contentButton={(propsF: ICommPropsDrawerWithForm) => {
            return <>

            </>
        }}
        onClose={() => {
            props.onClose()
        }}
        title={`ASSIGN TO ORGS / ${_.get(props.user, "id", "").toUpperCase()}`}
        visible={props.visible}
        closable={false}
    />)
}
