

import { BIGDATA_CONSTANT } from '../../common/constant';
import RestClient from '../../libs/api/RestClient';
import { Methods } from '../../libs/api/Methods';


class DAL {

    getCommonDateFormat() {
        let url =
            BIGDATA_CONSTANT.url.svc +
            `api/common/constants/date-format`;
        var restClient = new RestClient(Methods.GET, url);
        return new Promise((resolve, reject) => {
            restClient.execute({}, {}).then(
                (response: any) => {
                    const user: any = response.data;
                    resolve(user);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    }

    buildURL(url: string, dto: any, method: string, headers?: any) {
        var restClient = new RestClient(method, url);
        return new Promise((resolve, reject) => {
            restClient.execute(headers ? headers : {}, dto ? dto: {}).then(
                (response: any) => {
                    const user: any = response.data;
                    resolve(user);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    }

}

export const CommonDAL = new DAL()
