import { Button, Col, DatePicker, Divider, Empty, Form, Icon, Row, Select, Spin, Table, Tooltip, Typography } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import { ColumnProps } from 'antd/lib/table'
import { TreemapChart } from 'bizcharts'
import { Datum } from 'bizcharts/lib/interface'
import { isArray, isEmpty, keys, omit, size, upperCase } from 'lodash'
import moment from "moment"
import { InfoList, LongTextTd, O2OCustomPaging } from 'o2o_layout'
import React, { useEffect, useMemo, useState } from 'react'
import { useAsyncRetry } from 'react-use'
import uuid from 'uuid'
import CommonTypeProps, { ICommonPayload } from '../../../../common/CommonTypeProps'
import { BIGDATA_CONSTANT } from '../../../../common/constant'
import { getCurrentURL, getPrimaryColor, hexToRgbA } from '../../../../common/helper'
import DrawerViewJSON from '../../../../shares/DrawerViewJSON'
import { O2OFormItem } from '../../../../shares/O2OFormItem'
import { TagPrimaryColor } from '../../../../shares/TagPrimaryColor'
import { AccessLogsDAL } from './DAL'
import { AccessLogs, StatsAccessLogs, TypeAccessLog } from './model'
import numeral from 'numeral'
interface IFilterAccessLogsComponentProps extends FormComponentProps {
    onSearch: (payload: any) => void
    showChart: () => void
    loading: boolean
}

const FilterAccessLogsComponent = Form.create<IFilterAccessLogsComponentProps>()(
    ({ form, onSearch, loading, showChart }: IFilterAccessLogsComponentProps) => {

        const onSumit = () => {
            form.validateFields((err: any, values: any) => {
                if (!err) {
                    let payload = omit(values, 'time')
                    if (values.time && isArray(values.time) && size(values.time) === 2) {
                        payload['fromTime'] = moment(values.time[0]).toDate().getTime()
                        payload['toTime'] = moment(values.time[1]).toDate().getTime()
                    } else {
                        payload['fromTime'] = 0
                        payload['toTime'] = 0
                    }
                    onSearch(payload)
                }
            })
        }

        return <Form hideRequiredMark colon={false}>
            <Row type='flex' gutter={[5, 5]}>
                <Col style={{ flexGrow: 1 }} sm={24} lg={12} xl={6} xxl={5}>
                    <O2OFormItem label="Users">
                        {form.getFieldDecorator('userIds', {

                        })(<Select allowClear maxTagCount={1} showArrow placeholder="Enter" mode='tags' />)}
                    </O2OFormItem>
                </Col>
                <Col style={{ flexGrow: 1 }} sm={24} lg={12} xl={6} xxl={5}>
                    <O2OFormItem label="Applications">
                        {form.getFieldDecorator('apps', {

                        })(<Select allowClear maxTagCount={1} showArrow placeholder="Enter" mode='tags' />)}
                    </O2OFormItem>
                </Col>
                <Col style={{ flexGrow: 1 }} sm={24} lg={12} xl={6} xxl={5}>
                    <O2OFormItem label="Modules">
                        {form.getFieldDecorator('names', {

                        })(<Select allowClear maxTagTextLength={20} maxTagCount={1} showArrow placeholder="Enter" mode='tags' />)}
                    </O2OFormItem>
                </Col>
                <Col sm={24} lg={12} xl={6} xxl={5}>
                    <O2OFormItem label="Time Range">
                        {form.getFieldDecorator('time', {

                        })(<DatePicker.RangePicker style={{ width: "100%" }} showTime={{ defaultValue: [moment("00:00:00", "HH:mm:ss"), moment("23:59:59", "HH:mm:ss")] }} />)}
                    </O2OFormItem>
                </Col>
                <Col>
                    <O2OFormItem label=" ">
                        <Row type='flex' align={'middle'}>
                            <Button style={{ marginRight: 5 }} icon='area-chart' onClick={showChart} />
                            <Button icon='search' loading={loading} onClick={onSumit} type='primary'>
                                Search
                            </Button>
                        </Row>
                    </O2OFormItem>
                </Col>
            </Row>
        </Form>
    }
)






interface IAccessLogsComponentProps extends CommonTypeProps {

}

const TYPE_DISPATCH_CRITERIA = "TYPE_DISPATCH_CRITERIA"

const initCriteria: ICommonPayload<any> = {
    loading: false,
    type: TYPE_DISPATCH_CRITERIA,
    payload: {} as any,
}

const reducerCriteria = (state: ICommonPayload<any>, action: ICommonPayload<any>) => {
    switch (action.type) {
        case TYPE_DISPATCH_CRITERIA:
            return { ...state, ...action }
        default:
            throw new Error()
    }
}

const AccessLogsComponent = ({ commonActionFromDALV2, location, uiSetting, updateMetaTitle, updateMeta }: IAccessLogsComponentProps) => {
    const [criteria, dispatchCriteria] = React.useReducer(reducerCriteria, initCriteria)

    const [selectedRecord, setSelectedRecord] = useState(undefined as undefined | TypeAccessLog)
    const [visibleViewJSON, setVisibleViewJSON] = useState(false)
    const [visibleStats, setVisibleStats] = useState(false)

    const load = async () => {
        const result = commonActionFromDALV2(AccessLogsDAL.search, criteria.payload)
        try {
            const response = await result
            if (response.success) {
                return response
            } else {
                return new AccessLogs()
            }
        } catch (error) {
            return new AccessLogs()
        }
    }

    const loadStats = async () => {
        const result = commonActionFromDALV2(AccessLogsDAL.stats, criteria.payload)
        try {
            const response = await result
            if (response.success) {
                return response
            } else {
                return new StatsAccessLogs()
            }
        } catch (error) {
            return new StatsAccessLogs()
        }
    }


    const stateAccessLogs = useAsyncRetry(async () => {
        if (!isEmpty(criteria.payload)) {
            const data = await load()
            return data
        }
        return new AccessLogs()
    }, [criteria])

    const accessLogs: AccessLogs = stateAccessLogs.value || new AccessLogs()

    const stateStats = useAsyncRetry(async () => {
        if (visibleStats) {
            const data = await loadStats()
            return data
        }
        return new StatsAccessLogs()
    }, [visibleStats])

    const stats: StatsAccessLogs = stateStats.value || new StatsAccessLogs()

    useEffect(() => {
        dispatchCriteria({
            loading: false,
            type: TYPE_DISPATCH_CRITERIA,
            payload: { page: 0, pageSize: 10 } as any,
        })
        return () => {
            dispatchCriteria({
                loading: false,
                type: TYPE_DISPATCH_CRITERIA,
                payload: {} as any,
            })
        }
    }, [])

    React.useEffect(() => {
        updateMetaTitle({ title: BIGDATA_CONSTANT.trackTitle.Activities.AccessLogs })
        const timer = setTimeout(() => {
            updateMeta({
                name: BIGDATA_CONSTANT.trackTitle.Activities.AccessLogs,
                title: BIGDATA_CONSTANT.trackTitle.Activities.AccessLogs,
                app: "CONSOLE",
                url: getCurrentURL(location)
            })
        }, 5000)

        return () => {
            clearTimeout(timer)
        }
    }, [])

    const columns: ColumnProps<TypeAccessLog>[] = [
        {
            title: "#",
            key: "index",
            width: 40,
            align: "center",
            ellipsis: true,
            render: (text: any, record: TypeAccessLog, index: number) => {
                return index + 1
            }
        },
        {
            title: "User",
            key: "user",
            width: 160,
            ellipsis: true,
            render: (text: any, record: TypeAccessLog, index: number) => {
                return <LongTextTd text={record.userId} />
            }
        },
        {
            title: "Accessed At",
            key: "accessAt",
            width: 150,
            ellipsis: true,
            render: (text: any, record: TypeAccessLog, index: number) => {
                return <Tooltip title={moment(record.accessedAt).format('LLLL')}>
                    {moment(record.accessedAt).fromNow()}
                </Tooltip>
            }
        },
        {
            title: "App",
            key: "app",
            width: 100,
            ellipsis: true,
            render: (text: any, record: TypeAccessLog, index: number) => {
                return <TagPrimaryColor uiSetting={uiSetting}>
                    {upperCase(record.app)}
                </TagPrimaryColor>
            }
        },
        {
            title: "Link",
            key: "link",
            width: 50,
            ellipsis: true,
            render: (text: any, record: TypeAccessLog, index: number) => {
                return <a href={record.url} target={'blank'}>
                    <Icon type='link' />
                </a>
            }
        },
        {
            title: "Module",
            key: "module",
            ellipsis: true,
            render: (text: any, record: TypeAccessLog, index: number) => {
                return <LongTextTd text={record.name} />
            }
        },
        {
            title: "Action",
            fixed: "right",
            width: 70,
            key: "action",
            ellipsis: true,
            render: (text: any, record: TypeAccessLog, index: number) => {
                return <Row type='flex' align={"middle"}>
                    <Button type='link' size='small' icon='select' onClick={() => {
                        setVisibleViewJSON(true)
                        setSelectedRecord(record)
                    }} />
                </Row>
            }
        }
    ]

    const renderChart = useMemo(() => {
        if (!visibleStats) return null


        const color = getPrimaryColor(uiSetting);
        const colors = [hexToRgbA(color, 1)]
        let offset = 0.05
        for (let index = 0; index < 20; index++) {
            offset = offset + 0.05
            colors.push(hexToRgbA(color, 1 - offset))
            // colors.push(rgba(197,149,212, ${1 - offset}))
        }
        const { statsByUsers, statsByNames } = stats.results

        const users = keys(statsByUsers).map((key: string) => {
            return {
                name: key,
                value: statsByUsers[key],
            }
        })
        const modules = keys(statsByNames).map((key: string) => {
            return {
                name: key,
                value: statsByNames[key],
            }
        })

        const dataUsers = {
            name: 'root',
            children: users
        }
        const dataModules = {
            name: 'root',
            children: modules
        }

        const formatValue = {
            formatter: (datum: Datum) => {
                return {
                    name: datum.name,
                    value: numeral(datum.value).format('0,0')
                }
            }
        }

        return <Row gutter={[16, 0]} type='flex'>
            <Col lg={24} xl={12}>
                <span style={{ color: 'gray' }}>Stats by User</span>
            </Col>
            <Col lg={24} xl={12}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span style={{ color: 'gray' }}>Stats by Module</span>
                    <span>
                        <Tooltip title='Refresh'>
                            <Button style={{ margin: 0, padding: 0 }} onClick={stateStats.retry} size='small' icon='sync' type='link' />
                        </Tooltip>
                        <Divider type='vertical' />
                        <Tooltip title='Close statistic'>
                            <Button style={{ margin: 0, padding: 0 }} onClick={() => setVisibleStats(false)} size='small' icon='close' type='link' />
                        </Tooltip>
                    </span>
                </div>
            </Col>
            <Col lg={24} xl={12}>
                <Spin spinning={stateStats.loading}>
                    {
                        isEmpty(users) ? <Empty /> :
                            <div style={{ margin: -10 }}>

                                <TreemapChart
                                    tooltip={formatValue}
                                    padding={[0, 0, 0, 0]}
                                    legend={false}
                                    height={250}
                                    data={dataUsers}
                                    color={colors}
                                    key={uuid()}
                                />


                            </div>
                    }
                </Spin>
            </Col>
            <Col lg={24} xl={12}>
                <Spin spinning={stateStats.loading}>
                    {
                        isEmpty(users) ? <Empty /> :
                            <div style={{ margin: -10 }}>

                                <TreemapChart
                                    tooltip={formatValue}
                                    padding={[0, 0, 0, 0]}
                                    legend={false}
                                    height={250}
                                    data={dataModules}
                                    color={colors}
                                    key={uuid()}
                                />


                            </div>

                    }
                </Spin>
            </Col>
        </Row>

    }, [visibleStats, JSON.stringify(stats)])

    return (
        <Row type='flex' gutter={[10, 10]}>
            <Col xs={24}>
                <Typography.Title level={4}>
                    Access Logs
                </Typography.Title>
            </Col>
            <Col xs={24}>
                <FilterAccessLogsComponent
                    showChart={() => setVisibleStats(!visibleStats)}
                    loading={stateAccessLogs.loading}
                    onSearch={(payload) => {
                        dispatchCriteria({
                            loading: false,
                            type: TYPE_DISPATCH_CRITERIA,
                            payload: { ...criteria.payload, ...{ page: 0 }, ...payload } as any,
                        })
                    }}
                />
            </Col>
            <Col xs={24}>
                {renderChart}
            </Col>
            <Col xs={24}>
                <InfoList
                    loading={stateAccessLogs.loading}
                    refresh={stateAccessLogs.retry}
                    list={accessLogs}
                />
            </Col>
            <Col xs={24}>
                <Table
                    loading={stateAccessLogs.loading}
                    columns={columns}
                    dataSource={accessLogs.results.content}
                    rowKey="id"
                    size="small"
                    scroll={{ x: 'calc(500px + 50%)' }}
                    pagination={false}
                />
            </Col>
            <Col xs={24}>
                <O2OCustomPaging
                    list={accessLogs}
                    onChange={(e: any) => {
                        dispatchCriteria({
                            loading: false,
                            type: TYPE_DISPATCH_CRITERIA,
                            payload: { ...criteria.payload, ...{ page: e - 1 } } as any,
                        })
                    }}
                    onChangePageSize={(e: any) => {
                        dispatchCriteria({
                            loading: false,
                            type: TYPE_DISPATCH_CRITERIA,
                            payload: { ...criteria.payload, ...{ page: 0, pageSize: e } } as any,
                        })
                    }}
                />
            </Col>

            <DrawerViewJSON
                visible={visibleViewJSON}
                data={selectedRecord}
                onClose={() => {
                    setVisibleViewJSON(false)
                    setSelectedRecord(undefined)
                }}
            />

        </Row>
    )
}

export default AccessLogsComponent