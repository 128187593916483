import { Response } from '../../libs/api/Response';
import { UPDATE_ERROR } from '../../common/actionType/actionType';
import { IActionUI } from '../../redux/reducer/uiReducer';
import { UPDATE_COMMON_RESPONSE, IActionCommonResponse } from '../../redux/reducer/commonResponseReducer';
import { CommonResponse } from '../../redux/model/commonResponse';
import { typeCriteriaSearchOrgs, OrgsDAL } from './OrgsDAL';
import { UPDATE_ORGRANIZATIONS, IActionOrganization } from './orgsReducer';
import { Organizations, TypeOrganization } from 'o2o_layout/lib/common/model';


export type typeGetOrgs = (criterial?: typeCriteriaSearchOrgs) => any
export function getOrgs<typeGetOrgs>(criterial?: typeCriteriaSearchOrgs) {
    return (dispatch: any) => {


        return new Promise((resolve, reject) => {

            OrgsDAL.list(criterial)
                .then(orgs => {
                    dispatch({
                        type: UPDATE_ORGRANIZATIONS,
                        payload: orgs as Organizations
                    } as IActionOrganization)
                    resolve(orgs);
                }, (error: Response) => {
                    if (error.status == 401 || error.status == 403) {
                        dispatch({
                            type: UPDATE_ERROR,
                            errorStatus: error.status,
                            errorResponse: error.data
                        } as IActionUI)
                    }
                    reject(error);
                });
        });

    }
}
export type typeAddOrgs = (criterial: TypeOrganization) => any
export function addOrgs<typeAddOrgs>(criterial: TypeOrganization) {
    return (dispatch: any) => {


        return new Promise((resolve, reject) => {

            OrgsDAL.create(criterial)
                .then(orgs => {
                    dispatch({
                        type: UPDATE_COMMON_RESPONSE,
                        payload: orgs as CommonResponse
                    } as IActionCommonResponse)
                    
                    resolve(orgs);
                }, (error: Response) => {
                    if (error.status == 401 || error.status == 403) {
                        dispatch({
                            type: UPDATE_ERROR,
                            errorStatus: error.status,
                            errorResponse: error.data
                        } as IActionUI)
                    }
                    reject(error);
                });
        });

    }
}

export type typeUpdateOrg = (criterial: TypeOrganization, isSADMIN?: boolean) => any
export function updateOrg<typeUpdateOrg>(criterial: TypeOrganization) {
    return (dispatch: any) => {


        return new Promise((resolve, reject) => {

            OrgsDAL.update(criterial)
                .then(orgs => {
                    dispatch({
                        type: UPDATE_COMMON_RESPONSE,
                        payload: orgs as CommonResponse
                    } as IActionCommonResponse)
                    
                    resolve(orgs);
                }, (error: Response) => {
                    if (error.status == 401 || error.status == 403) {
                        dispatch({
                            type: UPDATE_ERROR,
                            errorStatus: error.status,
                            errorResponse: error.data
                        } as IActionUI)
                    }
                    reject(error);
                });
        });

    }
}

export function updateOrgByAdminClient<typeUpdateOrg>(criterial: TypeOrganization, isSADMIN?: boolean) {
    return (dispatch: any) => {


        return new Promise((resolve, reject) => {

            OrgsDAL.updateOrgByAdminClient(criterial, isSADMIN)
                .then(orgs => {
                    dispatch({
                        type: UPDATE_COMMON_RESPONSE,
                        payload: orgs as CommonResponse
                    } as IActionCommonResponse)
                    
                    resolve(orgs);
                }, (error: Response) => {
                    if (error.status == 401 || error.status == 403) {
                        dispatch({
                            type: UPDATE_ERROR,
                            errorStatus: error.status,
                            errorResponse: error.data
                        } as IActionUI)
                    }
                    reject(error);
                });
        });

    }
}

export type typeDeleteOrg = (id: string, otp_code: string) => any
export function deleteOrg<typeAddOrgs>(id: string, otp_code: string) {
    return (dispatch: any) => {


        return new Promise((resolve, reject) => {

            

            OrgsDAL.delete(id, otp_code)
                .then(orgs => {
                    
                    dispatch({
                        type: UPDATE_COMMON_RESPONSE,
                        payload: orgs as CommonResponse
                    } as IActionCommonResponse)
                    
                    resolve(orgs);
                }, (error: Response) => {
                    if (error.status == 401 || error.status == 403) {
                        dispatch({
                            type: UPDATE_ERROR,
                            errorStatus: error.status,
                            errorResponse: error.data
                        } as IActionUI)
                    }
                    reject(error);
                });
        });

    }
}

