import { FETCH_LOGIN_INFORMATION, GET_ME } from '../../common/actionType/actionType';
import { LOGIN_DATA } from "../../modules/login/actions/LoginAction";
import { User } from 'o2o_layout/lib/common/model/user';

const createEmptyUser = (): User => {
  return new User();
}

export const userReducer = (state = createEmptyUser(), action: any) => {
  switch (action.type) {
    case FETCH_LOGIN_INFORMATION:
      return state;
    case LOGIN_DATA:
      return {...state, ...action.user};
    case GET_ME: 
      return {...state, ...action.user}
    default:
      return state;
  }
};