import { Alert, Button, Checkbox, Col, Drawer, Form, Input, Popconfirm, Row } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import React from 'react'
import { O2OFormItem } from '../../../shares/O2OFormItem'
import { TypeConsoleUser, User } from 'o2o_layout/lib/common/model'
import _ from 'lodash'

interface Iprops extends FormComponentProps {
    onClose: () => void
    visible: boolean
    item: TypeConsoleUser
    onDelete: (record: TypeConsoleUser) => void
    user: User
}
const DrawerRemoveAccount = Form.create<Iprops>()((props: Iprops) => {

    React.useEffect(()=>{
        if (!props.visible) {
            props.form.resetFields()         
        }
    },[props.visible])


    return (
        <Drawer
            title="REMOVE USER"
            width={500}
            visible={props.visible}
            onClose={() => {
                props.onClose()
                props.form.resetFields()
            }}
        >
            <Form colon={false} hideRequiredMark={true}>

                <Row gutter={[5, 5]} type='flex'>
                    <Col xs={24}>
                        <O2OFormItem label="Email">
                            {props.form.getFieldDecorator("email", {
                                initialValue: _.get(props.item, "id"),
                            })(
                                <Input disabled />
                            )}
                        </O2OFormItem>
                    </Col>
                    <Col xs={24}>
                        <O2OFormItem label="Name">
                            {props.form.getFieldDecorator("name", {
                                initialValue: _.get(props.item, "name"),
                            })(
                                <Input disabled />
                            )}
                        </O2OFormItem>
                    </Col>
                    <Col xs={24}>
                        <O2OFormItem>
                            <Alert type="warning" message="You are about to remove the user out of your organization. You cannot add this account back." />
                        </O2OFormItem>
                    </Col>
                    <Col xs={24}>
                        <O2OFormItem label="">
                            {props.form.getFieldDecorator("checkbox", {
                                valuePropName: 'checked',
                                initialValue: false
                            })(
                                <Checkbox>Confirm to remove</Checkbox>
                            )}
                        </O2OFormItem>
                    </Col>
                </Row>
                <div
                    style={{
                        position: 'absolute',
                        right: 0,
                        bottom: 0,
                        width: '100%',
                        borderTop: '1px solid #e9e9e9',
                        padding: '10px 16px',
                        background: '#fff',
                        textAlign: 'right',
                    }}
                >
                    <Button type="default" style={{ marginRight: 10 }} onClick={() => {
                        props.form.resetFields()
                        props.onClose()
                    }}>Cancel</Button>


                    {props.form.getFieldValue("checkbox") ? (
                        <Popconfirm placement="leftTop" title={`Are you sure you want to remove account ${props.item.id} from ${props.user.orgCtx ? props.user.orgCtx.organization.name : ''} company?`} onConfirm={() => {
                            props.onDelete(props.item)
                        }}>
                            <Button icon="delete" type="primary">Remove</Button>
                        </Popconfirm>
                    ) : (
                        <Button disabled={true} icon="delete" type="primary">Remove</Button>
                    )}

                </div>
            </Form>
        </Drawer>
    )
})

export default DrawerRemoveAccount