import { Dropdown, Menu, Button, Icon } from 'antd'
import { ClickParam } from 'antd/lib/menu'
import React from 'react'

interface Iprops {
    orderBy: string
    onSelected: (value: string) => void
}
const OrderByCriteria = (props: Iprops) => {
    return (
        <Dropdown overlay={
            <Menu onClick={(e: ClickParam) => {
                props.onSelected(e.key)
            }} selectedKeys={[props.orderBy]}>
                <Menu.Item key="DESC">DESC</Menu.Item>
                <Menu.Item key="ASC">ASC</Menu.Item>
            </Menu>
        }>
            <div><Button type="link" size="small">{props.orderBy} <Icon type="caret-down" /></Button></div>

        </Dropdown>
    )
}

export default OrderByCriteria