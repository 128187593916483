import { Button, Col, Drawer, Form, Icon, Input, Row, Select, Table, Tooltip, notification } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import React from 'react'
import { O2OFormItem } from '../../../../shares/O2OFormItem'
import _ from 'lodash'
import SuggestionAccount from '../components/SuggestionAccount'
import { ColumnProps } from 'antd/lib/table'
import { LongTextTd } from 'o2o_layout'
import update from 'react-addons-update'
import { TypePermissionGroup } from '../model/permissions'
import { BIGDATA_CONSTANT } from '../../../../common/constant'
import { getPrimaryColor } from '../../../../common/helper'
import { useDispatch, useSelector } from '../../../../redux/store'
import { IState } from '../../../../redux/reducer'
import { IActionUI, SHOW_COMMON_ERROR } from '../../../../redux/reducer/uiReducer'
import { PermissionDAL } from '../dal/PermissionDAL'

interface Iprops extends FormComponentProps {
    visible: boolean,
    onClose: () => void,
    item?: TypePermissionGroup
    onSuccess: () => void
    commonActionFromDALV2: any
    userList: any

}
const DrawerAssignUser = Form.create<Iprops>()((props: Iprops) => {
    const { visible, onClose, onSuccess } = props
    const { getFieldDecorator, setFieldsValue, validateFields, resetFields } = props.form
    const [userIds, setUserIds] = React.useState<string[]>([])
    const [loading, setLoading] = React.useState(false as boolean)

    const uiSetting = useSelector((state: IState) => state.uiSetting)
    const dispatch = useDispatch()

    const columns: ColumnProps<{}>[] = [
        {
            title: '#',
            key: 'index',
            width: 45,
            render: (text: any, record: any, index: number) => {
                return index + 1
            },
        }, {
            title: 'Users',
            key: 'users',
            ellipsis: true,
            render: (text: any, record: any, index: number) => {
                const name = _.find(props.userList[record], (item: string) => item === record)
                return <LongTextTd text={name ? name : record} />
            },
        }, {
            title: <Icon type='delete' />,
            key: "action",
            fixed: "right",
            align: "center",
            render: (text: any, record: any, index: number) => {
                return (
                    <Tooltip title="Delete">
                        <Button size='small' type='link' icon='delete' onClick={() => {
                            const newData = update(userIds, {
                                $splice: [[index, 1]]
                            })
                            const formsDaraUni = _.compact(_.uniq(newData))
                            setUserIds(formsDaraUni)
                        }}></Button>
                    </Tooltip>
                )
            }
        },
    ]

    const onSave = () => {
        validateFields(async (err: any, values: any) => {
            if (err) return
            try {
                const result = props.commonActionFromDALV2(PermissionDAL.saveUsersGrant, _.get(props.item, "id"), userIds);
                setLoading(true)
                const response = await result
                if (response.success) {
                    onClose()
                    resetFields()
                    onSuccess()
                    setLoading(false)
                    notification.success({
                        message: BIGDATA_CONSTANT.message.default.title.success,
                        description: BIGDATA_CONSTANT.message.notification.success.update,
                        icon: <Icon type="check-circle" style={{ color: getPrimaryColor(uiSetting) }} />
                    })
                } else {
                    dispatch({
                        type: SHOW_COMMON_ERROR,
                        commonError: response
                    } as IActionUI);
                    setLoading(false)
                }
            } catch (error) {
                dispatch({
                    type: SHOW_COMMON_ERROR,
                    commonError: error
                } as IActionUI);
                setLoading(false)
            }
        })
    }

    React.useEffect(() => {
        if (!visible) {
            resetFields()
            setUserIds([])
        }
    }, [visible])

    React.useEffect(() => {
        if (visible && props.item && props.userList) {
            setUserIds(props.userList[props.item.id] || [])
        }
    }, [props.item, visible, props.userList])



    return (
        <Drawer
            title={<>
                <Button icon="left-circle" size="small" type="link" onClick={() => {
                    onClose()
                }}></Button>
                ASSIGN TO USER
            </>}
            width={600}
            onClose={onClose}
            visible={visible}
            bodyStyle={{ paddingBottom: 80 }}
            maskClosable={false}
        >
            <Form layout="vertical" colon={false} hideRequiredMark={true}>
                <Row gutter={[5, 5]} type='flex'>
                    <Col xs={24}>
                        <O2OFormItem label="Group Name">
                            {getFieldDecorator("name", {
                                initialValue: _.get(props.item, "name", undefined)
                            })(
                                <Input readOnly />
                            )}
                        </O2OFormItem>
                    </Col>
                    <Col xs={24}>
                        <O2OFormItem >
                            {getFieldDecorator('userIds', {
                                initialValue: userIds
                            })(
                                <Select style={{ display: "none" }} />
                            )}
                        </O2OFormItem>
                    </Col>
                    <Col xs={24}>
                        <SuggestionAccount
                            commonActionFromDALV2={props.commonActionFromDALV2}
                            form={props.form}
                            keyField={"userTemp"}
                            label="User"
                            placeholder="Select(s)"
                            onSelect={(e: string) => {
                                const newData = update(userIds, {
                                    $push: [e]
                                })
                                const formsDaraUni = _.compact(_.uniq(newData))
                                setUserIds(formsDaraUni)
                                setFieldsValue({
                                    userTemp: undefined,
                                    userIds: userIds
                                })

                            }}
                            mode='multiple'
                            visible={visible}
                            userIds={userIds}
                        />
                    </Col>

                    <Col xs={24}>
                        <Col xs={24}>
                            <O2OFormItem label={`Selected ${userIds && userIds.length} users`} >
                                <Table
                                    size="small"
                                    columns={columns}
                                    dataSource={userIds}
                                    pagination={false}
                                    rowKey="id"
                                />
                            </O2OFormItem>
                        </Col>
                    </Col>
                </Row>
                <div
                    style={{
                        zIndex: 999999,
                        position: 'absolute',
                        right: 0,
                        bottom: 0,
                        width: '100%',
                        borderTop: '1px solid #e9e9e9',
                        padding: '10px 16px',
                        background: '#fff',
                        textAlign: 'right',
                    }}
                >
                    <Button onClick={onClose} style={{ marginRight: 8 }}>
                        Cancel
                    </Button>
                    <Button onClick={onSave} type="primary" loading={loading}>
                        Save
                    </Button>
                </div>
            </Form>
        </Drawer>
    )
})

export default DrawerAssignUser