import { Form, Input, Button, Card, Divider } from 'antd';
import React from "react";
import { FormComponentProps } from 'antd/lib/form';

interface Props extends FormComponentProps {
    submit: any,
}

export const FormLinkedCompany = Form.create<Props>({ name: 'form_in_modal' })(
    // eslint-disable-next-line
    class extends React.Component<Props, any> {

        renderTheme = () => {
            const { getFieldDecorator } = this.props.form
            const { submit } = this.props

            return (
                <Form onSubmit={submit} colon={false} hideRequiredMark={true}>

                    <Form.Item label="Integration Name">
                        {
                            getFieldDecorator('integrationName', {
                                rules: [{ required: true, message: 'Please input integration name!' }],
                            })(<Input placeholder=" integration name" />)
                        }
                    </Form.Item>

                    <Form.Item label="Api key">
                        {
                            getFieldDecorator('apiKey', {
                            })(<Input placeholder=" Api key" />)
                        }
                    </Form.Item>

                    {/* <Form.Item label="Linked Source">
                        {
                            getFieldDecorator('linkedSource', {
                            })(
                                <Select placeholder="Linked Source">
                                    <Select.Option key="O2OCRM">O2O CRM</Select.Option>
                                </Select>
                            )
                        }
                    </Form.Item> */}

                    <Card title="Link Company">
                        <Form.Item label="Company Id">
                            {
                                getFieldDecorator('linkedOrgId', {
                                    rules: [{ required: true, message: 'Please input company integration id!' }],
                                })(<Input placeholder="company integration id" />)
                            }
                        </Form.Item>
                        <Form.Item label="Company Name">
                            {
                                getFieldDecorator('linkedOrgName', {
                                    rules: [{ required: true, message: 'Please input company integration name!' }],
                                })(<Input placeholder="company integration name" />)
                            }
                        </Form.Item>

                    </Card>

                    <Divider />
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Link
                        </Button>

                    </Form.Item>

                </Form>
            )
        }

        render() {


            return (
                this.renderTheme()
            );
        }
    },
);
