
import { IState } from '../../../redux/reducer';
import { O2OCommonContainer } from '../../../common/container/O2OContainer';
import { EmptyCompanyComponent } from './EmptyCompanyComponent';
import { bindActionCreators } from 'redux';
import { getCurrentUserOrgs } from '../SelectCompanyAction';

function mapStateToProps(rootState: IState) {
    return {  
        currentUserOrgs: rootState.currentUserOrgs,
    };
}


function mapDispatchToProps(dispatch: any) {
    return {
        getCurrentUserOrgs: bindActionCreators(getCurrentUserOrgs, dispatch),
    };
}

export const EmptyCompanyContainer = O2OCommonContainer(EmptyCompanyComponent, mapStateToProps, mapDispatchToProps)