import { IState } from '../../../redux/reducer/index';
import { O2OCommonContainer } from '../../../common/container/O2OContainer';
import { getUserTokens, logoutById } from '../ConsoleUsersAction';
import { bindActionCreators } from 'redux';
import { UserTokenComponent } from './UserTokenComponent';

function mapStateToProps(rootState: IState) {
    return {  
        userTokens: rootState.userTokens
    };
}


function mapDispatchToProps(dispatch: any) {
    return {
        getUserTokens: bindActionCreators(getUserTokens, dispatch),
        logoutById: bindActionCreators(logoutById, dispatch)
    };
}

export const UserTokenContainer = O2OCommonContainer(UserTokenComponent, mapStateToProps, mapDispatchToProps)