import { IActionGeneric } from '../../common/IActionGeneric';
import { ConsoleUsers, ConsoleUser } from 'o2o_layout/lib/common/model';
export const UPDATE_CONSOLE_USERS = "UPDATE_CONSOLE_USERS"
export const UPDATE_CONSOLE_USER_DETAIL = "UPDATE_CONSOLE_USER_DETAIL"

const empty = (): ConsoleUsers => {
    return new ConsoleUsers()
};

const emptyUser = () :ConsoleUser =>{ 
  return new ConsoleUser()
}

export interface IActionConsoleUsers extends IActionGeneric<ConsoleUsers>{}
export interface IActionConsoleUser extends IActionGeneric<ConsoleUser>{}

export const consoleUserReducer = (state = empty(), action: IActionConsoleUsers) => {
  switch (action.type) {
    case UPDATE_CONSOLE_USERS:
      return {...state, ...action.payload};
    default:
      return state;
  }
};

export const consoleUserDetailReducer = (state = emptyUser(), action: IActionConsoleUser) => {
  switch (action.type) {
    case UPDATE_CONSOLE_USER_DETAIL:
      return {...state, ...action.payload};
    default:
      return state;
  }
};