import { IState } from '../../redux/reducer/index';
import { O2OCommonContainer } from '../../common/container/O2OContainer';
import { bindActionCreators } from 'redux';
import { userLogin, userLogout } from './actions/LoginAction';
import { updateAuthen, getMe } from '../../common/actions/CommonAction';
import { LogoutComponent } from './LogoutComponent';

function mapStateToProps(rootState: IState) {
    return {  
        user: rootState.user,
        companyName: "Account"
    };
}


function mapDispatchToProps(dispatch: any) {
    return {
        login: bindActionCreators(userLogin, dispatch),
        updateAuthen: bindActionCreators(updateAuthen, dispatch),
        getMe: bindActionCreators(getMe, dispatch),
        userLogout: bindActionCreators(userLogout, dispatch)
    };
}
export const LogoutContainer = O2OCommonContainer(LogoutComponent ,mapStateToProps, mapDispatchToProps)

