import { Button, Col, Divider, Form, Icon, Input, InputNumber, notification, Popconfirm, Row, Select, Switch, Tooltip } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import _, { get } from 'lodash'
import moment from "moment"
import { Methods } from 'o2o_layout/lib/common/rest'
import * as React from 'react'
import { BIGDATA_CONSTANT } from '../../../../common/constant'
import { IActionUI, SHOW_COMMON_ERROR } from '../../../../redux/reducer/uiReducer'
import { useDispatch } from '../../../../redux/store'
import { CommonNotificationSaveError, CommonNotificationUpdateSuccessfully } from '../../../../shares/CommonNotification'
import { CommonDAL } from '../../../../shares/dal/CommonDAL'
import { O2OFormItem } from '../../../../shares/O2OFormItem'
import { TypeBlockObject, TypeQuotaSetting } from '../models'
import { SettingDAL } from '../SettingDAL'

interface Iprops extends FormComponentProps {
    commonActionFromDALV2: any
    id?: string
    selectedMenu: string
}


const ApiIngestion = Form.create<Iprops>({ name: "api_ingestion" })((props: Iprops) => {
    const [loading, setLoading] = React.useState(false)
    const [blockObject, setBlocObject] = React.useState(undefined as undefined | TypeBlockObject)
    const [data, setData] = React.useState(undefined as undefined | TypeQuotaSetting)

    const dispatch = useDispatch()

    const loadDataAPIIngestion = async () => {
        setLoading(true)
        const result = await props.commonActionFromDALV2(SettingDAL.getQuotaSupperAdmin, props.id, "ApiIngestion")
        try {
            const payload = await result
            if (payload.success) {
                setData(payload.result)
                setBlocObject(payload.blockObject)
            } else {
                notification.error({
                    message: BIGDATA_CONSTANT.message.default.title.error,
                    description: get(payload, "message", BIGDATA_CONSTANT.message.notification.fail)
                })
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            dispatch({
                type: SHOW_COMMON_ERROR,
                commonError: error,
            } as IActionUI);
        }
    }

    const handleUpdate = () => {
        props.form.validateFields(async (error: any, values: any) => {
            if (error) return

            try {
                setLoading(true)
                const payload = {
                    alertMessage: props.form.getFieldValue("alertMessage1"),
                    category: "ApiIngestion",
                    enabledBlocked: values.enabledBlocked ? true : false,
                    quotaByKeys: {
                        _5minutely: props.form.getFieldValue("_5minutely"),
                        _hourly: props.form.getFieldValue("_hourly"),
                        _daily: props.form.getFieldValue("_daily"),
                    },
                    unblockedPeriodInMin: +props.form.getFieldValue("unblockedPeriodInMin")
                }

                const result: Promise<any> = await props.commonActionFromDALV2(SettingDAL.updateQuotaLimit, props.id, "ApiIngestion", payload)

                const response = await result

                if (response.success) {
                    setLoading(false)
                    CommonNotificationUpdateSuccessfully()
                    setData(response.result)

                } else {
                    setLoading(false)
                    CommonNotificationSaveError(get(response, "message", "Something went wrong!"))
                }
                props.form.validateFields((err: any, values: any) => {
                    const fields = Object.keys(values);
                    const fieldsExclueOrg = _.filter(fields, (item: string) => { return item != "organization" })
                    props.form.resetFields(fieldsExclueOrg)
                })
            } catch (error) {
                setLoading(false)
                dispatch({
                    type: SHOW_COMMON_ERROR,
                    commonError: error,
                } as IActionUI);
            }
        })
    }


    React.useEffect(() => {
        if (props.selectedMenu === "menu1") {
            loadDataAPIIngestion()
        }
    }, [props.selectedMenu])

    return (
        <Form colon={true} hideRequiredMark={true}>
            <Row gutter={[10, 10]} type="flex">
                <Col xs={6}>
                    <O2OFormItem label={
                        <span>
                            5 Minutely
                            <Tooltip title="Total allowed calls within 5 minutes">
                                <Icon style={{ marginLeft: 5 }} type="question-circle" />
                            </Tooltip>

                        </span>
                    }>
                        {props.form.getFieldDecorator("_5minutely", {
                            rules: [{ required: true, message: "Required" }],
                            initialValue: data && data.quotaByKeys !== null ? data.quotaByKeys._5minutely : 3000
                        })(
                            <InputNumber
                                min={0}
                                placeholder='Please enter number'
                                formatter={(value: string) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                style={{ width: "100%" }}>
                            </InputNumber>
                        )}
                    </O2OFormItem>
                </Col>
                <Col xs={6}>
                    <O2OFormItem label={
                        <span>
                            Hourly
                            <Tooltip title="Total allowed calls within 1 hour">
                                <Icon style={{ marginLeft: 5 }} type="question-circle" />
                            </Tooltip>

                        </span>
                    }>
                        {props.form.getFieldDecorator("_hourly", {
                            rules: [{ required: true, message: "Required" }],
                            initialValue: data && data.quotaByKeys !== null ? data.quotaByKeys._hourly : 20000
                        })(
                            <InputNumber
                                min={0}
                                placeholder='Please enter number'
                                formatter={(value: string) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                style={{ width: "100%" }}>
                            </InputNumber>
                        )}
                    </O2OFormItem>
                </Col>
                <Col xs={6}>
                    <O2OFormItem label={
                        <span>
                            Daily
                            <Tooltip title="Total allowed calls within 1 day">
                                <Icon style={{ marginLeft: 5 }} type="question-circle" />
                            </Tooltip>

                        </span>
                    }>
                        {props.form.getFieldDecorator("_daily", {
                            rules: [{ required: true, message: "Required" }],
                            initialValue: data && data.quotaByKeys !== null ? data.quotaByKeys._daily : 150000,
                        })(
                            <InputNumber
                                min={0}
                                placeholder='Please enter number'
                                formatter={(value: string) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                style={{ width: "100%" }}>
                            </InputNumber>
                        )}
                    </O2OFormItem>
                </Col>
                <Col xs={6}>
                    <O2OFormItem label={
                        <span>
                            Block
                            <Tooltip title="Enable to block all API calls when reaching the limit quota">
                                <Icon style={{ marginLeft: 5 }} type="question-circle" />
                            </Tooltip>

                        </span>
                    }>
                        {props.form.getFieldDecorator("enabledBlocked", {
                            initialValue: data ? (data.enabledBlocked === true ? true : false) : false,
                            valuePropName: "checked",

                        })(
                            <Switch checkedChildren="Yes" unCheckedChildren="No" />
                        )}
                    </O2OFormItem>
                </Col>
                <Col xs={12}>
                    <O2OFormItem label="Error Message">
                        {props.form.getFieldDecorator("alertMessage1", {
                            rules: [{ required: true, message: "Required" }],
                            initialValue: data ? data.alertMessage : "You have exceeded your rate limit."
                        })(
                            <Input placeholder='Please enter message'></Input>
                        )}
                    </O2OFormItem>
                </Col>
                <Col xs={6}>
                    <O2OFormItem label={
                        <span>
                            Unblock After
                            <Tooltip title="Unblock the API calls since the last call">
                                <Icon style={{ marginLeft: 5 }} type="question-circle" />
                            </Tooltip>

                        </span>
                    }>
                        {props.form.getFieldDecorator("unblockedPeriodInMin", {
                            rules: [{ required: true, message: "Required" }],
                            initialValue: data ? data.unblockedPeriodInMin : 30
                        })(
                            <Select placeholder="Select one">
                                <Select.Option value={5}>5 minutes</Select.Option>
                                <Select.Option value={10}>10 minutes</Select.Option>
                                <Select.Option value={15}>15 minutes</Select.Option>
                                <Select.Option value={30}>30 minutes</Select.Option>
                                <Select.Option value={60}>60 minutes</Select.Option>
                                <Select.Option value={90}>90 minutes</Select.Option>
                                <Select.Option value={120}>120 minutes</Select.Option>
                            </Select>
                        )}
                    </O2OFormItem>
                </Col>

                {blockObject ? (
                    <Col xs={24}>
                        <strong style={{ color: "red" }} >API Ingestion is blocked. Automatically unblock {moment(blockObject ? blockObject.unblockedAt : 0).fromNow()}.</strong> <Divider type='vertical' /> <Popconfirm title="Are you sure to unblock?" okText="Unblock" onConfirm={async () => {
                            const orgId = props.form.getFieldValue("organization")
                            setLoading(true)
                            try {
                                const promise = await props.commonActionFromDALV2(CommonDAL.buildURL, `${BIGDATA_CONSTANT.url.svc}api/mngmnt/orgs/config/quota/${orgId}/ApiIngestion/unblocked`, {}, Methods.GET)
                                const data = await promise
                                setLoading(false)
                                if (data.success) {
                                    notification.success({
                                        message: BIGDATA_CONSTANT.message.notification.success.saved,
                                        description: _.get(data, "message", "Unblocked successfully")
                                    })
                                    setTimeout(() => {
                                        loadDataAPIIngestion()
                                    }, 1000);
                                } else {
                                    notification.error({
                                        message: BIGDATA_CONSTANT.message.notification.fail.update,
                                        description: _.get(data, "message", "Unblocked fail")
                                    })
                                }
                            } catch (error) {
                                setLoading(false)
                                notification.error({
                                    message: BIGDATA_CONSTANT.message.notification.fail.update,
                                    description: _.get(error, "message", "Unblocked fail")
                                })
                            }

                        }}>
                            <Button type="link" size="small">Unblocked</Button>
                        </Popconfirm>
                    </Col>
                ) : null}

                <Col xs={24}>

                    <Button loading={loading} type='primary' onClick={handleUpdate}>Update</Button>
                </Col>

            </Row>
        </Form>

    )
})

export default ApiIngestion