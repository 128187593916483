import { IResponseBigDataVN, TypeResultsBigData } from 'o2o_layout/lib/common/interface';
export type TypeChangeLog = {
    id?: string;
    orgId?: string;
    endpoint?: string;
    category?: string;
    objectType?: string;
    objectId?: string;
    action?: string;
    actionBy?: string;
    numberItems?: number;
    validItems?: number;
    invalidItems?: number;
    input?: string;
    output?: string;
    statusCode?: string;
    message?: string;
    tags?: string[];
    startTime?: number;
    endTime?: number;
    took?: number;
    env?: string;
}

export class ChangeLogs implements IResponseBigDataVN<TypeChangeLog> {
    success: boolean;
    results: TypeResultsBigData<TypeChangeLog>;
    constructor() {
        this.results = {
            content: [],
            timeTaken: 0,
            currentTime: 0,
            page: {
                pageSize: 0,
                currentPage: 0,
                totalRecords: 0,
                totalPages: 0
            },
            extras: null
        }
        this.success = false
    }
}