import { Button, Col, DatePicker, Drawer, Form, Input, Row, Select, Switch } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import moment from 'moment';
import { TypeOrganization, typeAccountType } from 'o2o_layout/lib/common/model';
import React from "react";
import { FormattedMessage } from 'react-intl';
import { O2OFormItem } from '../../shares/O2OFormItem';

                                                                      
export interface ModalCreateOrgProps extends FormComponentProps {
    visible?: boolean,
    onCreate?: any,
    loading?: boolean,
    onCancel?: any
    org?: TypeOrganization
}

export const ModalCreateOrg = Form.create<ModalCreateOrgProps>({ name: 'form_in_modal' })(
    // eslint-disable-next-line
    class extends React.Component<ModalCreateOrgProps, any> {
        render() {
            const { visible, onCancel, onCreate, form, loading, org } = this.props;
            const { getFieldDecorator } = form;
            return (
                <Drawer
                    title={org && org.id ? `UPDATE ORGANIZATION / ${org.name}` : "CREATE A ORGANIZATION"}
                    visible={visible}
                    width={700}
                    onClose={onCancel}
                    bodyStyle={{ marginBottom: 60 }}

                >
                    <Form layout="vertical" colon={false} hideRequiredMark={true}>

                        <Row gutter={[5, 5]} type="flex" justify="space-between">

                            <Col xs={12}>
                                <O2OFormItem help="" label="Brand Name">
                                    {getFieldDecorator('name', {
                                        initialValue: org && org.name ? org.name : '',
                                        rules: [{ required: true }],
                                    })(<Input placeholder="ByteTech" />)}
                                </O2OFormItem>
                            </Col>

                            <Col xs={12}>
                                <O2OFormItem help="" label="CODE of Company">
                                    {getFieldDecorator('code', {
                                        initialValue: org && org.code ? org.code : '',
                                        rules: [{ required: true }],
                                    })(<Input placeholder="BYTETECH" />)}
                                </O2OFormItem>
                            </Col>

                            <Col xs={24}>
                                <O2OFormItem help="" label="Name of Company">
                                    {getFieldDecorator('fullName', {
                                        initialValue: org && org.fullName ? org.fullName : '',
                                        rules: [{ required: true }],
                                    })(<Input placeholder="Bytetech JSC" />)}
                                </O2OFormItem>
                            </Col>

                            <Col xs={8}>
                                <O2OFormItem help="" label="Account Type">
                                    {getFieldDecorator('accountType',
                                        {
                                            initialValue: org && org.accountType ? org.accountType : undefined,
                                            rules: [{
                                                required: true,
                                                message: 'Required'
                                            }],

                                        })(
                                            <Select placeholder="Select one">
                                                <Select.Option key={"Enterprise" as typeAccountType}>Enterprise</Select.Option>
                                                <Select.Option key={"Lite" as typeAccountType}>Lite</Select.Option>
                                            </Select>
                                        )}
                                </O2OFormItem>
                            </Col>
                            <Col xs={6}>
                                <O2OFormItem help="" label="Phase">
                                    {getFieldDecorator('phase',
                                        {
                                            initialValue: org && org.phase ? org.phase : undefined,
                                            rules: [{
                                                required: true,
                                                message: 'Required'
                                            }],

                                        })(
                                            <Select placeholder="Select one">
                                                <Select.Option key="Pilot">Pilot</Select.Option>
                                                <Select.Option key="Tuning & Grow">Tuning & Grow</Select.Option>
                                                <Select.Option key="Retention">Retention</Select.Option>
                                                <Select.Option key="Dropped">Dropped</Select.Option>
                                            </Select>
                                        )}
                                </O2OFormItem>
                            </Col>
                            <Col xs={10}>
                                <O2OFormItem label="Environment">
                                    {getFieldDecorator('environment',
                                        {
                                            //@ts-ignore
                                            initialValue: org && org.environment ? org.environment : 'Production',
                                        })(
                                            <Select placeholder="Select">
                                                <Select.Option key={"Staging"}>Staging</Select.Option>
                                                <Select.Option key={"Production"}>Production</Select.Option>
                                            </Select>
                                        )}
                                </O2OFormItem>
                            </Col>



                            <Col xs={24}>
                                <O2OFormItem help="" label={
                                    <FormattedMessage id="app.organization.form.industry" />
                                }>
                                    {getFieldDecorator('industry', {
                                        rules: [{ required: true }],
                                        initialValue: org ? org.industry || undefined : undefined
                                    })(
                                        <Select placeholder="Select one" showSearch>
                                            <Select.Option key="FMCG">FMCG</Select.Option>
                                            <Select.Option key="Retail">Retail</Select.Option>
                                            <Select.Option key="Education">Education</Select.Option>
                                            <Select.Option key="Electronic Distribution">Electronic Distribution</Select.Option>
                                            <Select.Option key="Real Estate">Real Estate</Select.Option>
                                            <Select.Option key="Spa">Spa</Select.Option>
                                            <Select.Option key="Service">Service</Select.Option>
                                            <Select.Option key="Automotive">Automotive</Select.Option>
                                            <Select.Option key="Manufacturing">Manufacturing</Select.Option>
                                            <Select.Option key="Technology">Technology</Select.Option>
                                            <Select.Option key="Pharma">Pharma</Select.Option>
                                            <Select.Option key="Marketing Agency">Marketing Agency</Select.Option>
                                            <Select.Option key="F&B">F&B</Select.Option>
                                            <Select.Option key="Other">Other</Select.Option>
                                            <Select.Option key="Banking">Banking</Select.Option>
                                            <Select.Option key="Insurance">Insurance</Select.Option>
                                            <Select.Option key="Travel">Travel</Select.Option>
                                            <Select.Option key="Airline">Airline</Select.Option>
                                            <Select.Option key="Fintech">Fintech</Select.Option>
                                            <Select.Option key="Construction Material">Construction Material</Select.Option>
                                        </Select>
                                    )}
                                </O2OFormItem>
                            </Col>

                            <Col xs={8}>
                                <O2OFormItem label="Contract Info">
                                    {this.props.form.getFieldDecorator('contractType', {
                                        // @ts-ignore
                                        initialValue: org && org.contractType ? org.contractType : undefined
                                    })(<Select allowClear placeholder="Select" showArrow>
                                        <Select.Option  value={'Pango License'}>Pango License</Select.Option>
                                        <Select.Option  value={'One-Off Campaign'}>One-Off Campaign</Select.Option>
                                        <Select.Option  value={'Project Based'}>Project Based</Select.Option>
                                    </Select>)}
                                </O2OFormItem>
                            </Col>

                            <Col xs={16}>
                                <O2OFormItem label="Effect Time">
                                    {this.props.form.getFieldDecorator('effect_time', {
                                        // @ts-ignore
                                        initialValue: org && org.contractEffectiveFrom ? [moment(org.contractEffectiveTo), moment(org.contractEffectiveTo)] : undefined
                                    })(<DatePicker.RangePicker allowClear style={{ width: "100%" }} showTime={false}  />)}
                                </O2OFormItem>
                            </Col>

                            <Col xs={24}>
                                <O2OFormItem label="Website">
                                    {getFieldDecorator('website', {
                                        rules: [{ required: false, message: "Required" }],
                                        initialValue: org ? org.website : ''
                                    })(
                                        <Input placeholder="https://bytetech.io" />
                                    )}
                                </O2OFormItem>
                            </Col>
                            <Col xs={24}>
                                <O2OFormItem label="Address">
                                    {getFieldDecorator('address', {
                                        initialValue: org && org.address ? org.address : '',
                                    })(<Input placeholder="203 Nam Ky Khoi Nghia, Q3, HCM" />)}
                                </O2OFormItem>
                            </Col>

                            <Col xs={12}>

                                <O2OFormItem label="Phone">
                                    {getFieldDecorator('phone', {
                                        initialValue: org && org.phone ? org.phone : '',
                                    })(<Input placeholder="0986******" />)}
                                </O2OFormItem>
                            </Col>
                            <Col xs={12}>
                                <O2OFormItem label="Email">
                                    {getFieldDecorator('email', {
                                        initialValue: org && org.email ? org.email : '',
                                        rules: [
                                            {
                                                type: 'email',
                                                message: 'The input is not valid E-mail!',
                                            },

                                        ]
                                    })(<Input placeholder="hi@bytetech.io" />)}
                                </O2OFormItem>
                            </Col>

                            {org && org.id ? null : (
                                <Col xs={24}>
                                    <O2OFormItem label="Account Status">
                                        {getFieldDecorator('status', {
                                            initialValue: org && org.id ? (org.status == 'active' ? true : false) : true,
                                            valuePropName: 'checked'
                                        })(<Switch checkedChildren="ACTIVE" unCheckedChildren="INACTIVE"></Switch>)}
                                    </O2OFormItem>
                                </Col>
                            )}


                        </Row>
                    </Form>

                    <div
                        style={{
                            position: 'absolute',
                            right: 0,
                            bottom: 0,
                            width: '100%',
                            borderTop: '1px solid #e9e9e9',
                            padding: '10px 16px',
                            background: '#fff',
                            textAlign: 'right',
                        }}
                    >
                        <Button style={{ marginRight: 10 }} onClick={onCancel}>Close</Button>

                        <Button type="primary" onClick={onCreate} loading={loading}>
                            {org && org.id ? "Update" : "Create"}
                        </Button>


                    </div>

                </Drawer>
            );
        }
    },
);
