import { UISettingDAL } from '../dal/UISettingDAL';
import { GET_UISETTING, IActionUISetting, GET_UI_DATA_SETTING, IActionUIDataSetting } from '../reducer/uiSettingReducer';
import { UPDATE_COMMON_RESPONSE, IActionCommonResponse } from '../../../redux/reducer/commonResponseReducer';

export function getUISettingCtx() {
    return (dispatch: any) => {

       
        return new Promise((resolve, reject) => {
            UISettingDAL.getUISettingAccountContext().then((user: any) => {
                dispatch({
                    type: GET_UISETTING,
                    payload: user.result,
                } as IActionUISetting);
                
                resolve(user);
            }, error => {
                reject(error);
            });
        });

    }
}

export function getUIDataSettingCtx() {
    return (dispatch: any) => {

       
        return new Promise((resolve, reject) => {
            UISettingDAL.getUIDataSettingAccountContext().then((user: any) => {
                
                dispatch({
                    type: GET_UI_DATA_SETTING,
                    payload: user.result,
                } as IActionUIDataSetting);
                
                resolve(user);
            }, error => {
                reject(error);
            });
        });

    }
}

export function saveThemeColor(color: string){
    return (dispatch: any) => {

       
        return new Promise((resolve, reject) => {
            UISettingDAL.saveThemeColor(color).then((user: any) => {
                dispatch({
                    type: UPDATE_COMMON_RESPONSE,
                    payload: user,
                } as IActionCommonResponse);
                
                resolve(user);
            }, error => {
                reject(error);
            });
        });

    }
}


export function saveUIData(data: any){
    return (dispatch: any) => {

       
        return new Promise((resolve, reject) => {
            UISettingDAL.saveUiData(data).then((user: any) => {
                dispatch({
                    type: UPDATE_COMMON_RESPONSE,
                    payload: user,
                } as IActionCommonResponse);
                
                resolve(user);
            }, error => {
                reject(error);
            });
        });

    }
}