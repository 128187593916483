import React from 'react';
import { Route, Redirect } from 'react-router';
import { IState } from '../redux/reducer';
import { O2OCommonContainer } from '../common/container/O2OContainer';
import { updateAuthen, getMe, typeGetMe } from '../common/actions/CommonAction';
import { bindActionCreators } from 'redux';
import nprogress from 'nprogress'
import 'nprogress/nprogress.css'
// import { LoginContainer } from '../modules/login/LoginContainer';
import { UI } from '../redux/model/ui';
import { Spin } from 'antd';
import { UI403Component } from '../modules/ui/UI403Component';
import { SelectCompanyContainer } from '../modules/company/SelectCompanyContainer';
import { User, UISetting } from 'o2o_layout/lib/common/model';
import { changeAntdTheme, generateThemeColor } from 'mini-dynamic-antd-theme';
import { BIGDATA_CONSTANT } from '../common/constant';
import { getUIDataSettingCtx, getUISettingCtx } from '../modules/setting/action/UISettingAction';


type Props = {
    component: any,
    failComponent?: any,
    authed?: boolean,
    exact: any,
    path: any,
    ui?: UI,
    authen?: any,
    setGlobalLoading: any,
    updateAuthen: any,
    getMe: typeGetMe,
    user: User,
    getUIDataSettingCtx: any,
    getUISettingCtx: any,
    uiSetting: UISetting
}

function mapStateToProps(rootState: IState) {
    return {
        ui: rootState.ui,
        authen: rootState.authen,
        user: rootState.user,
        uiSetting: rootState.uiSetting
    };
}


function mapDispatchToProps(dispatch: any) {
    return {
        updateAuthen: bindActionCreators(updateAuthen, dispatch),
        getMe: bindActionCreators(getMe, dispatch),
        getUIDataSettingCtx: bindActionCreators(getUIDataSettingCtx, dispatch),
        getUISettingCtx: bindActionCreators(getUISettingCtx, dispatch)
    };
}

/**
 * Authen router xác thực
 * 1.check storage đã có thông tin chưa, nếu chưa -> Login
 * 2.nếu có call api getMe để lấy thông tin valid hay invalid 
 * @author: tuantruong
 * @param  
 */
export class AuthenRouter extends React.Component<Props, any> {

    state = {
        pendingGUI: true
    }

    componentWillMount() {
        nprogress.start()
    }

    componentDidMount() {
        this.props.setGlobalLoading(true)
        changeAntdTheme(
            generateThemeColor(BIGDATA_CONSTANT.primaryColorDelay)
        )
        Promise.all([
            this.props.getMe(),
            this.props.getUISettingCtx(),
            this.props.getUIDataSettingCtx()
        ])
            .then((user: any) => {
                this.props.setGlobalLoading(false)
                this.setState({ pendingGUI: false })
                if (this.props.uiSetting.themeColor) {
                    changeAntdTheme(
                        generateThemeColor(this.props.uiSetting.themeColor ? this.props.uiSetting.themeColor : BIGDATA_CONSTANT.primaryColor)
                    )
                } else {
                    changeAntdTheme(
                        generateThemeColor(BIGDATA_CONSTANT.primaryColor)
                    )
                }
                nprogress.done()

            })
            .catch((error: any) => {
                nprogress.done()
                // this.setState({ pendingGUI: false })
                this.props.setGlobalLoading(false)
                changeAntdTheme(
                    generateThemeColor(BIGDATA_CONSTANT.primaryColor)
                )
            })
    }

    renderAuthen = () => {
        const { component: Component, failComponent: FailComponent, authen, ...rest } = this.props;
        
        if (authen.isAuth) {
            if (this.props.ui && this.props.ui.errorStatus == 401) {
                return <Redirect to="/login"/>
                    // {...rest}
                    // component={LoginContainer}
                // />
            } else if (this.props.ui && this.props.ui.errorStatus == 403) {
                return <Route
                    {...rest}
                    component={UI403Component}
                />
            } else {
            
                if (this.props.user && this.props.user.id && !this.props.user.orgCtx)  {
                    return <Route
                        {...rest}
                        render={(props) =>
                            <SelectCompanyContainer {...props} />
                        }
                    />
                } else {
                    return <Route
                        {...rest}
                        render={(props) =>
                            <Component {...props} />
                        }
                    />
                }

            }
        } else {
            return FailComponent ? (
                <Redirect to="/login"/>
                // <Route
                //     {...rest}
                //     component={FailComponent}
                // />
            ) : (
                 <Redirect to="/login"/>
                
                )
        }

    }


    render() {

        return (
            <Spin spinning={this.props.ui && this.props.ui.isLoading} tip="Loading">
                {this.renderAuthen()}
            </Spin>

        )
    }
}

export const AuthenRouterContainer = O2OCommonContainer(AuthenRouter, mapStateToProps, mapDispatchToProps)