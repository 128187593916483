import { Col, Popconfirm, Row, Spin, Switch, Typography } from 'antd'
import { TypeUIDataSetting, UIDataSetting } from 'o2o_layout/lib/common/model'
import React, { useEffect, useState } from 'react'
import { CommonNotificationSaveError, CommonNotificationUpdateSuccessfully } from '../../../../../shares/CommonNotification'
import { CompanyDAL } from '../../DAL'
import _ from 'lodash'

interface Props {
    commonActionFromDALV2: any
}

export const SwitchPublishButton = (props: {
    api: any,
    load: any,
    showText: boolean,
    status: boolean,
    dataConfig: UIDataSetting
    keyData: string
}) => {
    const [loading, setLoading] = React.useState(false)

    return (
        <Popconfirm title={`Are you sure to ${props.status ? "disable" : "enable"}`}
            okText={!props.status ? "Enable" : "Disable"}
            onConfirm={async () => {
                setLoading(true)
                await props.api(Object.assign(props.dataConfig, { [props.keyData]: !props.status }))
                setLoading(false)
            }}
        >
            <Switch loading={loading} style={{ cursor: "default", marginRight: 20 }} checked={props.status}
                unCheckedChildren={props.showText ? "OFF" : ""}
                checkedChildren={props.showText ? "ON" : ""}
            />
        </Popconfirm>

    )
}

const DataMaking = ({ commonActionFromDALV2 }: Props) => {
    const [dataConfig, setDataConfig] = React.useState<TypeUIDataSetting>({} as TypeUIDataSetting)
    const [loading, setLoading] = useState<boolean>(false)
    const loadConfigData = async () => {
        try {
            setLoading(true)
            const result = commonActionFromDALV2(CompanyDAL.getConfigData)
            const response = await result
            if (response.success) {
                setDataConfig(response.result)
                setLoading(false)
            } else {
                setDataConfig({} as TypeUIDataSetting)
                setLoading(false)
            }
        } catch (error) {
            setDataConfig({} as TypeUIDataSetting)
            setLoading(false)
        }
    }

    const saveConfig = async (data: TypeUIDataSetting) => {
        try {
            const result = commonActionFromDALV2(CompanyDAL.saveConfigData, data)
            const response = await result
            if (response.success) {
                CommonNotificationUpdateSuccessfully()
                setTimeout(() => {
                    loadConfigData()
                }, 1000);
            } else {
                CommonNotificationSaveError(response.message)
                setLoading(false)
            }
        } catch (error) {
            CommonNotificationSaveError(_.get(error, "message", "Something went wrong!"))
            setLoading(false)
        }
    }

    useEffect(() => {
        loadConfigData()
    }, [])
    return (
        <Spin spinning={loading}>
            <Row gutter={[10, 10]} type='flex' align="middle" >
                <Col xs={24}>
                    <Typography.Title level={4}>Data masking</Typography.Title>
                    <Typography.Text type="secondary">
                        Allow you to set-up masking important, private and sensitive data, such as Phone, Email, Address of Customer.
                    </Typography.Text>
                </Col>

                <Col xs={24}>
                    <p>
                        <SwitchPublishButton
                            api={saveConfig}
                            keyData={"showMaskingData"}
                            load={loadConfigData}
                            showText={true}
                            status={dataConfig.showMaskingData}
                            dataConfig={dataConfig}
                        />
                        Displays the value of data obscured with a tooltip

                    </p>

                    <p>

                        <SwitchPublishButton
                            api={saveConfig}
                            keyData={"maskingAddress"}
                            load={loadConfigData}
                            showText={true}
                            status={dataConfig.maskingAddress}
                            dataConfig={dataConfig}
                        />
                        Masking customer specific addresses
                    </p>

                    <p>
                        <SwitchPublishButton
                            api={saveConfig}
                            keyData={"maskingEmail"}
                            load={loadConfigData}
                            showText={true}
                            status={dataConfig.maskingEmail}
                            dataConfig={dataConfig}
                        />
                        Masking contact email

                    </p>

                    <p>
                        <SwitchPublishButton
                            api={saveConfig}
                            keyData={"maskingPhone"}
                            load={loadConfigData}
                            showText={true}
                            status={dataConfig.maskingPhone}
                            dataConfig={dataConfig}
                        />
                        Masking contact phone number
                    </p>
                </Col>
            </Row>
        </Spin>
    )
}

export default DataMaking