import { Alert, Button, Checkbox, Col, Drawer, Form, Input, Row, Popconfirm } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import React from "react"
import { TypeConsoleUser } from 'o2o_layout/lib/common/model'
import { useState } from 'react'
import { O2OFormItem } from '../../shares/O2OFormItem'


interface IDrawerResetPassword extends FormComponentProps {
    visible: boolean
    onClose: () => void
    onReset: (sl: any) => void
    selectedUser?: TypeConsoleUser
}

const DrawerResetPassword = (props: IDrawerResetPassword) => {
    const [loading, setLoading] = useState(false as boolean)
    const { visible, onClose, onReset, selectedUser } = props
    const { getFieldDecorator } = props.form
    const { confirm } = props.form.getFieldsValue(["confirm"])

    return (
        <Drawer
            bodyStyle={{ marginBottom: 50, paddingTop: 5 }}
            title={"RESET PASSWORD"}
            visible={visible}
            onClose={() => {
                onClose()
                props.form.resetFields()
            }}
            maskClosable={false}
            width={450}
        >
            <Form hideRequiredMark colon={false}>
                <Row type='flex' gutter={[10, 10]}>

                    <Col xs={24}>
                        <O2OFormItem label="Email">
                            {getFieldDecorator("email", {
                                initialValue: selectedUser ? selectedUser.id : undefined,
                            })(<Input disabled />)}
                        </O2OFormItem>
                    </Col>

                    <Col xs={24}>
                        <O2OFormItem label="Name">
                            {getFieldDecorator("name", {
                                initialValue: selectedUser ? selectedUser.name : undefined,
                            })(<Input disabled />)}
                        </O2OFormItem>
                    </Col>

                    <Col xs={24}>
                        <Alert type="warning" message={<>
                            You are about to reset password of this account.
                            PangoCDP will send an email to {selectedUser ? selectedUser.id : null} after completed
                        </>} />
                    </Col>

                    <Col xs={24}>
                        <O2OFormItem>
                            {getFieldDecorator("confirm", {
                                valuePropName: 'checked',
                            })(<Checkbox >Confirm to reset</Checkbox>)}
                        </O2OFormItem>
                    </Col>

                </Row>
            </Form>
            <div
                style={{
                    position: 'absolute',
                    right: 0,
                    bottom: 0,
                    width: '100%',
                    borderTop: '1px solid #e9e9e9',
                    padding: '10px 16px',
                    background: '#fff',
                    textAlign: 'right',
                }}
            >
                <Button style={{ marginRight: 8 }} onClick={() => {
                    onClose()
                    props.form.resetFields()

                }}>Close</Button>

                {confirm ? (
                    <Popconfirm placement="leftTop" title={`Are you sure you want to reset password account: ${props.selectedUser ? props.selectedUser.name : ""}?`} onConfirm={() => {
                        onReset(setLoading)
                    }}>
                        <Button loading={loading} icon="redo" type="primary">Reset</Button>
                    </Popconfirm>
                ) : (
                    <Button disabled={true} icon="redo" type="primary">Reset</Button>
                )}
            </div>
        </Drawer>
    )
}

export const DrawerFormResetPassword = Form.create<IDrawerResetPassword>()(DrawerResetPassword)
