import { IResponseSingleModel } from "../../common/IResponseBigData";

export class CommonResponse implements IResponseSingleModel<any> {
    success: boolean
    message?: string
    result?: any
    results?: any
    timeTaken?: number
    errorCode?: any

    constructor(){
        this.success = false;
        this.message = '';
        this.timeTaken = 0
    }
}