import { Response } from '../../libs/api/Response';
import { OrgsDAL } from '../orgs/OrgsDAL';
import { GET_CURRENT_ORGS, IActionCurrentUserOrgs } from '../../redux/reducer/selectCurrentOrgReducer';
import { UPDATE_ERROR } from '../../common/actionType/actionType';
import { IActionUI } from '../../redux/reducer/uiReducer';
import { UserDAL } from '../../dal/UserDAL';
import { UPDATE_COMMON_RESPONSE, IActionCommonResponse } from '../../redux/reducer/commonResponseReducer';


export function getCurrentUserOrgs() {
    return (dispatch: any) => {

        return new Promise((resolve, reject) => {
            
            OrgsDAL.listCurrentUserOrg()
                .then(orgs => {
                    dispatch({
                        type: GET_CURRENT_ORGS,
                        payload: orgs
                    } as IActionCurrentUserOrgs)

                    resolve(orgs);
                }, (error: Response) => {
                    if (error.status == 401 || error.status == 403) {
                        dispatch({
                            type: UPDATE_ERROR,
                            errorStatus: error.status,
                            errorResponse: error.data
                        } as IActionUI)
                    }
                    reject(error);
                });
        });

    }
}

export function selectUserOrg(orgid: string, header: any, token: string) {
    return (dispatch: any) => {

        return new Promise((resolve, reject) => {

            UserDAL.selectOrg(orgid,header,token)
                .then(orgs => {
                    dispatch({
                        type: UPDATE_COMMON_RESPONSE,
                        payload: orgs
                    } as IActionCommonResponse)

                    resolve(orgs);
                }, (error: Response) => {
                    if (error.status == 401 || error.status == 403) {
                        dispatch({
                            type: UPDATE_ERROR,
                            errorStatus: error.status,
                            errorResponse: error.data
                        } as IActionUI)
                    }
                    reject(error);
                });
        });

    }
}
