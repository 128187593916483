import { Button, Col, DatePicker, Divider, Dropdown, Form, Icon, Input, InputNumber, Menu, Popover, Row, Table, Tag, Tooltip, Typography } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import { ColumnProps } from 'antd/lib/table'
import { get, isArray, isEmpty, size } from 'lodash'
import moment from "moment"
import { InfoList, LongTextTd, O2OCustomPaging } from 'o2o_layout'
import React, { useEffect, useState } from 'react'
import { useAsyncRetry } from 'react-use'
import CommonTypeProps, { ICommonPayload } from '../../../common/CommonTypeProps'
import DrawerViewJSON from '../../../shares/DrawerViewJSON'
import { O2OFormItem } from '../../../shares/O2OFormItem'
import { CronjobDAL } from './DAL'
import SuggestionCronjobConfig from './SuggestionCronjobConfig'
import { CronjobHistories, TypeCronjobHistory } from './model'
import { ClickParam } from 'antd/lib/menu'

interface IProps extends FormComponentProps {
    loading: boolean
    commonActionFromDAL2: any
    onSearch: (payload: any) => void
    configIds?: string[]
}

const TYPE_SEARCH_ON = {
    'totalRecords': 'Total Records',
    'totalValidRecords': 'Total Valid Records',
    'totalInvalidRecords': 'Total Invalid Records',
}

const FilterCronjobHistoryComponent = Form.create<IProps>()(
    ({ loading, form, commonActionFromDAL2, onSearch, configIds }: IProps) => {

        const [selectedSearchOn, setSelectedSearchOn] = useState('')

        const onSubmit = () => {
            form.validateFields((err: any, values: any) => {
                if (!err) {
                    let payload = {
                        configIds: values.configIds,
                        freeText: values.freeText,
                        recordField: selectedSearchOn,
                        fromTime: 0,
                        toTime: 0,
                        fromRecords: values.fromRecords,
                        toRecords: values.toRecords,
                    }
                    if (values.time && isArray(values.time) && size(values.time) === 2) {
                        payload = { ...payload, ... { fromTime: moment(values.time[0]).toDate().getTime(), toTime: moment(values.time[1]).toDate().getTime() } }
                    }
                    onSearch(payload)
                }
            })
        }

        return <Form hideRequiredMark colon={false}>
            <Row type='flex' align={"middle"} gutter={[5, 5]}>
                <Col sm={24} lg={12} xl={5} >
                    <O2OFormItem label="Info">
                        {form.getFieldDecorator("freeText", {

                        })(<Input onPressEnter={onSubmit} placeholder='Enter' allowClear />)}
                    </O2OFormItem>
                </Col>

                <Col style={{ flexGrow: 1 }} sm={24} lg={12} xl={5} >
                    <SuggestionCronjobConfig
                        initData={configIds}
                        form={form}
                        keyField={'configIds'}
                        commonActionFromDAL2={commonActionFromDAL2}
                        lable={<>
                            Config 
                        </>}
                    />
                </Col>

                <Col sm={24} lg={12} xl={6} >
                    <O2OFormItem label="Time Range">
                        {form.getFieldDecorator("time", {

                        })(<DatePicker.RangePicker style={{ width: "100%" }} showTime={{ defaultValue: [moment("00:00:00", "HH:mm:ss"), moment("23:59:59", "HH:mm:ss")] }} />)}
                    </O2OFormItem>
                </Col>

                <Col sm={24} lg={12} xl={5} >
                    <O2OFormItem label={<span>
                        Records <Divider type='vertical' />
                        <Dropdown overlay={<Menu selectedKeys={[selectedSearchOn]} onClick={(e: ClickParam) => {
                            if (e.key === 'remove') {
                                setSelectedSearchOn('')
                            } else {
                                setSelectedSearchOn(e.key)
                            }
                        }}>
                            <Menu.Item key={'totalRecords'}>{TYPE_SEARCH_ON.totalRecords}</Menu.Item>
                            <Menu.Item key={'totalValidRecords'}>{TYPE_SEARCH_ON.totalValidRecords}</Menu.Item>
                            <Menu.Item key={'totalInvalidRecords'}>{TYPE_SEARCH_ON.totalInvalidRecords}</Menu.Item>
                            <Menu.Divider />
                            <Menu.Item key={'remove'}><Icon type='delete' /> Remove</Menu.Item>
                        </Menu>}>
                            <span>
                                <a>Search on: {TYPE_SEARCH_ON[selectedSearchOn] || ''} <Icon type='caret-down' /></a>
                            </span>
                        </Dropdown>
                    </span>}>
                        <Row type='flex' gutter={[5, 5]}>
                            <Col xs={12}>
                                {form.getFieldDecorator("fromRecords", {

                                })(<InputNumber style={{ width: "100%" }} formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')} min={1} placeholder='Enter' />)}
                            </Col>
                            <Col xs={12}>
                                {form.getFieldDecorator("toRecords", {

                                })(<InputNumber style={{ width: "100%" }} formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')} min={1} placeholder='Enter' />)}
                            </Col>
                        </Row>
                    </O2OFormItem>
                </Col>

                <Col>
                    <O2OFormItem label=" ">
                        <Button loading={loading} onClick={onSubmit} type='primary' icon='search'>Search</Button>
                    </O2OFormItem>
                </Col>
            </Row>
        </Form >
    }
)









interface ICronjobHistoryComponentProps extends CommonTypeProps {
    configId?: string
    showTitle: boolean
}

const TYPE_DISPATCH_CRITERIA = "TYPE_DISPATCH_CRITERIA"

const initCriteria: ICommonPayload<any> = {
    loading: false,
    type: TYPE_DISPATCH_CRITERIA,
    payload: {} as any,
}

const reducerCriteria = (state: ICommonPayload<any>, action: ICommonPayload<any>) => {
    switch (action.type) {
        case TYPE_DISPATCH_CRITERIA:
            return { ...state, ...action }
        default:
            throw new Error()
    }
}

const CronjobHistoryComponent = ({ commonActionFromDALV2, configId, showTitle }: ICronjobHistoryComponentProps) => {
    const [criteria, dispatchCriteria] = React.useReducer(reducerCriteria, initCriteria)
    const [visibleViewJSON, setVisibleViewJSON] = useState(false)
    const [selectedRecord, setSelectedRecord] = useState(undefined as undefined | TypeCronjobHistory)

    useEffect(() => {
        dispatchCriteria({
            loading: false,
            type: TYPE_DISPATCH_CRITERIA,
            payload: { ...criteria.payload, ...{ page: 0, pageSize: 10 } }
        })
        return () => {
            dispatchCriteria({
                loading: false,
                type: TYPE_DISPATCH_CRITERIA,
                payload: {}
            })
        }
    }, [])

    useEffect(() => {
        if (configId) {
            dispatchCriteria({
                loading: false,
                type: TYPE_DISPATCH_CRITERIA,
                payload: { ...criteria.payload, ...{ configIds: [configId] } }
            })
        }
    }, [configId])


    const load = async () => {
        const result = commonActionFromDALV2(CronjobDAL.historySearch, criteria.payload)
        try {
            const response: CronjobHistories = await result
            if (response.success) {
                return response
            } else {
                return new CronjobHistories()
            }
        } catch (error) {
            return new CronjobHistories()
        }
    }


    const stateCronjobHistory = useAsyncRetry(async () => {
        if (!isEmpty(criteria.payload)) {
            const data = await load()
            return data
        }
        return new CronjobHistories()
    }, [criteria.payload])

    const cronJobs: CronjobHistories = stateCronjobHistory.value || new CronjobHistories()

    const columns: ColumnProps<TypeCronjobHistory>[] = [
        {
            title: "#",
            ellipsis: true,
            key: "index",
            width: 40,
            align: "center",
            render: (text: any, record: TypeCronjobHistory, index: number) => {
                return index + 1
            }
        },
        {
            title: "Config Id",
            ellipsis: true,
            key: "configId",
            width: 200,
            render: (text: any, record: TypeCronjobHistory, index: number) => {
                return <Popover content={<ul style={{ paddingLeft: 8, margin: 0 }}>
                    <li>Config Id: <Typography.Text copyable>{record.configId}</Typography.Text></li>
                    <li>Config Name: <Typography.Text copyable>{get(cronJobs.configs, `${record.configId}.name`)}</Typography.Text></li>
                </ul>}>
                    {get(cronJobs.configs, `${record.configId}.name`)}
                </Popover>
            }
        },
        {
            title: "Total Records",
            ellipsis: true,
            key: "totalRecords",
            width: 120,
            render: (text: any, record: TypeCronjobHistory, index: number) => {
                return <Popover content={<ul style={{ paddingLeft: 8, margin: 0 }}>
                    <li>Total Valid Records: {record.totalValidRecords}</li>
                    <li>Total Invalid Records: {record.totalInvalidRecord}</li>
                </ul>}>
                    {record.totalRecords}
                </Popover>
            }
        },
        {
            title: "Url",
            ellipsis: true,
            key: "url",
            width: 200,
            render: (text: any, record: TypeCronjobHistory, index: number) => {
                return <span>
                    <Tooltip title={`Method ${record.method}`}>
                        <Tag>{record.method === "GET" ? 'G' : 'P'}</Tag>
                    </Tooltip>
                    <LongTextTd text={record.url} />
                </span>
            }
        },
        {
            title: "From time",
            ellipsis: true,
            key: "fromTime",
            width: 120,
            render: (text: any, record: TypeCronjobHistory, index: number) => {
                return <Tooltip title={moment(record.fromTime).format('LLLL')}>
                    {moment(record.fromTime).fromNow()}
                </Tooltip>
            }
        },
        {
            title: "To time",
            ellipsis: true,
            key: "toTime",
            width: 120,
            render: (text: any, record: TypeCronjobHistory, index: number) => {
                return <Tooltip title={moment(record.toTime).format('LLLL')}>
                    {moment(record.toTime).fromNow()}
                </Tooltip>
            }
        },
        {
            title: "Start time",
            ellipsis: true,
            key: "startTime",
            width: 120,
            render: (text: any, record: TypeCronjobHistory, index: number) => {
                return <Tooltip title={moment(record.cronjobStartTime).format('LLLL')}>
                    {moment(record.cronjobStartTime).fromNow()}
                </Tooltip>
            }
        },
        {
            title: "End time",
            ellipsis: true,
            key: "endTime",
            width: 120,
            render: (text: any, record: TypeCronjobHistory, index: number) => {
                return <Tooltip title={moment(record.cronjobEndTime).format('LLLL')}>
                    {moment(record.cronjobEndTime).fromNow()}
                </Tooltip>
            }
        },
        {
            title: "Created At",
            ellipsis: true,
            key: "created",
            render: (text: any, record: TypeCronjobHistory, index: number) => {
                return <Tooltip title={<>Created by {record.createdBy} at {moment(record.createdAt).format("LLLL")}</>}>
                    {moment(record.createdAt).fromNow()}
                </Tooltip>
            }
        },
        {
            title: "Action",
            ellipsis: true,
            fixed: 'right',
            key: "action",
            width: 70,
            render: (text: any, record: TypeCronjobHistory, index: number) => {
                return <Row type='flex' align={"middle"}>
                    <Tooltip title="View JSON" >
                        <Button onClick={() => {
                            setSelectedRecord(record)
                            setVisibleViewJSON(true)
                        }} icon='select' size='small' type='link' />
                    </Tooltip>
                </Row>
            }
        }
    ]

    return (
        <Row type="flex" gutter={[10, 10]}>
            <Col xs={24}>
                {
                    showTitle &&
                    <Typography.Title level={4}>
                        History
                    </Typography.Title>
                }
            </Col>
            <Col xl={24}>
                <FilterCronjobHistoryComponent
                    configIds={criteria.payload["configIds"]}
                    onSearch={(payload: Object) => {
                        dispatchCriteria({
                            loading: false,
                            type: TYPE_DISPATCH_CRITERIA,
                            payload: { ...criteria.payload, ...{ page: 0 }, ...payload }
                        })
                    }}
                    commonActionFromDAL2={commonActionFromDALV2}
                    loading={stateCronjobHistory.loading}
                />
            </Col>
            <Col xl={24}>
                <InfoList
                    loading={stateCronjobHistory.loading}
                    list={cronJobs}
                    refresh={stateCronjobHistory.retry}
                />
            </Col>
            <Col xl={24}>
                <Table
                    size="small"
                    loading={stateCronjobHistory.loading}
                    bordered={false}
                    columns={columns}
                    dataSource={cronJobs.results.content}
                    pagination={false}
                    scroll={{ x: 'calc(900px + 50%)' }}
                    rowKey="id"

                />
            </Col>
            <Col xl={24}>
                <O2OCustomPaging
                    list={cronJobs}
                    onChange={(e: any) => {
                        dispatchCriteria({
                            loading: false,
                            type: TYPE_DISPATCH_CRITERIA,
                            payload: { ...criteria.payload, ...{ page: e - 1 } }
                        })
                    }}
                    onChangePageSize={(e: any) => {
                        dispatchCriteria({
                            loading: false,
                            type: TYPE_DISPATCH_CRITERIA,
                            payload: { ...criteria.payload, ...{ page: 0, pageSize: e } }
                        })
                    }}
                />
            </Col>

            <DrawerViewJSON
                data={selectedRecord}
                visible={visibleViewJSON}
                onClose={() => {
                    setSelectedRecord(undefined)
                    setVisibleViewJSON(false)
                }}
            />

        </Row>
    )
}

export default CronjobHistoryComponent
