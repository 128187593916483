import { Button, Col, Drawer, Form, Icon, InputNumber, notification, Row, Switch } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import _, { get } from 'lodash'
import React from 'react'
import { BIGDATA_CONSTANT } from '../../../common/constant'
import { getPrimaryColor } from '../../../common/helper'
import { IState } from '../../../redux/reducer'
import { IActionUI, SHOW_COMMON_ERROR } from '../../../redux/reducer/uiReducer'
import { useDispatch, useSelector } from '../../../redux/store'
import { O2OFormItem } from '../../../shares/O2OFormItem'
import { TypeDTOAddZMALiveVersion, ZMALiveVersionDAL } from './DAL'
import { TypeZMALiveVersionItem } from './model'
import SuggestionZMAInfo from './share/SuggestionZMAInfo'

interface IFormAddEditProps extends FormComponentProps {
    onClose: () => void
    visible: boolean
    commonActionFromDALV2: any
    selectedRecord?: TypeZMALiveVersionItem
    onSucess: () => void
}

const FormAddEdit = (props: IFormAddEditProps) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = React.useState(false as boolean)
    const { getFieldDecorator } = props.form
    const uiSetting = useSelector((state: IState) => state.uiSetting)

    React.useEffect(() => {
        if (!props.visible) {
            props.form.resetFields()
        }
    }, [props.visible])

    const onSubmit = () => {
        if (props.selectedRecord) {
            onEdit()
        } else {
            props.form.validateFields(async (err: any, values: any) => {
                if (err) {
                    return
                } else {
                    const splitAppInfo = _.split(values.appInfo, "-")
                    const appId = splitAppInfo[0] || ""
                    const appName = splitAppInfo[1] || ""
                    const payload = {
                        appId: appId,
                        appName: appName,
                        status: values.status ? "Enabled" : "Disabled",
                        version: values.version
                    } as TypeDTOAddZMALiveVersion
                    setLoading(true)
                    const result: Promise<any> = props.commonActionFromDALV2(ZMALiveVersionDAL.add, payload)
                    try {
                        const response = await result
                        if (response.success) {
                            props.onSucess()
                            notification.success({
                                message: BIGDATA_CONSTANT.message.default.title.success,
                                description: BIGDATA_CONSTANT.message.notification.success.add,
                                icon: <Icon type="check-circle" style={{ color: getPrimaryColor(uiSetting) }} />
                            })
                            setLoading(false)
                        } else {
                            dispatch({
                                type: SHOW_COMMON_ERROR,
                                commonError: response,
                            } as IActionUI);
                            setLoading(false)
                        }
                    } catch (error) {
                        dispatch({
                            type: SHOW_COMMON_ERROR,
                            commonError: error,
                        } as IActionUI);
                        setLoading(false)
                    }

                }

            })
        }
    }


    const onEdit = async () => {
        setLoading(true)
        const { status, version } = props.form.getFieldsValue(["status", "version"])
        const payload = {
            status: status ? "Enabled" : "Disabled",
            version: version
        }
        const result: Promise<any> = props.commonActionFromDALV2(ZMALiveVersionDAL.edit, payload, get(props.selectedRecord, "id", ""))
        try {
            const response = await result
            if (response.success) {
                setLoading(false)
                props.onSucess()
                notification.success({
                    message: BIGDATA_CONSTANT.message.default.title.success,
                    description: BIGDATA_CONSTANT.message.notification.success.update,
                    icon: <Icon type="check-circle" style={{ color: getPrimaryColor(uiSetting) }} />
                })
            } else {
                setLoading(false)
                dispatch({
                    type: SHOW_COMMON_ERROR,
                    commonError: response,
                } as IActionUI);
            }
        } catch (error) {
            setLoading(false)
            dispatch({
                type: SHOW_COMMON_ERROR,
                commonError: error,
            } as IActionUI);
        }
    }

    return (
        <Drawer
            bodyStyle={{ marginBottom: 50, paddingTop: props.selectedRecord ? 24 : 5 }}
            title={<>
                <Button onClick={props.onClose} type='link' size='small' icon='left-circle' />
            {props.selectedRecord ? `${props.selectedRecord.appName} / UPDATE` : "ADD LIVE VERSION"}</>}
            visible={props.visible}
            onClose={() => {
                props.onClose()
            }}
            maskClosable={false}
            width={500}
        >
            <Form hideRequiredMark colon={false}>
                <Row type='flex' gutter={[5, 5]}>
                    <Col xs={24}>
                        {
                            props.selectedRecord ?
                                <>
                                    <span style={{ display: "block", marginBottom: 8, fontWeight: 'bold' }}>
                                        App Info: {props.selectedRecord.id}
                                    </span>
                                    <span style={{ display: "block", fontWeight: 'bold' }}>
                                        {props.selectedRecord.appName}
                                    </span>
                                </>
                                :
                                <O2OFormItem label={<>App Info
                                </>}>
                                    <SuggestionZMAInfo
                                        initData={undefined}
                                        selectedRecord={props.selectedRecord}
                                        commonActionFromDALV2={props.commonActionFromDALV2}
                                        visible={props.visible}
                                        form={props.form}
                                        required={true}
                                        keyField={"appInfo"}
                                    />
                                </O2OFormItem>
                        }
                    </Col>
                    <Col xs={24}>
                        <O2OFormItem label={<>Version</>}>
                            {getFieldDecorator("version", {
                                initialValue: get(props.selectedRecord, "version") || 0,
                                rules: [{
                                    required: true,
                                    message: "Required"
                                }]
                            })(<InputNumber min={0} placeholder='Select' />)}
                        </O2OFormItem>
                    </Col>
                    <Col xs={24}>
                        <p />
                        <span style={{ display: "block", marginBottom: 8 }}>Status</span>
                        {getFieldDecorator("status", {
                            initialValue: props.selectedRecord ? (props.selectedRecord.status === "Enabled" ? true : false) : true,
                            valuePropName: "checked"
                        })(<Switch checkedChildren="Enabled" unCheckedChildren="Disabled" />)}
                    </Col>
                </Row>
            </Form>

            <div
                style={{
                    zIndex: 999999,
                    position: 'absolute',
                    right: 0,
                    bottom: 0,
                    width: '100%',
                    borderTop: '1px solid #e9e9e9',
                    padding: '10px 16px',
                    background: '#fff',
                    textAlign: 'right',
                }}
            >
                <Button onClick={props.onClose} style={{ marginRight: 8 }}>Discard</Button>
                <Button loading={loading} onClick={onSubmit} type='primary'>{props.selectedRecord ? "Update" : "Add"}</Button>
            </div>
        </Drawer>
    )
}

export const FormAddEditZMALiveVersion = Form.create<IFormAddEditProps>()(FormAddEdit)