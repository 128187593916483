import { Form, Input, Select, Row, Col } from 'antd';
import React from "react";
import { FormComponentProps } from 'antd/lib/form';
import { FormattedMessage } from 'react-intl';
import { TypeOrgCtx } from 'o2o_layout/lib/common/model';


interface Props extends FormComponentProps {
    onCreate?: any,
    loading?: boolean,
    locale: any,
    submit: any,
    orgCtx: TypeOrgCtx | undefined,
    visibleCompany: boolean
}

export const IndustriesList = {
    art: "Art and Entertainment",
    car: "Car",
    beauty_fitness: "Beauty and fitness",
    book_literature: "Books and literature",
    industrial_markets: "Business and industrial markets",
    computer_electronics: "Computers and electronics",
    finance: "Finance",
    food_beverage: "Food & Beverage",
    game: "Game",
    heath_care: "Health care",
    hobbies_and_free_time: "Hobbies and free time",
    houses_garden: "Houses and gardens",
    internet: "Internet and telecommunications",
    job_education: "Jobs and education",
    law_govement: "Law and government",
    news: "News",
    online_community: "Online community",
    people_society: "People and society",
    pets_animals: "Pets and animals",
    real_estate: "Real estate",
    reference: "Reference",
    science: "Science",
    shopping: "Shopping",
    sport: "Sport",
    travel: "Travel",
    other: "Other"
}

export const FormCompany = Form.create<Props>({ name: 'form_in_modal' })(
    // eslint-disable-next-line
    class extends React.Component<Props, any> {

        renderTheme = () => {
            const { getFieldDecorator } = this.props.form
            const { submit } = this.props
            const { orgCtx } = this.props



            return (

                <Form colon={false} hideRequiredMark={true} onSubmit={submit} layout="vertical"  >
                    <Row gutter={[5, 5]} type='flex'>
                        <Col xs={24}>
                            <Form.Item help="" style={{ marginBottom: 0 }} label={
                                <FormattedMessage id="app.organization.form.name" />
                            }>
                                {getFieldDecorator('name', {
                                    rules: [
                                        { required: true },
                                    ],
                                    initialValue: orgCtx ? orgCtx.organization.name : ''
                                })(
                                    <Input placeholder="ByteTech" />
                                )}
                            </Form.Item>
                        </Col>

                        <Col xs={24}>
                            <Form.Item help="" style={{ marginBottom: 0 }} label={
                                <FormattedMessage id="app.organization.form.address" />
                            }>
                                {getFieldDecorator('address', {
                                    rules: [{ required: true }],
                                    initialValue: orgCtx ? orgCtx.organization.address : ''
                                })(
                                    <Input placeholder="203 Nam Ky Khoi Nghia, Q3, HCM" />
                                )}
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item help="" style={{ marginBottom: 0 }} label={
                                <FormattedMessage id="app.organization.form.industry" />
                            }>
                                {getFieldDecorator('industry', {
                                    rules: [{ required: true }],
                                    initialValue: orgCtx ? orgCtx.organization.industry : ''
                                })(
                                    <Select placeholder="Select one" showSearch>
                                        <Select.Option key="art">Art and Entertainment</Select.Option>
                                        <Select.Option key="car">Car</Select.Option>
                                        <Select.Option key="beauty_fitness">Beauty and fitness</Select.Option>
                                        <Select.Option key="book_literature">Books and literature</Select.Option>
                                        <Select.Option key="industrial_markets">Business and industrial markets</Select.Option>
                                        <Select.Option key="computer_electronics">Computers and electronics</Select.Option>
                                        <Select.Option key="finance">Finance</Select.Option>
                                        <Select.Option key="food_beverage">Food & Beverage</Select.Option>
                                        <Select.Option key="game">Game</Select.Option>
                                        <Select.Option key="heath_care">Health care</Select.Option>
                                        <Select.Option key="hobbies_and_free_time">Hobbies and free time</Select.Option>
                                        <Select.Option key="houses_garden">Houses and gardens</Select.Option>
                                        <Select.Option key="internet">Internet and telecommunications</Select.Option>
                                        <Select.Option key="job_education">Jobs and education</Select.Option>
                                        <Select.Option key="law_govement">Law and government</Select.Option>
                                        <Select.Option key="news">News</Select.Option>
                                        <Select.Option key="online_community">Online community</Select.Option>
                                        <Select.Option key="people_society">People and society</Select.Option>
                                        <Select.Option key="pets_animals">Pets and animals</Select.Option>
                                        <Select.Option key="real_estate">Real estate</Select.Option>
                                        <Select.Option key="reference">Reference</Select.Option>
                                        <Select.Option key="science">Science</Select.Option>
                                        <Select.Option key="shopping">Shopping</Select.Option>
                                        <Select.Option key="sport">Sport</Select.Option>
                                        <Select.Option key="travel">Travel</Select.Option>
                                        <Select.Option key="other">Other</Select.Option>

                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item help="" style={{ marginBottom: 0 }} label="Website">
                                {getFieldDecorator('website', {
                                    rules: [{ required: true }],
                                    initialValue: orgCtx ? orgCtx.organization.website : ''
                                })(
                                    <Input placeholder="https://bytetech.io" />
                                )}
                            </Form.Item>
                            <Col xs={24}>
                                <Form.Item help="" style={{ marginBottom: 0 }} label="Phone">
                                    {getFieldDecorator('phone', {
                                        rules: [{ required: true }],
                                        initialValue: orgCtx ? orgCtx.organization.phone : ''
                                    })(
                                        <Input placeholder="0986******" />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24}>
                                <Form.Item style={{ marginBottom: 0 }} label="Email">
                                    {getFieldDecorator('email', {
                                        initialValue: orgCtx ? orgCtx.organization.email : ''
                                    })(
                                        <Input placeholder="hi@bytetech.io" />
                                    )}
                                </Form.Item>
                            </Col>
                        </Col>
                    </Row>
                </Form>
            )
        }

        render() {

            return (

                this.renderTheme()

            );
        }
    },
);
