import { bindActionCreators } from "redux";
import ChangelogComponent from "./ChangelogComponent";
import { O2OCommonContainer } from "../../../../common/container/O2OContainer";
import { distinctByField } from "../../../../shares/action/distinctFieldAction";

const mapStateToProps = () => {
    return {

    }
}
const mapDispatchToProps = (dispatch:any) => {
    return {
        distinctByField: bindActionCreators(distinctByField, dispatch)
    }
}



export const ChangelogContainer = O2OCommonContainer(ChangelogComponent, mapStateToProps, mapDispatchToProps)