
import RestClient from "../libs/api/RestClient";
import { Methods } from '../libs/api/Methods';
import { BIGDATA_CONSTANT } from '../common/constant';
import { User as UserModel } from 'o2o_layout/lib/common/model/user';
import { TypeCriteriaSearchNotifications } from "o2o_layout/lib/common";
var axios = require('axios');

export type criteriaSeachGaAccount = {
    "accountIds"?: string,
    "freeText"?: string,
    "googleAccountIds"?: string,
    "indexFromTime"?: any,
    "indexToTime"?: any,
    "userId"?: string
    "profileIds"?: string,
    "webPropertyIds"?: string
}

export type TypeRegisterUser = {
    "address"?: "string",
    "captcha": "string",
    "email"?: "string",
    "name"?: "string",
    "password"?: "string",
    "phone"?: "string"
}

class User {
    get(username: any, password: any) {
        throw new Error("Method not implemented.");
    }

    constructor() {

    }

    register(data: TypeRegisterUser) {
        let url = BIGDATA_CONSTANT.url.api + 'accounts/annonymous/register';
        var restClient = new RestClient(Methods.POST, url);
        var headers = { HTTP_VIA: "14.165.45.255" };
        return new Promise((resolve, reject) => {
            restClient.execute(headers, data).then((response: any) => {
                resolve(response.data)
            }, error => {
                reject(error);
            });
        });
    }

    /***
     * Author: tuantruong
     * Log in api
     * @param login: username or email (String)
     * @param password: password (String)
     * @returns return a promise resolve user object model
     */
    login(usr: string, pwd: string, captcha: string, header: any) {
        let url = BIGDATA_CONSTANT.url.api + `accounts/login?captcha=${captcha}`;
        var restClient = new RestClient(Methods.POST, url);
        var headers = header;
        var body = { usr, pwd }
        return new Promise((resolve, reject) => {
            restClient.execute(headers, body).then((response: any) => {
                // BigDataConsole("DAL LOGIN RESULT", response);
                const user: UserModel = response.data;
                localStorage.setItem(BIGDATA_CONSTANT.store.key, JSON.stringify(response.data.result));
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }

    getIP() {

        return new Promise((resolve, reject) => {
            axios.get('https://go.gtmjs.com/ip', {
                timeout: 3000,
                withCredentials: false,
            }).then((e: any) => {
                resolve(e)
            }).catch((e: any) => {
                reject(e)
            })
        })

    }


    login2fa(authenCode: string, username: string) {
        let url = BIGDATA_CONSTANT.url.api + 'accounts/login/2ft';
        var restClient = new RestClient(Methods.POST, url);
        var headers = {};
        var body = { authenCode, username }
        return new Promise((resolve, reject) => {
            restClient.execute(headers, body).then((response: any) => {
                const user: UserModel = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }

    enable2Fa() {
        let url = BIGDATA_CONSTANT.url.api + 'accounts/users/current/get-totp/qrcode';
        var restClient = new RestClient(Methods.GET, url);
        var headers = {};
        var body = {}
        return new Promise((resolve, reject) => {
            restClient.execute(headers, body).then((response: any) => {
                const user: UserModel = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }
    verifyEnable2Fa(authenCode: string, totpKey: string) {
        let url = BIGDATA_CONSTANT.url.api + 'accounts/users/current/enable-totp';
        var restClient = new RestClient(Methods.POST, url);
        var headers = {};
        var body = { authenCode, totpKey }
        return new Promise((resolve, reject) => {
            restClient.execute(headers, body).then((response: any) => {
                const user: UserModel = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }
    verifyDisable2Fa() {
        let url = BIGDATA_CONSTANT.url.api + 'accounts/users/current/disable-totp';
        var restClient = new RestClient(Methods.POST, url);
        var headers = {};
        var body = {}
        return new Promise((resolve, reject) => {
            restClient.execute(headers, body).then((response: any) => {
                const user: UserModel = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }

    forgotPassword(email: string, capcha: string) {
        let url = BIGDATA_CONSTANT.url.api + 'accounts/annonymous/forgotten-password';
        var restClient = new RestClient(Methods.POST, url);
        var headers = { HTTP_VIA: "14.165.45.255" };
        var body = { email: email, captcha: capcha }
        return new Promise((resolve, reject) => {
            restClient.execute(headers, body).then((response: any) => {
                resolve(response.data)
            }, error => {
                reject(error);
            });
        });
    }

    logout() {
        let url = BIGDATA_CONSTANT.url.api + 'logout';
        var restClient = new RestClient(Methods.POST, url);
        var headers = {};
        var body = {}
        return new Promise((resolve, reject) => {
            restClient.execute(headers, body).then((response: any) => {
                // BigDataConsole("DAL LOGIN RESULT", response);
                const user: UserModel = response.data;
                // localStorage.setItem(BIGDATA_CONSTANT.store.key, JSON.stringify(response.data.result));
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }
    logoutAll() {
        let url = BIGDATA_CONSTANT.url.api + 'logout/all';
        var restClient = new RestClient(Methods.POST, url);
        var headers = {};
        var body = {}
        return new Promise((resolve, reject) => {
            restClient.execute(headers, body).then((response: any) => {
                // BigDataConsole("DAL LOGIN RESULT", response);
                const user: UserModel = response.data;
                // localStorage.setItem(BIGDATA_CONSTANT.store.key, JSON.stringify(response.data.result));
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }

    changeApiKey() {
        let url = BIGDATA_CONSTANT.url.api + 'accounts/users/current/api-key/change';
        var restClient = new RestClient(Methods.GET, url);
        var headers = {};
        var body = {}
        return new Promise((resolve, reject) => {
            restClient.execute(headers, body).then((response: any) => {
                // BigDataConsole("DAL LOGIN RESULT", response);
                const user: UserModel = response.data;
                // localStorage.setItem(BIGDATA_CONSTANT.store.key, JSON.stringify(response.data.result));
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }

    changePwd(oldPassword: string, newPassword: string, confirmPassword: string) {
        let url = BIGDATA_CONSTANT.url.api + 'accounts/users/change-password';
        var restClient = new RestClient(Methods.POST, url);
        // var user: UserModel  = this.getUserFromLocalStorage();
        var headers = {
            // token: user.token,
            // api_key: user.apiKey
        };
        var body = {
            "confirmPassword": confirmPassword,
            "newPassword": newPassword,
            "oldPassword": oldPassword
        }
        return new Promise((resolve, reject) => {
            restClient.execute(headers, body).then((response: any) => {
                // BigDataConsole("Get Me", response);
                const data: any = response.data;

                resolve(data)
            }, error => {
                reject(error);
            });
        });
    }

    selectOrg(orgid: string, header: any, token: string) {
        let url = BIGDATA_CONSTANT.url.api + 'accounts/context/set-org?orgId=' + orgid + `&captcha=${token}`;
        var restClient = new RestClient(Methods.GET, url);
        // var user: UserModel  = this.getUserFromLocalStorage();
        // var headers = {
        //     token: user.token,
        //     api_key: user.apiKey
        // };

        return new Promise((resolve, reject) => {
            restClient.execute(header, {}).then((response: any) => {
                // BigDataConsole("Get Me", response);
                const user: UserModel = response.data;
                localStorage.setItem(BIGDATA_CONSTANT.store.key, JSON.stringify(response.data.result));
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }

    getLinkGaAuth() {
        let url = BIGDATA_CONSTANT.url.svc + 'int-ga/api/auth/GoogleAnalytics/access';
        var restClient = new RestClient(Methods.GET, url);
        // var user: UserModel  = this.getUserFromLocalStorage();
        var headers = {
            // token: user.token,
            // api_key: user.apiKey
        };

        return new Promise((resolve, reject) => {
            restClient.execute(headers, {}).then((response: any) => {
                // BigDataConsole("Get Me", response);
                const data: any = response.data;

                resolve(data)
            }, error => {
                reject(error);
            });
        });
    }
    getGAAuthCallback(code: string) {
        let url = BIGDATA_CONSTANT.url.svc + 'int-ga/api/auth/GoogleAnalytics/callbackOauth?code=' + code;
        var restClient = new RestClient(Methods.GET, url);
        // var user: UserModel  = this.getUserFromLocalStorage();
        var headers = {
            // token: user.token,
            // api_key: user.apiKey
        };

        return new Promise((resolve, reject) => {
            restClient.execute(headers, {}).then((response: any) => {
                // BigDataConsole("Get Me", response);
                const data: any = response.data;

                resolve(data)
            }, error => {
                reject(error);
            });
        });
    }

    // /api/oauth-access/{serviceType}/default/get
    getOAuthAccess() {
        let url = BIGDATA_CONSTANT.url.svc + 'int-ga/api/oauth-access/GoogleAnalytics/list';
        var restClient = new RestClient(Methods.GET, url);
        // var user: UserModel  = this.getUserFromLocalStorage();
        var headers = {
            // token: user.token,
            // api_key: user.apiKey
        };

        return new Promise((resolve, reject) => {
            restClient.execute(headers, {}).then((response: any) => {
                // BigDataConsole("Get Me", response);

                const data: any = response.data;

                resolve(data)
            }, error => {
                reject(error);
            });
        });
    }


    searchAccountGa(criteria: criteriaSeachGaAccount) {
        let url = BIGDATA_CONSTANT.url.svc + 'int-ga/api/GoogleAnalytics/meta/accounts/search';
        const body = criteria ? criteria : {}
        var restClient = new RestClient(Methods.POST, url);
        // var user: UserModel  = this.getUserFromLocalStorage();
        var headers = {
            // token: user.token,
            // api_key: user.apiKey
        };

        return new Promise((resolve, reject) => {
            restClient.execute(headers, body).then((response: any) => {
                // BigDataConsole("Get Me", response);
                const data: any = response.data;

                resolve(data)
            }, error => {
                reject(error);
            });
        });
    }

    searchAccountGaWebProperty(criteria: criteriaSeachGaAccount) {
        let url = BIGDATA_CONSTANT.url.svc + 'int-ga/api/GoogleAnalytics/meta/webproperties/search';
        const body = criteria ? criteria : {}
        var restClient = new RestClient(Methods.POST, url);
        // var user: UserModel  = this.getUserFromLocalStorage();
        var headers = {
            // token: user.token,
            // api_key: user.apiKey
        };

        return new Promise((resolve, reject) => {
            restClient.execute(headers, body).then((response: any) => {
                // BigDataConsole("Get Me", response);
                const data: any = response.data;

                resolve(data)
            }, error => {
                reject(error);
            });
        });
    }
    searchAccountGaWebPropertyProfile(criteria: criteriaSeachGaAccount) {
        let url = BIGDATA_CONSTANT.url.svc + 'int-ga/api/GoogleAnalytics/meta/profiles/search';
        const body = criteria ? criteria : {}
        var restClient = new RestClient(Methods.POST, url);
        // var user: UserModel  = this.getUserFromLocalStorage();
        var headers = {
            // token: user.token,
            // api_key: user.apiKey
        };

        return new Promise((resolve, reject) => {
            restClient.execute(headers, body).then((response: any) => {
                // BigDataConsole("Get Me", response);
                const data: any = response.data;

                resolve(data)
            }, error => {
                reject(error);
            });
        });
    }

    syncAllGa(oauthId?: string) {
        let url = BIGDATA_CONSTANT.url.svc + 'int-ga/api/GoogleAnalytics/meta/sync/all?mode=async&oauthId=' + oauthId;
        var restClient = new RestClient(Methods.GET, url);

        // var user: UserModel  = this.getUserFromLocalStorage();
        var headers = {
            // token: user.token,
            // api_key: user.apiKey
        };

        return new Promise((resolve, reject) => {
            restClient.execute(headers, {}).then((response: any) => {
                // BigDataConsole("Get Me", response);
                const data: any = response.data;

                resolve(data)
            }, error => {
                reject(error);
            });
        });
    }

    addGaTracking(tracking: any) {
        let url = BIGDATA_CONSTANT.url.svc + 'ggtrack/api/gatrackings/add';
        var restClient = new RestClient(Methods.POST, url);
        // var user: UserModel  = this.getUserFromLocalStorage();
        var headers = {
            // token: user.token,
            // api_key: user.apiKey
        };

        return new Promise((resolve, reject) => {
            restClient.execute(headers, tracking).then((response: any) => {

                const data: any = response.data;

                resolve(data)
            }, error => {
                reject(error);
            });
        });
    }

    getUserFromLocalStorage() {
        const data: any = localStorage.getItem(BIGDATA_CONSTANT.store.key);
        if (!data) {
            return new UserModel();
        }
        const user: UserModel = JSON.parse(data);
        return user
    }

    getUserFromToken() {
        let url = BIGDATA_CONSTANT.url.api + 'accounts/users/current';
        var restClient = new RestClient(Methods.GET, url);

        // var user: UserModel  = this.getUserFromLocalStorage();
        var headers = {
            // token: user.token,
            // api_key: user.apiKey
        };

        return new Promise((resolve, reject) => {
            restClient.execute(headers, {}).then((response: any) => {
                // BigDataConsole("Get Me", response);
                resolve(response.data)
            }, error => {
                reject(error);
            });
        });
    }

    saveColumns(values: any) {

        let url = BIGDATA_CONSTANT.url.api + 'preference/listing/config/save';
        var restClient = new RestClient(Methods.POST, url);

        var headers = {

        };

        return new Promise((resolve, reject) => {
            restClient.execute(headers, values).then((response: any) => {
                resolve(response.data)
            }, error => {
                reject(error);
            });
        });
    }
    getColumns(objectType: string) {

        let url = BIGDATA_CONSTANT.url.api + 'preference/listing/config/get?objectType=' + objectType;
        var restClient = new RestClient(Methods.GET, url);

        var headers = {

        };

        return new Promise((resolve, reject) => {
            restClient.execute(headers, {}).then((response: any) => {
                resolve(response.data)
            }, error => {
                reject(error);
            });
        });
    }

    getMessages(c: TypeCriteriaSearchNotifications) {
        let url = BIGDATA_CONSTANT.url.api + `messages/search?pageSize=${c.pageSize ? c.pageSize : 10}`
        let paramsUrl = new URLSearchParams();

        if (c && c.page) {
            const p = c.page - 1;
            paramsUrl.append('page', "" + p);
        }
        if (c && (c.page)) {
            url = url + "&" + paramsUrl.toString();
        }
        var restClient = new RestClient(Methods.POST, url);

        var headers = {};

        return new Promise((resolve, reject) => {
            restClient.execute(headers, c).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }

    markReadMessage(id: string) {

        let url = BIGDATA_CONSTANT.url.api + `messages/${id}/update/read`
        var restClient = new RestClient(Methods.POST, url);

        var headers = {

        };

        return new Promise((resolve, reject) => {
            restClient.execute(headers, {}).then((response: any) => {
                resolve(response.data)
            }, error => {
                reject(error);
            });
        });
    }

    getCriticalMessage() {

        let url = BIGDATA_CONSTANT.url.svc + `console/api/messages/search/_critical`
        var restClient = new RestClient(Methods.GET, url);

        var headers = {

        };

        return new Promise((resolve, reject) => {
            restClient.execute(headers, {}).then((response: any) => {
                resolve(response.data)
            }, error => {
                reject(error);
            });
        });
    }

    trackRecent(dto: any) {
        let url = BIGDATA_CONSTANT.url.account + 'api/quick-access/recent/track';
        var restClient = new RestClient(Methods.POST, url);
        return new Promise((resolve, reject) => {
            restClient.execute({}, dto).then(
                (response: any) => {
                    const data: any = response.data;
                    resolve(data);
                },
                error => {
                    reject(error);
                }
            );
        });
    }

    countTotalInviteByStatus(dto: any) {
        let url = BIGDATA_CONSTANT.url.api + 'accounts/users/current/invitation/join-orgs/pre/_count?status=Pending';
        var restClient = new RestClient(Methods.GET, url);
        return new Promise((resolve, reject) => {
            restClient.execute({}, dto).then(
                (response: any) => {
                    const data: any = response.data;
                    resolve(data);
                },
                error => {
                    reject(error);
                }
            );
        });
    }
}

export const UserDAL = new User()
