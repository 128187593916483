// import {  User } from 'o2o_layout/lib/common/model';
import { LinkOrg } from "../model/linkOrgs";
import { BIGDATA_CONSTANT } from '../../../../common/constant';
// import { UserDAL } from '../../../../dal/UserDAL';
import RestClient from '../../../../libs/api/RestClient';
import { Methods } from '../../../../libs/api/Methods';

export type typeCriteriaSearchLinkOrgs = {
    
        "freeText"?: string,
        "id"?: string,
        "indexFromTime"?: number,
        "indexToTime"?: number,
        "linkedOrgId"?: string,
        "o2oCdpOrgId"?: string,
        page?: number,
        order?: string,
        pageSize?: number

      
 }


class LinkOrgs {

    list(criterial: typeCriteriaSearchLinkOrgs) {

        let pageSize = criterial.pageSize ? criterial.pageSize : 10

        let url = BIGDATA_CONSTANT.url.api + `mngmnt/orgs/links/search?pageSize=${pageSize}`;

        let paramsUrl = new URLSearchParams();

        if (criterial && criterial.page) {
            const p = criterial.page - 1;
            paramsUrl.append('page', "" + p);
        }
        if (criterial && criterial.order) {
            paramsUrl.append('orderBy', criterial.order);
        }
        if (criterial && (criterial.page || criterial.order)) {
            url = url + "&" + paramsUrl.toString();
        }

        var restClient = new RestClient(Methods.POST, url);

        // var user: UserModel  = this.getUserFromLocalStorage();
        var headers = {
            // token: user.token,
            // api_key: user.apiKey
        };
        

        return new Promise((resolve, reject) => {
            restClient.execute(headers, criterial).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });

    }

    delete(id : string) {

        let url = BIGDATA_CONSTANT.url.api + 'mngmnt/orgs/links/'+id+'/delete';
        var restClient = new RestClient(Methods.POST, url);

        var headers = {

        };

        return new Promise((resolve, reject) => {
            restClient.execute(headers, id).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });


    }

    get(orgid : string) {

        let url = BIGDATA_CONSTANT.url.api + 'mngmnt/orgs/links/get?orgId='+orgid;
        var restClient = new RestClient(Methods.GET, url);
        var headers = {};

        return new Promise((resolve, reject) => {
            restClient.execute(headers, {}).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });


    }

    create(criterial: LinkOrg) {

        let url = BIGDATA_CONSTANT.url.api + 'mngmnt/orgs/links/create';
        var restClient = new RestClient(Methods.POST, url);

       // var user: UserModel  = this.getUserFromLocalStorage();
       var headers = {
        // token: user.token,
        // api_key: user.apiKey
    };

        return new Promise((resolve, reject) => {
            restClient.execute(headers, criterial).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, (error: any) => {
                reject(error);
            });
        });

    }

    testSearchLogs() {

        let url = BIGDATA_CONSTANT.url.svc + 'data-weblog/api/weblogs/search';
        var restClient = new RestClient(Methods.POST, url);

        // var user: UserModel  = this.getUserFromLocalStorage();
        var headers = {
            // token: user.token,
            // api_key: user.apiKey
        };
        
        var criterial = {
            tids: [
                "UA-151107057-2"
            ],
            orgIds: [
                "cf5dbce34bcbf11647f12f58197898e7"
            ],
            fromTime: 1575192746000,
            toTime: 1577439146000
        }

        return new Promise((resolve, reject) => {
            restClient.execute(headers, criterial).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });

    }


}

export const LinkOrgsDAL = new LinkOrgs()
