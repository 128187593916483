import { Methods, RestClient } from "o2o_layout/lib/common/rest";
import { BIGDATA_CONSTANT } from "../../../common/constant";
import { toQuery } from "../../../common/helper";
import { omit } from "lodash";

class DAL {
    search(criterial: any) {
        const query = toQuery({
            page: criterial.page || 0,
            pageSize: criterial.pageSize || 10
        })
        let url = BIGDATA_CONSTANT.url.api + `mngmnt/systems/variables/search?${query}`;
        var restClient = new RestClient(Methods.POST, url);
        var headers = {
        };
        return new Promise((resolve, reject) => {
            restClient.execute(headers, omit(criterial, ["page", "pageSize"])).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }
    add(body: any, otp_code: string) {
        let url = BIGDATA_CONSTANT.url.api + 'mngmnt/systems/variables/add';
        var restClient = new RestClient(Methods.POST, url);
        var headers = {
            otp_code: otp_code
        };
        return new Promise((resolve, reject) => {
            restClient.execute(headers, body).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }
    update(body: any, otp_code: string) {
        let url = BIGDATA_CONSTANT.url.api + 'mngmnt/systems/variables/update';
        var restClient = new RestClient(Methods.POST, url);
        var headers = {
            otp_code: otp_code
        };
        return new Promise((resolve, reject) => {
            restClient.execute(headers, body).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }
    getDetail(otp_code: string, id: string) {
        let url = BIGDATA_CONSTANT.url.api + `mngmnt/systems/variables/get?id=${encodeURIComponent(id)}`;
        var restClient = new RestClient(Methods.GET, url);
        var headers = {
            otp_code: otp_code
        };
        return new Promise((resolve, reject) => {
            restClient.execute(headers, {}).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }
}

export const SystemConfigurationDAL = new DAL()