import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { LoginContainer } from '../modules/login/LoginContainer';
import { AuthenRouterContainer } from './AuthenRouter';
import { OrganizationContainer } from '../modules/orgs/OrgsContainer';
import { ConsoleUsersContainer } from '../modules/users/ConsoleUsersContainer';
import { SelectCompanyContainer } from '../modules/company/SelectCompanyContainer';

import { EmptyCompanyContainer } from '../modules/company/create-company/EmptyCompanyContainer';
import { UserProfileContainer } from '../modules/users/profile/UserProfileContainer';
import { LinkOrgsContainer } from '../modules/orgs/link-orgs/LinkOrgsContainer';
import { UserInfoContainer } from '../modules/users/info/UserInfoContainer';
import { UserPwdContainer } from '../modules/users/pwd/UserPwdContainer';
import { UserCompanyContainer } from '../modules/users/company/UserCompanyContainer';
import { LogoutContainer } from '../modules/login/LogoutContainer';
import { UserTokenContainer } from '../modules/users/info/UserTokenContainer';
import { UserApiContainer } from '../modules/users/info/UserApiContainer';
import { PermissionGroupContainer } from '../modules/users/permisions/PerminssionGroupContainer';
import { TwoFaContainer } from '../modules/users/info/TwoFaContainer';
import { NotificationContainer } from '../modules/users/info/NotificationContainer';
import { Component404 } from '../modules/404/404Compopnent';
import { ConsoleSPUsersContainer } from '../modules/users/ConsoleSPUsersContainer';
import { InviteUserToOrgContainer } from '../modules/users/invite/InviteUserToOrgContainer';
import { SystemSettingContainer } from '../modules/system-setting/SystemSettingContainer';
import { ExportsHistoryContainer } from '../modules/users/exports-history/ExportsHistoryContainer';
import { SuperPermissionContainer } from '../modules/users/super-permission/SuperPermissionContainer';
import { InternalPermissionContainer } from '../modules/users/internal_permission/InternalPermissionContainer';
import { ConsoleUsersActivityContainer } from '../modules/users/activity/ConsoleUsersActivityContainer';
import { ChangePasswordContainer } from '../modules/users/info/ChangePasswordContainer';
import { LoginActivityContainer } from '../modules/users/login-activity/LoginActivityContainer';

class MainRouter extends React.Component {

  render() {
    return (
      <Switch>
        <Route exact path="/login" component={LoginContainer} />
        <Route exact path="/logout" component={LogoutContainer} />
        <AuthenRouterContainer exact path="/dashboard" component={UserInfoContainer} failComponent={LoginContainer} />
        <AuthenRouterContainer exact path="/" component={UserInfoContainer} failComponent={LoginContainer} />
        <AuthenRouterContainer exact path="/my-account-info" component={UserInfoContainer} failComponent={LoginContainer} />
        <AuthenRouterContainer exact path="/notifications" component={NotificationContainer} failComponent={LoginContainer} />
        <AuthenRouterContainer exact path="/security/2fa-security" component={TwoFaContainer} failComponent={LoginContainer} />
        <AuthenRouterContainer exact path="/security/change-password" component={ChangePasswordContainer} failComponent={LoginContainer} />
        <AuthenRouterContainer exact path="/security/my-account-tokens" component={UserTokenContainer} failComponent={LoginContainer} />
        <AuthenRouterContainer exact path="/security/my-account-api" component={UserApiContainer} failComponent={LoginContainer} />
        <AuthenRouterContainer exact path="/my-account-change-pwd" component={UserPwdContainer} failComponent={LoginContainer} />
        <AuthenRouterContainer exact path="/organization" component={UserCompanyContainer} failComponent={LoginContainer} />
        <AuthenRouterContainer exact path="/activity/invitation" component={InviteUserToOrgContainer} failComponent={LoginContainer} />
        <AuthenRouterContainer exact path="/activity/" component={InviteUserToOrgContainer} failComponent={LoginContainer} />
        <AuthenRouterContainer exact path="/activity/exports-history" component={ExportsHistoryContainer} failComponent={LoginContainer} />
        <AuthenRouterContainer exact path="/companies/empty" component={EmptyCompanyContainer} failComponent={LoginContainer} />
        <AuthenRouterContainer exact path="/companies/select" component={SelectCompanyContainer} failComponent={LoginContainer} />
        <AuthenRouterContainer exact path="/console-organizations" component={OrganizationContainer} failComponent={LoginContainer} />
        <AuthenRouterContainer exact path="/console-users" component={ConsoleUsersContainer} failComponent={LoginContainer} />
        <AuthenRouterContainer exact path="/super-admin-console-users" component={ConsoleSPUsersContainer} failComponent={LoginContainer} />
        <AuthenRouterContainer exact path="/console-link-orgs" component={LinkOrgsContainer} failComponent={LoginContainer} />
        <AuthenRouterContainer exact path="/console-system-setting" component={SystemSettingContainer} failComponent={LoginContainer} />
        <AuthenRouterContainer exact path="/console-activity" component={ConsoleUsersActivityContainer} failComponent={LoginContainer} />
        <AuthenRouterContainer exact path="/permission-group" component={PermissionGroupContainer} failComponent={LoginContainer} />
        <AuthenRouterContainer exact path="/profile" component={UserProfileContainer} failComponent={LoginContainer} />
        <AuthenRouterContainer exact path="/console-permission" component={SuperPermissionContainer} failComponent={LoginContainer} />
        <AuthenRouterContainer exact path="/internal-permission" component={InternalPermissionContainer} failComponent={LoginContainer} />
        <AuthenRouterContainer exact path="/activity/login-history" component={LoginActivityContainer} failComponent={LoginContainer} />
        <Route path="*" component={Component404}>
        </Route>
      </Switch>

    );
  }

}

export default MainRouter;
