import { Methods, RestClient } from "o2o_layout/lib/common/rest";
import { BIGDATA_CONSTANT } from "../../../common/constant";
import { toQuery } from "../../../common/helper";
import { isNil, omitBy } from "lodash";
import { TypePayloadAddEditCronjob } from "./model";

class DAL {
    search(body: any) {
        const q = toQuery(omitBy({
            page: body.page || 0,
            pageSize: body.pageSize || 10,
        }, isNil))
        let url = BIGDATA_CONSTANT.url.api + `mngmnt/systems/cronjob-configs/search?${q}`;
        var restClient = new RestClient(Methods.POST, url);
        var headers = {
        };
        return new Promise((resolve, reject) => {
            restClient.execute(headers, body).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }
    add(body: TypePayloadAddEditCronjob) {
        let url = BIGDATA_CONSTANT.url.api + `mngmnt/systems/cronjob-configs/add`;
        var restClient = new RestClient(Methods.POST, url);
        var headers = {
        };
        return new Promise((resolve, reject) => {
            restClient.execute(headers, body).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }
    update(configId: string, body: TypePayloadAddEditCronjob) {
        let url = BIGDATA_CONSTANT.url.api + `mngmnt/systems/cronjob-configs/${configId}/update`;
        var restClient = new RestClient(Methods.POST, url);
        var headers = {
        };
        return new Promise((resolve, reject) => {
            restClient.execute(headers, body).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }
    delete(configId: string) {
        let url = BIGDATA_CONSTANT.url.api + `mngmnt/systems/cronjob-configs/${configId}/delete`;
        var restClient = new RestClient(Methods.POST, url);
        var headers = {
        };
        return new Promise((resolve, reject) => {
            restClient.execute(headers, {}).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }
    historySearch(body: any) {
        const q = toQuery(omitBy({
            page: body.page || 0,
            pageSize: body.pageSize || 10,
        }, isNil))
        let url = BIGDATA_CONSTANT.url.api + `mngmnt/systems/cronjob-histories/search?${q}`;
        var restClient = new RestClient(Methods.POST, url);
        var headers = {
        };
        return new Promise((resolve, reject) => {
            restClient.execute(headers, body).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }
}

export const CronjobDAL = new DAL()