import { TypeOrganization } from 'o2o_layout/lib/common/model';
import { IResponseBigDataVN, TypeResultsBigData } from './../../../../common/IResponseBigData';
export type TypeAccountLogs = {
    action?: string,
    actionAt?: number,
    actionBy?: string,
    ctxObject?: { [key: string]: any },
    id?: string,
    object?: string,
    orgId?: string,
    reason?: string,
    userId?: string
}



export class AccountLogs implements IResponseBigDataVN<TypeAccountLogs>{
    success: boolean;
    results: TypeResultsBigData<TypeAccountLogs>;
    orgs: {[key:string]: TypeOrganization}
    constructor() {
        this.orgs = {}
        this.success = false
        this.results = {
            content: [],
            timeTaken: 0,
            currentTime: 0,
            page: {
                pageSize: 0,
                currentPage: 0,
                totalRecords: 0,
                totalPages: 0
            },
            extras: null
        }
    }
}

