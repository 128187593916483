import { Breadcrumb, Button, Col, Descriptions, Icon, Layout, Menu, Popconfirm, Row, Switch, Tag, Typography, notification } from 'antd';
import * as React from 'react';
import { Link } from 'react-router-dom';
import CommonTypeProps from '../../../common/CommonTypeProps';
import { LinkO2O } from '../../../common/LinkO2O';
import { BIGDATA_CONSTANT } from '../../../common/constant';
import { getCurrentURL, getPrimaryColor } from '../../../common/helper';
import { DashboardPageLayout } from '../../../layout/dashboard/DashboardPageLayout';
import { CommonResponse } from '../../../redux/model';
import { CommonNotificationSaveError, CommonNotificationUpdateSuccessfully } from '../../../shares/CommonNotification';
import { MetaHeader } from '../../../shares/MetaHeader';
import { UserTokenDAL } from './dal/UserTokenDAL';
import { get } from 'lodash';

const mask = (string: string, localPartIndex: number, domainIndex: number) => {
    const length = string.length - 1

    const arr = string.split('').map((character, i) => {
        if (i < localPartIndex || length - domainIndex < i) return character
        return '*'
    })

    return arr.join('')
}


const maskingKey = (input: string | undefined) => {


    if (!input) {
        return "--/--"
    } else {
        let output = input
        const replacement = mask(input, 0, 0)
        output = output.split(input).join(replacement)

        return output
    }


}


interface Props extends CommonTypeProps {
    changeApiKey: any
    getMe: any
}

export class UserApiComponent extends React.Component<Props, any> {

    state = {
    };


    componentDidMount = () => {
        this.props.updateMetaTitle({ title: BIGDATA_CONSTANT.trackTitle.AccountAPI })
        this.timer = setTimeout(() => {
            this.props.updateMeta({
                name: BIGDATA_CONSTANT.trackTitle.AccountAPI,
                title: BIGDATA_CONSTANT.trackTitle.AccountAPI,
                app: "ACCOUNT",
                url: getCurrentURL(this.props.location)
            })
            clearTimeout(this.timer)
        }, 5000)
    }
    timer: any

    componentWillUnmount() {
        clearTimeout(this.timer)
    }


    renderBreadCrumb = () => {
        return (
            <Row type="flex" justify="space-between" align="middle">
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/">Home</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <LinkO2O {...this.props} to={{
                            pathname: "/security/2fa-security"
                        }}>Security</LinkO2O>
                    </Breadcrumb.Item>

                    <Breadcrumb.Item>Account API</Breadcrumb.Item>
                </Breadcrumb>

            </Row>

        )
    }

    generateApiKey = () => {
        this.props.setGlobalLoading(true)
        this.props.changeApiKey()
            .then(() => {

                notification.success({
                    message: BIGDATA_CONSTANT.message.default.title.success,
                    description: "Change Api Key success, please wait to redirect to Login page!",
                    icon: <Icon type="check-circle" style={{ color: getPrimaryColor(this.props.uiSetting) }} />
                })
                setTimeout(() => {
                    this.props.setGlobalLoading(false)
                    this.props.history.replace("/login")
                }, 2000);

            })
    }

    onChangeStatusApiAccess = async () => {
        // @ts-ignore
        const result = this.props.commonActionFromDALV2(UserTokenDAL.updateStatusApiAccess, !this.props.user.disabledApiAccess)
        try {
            const res: CommonResponse = await result
            if (res.success) {
                setTimeout(() => {
                    this.props.getMe()
                }, 500);
                CommonNotificationUpdateSuccessfully()
            } else {
                CommonNotificationSaveError(res.message)
            }

        } catch (error) {
            CommonNotificationSaveError(get(error, 'message'))
        }
    }


    renderTheme() {
        return (
            <DashboardPageLayout  {...this.props} selectedLeftNav="my-security" selectedSubMenu="my-account" >
                <div className="org-page">
                    <MetaHeader title={this.props.ui.meta.title} />

                    <div className="contentLayout">
                        {this.renderBreadCrumb()}
                        <br />
                        <div className="containerInner">
                            <Layout>
                                <Layout.Sider width={200} style={{ background: '#fff' }}>
                                    <Menu
                                        mode="inline"
                                        defaultSelectedKeys={['api']}

                                        style={{ height: '100%' }}
                                    >


                                        <Menu.Item key="sercurity">
                                            <LinkO2O {...this.props} to={{ pathname: "/security/2fa-security" }}>Two-Factor Auth</LinkO2O>
                                        </Menu.Item>
                                        <Menu.Item key="change-password">
                                            <LinkO2O {...this.props} to={{ pathname: "/security/change-password" }}>Change Password</LinkO2O>
                                        </Menu.Item>
                                        <Menu.Item key="api">
                                            <LinkO2O {...this.props} to={{ pathname: "/security/my-account-api" }}>API Account</LinkO2O>
                                        </Menu.Item>
                                        <Menu.Item key="tokens"><LinkO2O {...this.props} to={{ pathname: "/security/my-account-tokens" }}>Token</LinkO2O></Menu.Item>


                                    </Menu>

                                </Layout.Sider>
                                <Layout.Content style={{ paddingLeft: 20 }}>
                                    <Typography.Title level={3}>API Account Information</Typography.Title>
                                    <Row>
                                        <Col xs={24}>

                                            <Descriptions title="" column={1}>
                                                <Descriptions.Item>
                                                    <Row gutter={[5, 5]} type='flex' align={'middle'} >
                                                        <Col xs={24}>
                                                            <Row type='flex' align={'middle'}>
                                                                <Popconfirm
                                                                    onConfirm={this.onChangeStatusApiAccess}
                                                                    title={BIGDATA_CONSTANT.message.default.confirmText}>
                                                                    <Switch
                                                                        // @ts-ignore
                                                                        checked={!this.props.user.disabledApiAccess}
                                                                    />
                                                                </Popconfirm>
                                                                <span style={{ marginLeft: 8 }}>
                                                                    Enable API Access
                                                                </span>
                                                            </Row>
                                                        </Col>
                                                        <Col xs={24}>
                                                            {/*@ts-ignore */}
                                                            <i style={{ color: !this.props.user.disabledApiAccess ? 'green' : "red" }}>
                                                                {/*@ts-ignore */}
                                                                {!this.props.user.disabledApiAccess ?
                                                                    '** Your account is able to access from API'
                                                                    :
                                                                    '** Your account is NOT able to access from API'
                                                                }
                                                            </i>
                                                        </Col>
                                                    </Row>
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Endpoint">
                                                    <Typography.Text type="secondary">
                                                        https://account.mydatalakes.com/api/accounts/authen <Tag color={getPrimaryColor(this.props.uiSetting)}>[POST]</Tag>
                                                    </Typography.Text>
                                                </Descriptions.Item>
                                                <Descriptions.Item label="">

                                                    <Descriptions bordered size="small" column={1}>
                                                        <Descriptions.Item label="username">{this.props.user.id}</Descriptions.Item>
                                                        <Descriptions.Item label="apiKey">
                                                            <Typography.Paragraph copyable={{ text: this.props.user.apiKey }}>{maskingKey(this.props.user.apiKey)}</Typography.Paragraph>
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label="cdpGKey">
                                                            <Typography.Paragraph copyable={{ text: this.props.user.orgCtx ? this.props.user.orgCtx.orgId : "" }}>{maskingKey(this.props.user.orgCtx ? this.props.user.orgCtx.orgId : "")}</Typography.Paragraph>
                                                        </Descriptions.Item>
                                                    </Descriptions>

                                                </Descriptions.Item>
                                                <Descriptions.Item label="">
                                                    <Typography.Paragraph copyable={{ text: this.props.user.apiKey }}><b>API KEY</b>  <Tag color={getPrimaryColor(this.props.uiSetting)}>{maskingKey(this.props.user.apiKey)} </Tag> </Typography.Paragraph>

                                                </Descriptions.Item>
                                                <Descriptions.Item label="">

                                                    <Popconfirm title="All tokens will be invalidated (including API Tokens)?" onConfirm={this.generateApiKey}>
                                                        <Button type="primary">Generate New Key</Button>
                                                    </Popconfirm>
                                                </Descriptions.Item>
                                            </Descriptions>
                                        </Col>
                                    </Row>

                                </Layout.Content>
                            </Layout>

                        </div>
                    </div>

                </div>
            </DashboardPageLayout>
        )
    }

    render() {
        return (
            this.renderTheme()
        );
    }
}
