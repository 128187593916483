import { Button, Col, Drawer, Form, Icon, Row, Select, notification } from 'antd'
import { FormComponentProps } from 'antd/lib/form';
import React from 'react'
import SuggestionOrgs from './SuggestionOrgs';
import { useAsyncFn } from 'react-use';
import { ConsoleUsersDAL } from '../ConsoleUserDAL';
import { TypeConsoleUser } from 'o2o_layout/lib/common/model';
import { BIGDATA_CONSTANT } from '../../../common/constant';
import _ from 'lodash';
import { O2OFormItem } from '../../../shares/O2OFormItem';
import { getPrimaryColor } from '../../../common/helper';
import { useSelector } from '../../../redux/store';
import { IState } from '../../../redux/reducer';

interface Iprops extends FormComponentProps {
    visible: boolean;
    onClose: () => void;
    commonActionFromDAL2: any
    user?: TypeConsoleUser
    assignSuccess: any
    orgIds?: string[]
}
const DrawerAssignUser = Form.create<Iprops>()((props: Iprops) => {
    const [stateAssignUser, doFetchAssignUser] = useAsyncFn(async (value: any) => {
        const response = await props.commonActionFromDAL2(ConsoleUsersDAL.assignUserToOrg, props.user ? props.user.id : "", value.org, value.role)
        const result = await response
        return result
    }, [props.user]);

    const uiSetting = useSelector((state: IState) => state.uiSetting)

    return (
        <Drawer
            title="ASSIGN ORG"
            width={400}
            visible={props.visible}
            onClose={() => {
                props.onClose()
                props.form.resetFields()
            }}
        >
            <Form colon={false} hideRequiredMark={true} >
                <Row type="flex" gutter={[5, 5]}>
                    <Col xs={24}>
                        <SuggestionOrgs
                            commonActionFromDAL2={props.commonActionFromDAL2}
                            form={props.form} keyField="org"
                            lable="Organization"
                            visible={props.visible}
                            ids={props.orgIds}
                        />
                    </Col>
                    <Col xs={24}>
                        <O2OFormItem label="Roles">
                            {props.form.getFieldDecorator("role", {
                                rules: [{
                                    required: true,
                                    message: "Required"
                                }]
                            })(
                                <Select mode="multiple" style={{ width: '100%' }} placeholder="Select Roles">
                                    <Select.Option key="ADMIN">Admin</Select.Option>
                                    <Select.Option key="API">Api</Select.Option>
                                    <Select.Option key="USER">User</Select.Option>
                                    <Select.Option key="OPER_ADMIN">Operator Admin</Select.Option>
                                </Select>
                            )}
                        </O2OFormItem>
                    </Col>
                </Row>
                <div
                    style={{
                        position: 'absolute',
                        bottom: 0,
                        width: '100%',
                        borderTop: '1px solid #e8e8e8',
                        padding: '10px 16px',
                        textAlign: 'right',
                        left: 0,
                        background: '#fff',
                        borderRadius: '0 0 4px 4px',
                    }}
                >
                    <Button style={{ marginRight: 5 }} onClick={() => {
                        props.onClose()
                        props.form.resetFields()
                    }}>
                        Discard
                    </Button>
                    <Button type="primary" loading={stateAssignUser.loading} onClick={async () => {
                        props.form.validateFieldsAndScroll(async (e: any, data: any) => {
                            if (e) return
                            const result = await doFetchAssignUser(data)
                            if (result.success) {
                                notification.success({
                                    message: BIGDATA_CONSTANT.message.default.title.success,
                                    description: "Assigned successfully.",
                                    icon: <Icon type="check-circle" style={{ color: getPrimaryColor(uiSetting) }} />
                                })
                                props.assignSuccess()
                                props.form.resetFields()
                                props.onClose()
                            } else {
                                notification.error({
                                    message: BIGDATA_CONSTANT.message.default.title.error,
                                    description: _.get(result, "message", BIGDATA_CONSTANT.message.notification.fail.update)
                                })
                            }
                        })
                    }}>Assign</Button>
                </div>

            </Form>

        </Drawer>
    )
})

export default DrawerAssignUser