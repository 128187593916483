import { Methods, RestClient } from "o2o_layout/lib/common/rest";
import { BIGDATA_CONSTANT } from "../../../../common/constant"
import { toQuery } from "../../../../common/helper";
import { isNil, omitBy } from "lodash";

class DAL {
    search(body: any) {
        const query = toQuery(omitBy({
            page: body.page || 0,
            pageSize: body.pageSize || 10,
        }, isNil))

        let url = BIGDATA_CONSTANT.url.api + `mngmnt-client/quick-access/recent/search?${query}&orderBy=createdAt:DESC`  ;
        var restClient = new RestClient(Methods.POST, url);

        return new Promise((resolve, reject) => {
            restClient.execute({}, body).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }
    stats(body: any){
        let url = BIGDATA_CONSTANT.url.api + `mngmnt-client/quick-access/recent/stats/overview`  ;
        var restClient = new RestClient(Methods.POST, url);

        return new Promise((resolve, reject) => {
            restClient.execute({}, body).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }
} 

export const AccessLogsDAL = new DAL()