import { Popconfirm, Switch } from 'antd';
import _ from 'lodash';
import * as React from 'react';

export const SwitchButtonConfirm = (props: {
    showText: boolean,
    status: string,
    callApi: any,
    textEnabled?: boolean
    type?: string
    valueChecked?: string
    valueUnchecked?: string
}) => {
    const { valueChecked = "enabled", valueUnchecked = "disabled" } = props


    const [loading, setLoading] = React.useState(false)
    const customText = props.type === "customText" ? "the account?" : "this record?"
    const statusLower = props.status.toLowerCase()

    const renderTitle = () => {
        if (valueChecked === "enabled") {
            return `Are you sure to ${statusLower === "enabled" ? "disabled" : "enabled"} ${customText}`
        } else {
            return `Are you sure to ${statusLower === "active" ? "deactivate" : "activate"} ${customText}`
        }
    }

    const renderOkText = () => {
        if (valueChecked === "enabled") {
            return statusLower === "enabled" ? "Disabled" : "Enabled"
        } else {
            return statusLower === "active" ? "Inactive" : "Active"
        }
    }
    return (
        <Popconfirm title={renderTitle()}
            okText={renderOkText()}
            onConfirm={() => {
                props.callApi(setLoading)
            }}
        >
            <Switch
                loading={loading}
                style={{ cursor: "default" }}
                checked={props.status.toLocaleLowerCase() === valueChecked ? true : false}
                unCheckedChildren={props.showText ? _.capitalize(valueUnchecked) : ""}
                checkedChildren={props.showText ? _.capitalize(valueChecked) : ""}
            />
        </Popconfirm >

    )
}