import { Alert, Avatar, Button, Spin, message, notification } from "antd"
import Upload, { UploadChangeParam } from "antd/lib/upload"
import _ from "lodash"
import React, { useEffect, useState } from "react"
import { useAsyncFn } from "react-use"
import { CompanyDAL } from "./DAL"
import { Methods } from "o2o_layout/lib/common/rest"
import { BIGDATA_CONSTANT } from "../../../common/constant"
import { CommonNotificationSaveError } from "../../../shares/CommonNotification"

interface Props {
    org: any
    onSuccess: any
    commonDALV2: any
}


function getBase64(file: File, callback: any) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        callback(reader.result)
    };
    reader.onerror = function (error) {
    };
}


export const OrgAvatar = (props: Props) => {

    //@ts-ignore
    const [file, setFile] = useState(undefined as any | undefined)
    const [loadingImg, setLoadingImg] = useState(false)
    const [src, setSrc] = useState(undefined as any)
    //@ts-ignore
    const [fileList, setFileList] = useState([] as any[])
    const [errorSize, setErrorSize] = useState(undefined as undefined | string)

    useEffect(() => {
        if (props.org) {
            setSrc(_.get(props.org, "imageLink", undefined))
        } else {
            setFile(undefined)
            setSrc(undefined)
            setFileList([])
            setErrorSize(undefined)
        }

    }, [props.org])

    const beforeUpload = () => {
        return false;
    }

    const handleOnChange = (info: UploadChangeParam<any>) => {

        let { fileList } = info
        let { file } = info
        setLoadingImg(true)
        getBase64(file, (src: any) => {
            setSrc(src)
            setLoadingImg(false)
        })

        const isImageType = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/svg' || file.type === 'image/svg+xml';
        if (!isImageType) {
            message.warn('You can only upload JPG/PNG/PNG/SVG file!');
        }
        const isLt1M = file.size / 1024 / 1024 < 2;
        if (!isLt1M) {
            setErrorSize("File size must be less than 2MB!")
        } else {
            setErrorSize("")
        }
        if (isImageType && isLt1M) {
            setFile(file)
            setFileList([fileList[fileList.length - 1]])
        }
        return false
    };
    const [stateAvatar, doFetchAvatar] = useAsyncFn(async () => {

        const response = await props.commonDALV2(CompanyDAL.updateLogoOrg, file, Methods.POST)
        const result = await response
        return result
    }, [file]);

    return (
        <div className="avatar-wrapper">
            <Spin spinning={loadingImg}>
                <Avatar shape="square" size={150} src={src} />
            </Spin>
            <div>

                {file ? (<div style={{marginTop: 5}}>
                    <Button loading={stateAvatar.loading} icon="upload" size="small" type="link" onClick={async () => {
                        try {
                            const data = await doFetchAvatar()
                            if (data.success) {
                                notification.success({
                                    message: BIGDATA_CONSTANT.message.default.title.success,
                                    description: BIGDATA_CONSTANT.message.notification.success.saved
                                })

                                setFile(undefined)
                                setSrc(undefined)
                                setFileList([])
                                setErrorSize(undefined)

                                props.onSuccess()

                            } else {

                                notification.error({
                                    message: BIGDATA_CONSTANT.message.default.title.error,
                                    description: _.get(data, "data.message", "Something went wrong!")
                                })
                            }
                        } catch (error) {

                            CommonNotificationSaveError(_.get(error, "data.message", "Something went wrong!"))
                        }
                    }}>Save Change</Button>
                    <Button icon="delete" size="small" type="link" onClick={() => {
                        setFile(undefined)
                        setFileList([])
                        setErrorSize(undefined)
                        setSrc(_.get(props.org, "result.imageLink", undefined))
                    }} />
                </div>) : <div style={{marginTop: 5}}>
                    <Upload type="select"
                        accept="image/*"
                        listType="picture"
                        beforeUpload={beforeUpload}
                        onChange={(info) => handleOnChange(info)}
                        fileList={[]}
                        onRemove={
                            () => {
                                setFile(undefined)
                                setFileList([])
                                return false
                            }} >
                        <Button style={{
                            color: "#797979"
                        }} size="small" type="link" icon="edit">Change</Button>

                    </Upload>
                    <br />
                    {errorSize && <Alert type="warning" message={errorSize} />}
                </div>}



            </div>
        </div>

    )
}