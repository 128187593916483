import { Button, Col, DatePicker, Input, Row, Select, Spin } from "antd"
import Form, { FormComponentProps } from "antd/lib/form"
import moment from "moment"
import React from "react"
import { O2OFormItem } from "../../O2OFormItem"
import _ from "lodash"

interface IFormFilterLogsTicketProps extends FormComponentProps {
    type: 'open' | 'close' | string
    onSearch: (values: any) => void
    visible: boolean
    commonActionFromDALV2: any
    loadingTopic: boolean
    resTopic: { success: boolean, results: { [key: string]: string } }
}

const FormFilterLogs = (props: IFormFilterLogsTicketProps) => {
    const [visibleMoreFilter, setVisibleMoreFilter] = React.useState<boolean>(false)
    const { getFieldDecorator, validateFields } = props.form

    React.useEffect(() => {
        if (!props.visible || props.type) {
            props.form.resetFields()
            setVisibleMoreFilter(false)
        }
    }, [props.visible, props.type])

    const onSearch = () => {
        validateFields(async (error: any, values: any) => {
            if (!error) {
                let time: [moment.Moment, moment.Moment] = values.time
                let fromTime = 0
                let toTime = 0
                if (time && _.isArray(time) && time.length === 2) {
                    fromTime = time[0].toDate().getTime()
                    toTime = time[1].toDate().getTime()
                }
                const payload = { ..._.omit(values, "time"), ...{ fromTime: fromTime, toTime: toTime } }
                props.onSearch(payload);
            }
        })
    }

    const listTopic = props.resTopic && props.resTopic.success && props.resTopic.results ? Object.entries(props.resTopic.results).map(([id, description]) => { return { id, description } }) : []

    return (
        <Form layout="horizontal" title="Search" colon={false}>
            <Row type='flex' gutter={[10, 10]}>
                <Col xs={5} style={{ flexGrow: 1 }}>
                    <O2OFormItem label='Tick Info'>
                        {getFieldDecorator('freeText', {
                            initialValue: undefined
                        })(
                            <Input.Search placeholder='Subject, description' onPressEnter={onSearch} allowClear />
                        )}
                    </O2OFormItem>
                </Col>

                <Col xs={5}>
                    <Spin spinning={props.loadingTopic}>
                        <O2OFormItem help='' label={'Topics'}>
                            {getFieldDecorator('topics', {
                                rules: [{
                                    required: false
                                }],
                                initialValue: undefined
                            })(
                                <Select
                                    mode={'multiple'} showSearch showArrow
                                    placeholder={'Select(s)'}
                                    maxTagTextLength={8}
                                    maxTagCount={1}
                                >
                                    {listTopic && listTopic.length && listTopic.map((item) => {
                                        return (
                                            <Select.Option key={item.id} value={item.id}>
                                                {item.description}
                                            </Select.Option>
                                        )
                                    })}
                                </Select>
                            )}
                        </O2OFormItem>
                    </Spin>
                </Col>

                <Col xs={4}>
                    <O2OFormItem label='Ticket Ids'>
                        {getFieldDecorator('ticketIds')(
                            <Select allowClear maxTagCount={1} maxTagTextLength={8} mode="tags" placeholder="Enter"></Select>
                        )}
                    </O2OFormItem>
                </Col>

                <Col xs={4}>
                    <O2OFormItem label='Ticket No.'>
                        {getFieldDecorator('ticketNos')(
                            <Select allowClear maxTagCount={1} maxTagTextLength={8} mode="tags" placeholder="Enter"></Select>
                        )}
                    </O2OFormItem>
                </Col>

                <Col>
                    <O2OFormItem label={<></>}>
                        <Row type='flex' align='middle'>
                            <Button style={{ marginRight: 10 }} onClick={onSearch} type="primary" icon='search'>Search</Button>
                            <Button
                                type={visibleMoreFilter ? "primary" : "default"} icon='caret-down'
                                onClick={() => setVisibleMoreFilter(!visibleMoreFilter)}>
                            </Button>
                        </Row>
                    </O2OFormItem>
                </Col>

                {
                    visibleMoreFilter &&
                    <>
                        <Col xs={7}>
                            <O2OFormItem label={'Submit Time'}>
                                {getFieldDecorator("time", {

                                })(<DatePicker.RangePicker style={{ width: "100%" }} showTime={{ defaultValue: [moment("00:00:00", "HH:mm:ss"), moment("23:59:59", "HH:mm:ss")] }} />)}
                            </O2OFormItem>
                        </Col>


                        {props.type === 'open' ? (
                            <Col xs={4}>
                                <O2OFormItem label='Status'>
                                    {getFieldDecorator('status')(
                                        <Select mode='multiple' maxTagCount={1} showArrow showSearch placeholder="Select(s)">
                                            <Select.Option value={'new'}>New</Select.Option>
                                            <Select.Option value={'open'}>Open</Select.Option>
                                            <Select.Option value={'pending'}>Pending</Select.Option>
                                            <Select.Option value={'solved'}>Solved</Select.Option>
                                            <Select.Option value={'closed'}>Closed</Select.Option>
                                        </Select>
                                    )}
                                </O2OFormItem>
                            </Col>
                        ) : null}
                    </>
                }
            </Row>
        </Form>
    )
}

export const FilterTicketComponents = Form.create<IFormFilterLogsTicketProps>()(FormFilterLogs)