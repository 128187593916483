import { BIGDATA_CONSTANT } from "../../common/constant";
import RestClient from "../../libs/api/RestClient";
import { Methods } from "../../libs/api/Methods";
import { UserDAL } from "../../dal/UserDAL";
import { TypeConsoleUser, User } from "o2o_layout/lib/common/model";


export type typeCriteriaSearchAccount = {
    email: string,
    orgIds: string[],
    status: string[],
    roles: string[],
    freeText: string,
    page: number,
    order: string,
    pageSize?: number
}

export type typeCriteriaFbEvent = {
    objectIds?: string[],
    events?: any[],
    eventFields?: any[],
    eventItems?: any[],
    eventItemsExclude?: any[],
    fromTime?: number,
    toTime?: number,
    freeText?: string,
    page?: number,
    order?: string,
}


class ConsoleUsers {

    list(criterial?: typeCriteriaSearchAccount, isSPA?: boolean) {
        let { pageSize = 10 } = criterial ? criterial : { pageSize: 10 }
        let url = !isSPA ? BIGDATA_CONSTANT.url.api + `mngmnt-client/accounts/search?pageSize=${pageSize}` : BIGDATA_CONSTANT.url.api + `mngmnt/accounts/search?pageSize=${pageSize}`

        let paramsUrl = new URLSearchParams();

        if (criterial && criterial.page) {
            const p = criterial.page - 1;
            paramsUrl.append('page', "" + p);
        }
        if (criterial && criterial.order) {
            paramsUrl.append('orderBy', criterial.order);
        }
        if (criterial && (criterial.page || criterial.order)) {
            url = url + "&" + paramsUrl.toString();
        }

        var restClient = new RestClient(Methods.POST, url);

        // var user: UserModel  = this.getUserFromLocalStorage();
        var headers = {
            // token: user.token,
            // api_key: user.apiKey
        };
        const body = criterial ? criterial : {}

        return new Promise((resolve, reject) => {
            restClient.execute(headers, body).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });

    }
    create(criterial: TypeConsoleUser, isSPA?: boolean) {

        let url = !isSPA ? BIGDATA_CONSTANT.url.api + 'mngmnt-client/accounts/add' : BIGDATA_CONSTANT.url.api + 'mngmnt/accounts/add';
        var restClient = new RestClient(Methods.POST, url);

        // var user: UserModel  = this.getUserFromLocalStorage();
        var headers = {
            // token: user.token,
            // api_key: user.apiKey
        };
        criterial.status = "active"

        return new Promise((resolve, reject) => {
            restClient.execute(headers, criterial).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });

    }
    update(criteria: TypeConsoleUser, isSPA?: boolean, isUser?: boolean) {

        let url = !isSPA ? BIGDATA_CONSTANT.url.api + 'mngmnt-client/accounts/update/info?id=' + criteria.id : BIGDATA_CONSTANT.url.api + 'mngmnt/accounts/update/info?id=' + criteria.id;

        if (isUser) {
            url = BIGDATA_CONSTANT.url.api + 'accounts/users/current/update/info'
        }

        var restClient = new RestClient(Methods.POST, url);

        var user: User = UserDAL.getUserFromLocalStorage();

        var headers = {
            token: user.token,
            api_key: user.apiKey
        };

        return new Promise((resolve, reject) => {
            restClient.execute(headers, criteria).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });

    }
    updateRole(email: string, roles: string[], isSPA?: boolean, orgId?: string) {

        let url = !isSPA ? BIGDATA_CONSTANT.url.api + 'mngmnt-client/accounts/roles/update' : BIGDATA_CONSTANT.url.api + 'mngmnt/accounts/roles/update'
        var restClient = new RestClient(Methods.POST, url);

        // var user: UserModel  = this.getUserFromLocalStorage();
        var headers = {
            // token: user.token,
            // api_key: user.apiKey
        };
        var criteria = {
            "email": email,
            "roles": roles
        }

        if (isSPA) {
            criteria["orgId"] = orgId
        }

        return new Promise((resolve, reject) => {
            restClient.execute(headers, criteria).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });

    }

    updateUserOrg(email: string, orgId: string, roles: string[]) {

        let url = BIGDATA_CONSTANT.url.api + 'mngmnt/accounts/assign-user-to-org'
        var restClient = new RestClient(Methods.POST, url);

        // var user: UserModel  = this.getUserFromLocalStorage();
        var headers = {
            // token: user.token,
            // api_key: user.apiKey
        };
        var criteria = {
            "email": email,
            orgId: orgId,
            "roles": roles
        }

        return new Promise((resolve, reject) => {
            restClient.execute(headers, criteria).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });

    }


    detail(id: string, isSPA?: boolean) {

        let url = !isSPA ? BIGDATA_CONSTANT.url.api + 'mngmnt-client/accounts/detail?id=' + id : BIGDATA_CONSTANT.url.api + 'mngmnt/accounts/detail?id=' + id
        var restClient = new RestClient(Methods.GET, url);

        // var user: UserModel  = this.getUserFromLocalStorage();
        var headers = {
            // token: user.token,
            // api_key: user.apiKey
        };
        return new Promise((resolve, reject) => {
            restClient.execute(headers, {}).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });

    }
    deletes(ids: string[], isSPA?: boolean) {

        let url = !isSPA ? BIGDATA_CONSTANT.url.api + 'mngmnt-client/accounts/delete' : BIGDATA_CONSTANT.url.api + 'mngmnt/accounts/delete';
        var restClient = new RestClient(Methods.POST, url);
        // var user: UserModel  = this.getUserFromLocalStorage();
        var headers = {
            // token: user.token,
            // api_key: user.apiKey
        };

        return new Promise((resolve, reject) => {
            restClient.execute(headers, ids).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });

    }

    deleteUserFromOrg(id: string, isSPA?: boolean) {

        let url = BIGDATA_CONSTANT.url.api + 'mngmnt-client/accounts/remove-user-fr-org?email=' + id;
        var restClient = new RestClient(Methods.POST, url);
        let body = { email: id }
        // var user: UserModel  = this.getUserFromLocalStorage();
        var headers = {
            // token: user.token,
            // api_key: user.apiKey
        };

        return new Promise((resolve, reject) => {
            restClient.execute(headers, body).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });

    }
    deleteUserFromOrgSPADMIN(id: string, orgId: string) {
        let url = BIGDATA_CONSTANT.url.api + 'mngmnt/accounts/remove-user-fr-org?email=' + id + '&orgId=' + orgId
        var restClient = new RestClient(Methods.POST, url);
        let body = { email: id, orgId: orgId }
        // var user: UserModel  = this.getUserFromLocalStorage();
        var headers = {
            // token: user.token,
            // api_key: user.apiKey
        };

        return new Promise((resolve, reject) => {
            restClient.execute(headers, body).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }

    assignUserToOrg = (id: string, orgId: string, roles?: string[]) => {
        let url = BIGDATA_CONSTANT.url.api + 'mngmnt/accounts/assign-user-to-org'
        var restClient = new RestClient(Methods.POST, url);
        let body = { email: id, orgId: orgId, roles: roles ? roles : ["USER"] }
        var headers = {
        };
        return new Promise((resolve, reject) => {
            restClient.execute(headers, body).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }

    resetPassword = (id: string, isSPA?: boolean) => {
        let url = !isSPA ? BIGDATA_CONSTANT.url.api + 'mngmnt-client/accounts/reset-password?id=' + id : BIGDATA_CONSTANT.url.api + 'mngmnt/accounts/reset-password?id=' + id;
        var restClient = new RestClient(Methods.POST, url);

        // var user: UserModel  = this.getUserFromLocalStorage();
        var headers = {
            // token: user.token,
            // api_key: user.apiKey
        };

        return new Promise((resolve, reject) => {
            restClient.execute(headers, {}).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }
    updateEdmSendInBlue = (name: string, apikey: string) => {
        let url = BIGDATA_CONSTANT.url.svc + 'workflow/api/edm/integration/sendinblue/save';
        var restClient = new RestClient(Methods.POST, url);

        // var user: UserModel  = this.getUserFromLocalStorage();
        var headers = {
            // token: user.token,
            // api_key: user.apiKey
        };

        return new Promise((resolve, reject) => {
            restClient.execute(headers, {
                name: name,
                apiKey: apikey
            }).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }
    getEdmSendBlue = () => {
        let url = BIGDATA_CONSTANT.url.svc + 'workflow/api/edm/integration/sendinblue/get';
        var restClient = new RestClient(Methods.GET, url);

        // var user: UserModel  = this.getUserFromLocalStorage();
        var headers = {
            // token: user.token,
            // api_key: user.apiKey
        };

        return new Promise((resolve, reject) => {
            restClient.execute(headers, {

            }).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }
    getFbEvent = (criteria?: typeCriteriaFbEvent, isSPA?: boolean) => {
        let url = BIGDATA_CONSTANT.url.api + 'dsvc/fb/events/search?pageSize=15';

        let paramsUrl = new URLSearchParams();

        if (criteria && criteria.page) {
            const p = criteria.page - 1;
            paramsUrl.append('page', "" + p);
        }
        if (criteria && criteria.order) {
            paramsUrl.append('order', criteria.order);
        }
        if (criteria && (criteria.page || criteria.order)) {
            url = url + "&" + paramsUrl.toString();
        }

        var restClient = new RestClient(Methods.POST, url);

        // var user: UserModel  = this.getUserFromLocalStorage();
        var headers = {
            // token: user.token,
            // api_key: user.apiKey
        };

        return new Promise((resolve, reject) => {
            restClient.execute(headers, criteria).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }


    // check password policy
    checkPasswordPolicy(text: string) {
        const query = encodeURIComponent(text || '')
        let url = BIGDATA_CONSTANT.url.api + `password/policy/verify?pwd=${query}`;
        var restClient = new RestClient(Methods.GET, url);

        var headers = {
        };

        return new Promise((resolve, reject) => {
            restClient.execute(headers, {

            }).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }

    updateTermStatus(active: boolean) {
        let url = BIGDATA_CONSTANT.url.api + `mngmnt-client/orgs/config/term/update/_status?active=${active}`;
        var restClient = new RestClient(Methods.POST, url);

        var headers = {
        };

        return new Promise((resolve, reject) => {
            restClient.execute(headers, {

            }).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }

    saveTerm(dto: any) {
        let url = BIGDATA_CONSTANT.url.api + `mngmnt-client/orgs/config/term/save`;
        var restClient = new RestClient(Methods.POST, url);

        var headers = {
        };

        return new Promise((resolve, reject) => {
            restClient.execute(headers, dto).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }

    checkUserAcceptedTermOfOrg(orgId: string) {
        let url = BIGDATA_CONSTANT.url.api + `accounts/users/current/accept-term/check?orgId=${orgId}`;
        var restClient = new RestClient(Methods.GET, url);

        var headers = {
        };

        return new Promise((resolve, reject) => {
            restClient.execute(headers, {}).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }

    acceptedTermOfOrg(orgId: string) {
        let url = BIGDATA_CONSTANT.url.api + `accounts/users/current/accept-term?orgId=${orgId}`;
        var restClient = new RestClient(Methods.GET, url);

        var headers = {
        };

        return new Promise((resolve, reject) => {
            restClient.execute(headers, {}).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }

    getPassPolicy() {

        let url = BIGDATA_CONSTANT.url.api + `mngmnt-client/orgs/config/password-policy/get`;
        var restClient = new RestClient(Methods.GET, url);

        var headers = {
        };

        return new Promise((resolve, reject) => {
            restClient.execute(headers, {}).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }

    getSessionPolicy() {

        let url = BIGDATA_CONSTANT.url.api + `mngmnt-client/orgs/config/session-policy/get`;
        var restClient = new RestClient(Methods.GET, url);

        var headers = {
        };

        return new Promise((resolve, reject) => {
            restClient.execute(headers, {}).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }
    savePassPolicy(dto: any) {

        let url = BIGDATA_CONSTANT.url.api + `mngmnt-client/orgs/config/password-policy/save`;
        var restClient = new RestClient(Methods.POST, url);

        var headers = {
        };

        return new Promise((resolve, reject) => {
            restClient.execute(headers, dto).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }
    saveSessionPolicy(dto: any) {

        let url = BIGDATA_CONSTANT.url.api + `mngmnt-client/orgs/config/session-policy/save`;
        var restClient = new RestClient(Methods.POST, url);

        var headers = {
        };

        return new Promise((resolve, reject) => {
            restClient.execute(headers, dto).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }
    suggestionPermissionGroup(q: string) {
        const encodedQuery = encodeURIComponent(q)
        let url = BIGDATA_CONSTANT.url.api + `mngmnt-client/permission/group/suggest?query=${encodedQuery}&pageSize=50`;
        var restClient = new RestClient(Methods.GET, url);
        var headers = {
        };
        return new Promise((resolve, reject) => {
            restClient.execute(headers, {}).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }

    generatePassword() {
        let url = BIGDATA_CONSTANT.url.api + `password/policy/generate`;
        var restClient = new RestClient(Methods.GET, url);

        return new Promise((resolve, reject) => {
            restClient.execute({}, {}).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }


}

export const ConsoleUsersDAL = new ConsoleUsers()