import { Button, Col, Divider, Form, Input, Popover, Row, Table, Tag, Tooltip, Typography } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import { ColumnProps } from 'antd/lib/table'
import _ from 'lodash'
import moment from 'moment'
import numeral from 'numeral'
import { InfoList, LongTextTd, O2OCustomPaging } from 'o2o_layout'
import React from 'react'
import { useAsyncRetry } from 'react-use'
import CommonTypeProps, { ICommonPayload } from '../../../../common/CommonTypeProps'
import { BIGDATA_CONSTANT } from '../../../../common/constant'
import { getCurrentURL } from '../../../../common/helper'
import { ObjectModels } from '../../../../redux/reducer/customFieldsReducer'
import DrawerViewJSON from '../../../../shares/DrawerViewJSON'
import { O2OFormItem } from '../../../../shares/O2OFormItem'
import { ExportActivityDAL } from './ExportActivityDAL'
import { ExportActivitys, TypeCriteriaExport, TypeExportActivityItem } from './models'
import DistinctComponent from './share/DistinctComponent'



interface IFilterProps extends FormComponentProps {
    commonActionFromDALV2: any
    loading: boolean
    onSearch: (value: any) => void
}

const FilterExportActivity = Form.create<IFilterProps>()((props: IFilterProps) => {

    const onSearch = (e: any) => {
        e.preventDefault()
        props.form.validateFieldsAndScroll((err: any, values: any) => {
            if (!err) {
                props.onSearch(values)
            }
        })
    }

    return (
        <Form colon={false} hideRequiredMark={true}>
            <Row gutter={[5, 5]} type='flex'>
                <Col sm={24} lg={12} xl={8} xxl={5}>
                    <O2OFormItem label="Info">
                        {props.form.getFieldDecorator("freeText", {})(
                            <Input.Search onPressEnter={onSearch} allowClear placeholder="Search..." />
                        )}
                    </O2OFormItem>
                </Col>
                <Col sm={24} lg={12} xl={8} xxl={5}>
                    <DistinctComponent
                        type='category'
                        commonActionFromDALV2={props.commonActionFromDALV2}
                        label='Category'
                        keyField='categories'
                        form={props.form}
                        placeholder='Select(s)'
                    />
                </Col>

                <Col sm={24} lg={12} xl={8} xxl={5}>
                    <DistinctComponent
                        type='action'
                        commonActionFromDALV2={props.commonActionFromDALV2}
                        label='Notif Channel'
                        keyField='actions'
                        form={props.form}
                        placeholder='Select(s)'
                    />
                </Col>

                <Col sm={24} lg={12} xl={8} xxl={5}>
                    <DistinctComponent
                        type='source'
                        commonActionFromDALV2={props.commonActionFromDALV2}
                        label='Source'
                        keyField='sources'
                        form={props.form}
                        placeholder='Select(s)'
                    />
                </Col>

                <Col>
                    <O2OFormItem label={<span></span>}>
                        <Button loading={props.loading} type="primary" onClick={onSearch}>Search</Button>
                    </O2OFormItem>
                </Col>
            </Row>
        </Form>
    )
})


interface Iprops extends CommonTypeProps {
    allMom: ObjectModels
    getAllCustomFieldObjects: any
}

const SEARCH_CRITERIA_EXPORT = "SEARCH_CRITERIA_EXPORT"

const initCriteria: ICommonPayload<TypeCriteriaExport> = {
    loading: false,
    type: SEARCH_CRITERIA_EXPORT,
    payload: {} as TypeCriteriaExport,
}

const reducerCriteria = (state: ICommonPayload<TypeCriteriaExport>, action: ICommonPayload<TypeCriteriaExport>) => {
    switch (action.type) {
        case SEARCH_CRITERIA_EXPORT:
            return { ...state, ...action }
        default:
            throw new Error()
    }
}

const ExportActivityComponent = (props: Iprops) => {
    const [criteria, dispatchCriteria]: [ICommonPayload<TypeCriteriaExport>, (action: ICommonPayload<TypeCriteriaExport>) => void] = React.useReducer(reducerCriteria, initCriteria)
    const [data, setData] = React.useState({} as undefined | TypeExportActivityItem)
    const [visibleViewJSON, setVisibleViewJSON] = React.useState(false as boolean)
    const [visibleViewCriteria, setVisibleViewCriteria] = React.useState(false as boolean)
    const allMom = props.allMom

    React.useEffect(() => {
        props.getAllCustomFieldObjects()
    }, [])


    const loadDataTableExport = async () => {
        const result = await props.commonActionFromDALV2(ExportActivityDAL.search, criteria.payload)
        try {
            const response = await result
            if (response.success) {
                return response
            }
            return new ExportActivitys()
        } catch (error) {
            return new ExportActivitys()
        }
    }

    const stateDataExport = useAsyncRetry(async () => {
        if (!_.isEmpty(criteria.payload)) {
            return await loadDataTableExport()
        }
        return new ExportActivitys()
    }, [criteria.payload])

    const dataExports = stateDataExport.value ? stateDataExport.value : new ExportActivitys()

    React.useEffect(() => {
        dispatchCriteria({
            loading: false,
            type: SEARCH_CRITERIA_EXPORT,
            payload: { page: 0, pageSize: 10 } as TypeCriteriaExport
        })
    }, [])

    React.useEffect(() => {
        props.updateMetaTitle({ title: BIGDATA_CONSTANT.trackTitle.Activities.ExportLogs })
        const timer = setTimeout(() => {
            props.updateMeta({
                name: BIGDATA_CONSTANT.trackTitle.Activities.ExportLogs,
                title: BIGDATA_CONSTANT.trackTitle.Activities.ExportLogs,
                app: "CONSOLE",
                url: getCurrentURL(props.location)
            })
        }, 5000)

        return () => {
            clearTimeout(timer)
        }
    }, [])

    const columns: ColumnProps<TypeExportActivityItem>[] = [
        {
            title: "#",
            key: "index",
            align: "center",
            sorter: false,
            width: 40,
            render: (text: any, record: TypeExportActivityItem, index: number) => {
                return index + 1
            }
        }, {
            title: "Exported By",
            key: "exported-by",
            sorter: false,
            width: 150,
            ellipsis: true,
            render: (text: any, record: TypeExportActivityItem, index: number) => {
                return <LongTextTd text={record.exportedBy} />
            }
        }, {
            title: "Start Time",
            key: "start_time",
            width: 120,
            ellipsis: true,
            render(text: any, record: TypeExportActivityItem, index: number) {
                return record.startTime ? (
                    <Tooltip title={moment(record.startTime).format("LLLL")}>
                        {moment(record.startTime).fromNow()}
                    </Tooltip>
                ) : "--/--"
            },
        }, {
            title: "End Time",
            key: "end_time",
            width: 120,
            ellipsis: true,
            render(text: any, record: TypeExportActivityItem, index: number) {
                return record.startTime ? (
                    <Tooltip title={moment(record.endTime).format("LLLL")}>
                        {moment(record.endTime).fromNow()}
                    </Tooltip>
                ) : "--/--"
            },
        }, {
            title: "Total",
            key: "total",
            align: "right",
            ellipsis: true,
            width: 80,
            render: (text: any, record: TypeExportActivityItem, index: number) => {
                return (
                    <Popover content={numeral(record.totalRecords).format("0,00")}>
                        {numeral(record.totalRecords).format("0.[0]a")}
                    </Popover>
                )

            }
        }, {
            title: "Status",
            key: "status",
            sorter: false,
            width: 100,
            render: (text: any, record: TypeExportActivityItem, index: number) => {
                if (record.status === "Success") {
                    return <Tag color='green'>{record.status}</Tag>
                } else if (record.status === "Failed") {
                    return <Tag color='red'>{record.status}</Tag>
                } else {
                    return <Tag color='gold'>{record.status}</Tag>
                }
            }
        }, {
            title: "Source",
            key: "source",
            sorter: false,
            width: 180,
            ellipsis: true,
            render: (text: any, record: TypeExportActivityItem, index: number) => {
                const model = allMom.results[record.source || ""] || {}
                const { code, name } = model
                return <LongTextTd textDisplay={name} text={code} />
            }
        }, {
            title: "Reason",
            key: "reason",
            sorter: false,
            ellipsis: true,
            render: (text: any, record: TypeExportActivityItem, index: number) => {
                return <LongTextTd text={record.reason} />
            }
        }, {
            title: "Action",
            key: "action",
            width: 90,
            fixed: "right",
            render(text: any, record: TypeExportActivityItem, index: number) {
                return (
                    <>
                        <Tooltip title="Data Filter">
                            <Button size="small" icon="select" type="link" onClick={() => {
                                setData(JSON.parse(record.criteria))
                                setVisibleViewCriteria(true)
                            }} />
                        </Tooltip>

                        <Divider type="vertical" />

                        <Tooltip title="View Json">
                            <Button size="small" icon="eye" type="link" onClick={() => {
                                setData(record)
                                setVisibleViewJSON(true)
                            }} />
                        </Tooltip>
                    </>
                )
            },
        },

    ]

    return (

        <Row gutter={[10, 10]} type='flex'>
            <Col xs={24}>
                <Typography.Title level={4}>Export Logs</Typography.Title>
            </Col>

            <Col xs={24}>
                <FilterExportActivity
                    commonActionFromDALV2={props.commonActionFromDALV2}
                    loading={stateDataExport.loading}
                    onSearch={(values: any) => {
                        dispatchCriteria({
                            loading: false,
                            payload: {
                                ...criteria.payload,
                                ...{ page: 0, ...values },
                            },
                            type: SEARCH_CRITERIA_EXPORT,
                        })
                    }}
                />
            </Col>

            <Col xs={24}>
                <InfoList
                    loading={stateDataExport.loading}
                    list={dataExports}
                    refresh={stateDataExport.retry}
                />
            </Col>

            <Col xs={24}>
                <Table
                    loading={stateDataExport.loading}
                    columns={columns}
                    dataSource={dataExports.results.content}
                    rowKey="id"
                    size="small"
                    scroll={{ x: 'calc(700px + 50%)' }}
                    pagination={false}
                />
            </Col>

            <Col xs={24} style={{ paddingBottom: 16 }}>
                <O2OCustomPaging
                    list={dataExports}
                    onChange={(e: number) => {
                        dispatchCriteria({
                            loading: false,
                            payload: { ...criteria.payload, ...({ page: e } as any) },
                            type: SEARCH_CRITERIA_EXPORT,
                        });
                    }}
                    onChangePageSize={(e: number) => {
                        dispatchCriteria({
                            loading: false,
                            payload: { ...criteria.payload, ...({ page: 0, pageSize: e } as any) },
                            type: SEARCH_CRITERIA_EXPORT,
                        });
                    }}
                />
            </Col>

            <DrawerViewJSON
                visible={visibleViewCriteria}
                onClose={() => {
                    setVisibleViewCriteria(false)
                    setData(undefined)
                }}
                data={data}
            />

            <DrawerViewJSON
                visible={visibleViewJSON}
                onClose={() => {
                    setVisibleViewJSON(false)
                    setData(undefined)
                }}
                data={data}
            />

        </Row>

    )
}

export default ExportActivityComponent