import { bindActionCreators } from 'redux';
import { getColumns, saveColumns } from '../../common/actions/CommonAction';
import { O2OCommonContainer } from "../../common/container/O2OContainer";
import { IState } from "../../redux/reducer";
import { getOrgs } from '../orgs/OrgsAction';
import { ConsoleSPUsersComponent } from "./ConsoleSPUsersComponent";
import { addUser, assignUserToOrg, deleteAccount, deleteUserFromOrg, deleteUserFromOrgSPADMIN, detailAccount, getAccount, resetPasswordAccount, updateAccount, updateRoleAccount, updateUserOrg } from './ConsoleUsersAction';
import { assignUsersToPermissionGroups, saveUsersToPermissionGroups, searchAllPermissionGroup, searchPermission } from './permisions/action/PermissionAction';
import { distinctByField } from '../../shares/action/distinctFieldAction';


function mapStateToProps(rootState: IState) {
    return {
        user: rootState.user,
        commonResponse: rootState.commonResponse,
        consoleUsers: rootState.consoleUsers,
        organizations: rootState.organizations,
        consoleUser: rootState.consoleUser,
        permissionGroups: rootState.allPermissionGroups,
        permissions: rootState.permissions
    };
}


function mapDispatchToProps(dispatch: any) {
    return {
        getAccount: bindActionCreators(getAccount, dispatch),
        getOrgs: bindActionCreators(getOrgs, dispatch),
        addUser: bindActionCreators(addUser, dispatch),
        updateAccount: bindActionCreators(updateAccount, dispatch),
        detailAccount: bindActionCreators(detailAccount, dispatch),
        resetPasswordAccount: bindActionCreators(resetPasswordAccount, dispatch),
        deleteAccount: bindActionCreators(deleteAccount, dispatch),
        updateRoleAccount: bindActionCreators(updateRoleAccount, dispatch),
        deleteUserFromOrg: bindActionCreators(deleteUserFromOrg, dispatch),
        deleteUserFromOrgSPADMIN: bindActionCreators(deleteUserFromOrgSPADMIN, dispatch),
        updateUserOrg: bindActionCreators(updateUserOrg, dispatch),
        assignUserToOrg: bindActionCreators(assignUserToOrg, dispatch),
        searchPermissionGroup: bindActionCreators(searchAllPermissionGroup, dispatch),
        assignUsersToPermissionGroups: bindActionCreators(assignUsersToPermissionGroups, dispatch),
        searchPermission: bindActionCreators(searchPermission, dispatch),
        getColumns: bindActionCreators(getColumns, dispatch),
        saveColumns: bindActionCreators(saveColumns, dispatch),
        saveUsersToPermissionGroups: bindActionCreators(saveUsersToPermissionGroups, dispatch),
        distinctByField: bindActionCreators(distinctByField, dispatch),
    };
}

export const ConsoleSPUsersContainer = O2OCommonContainer(ConsoleSPUsersComponent, mapStateToProps, mapDispatchToProps)