import { IState } from '../../../redux/reducer/index';
import { O2OCommonContainer } from '../../../common/container/O2OContainer';
import { changeApiKey } from '../ConsoleUsersAction';
import { bindActionCreators } from 'redux';
import { UserApiComponent } from './UserApiComponent';
import { getMe } from '../../../common/actions/CommonAction';

function mapStateToProps(rootState: IState) {
    return {  
    };
}


function mapDispatchToProps(dispatch: any) {
    return {
       changeApiKey: bindActionCreators(changeApiKey, dispatch),
       getMe: bindActionCreators(getMe, dispatch)
    };
}

export const UserApiContainer = O2OCommonContainer(UserApiComponent, mapStateToProps, mapDispatchToProps)