import { IResponseBigDataVN, TypeResultsBigData } from "o2o_layout/lib/common/interface";

export type TypeAccessLog = {
    id?: string;
    orgId?: string;
    userId?: string;
    accessedAt?: 0;
    app?: string;
    name?: string;
    url?: string;
    createdAt?: number;
    createdBy?: string;
    updatedAt?: number;
    updatedBy?: any;
}

export class AccessLogs implements IResponseBigDataVN<TypeAccessLog>{
    success: boolean;
    results: TypeResultsBigData<TypeAccessLog>;
    constructor() {
        this.results = {
            content: [],
            timeTaken: 0,
            currentTime: 0,
            page: {
                pageSize: 0,
                currentPage: 0,
                totalRecords: 0,
                totalPages: 0
            },
            extras: null
        }
        this.success = false
    }
}

export class StatsAccessLogs {
    success: boolean;
    results: {
        statsByNames: { [key: string]: number };
        statsByLinks: { [key: string]: number };
        statsByUsers: { [key: string]: number };
        totalRecords: number;
        took: number;
    };
    constructor() {
        this.success = false
        this.results = {
            statsByNames: {},
            statsByLinks: {},
            statsByUsers: {},
            totalRecords: 0,
            took: 0,
        }
    }
}
