import { SET_GLOBAL_MESSAGE, LOADING_HIDE_SCREEN, LOADING_SHOW_SCREEN, UPDATE_LOCALE, UPDATE_AUTHEN, SHOW_SIDER_MAIN_LAYOUT, GET_ME, UPDATE_ERROR } from '../actionType/actionType';
import { Authen } from '../../redux/model';
import { IActionUI, UPDATE_BREAKPOINT, UPDATE_FILTER_WEBLOG, UPDATE_COLUMNS, UPDATE_META, SHOW_COMMON_ERROR } from '../../redux/reducer/uiReducer';
import { UserDAL } from '../../dal/UserDAL';
import { CommonResponse } from '../../redux/model/commonResponse';
import { UPDATE_COMMON_RESPONSE, IActionCommonResponse } from '../../redux/reducer/commonResponseReducer';
import { IActionLocale } from '../../redux/reducer/localeReducer';
import { Response } from '../../libs/api/Response';
import { O2OResponse } from 'o2o_layout/lib/common/rest';
import { GET_ALL_CUSTOM_FIELD_OBJECTS, IActionGetAllCutomFieldObject } from '../../redux/reducer/customFieldsReducer';
import { CustomFieldDAL } from '../../shares/dal/CustomFieldDAL';

/**
 * hiển thị message cho tất cả các page
 * @param message
 */
export function setGlobalMessage(message: string) {
    return (dispatch: any) => {
        return new Promise((resolve, reject) => {
            try {
                dispatch({
                    type: SET_GLOBAL_MESSAGE,
                    message: message
                });
                resolve(message)
            } catch (error) {
                reject(error)
            }
        });
    }
}

export type typeUpdateLocate = (locale: string) => any
export function updateLocale<typeUpdateLocate>(locale: string) {
    return (dispatch: any) => {
        return new Promise((resolve, reject) => {
            try {
                dispatch({
                    type: UPDATE_LOCALE,
                    locale: locale
                } as IActionLocale);
                resolve(locale)
            } catch (error) {
                reject(error)
            }
        });
    }
}

export type typeUpdateAuthen = (authen: Authen) => any
export function updateAuthen<typeUpdateAuthen>(authen: Authen) {
    return (dispatch: any) => {
        return new Promise((resolve, reject) => {
            try {
                dispatch({
                    type: UPDATE_AUTHEN,
                    payload: authen
                });
                resolve(authen)
            } catch (error) {
                reject(error)
            }
        });
    }
}

export type typeGetMe = () => any
export function getMe<typeGetMe>() {

    return (dispatch: any) => {

        return new Promise((resolve, reject) => {
            UserDAL.getUserFromToken().then((res: any) => {

                dispatch({
                    type: GET_ME,
                    user: res.result,
                });
                dispatch({
                    type: UPDATE_AUTHEN,
                    payload: {isAuth: true, permission: res.permission, changePassword: res.changePassword, changePasswordReason: res.changePasswordReason} as Authen
                 });
                resolve(res.result);
            }, error => {
                dispatch({
                    type: UPDATE_AUTHEN,
                    payload: {isAuth: false} as Authen
                });
                reject(error);
            });
        });
    }

}

export type typeSetGobalLoading = (status: boolean) => any
export function setGlobalLoading<typeSetGobalLoading>(status: boolean) {
    return (dispatch: any) => {
        return new Promise((resolve, reject) => {
            try {
                dispatch({
                    type: (status ? LOADING_SHOW_SCREEN : LOADING_HIDE_SCREEN)
                });
                resolve(true);
            } catch (error) {
                reject(error)
            }
        });
    }
}

export type typeUpdateErrorUI = (status: number, res: any) => any
export function updateErrorUI<typeUpdateErrorUI>(status: number, res: any) {
    return (dispatch: any) => {
        return new Promise((resolve, reject) => {
            try {
                dispatch({
                    type: UPDATE_ERROR,
                    errorStatus: status,
                    errorResponse: res
                } as IActionUI);
                resolve(true);
            } catch (error) {
                reject(error)
            }
        });
    }
}

export type typeUpdateFilterDataWebLog = (from: number, to: number) => any
export function updateFilterDataWebLog<typeUpdateFilterDataWebLog>(from: number, to: number) {
    return (dispatch: any) => {
        return new Promise((resolve, reject) => {
            try {
                dispatch({
                    type: UPDATE_FILTER_WEBLOG,
                    filterWeblogFromDate: from,
                    filterWeblogToDate: to
                } as IActionUI);
                resolve(true);
            } catch (error) {
                reject(error)
            }
        });
    }
}


export type typeUpdateBreakpoint = (breakpoint: string, innerHeight: number) => any
export function updateBreakpoint<typeUpdateBreakpoint>(breakpoint: string, innerHeight: number) {
    return (dispatch: any) => {
        return new Promise((resolve, reject) => {
            try {
                dispatch({
                    type: UPDATE_BREAKPOINT,
                    breakpoint: breakpoint,
                    innerHeight: innerHeight
                } as IActionUI);
                resolve(true);
            } catch (error) {
                reject(error)
            }
        });
    }
}

export type typeShowSiderMainLayout = (status: boolean) => any
export function showSiderMainLayout<typeShowSiderMainLayout>(status: boolean) {

    return (dispatch: any) => {
        return new Promise((resolve, reject) => {
            try {
                dispatch({
                    type: SHOW_SIDER_MAIN_LAYOUT,
                    showSider: status
                } as IActionUI);

                resolve(true);
            } catch (error) {
                reject(error)
            }
        });
    }
}

export type typeUpdateCommonResponse = (cR: CommonResponse) => any
export function updateCommonResponse<typeUpdateCommonResponse>(cR: CommonResponse) {
    return (dispatch: any) => {
        return new Promise((resolve, reject) => {
            try {
                dispatch({
                    type: UPDATE_COMMON_RESPONSE,
                    payload: cR
                } as IActionCommonResponse);

                resolve(true);
            } catch (error) {
                reject(error)
            }
        });
    }
}


export function saveColumns (values: any){
    return (dispatch: any) => {

        return new Promise((resolve, reject) => {
            UserDAL.saveColumns(values).then((res: any) => {

                dispatch({
                    type: UPDATE_COMMON_RESPONSE,
                    payload: res
                } as IActionCommonResponse);

                resolve(res.result);
            }, (error: Response) => {
                if (error.status == 401 || error.status == 403) {
                    dispatch({
                        type: UPDATE_ERROR,
                        errorStatus: error.status,
                        errorResponse: error.data
                    } as IActionUI)
                }
                reject(error);
            });
        });
    }
}


export function  getColumns (objectType: string){
    return (dispatch: any) => {

        return new Promise((resolve, reject) => {
            UserDAL.getColumns(objectType).then((res: any) => {

                dispatch({
                    type: UPDATE_COLUMNS,
                    columns: res
                } as IActionUI);

                resolve(res.result);
            }, (error: Response) => {
                if (error.status == 401 || error.status == 403) {
                    dispatch({
                        type: UPDATE_ERROR,
                        errorStatus: error.status,
                        errorResponse: error.data
                    } as IActionUI)
                }
                reject(error);
            });
        });
    }
}


export function  enable2Fa (){
    return (dispatch: any) => {

        return new Promise((resolve, reject) => {
            UserDAL.enable2Fa().then((res: any) => {
                dispatch({
                    type: UPDATE_COMMON_RESPONSE,
                    payload: res
                } as IActionCommonResponse);

                resolve(res.result);
            }, (error: Response) => {
                if (error.status == 401 || error.status == 403) {
                    dispatch({
                        type: UPDATE_ERROR,
                        errorStatus: error.status,
                        errorResponse: error.data
                    } as IActionUI)
                }
                reject(error);
            });
        });
    }
}

export function  verifyEnable2Fa(authenCode: string, totpKey: string){
    return (dispatch: any) => {

        return new Promise((resolve, reject) => {
            UserDAL.verifyEnable2Fa(authenCode, totpKey).then((res: any) => {
                dispatch({
                    type: UPDATE_COMMON_RESPONSE,
                    payload: res
                } as IActionCommonResponse);

                resolve(res.result);
            }, (error: Response) => {
                if (error.status == 401 || error.status == 403) {
                    dispatch({
                        type: UPDATE_ERROR,
                        errorStatus: error.status,
                        errorResponse: error.data
                    } as IActionUI)
                }
                reject(error);
            });
        });
    }
}



export function  verifyDisable2Fa(){
    return (dispatch: any) => {

        return new Promise((resolve, reject) => {
            UserDAL.verifyDisable2Fa().then((res: any) => {
                dispatch({
                    type: UPDATE_COMMON_RESPONSE,
                    payload: res
                } as IActionCommonResponse);

                resolve(res.result);
            }, (error: Response) => {
                if (error.status == 401 || error.status == 403) {
                    dispatch({
                        type: UPDATE_ERROR,
                        errorStatus: error.status,
                        errorResponse: error.data
                    } as IActionUI)
                }
                reject(error);
            });
        });
    }
}



export function commonActionFromDAL (DAL: any, functionName: string, ...params: any){
    return (dispatch: any) => {

        return new Promise((resolve, reject) => {
            DAL[functionName](...params).then((res: any) => {

                dispatch({
                    type: UPDATE_COMMON_RESPONSE,
                    payload: res
                } as IActionCommonResponse);

                resolve(res);
            }, (error: Response) => {
                if (error.status == 401 || error.status == 403) {
                    dispatch({
                        type: UPDATE_ERROR,
                        errorStatus: error.status,
                        errorResponse: error.data
                    } as IActionUI)
                }
                reject(error);
            });
        });
    }
}

export type typeShowCommonError = (err: any) => any
export function showCommonError<typeShowCommonError>(err: any) {
    return (dispatch: any) => {
        return new Promise((resolve, reject) => {
            try {
                dispatch({
                    type: SHOW_COMMON_ERROR,
                    commonError: err,
                } as IActionUI);
                resolve(true);
            } catch (error) {
                reject(error)
            }
        });
    }
}
export function getAllCustomFieldObjects(q?: string) {
    return (dispatch: any) => {

        return new Promise((resolve, reject) => {
            CustomFieldDAL.getAllCustomFieldObjects(q).then((res: any) => {

                dispatch({
                    type: GET_ALL_CUSTOM_FIELD_OBJECTS,
                    payload: res
                } as IActionGetAllCutomFieldObject);
                resolve(res);
            }, (error: Response) => {
                if (error.status == 401 || error.status == 403) {
                    dispatch({
                        type: UPDATE_ERROR,
                        errorStatus: error.status,
                        errorResponse: error.data
                    } as IActionUI)
                }
                reject(error);
            });
        });
    }
}


export function commonActionFromDALV2(DAL_Function: any, ...params: any){
    return (dispatch: any) => {

        return new Promise((resolve, reject) => {
            DAL_Function(...params).then((res: any) => {

                dispatch({
                    type: UPDATE_COMMON_RESPONSE,
                    payload: res
                } as IActionCommonResponse);

                resolve(res);
            }, (error: Response) => {
                if (error.status == 401 || error.status == 403) {
                    dispatch({
                        type: UPDATE_ERROR,
                        errorStatus: error.status,
                        errorResponse: error.data
                    } as IActionUI)
                }
                reject(error);
            });
        });
    }
}


/**
 * Updat meta
 * @param message
 */
 export function updateMeta(meta: {
    app: string,
    url: string,
    name: string,
    title: string
}) {
    return (dispatch: any) => {
        return new Promise((resolve, reject) => {
            try {

                UserDAL.trackRecent(meta)
                    .then(orgs => {
                        dispatch({
                            type: UPDATE_COMMON_RESPONSE,
                            payload: orgs
                        } as any)
                        resolve(orgs);
                    }, (error: O2OResponse) => {
                        if (error.status == 401 || error.status == 403) {
                            dispatch({
                                type: UPDATE_ERROR,
                                errorStatus: error.status,
                                errorResponse: error.data
                            } as IActionUI)
                        }
                        reject(error);
                    });

                resolve(meta)
            } catch (error) {
                reject(error)
            }
        });
    }
}


/**
 * Updat title
 * @param message
 */
 export function updateMetaTitle(meta: {
    title: string
}) {
    return (dispatch: any) => {
        return new Promise((resolve, reject) => {
            try {
                dispatch({
                    type: UPDATE_META,
                    meta: meta.title
                });
                resolve(meta)
            } catch (error) {
                reject(error)
            }
        });
    }
}