import { Form, Select, Tooltip } from "antd"
import _ from "lodash"
import * as React from "react"
import { TypeDistinctDomain } from "./dal/DistinctFieldDAL"
import numeral from 'numeral';


export const DistinctByField = (props: {
    api: any,
    domain: TypeDistinctDomain,
    getFieldDecorator: any,
    field: string,
    placeholder: string,
    formLabel: string | React.ReactNode,
    formKey: string
    maxTagTextLength?:  number
}) => {
    const [loading, setLoading] = React.useState(false)
    const [actions, setActions] = React.useState({} as any)
    React.useEffect(() => {
        props.api(props.domain as TypeDistinctDomain, props.field)
            .then((e: any) => {
                setLoading(false)
                setActions(e)
            })
    }, ["api"])

    const actionsResult: { [key: string]: number } = actions && !_.isEmpty(actions) && actions.result && actions.result.results && !_.isEmpty(actions.result.results[props.field]) ? actions.result.results[props.field] : {}

    return (
        <Form.Item style={{ marginBottom: 0 }} label={props.formLabel}>
            {props.getFieldDecorator(props.formKey, {

            })(
                <Select
                    style={{ width: "100%" }}
                    optionLabelProp="label"
                    loading={loading}
                    allowClear showArrow

                    showSearch mode="tags" maxTagCount={1} maxTagTextLength={props.maxTagTextLength || 5} placeholder={props.placeholder}
                >
                    {Object.keys(actionsResult).map((item: string) => {
                        if (item) {
                            return <Select.Option label={item} key={item}><Tooltip title={`${item} (${numeral(actionsResult[item]).format("0,00")})`}>{item} ({numeral(actionsResult[item]).format("0,00")})</Tooltip></Select.Option>
                        }
                        return null
                    })}
                </Select>
            )}
        </Form.Item>

    )
}