import { IState } from '../../../redux/reducer/index';
import { O2OCommonContainer } from '../../../common/container/O2OContainer';
import { NotificationComponent } from './NotificationComponent';

function mapStateToProps(rootState: IState) {
    return {  
    };
}


function mapDispatchToProps(dispatch: any) {
    return {
       
    };
}

export const NotificationContainer = O2OCommonContainer(NotificationComponent, mapStateToProps, mapDispatchToProps)