import './landing.scss';
import { connect } from 'react-redux';
import { IState } from '../../redux/reducer/index';
import React from 'react';
import { Layout, Row, Col, LocaleProvider, Spin } from 'antd';
import Helmet from 'react-helmet';

type LandingPageComponentProps = {
    children: any,
    locale: string,
    updateLocale: any,
    ui?: any,
    logo?: any,
    companyName?: string,
    showHeader?:boolean,
    isSelectCompanyPage?: boolean
}

const { Content } = Layout

class LandingPageComponent extends React.Component<LandingPageComponentProps, any> {


    renderTheme = (children: any) => {
        return (
            <div>
                <Content style={{ padding: '0 50px', marginTop: 100 }}>
                    <Row>
                        <Col
                            xs={{
                                span: 24,
                            }} 
                            md={{ span: 12, offset: 6 }}
                            lg={{ span: 12, offset: 6 }}
                            xl={{ span: this.props.isSelectCompanyPage ? 10 : 6, offset:  this.props.isSelectCompanyPage ? 7 : 9}}
                            xxl={{ span: this.props.isSelectCompanyPage ? 10 : 6, offset:  this.props.isSelectCompanyPage ? 7 : 9}}
                        >

                            {children}

                        </Col>
                    </Row>

                </Content>
                
            </div>
        )
    }

    render() {
        const { children } = this.props
        return (
            <LocaleProvider>

                <Layout className="landing">

                    <Helmet>
                        <title>Landing Page</title>
                        <meta name="description" content="" />
                    </Helmet>
                    
                
                    {this.props.ui ? (

                        <Spin spinning={this.props.ui.isLoading}>
                            {this.renderTheme(children)}
                        </Spin>
                    ) :
                        this.renderTheme(children)
                    }

                </Layout>
            </LocaleProvider>
        )
    }
}

function mapStateToProps(rootState: IState) {
    return {
        ui: rootState.ui,
        companyName: "ByteTech"
    };
}

export const LandingPage = connect<any, any>(mapStateToProps)(LandingPageComponent)