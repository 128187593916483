import { IResponseBigDataVN, TypePageBigData, TypeResultsBigData } from "o2o_layout/lib/common/interface";

export type TypeCriteriaExport = {
    page: number;
    pageSize: number;
    freeText?: string;
}

export type TypeExportActivityItem = {
    id: string,
    orgId: string,
    category: string,
    source: string,
    fileName: string,
    criteria: string,
    totalRecords: number,
    status: string,
    message: string,
    exportedBy: string,
    startTime: number,
    endTime: number,
    timeTaken: 5307,
    action: string,
    actionValue: string
    reason?: string
}

export class ExportActivitys implements IResponseBigDataVN<TypeExportActivityItem> {
    results: TypeResultsBigData<TypeExportActivityItem>;
    success: boolean;
    constructor() {
        this.success = false;
        this.results = {
            content: [],
            currentTime: 0,
            extras: null,
            page: {
                pageSize: 0,
                currentPage: 0,
                totalRecords: 0,
                totalPages: 0,
            } as TypePageBigData,
            timeTaken: 0,
        };
    }
}

export class DistinctFields {
    result: {
        fields: string[],
        results: {
            [key: string]: {}
        }
    };
    success: boolean
}