import { Button, Col, Input, Row } from 'antd'
import Form, { FormComponentProps } from 'antd/lib/form'
import React from 'react'
import { O2OFormItem } from '../../../shares/O2OFormItem'

interface IFilterZMALiveVersionProps extends FormComponentProps {
    onSearch: (values: any) => void
    loading: boolean
}

const FilterZMALiveVersionComponent = (props: IFilterZMALiveVersionProps) => {

    const onSearch = () => {
        props.form.validateFields((err: any, values: any) => {
            if (!err) {
                props.onSearch(values)
            }
        })
    }

    const { getFieldDecorator } = props.form
    return (
        <Form hideRequiredMark colon={false}>
            <Row type='flex' gutter={[5, 5]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                    <O2OFormItem label="Info">
                        {getFieldDecorator("freeText", {

                        })(<Input.Search onPressEnter={onSearch} placeholder='Enter' />)}
                    </O2OFormItem>
                </Col>
                <Col>
                    <O2OFormItem label={<></>}>
                        <Button loading={props.loading} onClick={onSearch} type='primary'>Search</Button>
                    </O2OFormItem>
                </Col>
            </Row>
        </Form>
    )
}

export const FormFilterZMALiveVersionComponent = Form.create<IFilterZMALiveVersionProps>()(FilterZMALiveVersionComponent)