
import { O2OCommonContainer } from '../../../common/container/O2OContainer';
import { IState } from '../../../redux/reducer';
import ConsoleUsersActivityComponent from './ConsoleUsersActivityComponent';

function mapStateToProps(rootState: IState) {
    return {

    };
}


function mapDispatchToProps(dispatch: any) {
    return {

    };
}

export const ConsoleUsersActivityContainer = O2OCommonContainer(ConsoleUsersActivityComponent, mapStateToProps, mapDispatchToProps)
