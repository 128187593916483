import { Button, Col, Drawer, Form, Icon, Input, Row, Select, Table, Tooltip, notification } from 'antd'
import React from 'react'
import { Permissions, TypePermission, TypePermissionGroup } from './model/permissions'
import { FormComponentProps } from 'antd/lib/form'
import _ from 'lodash'
import { LongTextTd } from 'o2o_layout'
import { ColumnProps } from 'antd/lib/table'
import update from 'react-addons-update'
import { BIGDATA_CONSTANT } from '../../../common/constant'
import { getPrimaryColor } from '../../../common/helper'
import { useSelector } from '../../../redux/store'
import { IState } from '../../../redux/reducer'
import { O2OFormItem } from '../../../shares/O2OFormItem'
import SuggestionPermissionGroup from './components/SuggestionPermissionGroup'

interface Iprops extends FormComponentProps {
    visible: boolean,
    onClose: any,
    permission: Permissions,
    item?: TypePermissionGroup
    updatePermissionGroup: any
    onSuccess: () => void
    createPermissionGroup: any
    commonActionFromDALV2: any
}
const DrawerCreatePermissionGroup = Form.create<Iprops>()((props: Iprops) => {
    const { getFieldDecorator, setFieldsValue, resetFields, validateFields } = props.form;
    const { visible, onClose, onSuccess } = props
    const [permissions, setPermissions] = React.useState<string[]>([])
    const [loading, setLoading] = React.useState<boolean>(false)

    const uiSetting = useSelector((state: IState) => state.uiSetting)

    const columns: ColumnProps<{}>[] = [
        {
            title: '#',
            key: 'index',
            width: 45,
            render: (text: any, record: any, index: number) => {
                return index + 1
            },
        },
        {
            title: 'Name',
            key: 'name',
            width: 300,
            ellipsis: true,
            render: (text: any, record: any, index: number) => {
                const findName = _.find(props.permission.results, (item: TypePermission) => item.id === record)
                return <LongTextTd text={`${_.get(findName, "name", "")} <${_.get(findName, "id", record)}>`} textDisplay={_.get(findName, "name", record)} />
            },
        }, {
            title: 'Category',
            key: 'category',
            width: 150,
            ellipsis: true,
            render: (text: any, record: any, index: number) => {
                const findName = _.find(props.permission.results, (item: TypePermission) => item.id === record)
                return <LongTextTd text={findName ? findName.category : "--/--"} />
            },
        }, {
            title: <Icon type='delete' />,
            key: "action",
            fixed: "right",
            align: "center",
            render: (text: any, record: any, index: number) => {
                return (
                    <Tooltip placement='topLeft' title={"Delete"}>
                        <Button size='small' type='link' icon='delete' onClick={() => {
                            const newData = update(permissions, {
                                $splice: [[index, 1]]
                            })
                            const formsDaraUni = _.compact(_.uniq(newData))
                            setPermissions(formsDaraUni)
                        }}></Button>
                    </Tooltip>
                )
            }
        },
    ]



    const onSave = () => {
        validateFields(async (err: any, values: any) => {
            if (err) {
                return;
            }
            setLoading(true)
            if (props.item && props.item.id) {
                try {
                    const result = props.updatePermissionGroup(props.item.id, {
                        name: values.name,
                        permissions: permissions,
                        description: values.description
                    })
                    const response = await result
                    if (response.success) {
                        notification.success({
                            message: BIGDATA_CONSTANT.message.default.title.success,
                            description: BIGDATA_CONSTANT.message.notification.success.update,
                            icon: <Icon type="check-circle" style={{ color: getPrimaryColor(uiSetting) }} />
                        })
                        onClose()
                        resetFields()
                        onSuccess()
                        setLoading(false)
                    } else {
                        notification.error({
                            message: BIGDATA_CONSTANT.message.default.title.error,
                            description: _.get(response, "message", "Something went wrong"),
                        })
                        setLoading(false)
                    }
                } catch (error) {
                    notification.error({
                        message: BIGDATA_CONSTANT.message.default.title.error,
                        description: _.get(error, "message", "Something went wrong"),
                    })
                    setLoading(false)
                }
            } else {
                const result = props.createPermissionGroup({
                    name: values.name,
                    permissions: permissions,
                    description: values.description
                })
                try {
                    const response = await result
                    if (response.success) {
                        notification.success({
                            message: BIGDATA_CONSTANT.message.default.title.success,
                            description: "Assigned Permission successfully.",
                            icon: <Icon type="check-circle" style={{ color: getPrimaryColor(uiSetting) }} />
                        })
                        onClose()
                        resetFields()
                        onSuccess()
                        setLoading(false)
                    } else {
                        notification.error({
                            message: BIGDATA_CONSTANT.message.default.title.error,
                            description: _.get(response, "message", "Something went wrong"),
                        })
                        setLoading(false)
                    }
                } catch (error) {
                    notification.error({
                        message: BIGDATA_CONSTANT.message.default.title.error,
                        description: _.get(error, "message", "Something went wrong"),
                    })
                    setLoading(false)
                }
            }
        })
    }

    React.useEffect(() => {
        if (!visible) {
            resetFields()
            setPermissions([])
        }
    }, [visible])

    React.useEffect(() => {
        if (visible && props.item && props.item.permissions) {
            setPermissions(props.item.permissions)
        }
    }, [props.item, visible])

    return (
        <Drawer
            title={<>
                <Button icon="left-circle" size="small" type="link" onClick={() => {
                    onClose()
                }}></Button>
                {props.item && props.item.id ? "UPDATE PERMISSION GROUP" : "ADD PERMISSION GROUP"}
            </>}
            width={600}
            onClose={onClose}
            visible={visible}
            bodyStyle={{ paddingBottom: 80 }}
            maskClosable={false}
        >
            <Form layout="vertical" colon={false} hideRequiredMark={true}>

                <Row gutter={[5, 5]} type='flex'>

                    <Col xs={24}>
                        <O2OFormItem label="Name">
                            {getFieldDecorator('name', {
                                rules: [{ required: true, message: 'Please enter permission name' }],
                                initialValue: props.item ? props.item.name : undefined
                            })(<Input placeholder="Group name" />)}
                        </O2OFormItem>
                    </Col>


                    <Col xs={24}>
                        <O2OFormItem label="Description">
                            {getFieldDecorator('description', {
                                initialValue: props.item ? props.item.description : undefined
                            })(<Input placeholder="More information" />)}
                        </O2OFormItem>
                    </Col>

                    <Col xs={24}>
                        <O2OFormItem>
                            {getFieldDecorator('permissions', {
                                initialValue: permissions
                            })(
                                <Select style={{ display: "none" }} />
                            )}
                        </O2OFormItem>
                    </Col>
                    <Col xs={24}>
                        <SuggestionPermissionGroup
                            commonActionFromDALV2={props.commonActionFromDALV2}
                            form={props.form}
                            keyField={"permissionsTemp"}
                            label="Permissions"
                            placeholder="Select one"
                            onSelect={(e: string) => {
                                const newData = update(permissions, {
                                    $push: [e]
                                })
                                const formsDaraUni = _.compact(_.uniq(newData))
                                setPermissions(formsDaraUni)
                                setFieldsValue({
                                    permissionsTemp: undefined,
                                    permissions: permissions
                                })
                            }}
                            required={permissions && permissions.length > 0 ? false : true}
                            visible={props.visible}
                            permissions={permissions}
                            mode='multiple'
                        />
                    </Col>

                    <Col xs={24}>
                        <O2OFormItem label={`Selected ${permissions && permissions.length} permission`}>
                            <Table
                                size="small"
                                columns={columns}
                                dataSource={permissions}
                                pagination={false}
                            />
                        </O2OFormItem>
                    </Col>

                </Row>

            </Form>
            <div
                style={{
                    position: 'absolute',
                    right: 0,
                    bottom: 0,
                    width: '100%',
                    borderTop: '1px solid #e9e9e9',
                    padding: '10px 16px',
                    background: '#fff',
                    textAlign: 'right',
                    zIndex: 999999,
                }}
            >
                <Button onClick={onClose} style={{ marginRight: 5 }}>
                    Cancel
                </Button>

                <Button onClick={onSave} type="primary" loading={loading}>
                    {`${props.item && props.item.id ? "Update" : "Add"}`}
                </Button>

            </div>
        </Drawer >
    )
})

export default DrawerCreatePermissionGroup