import { FormComponentProps } from 'antd/lib/form'
import { Button, Col, Divider, Empty, Form, Icon, Input, InputNumber, notification, Popconfirm, Row, Select, Spin, Tooltip, Typography } from "antd"
import * as React from 'react'
import _, { get } from 'lodash'
import { O2OFormItem } from '../../../../shares/O2OFormItem'
import { SettingDAL } from '../SettingDAL'
import { CommonNotificationSaveError, CommonNotificationUpdateSuccessfully } from '../../../../shares/CommonNotification'
import { useDispatch } from '../../../../redux/store'
import { IActionUI, SHOW_COMMON_ERROR } from '../../../../redux/reducer/uiReducer'
import { QuotaSettings, TypeQuotaSetting } from '../models'
import { BIGDATA_CONSTANT } from '../../../../common/constant'
import { WrappedFormUtils } from 'antd/lib/form/Form'
import update from 'react-addons-update'

interface TypeSubItemForm {
    data: string
    lasItemLength: number
    indexColumn: number
    subData: string[]
    form: WrappedFormUtils<any>
    onAdd: any
    onDelete: any
    onChange?: any
}
const SubCategogyItemForm = (props: TypeSubItemForm) => {
    const [data, setData] = React.useState<string[]>([])

    const onChangeSelect = (value: string) => {
        props.onChange({
            sub: value,
            number: props.form.getFieldValue(`number[${props.indexColumn}]`)
        })
    }
    const onChangeInputNumber = (value?: number) => {
        props.onChange({
            number: value,
            sub: props.form.getFieldValue(`sub[${props.indexColumn}]`)
        })
    }

    React.useEffect(() => {
        if (props.data) {
            const splitData = props.data.split("=")
            setData(splitData)
        }
    }, [props.data])

    return (
        <Row gutter={[10, 10]} type="flex">
            <Col xs={10} md={10} sm={10} xl={10} xxl={6}>
                <O2OFormItem >
                    {props.form.getFieldDecorator(`sub[${props.indexColumn}]`, {
                        rules: [{ required: true, message: "Required" }],
                        initialValue: data && data.length === 2 && data[0] !== "" ? data[0] : undefined
                    })(
                        <Select
                            allowClear
                            showArrow={true}
                            placeholder='Select a model'
                            optionFilterProp='children'
                            showSearch
                            filterOption={(input: any, option: any) => {
                                return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                            }}
                            onChange={(value: string) => {
                                onChangeSelect(value)
                            }}
                        >
                            {props.subData && props.subData.map((item: string) => (
                                <Select.Option key={item} value={item}>{item}</Select.Option>
                            ))}
                        </Select>
                    )}
                </O2OFormItem>
            </Col>

            <Col>
                <O2OFormItem>
                    {props.form.getFieldDecorator(`number[${props.indexColumn}]`, {
                        rules: [
                            {
                                validator(rule, value, callback, source, options) {
                                    if (value > props.form.getFieldValue("maxAllowed")) {
                                        return Promise.reject("Value should be less than or equal Max Tasks");
                                    } else if (value < 1) {
                                        return Promise.reject("Value should be more than 1");
                                    } else if (value === undefined) {
                                        return Promise.reject("Required");
                                    } else {
                                        return Promise.reject();
                                    }
                                },
                            }
                        ],
                        initialValue: data && data.length === 2 && data[1] !== "" ? +data[1] : undefined
                    })(
                        <InputNumber
                            placeholder='Please enter number'
                            min={1}
                            max={2000}
                            formatter={(value: string) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            style={{ width: "100%" }}
                            onChange={(e) => {
                                onChangeInputNumber(e)
                            }}
                        />

                    )}
                </O2OFormItem>
            </Col>

            <Col>
                <O2OFormItem>
                    <Row gutter={[5, 5]} type="flex" align='middle'>
                        <Col>
                            <Popconfirm
                                title='Are you sure?'
                                onConfirm={() => {
                                    props.onDelete();
                                }}>
                                <Button
                                    icon='delete'
                                    type='link'
                                    size='small'
                                />
                            </Popconfirm>
                        </Col>

                        <Col>
                            {props.indexColumn === props.lasItemLength - 1 ? (
                                <Button
                                    icon='plus-circle'
                                    size='small'
                                    type='link'
                                    onClick={() => {
                                        props.onAdd();
                                    }}
                                />
                            ) : null}
                        </Col>
                    </Row>
                </O2OFormItem>
            </Col>
        </Row>
    )
}


interface Iprops extends FormComponentProps {
    commonActionFromDALV2: any
    id?: string
    selectedMenu: string
}

const Task = Form.create<Iprops>({ name: "task" })((props: Iprops) => {
    const [loading, setLoading] = React.useState(false)
    const [dataColumn, setDataColumn] = React.useState([] as any | string[])
    const [data, setData] = React.useState(undefined as undefined | TypeQuotaSetting)
    const [subData, setSubData] = React.useState([])
    const [isDeleteItem, setIsDeleteItem] = React.useState(false);

    const dispatch = useDispatch()

    const loadDataTask = async () => {
        setLoading(true)
        const result: Promise<QuotaSettings> = await props.commonActionFromDALV2(SettingDAL.getQuotaSupperAdmin, props.id, "Task")
        try {
            const response = await result
            if (response.success) {
                setData(response.result)
                const categories = []
                if (response.result && response.result.quotaByKeys) {
                    for (let p in response.result.quotaByKeys) {
                        categories.push(p + '=' + response.result.quotaByKeys[p])
                    }
                    setDataColumn(categories)
                }
                setLoading(false)
            } else {
                notification.error({
                    message: BIGDATA_CONSTANT.message.default.title.error,
                    description: _.get(response, "message", BIGDATA_CONSTANT.message.notification.fail)
                })
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            dispatch({
                type: SHOW_COMMON_ERROR,
                commonError: error,
            } as IActionUI);
        }
    }

    const loadDataSubCategory = async () => {
        const result = await props.commonActionFromDALV2(SettingDAL.getSubCategory)
        try {
            const payload = await result
            if (payload.success) {
                setSubData(payload.results)
            } else {
                dispatch({
                    type: SHOW_COMMON_ERROR,
                    commonError: payload,
                } as IActionUI);
            }
        } catch (error) {
            dispatch({
                type: SHOW_COMMON_ERROR,
                commonError: error,
            } as IActionUI);
        }
    }

    const handleUpdate = () => {
        props.form.validateFields(async (error: any, values: any) => {
            if (error) return
            setLoading(true)

            const valueConvertArrToObject = dataColumn.reduce((accumulator: any, value: string) => {
                if (value.indexOf("=") > -1) {
                    const valueAfterSplit = value.split("=")
                    const payloadValueNumber = () => {
                        if (values.maxAllowed >= +valueAfterSplit[1]) {
                            return +valueAfterSplit[1]
                        } else {
                            return values.maxAllowed
                        }

                    }
                    return { ...accumulator, [valueAfterSplit[0]]: payloadValueNumber() }
                }
                return {}
            }, {});


            const payload = {
                alertMessage: values.alertMessage2,
                category: "Task",
                maxAllowed: values.maxAllowed ? values.maxAllowed : 100,
                quotaByKeys: valueConvertArrToObject,
            }

            try {
                const result: Promise<any> = await props.commonActionFromDALV2(SettingDAL.updateQuotaLimit, props.id, "Task", payload)
                const response = await result

                if (response.success) {
                    setLoading(false)
                    CommonNotificationUpdateSuccessfully()
                    setData(response.result)

                } else {
                    setLoading(false)
                    CommonNotificationSaveError(get(response, "message", "Something went wrong!"))
                }
                props.form.validateFields((err: any, values: any) => {
                    const fields = Object.keys(values);
                    const fieldsExclueOrg = _.filter(fields, (item: string) => { return item != "organization" })
                    props.form.resetFields(fieldsExclueOrg)
                })

            } catch (error) {
                setLoading(false)
                dispatch({
                    type: SHOW_COMMON_ERROR,
                    commonError: error,
                } as IActionUI);
            }
        })
    }

    React.useEffect(() => {
        if (props.selectedMenu === "menu3") {
            loadDataTask()
            loadDataSubCategory()
        }
    }, [props.selectedMenu])


    React.useEffect(() => {
        if (isDeleteItem) {
            const subColumn = dataColumn.map((item: any, index: number) => {
                return `sub[${index}]`;
            });
            const numberColumn = dataColumn.map((item: any, index: number) => {
                return `number[${index}]`;
            });
            props.form.resetFields(subColumn.concat(numberColumn));
            setIsDeleteItem(false);
        }
    }, [isDeleteItem]);

    // const renderDefaultColumn = () => {
    //     if (data && data.quotaByKeys) {
    //         for (let p in data.quotaByKeys) {
    //             dataColumn.push(p + '=' + data.quotaByKeys[p])
    //         }
    //         return dataColumn;
    //     }
    //     return []
    // }

    const addNewMappingField = () => {
        const value = update(dataColumn, {
            $push: [""]
        })
        setDataColumn(value)
    }

    const renderAddColumn = () => {
        return dataColumn.length && dataColumn.map((item: any, index: number) => {
            return (
                <SubCategogyItemForm
                    data={item}
                    lasItemLength={dataColumn.length}
                    indexColumn={index}
                    subData={subData}
                    form={props.form}

                    onAdd={() => {
                        addNewMappingField()
                    }}

                    onDelete={() => {
                        const dataColumnAfterChange = update(dataColumn, {
                            $splice: [[index, 1]]
                        })
                        setIsDeleteItem(true);
                        setDataColumn(dataColumnAfterChange)
                    }}

                    onChange={(values: any) => {
                        const data = `${values.sub || ""}=${values.number || ""}`
                        const dataMappingArrayAfterChange = update(dataColumn, {
                            [index]: {
                                $set: data
                            }
                        })
                        setDataColumn(dataMappingArrayAfterChange)
                    }}
                />
            )
        })
    }
    return (
        <Spin spinning={loading}>
            <Form colon={true} hideRequiredMark={true}>
                <Row gutter={[10, 10]} type="flex">
                    <Col xs={6}>
                        <O2OFormItem help="" label={
                            <span>
                                Max Tasks
                                <Tooltip title="Maximum of tasks are allowed (Min: 1, Max: 2,000)">
                                    <Icon style={{ marginLeft: 5 }} type="question-circle" />
                                </Tooltip>
                            </span>
                        }>
                            {props.form.getFieldDecorator("maxAllowed", {
                                initialValue: data ? data.maxAllowed : 100,
                                rules: [{ required: true }],
                            })(
                                <InputNumber
                                    placeholder='Please enter number'
                                    min={1}
                                    max={2000}
                                    formatter={(value: string) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    style={{ width: "100%" }}
                                >
                                </InputNumber>
                            )}
                        </O2OFormItem>
                    </Col>

                    <Col xs={18}>
                        <O2OFormItem help="" label="Alert Messages">
                            {props.form.getFieldDecorator("alertMessage2", {
                                rules: [{ required: true }],
                                initialValue: _.get(data, "alertMessage", "Reached the maximum of allowed tasks.")
                            })(
                                <Input placeholder='Please enter message'></Input>
                            )}
                        </O2OFormItem>
                    </Col>

                    <Col xs={24}>
                        <O2OFormItem>
                            <Row type='flex' gutter={[5, 5]} style={{ width: "100%", borderBottom: "1px solid #e8e8e8", margin: "10px 10px 10px 0" }} align='middle' justify='start'>
                                <Col>
                                    <Typography.Text strong>Quota by Category</Typography.Text>
                                    <Divider type='vertical' />
                                </Col>
                                <Col>
                                    <Button
                                        type='link'
                                        style={{ marginLeft: -20 }}
                                        icon='plus-circle'
                                        onClick={() => {
                                            addNewMappingField()
                                        }}>
                                    </Button>
                                </Col>

                            </Row>
                        </O2OFormItem>

                        <O2OFormItem >
                            {dataColumn && dataColumn.length > 0 ? renderAddColumn() : <Empty
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                description={
                                    <Button
                                        icon='plus'
                                        type='link'
                                        onClick={() => {
                                            addNewMappingField()
                                        }}
                                    >
                                        Add column
                                    </Button>
                                }
                            />
                            }
                        </O2OFormItem>
                    </Col>


                    <Col xs={24}>
                        <Button loading={loading} type='primary' onClick={handleUpdate}>Update</Button>
                    </Col>

                </Row>
            </Form>
        </Spin>

    )
})

export default Task