// api/custom-fields/objects

import { IActionGeneric } from "../../common/IActionGeneric";
export const GET_ALL_CUSTOM_FIELD_OBJECTS = "GET_ALL_CUSTOM_FIELD_OBJECTS"

export type TypeObjectModel = {
  "id": string,
  "orgId": string,
  "code": string,
  "name": string,
  "order": 0,
  "type": "System" | "Custom",
  category: string,
  ctxSupportExtraction: boolean
  "createdAt": number,
  "createdBy": string,
  "updatedAt": number,
  "updatedBy": string,
  modelGroupId: string 
  modelGroupName: string
}

export class ObjectModels {
  success: boolean
  results: {[key: string]: TypeObjectModel }
  constructor() {
    this.success = false
    this.results = {}
  }
}

const empty = (): ObjectModels => {
  return new ObjectModels()
};

export interface IActionGetAllCutomFieldObject extends IActionGeneric<ObjectModels> { }

export const customFieldObjectsReducer = (state = empty(), action: IActionGetAllCutomFieldObject) => {
  switch (action.type) {
    case GET_ALL_CUSTOM_FIELD_OBJECTS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
