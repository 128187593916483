import * as React from "react"
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Button, Form, notification, Row, Col, Icon } from 'antd';
import { TypeTermOfOrg, User } from 'o2o_layout/lib/common/model';
import { ConsoleUsersDAL } from "../ConsoleUserDAL";
import _ from "lodash";
import { FormComponentProps } from 'antd/lib/form';
import { BIGDATA_CONSTANT } from "../../../common/constant";
import { getPrimaryColor } from "../../../common/helper";
import { useSelector } from "../../../redux/store";
import { IState } from "../../../redux/reducer";

interface IProps extends FormComponentProps {
    visible: boolean
    onClose: any
    term: TypeTermOfOrg | undefined
    commonActionFromDAL: any,
    success: any
    user: User
}

export const DrawerTerm = Form.create<IProps>()((props: IProps) => {
    const [value, setValue] = React.useState('');
    const [loading, setLoading] = React.useState(false);

    const uiSetting = useSelector((state: IState) => state.uiSetting)

    React.useEffect(() => {
        if (props.term) {
            const term = `<div><h1>Legal Notice</h1><div>This information asset and information system is the property of ${props.user.orgCtx ? props.user.orgCtx.organization.name : ""}.</div><div>The use of this system is governed by IT Policies under OGR.</div><div>It must be used responsibly and for authorized purposes only.</div><p>Unauthorized or improper use of this system may result in disciplinary action and/or civil charges/criminal penalties and/or prosecution of law. By continuing to use this system you indicated your awareness and consent to these terms and conditions of use.</div></div>`
            setValue(props.term ? props.term.term : term)
        }
    }, [props.term]);

    const save = async (dto: any) => {
        try {
            const result: Promise<any> = await props.commonActionFromDAL(ConsoleUsersDAL, "saveTerm", dto)

            const payload = await result
            return payload
        } catch (error) {
            return { success: false, message: "Something was wrong." }
        }
    }

    return (
        <Form layout="horizontal">
            <Row type="flex" gutter={[10, 10]}>
                <Col xs={24}>
                    <Form.Item>
                        <ReactQuill theme="snow" style={{ height: 500 }} value={value} onChange={setValue} />
                    </Form.Item>
                </Col>
                <Col xs={24} style={{marginTop: 60}}>
                    <Form.Item>
                        <Button loading={loading} type="primary" onClick={async () => {
                            if (value) {
                                setLoading(true)
                                const result = await save({
                                    term: value,
                                    active: props.term ? props.term.active : false
                                })
                                setLoading(false)
                                if (result && result.success) {
                                    // notification.success({
                                    //     message: "Success",
                                    //     description: "Update successfully."
                                    // })
                                    notification.success({
                                        message: BIGDATA_CONSTANT.message.default.title.success,
                                        description: BIGDATA_CONSTANT.message.notification.success.update,
                                        icon: <Icon type="check-circle" style={{ color: getPrimaryColor(uiSetting) }} />
                                    })
                                    setTimeout(() => {
                                        props.onClose()
                                        props.success()
                                    }, 1000);
                                } else {
                                    notification.error({
                                        message: "Error",
                                        description: _.get(result, "message", "Something was wrong.")
                                    })
                                }
                            }
                        }}>Save</Button>
                    </Form.Item>
                </Col>
            </Row>



        </Form>

    )
})