import { Button, Card, Form, Popconfirm, notification } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { JsonEditor as Editor } from 'jsoneditor-react';
import React, { useState } from "react";
import { BIGDATA_CONSTANT } from '../../../common/constant';
import { IActionUI, SHOW_COMMON_ERROR } from '../../../redux/reducer/uiReducer';
import { useDispatch } from '../../../redux/store';
import { SystemDAL } from '../SystemDAL';

interface IFormAddPangoTemplateProps extends FormComponentProps {
    commonActionFromDALV2: any
}

export const FormAddPangoTemplate = Form.create<IFormAddPangoTemplateProps>()(
    ({ commonActionFromDALV2, form }: IFormAddPangoTemplateProps) => {
        const [value,] = useState({})
        const editor = React.useRef(null as any);
        const [loading, setLoading] = useState(false)

        const dispatch = useDispatch()

        const onAddPangoTemplate = async () => {
            setLoading(true)
            const result = commonActionFromDALV2(SystemDAL.addPangoTemplate, form.getFieldValue("json"))
            try {
                const response = await result
                if (response.success) {
                    setLoading(false)
                    notification.success({
                        message: BIGDATA_CONSTANT.message.default.title.success,
                        description: BIGDATA_CONSTANT.message.notification.success.saved,
                    })
                } else {
                    setLoading(false)
                    dispatch({
                        type: SHOW_COMMON_ERROR,
                        commonError: response
                    } as IActionUI)
                }
            } catch (error) {
                setLoading(false)
                dispatch({
                    type: SHOW_COMMON_ERROR,
                    commonError: error
                } as IActionUI)
            }
        }

        return <Card
            headStyle={{ fontSize: 18 }}
            title={"Add Pango Template"}
            size="small">
            {form.getFieldDecorator("json", {

            })}
            <div style={{ width: "100%", height: 500, overflow: "auto", marginBottom: 8 }}>
                <Editor
                    ref={editor}
                    onChange={(e: any) => {
                        form.setFieldsValue({
                            json: e
                        })
                    }}

                    htmlElementProps={{ style: { height: "100%" } }}
                    height={700}
                    mode={"code"}
                    allowedModes={['view', 'preview', 'code']}
                    value={value}
                />
            </div>
            <Button onClick={() => editor.current.jsonEditor.set({})} style={{ marginRight: 8 }}>Clear</Button>
            <Popconfirm title="Are you sure?" onConfirm={onAddPangoTemplate}>
                <Button loading={loading} type="primary">Save</Button>
            </Popconfirm>
        </Card>
    }
)
