
import { bindActionCreators } from "redux"
import { getAllCustomFieldObjects } from "../../../../common/actions/CommonAction"
import { O2OCommonContainer } from "../../../../common/container/O2OContainer"
import { IState } from "../../../../redux/reducer"
import ExportActivityComponent from "./ExportActivityComponent"

const mapStateToProps = (state: IState) => {
    return {
        allMom: state.customFieldObjects
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        getAllCustomFieldObjects: bindActionCreators(getAllCustomFieldObjects, dispatch)
    }
}

export const ExportActivityContainer = O2OCommonContainer(ExportActivityComponent, mapStateToProps, mapDispatchToProps)