import * as React from 'react';
import { Typography, Row } from 'antd';
import Helmet from 'react-helmet';
import CommonTypeProps from '../../../common/CommonTypeProps';
import { LandingPage } from '../../../layout/landing/LandingPage';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Location, History } from 'history';
import queryString from 'query-string'
import { TypeLangs } from '../../../redux/reducer/localeReducer';
import { typeCurrentUserOrgs } from '../../../redux/reducer/selectCurrentOrgReducer';
// import { FooterLang } from '../../../shares/FooterLang';
import { Footer } from '../../../shares/Footer';

interface EmptyCompanyComponentProps extends CommonTypeProps {
    location: Location,
    history: History,
    getCurrentUserOrgs: any,
    currentUserOrgs: typeCurrentUserOrgs,

}

export class EmptyCompanyComponent extends React.Component<EmptyCompanyComponentProps, any> {

    componentDidMount() {
        this.updateLang()
        this.props.getCurrentUserOrgs()
            .then(() => {
                if (this.props.currentUserOrgs.orgs.results.length) {
                    this.props.history.push({
                        pathname: "/companies/select",
                        search: queryString.stringify({
                            lang: queryString.parse(this.props.location.search).lang
                        })
                    })
                }
            })
    }

    updateLang = () => {
        let qString = this.props.location.search;
        const parseQuery = queryString.parse(qString)

        if (parseQuery.lang) {
            // debugger
            const lang: TypeLangs = parseQuery.lang == 'vi' || parseQuery.lang == 'en' ? parseQuery.lang : 'vi';
            this.props.updateLocale(lang)
        }
    }

    successChangeLang = (key: string) => {
        let qString = this.props.location.search;
        const parseQuery = queryString.parse(qString)
        parseQuery.lang = key;
        qString = queryString.stringify(parseQuery);
        this.props.history.push({ pathname: this.props.location.pathname, search: qString })
    }

    renderTheme() {
        let cretateUrl = {
            pathname: "/companies/form",
            search: ""
        };
        let loginUrl = {
            pathname: "/login",
            search: ""
        }
        const qString = this.props.location.search;
        const parseQuery = queryString.parse(qString)

        if (parseQuery.target) {
            cretateUrl.search = queryString.stringify(parseQuery)
            loginUrl.search = queryString.stringify(parseQuery)
        }

        return (
            <div>
                <div >
                    <LandingPage {...this.props}>

                        <Helmet>
                            <title>Empty Company</title>
                            <meta name="description" content="" />
                        </Helmet>

                        <Typography.Title className="o2o-title-gray " level={1}>
                            <FormattedMessage id="app.organization.select" />
                        </Typography.Title>
                        <span className="p-company">
                            <FormattedMessage id="app.company.create.intro" defaultMessage="" />
                        </span>
                        <br />

                        <Row>
                            <Typography.Text><Link to={loginUrl}>
                                <strong><FormattedMessage id="app.login.different" /></strong>
                            </Link></Typography.Text>
                        </Row>

                        <br/>
                            <div className="landing-form-footer" >
                                {/* <FooterLang
                                    mainProps={this.props}
                                    onSuccess={this.successChangeLang}
                                /> */}
                            </div>


                    
                    </LandingPage>
                </div>
                <Footer/>
            </div>
        )

    }

    render() {
        return (
            this.renderTheme()
        );
    }
}

