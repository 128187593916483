import { Authen } from '../model/';
import { UPDATE_AUTHEN, CHECK_AUTHEN } from '../../common/actionType/actionType';

const createEmptyAuthen = (): Authen => ({
    isAuth: true,
    permission: {},
    changePassword: false,
    changePasswordReason: null
});

export interface IActionAuthen {
    type: string,
    payload: Authen
}

export const authenReducer = (state = createEmptyAuthen(), action: IActionAuthen) => {
    switch (action.type) {
        case UPDATE_AUTHEN:
            return { ...state, ...action.payload }
        case CHECK_AUTHEN:
            return { ...state, ...action.payload }
        default:
            return state;
    }
};