import * as React from 'react';
import { Breadcrumb, Row, Icon, Col, Form, notification } from 'antd';
import { Link } from 'react-router-dom';
import CommonTypeProps from '../../../common/CommonTypeProps';
import { DashboardPageLayout } from '../../../layout/dashboard/DashboardPageLayout';
import { FormComponentProps } from 'antd/lib/form';
import { typeUpdateAcount } from '../ConsoleUsersAction';
import { typeUpdateOrg } from '../../orgs/OrgsAction';
import _ from 'lodash';
import { TypeEDMSendInBlue } from '../../../redux/reducer/edmSendInBlueReducer';
import { ChangePassword } from '../profile/ChangePassword';
import { BIGDATA_CONSTANT } from '../../../common/constant';
import { getCurrentURL, getPrimaryColor } from '../../../common/helper';
import { MetaHeader } from '../../../shares/MetaHeader';


interface Props extends CommonTypeProps, FormComponentProps {
    updateAccount: typeUpdateAcount,
    updateOrgByAdminClient: typeUpdateOrg,
    changePwd: any,
    edmSendInBlue: TypeEDMSendInBlue,
    updateEDMSendInBlue: any,

}
export class UserPwd extends React.Component<Props, any> {
    formRef: any
    formRefInfo: any
    formRefEDM: any
    formRefChangePassword: any

    state = {
        confirmDirty: false,
        visibleModalUpdateOrg: false
    };

    componentDidMount = () => {
        this.props.updateMetaTitle({ title: BIGDATA_CONSTANT.trackTitle.ChangePW })
        this.timer = setTimeout(() => {
            this.props.updateMeta({
                name: BIGDATA_CONSTANT.trackTitle.ChangePW,
                title: BIGDATA_CONSTANT.trackTitle.ChangePW,
                app: "ACCOUNT",
                url: getCurrentURL(this.props.location)
            })
            clearTimeout(this.timer)
        }, 5000)
    }
    timer: any

    componentWillUnmount() {
        clearTimeout(this.timer)
    }


    checkCurrentUserIsSuperAdmin = (): boolean => {
        if (this.props.user) {
            if (this.props.user.orgCtx) {
                let roles = this.props.user.orgCtx.roles;
                let isSP = _.find(roles, (e) => {
                    return e == "SUPER_ADMIN"
                });

                if (isSP) {
                    return true
                }
                return false
            }
            return false
        }
        return false;
    }

    renderBreadCrumb = () => {
        return (
            <Row type="flex" justify="space-between" align="middle" style={{ marginTop: 20 }}>
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/"><Icon type="home" /></Link>
                    </Breadcrumb.Item>

                    <Breadcrumb.Item>Change Password</Breadcrumb.Item>
                </Breadcrumb>

            </Row>

        )
    }

    submitUpdateOrg = (e: any) => {
        const form = this.formRef.props.form;

        form.validateFields((err: any, values: any) => {
            if (err) {
                return;
            }

            this.props.setGlobalLoading(true)
            let isSAdmin = this.checkCurrentUserIsSuperAdmin()
            values.id = this.props.user.orgCtx ? this.props.user.orgCtx.id : null

            this.props.updateOrgByAdminClient(values, isSAdmin)
                .then(() => {
                    if (this.props.commonResponse.success) {
                        this.props.setGlobalLoading(false)
                        this.setState({ visibleModalUpdateOrg: false })
                        notification.success({
                            message: BIGDATA_CONSTANT.message.default.title.success,
                            description: `Organization ${this.props.user.orgCtx ? this.props.user.orgCtx.organization.name : ""} updated successfully`,
                            icon: <Icon type="check-circle" style={{ color: getPrimaryColor(this.props.uiSetting) }} />
                        })
                        setTimeout(() => {
                            window.location.reload()
                        }, 200)
                    } else {
                        this.props.setGlobalLoading(false)
                        notification.error({
                            message: BIGDATA_CONSTANT.message.default.title.error,
                            description: this.props.commonResponse.message,
                        })
                        this.setState({ visibleModalUpdateOrg: false })
                    }


                })
                .catch((e: any) => {

                    this.props.setGlobalLoading(false)
                    notification.error({
                        message: BIGDATA_CONSTANT.message.default.title.error,
                        description: _.get(e.data, "message", "Something went wrong"),
                    })
                    this.setState({ visibleModalUpdateOrg: false })
                })
        })


    }

    submitPwd = (e: any) => {
        e.preventDefault();
        this.formRefChangePassword.props.form.validateFieldsAndScroll((err: any, values: any) => {
            if (!err) {
                this.props.setGlobalLoading(true)
                this.props.changePwd(values.current_password, values.password, values.confirm)
                    .then(() => {
                        this.props.setGlobalLoading(false)
                        this.formRefChangePassword.props.form.resetFields()
                        notification.success({
                            message: BIGDATA_CONSTANT.message.default.title.success,
                            description: `Change password successfuly`,
                            icon: <Icon type="check-circle" style={{ color: getPrimaryColor(this.props.uiSetting) }} />
                        })
                    })
            }

        });
    }



    submitUpdateInfo = (e: any) => {
        e.preventDefault();
        this.formRefInfo.props.form.validateFieldsAndScroll((err: any, values: any) => {
            if (!err) {
                let user: any = Object.assign(values, {
                    email: values.id,
                    status: 'active'
                })

                let isSPA = this.checkCurrentUserIsSuperAdmin()
                let promise: any = this.props.updateAccount(user, isSPA)
                this.props.setGlobalLoading(true)
                promise
                    .then(() => {

                        if (this.props.commonResponse.success) {

                            notification.success({
                                message: BIGDATA_CONSTANT.message.default.title.success,
                                description: `User ${this.props.user.id} updated successfully`,
                                icon: <Icon type="check-circle" style={{ color: getPrimaryColor(this.props.uiSetting) }} />
                            })


                            this.props.setGlobalLoading(false)


                        } else {
                            notification.error({
                                message: BIGDATA_CONSTANT.message.default.title.error,
                                description: _.get(this.props.commonResponse, "message", "Something went wrong"),
                            })
                            this.props.setGlobalLoading(false)
                        }

                    })
                    .catch((error: any) => {
                        notification.error({
                            message: BIGDATA_CONSTANT.message.default.title.error,
                            description: _.get(error.data, "message", "Something went wrong"),
                        })
                        this.props.setGlobalLoading(false)
                    })

            }

        });
    }




    renderTheme() {


        return (
            <DashboardPageLayout  {...this.props} selectedLeftNav="change-pwd" selectedSubMenu="my-account" >
                <div className="org-page">

                    <MetaHeader title={this.props.ui.meta.title} />

                    <div className="contentLayout">
                        {this.renderBreadCrumb()}
                        <br />
                        <div className="containerInner">

                            <Row>
                                <Col xs={24}>

                                    <Col xs={24} lg={12}>
                                        <ChangePassword
                                            loading={this.props.ui.isLoading}
                                            locale={this.props.locale}
                                            orgCtx={this.props.user.orgCtx}
                                            wrappedComponentRef={(propsRef: any) => this.formRefChangePassword = propsRef}
                                            changePwd={this.props.changePwd}
                                            user={this.props.user}
                                            commonActionFromDAL={this.props.commonActionFromDAL}
                                            history={this.props.history}
                                        />
                                    </Col>


                                </Col>
                            </Row>

                        </div>
                    </div>

                </div>
            </DashboardPageLayout>
        )
    }

    render() {
        return (
            this.renderTheme()
        );
    }
}

export const UserPwdComponent = Form.create<Props>({ name: 'profile_form' })(UserPwd)