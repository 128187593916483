import { Button, Drawer } from 'antd'
import _ from 'lodash'
import React from 'react'
import ReactJson from 'react-json-view'

interface IDrawerViewJSONProps {
    visible: boolean
    onClose: () => void
    data: any
    title?: string
    maskClosable?: boolean
}

const DrawerViewJSON = (props: IDrawerViewJSONProps) => {
    return (
        <Drawer
            bodyStyle={{ marginBottom: 50, wordBreak: 'break-word' }}
            title={props.title ? _.upperCase(props.title) : "VIEW JSON"}
            visible={props.visible}
            onClose={() => {
                props.onClose()
            }}
            maskClosable={props.maskClosable || true}
            width={600}
        >
            <ReactJson name={null} displayObjectSize={false} displayDataTypes={false} src={props.data || {}} />
            <div
                style={{
                    zIndex: 999999,
                    position: 'absolute',
                    right: 0,
                    bottom: 0,
                    width: '100%',
                    borderTop: '1px solid #e9e9e9',
                    padding: '10px 16px',
                    background: '#fff',
                    textAlign: 'right',
                }}
            >
                <Button onClick={props.onClose}>Close</Button>
            </div>
        </Drawer>
    )
}

export default DrawerViewJSON
