import { IResponseBigDataVN, TypeResultsBigData } from "../../../common/IResponseBigData";

export type TypeLogsTicketCriteria = {
    freeText?: string;
    orderBy?: string;
    fromTime?: number;
    toTime?: number;
    status?: string[];
    ticketIds?: string[];
    ticketNos?: string[];
    topics?: string[];

    page: number;
    pageSize: number;
};

export type TypeCallInfo = {
    answerTime: number
    callId: string
    called: string
    caller: string
    endTime: number
    startTime: number
    statusCode: string
}

export type TypeComment = {
    additionDetails: string
    comment: string
    commentatorId: number
    createdAt: number
    isPublic: number
    source: string
    ticketId: number
    type: number
    updatedAt: number
}

export type TypeCustomField = {
    field: string
    id: number
    label: string
    type: number
    value: string
    valueId: number
}

export type TypeLogsTicketItem = {
    assigneeEmail: string
    assigneeId: number
    assigneePhone: string
    callInfo: TypeCallInfo
    comment: TypeComment[]
    createdAt: number
    createdBy: string
    customFields: TypeCustomField[]
    description: string
    email: string
    id: string
    orgId: string
    phone: string
    public: boolean
    requestId: number
    serviceId: number
    status: string
    subject: string
    ticketId: number
    ticketNo: number
    ticketPriority: string
    topic: string
    updatedAt: number
    updatedBy: string
}

export class ManageLogsTicket implements IResponseBigDataVN<TypeLogsTicketItem> {
    success: boolean;
    results: TypeResultsBigData<TypeLogsTicketItem>;
    constructor() {
        this.success = false;
        this.results = {
            content: [],
            currentTime: 0,
            extras: null,
            page: {
                pageSize: 0,
                currentPage: 0,
                totalRecords: 0,
                totalPages: 0,
            },
            timeTaken: 0,
        };
    }
}