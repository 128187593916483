import { IState } from "../../../redux/reducer";

import { O2OCommonContainer } from "../../../common/container/O2OContainer";
import { LinkOrgsComponent } from './LinkOrgsComponent';
import { bindActionCreators } from "redux";
import { getOrgs } from "../OrgsAction";
import { linkOrg, searchLinkOrgs, deleteLinkOrgs } from './action/LinkOrgsAction';


function mapStateToProps(rootState: IState) {
    return {  
        organizations: rootState.organizations,
        commonResponse: rootState.commonResponse,
        linkOrgs: rootState.linkOrgs
    };
}


function mapDispatchToProps(dispatch: any) {
    return {
        getOrgs: bindActionCreators(getOrgs, dispatch),
        linkOrg: bindActionCreators(linkOrg, dispatch),
        searchLinkOrgs: bindActionCreators(searchLinkOrgs, dispatch),
        deleteLinkOrgs: bindActionCreators(deleteLinkOrgs, dispatch)
    };
}

export const LinkOrgsContainer = O2OCommonContainer(LinkOrgsComponent ,mapStateToProps, mapDispatchToProps)