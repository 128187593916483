import { Methods, RestClient } from "o2o_layout/lib/common/rest";
import { BIGDATA_CONSTANT } from "../../../../common/constant"
import { toQuery } from "../../../../common/helper";
import { head, isNil, omitBy } from "lodash";

class DAL {
    search(body: any, isSpAdmin?: boolean) {
        const query = toQuery(omitBy({
            page: body.page || 0,
            pageSize: body.pageSize || 10,
        }, isNil))
        let url = ''
        if (isSpAdmin) {
            url = BIGDATA_CONSTANT.url.api + `mngmnt/account-logs/search/_by_user?${query}&userId=${head(body.userIds)}`;       
        } else {
            url = BIGDATA_CONSTANT.url.api + `mngmnt-client/account-logs/search?${query}`;
        }
        var restClient = new RestClient(Methods.POST, url);
        return new Promise((resolve, reject) => {
            restClient.execute({}, body).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }
}

export const AccountLogsDAL = new DAL()