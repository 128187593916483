import { Result, Button } from 'antd';
import * as React from 'react';
import { Link } from 'react-router-dom';
import img404 from 'o2o_layout/lib/statics/images/404.svg'



interface Props {

}
export class Component404 extends React.Component<Props, any> {

    state = {

    }

    componentDidMount() {

    }


    renderTheme() {

        return (
            <Result
                icon={
                    <img src={img404} />
                }
                title="404"
                subTitle="Sorry, the page you visited does not exist."
                extra={<Button type="primary"><Link to={{pathname: "/"}}>Back Home</Link></Button>}
            />
        )
    }

    render() {
        return (
            <React.Fragment>
                {this.renderTheme()}
            </React.Fragment>

        );
    }
}

