import { Badge, Breadcrumb, Button, Col, Descriptions, Divider, Drawer, Dropdown, Form, Icon, Input, Menu, Popconfirm, Popover, Row, Select, Spin, Table, Tag, Tooltip, Typography, message, notification } from 'antd';
import * as React from 'react';
import { Link } from 'react-router-dom';
import CommonTypeProps from '../../common/CommonTypeProps';

import { DashboardPageLayout } from '../../layout/dashboard/DashboardPageLayout';

import { PaginationProps } from 'antd/lib/pagination';
import { ColumnProps } from 'antd/lib/table';
import { FormattedDate } from 'react-intl';
import { CanAccess } from '../../shares/CanAccess';

import { FormComponentProps } from 'antd/lib/form';
import { ClickParam } from 'antd/lib/menu';
import _, { debounce, find, get, isArray, isEmpty, map, size } from 'lodash';
import moment from 'moment';
import { BIGDATA_USER_REF, LongTextTd, O2OCustomPaging, PANGO_PERMISSIONS, canAccess } from 'o2o_layout';
import { ConsoleUser, ConsoleUsers, Organizations, TypeConsoleUser, TypeOrganization, User } from 'o2o_layout/lib/common/model';
import { Methods } from 'o2o_layout/lib/common/rest';
import update from 'react-addons-update';
import { SelectColumns } from '../../common/SelectColumns';
import { BIGDATA_CONSTANT } from '../../common/constant';
import { checkPermission, getCurrentURL, getPrimaryColor } from '../../common/helper';
import { CommonResponse } from '../../redux/model/commonResponse';
import ButtonBackDrawer from '../../shares/ButtonBackDrawer';
import { DrawerExportDataWithout2FA } from '../../shares/DrawerExportData';
import DrawerViewJSON from '../../shares/DrawerViewJSON';
import { InfoList } from '../../shares/InfoList';
import { MetaHeader } from '../../shares/MetaHeader';
import { O2OFormItem } from '../../shares/O2OFormItem';
import { TagPrimaryColor } from '../../shares/TagPrimaryColor';
import { CommonDAL } from '../../shares/dal/CommonDAL';
import { typeGetOrgs } from '../orgs/OrgsAction';
import { typeCriteriaSearchOrgs } from '../orgs/OrgsDAL';
import { typeCriteriaSearchAccount } from './ConsoleUserDAL';
import { typeAddUsers, typeDeleteAccount, typeDetailAcount, typeGetAccounts, typeResetPasswordAccount, typeUpdateAcount } from './ConsoleUsersAction';
import { DrawerActionsLog } from './DrawerActionsLog';
import { DrawerAssignUserToPermissionGroup } from './DrawerAssignUserToPermissionGroup';
import { DrawerConsoleUserAdmin } from './DrawerConsoleUserAdmin';
import { DrawerFormResetPassword } from './DrawerResetPassword';
import { IGeneralSetting } from './company/UserCompanyComponent';
import DrawerRemoveAccount from './drawer/DrawerRemoveAccount';
import { DrawerInviteUser } from './invite/DrawerInviteUser';
import { PermissionDAL } from './permisions/dal/PermissionDAL';
import { AllPermissionGroups, PermissionPangoAct, Permissions, TypePermissionGroup } from './permisions/model/permissions';
import SuggestionPermisstionGroup from './share/SuggestionPermisstionGroup';
import numeral from 'numeral';

interface IFormFilterProps extends FormComponentProps {
    onSearch: any,
    user: User,
    searchOrg: any,
    organizations: Organizations,
    isLoading: boolean
    commonActionFromDALV2: any
    onExport: () => void
}
const FormFilterComponent = Form.create<IFormFilterProps>({
    name: 'filterform',

})(
    class FormFilterClass extends React.Component<IFormFilterProps, any> {
        onSearch = (e: any) => {
            e.preventDefault();

            this.props.form.validateFieldsAndScroll((err: any, values: any) => {
                if (!err) {
                    this.props.onSearch(values)
                }
            });
        }
        render() {
            const { getFieldDecorator } = this.props.form

            return (
                <Form colon={false} hideRequiredMark={true} layout="horizontal" title="Search">
                    <Row gutter={[5, 5]} type="flex" justify="start" align="bottom">

                        <Col md={24} lg={12} xl={7} xxl={6} style={{ flexGrow: 1 }}>
                            <O2OFormItem label="Info">
                                {getFieldDecorator("freeText")(
                                    <Input placeholder="Account info" onPressEnter={this.onSearch} />
                                )}
                            </O2OFormItem>
                        </Col>

                        <Col span={10} xs={24} sm={5} style={{ flexGrow: 1 }}>
                            <O2OFormItem label="Roles">
                                {getFieldDecorator("roles")(
                                    <Select mode="multiple" style={{ width: '100%' }} placeholder="Select(s)">
                                        <Select.Option key="ADMIN">Admin</Select.Option>
                                        <Select.Option key="API">Api</Select.Option>
                                        <Select.Option key="USER">User</Select.Option>
                                    </Select>
                                )}
                            </O2OFormItem>

                        </Col>
                        <Col xs={24} lg={4}>
                            <O2OFormItem label="Status" style={{ flexGrow: 1 }}>
                                {getFieldDecorator("status")(
                                    <Select mode="multiple" style={{ width: '100%' }} placeholder="Select(s)">
                                        <Select.Option key="active">Active</Select.Option>
                                        <Select.Option key="inactive">Inactive</Select.Option>
                                    </Select>
                                )}
                            </O2OFormItem>
                        </Col>
                        <Col span={10} xs={24} sm={5} style={{ flexGrow: 1 }}>
                            <SuggestionPermisstionGroup
                                keyField='permissionGroup'
                                form={this.props.form}
                                commonActionFromDALV2={this.props.commonActionFromDALV2}
                            />

                        </Col>
                        <Col>
                            <O2OFormItem>
                                <Row type='flex' align={"middle"}>

                                    <Button style={{ marginRight: 5 }} icon="search" onClick={this.onSearch} type="primary">Search</Button>

                                    <Dropdown overlay={<Menu onClick={(e: ClickParam) => {
                                        if (e.key === 'export') {
                                            this.props.onExport()
                                        }
                                    }}>
                                        <Menu.Item key={"export"}>Export Data</Menu.Item>
                                    </Menu>}>

                                        <Button icon="more"></Button>
                                    </Dropdown>

                                </Row>
                            </O2OFormItem>
                        </Col>
                    </Row>

                </Form>
            )
        }
    }
)


interface Props extends CommonTypeProps {
    user: User,
    commonResponse: CommonResponse,
    consoleUsers: ConsoleUsers,
    getAccount: typeGetAccounts,
    organizations: Organizations,
    getOrgs: typeGetOrgs,
    addUser: typeAddUsers,
    updateAccount: typeUpdateAcount,
    consoleUser: ConsoleUser,
    detailAccount: typeDetailAcount,
    resetPasswordAccount: typeResetPasswordAccount,
    deleteAccount: typeDeleteAccount,
    updateRoleAccount: any,
    deleteUserFromOrg: any,
    updateUserOrg: any,
    deleteUserFromOrgSPADMIN: any,
    assignUserToOrg: any,

    searchPermissionGroup: any,
    permissionGroups: AllPermissionGroups,
    assignUsersToPermissionGroups: any,
    searchPermission: any,
    permissions: Permissions,
    getColumns: any,
    saveColumns: any,
    saveUsersToPermissionGroups: any
    distinctByField: any
}

export class ConsoleUsersComponent extends React.Component<Props, any> {

    formRef: any
    searchInput: any

    constructor(props: Props) {
        super(props);
        this.loadOrg = debounce(this.loadOrg, 1000);
    }
    componentDidMount = () => {
        this.getCurrentUser().then(() => {
            this.load()
        })
        this.getPangoActPermission()
        this.loadPermission()
        this.loadGrantPermission()
        this.changeFreeText = _.debounce(this.changeFreeText, 500)
        this.loadGeneralSetting()
        this.props.searchPermissionGroup()

        this.setState({
            columnsAdminFilter: this.state.columns
        })

        let letTop10 = []
        for (let i = 0; i < 10; i++) {
            letTop10[i] = this.getRandomColor()
        }

        this.setState({
            top10Color: letTop10
        })

        this.props.getColumns("acc_console_user")
            .then(() => {
                if (this.props.ui.columns && this.props.ui.columns.result && this.props.ui.columns.result.columns.length) {
                    this.setState({
                        defaultColumnAttrs: this.props.ui.columns.result.columns
                    })
                } else {
                    this.setState({
                        defaultColumnAttrs: BIGDATA_USER_REF.ACCOUNT.console_user.columns
                    })
                }
                this.initColumns(this.state.defaultColumnAttrs)
            })

        this.props.updateMetaTitle({ title: BIGDATA_CONSTANT.trackTitle.ConsoleUser })
        this.timer = setTimeout(() => {
            this.props.updateMeta({
                name: BIGDATA_CONSTANT.trackTitle.ConsoleUser,
                title: BIGDATA_CONSTANT.trackTitle.ConsoleUser,
                app: "ACCOUNT",
                url: getCurrentURL(this.props.location)
            })
            clearTimeout(this.timer)
        }, 5000)

    }

    timer: any

    componentWillUnmount() {
        clearTimeout(this.timer)
    }

    loadPermission = () => {

        this.props.searchPermission()

    }

    state = {
        generalSetting: {} as IGeneralSetting,
        visibleExportData: false,
        pagination: {

        } as PaginationProps,
        criteria: {
            order: "createdAt:DESC"
        } as typeCriteriaSearchAccount,
        criteriaOrg: {
            order: "createdAt:DESC"
        } as typeCriteriaSearchOrgs,
        visibleDrawerResetPw: false,
        visibleViewJSON: false,
        isVisibleDrawer: false,
        isVisibleModal: false,
        isVisibleOrganizationInfo: false,
        visible_assign_permission_group: false,
        selectedConsoleUser: {} as TypeConsoleUser,
        selectedOrgIdAsign: undefined,
        currentUser: {} as User,
        currentUserOrg: [],
        selectedUser: {} as TypeConsoleUser,
        selectedUserRoles: undefined as undefined | string[],
        selectedRowKeys: [],
        selectedUsers: [] as TypeConsoleUser[],
        selectedPermissionGroup: {} as TypePermissionGroup,
        userPermissions: [],
        visibleInvite: false,
        visibleActionsLog: false,
        columns: [
            {
                title: "#",
                key: "index",
                render: (text: any, record: TypeConsoleUser, index: number) => {
                    return (
                        index + 1
                    )
                },
                width: 40,
                ellipsis: true
            },
            {
                title: 'Status', dataIndex: 'status',
                ellipsis: true,
                width: 90,
                key: 'status', render: (text: any, record: TypeConsoleUser, index: number) => {
                    return (
                        record.status == "active" ? <TagPrimaryColor uiSetting={this.props.uiSetting} >Active</TagPrimaryColor> :
                            <Tag>Inactive</Tag>
                    )
                },
            },
            {
                title: 'Name',
                dataIndex: 'name',
                ellipsis: true,
                width: 200,
                key: 'name',
                render: (text: any, record: TypeConsoleUser, index: number) => {
                    const expiredTime = this.props.consoleUsers.orgCtxes && this.props.consoleUsers.orgCtxes[record.id] ? this.props.consoleUsers.orgCtxes[record.id].validUntil : 0
                    const isExpired = expiredTime > 0 ? moment(expiredTime).isBefore(moment()) : false
                    return (
                        expiredTime ?
                            <Tooltip title={`Expiration Time: ${moment(expiredTime).format('LLLL')}`}>
                                {record.name} <Divider type='vertical' /> <Icon style={{ color: isExpired ? 'green' : 'red' }} type="clock-circle" />
                            </Tooltip> : record.name
                    )
                },
            },
            {
                title: 'Email',
                dataIndex: 'email',
                key: 'email',
                width: 200,
                ellipsis: true,
                render: (text: any, record: TypeConsoleUser, index: number) => {
                    return <LongTextTd text={record.id} />
                },
            },
            {
                title: '2FA',
                ellipsis: true,
                dataIndex: '2fa',
                key: '2fa',
                align: 'center',
                width: 120,
                render: (text: any, record: TypeConsoleUser, index: number) => {
                    return (
                        record.totpEnabled ? (
                            <Button type='link' style={{ cursor: 'default' }} size='small' icon='check-circle' />
                        ) : '--/--'
                    )
                },
            },
            {
                title: 'Roles',
                key: 'roles',
                width: 350,
                render: (text: any, record: TypeConsoleUser, index: number) => {
                    if (this.props.consoleUsers.orgCtxes) {
                        let roles = this.props.consoleUsers && this.props.consoleUsers.orgCtxes && this.props.consoleUsers.orgCtxes.hasOwnProperty(record.id) ? this.props.consoleUsers.orgCtxes[record.id].roles : [];
                        return (
                            <React.Fragment>
                                {
                                    roles.map((e: string, index: number) => {
                                        return <Tag>{this.convertRoleToString(e)}</Tag>
                                    })
                                }
                            </React.Fragment>
                        )
                    }
                    return "--/--"
                },
            },
            {
                title: 'Permissions',
                width: 130,
                ellipsis: true,
                key: 'permissions',
                render: (text: any, record: TypeConsoleUser, index: number) => {
                    if (!_.isEmpty(this.props.consoleUsers.permissionGroups)) {
                        let groups = this.props.consoleUsers.permissionGroups;
                        let userPermisionGroups: any;
                        for (const key in groups) {
                            if (groups.hasOwnProperty(key)) {
                                const permisionGroup = groups[key];
                                if (permisionGroup.userId == record.id) {
                                    userPermisionGroups = permisionGroup;
                                }
                            }
                        }
                        if (userPermisionGroups && isArray(userPermisionGroups.groupIds) && userPermisionGroups.groupIds.length) {
                            const count = userPermisionGroups.groupIds.length
                            return (
                                <Tooltip title={this.state.grantPersmission ? "Assign to permission groups" : "Missing Permission: Grant Privilege"}>
                                    <Button style={{ marginLeft: -8 }} disabled={!this.state.grantPersmission} onClick={() => {
                                        this.setState({
                                            visible_assign_permission_group: true,
                                            selectedUser: record
                                        })
                                    }} size='small' type='link' >
                                        {count} group{`${count > 1 ? "s" : ""}`}
                                    </Button>
                                </Tooltip>
                            )
                        }
                    }
                    return <Tooltip title={this.state.grantPersmission ? "Assign to permission groups" : "Missing Permission: Grant Privilege"}>
                        <Button style={{ marginLeft: -5 }} disabled={!this.state.grantPersmission} onClick={() => {
                            this.setState({
                                visible_assign_permission_group: true,
                                selectedUser: record
                            })
                        }} size='small' icon='plus-circle' type='link' >
                        </Button>
                    </Tooltip>
                },
            },
            {
                title: 'P.ACT',
                width: 170,
                ellipsis: true,
                key: 'act-permissions',
                render(text: any, record: TypeConsoleUser, index: number) {
                    return '--/--'
                },
            },
            {
                title: 'Created Time', dataIndex: 'createdAt', key: 'createdAt',
                width: 150,
                ellipsis: true,
                render: (text: any, record: TypeConsoleUser, index: number) => {
                    return (
                        <Tooltip title={`Created at ${moment(record.createdAt).format("LLLL")} by PangoCDP`}>
                            {moment(record.createdAt).fromNow()}
                        </Tooltip>

                    )
                },
            },
            {
                title: 'Action',
                ellipsis: true,
                key: "action",
                width: 90,
                fixed: "right",
                render: (text: any, record: TypeConsoleUser, index: number) => {
                    let roles = this.props.consoleUsers && this.props.consoleUsers.orgCtxes && this.props.consoleUsers.orgCtxes.hasOwnProperty(record.id) ? this.props.consoleUsers.orgCtxes[record.id].roles : undefined;
                    const permisionAccount: boolean = checkPermission(PANGO_PERMISSIONS.permissions.AccountManagement.key, this.props.authen.permission)

                    return (
                        <span>
                            <Tooltip title="Activities on the user">
                                <Button onClick={() => {
                                    this.setState({
                                        selectedUser: record,
                                        visibleActionsLog: true
                                    })
                                }} type='link' icon='history' size='small'></Button>
                            </Tooltip>

                            <Divider type='vertical' />

                            <CanAccess permission={['ADMIN', 'SUPER_ADMIN']} userRoles={this.props.user ? this.props.user.orgCtx ? this.props.user.orgCtx.roles : [] : []}>
                                <Tooltip title={permisionAccount ? "Edit" : "Missing Permission: Account Management"}>
                                    <Button disabled={!permisionAccount} size="small" icon="edit" type="link" onClick={() => {
                                        this.openModal(record, roles)
                                    }}></Button>
                                </Tooltip>
                            </CanAccess>

                            <Divider type='vertical' />

                            <Dropdown overlay={
                                <Menu
                                    onClick={(e: ClickParam) => {
                                        if (e.key === "resetPassword") {
                                            this.setState({
                                                selectedUser: record,
                                                visibleDrawerResetPw: true
                                            })
                                        } else if (e.key === "removeFromOrg") {
                                            this.setState({
                                                visibleConfirmRemoveUser: true,
                                                selectedUser: record
                                            })
                                        }
                                    }}>
                                    {canAccess(['ADMIN'], this.props.user ? this.props.user.orgCtx ? this.props.user.orgCtx.roles : [] : []) ? (
                                        <Menu.Item key="removeFromOrg" disabled={!permisionAccount}>
                                            <Tooltip title={!permisionAccount && "Missing Permission: Account Management"}>
                                                <Button disabled={!permisionAccount} icon="close" size="small" type="link" >
                                                    Remove from Organization
                                                </Button>
                                            </Tooltip>
                                        </Menu.Item>
                                    ) : null}
                                    {canAccess(['ADMIN', 'SUPER_ADMIN'], this.props.user ? this.props.user.orgCtx ? this.props.user.orgCtx.roles : [] : []) ? (
                                        <Menu.Item key="resetPassword" disabled={!permisionAccount}>
                                            <Tooltip title={!permisionAccount && "Missing Permission: Account Management"}>
                                                <Button disabled={!permisionAccount} icon='reload' size="small" type="link" >
                                                    Reset Password
                                                </Button>
                                            </Tooltip>
                                        </Menu.Item>
                                    ) : null}
                                </Menu>
                            }>
                                <Button size="small" icon="more" type="link" />
                            </Dropdown>

                        </span >


                    )
                }
            },
        ] as ColumnProps<{}>[],
        columnsSPAdmin: [
            {
                title: "#",
                key: "index",
                width: 40,
                render: (text: any, record: TypeConsoleUser, index: number) => {
                    return (
                        <span> {index + 1}</span>
                    )
                },
            },
            {
                title: 'User id',
                dataIndex: 'id',
                key: 'id',

                sorter: true,
                ellipsis: true,
                render: (text: any, record: TypeConsoleUser, index: number) => {
                    let { id = "" } = record
                    let initials: any = id.match(/\b\w/g) || [];
                    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
                    return (
                        <LongTextTd text={record.id} />
                    )
                },

            },

            {
                title: 'Company(s)',

                render: (text: any, record: TypeConsoleUser, index: number) => {
                    return (
                        this.renderOrganizationSAdmin(record)

                    )
                },
                sorter: false,

            },
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                render: (text: any, record: TypeConsoleUser, index: number) => {
                    return (
                        record.name
                    )
                },
                sorter: true,

            },
            {
                title: 'Phone',
                dataIndex: 'phone',
                key: 'phone',
                render: (text: any, record: TypeConsoleUser, index: number) => {
                    return (
                        record.phone || "--/--"
                    )
                },
                sorter: true,

            },

            {
                title: 'Address',
                dataIndex: 'address',
                key: 'address',
                ellipsis: true,
                render: (text: any, record: TypeConsoleUser, index: number) => {
                    return (
                        <LongTextTd text={record.address} />
                    )
                },
                sorter: true,

            },
            {
                title: 'Active', dataIndex: 'status',
                key: 'status', render: (text: any, record: TypeConsoleUser, index: number) => {
                    return (
                        record.status == "active" ? <Badge color="green" text="Active" /> : <Badge color="red" text="Inactive" />
                    )
                },
                sorter: true,
            },
            {
                title: 'Created by', dataIndex: 'createBy',
                key: 'status', render: (text: any, record: TypeConsoleUser, index: number) => {
                    return (
                        record.createdBy
                    )
                },

            },
            {
                title: 'Updated At', dataIndex: 'updatedAt', key: 'updatedAt', sorter: true,
                render: (text: any, record: TypeConsoleUser, index: number) => {
                    return (
                        <FormattedDate value={new Date(record.updatedAt)}
                            year='numeric'
                            month='2-digit'
                            day='2-digit'
                            hour="2-digit"
                            minute="2-digit"
                            second="2-digit"
                        />
                    )
                },
            },
            {
                title: 'Created At', dataIndex: 'createdAt', key: 'createdAt', sorter: true,
                render: (text: any, record: TypeConsoleUser, index: number) => {
                    return (
                        <FormattedDate value={new Date(record.createdAt)}
                            year='numeric'
                            month='2-digit'
                            day='2-digit'
                            hour="2-digit"
                            minute="2-digit"
                            second="2-digit"
                        />
                    )
                },
            },

            {
                title: 'Action',
                width: 180,
                fixed: "right",
                render: (text: any, record: TypeConsoleUser, index: number) => {

                    return (
                        <span>
                            <CanAccess permission={['ADMIN', 'SUPER_ADMIN']} userRoles={this.props.user ? this.props.user.orgCtx ? this.props.user.orgCtx.roles : [] : []}>

                                <Tooltip title="Edit">
                                    <Button size="small" icon="edit" type="link" onClick={() => {
                                        this.openModal(record, undefined)
                                    }}></Button>
                                </Tooltip>

                            </CanAccess>

                            <CanAccess permission={['ADMIN', 'SUPER_ADMIN']} userRoles={this.props.user ? this.props.user.orgCtx ? this.props.user.orgCtx.roles : [] : []}>
                                <Popconfirm title={`Are you sure you want to reset password account: ${record.name} ?`}>
                                    <Tooltip title="Reset Password">
                                        <Button size="small" icon="redo" type="link" ></Button>
                                    </Tooltip>

                                </Popconfirm>

                            </CanAccess>
                            <CanAccess permission={['ADMIN']} userRoles={this.props.user ? this.props.user.orgCtx ? this.props.user.orgCtx.roles : [] : []}>
                                <Popconfirm title={`Are you sure you want to remove account${record.name} from ${this.props.user.orgCtx ? this.props.user.orgCtx.organization.name : ''} company?`} onConfirm={() => this.deleteAccountFromOrg(record)}>
                                    <Tooltip title="Remove from organization">
                                        <Button size="small" icon="close" type="link" ></Button>
                                    </Tooltip>

                                </Popconfirm>

                            </CanAccess>

                            <CanAccess permission={['ADMIN', 'SUPER_ADMIN']} userRoles={this.props.user ? this.props.user.orgCtx ? this.props.user.orgCtx.roles : [] : []}>
                                <Popconfirm title={`Are you sure you want to delete account: ${record.name} ?`} onConfirm={() => this.deleteAccounts([record.id])}>
                                    <Tooltip title="Delete account">
                                        <Button size="small" icon="delete" type="link" ></Button>
                                    </Tooltip>

                                </Popconfirm>

                            </CanAccess>

                            <Divider type="vertical" />

                            <CanAccess permission={['SUPER_ADMIN']} userRoles={this.props.user ? this.props.user.orgCtx ? this.props.user.orgCtx.roles : [] : []}>

                                <Tooltip title="Assign Role">
                                    <Button size="small" icon="usergroup-add" type="link" onClick={() => {

                                        this.openAssignRole(record)
                                    }}></Button>
                                </Tooltip>

                            </CanAccess>

                        </span>


                    )
                }
            },
        ] as ColumnProps<{}>[],
        columnsAdminFilter: [] as ColumnProps<{}>[],
        defaultColumnAttrs: [],
        top10Color: [],
        visibleConfirmRemoveUser: false,
        grantPersmission: false,
        permissionPangoAct: new PermissionPangoAct(),
        visiblePermissionPangoAct: false
    }

    getPangoActPermission = async () => {
        if (!this.props.consoleUsers || !this.props.consoleUsers.success || !this.props.consoleUsers) return

        const users = map(this.props.consoleUsers.results.content, (o: TypeConsoleUser) => o.id)
        const result = this.props.commonActionFromDALV2(CommonDAL.buildURL,
            BIGDATA_CONSTANT.url.act + `pango-svc/permission/list`,
            users
            ,
            Methods.POST,
        )
        try {
            const response: PermissionPangoAct = await result
            if (response.success) {
                this.setState({
                    permissionPangoAct: response
                })
                const newColumns = update(this.state.columns, {
                    $splice: [[7, 1, {
                        title: 'P.ACT',
                        width: 170,
                        ellipsis: true,
                        key: 'act-permissions',
                        render(text: any, record: TypeConsoleUser, index: number) {
                            const actUser = find(response.data, (o: any) => o.userId === record.id) || {}
                            const actPermissions = actUser.permissions || []

                            return <Popover overlayStyle={{ maxWidth: 400 }} title={<span>
                                PangoACT Permissions <Divider type='vertical' />
                                <a target={'_blank'} href={process.env.REACT_APP_ACT_APP + 'permission'}>Manage</a>
                            </span>} content={<>
                                {actPermissions.map((permission: string) => <Tag style={{ marginBottom: 5 }}>{permission}</Tag>)}
                            </>}>
                                <span>
                                    {isEmpty(actPermissions) ? `--/--` : `[ ${numeral(size(actPermissions)).format('00')} ]`}
                                </span>
                            </Popover>
                        },
                    }]]
                })
                this.setState({
                    columns: newColumns
                })
            } else {
                return
            }
        } catch (error) {
            return
        }

    }

    getRandomColor = () => {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    convertRoleToString = (role: string) => {
        switch (role) {
            case 'USER':
                return 'User'
            case 'API':
                return 'Api'
            case 'ADMIN':
                return 'Admin'
            case 'SUPER_ADMIN':
                return 'Super admin'

            case 'OPER_ADMIN':
                return 'Operator Admin'
            default:
                return ""
        }
    }

    showPermissionDetalt = (item: any) => {

        this.props.setGlobalLoading(false)
        let permissionGroup: TypePermissionGroup | undefined = _.find(this.props.permissionGroups.results, (it: TypePermissionGroup) => it.id == item.id)
        if (permissionGroup) {
            this.setState({
                selectedPermissionGroup: permissionGroup

            })
        } else {
            this.setState({
                selectedPermissionGroup: {}
            })
        }


    }

    openAssignRole = (record: TypeConsoleUser) => {
        // let orgs = this.props.consoleUsers.orgs;

        let userOrgs = this.props.consoleUsers.userOrgs;
        let currentUserOrg = userOrgs ? userOrgs[record.id] : [];

        this.setState({ isVisibleOrganizationInfo: true, selectedConsoleUser: record, currentUserOrg: currentUserOrg })
    }

    openCompanyRoles = (record: TypeConsoleUser) => {
        let userOrgs = this.props.consoleUsers.userOrgs;
        let currentUserOrg = userOrgs ? userOrgs[record.id] : [];
        this.setState({ isVisibleOrganizationInfo: true, selectedConsoleUser: record, currentUserOrg: currentUserOrg })
    }

    renderOrganizationSAdmin = (record: TypeConsoleUser) => {

        let orgs = this.props.consoleUsers.orgs;
        let userOrgs = this.props.consoleUsers.userOrgs;
        let currentUserOrg = userOrgs ? userOrgs[record.id] : [];

        if (currentUserOrg && currentUserOrg.length) {
            if (currentUserOrg.length > 1) {
                return (
                    orgs[currentUserOrg[0].orgId] ?
                        <React.Fragment>
                            <Tooltip title="Click to show more information company">
                                <Badge count={currentUserOrg.length}>
                                    <Tag style={{ cursor: "pointer" }} onClick={() => {
                                        if (this.refSelectOrg) {
                                        }
                                        this.setState({ isVisibleOrganizationInfo: true, selectedConsoleUser: record, currentUserOrg: currentUserOrg })
                                    }}>{orgs[currentUserOrg[0].orgId] ? orgs[currentUserOrg[0].orgId].name : ""}</Tag>
                                </Badge>
                            </Tooltip>

                        </React.Fragment>
                        : (null)
                )
            } else {
                return (
                    <Tooltip title="Click to show more information company">
                        <p>
                            {currentUserOrg.map((e: any) => {
                                return orgs[e.orgId] ? <Tag style={{ cursor: "pointer" }} onClick={() => {
                                    if (this.refSelectOrg) {
                                        debugger
                                    }
                                    this.setState({ isVisibleOrganizationInfo: true, selectedConsoleUser: record, currentUserOrg: currentUserOrg })
                                }}>{orgs[e.orgId] ? orgs[e.orgId].name : ""}</Tag> : ("--/--")
                            })}
                        </p>
                    </Tooltip>
                )
            }

        } else {
            return "--/--"
        }
    }

    checkUserHasOrg = (id: string) => {
        // this.state.currentUserOrg
        if (this.state.currentUserOrg && this.state.currentUserOrg.length) {
            // orgId
            let og = _.find(this.state.currentUserOrg, (item: any) => item.orgId == id)
            if (og) {
                return <Icon type="check" style={{ color: getPrimaryColor(this.props.uiSetting) }} />
            }
            return null
        }
        return null
    }

    refSelectOrg: null
    renderDrawerOrgInfo = () => {
        let uo = this.state.selectedConsoleUser && this.props.consoleUsers.userOrgs ? this.props.consoleUsers.userOrgs[this.state.selectedConsoleUser.id] : [];
        let isSAdmin = this.checkCurrentUserIsSuperAdmin();
        return <Drawer
            width={"80%"}
            title={`Company(s)`}
            placement="right"
            closable={false}
            onClose={() => this.setState({ isVisibleOrganizationInfo: false })}
            visible={this.state.isVisibleOrganizationInfo}

        >
            <p>User <b>{this.state.selectedConsoleUser.name}</b> belongs to bellow company</p>
            <Row gutter={[10, 10]} type="flex" justify="space-between">
                <Select
                    ref={(e: any) => this.refSelectOrg = e}
                    showSearch
                    style={{ width: '80%' }}
                    placeholder={`Assign ${this.state.selectedConsoleUser.name} to Company`}
                    onSearch={this.searchOrg}
                    filterOption={false}
                    value={this.state.selectedOrgIdAsign}
                    onChange={(e: any) => {
                        this.setState({ selectedOrgIdAsign: e })
                    }}
                    notFoundContent={this.props.ui.isLoading ? <Spin size="small" /> : null}
                >
                    {
                        this.props.organizations.results.content.map((item: TypeOrganization) => {
                            return (
                                <Select.Option key={item.id}>{item.name} {this.checkUserHasOrg(item.id)}</Select.Option>
                            )
                        })
                    }
                </Select>
                <Button icon="plus" loading={this.props.ui.isLoading} disabled={this.props.ui.isLoading} onClick={() => {
                    if (this.state.selectedOrgIdAsign) {
                        this.props.setGlobalLoading(true)
                        message.info("Assigning...")
                        this.props.assignUserToOrg(this.state.selectedConsoleUser.id, this.state.selectedOrgIdAsign)
                            .then(() => {
                                this.props.setGlobalLoading(false)
                                message.info("Assigning success")

                                this.setState({ isVisibleOrganizationInfo: false, selectedOrgIdAsign: undefined })

                                setTimeout(() => {
                                    this.load(() => {
                                        this.openCompanyRoles(this.state.selectedConsoleUser)
                                    })
                                }, 1000)
                            })
                    } else {
                        message.warning("Please select an company")
                    }
                }}>Assign</Button>
            </Row>
            <br />
            <p>
                <React.Fragment>
                    {(this.state.currentUserOrg || []).map((e: any) => {
                        let o = this.props.consoleUsers.orgs[e.orgId]
                        let uSelect = _.find(uo, (item: any) => {
                            return item.orgId == e.orgId
                        });

                        return (
                            o ? (
                                <p>
                                    <Descriptions title={o.name} bordered column={{ xxl: 2, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }} layout="vertical">
                                        <Descriptions.Item label="Company name">
                                            {o.name}
                                            <Popconfirm title="Are you sure to remove user from this company?" onConfirm={() => {
                                                this.deleteUserFromOrgSPADMIN(this.state.selectedConsoleUser.id, o.id)
                                            }}>
                                                <Button type="link" icon="delete">Remove user form company</Button>
                                            </Popconfirm>

                                        </Descriptions.Item>
                                        <Descriptions.Item label="Company Id">{o.id}</Descriptions.Item>
                                        <Descriptions.Item label="Company email">{o.email || "empty"}</Descriptions.Item>
                                        <Descriptions.Item label="Roles">
                                            <React.Fragment>
                                                <p>
                                                    <Select onChange={(e: any) => {
                                                        this.props.setGlobalLoading(true)
                                                        this.props.updateRoleAccount(this.state.selectedConsoleUser.id, e, isSAdmin, o.id)
                                                            .then(() => {
                                                                this.props.setGlobalLoading(false)
                                                                setTimeout(() => {
                                                                    this.load()
                                                                }, 1000)
                                                                notification.success({
                                                                    message: BIGDATA_CONSTANT.message.default.title.success,
                                                                    description: `Roles updated`,
                                                                    icon: <Icon type="check-circle" style={{ color: getPrimaryColor(this.props.uiSetting) }} />
                                                                })
                                                            })
                                                    }} value={uSelect ? uSelect.roles : ""} defaultValue={uSelect ? uSelect.roles : ""} mode="multiple" style={{ width: '100%' }} placeholder="Update roles">
                                                        <Select.Option key="ADMIN">Admin</Select.Option>
                                                        <Select.Option key="API">Api</Select.Option>
                                                        <Select.Option key="USER">User</Select.Option>
                                                    </Select>
                                                </p>
                                            </React.Fragment>

                                        </Descriptions.Item>

                                    </Descriptions>

                                </p>
                            ) : null
                        )
                    })}
                </React.Fragment>
            </p>
        </Drawer >
    }

    resetPass = (item: TypeConsoleUser, sl: any) => {
        this.props.setGlobalLoading(true)
        sl(true)
        let isSad = this.checkCurrentUserIsSuperAdmin();
        this.props.resetPasswordAccount(item.id, isSad)
            .then(() => {
                this.props.setGlobalLoading(false)
                sl(false)
                if (this.props.commonResponse.success) {
                    this.setState({
                        visibleDrawerResetPw: false,
                        selectedConsoleUser: {}
                    })
                    notification.success({
                        message: BIGDATA_CONSTANT.message.default.title.success,
                        description: "Reset Password for " + item.name + " successfully",
                        icon: <Icon type="check-circle" style={{ color: getPrimaryColor(this.props.uiSetting) }} />
                    })
                } else {
                    notification.error({
                        message: BIGDATA_CONSTANT.message.default.title.error,
                        description: this.props.commonResponse.message || "error"
                    })
                }
            })
            .catch((error: any) => {
                sl(false)
                this.props.setGlobalLoading(false)
            })
    }


    deleteAccounts = (ids: string[]) => {
        this.props.setGlobalLoading(true)
        // let isSAdmin = this.checkCurrentUserIsSuperAdmin()
        this.props.deleteAccount(ids, false)
            .then(() => {
                this.props.setGlobalLoading(false)
                if (this.props.commonResponse.success) {
                    notification.success({
                        message: BIGDATA_CONSTANT.message.default.title.success,
                        description: BIGDATA_CONSTANT.message.notification.success.delete,
                        icon: <Icon type="check-circle" style={{ color: getPrimaryColor(this.props.uiSetting) }} />
                    })
                    setTimeout(() => {
                        this.load()
                    }, 1000)
                    this.setState({
                        selectedRowKeys: []
                    })

                } else {
                    notification.error({
                        message: BIGDATA_CONSTANT.message.default.title.error,
                        description: this.props.commonResponse.message || "error"
                    })
                }
            })
            .catch(() => {
                notification.error({
                    message: BIGDATA_CONSTANT.message.default.title.error,
                    description: "Something was wrong, please try later"
                })
                this.props.setGlobalLoading(false)
            })
    }
    deleteUserFromOrgSPADMIN = (id: string, oid: string) => {
        this.props.setGlobalLoading(true)
        this.props.deleteUserFromOrgSPADMIN(id, oid)
            .then(() => {
                this.props.setGlobalLoading(false)
                if (this.props.commonResponse.success) {
                    notification.success({
                        message: BIGDATA_CONSTANT.message.default.title.success,
                        description: "Remove account from company success",
                        icon: <Icon type="check-circle" style={{ color: getPrimaryColor(this.props.uiSetting) }} />
                    })
                    this.setState({ isVisibleOrganizationInfo: false })
                    setTimeout(() => {
                        this.load(() => {
                            this.openCompanyRoles(this.state.selectedConsoleUser)
                        })
                    }, 1000)
                } else {
                    notification.error({
                        message: BIGDATA_CONSTANT.message.default.title.error,
                        description: this.props.commonResponse.message || "error"
                    })
                }
            })
            .catch(() => {
                notification.error({
                    message: BIGDATA_CONSTANT.message.default.title.error,
                    description: "Something was wrong, please try later"
                })
                this.props.setGlobalLoading(false)
            })
    }
    deleteAccountFromOrg = (item: TypeConsoleUser) => {
        this.props.setGlobalLoading(true)
        this.props.deleteUserFromOrg(item.id)
            .then(() => {
                this.props.setGlobalLoading(false)
                if (this.props.commonResponse.success) {
                    notification.success({
                        message: BIGDATA_CONSTANT.message.default.title.success,
                        description: "Removed successfully",
                        icon: <Icon type="check-circle" style={{ color: getPrimaryColor(this.props.uiSetting) }} />
                    })
                    setTimeout(() => {
                        this.load()
                    }, 1000)
                } else {
                    notification.error({
                        message: BIGDATA_CONSTANT.message.default.title.error,
                        description: this.props.commonResponse.message || "error"
                    })
                }
            })
            .catch(() => {
                notification.error({
                    message: BIGDATA_CONSTANT.message.default.title.error,
                    description: "Something was wrong, please try later"
                })
                this.props.setGlobalLoading(false)
            })
    }

    showDetail = (item: TypeOrganization) => {
        this.setState({ isVisibleDrawer: true, selectedOrg: item })
    }




    changeColumnsAttribute = (e: string[]) => {

        message.loading("Hệ thống đang lưu")

        this.props.saveColumns({
            columns: e,
            objectType: "acc_console_user"
        })
            .then(() => {
                this.initColumns(e)
            })


    }

    initColumns = (e: string[]) => {
        if (e && e.length > 0) {
            let newColumns: any[] = _.filter(this.state.columns, (item: any) => {

                return _.indexOf(e, item.key) > -1
            })
            this.setState({
                columnsAdminFilter: newColumns
            })
        } else {
            this.setState({
                columnsAdminFilter: this.state.columns
            })
        }
    }


    renderColumnAttr = () => {
        return (

            <SelectColumns
                objectType="console_user"
                loading={this.props.ui.isLoading}
                columns={this.state.defaultColumnAttrs}
                onChangeForm={(e: any) => {
                    if (e.columns) {
                        this.changeColumnsAttribute(e.columns.value)
                    }
                }}
            />
        )
    }


    loadOrg = () => {

        this.props.getOrgs(this.state.criteriaOrg)
            .then(() => {
                this.props.setGlobalLoading(false)
            })
            .catch(() => this.props.setGlobalLoading(false))


    }

    getCurrentUser = async () => {
        const result = this.props.commonActionFromDALV2(CommonDAL.buildURL,
            BIGDATA_CONSTANT.url.api + 'accounts/users/current',
            {}, Methods.GET
        )
        const response = await result
        if (response.success) {
            this.setState({
                currentUser: response.result
            })
        } else {
            this.setState({
                currentUser: {}
            })
        }
        return response.result
    }

    checkCurrentUserIsSuperAdmin = (): boolean => {
        if (this.props.user) {
            if (this.props.user.orgCtx) {
                let roles = this.props.user.orgCtx.roles;
                let isSP = _.find(roles, (e) => {
                    return e == "SUPER_ADMIN"
                });

                if (isSP) {
                    return true
                }
                return false
            }
            return false
        }
        return false;
    }

    load = (callback?: any) => {
        this.props.setGlobalLoading(true)
            .then(() =>
                this.props.getAccount(this.state.criteria, false)
                    .then(() => {
                        if (this.props.commonResponse.success) {
                            this.getPangoActPermission()
                            this.props.setGlobalLoading(false)
                            let pagination = { ...this.props.consoleUsers.results.page }
                            let convertPaginationTable: PaginationProps = {
                                current: pagination.currentPage + 1,
                                pageSize: pagination.pageSize,
                                total: pagination.totalRecords,
                            }
                            this.setState({
                                pagination: Object.assign(this.state.pagination, convertPaginationTable),
                                criteria: Object.assign(this.state.criteria, { page: convertPaginationTable.current })
                            })

                            if (callback && typeof (callback) == "function") {
                                callback()
                            }
                        } else {
                            this.props.setGlobalLoading(false)
                            notification.error({
                                message: BIGDATA_CONSTANT.message.default.title.error,
                                description: this.props.commonResponse.message || "Something was wrong"
                            })
                        }
                    })
                    .catch((error: any) => {
                        notification.error({
                            message: BIGDATA_CONSTANT.message.default.title.error,
                            description: error.message || "Something was wrong"
                        })
                        this.props.setGlobalLoading(false)
                    })
            )

    }

    async loadGrantPermission() {
        const result = await this.props.commonActionFromDALV2(PermissionDAL.grantPermission)
        try {
            const response = await result
            this.setState({
                grantPersmission: response.success
            })
        } catch (error) {
            this.setState({
                grantPersmission: false
            })
        }
    }

    handleTableChange = (pagination: any, filters: any, sorter: any) => {
        const pager = { ...this.state.pagination };
        pager.current = pagination.current;
        const order = sorter.field ? `${sorter.field}:${sorter.order == 'descend' ? 'DECS' : 'ASC'}` : 'createdAt:DESC'

        this.setState({
            pagination: pager,
            criteria: Object.assign(this.state.criteria, { order: order, page: pager.current })
        });


        this.load();
    };

    openInvationUser = () => {
        this.setState({
            visibleInvite: true
        })
    }
    renderInvationUser = () => {
        return <DrawerInviteUser
            commonActionFromDALV2={this.props.commonActionFromDALV2}
            visible={this.state.visibleInvite}
            onClose={() => {
                this.setState({
                    visibleInvite: false
                })
            }}
            commonActionFromDAL={this.props.commonActionFromDAL}
        />
    }

    renderBreadCrumb = () => {
        const permisionAccount: boolean = checkPermission(PANGO_PERMISSIONS.permissions.AccountManagement.key, this.props.authen.permission)
        const permissionInviteUserJoinOrg: boolean = checkPermission(PANGO_PERMISSIONS.permissions.InviteUser.key, this.props.authen.permission)
        return (
            <Row type="flex" justify="space-between" align="middle" style={{ marginBottom: -15 }}>
                <Col>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Link to="/">Home</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>Admin</Breadcrumb.Item>
                        <Breadcrumb.Item>Accounts</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
                <Col>
                    {this.state.generalSetting.invitationUsersToJoin && <CanAccess permission={["ADMIN"]} userRoles={this.props.user.orgCtx ? this.props.user.orgCtx.roles : []} >
                        <Tooltip title={permissionInviteUserJoinOrg ? <>Invite an user to join <br /> your organization</> : "Missing Invite Permission"}>
                            <Button disabled={!permissionInviteUserJoinOrg} style={{ marginRight: 5 }} type="primary" icon="usergroup-add" onClick={() => this.openInvationUser()}>Invite</Button>
                        </Tooltip>
                    </CanAccess>}

                    <CanAccess permission={["ADMIN", "SUPER_ADMIN"]} userRoles={this.props.user.orgCtx ? this.props.user.orgCtx.roles : []} >
                        <Tooltip title={permisionAccount ? "Add new user" : "Missing Permission: Account Management"}>
                            <Button disabled={!permisionAccount} type="primary" icon="plus" onClick={() => this.openModal({} as TypeConsoleUser, undefined)}>Add User</Button>
                        </Tooltip>
                    </CanAccess>
                </Col>
            </Row>

        )
    }

    changeSearchRole = (e: string[]) => {
        this.setState({ s: Object.assign(this.state.criteria, { roles: e, page: 0 }) })
        this.load()
    }
    changeFreeText = (e: any) => {
        this.setState({ criteria: Object.assign(this.state.criteria, { freeText: e, page: 0 }) });
        this.load()

    }
    changeSearchStatus = (e: string[]) => {
        this.setState({ s: Object.assign(this.state.criteria, { status: e, page: 0 }) })
        this.load()
    }

    handleSearch = (selectedKeys: any, confirm: any) => {
        confirm();
        this.setState({ criteria: Object.assign(this.state.criteria, { freeText: selectedKeys[0], page: 0 }) });
        this.load()
    };

    handleReset = (clearFilters: any) => {
        clearFilters();
        this.setState({ criteria: Object.assign(this.state.criteria, { freeText: '', page: 0 }) });
    };

    loadGeneralSetting = async () => {
        const result = this.props.commonActionFromDALV2(CommonDAL.buildURL,
            BIGDATA_CONSTANT.url.api + `mngmnt-client/orgs/config/general-settings/get`,
            {},
            Methods.GET
        )
        try {
            const response: any = await result;
            if (response.success) {
                this.setState({
                    generalSetting: get(response, 'result', {})
                })
            } else {
                this.setState({
                    generalSetting: {}
                })
            }
        } catch (error) {
            this.setState({
                generalSetting: {}
            })
        }
    }


    renderTheme() {
        const { consoleUsers } = this.props
        // let isSAdmin = this.checkCurrentUserIsSuperAdmin()


        return (
            <DashboardPageLayout  {...this.props} selectedLeftNav="13" selectedSubMenu="console">
                <div className="org-page">

                    <MetaHeader title={this.props.ui.meta.title} />

                    <div className="contentLayout">
                        {this.renderBreadCrumb()}
                        <br />
                        <div className="containerInner">
                            <Row gutter={[5, 5]} type='flex'>
                                <Col xs={24}>
                                    <Typography.Title level={4}>Accounts</Typography.Title>
                                </Col>
                                <Col xs={24}>
                                    <FormFilterComponent
                                        onExport={() => this.setState({
                                            visibleExportData: true
                                        })}
                                        commonActionFromDALV2={this.props.commonActionFromDALV2}
                                        onSearch={(values: any) => {
                                            this.setState({ criteria: Object.assign(this.state.criteria, { ...values, page: 0 }) });
                                            this.load()
                                        }}
                                        isLoading={this.props.ui.isLoading}
                                        organizations={this.props.organizations}
                                        searchOrg={this.searchOrg}
                                        user={this.props.user}
                                    />
                                </Col>

                                <Col xs={24}>
                                    <InfoList
                                        list={this.props.consoleUsers}
                                        refresh={this.load}
                                    />
                                </Col>

                                <Col xs={24}>
                                    <Table
                                        onChange={this.handleTableChange}
                                        size="small"
                                        bordered={false}
                                        style={{ fontSize: 12 }}
                                        columns={this.state.columns}
                                        dataSource={consoleUsers.results.content}
                                        pagination={false}
                                        rowKey="id"
                                        scroll={{ x: 'calc(700px + 50%)' }}
                                    />
                                </Col>

                                <Col xs={24}>
                                    <O2OCustomPaging
                                        list={this.props.consoleUsers}
                                        onChange={this.pagi}
                                        onChangePageSize={(e: any) => {
                                            this.setState({
                                                criteria: Object.assign(this.state.criteria, { pageSize: !e ? 10 : +e })
                                            }, () => {
                                                this.load()
                                            })
                                        }}
                                    />
                                </Col>
                                {this.renderModalUser()}

                                {this.renderAssignToPermissionGroup()}

                                {this.renderDrawerViewJSON()}
                                {this.renderDrawerResetPassword()}
                                {this.renderDrawerRemoveAccount()}
                                {this.renderDrawerActionsLog()}
                                {this.renderExportData()}
                                {this.renderPermissionPangoAct()}

                            </Row>

                        </div>
                    </div>
                    {false ? (
                        this.renderDrawerOrgInfo()
                    ) : (null)}

                    {this.renderInvationUser()}
                </div>
            </DashboardPageLayout >
        )
    }

    pagi = (n: number) => {

        let sC: typeCriteriaSearchAccount = Object.assign(this.state.criteria, { page: n })
        this.setState({ criteria: sC })
        this.load()

    }


    formAssginPermissionGroup: any
    renderAssignToPermissionGroup(): React.ReactNode {
        return (
            <DrawerAssignUserToPermissionGroup
                onSuccess={() => {
                    setTimeout(() => {
                        this.load()
                    }, 1000);
                }}
                selectedUser={this.state.selectedUser}
                commonActionFromDALV2={this.props.commonActionFromDALV2}
                loading={this.props.ui.isLoading}
                onClose={() => {
                    this.setState({
                        visible_assign_permission_group: false
                    })
                }}
                userPermissions={this.state.userPermissions}
                onSubmit={() => {

                    this.formAssginPermissionGroup.props.form.validateFields((err: any, values: any) => {
                        if (err) {
                            return;
                        }
                        this.props.setGlobalLoading(true)
                        if (this.state.selectedUsers.length == 1) {
                            this.props.saveUsersToPermissionGroups(this.state.selectedUsers.map((item: TypeConsoleUser) => item.id), values.pg)
                                .then(() => {
                                    this.props.setGlobalLoading(false)
                                    this.setState({
                                        visible_assign_permission_group: false
                                    })
                                    notification.success({
                                        message: BIGDATA_CONSTANT.message.default.title.success,
                                        description: "Assgin to permission group success",
                                        icon: <Icon type="check-circle" style={{ color: getPrimaryColor(this.props.uiSetting) }} />
                                    })
                                    setTimeout(() => {
                                        this.load()
                                        this.formAssginPermissionGroup.props.form.resetFields()
                                    }, 1000);
                                })
                                .catch(() => {
                                    this.props.setGlobalLoading(false)
                                    notification.error({
                                        message: BIGDATA_CONSTANT.message.default.title.error,
                                        description: "Something was wrong"
                                    })
                                })
                        } else {
                            this.props.assignUsersToPermissionGroups(this.state.selectedUsers.map((item: TypeConsoleUser) => item.id), values.pg)
                                .then(() => {
                                    this.props.setGlobalLoading(false)
                                    notification.success({
                                        message: BIGDATA_CONSTANT.message.default.title.success,
                                        description: "Assgin to permission group success",
                                        icon: <Icon type="check-circle" style={{ color: getPrimaryColor(this.props.uiSetting) }} />
                                    })
                                    this.setState({
                                        visible_assign_permission_group: false
                                    })
                                    setTimeout(() => {
                                        this.load()
                                        this.formAssginPermissionGroup.props.form.resetFields()
                                    }, 1000);
                                })
                                .catch(() => {
                                    this.props.setGlobalLoading(false)
                                    notification.error({
                                        message: BIGDATA_CONSTANT.message.default.title.error,
                                        description: "Something was wrong"
                                    })
                                })
                        }



                    })
                }}
                visible={this.state.visible_assign_permission_group}
                permissionGroup={this.props.permissionGroups}
                users={this.state.selectedUsers}
                wrappedComponentRef={(f: any) => this.formAssginPermissionGroup = f}
            />
        )
    }

    handleSubmit = () => {
        const form = this.formRef.props.form;

        form.validateFields(async (err: any, values: any) => {
            try {
                if (err) {
                    return;
                }

                this.props.setGlobalLoading(true)

                values.status = values.active == true ? 'active' : 'inactive'
                if (!values.password) {
                    values.password = this.state.selectedUser.password
                    values.id = this.state.selectedUser.id
                }
                let isSPAdmin = this.checkCurrentUserIsSuperAdmin()
                const result = this.state.selectedUser && this.state.selectedUser.id ? this.props.updateAccount(values, false) : this.props.addUser(values, false)
                const response: CommonResponse = await result

                if (response.success) {
                    if (this.state.selectedUser && this.state.selectedUser.id) {
                        notification.success({
                            message: BIGDATA_CONSTANT.message.default.title.success,
                            description: BIGDATA_CONSTANT.message.notification.success.update,
                            icon: <Icon type="check-circle" style={{ color: getPrimaryColor(this.props.uiSetting) }} />
                        })

                        if (isSPAdmin) {

                        } else {
                            this.props.updateRoleAccount(this.state.selectedUser.id, values.roles, false)
                                .then(() => {
                                })
                        }

                    } else {
                        notification.success({
                            message: BIGDATA_CONSTANT.message.default.title.success,
                            description: BIGDATA_CONSTANT.message.notification.success.add,
                            icon: <Icon type="check-circle" style={{ color: getPrimaryColor(this.props.uiSetting) }} />
                        })
                    }
                    setTimeout(() => {
                        this.load()
                    }, 1000)

                    form.resetFields()
                    this.props.setGlobalLoading(false)
                    this.setState({ isVisibleModal: false })

                } else {
                    notification.error({
                        message: BIGDATA_CONSTANT.message.default.title.error,
                        description: _.get(response, "message", "Something went wrong")
                    })
                    this.props.setGlobalLoading(false)
                }

            } catch (error) {
                notification.error({
                    message: BIGDATA_CONSTANT.message.default.title.error,
                    description: _.get(error, "message", "Something went wrong")
                })
                this.props.setGlobalLoading(false)
            }
        })
    }

    openModal = (user: TypeConsoleUser, roles: undefined | string[]) => {
        // let isSAdmin = this.checkCurrentUserIsSuperAdmin();
        if (user && user.id) {
            // this.setState({  })

            this.props.detailAccount(user.id, false)
                .then(() => {
                    // debugger
                    // this.setState({ isVisibleModal: true, selectedUser: Object.assign(this.state.selectedUser, this.props.consoleUser.result) })
                    this.setState({ isVisibleModal: true, selectedUser: this.props.consoleUser.result, selectedUserRoles: roles })
                })
        } else {
            this.setState({
                isVisibleModal: true, selectedUser: null, selectedUserRoles: undefined
            })
        }



    }

    renderModalUser = () => {
        // let isSAdmin = this.checkCurrentUserIsSuperAdmin();
        return (
            <DrawerConsoleUserAdmin
                wrappedComponentRef={(propsRef: any) => this.formRef = propsRef}
                visible={this.state.isVisibleModal}
                commonActionFromDAL={this.props.commonActionFromDAL}
                onCancel={() => {
                    this.setState({
                        isVisibleModal: false,
                        selectedUser: {},
                        criteriaOrg: Object.assign(this.state.criteriaOrg, { freeText: '' })
                    })
                    this.formRef.props.form.resetFields()
                }}
                isSAdmin={false}
                currentUser={this.props.user}
                user={this.state.selectedUser}
                orgs={this.props.organizations}
                onSearchOrg={this.searchOrg}
                onCreate={this.handleSubmit}
                loading={this.props.ui.isLoading}
                roles={this.state.selectedUserRoles}
            />

        )
    }

    renderDrawerViewJSON = () => {
        return <DrawerViewJSON
            visible={this.state.visibleViewJSON}
            onClose={() => {
                this.setState({
                    visibleViewJSON: false,
                    selectedUser: {}
                })
            }}
            data={this.state.selectedUser}
        />
    }

    renderDrawerResetPassword = () => {
        return <DrawerFormResetPassword
            selectedUser={this.state.selectedUser}
            onReset={(sl) => {
                this.resetPass(this.state.selectedUser, sl)
            }}
            visible={this.state.visibleDrawerResetPw}
            onClose={() => {
                this.setState({
                    visibleDrawerResetPw: false,
                    selectedUser: {}
                })
            }} />
    }

    renderDrawerRemoveAccount = () => {
        return <DrawerRemoveAccount
            visible={this.state.visibleConfirmRemoveUser}
            onClose={() => {
                this.setState({
                    visibleConfirmRemoveUser: false,
                    selectedUser: {}
                })
            }}
            item={this.state.selectedUser}
            onDelete={(record: TypeConsoleUser) => {
                this.deleteAccountFromOrg(record)
                this.setState({
                    visibleConfirmRemoveUser: false,
                    selectedUser: {}
                })
            }}
            user={this.props.user}
        />
    }


    renderDrawerActionsLog = () => {
        return <DrawerActionsLog
            distinctByField={this.props.distinctByField}
            visible={this.state.visibleActionsLog}
            onClose={() => {
                this.setState({
                    visibleActionsLog: false,
                    selectedUser: {}
                })
            }}
            commonActionFromDALV2={this.props.commonActionFromDALV2}
            selectedRecord={this.state.selectedUser}
        />
    }

    renderExportData = () => {
        return <DrawerExportDataWithout2FA
            visible={this.state.visibleExportData}
            onClose={() => {
                this.setState({
                    visibleExportData: false
                })
            }}
            totalRecords={this.props.consoleUsers.results.page.totalRecords}
            commonActionDALV2={this.props.commonActionFromDALV2}
            criteria={this.state.criteria}
            disableButton={this.props.consoleUsers.results.page.totalRecords >= 1000000}
            type={'account_Admin'}
        />
    }

    renderPermissionPangoAct = () => {
        return <Drawer
            bodyStyle={{ marginBottom: 100 }}
            title={<span>
                <ButtonBackDrawer onClick={() => {
                    this.setState({
                        visiblePermissionPangoAct: false
                    })
                }} />
                PangoAct PERMISSION
            </span>}
            visible={this.state.visiblePermissionPangoAct}
            onClose={() => {
                this.setState({
                    visiblePermissionPangoAct: false
                })
            }}
            maskClosable={false}
            width={600}
        >

        </Drawer>
    }


    searchOrg = (e: any) => {

        this.setState({
            criteriaOrg: Object.assign(this.state.criteriaOrg, { freeText: e })
        })

        this.loadOrg();
    }


    render() {
        return (
            this.renderTheme()
        );
    }
}

