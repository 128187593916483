import { Form, Input, Button, notification, Spin, Alert, Col, Row, Tooltip, Icon } from 'antd';
import React from "react";
import { FormComponentProps } from 'antd/lib/form';
import { User } from 'o2o_layout/lib/common/model/user';
import { TypeOrgCtx } from 'o2o_layout/lib/common/model';
import { useAsyncRetry, useDebounce } from 'react-use';
import { ConsoleUsersDAL } from '../ConsoleUserDAL';
import _ from 'lodash';
import { History } from 'history';
import { getPrimaryColor } from '../../../common/helper';
import { BIGDATA_CONSTANT } from '../../../common/constant';
import { useSelector } from '../../../redux/store';
import { IState } from '../../../redux/reducer';



interface Props extends FormComponentProps {
    loading?: boolean,
    locale: any,
    changePwd: any,
    orgCtx: TypeOrgCtx | undefined,
    user: User
    commonActionFromDAL: any
    history: History<any>
}

export class PolicyPasswordCheck {
    success: boolean
    result: {
        errors: string[],
        level: "Low" | "Fair" | "Good" | "Strong"
        policy: any
    }
    constructor() {
        this.success = false
        this.result = {
            errors: [],
            level: "Low",
            policy: null
        }
    }
}

export const ChangePassword = Form.create<Props>({ name: 'form_change_pwd_modal' })(
    // eslint-disable-next-line
    (props: Props) => {

        const [confirmDirty, setConfirmDirty] = React.useState(false)
        const [loading, setLoading] = React.useState(false)

        const [val, setVal] = React.useState(undefined as undefined | string);
        const [q, setQ] = React.useState(undefined as undefined | string);

        const uiSetting = useSelector((state: IState) => state.uiSetting)

        const statePolicyPasswword = useAsyncRetry(async () => {
            if (q) {
                const data = await loadPolicyPassword();
                return data
            }
            return new PolicyPasswordCheck()
        }, [q])

        const loadPolicyPassword = async () => {
            const result: Promise<PolicyPasswordCheck> = props.commonActionFromDAL(ConsoleUsersDAL, "checkPasswordPolicy", q)
            try {
                const payload: PolicyPasswordCheck = await result;
                return payload
            } catch (error) {
                return new PolicyPasswordCheck()
            }
        }

        const [,] = useDebounce(
            () => {
                if (val != undefined) {
                    setQ(val)
                }
            },
            500,
            [val]
        );

        const handleConfirmBlur = (e: any) => {
            const { value } = e.target;
            setConfirmDirty(confirmDirty || !!value)
        };

        const compareToFirstPassword = (rule: any, value: any, callback: any) => {
            const { form } = props;
            if (value && value !== form.getFieldValue('password')) {
                callback('Two passwords that you enter is inconsistent!');
            } else {
                callback();
            }
        };

        const validateToNextPassword = (rule: any, value: any, callback: any) => {
            const { form } = props;
            if (value && confirmDirty) {
                form.validateFields(['confirm'], { force: true });
            }
            if(value == form.getFieldValue('current_password')){
                callback('Your new password must be different from your previous password');
            }
            callback();
        };

        const submit = (e: any) => {
            e.preventDefault();
            let valid = checkValidate()
            if (valid) {
                props.form.validateFieldsAndScroll((err: any, values: any) => {
                    if (!err) {
                        setLoading(true)
                        props.changePwd(values.current_password, values.password, values.confirm)
                            .then(() => {
                                setLoading(false)
                                props.form.resetFields()
                                notification.success({
                                    message: BIGDATA_CONSTANT.message.default.title.success,
                                    description: "Change password successfully, please sign-in again.",
                                    icon: <Icon type="check-circle" style={{ color: getPrimaryColor(uiSetting) }} />
                                })

                                setTimeout(() => {
                                    props.history.push({
                                        pathname: "/login"
                                    })
                                }, 2000);

                            })
                            .catch((e: any) => {
                                setLoading(false)
                                notification.error({
                                    message: "Error",
                                    description: _.get(e, "data.message", "Something was wrong!")
                                })
                            })
                    }
                });
            }else{
                props.form.validateFieldsAndScroll((err: any, values: any) => {
                    if (!err) {

                    }
                })
            }

        }

        const checkValidate = () => {
            if (statePolicyPasswword.value && statePolicyPasswword.value.success) {
                if (statePolicyPasswword.value.result.errors && statePolicyPasswword.value.result.errors.length) {
                    return false
                }
                return true
            }
            return false
        }

        const renderError = () => {
            if (statePolicyPasswword.value && statePolicyPasswword.value.success) {
                if (statePolicyPasswword.value.result.errors && statePolicyPasswword.value.result.errors.length) {
                    return (
                        <Alert
                            message=""
                            description={
                                <ul>
                                    {statePolicyPasswword.value.result.errors.map((item: string) => <li>{item}</li>)}
                                </ul>
                            }
                            type="error"
                        />
                    )
                }
                return null
            }
            return null
        }

        const renderLevel = () => {
            if (statePolicyPasswword.value && statePolicyPasswword.value.success) {
                if (statePolicyPasswword.value.result.level == "Low") {
                    return (
                        <Row gutter={[5, 5]} style={{ marginTop: 5 }}>
                            <Col xs={6}>
                                <Tooltip title="Low">
                                    <div style={{
                                        background: "#FF445E",
                                        width: "100%",
                                        height: 8,
                                        borderRadius: 10
                                    }}></div>
                                </Tooltip>

                            </Col>
                            <Col xs={6}>
                                <Tooltip title="Fair">
                                    <div style={{
                                        background: "#d9d9d9",
                                        width: "100%",
                                        height: 8,
                                        borderRadius: 10
                                    }}></div>
                                </Tooltip>
                            </Col>
                            <Col xs={6}>
                                <Tooltip title="Good">
                                    <div style={{
                                        background: "#d9d9d9",
                                        width: "100%",
                                        height: 8,
                                        borderRadius: 10
                                    }}></div>
                                </Tooltip>
                            </Col>
                            <Col xs={6}>
                                <Tooltip title="Strong">
                                    <div style={{
                                        background: "#d9d9d9",
                                        width: "100%",
                                        height: 8,
                                        borderRadius: 10
                                    }}></div>
                                </Tooltip>
                            </Col>

                        </Row>

                    )
                } else if (statePolicyPasswword.value.result.level == "Fair") {
                    return (
                        <Row gutter={[5, 5]} style={{ marginTop: 5 }}>
                            <Col xs={6}>
                                <Tooltip title="Low">
                                    <div style={{
                                        background: "#FF445E",
                                        width: "100%",
                                        height: 8,
                                        borderRadius: 10
                                    }}></div>
                                </Tooltip>

                            </Col>
                            <Col xs={6}>
                                <Tooltip title="Fair">
                                    <div style={{
                                        background: "rgb(255 166 44)",
                                        width: "100%",
                                        height: 8,
                                        borderRadius: 10
                                    }}></div>
                                </Tooltip>
                            </Col>
                            <Col xs={6}>
                                <Tooltip title="Good">
                                    <div style={{
                                        background: "#d9d9d9",
                                        width: "100%",
                                        height: 8,
                                        borderRadius: 10
                                    }}></div>
                                </Tooltip>
                            </Col>
                            <Col xs={6}>
                                <Tooltip title="Strong">
                                    <div style={{
                                        background: "#d9d9d9",
                                        width: "100%",
                                        height: 8,
                                        borderRadius: 10
                                    }}></div>
                                </Tooltip>
                            </Col>

                        </Row>

                    )
                } else if (statePolicyPasswword.value.result.level == "Good") {
                    return (
                        <Row gutter={[5, 5]} style={{ marginTop: 5 }}>
                            <Col xs={6}>
                                <Tooltip title="Low">
                                    <div style={{
                                        background: "#FF445E",
                                        width: "100%",
                                        height: 8,
                                        borderRadius: 10
                                    }}></div>
                                </Tooltip>

                            </Col>
                            <Col xs={6}>
                                <Tooltip title="Fair">
                                    <div style={{
                                        background: "rgb(255 166 44)",
                                        width: "100%",
                                        height: 8,
                                        borderRadius: 10
                                    }}></div>
                                </Tooltip>
                            </Col>
                            <Col xs={6}>
                                <Tooltip title="Good">
                                    <div style={{
                                        background: "rgb(45, 183, 245)",
                                        width: "100%",
                                        height: 8,
                                        borderRadius: 10
                                    }}></div>
                                </Tooltip>
                            </Col>
                            <Col xs={6}>
                                <Tooltip title="Strong">
                                    <div style={{
                                        background: "#d9d9d9",
                                        width: "100%",
                                        height: 8,
                                        borderRadius: 10
                                    }}></div>
                                </Tooltip>
                            </Col>

                        </Row>

                    )
                } else if (statePolicyPasswword.value.result.level == "Strong") {
                    return (
                        <Row gutter={[5, 5]} style={{ marginTop: 5 }}>
                            <Col xs={6}>
                                <Tooltip title="Low">
                                    <div style={{
                                        background: "#FF445E",
                                        width: "100%",
                                        height: 8,
                                        borderRadius: 10
                                    }}></div>
                                </Tooltip>

                            </Col>
                            <Col xs={6}>
                                <Tooltip title="Fair">
                                    <div style={{
                                        background: "rgb(255 166 44)",
                                        width: "100%",
                                        height: 8,
                                        borderRadius: 10
                                    }}></div>
                                </Tooltip>
                            </Col>
                            <Col xs={6}>
                                <Tooltip title="Good">
                                    <div style={{
                                        background: "rgb(45, 183, 245)",
                                        width: "100%",
                                        height: 8,
                                        borderRadius: 10
                                    }}></div>
                                </Tooltip>
                            </Col>
                            <Col xs={6}>
                                <Tooltip title="Strong">
                                    <div style={{
                                        background: "rgb(135, 208, 104)",
                                        width: "100%",
                                        height: 8,
                                        borderRadius: 10
                                    }}></div>
                                </Tooltip>
                            </Col>

                        </Row>

                    )
                }
            }
            return null
        }

        const renderTheme = () => {
            const { getFieldDecorator } = props.form
            return (
                <Form colon={false} hideRequiredMark={true} onSubmit={submit}>


                    <Form.Item label="Current password">
                        {getFieldDecorator('current_password', {
                            rules: [
                                { required: true, message: "Please enter your current password" },

                            ],
                        })(<Input.Password placeholder="**************" />)}
                    </Form.Item>
                    <Form.Item label="Set a new Password (Special characters: ~!@#$%^&*()_-)">

                        <Spin spinning={false}>
                            {getFieldDecorator('password', {
                                rules: [
                                    {
                                        validator: validateToNextPassword,
                                    },
                                ],
                            })(<Input.Password placeholder="**************" onChange={(e: any) => {
                                setVal(e.currentTarget.value)
                            }} />)}
                        </Spin>
                        {renderLevel()}

                    </Form.Item>

                    <Form.Item label="Confirm Password">
                        {getFieldDecorator('confirm', {
                            rules: [
                                {
                                    validator: compareToFirstPassword,
                                },
                            ],
                        })(<Input.Password placeholder="**************" onBlur={handleConfirmBlur} />)}
                    </Form.Item>

                    <Form.Item>
                        {renderError()}
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            Update
                        </Button>
                    </Form.Item>

                </Form>
            )
        }
        return (
            renderTheme()
        );
    },
);
