import React from 'react';
import { Form, Icon, Select } from "antd";
import { FormComponentProps } from 'antd/lib/form';
import { BIGDATA_USER_REF } from 'o2o_layout';

interface IFormFilterProps extends FormComponentProps {
    onChangeForm: any,
    objectType: string,
    columns: string[],
    loading: boolean
}
export const SelectColumns = Form.create<IFormFilterProps>({
    name: 'filterform',
    onFieldsChange(props, changedFields) {
        props.onChangeForm(changedFields);
    }
})(
    class FormFilterClass extends React.Component<IFormFilterProps, any> {
        render() {
            const { getFieldDecorator } = this.props.form
            const columns: any = BIGDATA_USER_REF.ACCOUNT[this.props.objectType].all
            
            return (
                <Form layout="inline" title="Search">
                    <Form.Item>
                        <div><Icon type="setting" /> Select Columns</div>
                        {
                            getFieldDecorator("columns", {
                                initialValue: this.props.columns
                            })(
                                <Select
                                    loading={this.props.loading}
                                    style={{ width: 300 }} mode="multiple"
                                    placeholder={<span><Icon type="setting" /> Columns attributes</span>}
                                    maxTagCount={2}
                                    allowClear
                                    showArrow>

                                    {
                                        
                                        columns.map((item: any) => {
                                            return <Select.Option key={item.key}>{item.text}</Select.Option>
                                        })
                                    }
                                </Select>
                            )
                        }

                    </Form.Item>
                </Form>
            )
        }
    }
)