import { Button, Dropdown, Icon, Menu } from 'antd'
import { ClickParam } from 'antd/lib/menu'
import React from 'react'

interface Iprops {
    onSelected: any
    sortBy: string
}
const SortByCriteria = (props: Iprops) => {
    const renderText = () => {
        switch (props.sortBy) {
            case "name":
                return "Name"
            case "accountType":
                return "Account Type"
            case "status":
                return "Status"
            case "createdAt":
                return "Created At"
            case "Updated At":
                return "updatedAt"
            default:
                return "Created At"
        }
    }


    return (
        <Dropdown overlay={
            <Menu onClick={(e: ClickParam) => {
                props.onSelected(e.key)
            }} selectedKeys={[props.sortBy]}>
                <Menu.Item key="name">Name</Menu.Item>
                <Menu.Item key="accountType">Account Type</Menu.Item>
                <Menu.Item key="status">Status</Menu.Item>
                <Menu.Item key="createdAt">Created At</Menu.Item>
                <Menu.Item key="updatedAt">Updated At</Menu.Item>
            </Menu>
        }>
            <div> Sort by: <Button type="link" size="small">{renderText()} <Icon type="caret-down" /></Button></div>

        </Dropdown>
    )
}

export default SortByCriteria