import { IActionGeneric } from '../../common/IActionGeneric';
import { Organizations } from 'o2o_layout/lib/common/model';

export const UPDATE_ORGRANIZATIONS = "UPDATE_ORGRANIZATIONs"

const emptyOrganizations = (): Organizations => {
    return new Organizations()
};

export interface IActionOrganization extends IActionGeneric<Organizations>{}

export const organizationReducer = (state = emptyOrganizations(), action: IActionOrganization) => {
  switch (action.type) {
    case UPDATE_ORGRANIZATIONS:
      return {...state, ...action.payload};
    default:
      return state;
  }
};