import { Button, Col, Form, Input, Row, Select, Switch } from 'antd';
import * as React from 'react';

import { FormComponentProps } from 'antd/lib/form';
import _ from 'lodash';
import { CommonNotificationSaveError, CommonNotificationUpdateSuccessfully } from '../../../../../shares/CommonNotification';
import { O2OFormItem } from '../../../../../shares/O2OFormItem';
import { CompanyDAL } from '../../DAL';
import { TypeCountry, TypeLocalizationItem } from './Localization';


interface IProps extends FormComponentProps {
    personalize: TypeLocalizationItem
    commonActionDAL: any
    callback: any
    coutries: TypeCountry[]
}

const TypeCurcenry = {
    VND: "VND",
    SGD: "SGD",
    USD: "USD",
    EUR: "EUR",
    AUD: "AUD",

}

const FormD = (props: IProps) => {
    const [loading, setLoading] = React.useState(false)
    const onSubmit = () => {
        props.form.validateFields(async (err: any, values: any) => {
            try {
                if (err) {
                    return;
                }
                setLoading(true)
                const coutry: TypeCountry | undefined = _.find(props.coutries, (item: TypeCountry) => item.name == values.countryName)
                const result = props.commonActionDAL(CompanyDAL.saveConfigPersonalize, Object.assign(values, {
                    countryCodeAlpha2: coutry ? coutry.alpha2 : undefined, countryCodeAlpha3: coutry ? coutry.alpha3 : undefined
                }))
                const response = await result
                if (response.success) {
                    setLoading(false)
                    CommonNotificationUpdateSuccessfully()
                    props.callback();
                } else {
                    CommonNotificationSaveError(response.message)
                    setLoading(false)
                }
            } catch (error) {
                CommonNotificationSaveError(_.get(error, "message", "Something went wrong!"))
                setLoading(false)
            }

        })
    }
    return (
        <Form colon={false} hideRequiredMark={true}>
            <Row type="flex" gutter={[10, 10]}>
                <Col xs={24}>
                    <O2OFormItem label="GMT">
                        {props.form.getFieldDecorator("gmt", {
                            rules: [{
                                required: true,
                                message: "Required"
                            }],
                            initialValue: props.personalize.gmt ? props.personalize.gmt + "" : "0"
                        })(
                            <Select showSearch placeholder="Select" showArrow >
                                <Select.Option value={"-12"}>GMT -12:00</Select.Option>
                                <Select.Option value={"-11"}>GMT -11:00</Select.Option>
                                <Select.Option value={"-10"}>GMT -10:00</Select.Option>
                                <Select.Option value={"-9"}>GMT -9:00</Select.Option>
                                <Select.Option value={"-8"}>GMT -8:00</Select.Option>
                                <Select.Option value={"-7"}>GMT -7:00</Select.Option>
                                <Select.Option value={"-6"}>GMT -6:00</Select.Option>
                                <Select.Option value={"-5"}>GMT -5:00</Select.Option>
                                <Select.Option value={"-4"}>GMT -4:00</Select.Option>
                                <Select.Option value={"-3"}>GMT -3:00</Select.Option>
                                <Select.Option value={"-2"}>GMT -2:00</Select.Option>
                                <Select.Option value={"-1"}>GMT -1:00</Select.Option>
                                <Select.Option value={"0"}>GMT +00:00</Select.Option>
                                <Select.Option value={"1"}>GMT +1:00</Select.Option>
                                <Select.Option value={"2"}>GMT +2:00</Select.Option>
                                <Select.Option value={"3"}>GMT +3:00</Select.Option>
                                <Select.Option value={"4"}>GMT +4:00</Select.Option>
                                <Select.Option value={"5"}>GMT +5:00</Select.Option>
                                <Select.Option value={"6"}>GMT +6:00</Select.Option>
                                <Select.Option value={"7"}>GMT +7:00</Select.Option>
                                <Select.Option value={"8"}>GMT +8:00</Select.Option>
                                <Select.Option value={"9"}>GMT +9:00</Select.Option>
                                <Select.Option value={"10"}>GMT +10:00</Select.Option>
                                <Select.Option value={"11"}>GMT +11:00</Select.Option>
                                <Select.Option value={"12"}>GMT +12:00</Select.Option>
                                <Select.Option value={"13"}>GMT +13:00</Select.Option>
                                <Select.Option value={"14"}>GMT +14:00</Select.Option>
                            </Select>
                        )}
                    </O2OFormItem>

                </Col>
                <Col xs={24}>

                    <O2OFormItem label="Currency">
                        {props.form.getFieldDecorator("currency", {
                            rules: [{
                                required: true,
                                message: "Required"
                            }],
                            initialValue: props.personalize.currency ? props.personalize.currency : "VND"
                        })(
                            <Select showSearch placeholder="Select" showArrow >
                                {Object.keys(TypeCurcenry).map((item: string) => <Select.Option value={item}>{TypeCurcenry[item]}</Select.Option>)}
                            </Select>
                        )}
                    </O2OFormItem>

                </Col>
                <Col xs={24}>

                    <O2OFormItem label="Country Name">
                        {props.form.getFieldDecorator("countryName", {
                            rules: [{
                                required: true,
                                message: "Required"
                            }],
                            initialValue: props.personalize.countryName ? props.personalize.countryName : "Vietnam"
                        })(
                            <Select showSearch placeholder="Select" showArrow onSelect={(e: string) => {
                                const coutry: TypeCountry | undefined = _.find(props.coutries, (item: TypeCountry) => item.name == e)
                                props.form.setFieldsValue({
                                    zipCode: coutry ? coutry.code : undefined
                                })
                            }}>
                                {props.coutries.map((item: TypeCountry) => <Select.Option value={item.name}>{item.name} / {item.alpha2} / {item.alpha3}</Select.Option>)}
                            </Select>
                        )}
                    </O2OFormItem>

                </Col>
                <Col xs={24}>

                    <O2OFormItem label="Zip code">
                        {props.form.getFieldDecorator("zipCode", {
                            rules: [{
                                required: false,
                                message: "Required"
                            }],
                            initialValue: props.personalize.zipCode
                        })(
                            <Input placeholder="Zip code" />
                        )}
                    </O2OFormItem>

                </Col>
                <Col xs={24}>

                    <O2OFormItem label="Country Phone Code">
                        {props.form.getFieldDecorator("countryPhoneCode", {
                            rules: [{
                                required: false,
                                message: "Required"
                            }],
                            initialValue: props.personalize.countryPhoneCode
                        })(
                            <Input placeholder="Country Phone Code" />
                        )}
                    </O2OFormItem>

                </Col>
                <Col xs={24}>

                    <O2OFormItem label="Required Country Phone Code">
                        {props.form.getFieldDecorator("requiredCountryPhoneCode", {
                            rules: [{
                                required: false,
                                message: "Required"
                            }],
                            initialValue: props.personalize.requiredCountryPhoneCode,
                            valuePropName: "checked"

                        })(
                            <Switch />
                        )}
                    </O2OFormItem>

                </Col>
                <Col xs={24}>

                    <Button loading={loading} type="primary" onClick={() => {
                        onSubmit()
                    }}>Update</Button>

                </Col>

            </Row>
        </Form>
    )
}

export const FormUpdatePersonalize = Form.create<IProps>()(FormD)