import { Methods, RestClient } from "o2o_layout/lib/common/rest";
import { BIGDATA_CONSTANT } from "../../../../common/constant";
import { TypeCriteriaPermission } from "../../permisions/model/permissions";

class DAL {
    search(criterial: TypeCriteriaPermission) {
        const { pageSize = 10 } = criterial ? criterial : { pageSize: 10 };
        let url = BIGDATA_CONSTANT.url.api + `mngmnt/permission/search?pageSize=${pageSize}`;

        let paramsUrl = new URLSearchParams();
        if (criterial && criterial.page) {
            const p = criterial.page - 1;
            paramsUrl.append("page", "" + p);
        }
        if (criterial && criterial.page) {
            url = url + "&" + paramsUrl.toString();
        }
        var restClient = new RestClient(Methods.POST, url);

        var headers = {};
        const payload = {
            query: criterial.query
        }
        var body = payload ? payload : {}

        return new Promise((resolve, reject) => {
            restClient.execute(headers, body).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }

    delete(id: string) {
        let url = BIGDATA_CONSTANT.url.api + `mngmnt/permission/${id}/delete`;
        var restClient = new RestClient(Methods.POST, url);
        return new Promise((resolve, reject) => {
            restClient.execute({}, {}).then(
                (response: any) => {
                    const user: any = response.data;
                    resolve(user);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    }

    add(dto: any) {
        let url = BIGDATA_CONSTANT.url.api + `mngmnt/permission/add `;
        var restClient = new RestClient(Methods.POST, url);

        return new Promise((resolve, reject) => {
            restClient.execute({}, dto).then(
                (response: any) => {
                    const user: any = response.data;
                    resolve(user);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    }

    update(id: string, dto: any) {
        let url = BIGDATA_CONSTANT.url.api + `mngmnt/permission/${id}/update`;
        var restClient = new RestClient(Methods.POST, url);

        return new Promise((resolve, reject) => {
            restClient.execute({}, dto).then(
                (response: any) => {
                    const user: any = response.data;
                    resolve(user);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    }
}

export const SAPermission = new DAL()