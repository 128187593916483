import * as React from 'react';
import Helmet from 'react-helmet';

interface IPropsTitle {
    title?: string
}

export const MetaHeader = (props: IPropsTitle) => {
    return (
        <Helmet>
            <title>{props.title ? props.title : "ACCOUNT Application"}</title>
            <meta name="description" content="ACCOUNT Application" />
        </Helmet>
    )
}