import * as React from "react"
import { Form, Row, Col, Tooltip, Icon, Switch, InputNumber, Button, notification } from 'antd';
import _ from "lodash";
import { FormComponentProps } from 'antd/lib/form';
import { CommonResponse } from "../../../redux/model";
import { ConsoleUsersDAL } from "../ConsoleUserDAL";
import { useAsyncRetry } from "react-use";
import { BIGDATA_CONSTANT } from "../../../common/constant";
import { useSelector } from "../../../redux/store";
import { IState } from "../../../redux/reducer";
import { getPrimaryColor } from "../../../common/helper";

interface IProps extends FormComponentProps {
    visible: boolean
    onClose: any
    commonActionFromDAL: any,
    success: any
}

export const SessionPolicy = Form.create<IProps>()((props: IProps) => {

    const [loading, setLoading] = React.useState(false)

    const uiSetting = useSelector((state: IState) => state.uiSetting)

    const load = async () => {
        const result: Promise<CommonResponse> = await props.commonActionFromDAL(ConsoleUsersDAL, "getSessionPolicy")
        try {
            const payload = await result
            return payload
        } catch (error) {
            return new CommonResponse()
        }
    }

    const stateGetSessionPolicy = useAsyncRetry(async () => {
        if (props.visible) {
            const data: CommonResponse = await load();
            return data
        }
        return new CommonResponse()
    }, [props.visible])

    const sessionPolicy = stateGetSessionPolicy.value ? stateGetSessionPolicy.value : new CommonResponse()


    return (
        <Form layout="horizontal" colon={false} hideRequiredMark={true}>
            <Row type="flex" gutter={[10, 15]}>
                <Col xs={24}>

                    <Form.Item style={{ marginBottom: 0 }} label={<span>

                        Invalidate Session <Tooltip title="Required to login if users are inactive after X minutes">
                            <Icon type="question-circle" />
                        </Tooltip>

                    </span>}>

                        {props.form.getFieldDecorator("enabledInvalidSession", {
                            initialValue: _.get(sessionPolicy.result, "enabledInvalidSession", false),
                            valuePropName: "checked"
                        })(
                            <Switch style={{ marginRight: 10 }} checkedChildren={"Enabled"} unCheckedChildren={"Disabled"} onChange={(e: boolean) => {
                                if (e) {
                                    props.form.setFieldsValue({
                                        invalidSessionAfter: 30
                                    })
                                } else {
                                    props.form.setFieldsValue({
                                        invalidSessionAfter: 0
                                    })
                                }
                            }} />
                        )}
                        {props.form.getFieldDecorator("invalidSessionAfter", {
                            initialValue: _.get(sessionPolicy.result, "invalidSessionAfter", 0),
                        })(
                            <InputNumber disabled={!props.form.getFieldValue("enabledInvalidSession")} placeholder="Enter" min={1} step={1} />
                        )} Minutes

                    </Form.Item>

                    <Form.Item label={<span>

                        Prevent multiple Sessions
                        <Tooltip title="Only one session is able to exist at a time">
                            <Icon style={{ marginLeft: 5 }} type="question-circle" />
                        </Tooltip>

                    </span>}>

                        {props.form.getFieldDecorator("preventMultiSessions", {
                            initialValue: _.get(sessionPolicy.result, "preventMultiSessions", false),
                            valuePropName: "checked"
                        })(
                            <Switch style={{ marginRight: 10 }} checkedChildren={"Enabled"} unCheckedChildren={"Disabled"} />
                        )}

                    </Form.Item>

                    <Form.Item>
                        <Button loading={loading} type="primary" onClick={() => {
                            props.form.validateFieldsAndScroll((err: any, values: any) => {
                                if (err) {
                                    return
                                }
                                setLoading(true)

                                props.commonActionFromDAL(ConsoleUsersDAL, "saveSessionPolicy", values)
                                    .then((result: any) => {
                                        setLoading(false)
                                        if (result && result.success) {
                                            notification.success({
                                                message: BIGDATA_CONSTANT.message.default.title.success,
                                                description: BIGDATA_CONSTANT.message.notification.success.saved,
                                                icon: <Icon type="check-circle" style={{ color: getPrimaryColor(uiSetting) }} />
                                            })
                                            setTimeout(() => {
                                                props.onClose()
                                                props.success()
                                                stateGetSessionPolicy.retry()
                                            }, 1000);
                                        } else {
                                            notification.error({
                                                message: BIGDATA_CONSTANT.message.default.title.error,
                                                description: _.get(result, "message", "Something was wrong.")
                                            })
                                        }
                                    })
                            })
                        }}>Save</Button>
                    </Form.Item>

                </Col>


            </Row>



        </Form>

    )
})