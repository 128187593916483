import { IState } from '../../../redux/reducer/index';
import { O2OCommonContainer } from '../../../common/container/O2OContainer';
import { UserProfileComponent } from './UserProfileComponent';
import { bindActionCreators } from 'redux';
import { updateAccount, changePwd, updateEDMSendInBlue } from '../ConsoleUsersAction';
import { updateOrgByAdminClient } from '../../orgs/OrgsAction';



function mapStateToProps(rootState: IState) {
    return {  
        edmSendInBlue: rootState.edmSendInBlue
    };
}


function mapDispatchToProps(dispatch: any) {
    return {
        updateAccount: bindActionCreators(updateAccount, dispatch),
        updateOrgByAdminClient: bindActionCreators(updateOrgByAdminClient, dispatch),
        changePwd: bindActionCreators(changePwd, dispatch),
        updateEDMSendInBlue: bindActionCreators(updateEDMSendInBlue, dispatch),
        // getEDMSendInBlue: bindActionCreators(getEDMSendInBlue, dispatch)
    };
}

export const UserProfileContainer = O2OCommonContainer(UserProfileComponent, mapStateToProps, mapDispatchToProps)