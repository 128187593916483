import * as React from 'react';
import "./login.scss";

import { Location, History } from 'history';
import queryString from 'query-string';
import _ from 'lodash';

interface LoginComponentProps  {
    history: History,
    location: Location,
    userLogout: any,
    userLogoutAll: any
}


export class LogoutComponent extends React.Component<LoginComponentProps, any>  {

    state = {
        
        url: ''
    }

    componentDidMount() {
       
        let query: any = queryString.parse(this.props.location.search);
        let isLogoutAll: string | undefined = "false";
        
        if(query && !_.isEmpty(query)){
            let url: string = query.url
            let all: string = query.signoutall ? query.signoutall : "false"
            
            if(url){
                this.setState({url: url})
            }
            isLogoutAll = all
        }
        if(isLogoutAll == "true"){
            this.props.userLogoutAll()
            .then(()=>{
                if(this.state.url){
                    window.location.href = this.state.url;
                }else{
                    this.props.history.replace("login")
                }
            })
        }else{
            this.props.userLogout()
            .then(()=>{
                if(this.state.url){
                    window.location.href = this.state.url;
                }else{
                    this.props.history.replace("login")
                }
            })
        }
       
        
    }

   

    renderTheme() {
        let query: any = queryString.parse(this.props.location.search);
        let isLogoutAll: string | undefined = query.signoutall ? query.signoutall : "false"
        return (
            <div >
               {isLogoutAll == "true" ? "Log-out all devices" : "Log-out"} 
            </div>
        )
    }

    render() {
        return (
            this.renderTheme()
        );
    }
}

