import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {unregister} from './registerServiceWorker';
import './index.scss';
import { MainApplication } from './MainApplication';


ReactDOM.render(
    (

        <MainApplication />

    ),
    document.getElementById('root'));

    unregister();
