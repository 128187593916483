import { Badge, Breadcrumb, Button, Col, Descriptions, Divider, Drawer, Dropdown, Form, Icon, Input, Menu, Modal, Row, Select, Switch, Table, Tag, Tooltip, Typography, notification } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { ClickParam } from 'antd/lib/menu';
import { PaginationProps } from 'antd/lib/pagination';
import { ColumnProps } from 'antd/lib/table';
import _, { isArray, size } from 'lodash';
import moment from 'moment';
import { LongTextTd, O2OCustomPaging } from 'o2o_layout';
import { Organizations, TypeOrganization } from 'o2o_layout/lib/common/model';
import * as React from 'react';
import { FormattedNumber } from 'react-intl';
import ReactJson from 'react-json-view';
import { Link } from 'react-router-dom';
import CommonTypeProps from '../../common/CommonTypeProps';
import { BIGDATA_CONSTANT } from '../../common/constant';
import { getCurrentURL, getPrimaryColor } from '../../common/helper';
import { DashboardPageLayout } from '../../layout/dashboard/DashboardPageLayout';
import { CommonResponse } from '../../redux/model/commonResponse';
import { Confirm2FA } from '../../shares/Confirm2FA';
import { DrawerExportDataWithout2FA } from '../../shares/DrawerExportData';
import DrawerViewJSON from '../../shares/DrawerViewJSON';
import { MetaHeader } from '../../shares/MetaHeader';
import DrawerOrgInfo from './DrawerOrgInfo';
import { ModalCreateOrg } from './ModalCreateOrg';
import { typeAddOrgs, typeDeleteOrg, typeGetOrgs, typeUpdateOrg } from './OrgsAction';
import { OrgsDAL, typeCriteriaSearchOrgs } from './OrgsDAL';
import DrawerConfirmDelete from './components/DrawerConfirmDelete';
import OrderByCriteria from './components/OrderByCriteria';
import SortByCriteria from './components/SortByCriteria';
import { FormLinkedCompany } from './link-orgs/FormLinkedCompany';
import { LinkOrg, LinkOrgsNoContent } from './link-orgs/model/linkOrgs';
import './org.scss';
import { DrawerSetupOrg } from './setup/DrawerSetupOrg';


interface Props extends CommonTypeProps {
    organizations: Organizations,
    getOrgs: typeGetOrgs,
    addOrgs: typeAddOrgs,
    commonResponse: CommonResponse,
    deleteOrg: typeDeleteOrg,
    updateOrg: typeUpdateOrg,
    linkOrg: any,
    getLinkOrg: any,
    linkOrgsNoContent: LinkOrgsNoContent
}

interface IFormFilterProps extends FormComponentProps {
    onSearch: any
    onExport: () => void
}

const FormFilterComponent = Form.create<IFormFilterProps>({
    name: 'filterform',

})(
    class FormFilterClass extends React.Component<IFormFilterProps, any> {
        onSearch = (e: any) => {
            e.preventDefault();
            this.props.form.validateFields((err: any, values: any) => {
                if (err) {
                    return;
                }
                this.props.onSearch(values)
            })

        }

        render() {
            const { getFieldDecorator } = this.props.form
            return (
                <Form layout="vertical" title="Search">
                    <Row gutter={[4, 4]} type="flex" justify="start">

                        <Col xs={24} sm={5} md={5} lg={5} xl={5} style={{ flexGrow: 1 }}>
                            <Form.Item label="Info">
                                {getFieldDecorator('freeText', {

                                })(
                                    <Input.Search onPressEnter={this.onSearch} placeholder="Name, email, phone, website..." allowClear></Input.Search>
                                )}
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={4} md={4} lg={4} xl={4} style={{ flexGrow: 1 }}>
                            <Form.Item label="Account Type">
                                {getFieldDecorator("accountTypes", {})(
                                    <Select placeholder="Select" mode="multiple" maxTagCount={1} maxTagTextLength={6} allowClear>
                                        <Select.Option value="Enterprise">Enterprise</Select.Option>
                                        <Select.Option value="Lite">Lite</Select.Option>
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={4} md={4} lg={4} xl={4} style={{ flexGrow: 1 }}>
                            <Form.Item label="Industry">
                                {getFieldDecorator("industries", {})(
                                    <Select placeholder="Select" mode="multiple" maxTagCount={1} allowClear maxTagTextLength={6}>
                                        <Select.Option key="FMCG">FMCG</Select.Option>
                                        <Select.Option key="Retail">Retail</Select.Option>
                                        <Select.Option key="Education">Education</Select.Option>
                                        <Select.Option key="Electronic Distribution">Electronic Distribution</Select.Option>
                                        <Select.Option key="Real Estate">Real Estate</Select.Option>
                                        <Select.Option key="Spa">Spa</Select.Option>
                                        <Select.Option key="Service">Service</Select.Option>
                                        <Select.Option key="Automotive">Automotive</Select.Option>
                                        <Select.Option key="Manufacturing">Manufacturing</Select.Option>
                                        <Select.Option key="Technology">Technology</Select.Option>
                                        <Select.Option key="Pharma">Pharma</Select.Option>
                                        <Select.Option key="Marketing Agency">Marketing Agency</Select.Option>
                                        <Select.Option key="F&B">F&B</Select.Option>
                                        <Select.Option key="Other">Other</Select.Option>
                                        <Select.Option key="Banking">Banking</Select.Option>
                                        <Select.Option key="Insurance">Insurance</Select.Option>
                                        <Select.Option key="Travel">Travel</Select.Option>
                                        <Select.Option key="Airline">Airline</Select.Option>
                                        <Select.Option key="Fintech">Fintech</Select.Option>
                                        <Select.Option key="Construction Material">Construction Material</Select.Option>
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={4} md={4} lg={4} xl={4} style={{ flexGrow: 1 }}>
                            <Form.Item label="Status">
                                {getFieldDecorator("status", {})(
                                    <Select placeholder="Select" mode="multiple" maxTagCount={1} maxTagTextLength={6} allowClear>
                                        <Select.Option value="active">Active</Select.Option>
                                        <Select.Option value="inactive">Inactive</Select.Option>
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={4} md={4} lg={4} xl={4} style={{ flexGrow: 1 }}>
                            <Form.Item label="Environment">
                                {getFieldDecorator("envs", {})(
                                    <Select placeholder="Select" mode="multiple" maxTagCount={1} maxTagTextLength={6} allowClear>
                                        <Select.Option value="Production">Production</Select.Option>
                                        <Select.Option value="Staging">Staging</Select.Option>
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item label={<span style={{ color: "#fff" }}>search</span>}>

                                <Row type='flex' align={"middle"}>

                                    <Button style={{ marginRight: 5 }} type="primary" onClick={this.onSearch} icon="search">Search</Button>

                                    <Dropdown overlay={<Menu onClick={(e: ClickParam) => {
                                        if (e.key === 'export') {
                                            this.props.onExport()
                                        }
                                    }}>
                                        <Menu.Item key={"export"}>Export Data</Menu.Item>
                                    </Menu>}>

                                        <Button icon="more"></Button>
                                    </Dropdown>

                                </Row>

                            </Form.Item>
                        </Col>

                    </Row>

                </Form>
            )
        }
    }
)

export class OrgsComponent extends React.Component<Props, any> {

    formRef: any
    formRefLinked: any
    searchInput: any


    state = {
        pagination: {} as PaginationProps,
        visibleExportData: false,
        copied: false,
        visible2FA: false,
        criteria: {
            order: "createdAt:DESC"
        } as typeCriteriaSearchOrgs,
        isVisibleModal: false,
        isVisibleLinkedCompnay: false,
        visibleJson: false,
        selectedOrg: {} as TypeOrganization,
        selectedOrgSetup: undefined as TypeOrganization | undefined,
        visibleDrawerInfo: false as boolean,
        columns: [
            {
                title: '#',
                key: 'index',
                width: 45,
                render: (text: any, record: TypeOrganization, index: number) => {
                    return index + 1
                },
            },
            {
                title: 'Status',
                dataIndex: 'status',
                width: 100,
                ellipsis: true,
                key: 'status', render: (text: any, record: TypeOrganization, index: number) => {
                    const renderTitle = () => {
                        if (record.status === "active") {
                            return (
                                <>
                                    <span>You are about to change the status of Organization: <strong>{record.name}</strong> to Inactive.</span>
                                    <br />
                                    <span>All accesses will be blocked to this organization.</span>
                                    <br />
                                    <span>Are you sure to continue?</span>
                                </>

                            )
                        } else {
                            return (
                                <>
                                    <span>You are about to change the status of Organization: <strong>{record.name}</strong> to Active.</span>
                                    <br />
                                    <span>Are you sure to continue?</span>
                                </>

                            )
                        }
                    }
                    return (
                        <Switch onChange={(e) => {
                            Modal.confirm({
                                title: <> {record.status === "active" ? "Inactivate Status" : " Activate Status"} </>,
                                content: renderTitle(),
                                okText: record.status === "active" ? "Inactive" : "Active",
                                cancelText: "Cancel",
                                onOk: async () => {
                                    await this.onUpdateStatus(record.id, record.status === "active" ? "inactive" : "active")
                                },
                                icon: <Icon type="exclamation-circle" />,
                                okButtonProps: {
                                    type: record.status === "active" ? "danger" : "primary",
                                    style: { backgroundColor: record.status === "inactive" ? getPrimaryColor(this.props.uiSetting) : "" }
                                }
                            })
                        }} style={{ cursor: "default" }} checked={record.status === "active" ? true : false}
                            unCheckedChildren={"Inactive"}
                            checkedChildren={"Active"}
                        />
                    )
                },
            },
            {
                title: 'Account Type', dataIndex: 'accountType', key: 'accountType', sorter: false,
                width: 150,
                render: (text: any, record: TypeOrganization, index: number) => {
                    return (
                        <>
                            {this.renderAccountType(record.accountType)}
                            {record.status === "inactive" && <Badge color="red" />}
                        </>
                    )
                },

            },
            {
                title: 'Brand Name',
                dataIndex: 'brand_name',
                key: 'brand_name',
                render: (text: any, record: TypeOrganization, index: number) => {
                    return (
                        <span onClick={() => this.showDetail(record)} style={{ cursor: "pointer" , color: getPrimaryColor(this.props.uiSetting)}} >
                            <LongTextTd text={record.fullName ? record.fullName : record.name} textDisplay={record.name} />
                            {
                                //@ts-ignore
                                record.environment === "Staging" && <span style={{ color: "#e9e400f5", marginLeft: 5 }}>*</span>
                            }
                        </span>
                    )
                },
                ellipsis: true,
                width: 200,

            },
            {
                title: 'Industry',
                dataIndex: 'industry',
                key: 'industry',
                width: 150,
                ellipsis: true,
                render: (text: any, record: TypeOrganization, index: number) => {
                    return (
                        <>
                            {this.renderPhase(record.phase)}
                            {BIGDATA_CONSTANT.industry.hasOwnProperty(record.industry || "") ? BIGDATA_CONSTANT.industry[record.industry || ""] : record.industry}
                        </>
                    )
                },
            },
            {
                title: 'Environment',
                key: 'environment',
                width: 150,
                render: (text: any, record: TypeOrganization, index: number) => {
                    //@ts-ignore
                    return <LongTextTd text={record.environment} />
                },
            },
            {
                title: 'Website',
                dataIndex: 'website',
                key: 'website',
                ellipsis: true,
                width: 250,
                render: (text: any, record: TypeOrganization, index: number) => {
                    return (
                        record.website ? <a href={record.website} target="_blank">{record.website}</a> : '--/--'
                    )
                },
            },
            {
                title: 'Phone',
                dataIndex: 'phone',
                key: 'phone',
                width: 150,
                ellipsis: true,
                render: (text: any, record: TypeOrganization, index: number) => {
                    return (
                        <LongTextTd text={record.phone} />
                    )
                },
            },
            {
                title: 'Email',
                dataIndex: 'email',
                key: 'email',
                width: 150,
                ellipsis: true,
                render: (text: any, record: TypeOrganization, index: number) => {
                    return (
                        <LongTextTd text={record.email} />
                    )
                },
            },
            {
                title: "Created At",
                key: "creation_time",
                width: 150,
                ellipsis: true,
                render(text: any, record: TypeOrganization, index: number) {
                    return record.createdAt ? (
                        <Tooltip title={`Created by ${record.createdBy} at ${moment(record.createdAt).format("DD/MM/YYYY HH:mm:ss")}`}>
                            {moment(record.createdAt).fromNow()}
                        </Tooltip>
                    ) : "--/--"
                },
            }, {
                title: "Modification Time",
                key: "modification_time",
                width: 150,
                ellipsis: true,
                render(text: any, record: TypeOrganization, index: number) {
                    return record.updatedAt ? (
                        <Tooltip title={`Updated by ${record.updatedBy} at ${moment(record.updatedAt).format("DD/MM/YYYY HH:mm:ss")}`}>
                            {moment(record.updatedAt).fromNow()}
                        </Tooltip>
                    ) : "--/--"
                },
            }, {
                title: 'Action',
                width: 130,
                key: "action",
                fixed: "right",
                render: (text: any, record: TypeOrganization, index: number) => {
                    return (
                        <>

                            <Tooltip title="Edit info">
                                <Button size="small" icon="edit" type="link" onClick={() => {
                                    this.setState({
                                        isVisibleModal: true,
                                        selectedOrg: record
                                    })
                                }}></Button>
                            </Tooltip>
                            <Divider type="vertical" />
                            <Tooltip title="Setup">
                                <Button size="small" icon="setting" type="link" onClick={() => {
                                    this.setState({
                                        selectedOrgSetup: record
                                    })
                                }}></Button>
                            </Tooltip>
                            <Divider type="vertical" />
                            <Dropdown overlay={
                                <Menu onClick={(e: any) => {
                                    if (e.key === "delete") {
                                        this.setState({
                                            visibleConfirmDelete: true,
                                            selectedOrg: record
                                        })
                                    }
                                    else if (e.key === "json") {
                                        this.setState({
                                            visibleJson: true,
                                            selectedOrg: record
                                        })
                                    } else if (e.key === "link-company") {
                                        this.openLinkedDrawler(record)
                                    } else if (e.key === "rerun-system-permission") {
                                        Modal.confirm({
                                            content: BIGDATA_CONSTANT.message.default.confirmText,
                                            title: BIGDATA_CONSTANT.message.default.titleConfirm,
                                            onOk: () => {
                                                this.onRerunSystemPermission(record.id)
                                            }
                                        })
                                    }
                                }}
                                >
                                    <Menu.Item key="link-company">
                                        <Tooltip title="Link Company">
                                            <Button size="small" icon="link" type="link">Link Company</Button>
                                        </Tooltip>
                                    </Menu.Item>
                                    <Menu.Item key="rerun-system-permission">
                                        <Tooltip title="Rerun System Permissions">
                                            <Button size="small" icon="reload" type="link">Init Pango Permissions</Button>
                                        </Tooltip>
                                    </Menu.Item>

                                    <Menu.Item key="json">
                                        <Tooltip title="View JSON">
                                            <Button size='small' type='link' icon='eye'>View JSON</Button>
                                        </Tooltip>
                                    </Menu.Item>

                                    <Menu.Item key="delete">
                                        <Tooltip title="Delete">
                                            <Button size='small' type='link' icon='delete'>Delete</Button>
                                        </Tooltip>
                                    </Menu.Item>

                                </Menu>
                            }>
                                <Button type='link' icon='more' size='small'></Button>
                            </Dropdown>
                        </>
                    )
                }
            },
        ] as ColumnProps<{}>[],
        fixedAction: "right",
        expandedRowKeys: [],
        columnLinkOrgs: [
            {
                title: 'Index',
                dataIndex: 'index',
                key: 'index',
                width: 80,
                render: (t: string, record: LinkOrg, index: number) => {
                    return (<span>{index + 1}</span>)
                }
            },
            {
                title: 'Integration Name',
                dataIndex: 'integrationName',
                key: 'integrationName',
                ellipsis: true,
                render: (t: string, record: LinkOrg, index: number) => {
                    return <LongTextTd text={record.integrationName} />
                }

            }, {
                title: 'CDP Company Id',
                dataIndex: 'cdpOrgId',
                key: 'cdpOrgId',
                ellipsis: true,
                render: (t: string, record: LinkOrg, index: number) => {
                    return <LongTextTd text={record.o2oCdpOrgId} />
                }

            }, {
                title: 'Linked Source',
                dataIndex: 'linkedSource',
                key: 'linkedSource',
                ellipsis: true,
                render: (t: string, record: LinkOrg, index: number) => {
                    return <LongTextTd text={record.linkedSource} />
                }

            }, {
                title: 'linked Id',
                dataIndex: 'linkedOrgId',
                key: 'linkedOrgId',
                ellipsis: true,
                render: (t: string, record: LinkOrg, index: number) => {
                    return <LongTextTd text={record.linkedOrgId} />
                }

            }
        ] as ColumnProps<{}>[],
        visibleConfirmDelete: false,
        sortBy: "createdAt",
        orderBy: "DESC"
    }

    onUpdateStatus = async (id: string, status: string) => {
        const result = await this.props.commonActionFromDALV2(OrgsDAL.updateStatus, id, status)
        try {
            const response = await result
            if (response.success) {
                setTimeout(() => {
                    this.load();
                }, 1000);
                notification.success({
                    message: BIGDATA_CONSTANT.message.default.title.success,
                    description: BIGDATA_CONSTANT.message.notification.success.update,
                    icon: <Icon type="check-circle" style={{ color: getPrimaryColor(this.props.uiSetting) }} />
                })
            } else {
                notification.error({
                    message: BIGDATA_CONSTANT.message.default.title.error,
                    description: _.get(response, "message", "Something went wrong"),
                })
            }
        } catch (error) {
            notification.error({
                message: BIGDATA_CONSTANT.message.default.title.error,
                description: _.get(error, "message", "Something went wrong"),
            })
        }
    }

    onRerunSystemPermission = async (id: string) => {
        const result: Promise<any> = this.props.commonActionFromDALV2(OrgsDAL.rerunPermissionSystemGroup, id)
        try {
            const response = await result
            if (response.success) {
                setTimeout(() => {
                    this.load();
                }, 1000);
                notification.success({
                    message: BIGDATA_CONSTANT.message.default.title.success,
                    description: "Init Permission Groups successfully",
                    icon: <Icon type="check-circle" style={{ color: getPrimaryColor(this.props.uiSetting) }} />
                })
            } else {
                notification.error({
                    message: BIGDATA_CONSTANT.message.default.title.error,
                    description: response.message,
                })
            }
        } catch (error) {
            notification.error({
                message: BIGDATA_CONSTANT.message.default.title.error,
                description: "Something was wrong",
            })
        }
    }

    renderPhase = (phase: string) => {
        switch (phase) {
            case "Pilot":
                return <Tag style={{ background: "#FC5858", color: "#fff", borderColor: "#FC5858", padding: "0px 6px", borderRadius: 5 }}>P</Tag>
            case "Tuning & Grow":
                return <Tag style={{ background: "#FFB800", color: "#fff", borderColor: "#FFB800", padding: "0px 6px", borderRadius: 5 }}>T</Tag>
            case "Retention":
                return <Tag style={{ background: "#04AD29", color: "#fff", borderColor: "#04AD29", padding: "0px 6px", borderRadius: 5 }}>R</Tag>
            case "Dropped":
                return <Tag style={{ background: "#797979", color: "#fff", borderColor: "#797979", padding: "0px 6px", borderRadius: 5 }}>D</Tag>
            default:
                return null
        }
    }

    renderAccountType = (accountType: string) => {
        switch (accountType) {
            case "Trial":
                return <Tag style={{ background: "#EB2C56", color: "#fff", borderColor: "#EB2C56", }}>{accountType}</Tag>
            case "Basic":
                return <Tag style={{ background: "#FCBC04", color: "#fff", borderColor: "#FCBC04" }}>{accountType}</Tag>
                break;
            case "Profesional":
                return <Tag style={{ background: "#38A456", color: "#fff", borderColor: "#38A456" }}>{accountType}</Tag>
                break;
            case "Enterprise":
                return <Tag style={{ background: "#9837FF", color: "#fff", borderColor: "#9837FF" }}>{accountType}</Tag>
                break;
            case "Contract":
                return <Tag style={{ background: "#47BBBD", color: "#fff", borderColor: "#47BBBD" }}>{accountType}</Tag>
                break;
            default:
                return <Tag>{accountType}</Tag>
                break;
        }
    }

    openLinkedDrawler = (o: TypeOrganization) => {

        this.setState({
            selectedOrg: o,
            isVisibleLinkedCompnay: true
        })
    }

    updateOrg = (item: TypeOrganization) => {
        let org: TypeOrganization = Object.assign(item, { active: !item.active })
        this.props.setGlobalLoading(true)
        this.props.updateOrg(org)
            .then(() => {

                if (this.props.commonResponse.success) {
                    this.props.setGlobalLoading(false)
                    notification.success({
                        message: BIGDATA_CONSTANT.message.default.title.success,
                        description: `Set ${item.name} "${!item.active ? "Inactive" : "Active"}" successfully`,
                        icon: <Icon type="check-circle" style={{ color: getPrimaryColor(this.props.uiSetting) }} />
                    })
                    this.load()

                    this.setState({ isVisibleModal: false })
                } else {
                    notification.error({
                        message: BIGDATA_CONSTANT.message.default.title.error,
                        description: this.props.commonResponse.message,
                    })
                }

            })
            .catch(() => {

                this.props.setGlobalLoading(false)
            })
    }

    showDetail = (item: TypeOrganization) => {
        this.setState({ visibleDrawerInfo: true, selectedOrg: item })
    }



    componentDidMount = () => {
        this.load()
        this.props.updateMetaTitle({ title: BIGDATA_CONSTANT.trackTitle.ConsoleOrg })
        this.timer = setTimeout(() => {
            this.props.updateMeta({
                name: BIGDATA_CONSTANT.trackTitle.ConsoleOrg,
                title: BIGDATA_CONSTANT.trackTitle.ConsoleOrg,
                app: "ACCOUNT",
                url: getCurrentURL(this.props.location)
            })
            clearTimeout(this.timer)
        }, 5000)
    }
    timer: any

    componentWillUnmount() {
        clearTimeout(this.timer)
    }


    load = () => {
        this.props.setGlobalLoading(true)
            .then(() =>
                this.props.getOrgs(this.state.criteria)
                    .then(() => {
                        this.props.setGlobalLoading(false)
                        let pagination = { ...this.props.organizations.results.page }
                        let convertPaginationTable: PaginationProps = {
                            current: pagination.currentPage + 1,
                            pageSize: pagination.pageSize,
                            total: pagination.totalRecords,
                        }
                        this.setState({
                            pagination: Object.assign(this.state.pagination, convertPaginationTable),
                            criteria: Object.assign(this.state.criteria, { page: convertPaginationTable.current })
                        })
                    })
                    .catch(() => this.props.setGlobalLoading(false))
            )

    }

    handleTableChange = (pagination: any, filters: any, sorter: any) => {
        const pager = { ...this.state.pagination };
        pager.current = pagination.current;
        const order = sorter.field ? `${sorter.field}:${sorter.order == 'descend' ? 'DECS' : 'ASC'}` : 'createdAt:DESC'

        this.setState({
            pagination: pager,
            criteria: Object.assign(this.state.criteria, { order: order, page: pager.current })
        });


        this.load();
    };

    renderBreadCrumb = () => {
        return (
            <Row style={{ marginBottom: "7px" }} type="flex" justify="space-between" align="middle">
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/">Home</Link>
                    </Breadcrumb.Item>

                    <Breadcrumb.Item>Super Admin / Organizations</Breadcrumb.Item>
                </Breadcrumb>

                <Button type="primary" icon="plus" onClick={() => this.setState({ isVisibleModal: true, selectedOrg: {} })}>Add Organization</Button>
            </Row>

        )
    }

    renderDrawlerLinkedCompnay = () => {
        return (
            <Drawer
                width={this.calWidth()}
                placement="right"
                closable={true}
                onClose={() => { this.setState({ isVisibleLinkedCompnay: false }) }}
                visible={this.state.isVisibleLinkedCompnay}
                title="Link 3thrd company form"
            >
                <FormLinkedCompany wrappedComponentRef={(propsRef: any) => this.formRefLinked = propsRef} submit={this.submitLinked} />
            </Drawer>
        )
    }

    submitLinked = (e: any) => {
        e.preventDefault();
        this.formRefLinked.props.form.validateFieldsAndScroll((err: any, values: any) => {
            if (!err) {
                this.props.setGlobalLoading(true);
                values.o2oCdpOrgId = this.state.selectedOrg.id;
                values.linkedSource = "O2OCRM";
                this.props.linkOrg(values)
                    .then(() => {
                        this.props.setGlobalLoading(false)

                        if (this.props.commonResponse.success) {
                            notification.success({
                                message: BIGDATA_CONSTANT.message.default.title.success,
                                description: `Link company successfully`,
                                icon: <Icon type="check-circle" style={{ color: getPrimaryColor(this.props.uiSetting) }} />
                            })
                            this.setState({
                                isVisibleLinkedCompnay: false
                            })
                            this.formRefLinked.props.form.resetFields()
                            setTimeout(() => {
                                this.load();
                                this.props.getLinkOrg(this.state.selectedOrg.id)
                                this.setState({
                                    isVisible: false,
                                    selectedOrg: {}
                                })
                            }, 1000);
                        } else {
                            notification.error({
                                message: BIGDATA_CONSTANT.message.default.title.success,
                                description: this.props.commonResponse.message ? this.props.commonResponse.message : "Something was wrong...",
                            })
                        }
                    })

            }

        });
    }

    calWidth = () => {
        switch (this.props.ui.breakpoint) {
            case "xs":
                return "100%"
                break;
            case "md":
                return "80%"
                break;
            case "sm":
                return "80%"
                break;
            case "lg":
                return "700px"
                break;
            default:
                return "400px"
                break;
        }
    }

    deleteOrg = (record: TypeOrganization, codeOTP?: string) => {
        this.props.setGlobalLoading(true)
        this.props.deleteOrg(record.id, codeOTP || '')
            .then((e: any) => {

                if (this.props.commonResponse.success) {
                    this.setState({
                        visible2FA: false,
                        visibleConfirmDelete: false
                    })
                    this.props.setGlobalLoading(false)
                    notification.success({
                        message: BIGDATA_CONSTANT.message.default.title.success,
                        description: BIGDATA_CONSTANT.message.notification.success.delete,
                        icon: <Icon type="check-circle" style={{ color: getPrimaryColor(this.props.uiSetting) }} />
                    })
                    setTimeout(() => {
                        this.load()
                    }, 1000)

                } else {
                    if (e.message !== "Invalid PIN, please try again!") {
                        this.setState({
                            visible2FA: false
                        })
                    }
                    this.props.setGlobalLoading(false)
                    notification.error({
                        message: BIGDATA_CONSTANT.message.default.title.error,
                        description: this.props.commonResponse.message,
                    })
                }

            })
            .catch(() => {
                this.setState({
                    visible2FA: false
                })
                notification.error({
                    message: BIGDATA_CONSTANT.message.default.title.error,
                    description: this.props.commonResponse.message,
                })
                this.props.setGlobalLoading(false)
            })
    }

    handleSearch = (selectedKeys: any, confirm: any, dataIndex: string) => {
        confirm();
        if (dataIndex == "industry") {
            this.setState({ criteria: Object.assign(this.state.criteria, { industries: selectedKeys, page: 0 }) });
        } else if (dataIndex == 'accountType') {
            this.setState({ criteria: Object.assign(this.state.criteria, { accountTypes: selectedKeys, page: 0 }) });
        } else {
            this.setState({ criteria: Object.assign(this.state.criteria, { freeText: selectedKeys[0], page: 0 }) });
        }

        this.load()
    };

    handleReset = (clearFilters: any, dataIndex: string) => {
        clearFilters();
        if (dataIndex == "industry") {
            this.setState({ criteria: Object.assign(this.state.criteria, { industries: [], page: 0 }) });
        } else if (dataIndex == 'accountType') {
            this.setState({ criteria: Object.assign(this.state.criteria, { accountTypes: [], page: 0 }) });
        } else {
            this.setState({ criteria: Object.assign(this.state.criteria, { freeText: '', page: 0 }) });
        }

    };

    onSearch = (values: any) => {


        this.setState({
            criteria: Object.assign(this.state.criteria, { ...values, page: 0 })
        }, () => {
            this.load();
        })


    }



    renderTheme() {
        const { organizations } = this.props
        return (
            <DashboardPageLayout {...this.props} selectedLeftNav="12" selectedSubMenu="console-super">
                <div className="org-page">

                    <MetaHeader title={this.props.ui.meta.title} />

                    <div className="contentLayout">
                        {this.renderBreadCrumb()}

                        <div className="containerInner">
                            <Row gutter={[5, 5]} type='flex'>
                                <Col xs={24}>
                                    <Typography.Title level={4}>Organization</Typography.Title>
                                </Col>
                                <Col xs={24} >
                                    <FormFilterComponent
                                        onExport={() => {
                                            this.setState({
                                                visibleExportData: true
                                            })
                                        }}
                                        onSearch={this.onSearch}
                                    />
                                </Col>
                                <Col xs={24}>
                                    <Row gutter={[5, 5]} type="flex" justify="space-between" align="middle" style={{ marginBottom: -15 }}>
                                        <Col>
                                            <Button type="primary" size="small" icon="sync" shape="circle" onClick={this.load}></Button>
                                            <Typography.Text style={{ marginLeft: 10 }}>Found
                                                <span style={{ marginLeft: 3 }}><FormattedNumber value={this.props.organizations.results.page.totalRecords} /></span>
                                                <span style={{ marginLeft: 4 }}>record(s)</span>
                                                <span style={{ marginLeft: 3 }}>(<FormattedNumber value={this.props.organizations.results.timeTaken} /> ms)</span>
                                            </Typography.Text>
                                        </Col>
                                        <Col>
                                            <div style={{ display: "flex", gap: 5 }}>
                                                <SortByCriteria
                                                    sortBy={this.state.sortBy}
                                                    onSelected={(e: string) => {
                                                        this.setState({
                                                            criteria: Object.assign(this.state.criteria, { order: `${e}:${this.state.orderBy}` }),
                                                            sortBy: e
                                                        }, () => {
                                                            this.load()
                                                        })
                                                    }}
                                                />

                                                <OrderByCriteria
                                                    orderBy={this.state.orderBy}
                                                    onSelected={(e: string) => {
                                                        this.setState({
                                                            criteria: Object.assign(this.state.criteria, { order: `${this.state.sortBy}:${e}` }),
                                                            orderBy: e
                                                        }, () => {
                                                            this.load()
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                    </Row>

                                    <Table
                                        onChange={this.handleTableChange}

                                        onExpand={(expanded: boolean, record: TypeOrganization) => {

                                            if (expanded) {
                                                this.setState({
                                                    fixedAction: "",
                                                    expandedRowKeys: [record.id]
                                                })
                                                this.props.setGlobalLoading(true)
                                                this.props.getLinkOrg(record.id)
                                                    .then(() => {
                                                        this.props.setGlobalLoading(false)
                                                    })
                                            } else {
                                                this.setState({
                                                    fixedAction: "right",
                                                    expandedRowKeys: []
                                                })
                                            }

                                        }}
                                        expandedRowKeys={this.state.expandedRowKeys}
                                        expandedRowRender={(record: TypeOrganization) => <p style={{ margin: 0 }}>

                                            <Row gutter={[6, 6]} style={{ width: 800 }}>
                                                <Table
                                                    title={() => (
                                                        <React.Fragment>

                                                            <Button icon="link" type="link" onClick={() => {
                                                                this.openLinkedDrawler(record)
                                                            }}>Add Link company</Button>
                                                        </React.Fragment>
                                                    )}

                                                    size="small"
                                                    bordered
                                                    dataSource={this.props.linkOrgsNoContent.results}
                                                    columns={this.state.columnLinkOrgs}
                                                    expandedRowRender={(record: LinkOrg) => <p style={{ margin: 0 }}>

                                                        <Row gutter={[6, 6]}>
                                                            <Descriptions column={3} title="Detail">
                                                                <Descriptions.Item label="Object">
                                                                    <ReactJson src={record} />
                                                                </Descriptions.Item>



                                                            </Descriptions>
                                                        </Row>

                                                    </p>}
                                                // scroll={{ x: 500 }}
                                                />
                                            </Row>

                                        </p>}
                                        size="small"
                                        bordered={false}
                                        rowKey="id"
                                        style={{ marginTop: 20, fontSize: 12 }}
                                        columns={this.state.columns.map((item: any) => {
                                            if (item.key == 'action') {
                                                return { ...item, fixed: this.state.fixedAction }
                                            } else {
                                                return { ...item }
                                            }
                                        })}
                                        dataSource={organizations.results.content}
                                        pagination={false}
                                        scroll={{ x: 'calc(700px + 50%)' }}
                                    />


                                    {this.renderModalAddOrg()}
                                    {this.renderDrawlerLinkedCompnay()}
                                    {this.renderDrawerViewJSON()}
                                    {this.renderSetupCompany()}
                                    {this.renderDrawerConfirmDelete()}
                                    {this.renderConfirm2FA()}
                                    {this.renderExportData()}
                                    {this.renderDrawerOrgInfo()}
                                </Col>
                                <Col xs={24}>
                                    <O2OCustomPaging
                                        list={organizations}
                                        onChange={(e: any) => {
                                            this.setState({
                                                criteria: { ...this.state.criteria, ...{ page: e } }
                                            }, () => {
                                                this.load();
                                            })
                                        }}
                                        onChangePageSize={(e: any) => {
                                            this.setState({
                                                criteria: { ...this.state.criteria, ...{ page: 0, pageSize: e } }
                                            }, () => {
                                                this.load();
                                            })
                                        }}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </div>





                </div>
            </DashboardPageLayout>
        )
    }

    handleSubmit = () => {
        const form = this.formRef.props.form;

        form.validateFields(async (err: any, values: any) => {
            console.log(values);
            if (err) {
                return;
            }

            this.props.setGlobalLoading(true)

            values.status = values.status == true ? 'active' : 'inactive'

            if (this.state.selectedOrg && this.state.selectedOrg.id) {
                values.id = this.state.selectedOrg.id
            }
            if (values.effect_time && isArray(values.effect_time) && size(values.effect_time) === 2) {
                values['contractEffectiveFrom'] = values.effect_time[0].toDate().getTime()
                values['contractEffectiveTo'] = values.effect_time[1].toDate().getTime()
            }
            delete values['effect_time'];
            let promise: any = this.state.selectedOrg && this.state.selectedOrg.id ? this.props.updateOrg(values) : this.props.addOrgs(values)
            try {
                const response = await promise
                if (response.success) {
                    this.props.setGlobalLoading(false)

                    if (this.state.selectedOrg && this.state.selectedOrg.id) {
                        notification.success({
                            message: BIGDATA_CONSTANT.message.default.title.success,
                            description: `${this.state.selectedOrg.name} Organiziation updated successfully`,
                            icon: <Icon type="check-circle" style={{ color: getPrimaryColor(this.props.uiSetting) }} />
                        })
                    } else {
                        notification.success({
                            message: BIGDATA_CONSTANT.message.default.title.success,
                            description: `${this.props.commonResponse.result.name} Organiziation added successfully`,
                            icon: <Icon type="check-circle" style={{ color: getPrimaryColor(this.props.uiSetting) }} />
                        })
                    }
                    setTimeout(() => {
                        this.load()
                    }, 1000)

                    form.resetFields()
                    this.setState({ isVisibleModal: false })
                } else {
                    this.props.setGlobalLoading(false)
                    notification.error({
                        message: BIGDATA_CONSTANT.message.default.title.error,
                        description: response.message,
                    })
                }
            } catch (error) {
                notification.error({
                    message: BIGDATA_CONSTANT.message.default.title.error,
                    description: _.get(error, "message", 'Something went wrong')
                })
                this.props.setGlobalLoading(false)
            }
        })
    }

    renderModalAddOrg = () => {
        return (
            <ModalCreateOrg
                wrappedComponentRef={(propsRef: any) => this.formRef = propsRef}
                visible={this.state.isVisibleModal}
                onCancel={() => {
                    this.formRef.props.form.resetFields();
                    this.setState({
                        isVisibleModal: false,
                        selectedOrg: {}
                    })
                }}
                org={this.state.selectedOrg}
                onCreate={this.handleSubmit}
                loading={this.props.ui.isLoading}
            />
        )
    }

    renderDrawerViewJSON = () => {
        return <DrawerViewJSON
            visible={this.state.visibleJson}
            onClose={() => {
                this.setState({
                    visibleJson: false,
                    selectedOrg: {}
                })
            }}
            data={this.state.selectedOrg}
        />
    }

    renderSetupCompany = () => {
        return (
            <DrawerSetupOrg
                commonActionFromDAL={this.props.commonActionFromDAL}
                commonActionFromDALV2={this.props.commonActionFromDALV2}
                onClose={() => {
                    this.setState({
                        selectedOrgSetup: undefined
                    })
                }}
                company={this.state.selectedOrgSetup}
            />
        )
    }
    renderDrawerConfirmDelete = () => {
        return (
            <DrawerConfirmDelete
                openConfirm2FA={() => {
                    this.setState({
                        visible2FA: true
                    })
                }}
                visible={this.state.visibleConfirmDelete}
                onClose={() => {
                    this.setState({
                        visibleConfirmDelete: false,
                        selectedOrgSetup: undefined
                    })
                }}
                item={this.state.selectedOrg}
                onDelete={(record: TypeOrganization) => {
                    this.deleteOrg(record)
                    this.setState({
                        visibleConfirmDelete: false,
                        selectedOrgSetup: undefined
                    })
                }}
            />
        )
    }

    renderConfirm2FA = () => {
        return <Confirm2FA
            visible={this.state.visible2FA}
            onClose={() => {
                this.setState({
                    visible2FA: false
                })
            }}
            okModal={async (e: any, f: WrappedFormUtils<any>) => {
                const codeOTP = f.getFieldValue("codeOTP")
                this.deleteOrg(this.state.selectedOrg, codeOTP)

            }}
            loading={false}
            okText={"Delete"}
            type={'delete'}
        />
    }

    renderExportData = () => {
        return <DrawerExportDataWithout2FA
            visible={this.state.visibleExportData}
            onClose={() => {
                this.setState({
                    visibleExportData: false
                })
            }}
            totalRecords={this.props.organizations.results.page.totalRecords}
            commonActionDALV2={this.props.commonActionFromDALV2}
            criteria={this.state.criteria}
            disableButton={this.props.organizations.results.page.totalRecords >= 1000000}
            type={'organization_SpAdmin'}
        />
    }

    renderDrawerOrgInfo = () => {
        return <DrawerOrgInfo
            selectedRecord={this.state.selectedOrg}
            visible={this.state.visibleDrawerInfo}
            onClose={()=>{
                this.setState({
                    visibleDrawerInfo: false,
                    selectedOrg: {}
                })
            }}
        />
    }

    render() {
        return (
            this.renderTheme()
        );
    }
}

