

import * as React from 'react'
import { Row, Col, Typography } from 'antd';
import { FooterO2OComponent } from 'o2o_layout';

type Props = {

}


export const Footer: React.SFC<Props> = (props: Props) => {
    return (
        <Row>
            <Col

            >


                <div className="landing-form-footer" style={{ marginBottom: 100, padding: 0 }}>


                    <FooterO2OComponent style={{
                        textAlign: "left",
                        padding: "10px 0 50px 0"
                    }} footerContent={
                        <Row style={{ marginBottom: 20, marginTop: 20 }}>
                            <div>

                                <Typography.Text style={{ marginRight: 18 }}><a href="https://bytetech.io/" target="_blank">About ByteTech</a></Typography.Text>
                                <Typography.Text style={{ marginRight: 18 }}><a href="https://pangocdp.com/vi/lien-he/" target="_blank">Contact</a></Typography.Text>
                                <Typography.Text style={{ marginRight: 18 }}><a href="https://pangocdp.com/en/data-privacys-pangocdp/" target="_blank">Privacy policy</a></Typography.Text>
                            </div>
                        </Row>
                    } />


                </div>
            </Col>
        </Row>


    )
}