import { LinkOrgs, LinkOrgsNoContent } from './model/linkOrgs';
import { IActionGeneric } from '../../../common/IActionGeneric';

export const GET_LINK_ORGS = "GET_LINK_ORGS"
export const GET_LINK_ORGS_NO_CONTENT = "GET_LINK_ORGS_NO_CONTENT"

const empty = (): LinkOrgs => {
    return new LinkOrgs()
};

const emptyNoContent = () : LinkOrgsNoContent => {
  return new LinkOrgsNoContent();
}

export interface IActionLinkOrgs extends IActionGeneric<LinkOrgs>{}
export interface IActionLinkOrgsNoContent extends IActionGeneric<LinkOrgsNoContent>{}

export const linkOrgsReducer = (state = empty(), action: IActionLinkOrgs) => {
  switch (action.type) {
    case GET_LINK_ORGS:
      return {...state, ...action.payload};
    default:
      return state;
  }
};

export const linkOrgsNoContentReducer = (state = emptyNoContent(), action: IActionLinkOrgsNoContent) => {
  switch (action.type) {
    case GET_LINK_ORGS_NO_CONTENT:
      return {...state, ...action.payload};
    default:
      return state;
  }
};