import { canAccess } from '../common/helper';
import React from 'react';

type canAccessProps = {
    permission: string[],
    userRoles: string[],
    children: React.ReactNode
}

export class CanAccess extends React.Component<canAccessProps, any>{
    
    render(){
        const { permission, userRoles, children } = this.props;
        
        return (
            canAccess(permission, userRoles) ? (
                
                React.Children.only(children)
                
            ) : (null)
        )
    }
 
}

