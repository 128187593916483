import { Button, Col, Divider, Drawer, Dropdown, Form, Icon, Input, Menu, Modal, Row, Table, Tag, Tooltip, notification } from 'antd';
import { ClickParam } from "antd/lib/menu";
import { ColumnProps } from "antd/lib/table";
import { InfoList, LongTextTd, O2OCustomPaging } from "o2o_layout";
import * as React from "react";

import { FormComponentProps } from "antd/lib/form";
import _, { isArray, isEmpty, upperCase } from "lodash";
import moment from 'moment';
import ReactJson from "react-json-view";
import { useAsyncRetry } from "react-use";
import { BIGDATA_CONSTANT } from "../../../common/constant";
import { getPrimaryColor } from "../../../common/helper";
import { IState } from "../../../redux/reducer";
import { useSelector } from "../../../redux/store";
import ButtonBackDrawer from "../../../shares/ButtonBackDrawer";
import { O2OFormItem } from '../../../shares/O2OFormItem';
import { TagPrimaryColor } from "../../../shares/TagPrimaryColor";
import { InvationUserDAL, InvationUsers, TypeInvationUser } from "./InvationUserDAL";
import { InvatationUserForm } from "./InvationUserForm";



interface IFilterDrawerInviteUserProps extends FormComponentProps {
    loading: boolean
    onSearch: (payload: any) => void
    visible: boolean
}

const FilterDrawerInviteUser = Form.create<IFilterDrawerInviteUserProps>()(
    ({ form, loading, onSearch, visible }: IFilterDrawerInviteUserProps) => {

        React.useEffect(() => {
            if (!visible) {
                form.resetFields()
            }
        }, [visible])

        const onSubmit = () => {
            form.validateFields((err: any, values: any) => {
                if (!err) {
                    onSearch(values)
                }
            })
        }

        return <Form hideRequiredMark colon={false}>
            <Row gutter={[10, 10]}>
                <Col style={{ zIndex: 20 }} sm={24} lg={12} xl={8}>
                    <O2OFormItem label="User Id">
                        {form.getFieldDecorator("freeText", {

                        })(<Input placeholder='Enter' allowClear onPressEnter={onSubmit} />)}
                    </O2OFormItem>
                </Col>
                <Col>
                    <O2OFormItem label=" ">
                        <Button onClick={onSubmit} loading={loading} icon='search' type='primary'>Search</Button>
                    </O2OFormItem>
                </Col>
            </Row>
        </Form>
    }
)






interface IProps {
    visible: boolean
    onClose: any
    commonActionFromDAL: any
    commonActionFromDALV2: any
}

const initDataCriteria: any = {
    loading: false,
    payload: { page: 0, pageSize: 10 },
    type: ""
}
const SEARCH_INVATION_LETTERS = "SEARCH_INVATION_LETTERS"

const criteriaReducer = (state = initDataCriteria, action: any) => {
    switch (action.type) {
        case SEARCH_INVATION_LETTERS:
            return { ...state, ...action }

        default:
            throw new Error();
    }
}

export const DrawerInviteUser: React.FC<IProps> = (props: IProps) => {

    const [criteria, dispatchCriteria] = React.useReducer(criteriaReducer, initDataCriteria)
    const [visibleDetail, setVisibleDetail] = React.useState(false)
    const [selectedInvationUser, setSelectedInvationUser] = React.useState<TypeInvationUser | undefined>(undefined)
    const [visibleInvite, setVisibleInvite] = React.useState(false)

    const uiSetting = useSelector((state: IState) => state.uiSetting)

    const load = async () => {
        const data: Promise<InvationUsers> = await props.commonActionFromDAL(InvationUserDAL, "searchInvations", criteria.payload)
        try {
            const result: InvationUsers = await data;
            if (result.success) {
                return result
            } else {
                return new InvationUsers()
            }
        } catch (error) {
            return new InvationUsers()
        }
    }

    const stateInvationUsers = useAsyncRetry(async () => {
        if (props.visible && !isEmpty(criteria.payload)) {
            const data = await load();
            return data
        }
        return new InvationUsers()

    }, [criteria, props.visible])

    React.useEffect(() => {
        if (props.visible) {
            dispatchCriteria({
                loading: false,
                payload: { ...criteria.payload, ...({ page: 0, pageSize: 10 } as any) },
                type: SEARCH_INVATION_LETTERS,
            });
        } else {
            dispatchCriteria({
                loading: false,
                payload: {},
                type: SEARCH_INVATION_LETTERS,
            });
        }
    }, [props.visible])


    const invationUsers = stateInvationUsers.value ? stateInvationUsers.value : new InvationUsers();
    //     Roles, Invited Time, Expired Time
    // Action: 

    const columns: ColumnProps<{}>[] = [
        {
            title: '#',
            sorter: false,
            width: 40,
            ellipsis: true,
            align: 'center',
            key: 'index',
            render: (text: any, record: any, index: number) => {
                return index + 1
            }
        }, {
            title: "Status",
            ellipsis: true,
            width: 110,
            key: 'status',
            render: (text: any, record: TypeInvationUser, index: number) => {
                return <TagPrimaryColor uiSetting={uiSetting}>{upperCase(record.status)}</TagPrimaryColor>
            }
        },
        {
            title: "Invited Time",
            ellipsis: true,
            width: 120,
            key: 'invited-time',
            render: (text: any, record: TypeInvationUser, index: number) => {
                return record.createdAt ? <Tooltip title={<>{moment(record.createdAt).format('LLLL')}</>} >
                    {moment(record.createdAt).fromNow()}
                </Tooltip> : "--/--"
            }
        },
        {
            title: "User Id",
            width: 200,
            ellipsis: true,
            key: 'user-id',
            render: (text: any, record: TypeInvationUser, index: number) => {
                return <LongTextTd text={record.userId} />
            }
        },
        {
            title: "Role",
            width: 200,
            key: 'role',
            ellipsis: true,
            render: (text: any, record: TypeInvationUser, index: number) => {
                return isArray(record.roles) && record.roles.map((role: string) => {
                    return <Tag>{role}</Tag>
                })
            }
        },
        {
            title: "Reason",
            ellipsis: true,
            key: 'reason',
            render: (text: any, record: TypeInvationUser, index: number) => {
                return record.reason || '--/--'
            }
        },

        {
            title: "Action",
            ellipsis: true,
            width: 80,
            fixed: "right",
            key: 'action',
            render: (text: any, record: TypeInvationUser, index: number) => {
                return <React.Fragment>

                    <Tooltip title={"View JSON"}>
                        <Button icon="select" type='link' size="small" onClick={() => {
                            setSelectedInvationUser(record)
                            setVisibleDetail(true)
                        }}></Button>
                    </Tooltip>

                    <Divider type='vertical' />
                    <Dropdown overlay={
                        <Menu onClick={(e: ClickParam) => {
                            if (e.key == "delete") {
                                Modal.confirm({
                                    onOk: async () => {
                                        const result = props.commonActionFromDAL(InvationUserDAL, "adminRemoveUserFromOrg", record.id)
                                        try {
                                            const response = await result
                                            if (response.success) {
                                                notification.success({
                                                    message: BIGDATA_CONSTANT.message.default.title.success,
                                                    description: BIGDATA_CONSTANT.message.notification.success.delete,
                                                    icon: <Icon type="check-circle" style={{ color: getPrimaryColor(uiSetting) }} />
                                                })
                                                setTimeout(() => {
                                                    stateInvationUsers.retry()
                                                }, 1000);
                                            } else {
                                                notification.error({
                                                    message: BIGDATA_CONSTANT.message.default.title.error,
                                                    description: _.get(response, "message", BIGDATA_CONSTANT.message.notification.fail.delete),
                                                })
                                            }
                                        } catch (error) {
                                            notification.error({
                                                message: BIGDATA_CONSTANT.message.default.title.error,
                                                description: _.get(error, "message", "Something went wrong"),
                                            })
                                        }
                                    },
                                    content: "Are you sure to delete the invitation?",
                                    title: "Confirm"
                                })
                            }
                        }}>

                            <Menu.Item disabled={record.status === "Accepted"} key={"delete"}>
                                <Icon type="delete" /> Delete
                            </Menu.Item>
                        </Menu>
                    }>
                        <Button icon="more" type='link' size="small" onClick={() => {

                        }}></Button>
                    </Dropdown>
                </React.Fragment>
            }
        }
    ]

    const renderDetail = () => {
        return (
            <Drawer
                width={700}
                title="Detail"
                visible={visibleDetail}
                onClose={() => {
                    setVisibleDetail(false)
                    setSelectedInvationUser(undefined)
                }}
            >
                <ReactJson src={selectedInvationUser || {}} />
            </Drawer>
        )
    }

    const renderInvite = () => {
        return <InvatationUserForm
            commonActionFromDAL={props.commonActionFromDAL}
            visible={visibleInvite}
            onClose={() => {
                setVisibleInvite(false)
            }}
            onSuccess={() => {
                setTimeout(() => {
                    stateInvationUsers.retry()
                }, 1000);
            }}
        />
    }

    return (
        <Drawer
            width={1200}
            onClose={() => {
                props.onClose()
            }}
            visible={props.visible}
            title={<span>
                <ButtonBackDrawer onClick={props.onClose} />
                Invitation
            </span>}
            maskClosable={false}
        >
            <Row gutter={[10, 10]} type="flex" justify="start">

                <Col xs={24}>
                    <FilterDrawerInviteUser
                        visible={props.visible}
                        onSearch={(payload: any) => {
                            dispatchCriteria({
                                loading: false,
                                payload: { ...criteria.payload, ...payload, ...({ page: 0, } as any) },
                                type: SEARCH_INVATION_LETTERS,
                            });
                        }}
                        loading={stateInvationUsers.loading}

                    />
                </Col>

                <Col xs={24}>
                    <InfoList
                        loading={stateInvationUsers.loading}
                        list={invationUsers}
                        refresh={stateInvationUsers.retry}
                        right={
                            <Button type="primary" icon='plus' onClick={() => {
                                setVisibleInvite(true)
                            }}>Invite</Button>
                        }
                    />
                </Col>


                <Col xs={24}>
                    <Table
                        loading={stateInvationUsers.loading}
                        columns={columns}
                        dataSource={invationUsers.results.content}
                        rowKey="id"
                        size="small"
                        scroll={{ x: 'calc(500px + 50%)' }}
                        pagination={false}

                    />
                </Col>

                <Col xs={24}>
                    <O2OCustomPaging
                        list={invationUsers}
                        onChange={(e: number) => {
                            dispatchCriteria({
                                loading: false,
                                payload: { ...criteria.payload, ...({ page: e - 1 } as any) },
                                type: SEARCH_INVATION_LETTERS,
                            });
                        }}
                        onChangePageSize={(e: number) => {
                            dispatchCriteria({
                                loading: false,
                                payload: { ...criteria.payload, ...({ page: 0, pageSize: e } as any) },
                                type: SEARCH_INVATION_LETTERS,
                            });
                        }}
                    />
                </Col>
                {renderDetail()}
                {renderInvite()}

            </Row>

        </Drawer>
    )
}