
import { Store, createStore, compose, applyMiddleware } from 'redux';
import reduxThunk from 'redux-thunk';
import { state, IState } from './reducer';

import createHistory from 'history/createBrowserHistory'

import { routerMiddleware } from 'react-router-redux'

export const historyApp = createHistory()
// Build the middleware for intercepting and dispatching navigation actions
const middleware = routerMiddleware(historyApp)


export const store: Store<IState> = createStore(
  state,
  compose(
    applyMiddleware(reduxThunk, middleware)
  )
);

export const useSelector = (func: any) => {
    return func(store.getState())
}

export const useDispatch = () => {
    return store.dispatch
}