import { IState } from '../../redux/reducer/index';
import { O2OCommonContainer } from '../../common/container/O2OContainer';
import { SelectCompaniesComponent } from './SelectCompanyComponent';
import { getCurrentUserOrgs, selectUserOrg } from './SelectCompanyAction';
import { bindActionCreators } from 'redux';
import { getMe } from '../../common/actions/CommonAction';
import { getUISettingCtx } from '../setting/action/UISettingAction';
import { UISetting } from 'o2o_layout/lib/common/model';

function mapStateToProps(rootState: IState) {
    return {  
        currentUserOrgs: rootState.currentUserOrgs,
        commonResponse: rootState.commonResponse,
        uiSetting: UISetting
    };
}


function mapDispatchToProps(dispatch: any) {
    return {
        getCurrentUserOrgs: bindActionCreators(getCurrentUserOrgs, dispatch),
        selectUserOrg: bindActionCreators(selectUserOrg, dispatch),
        getMe: bindActionCreators(getMe, dispatch),
        getUISettingCtx: bindActionCreators(getUISettingCtx, dispatch)
    };
}

export const SelectCompanyContainer = O2OCommonContainer(SelectCompaniesComponent, mapStateToProps, mapDispatchToProps)