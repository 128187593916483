import { Methods } from 'o2o_layout/lib/common/rest'
import React from 'react'
import { useAsyncRetry } from 'react-use'
import { WrappedFormUtils } from 'antd/lib/form/Form'
import { Select, Spin } from 'antd'
import { CommonDAL } from '../dal/CommonDAL'
import { BIGDATA_CONSTANT } from '../../common/constant'
import { O2OFormItem } from '../O2OFormItem'

interface ISuggestTopic {
    mode?: "default" | "multiple" | "tags" | "combobox" | "SECRET_COMBOBOX_MODE_DO_NOT_USE" | undefined
    visible: boolean
    lable: string | React.ReactNode
    keyField: string
    required: boolean
    initialValue: any
    placeholder: string
    commonActionFromDALV2: any
    form: WrappedFormUtils<any>
}

const SuggestTopic = (props: ISuggestTopic) => {
    const { getFieldDecorator } = props.form

    const loadTopicSupport = async () => {
        const data = await props.commonActionFromDALV2(CommonDAL.buildURL, `${BIGDATA_CONSTANT.url.console}connections/crm-loyalty/caresoft/pango-ticket/topic`, {}, Methods.GET)
        const response = await data
        if (response.success) {
            return response
        } else {
            return { success: false, results: {} }
        }
    }

    const stateTopicSupport = useAsyncRetry(async () => {
        if (props.visible) {
            const data = await loadTopicSupport()
            return data;
        }
        return null
    }, [props.visible])

    const res = stateTopicSupport.value
    const listTopic = res && res.success ? Object.entries(res.results).map(([id, description]) => { return { id, description } }) : []

    return (
        <Spin spinning={stateTopicSupport.loading}>
            <O2OFormItem help='' label={props.lable}>
                {getFieldDecorator(props.keyField, {
                    rules: [{
                        required: props.required
                    }],
                    initialValue: props.initialValue
                })(
                    <Select
                        mode={props.mode} showSearch showArrow
                        placeholder={props.placeholder}
                        maxTagTextLength={8}
                        maxTagCount={1}
                    >
                        {listTopic && listTopic.length && listTopic.map((item) => {
                            return (
                                <Select.Option key={item.id} value={item.id}>
                                    {item.description}
                                </Select.Option>
                            )
                        })}
                    </Select>
                )}
            </O2OFormItem>
        </Spin>
    )
}

export default SuggestTopic
