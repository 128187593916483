import { TypeResultsBigData, IResponseBigDataVN, TypePageBigData } from '../../../../common/IResponseBigData';
import { TypeLoginActivity } from '../../login-activity/model';

export type TypeUserToken = {
    "apiKey": string,
    "expiredAt": number,
    "geoLocation": string,
    "id": string,
    "ipAddress": string,
    "issuedAt": number,
    "lastAccessedAt": number,
    "orgId": string,
    "token": string,
    "type": string,
    "userAgent": string,
    "userId": string
}

export class UserTokens implements IResponseBigDataVN<TypeUserToken> {
    success: boolean;
    results: TypeResultsBigData<TypeUserToken>

    constructor() {
        this.success = false;
        this.results = {
            content: [],
            timeTaken: 0,
            currentTime: 0,
            page: {
                pageSize: 0,
                currentPage: 0,
                totalPages: 0,
                totalRecords: 0
            } as TypePageBigData,
            extras: {}
        }
    }

    isEmpty() {
        if (this.results.content.length)
            return false;
        return true
    }
}
export class UserActivities {
    lastLoginSuccess: TypeLoginActivity
    success: boolean
    lastChangePassword: TypeLoginActivity
    lastLoginFailed: TypeLoginActivity
    constructor() {
        this.success = false
        this.lastChangePassword = {}
        this.lastLoginSuccess = {}
        this.lastLoginFailed = {}
    }
}


