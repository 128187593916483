import { UserTokens } from '../model/usertoken';
export const GET_USER_TOKENS = "GET_USER_TOKENS";

const empty = (): UserTokens => {
    return new UserTokens();
}

export interface IActionUserTokens{
    type: string,
    payload: UserTokens
}

export const userTokenReducer = (state = empty(), action: IActionUserTokens) => {
    switch (action.type) {
        case GET_USER_TOKENS: 
        
            return {...state, ...action.payload}
        default:
            return state;
    }
};