import * as React from 'react';
import CommonTypeProps from '../../../common/CommonTypeProps';
import { DashboardPageLayout } from '../../../layout/dashboard/DashboardPageLayout';
import { Row, Breadcrumb, Button, Drawer, Form, Input, Spin, Select, Table, Col, Typography, Popconfirm, Descriptions, Card, Divider, notification, Icon } from 'antd';
import { Link } from 'react-router-dom';
import { Organizations } from 'o2o_layout/lib/common/model/Organizations';
import { typeGetOrgs } from '../OrgsAction';
import { TypeOrganization } from 'o2o_layout/lib/common/model';
import { typeCriteriaSearchOrgs } from '../OrgsDAL';
import { FormComponentProps } from 'antd/lib/form';
import { typeLinkOrg, typeSearchLinkOrgs } from './action/LinkOrgsAction';
import { typeCriteriaSearchLinkOrgs } from './dal/LinkOrgsDal';
import { ColumnProps } from 'antd/lib/table';
import { PaginationProps } from 'antd/lib/pagination';
import { LinkOrgs, LinkOrg } from './model/linkOrgs';
import _, { debounce } from 'lodash';
import queryString from 'query-string';
import { FormattedNumber } from 'react-intl';
import ReactJson from 'react-json-view';
import { LongTextTd, O2OCustomPaging } from 'o2o_layout';
import { BIGDATA_CONSTANT } from '../../../common/constant';
import { getCurrentURL, getPrimaryColor } from '../../../common/helper';
import { MetaHeader } from '../../../shares/MetaHeader';


interface LinkOrgComponentProps extends CommonTypeProps, FormComponentProps {
    organizations: Organizations,
    getOrgs: typeGetOrgs,
    linkOrg: typeLinkOrg,
    searchLinkOrgs: typeSearchLinkOrgs,
    linkOrgs: LinkOrgs,
    deleteLinkOrgs: any
}

export class LinkOrgCs extends React.Component<LinkOrgComponentProps, any> {

    state = {
        pagination: {} as PaginationProps,
        selectedRowKeys: [],
        criteriaOrg: {
            order: "createdAt:DESC"
        } as typeCriteriaSearchOrgs,
        criteria: {

        } as typeCriteriaSearchLinkOrgs,
        isVisible: false,

        columns: [
            {
                title: 'Index',
                dataIndex: 'index',
                key: 'index',
                width: 80,
                render: (t: string, record: typeLinkOrg, index: number) => {
                    return (<span>{index + 1}</span>)
                }
            },
            {
                title: 'Integration Name',
                dataIndex: 'integrationName',
                key: 'integrationName',
                ellipsis: true,
                render: (t: string, record: LinkOrg, index: number) => {
                    return <LongTextTd text={record.integrationName} />
                }

            }, {
                title: 'O2O CDP Company Id',
                dataIndex: 'o2oCdpOrgId',
                key: 'o2oCdpOrgId',
                ellipsis: true,
                render: (t: string, record: LinkOrg, index: number) => {
                    return <LongTextTd text={record.o2oCdpOrgId} />
                }

            }, {
                title: 'linked Id',
                dataIndex: 'linkedOrgId',
                key: 'linkedOrgId',
                ellipsis: true,
                render: (t: string, record: LinkOrg, index: number) => {
                    return <LongTextTd text={record.linkedOrgId} />
                }
            }, {
                title: "Action",
                dataIndex: "action",
                // fixed: "right",
                width: 80,
                render: (text: string, record: LinkOrg, index: number) => {
                    return (
                        <Popconfirm title="Are you sure?" onConfirm={() => {
                            this.deletes(record.id)
                        }}>
                            <Button icon="delete" type="link" size="small"></Button>
                        </Popconfirm>
                    )
                }
            }
        ] as ColumnProps<{}>[]
    }

    formRef: any


    componentDidMount() {

        let searchQuery: string = this.props.location.search;
        if (searchQuery) {
            let searchQueryPares: any = queryString.parse(searchQuery);
            let o2oorgid = searchQueryPares.o2ocdporgid
            if (o2oorgid) {
                this.setState({
                    criteria: Object.assign(this.state.criteria, { o2oCdpOrgId: o2oorgid })
                })
            }
        }
        this.handleFilter = debounce(this.handleFilter, 1000)
        this.loadOrg()
        this.load();

        this.props.updateMetaTitle({ title: BIGDATA_CONSTANT.trackTitle.LinkCom })
        this.timer = setTimeout(() => {
            this.props.updateMeta({
                name: BIGDATA_CONSTANT.trackTitle.LinkCom,
                title: BIGDATA_CONSTANT.trackTitle.LinkCom,
                app: "ACCOUNT",
                url: getCurrentURL(this.props.location)
            })
            clearTimeout(this.timer)
        }, 5000)

    }

    timer: any

    componentWillUnmount() {
        clearTimeout(this.timer)
    }


    load = () => {
        this.props.setGlobalLoading(true);

        this.props.searchLinkOrgs(this.state.criteria)
            .then(() => {
                this.props.setGlobalLoading(false)
            })
    }

    searchOrg = (e: any) => {

        this.setState({
            criteriaOrg: Object.assign(this.state.criteriaOrg, { freeText: e })
        })

        this.loadOrg();
    }
    loadOrg = () => {

        this.props.getOrgs(this.state.criteriaOrg)
            .then(() => {

                this.props.setGlobalLoading(false)
            })
            .catch(() => this.props.setGlobalLoading(false))

    }
    submit = (e: any) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err: any, values: any) => {
            if (!err) {
                this.props.setGlobalLoading(true)
                values.linkedSource = "O2OCRM";
                this.props.linkOrg(values)
                    .then(() => {
                        this.props.setGlobalLoading(false)

                        if (this.props.commonResponse.success) {
                            this.setState({
                                isVisible: false
                            })
                            notification.success({
                                message: BIGDATA_CONSTANT.message.default.title.success,
                                description: BIGDATA_CONSTANT.message.notification.success.add,
                                icon: <Icon type="check-circle" style={{ color: getPrimaryColor(this.props.uiSetting) }} />
                            })
                            setTimeout(() => {
                                this.load()
                            }, 1000);
                        } else {
                            notification.error({
                                message: BIGDATA_CONSTANT.message.default.title.error,
                                description: this.props.commonResponse.message ? this.props.commonResponse.message : "Something was wrong...",
                            })
                        }
                    })

            }

        });
    }

    handleTableChange = (pagination: any, filters: any, sorter: any) => {
        const pager = { ...this.state.pagination };
        pager.current = pagination.current;


        this.setState({
            pagination: pager,
            criteria: Object.assign(this.state.criteria, { page: pager.current })
        });


        this.load();
    };
    renderBreadCrumb = () => {
        return (
            <Row type="flex" justify="space-between" align="middle" style={{ marginBottom: 7 }}>
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/">Home</Link>
                    </Breadcrumb.Item>

                    <Breadcrumb.Item>Console / Link 3thrd Company</Breadcrumb.Item>
                </Breadcrumb>


                <Button type="primary" icon="plus" onClick={() => this.setState({ isVisible: true })}>Link Organization</Button>


            </Row>

        )
    }
    handleFilter = () => {
        this.load()
    }

    onSelectChangeTable = (e: string[]) => {
        this.setState({
            selectedRowKeys: e
        })
    }


    deletes = (id: string) => {
        this.props.setGlobalLoading(true)
        this.props.deleteLinkOrgs(id)
            .then((e: any) => {
                if (e.success) {
                    notification.success({
                        message: BIGDATA_CONSTANT.message.default.title.success,
                        description: BIGDATA_CONSTANT.message.notification.success.delete,
                        icon: <Icon type="check-circle" style={{ color: getPrimaryColor(this.props.uiSetting) }} />
                    })
                    this.props.setGlobalLoading(false)
                    this.setState({
                        selectedRowKeys: [],
                    });
                    setTimeout(() => {
                        this.load()
                    }, 1000);
                } else {
                    notification.error({
                        message: BIGDATA_CONSTANT.message.default.title.error,
                        description: _.get(e, "message", "Something went wrong"),
                    })
                    this.props.setGlobalLoading(false)

                }
            })
    }


    renderTheme() {
        const { getFieldDecorator } = this.props.form
        // const { selectedRowKeys } = this.state;

        // const rowSelection = {
        //     selectedRowKeys,
        //     onChange: this.onSelectChangeTable,
        // };
        return (
            <DashboardPageLayout {...this.props} selectedLeftNav="menu_link_org" selectedSubMenu="console-super" >
                <div className="dashboard-page">

                    <MetaHeader title={this.props.ui.meta.title} />

                    <div className="contentLayout">
                        {this.renderBreadCrumb()}
                        <div className="containerInner">

                            <Row gutter={[16, 16]}>

                                <Col xs={24} lg={8} md={12}>
                                    <Input.Search placeholder="Free text search" onChange={(e: any) => {

                                        this.setState({
                                            criteria: Object.assign(this.state.criteria, { freeText: e.target.value })
                                        })
                                        this.handleFilter()

                                    }} />
                                </Col>
                                <Col xs={24} lg={8} md={12}>
                                    <Select
                                        showSearch
                                        onChange={(e: any) => {


                                            this.setState({
                                                criteria: Object.assign(this.state.criteria, { o2oCdpOrgIds: e })
                                            })
                                            this.handleFilter()
                                        }}
                                        style={{ width: '100%' }}
                                        placeholder={`O2O CDP Company`}
                                        onSearch={this.searchOrg}
                                        allowClear={true}
                                        filterOption={false}
                                        mode="multiple"
                                        notFoundContent={this.props.ui.isLoading ? <Spin size="small" /> : null}
                                    >
                                        {
                                            this.props.organizations.results.content.map((item: TypeOrganization) => {
                                                return (
                                                    <Select.Option key={item.id}>{item.name}</Select.Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Col>


                                <Col xs={24} lg={8} md={12}>
                                    <Input.Search placeholder="3thrd company id" onChange={(e: any) => {

                                        this.setState({
                                            criteria: Object.assign(this.state.criteria, { linkedOrgId: e.target.value })
                                        })
                                        this.handleFilter()
                                    }} />
                                </Col>


                            </Row>

                            <Row gutter={[8, 8]}>
                                <Col xs={24}>
                                    <Button type="primary" size="small" icon="sync" shape="circle" onClick={this.load}></Button>
                                    <Typography.Text style={{ marginLeft: 10 }}>Found
                                        <span style={{ marginLeft: 3 }}><FormattedNumber value={this.props.linkOrgs.results.page.totalRecords} /></span>
                                        <span style={{ marginLeft: 4 }}>record(s)</span>
                                        <span style={{ marginLeft: 3 }}>(<FormattedNumber value={this.props.linkOrgs.results.timeTaken} /> ms)</span>
                                    </Typography.Text>


                                </Col>
                                <Col xs={24}>
                                    <Table
                                        onChange={this.handleTableChange}
                                        size="small"
                                        key="id"
                                        // rowSelection={rowSelection}
                                        style={{ marginTop: 20, fontSize: 12 }}
                                        columns={this.state.columns}
                                        dataSource={this.props.linkOrgs.results.content}
                                        pagination={false}
                                        loading={this.props.ui.isLoading}
                                        expandedRowRender={(record: LinkOrg) => <p style={{ margin: 0 }}>

                                            <Row gutter={[6, 6]}>
                                                <Descriptions column={2} title="Detail">
                                                    <Descriptions.Item label="Object">
                                                        <ReactJson src={record} />
                                                    </Descriptions.Item>


                                                </Descriptions>
                                            </Row>

                                        </p>}
                                    // scroll={{ x:  'calc(700px + 50%)' }}
                                    />
                                </Col>
                                <Col xs={24}>
                                    <O2OCustomPaging
                                        list={this.props.linkOrgs}
                                        onChange={(e: number) => {
                                            this.setState({
                                                criteria: { ...this.state.criteria, ...{ page: e } }
                                            }, () => {
                                                this.load()
                                            })
                                        }}
                                        onChangePageSize={(e: number) => {
                                            this.setState({
                                                criteria: { ...this.state.criteria, ...{ page: 0, pageSize: e } }
                                            }, () => {
                                                this.load()
                                            })
                                        }}
                                    />
                                </Col>
                            </Row>



                        </div>



                    </div>
                </div>

                <Drawer
                    width={this.calWidth()}
                    placement="right"
                    closable={true}
                    onClose={() => { this.setState({ isVisible: false }) }}
                    visible={this.state.isVisible}
                    title="Link 3thrd company form"
                >

                    <Row>
                        <Form onSubmit={this.submit} hideRequiredMark={true} colon={false}>
                            <Form.Item label="Integration Name">
                                {
                                    getFieldDecorator('integrationName', {
                                        rules: [{ required: true, message: 'Please input integration name!' }],
                                    })(<Input placeholder=" integration name" />)
                                }
                            </Form.Item>
                            <Form.Item label="Api key">
                                {
                                    getFieldDecorator('apiKey', {
                                    })(<Input placeholder=" Api key" />)
                                }
                            </Form.Item>
                            <Form.Item label="Select Company">
                                {
                                    getFieldDecorator('o2oCdpOrgId', {
                                        rules: [{ required: true, message: 'Required' }],
                                    })(
                                        <Select
                                            showSearch
                                            style={{ width: '100%' }}
                                            placeholder={`Link to Organizations`}
                                            onSearch={this.searchOrg}
                                            onChange={(e: any) => {
                                                this.setState({ selectedOrgIdAsign: e })
                                            }}
                                            notFoundContent={this.props.ui.isLoading ? <Spin size="small" /> : null}
                                        >
                                            {
                                                this.props.organizations.results.content.map((item: TypeOrganization) => {
                                                    return (
                                                        <Select.Option key={item.id}>{item.name}</Select.Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    )
                                }

                            </Form.Item>
                            <Card title="Link Company">
                                <Form.Item label="Company Id">
                                    {
                                        getFieldDecorator('linkedOrgId', {
                                            rules: [{ required: true, message: 'Please input company integration id!' }],
                                        })(<Input placeholder="company id" />)
                                    }
                                </Form.Item>
                                <Form.Item label="Company Name">
                                    {
                                        getFieldDecorator('linkedOrgName', {
                                            rules: [{ required: true, message: 'Please input company integration name!' }],
                                        })(<Input placeholder="company name" />)
                                    }
                                </Form.Item>


                            </Card>
                            <Divider />
                            <Form.Item>

                                <Button type="primary" htmlType="submit">
                                    Link Compnay
                                </Button>



                            </Form.Item>
                        </Form>
                    </Row>
                </Drawer>

            </DashboardPageLayout >
        )
    }

    calWidth = () => {
        switch (this.props.ui.breakpoint) {
            case "xs":
                return "100%"
                break;
            case "md":
                return "80%"
                break;
            case "sm":
                return "80%"
                break;
            case "lg":
                return "700px"
                break;
            default:
                return "400px"
                break;
        }
    }

    render() {
        return (
            this.renderTheme()
        );
    }
}

export const LinkOrgsComponent = Form.create<LinkOrgComponentProps>({ name: 'form' })(LinkOrgCs)

