import { IState } from '../../../redux/reducer/index';
import { O2OCommonContainer } from '../../../common/container/O2OContainer';
import { TwoFaComponent } from './TwoFaComponent';
import { enable2Fa, verifyEnable2Fa, verifyDisable2Fa } from '../../../common/actions/CommonAction';
import { bindActionCreators } from 'redux';

function mapStateToProps(rootState: IState) {
    return {  
    };
}


function mapDispatchToProps(dispatch: any) {
    return {
        enable2Fa: bindActionCreators(enable2Fa, dispatch),
        verifyEnable2Fa: bindActionCreators(verifyEnable2Fa, dispatch),
        verifyDisable2Fa: bindActionCreators(verifyDisable2Fa, dispatch)
    };
}

export const TwoFaContainer = O2OCommonContainer(TwoFaComponent, mapStateToProps, mapDispatchToProps)