export const Methods = {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT',
    PATCH: 'PATCH',
    DELETE: 'DELETE'
}

export type methodType =
    "POST"
    | "GET"
    | "PUT"
    | "DELETE"
    | "PATCH";