import { Button, Col, Drawer, Form, Input, Row, Select, Table } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import { ColumnProps } from 'antd/lib/table'
import _ from 'lodash'
import { LongTextTd } from 'o2o_layout'
import React from 'react'
import { O2OFormItem } from '../../../../shares/O2OFormItem'
import { Permissions, TypePermission, TypePermissionGroup } from '../model/permissions'

interface Iprops extends FormComponentProps {
    visible: boolean,
    onClose: any,
    permission: Permissions,
    item?: TypePermissionGroup
}
const DrawerPermissionGroupInfo = Form.create<Iprops>()((props: Iprops) => {
    const { getFieldDecorator, resetFields } = props.form;
    const { visible, onClose } = props
    const [permissions, setPermissions] = React.useState<string[]>([])
    const columns: ColumnProps<{}>[] = [
        {
            title: '#',
            key: 'index',
            width: 45,
            render: (text: any, record: any, index: number) => {
                return index + 1
            },
        },
        {
            title: 'Name',
            key: 'name',
            render: (text: any, record: any, index: number) => {
                const findName = _.find(props.permission.results, (item: TypePermission) => item.id === record)
                return <LongTextTd text={`${_.get(findName, "name", "")} <${_.get(findName, "id", "")}>`} textDisplay={_.get(findName, "name", record)} />
            },
        }, {
            title: 'Category',
            key: 'category',
            render: (text: any, record: any, index: number) => {
                const findName = _.find(props.permission.results, (item: TypePermission) => item.id === record)
                return <LongTextTd text={findName ? findName.category : "--/--"} />
            },
        }
    ]



    React.useEffect(() => {
        if (!visible) {
            resetFields()
            setPermissions([])
        }
    }, [visible])

    React.useEffect(() => {
        if (visible && props.item && props.item.permissions) {
            setPermissions(props.item.permissions)
        }
    }, [props.item, visible])

    return (
        <Drawer
            title={<>
                <Button icon="left-circle" size="small" type="link" onClick={() => {
                    onClose()
                }}></Button>
                PERMISSION GROUP INFO
            </>}
            width={"500px"}
            onClose={onClose}
            visible={visible}
            bodyStyle={{ paddingBottom: 80 }}
            maskClosable={false}
        >
            <Form layout="vertical" colon={false} hideRequiredMark={true}>

                <Row gutter={[5, 5]} type='flex'>

                    <Col xs={24}>
                        <O2OFormItem label="Name">
                            {getFieldDecorator('name', {
                                rules: [{ required: true, message: 'Please enter permission name' }],
                                initialValue: props.item ? props.item.name : undefined
                            })(<Input placeholder="Group name" />)}
                        </O2OFormItem>
                    </Col>


                    <Col xs={24}>
                        <O2OFormItem label="Description">
                            {getFieldDecorator('description', {
                                initialValue: props.item ? props.item.description : undefined
                            })(<Input placeholder="More information" />)}
                        </O2OFormItem>
                    </Col>

                    <Col xs={24}>
                        <O2OFormItem>
                            {getFieldDecorator('permissions', {
                                initialValue: permissions
                            })(
                                <Select style={{ display: "none" }} />
                            )}
                        </O2OFormItem>
                    </Col>
                    <Col xs={24}>
                        <O2OFormItem label={"Permissions"}>
                            {props.form.getFieldDecorator("permissionsTemp", {

                            })(<Select disabled placeholder="Select one" />)}
                        </O2OFormItem>
                    </Col>

                    <Col xs={24}>
                        <O2OFormItem label={`Selected ${permissions && permissions.length} permission`}>
                            <Table
                                size="small"
                                columns={columns}
                                dataSource={permissions}
                                pagination={false}
                            />
                        </O2OFormItem>
                    </Col>



                </Row>

            </Form>
            <div
                style={{
                    position: 'absolute',
                    right: 0,
                    bottom: 0,
                    width: '100%',
                    borderTop: '1px solid #e9e9e9',
                    padding: '10px 16px',
                    background: '#fff',
                    textAlign: 'right',
                }}
            >
                <Button onClick={onClose} >
                    Cancel
                </Button>
            </div>
        </Drawer >
    )
})

export default DrawerPermissionGroupInfo