import { RestClient } from "o2o_layout/lib/common/rest/RestClient";
import { BIGDATA_CONSTANT } from "../../../common/constant";
import { Methods } from "o2o_layout/lib/common/rest";
import { TypeCriteriaExportsHistory } from "../exports-history/DAL";
import { toQuery } from "../../../common/helper";
import _ from "lodash";

export class DAL {
    search(dto: TypeCriteriaExportsHistory) {
        const q = toQuery(_.omitBy({
            page: dto.page,
            pageSize: dto.pageSize,
            orderBy: 'actionAt:DESC'
        }, _.isNil),)

        let url = BIGDATA_CONSTANT.url.api + `accounts/users/current/login-activities/search?${q}`

        var restClient = new RestClient(Methods.POST, url);

        return new Promise((resolve, reject) => {
            restClient.execute({}, dto).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }
}

export const LoginActivityDAL = new DAL()