import { IResponseBigDataVN, TypePageBigData, TypeResultsBigData } from "o2o_layout/lib/common/interface";

export type TypeCriteriaInternalPermission = {
    page: number
    pageSize: number
}

export type TypeInternalPermissionItem = {
    id: string,
    orgId: string,
    userId: string,
    userOrgKey: string,
    permission: string
}

export class InternalPermissions implements IResponseBigDataVN<TypeInternalPermissionItem> {
    results: TypeResultsBigData<TypeInternalPermissionItem>;
    success: boolean;
    constructor() {
        this.success = false;
        this.results = {
            content: [],
            currentTime: 0,
            extras: null,
            page: {
                pageSize: 0,
                currentPage: 0,
                totalRecords: 0,
                totalPages: 0,
            } as TypePageBigData,
            timeTaken: 0,
        };
    }
}