export const BIGDATA_CONSTANT = {
    url: {
        api: process.env.REACT_APP_API + 'api/',
        svc: process.env.REACT_APP_SVC_API,
        console: process.env.REACT_APP_SVC_API + "console/api/",
        app: 'http://localhost:3000/',
        account: process.env.REACT_APP_API,
        apps: {
            REACT_APP_ACCOUNT_APP: process.env.REACT_APP_ACCOUNT_APP
        },
        act: process.env.REACT_APP_ACT_SVC_API + "api/",
    },
    facebook_id: process.env.REACT_APP_FACEBOOKID || '',
    google_id: process.env.REACT_APP_GOOGLEID || '',
    store: {
        key: "appvnbd"
    },
    trackTitle: {
        Home: 'Home / Account Info',
        Notification: "Home / My Notification",
        TwoFa: "Home / Security / 2FA-TOTP",
        MyToken: "Home / Security / My Tokens",
        AccountAPI: "Home / Security / Account API",
        ChangePW: "Home / Change Password",
        Organization: "Home / Admin / Organization",
        ActInvivation: "Home / Activity / Invitation",
        ConsoleOrg: "Home / Super Admin / Organizations",
        ConsoleUser: "Home / Admin / Accounts",
        SPConsoleUser: "Home / Super Admin / Accounts",
        SPPermission: "Home / Super Admin / Permission",
        SPInternalPermission: "Home / Super Admin / Internal Permission",
        LinkCom: "Home / Console / Link 3thrd Company",
        ConsoleSystem: "Home / System Setting",
        ConsolePermissionGroup: "Home / Admin / Permission Groups",
        Profile: "Home / Profile",
        ExportsHistory: "Home / Activity / Exports History",
        Activities: {
            ExportLogs: "Home / Admin / Activity / Export Logs",
            AccountLogs: "Home / Admin / Activity / Account Logs",
            Changelog: "Home / Admin / Activity / Changelog",
            AccessLogs: "Home / Admin / Activity / Access Logs",
        }
    },
    bear: 'BEARER',
    reCapchaSiteKey: "6LcYlOMUAAAAAH_G6c5M_yLufOfnbzieF91nUNcD",
    primaryColor: "#8e00e8",
    primaryColorDelay: "#AFB0B3",
    industry: {
        art: "Art and Entertainment",
        car: "Car",
        beauty_fitness: "Beauty and fitness",
        book_literature: "Books and literature",
        industrial_markets: "Business and industrial markets",
        computer_electronics: "Computers and electronics",
        finance: "Finance",
        food_beverage: "Food & Beverage",
        game: "Game",
        heath_care: "Health care",
        hobbies_and_free_time: "Hobbies and free time",
        houses_garden: "Houses and gardens",
        internet: "Internet and telecommunications",
        job_education: "Jobs and education",
        law_govement: "Law and government",
        news: "News",
        online_community: "Online community",
        people_society: "People and society",
        pets_animals: "Pets and animals",
        real_estate: "Real estate",
        reference: "Reference",
        science: "Science",
        shopping: "Shopping",
        sport: "Sport",
        travel: "Travel",
        other: "Other"
    },
    message: {
        notification: {
            success: {
                add: "Added successfully",
                delete: "Deleted successfully",
                update: "Updated successfully",
                saved: "Saved successfully"
            }, fail: {
                add: "Failed to add",
                delete: "Failed to delete",
                update: "Failed to update",
                saved: "Failed to save"
            },
        },
        default: {
            titleConfirm: "Confirmation",
            confirmText: "Are you sure you want to continue?",
            confirmDelete: "Are you sure that you want to permanently remove this item?",
            title: {
                info: "Information",
                success: "Success",
                error: "Failed"
            }
        }
    },
}