import { IState } from "../../redux/reducer";
import { O2OCommonContainer } from "../../common/container/O2OContainer";
import { OrgsComponent } from './OrgsComponent';
import { getOrgs, addOrgs, deleteOrg, updateOrg } from './OrgsAction';
import { bindActionCreators } from 'redux';
import { linkOrg, getLinkOrg } from './link-orgs/action/LinkOrgsAction';


function mapStateToProps(rootState: IState) {
    return {  
        organizations: rootState.organizations,
        user: rootState.user,
        commonResponse: rootState.commonResponse,
        linkOrgsNoContent: rootState.linkOrgsNoContent
    };
}


function mapDispatchToProps(dispatch: any) {
    return {
        getOrgs: bindActionCreators(getOrgs, dispatch),
        addOrgs: bindActionCreators(addOrgs, dispatch),
        deleteOrg: bindActionCreators(deleteOrg, dispatch),
        updateOrg: bindActionCreators(updateOrg, dispatch),
        linkOrg: bindActionCreators(linkOrg, dispatch),
        getLinkOrg: bindActionCreators(getLinkOrg, dispatch)
    };
}

export const OrganizationContainer = O2OCommonContainer(OrgsComponent, mapStateToProps, mapDispatchToProps)