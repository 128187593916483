import { Alert, Breadcrumb, Col, Form, Icon, Layout, Menu, Row, Typography } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import React from 'react'
import { ChangePassword } from '../profile/ChangePassword'
import CommonTypeProps from '../../../common/CommonTypeProps'
import { LinkO2O } from '../../../common/LinkO2O'
import { DashboardPageLayout } from '../../../layout/dashboard/DashboardPageLayout'
import { MetaHeader } from '../../../shares/MetaHeader'
import { Link } from 'react-router-dom'

interface IChangePasswordComponentProps extends FormComponentProps, CommonTypeProps {
    changePwd: any
}


export const ChangePasswordComponent = Form.create<IChangePasswordComponentProps>()(
    (props: IChangePasswordComponentProps) => {

        const renderBreadCrumb = () => {
            return (
                <Row type="flex" justify="space-between" align="middle">
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Link to="/">Home</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <LinkO2O {...props} to={{
                                pathname: "/security/2fa-security"
                            }}>Security</LinkO2O>
                        </Breadcrumb.Item>

                        <Breadcrumb.Item>Change Password</Breadcrumb.Item>
                    </Breadcrumb>

                </Row>

            )
        }

        return <DashboardPageLayout  {...props} selectedLeftNav="my-security" selectedSubMenu="my-account" >
            <div className="org-page">
                <MetaHeader title={props.ui.meta.title} />

                <div className="contentLayout">
                    {renderBreadCrumb()}
                    <br />
                    <div className="containerInner">
                        <Layout>
                            <Layout.Sider width={200} style={{ background: '#fff' }}>
                                <Menu
                                    mode="inline"
                                    defaultSelectedKeys={['change-password']}

                                    style={{ height: '100%' }}
                                >


                                    <Menu.Item key="sercurity">
                                        <LinkO2O {...props} to={{ pathname: "/security/2fa-security" }}>Two-Factor Auth</LinkO2O>
                                    </Menu.Item>
                                    <Menu.Item key="change-password">
                                        <LinkO2O {...props} to={{ pathname: "/security/change-password" }}>Change Password</LinkO2O>
                                    </Menu.Item>
                                    <Menu.Item key="api">
                                        <LinkO2O {...props} to={{ pathname: "/security/my-account-api" }}>API Account</LinkO2O>
                                    </Menu.Item>
                                    <Menu.Item key="tokens"><LinkO2O {...props} to={{ pathname: "/security/my-account-tokens" }}>Token</LinkO2O></Menu.Item>


                                </Menu>

                            </Layout.Sider>
                            <Layout.Content style={{ paddingLeft: 20 }}>
                                <Typography.Title level={3}> Change Password
                                </Typography.Title>

                                {
                                    props.authen.changePasswordReason ? <Alert
                                        message=''
                                        description={props.authen.changePasswordReason || 'Change Password Reason'}
                                        type='error'
                                        showIcon
                                        icon={<Icon type="warning" />}
                                    /> : null
                                }

                                <Col md={24} lg={14}>
                                    <ChangePassword
                                        loading={props.ui.isLoading}
                                        locale={props.locale}
                                        orgCtx={props.user.orgCtx}
                                        wrappedComponentRef={(propsRef: any) => { }}
                                        user={props.user}
                                        changePwd={props.changePwd}
                                        commonActionFromDAL={props.commonActionFromDAL}
                                        history={props.history}
                                    />
                                </Col>
                            </Layout.Content>
                        </Layout>

                    </div>
                </div>

            </div>
        </DashboardPageLayout>

    }
)
