import { Button, Col, Drawer, Dropdown, Menu, Row, Spin, Table, Tabs, Tooltip } from 'antd'
import React from 'react'
import { ICommonPayload } from '../../common/CommonTypeProps'
import { ManageLogsTicket, TypeLogsTicketCriteria, TypeLogsTicketItem } from './model/syncTicketModel'
import { ManageTicketDAL } from './dal/syncTicketDAL'
import { useAsyncRetry } from 'react-use'
import _ from 'lodash'
import { InfoList, LongTextTd, O2OCustomPaging } from 'o2o_layout'
import { ColumnProps } from 'antd/lib/table'
import { TagPrimaryColor } from '../TagPrimaryColor'
import { useSelector } from '../../redux/store'
import { IState } from '../../redux/reducer'
import moment from "moment"
import DrawerViewJSON from '../DrawerViewJSON'
import { FilterTicketComponents } from './components/FilterTicketComponents'
import { CommonDAL } from '../dal/CommonDAL'
import { BIGDATA_CONSTANT } from '../../common/constant'
import { Methods } from 'o2o_layout/lib/common/rest'

interface IDrawerLogsTicketProps {
    commonActionFromDALV2: any
    visible: boolean
    onClose: () => void
}

const initCriteria: ICommonPayload<TypeLogsTicketCriteria> = {
    loading: false,
    type: "",
    payload: { page: 0, pageSize: 10 } as TypeLogsTicketCriteria
}

const SEARCH_MANAGE_LOGS_TICKET = "SEARCH_MANAGE_LOGS_TICKET"

const reducerCriteria = (state: ICommonPayload<TypeLogsTicketCriteria>, action: ICommonPayload<TypeLogsTicketCriteria>) => {
    switch (action.type) {
        case SEARCH_MANAGE_LOGS_TICKET:
            return { ...state, ...action };
        default:
            throw new Error();
    }
}

const DrawerViewTicket = (props: IDrawerLogsTicketProps) => {
    const [criteria, dispatchCriteria]: [ICommonPayload<TypeLogsTicketCriteria>, (action: ICommonPayload<TypeLogsTicketCriteria>) => void] = React.useReducer(reducerCriteria, initCriteria)
    const [selectedRecord, setSelectedRecord] = React.useState(undefined as undefined | TypeLogsTicketItem)
    const [tab, setTab] = React.useState<string>("open")

    const [visibleViewJSON, setVisibleViewJSON] = React.useState<boolean>(false)

    const uiSetting = useSelector((state: IState) => state.uiSetting)

    React.useEffect(() => {
        if (tab === 'close') {
            dispatchCriteria({
                loading: false,
                payload: { page: 0, pageSize: 10, status: ['closed'] },
                type: SEARCH_MANAGE_LOGS_TICKET,
            })
        } else {
            dispatchCriteria({
                loading: false,
                payload: { page: 0, pageSize: 10 },
                type: SEARCH_MANAGE_LOGS_TICKET,
            })
        }
    }, [tab])

    React.useEffect(() => {
        if (!props.visible) {
            setTab('open')
            dispatchCriteria({
                loading: false,
                payload: { page: 0, pageSize: 10 },
                type: SEARCH_MANAGE_LOGS_TICKET,
            })
        }
    }, [props.visible])

    const loadApiSearchLogs = async () => {
        const result: Promise<ManageLogsTicket> = await props.commonActionFromDALV2(ManageTicketDAL.logsTicketSearch, criteria.payload)
        try {
            const response = await result
            if (response.success) {
                return response
            } else {
                return new ManageLogsTicket()
            }
        } catch (error) {
            return new ManageLogsTicket()
        }
    }

    const stateManageLogsTicket = useAsyncRetry(async () => {
        if (!_.isEmpty(criteria.payload) && props.visible) {
            const data = await loadApiSearchLogs()
            return data
        }
        return new ManageLogsTicket()
    }, [criteria.payload, props.visible])

    const resLogsTicketTables = stateManageLogsTicket.value || new ManageLogsTicket()

    const loadTopicSupport = async () => {
        const data = await props.commonActionFromDALV2(CommonDAL.buildURL, `${BIGDATA_CONSTANT.url.console}connections/crm-loyalty/caresoft/pango-ticket/topic`, {}, Methods.GET)
        const response = await data
        if (response.success) {
            return response
        } else {
            return { success: false, results: {} }
        }
    }

    const stateTopicSupport = useAsyncRetry(async () => {
        if (props.visible) {
            const data = await loadTopicSupport()
            return data;
        }
        return null
    }, [props.visible])

    const resTopic = stateTopicSupport.value || { success: false, results: {} }

    const renderLableStatus = (status: string) => {
        if (status === 'new') {
            return 'New'
        } else if (status === 'open') {
            return 'Open'
        } else if (status === 'pending') {
            return 'Pending'
        } else if (status === 'solved') {
            return 'Solved'
        } else if (status === 'closed') {
            return 'Closed'
        } else {
            return '--/--'
        }
    }


    const columns: ColumnProps<TypeLogsTicketItem>[] = [
        {
            title: "#",
            key: "index",
            sorter: false,
            width: 25,
            render: (text: any, record: TypeLogsTicketItem, index: number) => {
                return index + 1
            }
        }, {
            title: "Status",
            key: "status",
            sorter: false,
            ellipsis: true,
            width: 60,
            render: (text: any, record: TypeLogsTicketItem, index: number) => {
                return record.status ? <TagPrimaryColor uiSetting={uiSetting}>{renderLableStatus(record.status)}</TagPrimaryColor> : '--/--'
            }
        }, {
            title: "Subject",
            key: "subject",
            sorter: false,
            ellipsis: true,
            width: 250,
            render: (text: any, record: TypeLogsTicketItem, index: number) => {
                return (
                    <>
                        <TagPrimaryColor uiSetting={uiSetting}>{`#${record.ticketId}`}</TagPrimaryColor>
                        <LongTextTd text={record.subject} />
                    </>
                )
            }
        }, {
            title: "Owner",
            key: "email",
            sorter: false,
            ellipsis: true,
            width: 110,
            render: (text: any, record: TypeLogsTicketItem, index: number) => {
                return <LongTextTd text={record.email} />
            }
        }, {
            title: "Assigned to",
            key: "assigneeEmail",
            sorter: false,
            ellipsis: true,
            width: 110,
            render: (text: any, record: TypeLogsTicketItem, index: number) => {
                return <LongTextTd text={record.assigneeEmail} />
            }
        }, {
            title: "Topic",
            key: "topic",
            sorter: false,
            ellipsis: true,
            width: 130,
            render: (text: any, record: TypeLogsTicketItem, index: number) => {
                const topic = resTopic && resTopic.success ? resTopic.results[record.topic] || '' : ''
                return <Spin spinning={stateTopicSupport.loading}><LongTextTd text={topic} /></Spin>
            }
        },

        // {
        //     title: "Priority ",
        //     key: "ticketPriority",
        //     sorter: false,
        //     ellipsis: true,
        //     width: 100,
        //     render: (text: any, record: TypeLogsTicketItem, index: number) => {
        //         return <LongTextTd text={record.ticketPriority} />
        //     }
        // }, 

        {
            title: "Last Updated Time",
            key: "updatedAt",
            sorter: false,
            ellipsis: true,
            width: 110,
            render: (text: any, record: TypeLogsTicketItem, index: number) => {
                return record.updatedAt ? (
                    <Tooltip title={`Updated by ${record.updatedBy} at ${moment(record.updatedAt).format("DD/MM/YYYY HH:mm:ss")}`}>
                        {moment(record.updatedAt).fromNow()}
                    </Tooltip>
                ) : "--/--"

            }
        }, {
            title: "Created Time",
            key: "createdAt",
            sorter: false,
            ellipsis: true,
            width: 110,
            render: (text: any, record: TypeLogsTicketItem, index: number) => {
                return record.createdAt ? (
                    <Tooltip title={`Created by ${record.createdBy} at ${moment(record.createdAt).format("DD/MM/YYYY HH:mm:ss")}`}>
                        {moment(record.createdAt).fromNow()}
                    </Tooltip>
                ) : "--/--"
            }
        }, {
            title: "Action",
            key: "action",
            width: 60,
            fixed: "right",
            render(text: any, record: TypeLogsTicketItem, index: number) {
                return <>
                    {/* <Tooltip title="Detail">
                        <Button size='small' type='link' onClick={() => {

                        }}>Detail</Button>
                    </Tooltip>

                    <Divider type='vertical' /> */}

                    <Tooltip placement='topLeft' title="More actions">
                        <Dropdown
                            placement='bottomLeft'
                            overlay={
                                <Menu onClick={(e: any) => {
                                    if (e.key === "json") {
                                        setVisibleViewJSON(true)
                                        setSelectedRecord(record)
                                    }
                                }}>
                                    <Menu.Item key="json">
                                        <Tooltip title="View JSON">
                                            <Button size='small' type='link' icon='select' >
                                                View JSON
                                            </Button>
                                        </Tooltip>
                                    </Menu.Item>
                                </Menu>
                            }
                        >
                            <Button type='link' icon='more' size='small' />
                        </Dropdown>
                    </Tooltip>
                </>
            }
        }

    ]


    return (
        <Drawer
            title={<>
                <Button onClick={() => {
                    props.onClose()
                }} type='link' size='small' icon='left-circle'
                />
                VIEW TICKET
            </>}
            width={'100%'}
            visible={props.visible}
            onClose={() => {
                props.onClose()
            }}
            maskClosable={false}
        >
            <Row type='flex' gutter={[10, 10]} align='middle'>
                <Col xs={24} style={{ paddingBottom: 0 }}>
                    <Tabs size='small' animated={false} activeKey={tab} onChange={(activeKey: string) => { setTab(activeKey) }}>
                        <Tabs.TabPane tab="Open Tickets" key="open"></Tabs.TabPane>
                        <Tabs.TabPane tab="Close Tickets" key="close"></Tabs.TabPane>
                    </Tabs>
                </Col>

                <Col xs={24}>
                    <FilterTicketComponents
                        type={tab}
                        resTopic={resTopic}
                        loadingTopic={stateTopicSupport.loading}
                        commonActionFromDALV2={props.commonActionFromDALV2}
                        visible={props.visible}
                        onSearch={(values: any) => {
                            dispatchCriteria({
                                loading: false,
                                payload: {
                                    ...criteria.payload,
                                    ...{ page: 0, ...values },
                                },
                                type: SEARCH_MANAGE_LOGS_TICKET,
                            })
                        }}
                    />
                </Col>

                <Col xs={24} style={{ marginBottom: 5 }}>
                    <InfoList
                        loading={stateManageLogsTicket.loading}
                        list={resLogsTicketTables}
                        refresh={stateManageLogsTicket.retry}
                    />
                </Col>

                <Col xs={24}>
                    <Table
                        loading={stateManageLogsTicket.loading}
                        dataSource={resLogsTicketTables.results.content}
                        columns={columns}
                        rowKey='id'
                        size='small'
                        scroll={{ x: 'calc(900px + 50%)' }}
                        pagination={false}
                    />
                </Col>

                <Col xs={24}>
                    <O2OCustomPaging
                        list={resLogsTicketTables}
                        onChange={(e: number) => {
                            dispatchCriteria({
                                loading: false,
                                payload: { ...criteria.payload, ...{ page: e } },
                                type: SEARCH_MANAGE_LOGS_TICKET,
                            });
                        }}
                        onChangePageSize={(e: number) => {
                            dispatchCriteria({
                                loading: false,
                                payload: { ...criteria.payload, ...{ page: 0, pageSize: e } },
                                type: SEARCH_MANAGE_LOGS_TICKET,
                            });
                        }}
                    />
                </Col>
            </Row>

            <DrawerViewJSON
                visible={visibleViewJSON}
                onClose={() => {
                    setVisibleViewJSON(false)
                    setSelectedRecord(undefined)
                }}
                data={selectedRecord}
            />
        </Drawer>
    )
}

export default DrawerViewTicket
