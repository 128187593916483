import { Breadcrumb, Button, Col, Divider, Form, Icon, Input, Popconfirm, Row, Table, Tooltip, Typography, notification } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { ColumnProps } from 'antd/lib/table';
import _ from 'lodash';
import moment from 'moment';
import { InfoList, LongTextTd, O2OCustomPaging } from 'o2o_layout';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { BIGDATA_CONSTANT } from '../../../common/constant';
import { getCurrentURL, getPrimaryColor } from '../../../common/helper';
import { DashboardPageLayout } from '../../../layout/dashboard/DashboardPageLayout';
import DrawerViewJSON from '../../../shares/DrawerViewJSON';
import { MetaHeader } from '../../../shares/MetaHeader';
import { O2OFormItem } from '../../../shares/O2OFormItem';
import { PermissionGroupsProps } from './PerminssionGroupContainer';
import { IPermissionGroupCriteria, PermissionDAL } from './dal/PermissionDAL';
import { TypePermission, TypePermissionGroup } from './model/permissions';
import DrawerCreatePermissionGroup from './DrawerCreatePermissionGroup';
import DrawerAssignUser from './drawer/DrawerAssignUser';
import SuggestionAccount from './components/SuggestionAccount';
import SuggestionPermissionGroup from './components/SuggestionPermissionGroup';
import DrawerPermission from './drawer/DrawerPermission';
import DrawerPermissionGroupInfo from './drawer/DrawerPermissionGroupInfo';
import { TagPrimaryColor } from '../../../shares/TagPrimaryColor';


interface IFormFilterProps extends FormComponentProps {
    search: (values: any) => void
    commonActionFromDALV2: any
}
const FormFilterComponent = Form.create<IFormFilterProps>({
    name: 'filterform'
})((props: IFormFilterProps) => {
    const [visiblePermission, setVisiblePermission] = React.useState<boolean>(false)
    const { getFieldDecorator } = props.form

    const onSearch = () => {
        props.form.validateFields((err: any, values: any) => {
            if (!err) {
                props.search(values)
            }
        })
    }

    return <Form colon={false} layout="horizontal" title="Search">
        <Row gutter={[5, 5]} type="flex" justify="start">
            <Col xs={24} lg={6} style={{ flexGrow: 1 }}>
                <O2OFormItem label="Info">
                    {getFieldDecorator('freeText', {
                    })(
                        <Input.Search onPressEnter={onSearch} placeholder="Group name" allowClear></Input.Search>
                    )}
                </O2OFormItem>
            </Col>
            <Col xs={24} lg={6} style={{ flexGrow: 1 }}>
                <SuggestionPermissionGroup
                    commonActionFromDALV2={props.commonActionFromDALV2}
                    form={props.form}
                    keyField={"permission"}
                    label="Permission"
                    placeholder="Select one"
                    onSelect={(e: string) => {
                    }}
                    visible={true}
                />
            </Col>
            <Col xs={24} lg={6} style={{ flexGrow: 1 }}>
                <SuggestionAccount
                    commonActionFromDALV2={props.commonActionFromDALV2}
                    form={props.form}
                    keyField={"userId"}
                    label="User"
                    placeholder="Select one"
                    onSelect={(e: string) => {
                    }}
                    visible={true}
                />
            </Col>
            <Col>
                <O2OFormItem label={<span style={{ color: "#fff" }}>Search</span>}>
                    <Button onClick={onSearch} type="primary" icon="search">Search</Button>
                </O2OFormItem>

            </Col>
            <Col>
                <O2OFormItem label={<span style={{ color: "#fff" }}></span>}>
                    <Button type="primary" icon="ordered-list" onClick={() => {
                        setVisiblePermission(true)
                    }}>Permissions</Button>
                </O2OFormItem>
            </Col>

            <DrawerPermission
                onClose={() => {
                    setVisiblePermission(false)
                }}
                visible={visiblePermission}
                commonActionFromDALV2={props.commonActionFromDALV2}
            />
        </Row>

    </Form>
}
)

export class PermissionGroupComponent extends React.Component<PermissionGroupsProps, any> {
    formRef: any
    formFilter: any
    state = {
        criteria: { page: 0, pageSize: 10 } as IPermissionGroupCriteria,
        visible: false,
        selected: {} as TypePermissionGroup,
        visibleViewJSON: false,
        visiblePermissionGroupInfo: false,
        columns: [
            {
                title: '#',
                sorter: false,
                align: "center",
                ellipsis: true,
                width: 40,
                render: (text: any, record: TypePermissionGroup, index: number) => {
                    return (
                        index + 1
                    )
                }

            },
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                width: 300,
                render: (text: any, record: TypePermissionGroup, index: number) => {
                    if (record.system) {
                        return (
                            <>
                                <LongTextTd text={record.name} />
                                <TagPrimaryColor style={{ marginLeft: 5 }} uiSetting={this.props.uiSetting} children={"System"} />
                            </>
                        )
                    } else {
                        return (
                            <LongTextTd text={record.name} />
                        )
                    }
                },
                ellipsis: true,

            }, {
                title: 'Permission',
                dataIndex: 'permission',
                ellipsis: true,
                key: 'permission',
                width: 150,
                render: (text: any, record: TypePermissionGroup, index: number) => {
                    return (
                        record.permissions && record.permissions.length > 0 ? (
                            <Tooltip title={!this.state.grantPersmission && "Missing Grant Permission"}>
                                <Button disabled={!this.state.grantPersmission} style={{ marginLeft: -8 }} size='small' type='link' onClick={() => {
                                    if (record.system) {
                                        this.setState({
                                            visiblePermissionGroupInfo: true,
                                            selected: record
                                        })
                                    } else {
                                        this.setState({
                                            visible: true,
                                            selected: record
                                        })
                                    }
                                }}>{this.renderPermission(record.permissions.length)}</Button>
                            </Tooltip>
                        ) : "--/--"
                    )
                },
            },
            {
                title: 'User',
                dataIndex: 'user',
                ellipsis: true,
                key: 'user',
                width: 100,
                render: (text: any, record: TypePermissionGroup, index: number) => {
                    const countUser = this.props.permissionGroups.users && this.props.permissionGroups.users[record.id] ? this.props.permissionGroups.users[record.id].length : 0
                    return (
                        <>
                            {countUser === 0 ? (
                                <Tooltip title={this.state.grantPersmission ? "Assign to users" : "Missing Permission: GrantPrivilege"}>
                                    <Button disabled={!this.state.grantPersmission} style={{ marginLeft: -5 }} size='small' type='link' icon='plus-circle' onClick={() => {
                                        this.setState({
                                            visibleAssign: true,
                                            selected: record
                                        })
                                    }}></Button>
                                </Tooltip>
                            ) : (
                                <Tooltip title={this.state.grantPersmission ? "Assign to users" : "Missing Permission: GrantPrivilege"}>
                                    <Button disabled={!this.state.grantPersmission} style={{ marginLeft: -8 }} size='small' type='link' onClick={() => {
                                        this.setState({
                                            visibleAssign: true,
                                            selected: record
                                        })
                                    }}>{countUser === 1 ? `${countUser} user` : `${countUser} users`}</Button>
                                </Tooltip>
                            )}
                        </>
                    )
                },

            }, {
                title: 'Description',
                dataIndex: 'description',
                key: 'description',
                width: 200,
                render: (text: any, record: TypePermissionGroup, index: number) => {
                    return <LongTextTd text={record.description} />
                },
                ellipsis: true,
            }, {
                title: "Creation Time",
                key: "creation_time",
                width: 150,
                ellipsis: true,
                render(text: any, record: TypePermissionGroup, index: number) {
                    return record.createdAt ? (
                        <Tooltip title={`Created by ${record.createdBy} at ${moment(record.createdAt).format("DD/MM/YYYY HH:mm:ss")}`}>
                            {moment(record.createdAt).fromNow()}
                        </Tooltip>
                    ) : "--/--"
                },
            },
            {
                title: 'Updated Time',
                dataIndex: 'updatedAt',
                key: 'updatedAt',
                width: 150,
                ellipsis: true,
                render: (text: any, record: TypePermissionGroup, index: number) => {
                    return record.updatedAt ? (
                        <Tooltip title={`Updated by ${record.updatedBy} at ${moment(record.updatedAt).format("DD/MM/YYYY HH:mm:ss")}`}>
                            {moment(record.updatedAt).fromNow()}
                        </Tooltip>
                    ) : "--/--"
                },
            },
            {
                title: 'Action',
                width: 140,
                fixed: "right",
                render: (text: any, record: TypePermissionGroup, index: number) => {
                    return (
                        <React.Fragment>
                            <Button onClick={() => {
                                this.setState({
                                    visibleViewJSON: true,
                                    selected: record
                                })
                            }} size='small' type='link' icon='select' />

                            {!record.system ? (
                                <>
                                    <Divider type="vertical" />
                                    <Tooltip title={this.state.grantPersmission ? "" : `Missing Grant Permission`}>
                                        <Button disabled={this.state.grantPersmission ? false : true} size="small" type="link" icon="edit" onClick={() => {
                                            this.setState({
                                                visible: true,
                                                selected: record
                                            })
                                        }}></Button>
                                    </Tooltip>
                                </>

                            ) : null}

                            {!record.system ? (
                                <>
                                    <Divider type="vertical" />
                                    {this.state.grantPersmission ? (
                                        <Popconfirm title="Are you sure to delete this permission group?" onConfirm={() => { this.delete(record.id) }}>
                                            <Button size="small" type="link" icon="delete" ></Button>
                                        </Popconfirm>
                                    ) : (
                                        <Tooltip title={`Missing Grant Permission`}>
                                            <Button disabled size="small" type="link" icon="delete" ></Button>
                                        </Tooltip>
                                    )}
                                </>
                            ) : null}


                        </React.Fragment>
                    )
                }
            },
        ] as ColumnProps<{}>[],
        visibleAssign: false,
        grantPersmission: false
    };

    componentDidMount = () => {
        this.load();
        this.loadGrantPermission()
        this.props.updateMetaTitle({ title: BIGDATA_CONSTANT.trackTitle.ConsolePermissionGroup })
        this.timer = setTimeout(() => {
            this.props.updateMeta({
                name: BIGDATA_CONSTANT.trackTitle.ConsolePermissionGroup,
                title: BIGDATA_CONSTANT.trackTitle.ConsolePermissionGroup,
                app: "ACCOUNT",
                url: getCurrentURL(this.props.location)
            })
            clearTimeout(this.timer)
        }, 5000)
    }

    timer: any

    componentWillUnmount() {
        clearTimeout(this.timer)
    }

    renderPermission = (length: number) => {
        if (length === 1) return "1 permission"
        else {
            return `${length} permissions`
        }
    }

    delete = async (id: string) => {
        this.props.setGlobalLoading(true)
        const result = this.props.deletePermissionGroup(id)
        try {
            const response = await result
            if (response.success) {
                this.props.setGlobalLoading(true)
                notification.success({
                    message: BIGDATA_CONSTANT.message.default.title.success,
                    description: BIGDATA_CONSTANT.message.notification.success.delete,
                    icon: <Icon type="check-circle" style={{ color: getPrimaryColor(this.props.uiSetting) }} />
                })
                setTimeout(() => {
                    this.load()
                }, 1000);
            } else {
                notification.error({
                    message: BIGDATA_CONSTANT.message.default.title.error,
                    description: _.get(response, "message", BIGDATA_CONSTANT.message.notification.fail.delete),
                })
                this.props.setGlobalLoading(false)
            }
        } catch (error) {
            notification.error({
                message: BIGDATA_CONSTANT.message.default.title.error,
                description: _.get(error, "message", "Something went wrong"),
            })
            this.props.setGlobalLoading(false)
        }
    }

    convertoPermissionName = (id: string) => {
        let item: TypePermission | undefined = _.find(this.props.permissions.results, (item: TypePermission) => item.id == id)
        return item ? item.name : "--"
    }

    load = () => {
        this.props.setGlobalLoading(true)
        Promise.all([
            this.props.searchPermission(),
            this.props.searchPermissionGroup(this.state.criteria)
        ])
            .then((result) => {
                this.props.setGlobalLoading(false)
            })
    }

    checkCurrentUserIsSuperAdmin = (): boolean => {
        if (this.props.user) {
            if (this.props.user.orgCtx) {
                let roles = this.props.user.orgCtx.roles;
                let isSP = _.find(roles, (e) => {
                    return e == "SUPER_ADMIN"
                });

                if (isSP) {
                    return true;
                }
                return false;
            }
            return false;
        }
        return false;
    }

    renderBreadCrumb = () => {
        return (
            <Row type="flex" justify="space-between" align="middle" style={{ marginBottom: -15 }}>
                <Col>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Link to="/">Home</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>Admin</Breadcrumb.Item>
                        <Breadcrumb.Item>Permission Group</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
                <Col>
                    <Tooltip title={!this.state.grantPersmission && "Missing Grant Permission"}>
                        <Button disabled={!this.state.grantPersmission} type="primary" icon="plus-circle" onClick={() => {
                            this.setState({
                                visible: true,
                                selected: {}
                            })
                        }}>New Group</Button>
                    </Tooltip>
                </Col>

            </Row>

        )
    }
    async loadGrantPermission() {
        const result = await this.props.commonActionFromDALV2(PermissionDAL.grantPermission)
        try {
            const response = await result
            this.setState({
                grantPersmission: response.success
            })
        } catch (error) {
            this.setState({
                grantPersmission: false
            })
        }
    }

    renderTheme() {

        return (
            <DashboardPageLayout  {...this.props} selectedLeftNav="group-permision" selectedSubMenu="console" >
                <div className="org-page">

                    <MetaHeader title={this.props.ui.meta.title} />

                    <div className="contentLayout">
                        {this.renderBreadCrumb()}
                        <br />
                        <div className="containerInner">
                            <Row type='flex' gutter={[5, 5]}>
                                <Col xs={24}>
                                    <Typography.Title level={4}>Permission Group</Typography.Title>
                                </Col>
                                <Col xs={24}>
                                    <FormFilterComponent
                                        search={(values: any) => {
                                            this.setState({
                                                criteria: Object.assign(this.state.criteria, values)
                                                ,
                                            }, () => {
                                                this.load()
                                            })
                                        }}
                                        commonActionFromDALV2={this.props.commonActionFromDALV2}
                                    />
                                </Col>

                                <Col xs={24}>
                                    <InfoList
                                        list={this.props.permissionGroups}
                                        refresh={this.load}
                                    />
                                </Col>

                                <Col xs={24}>
                                    <Table
                                        size="small"
                                        bordered={false}
                                        columns={this.state.columns}
                                        dataSource={this.props.permissionGroups.results.content}
                                        pagination={false}
                                        scroll={{ x: 'calc(600px + 50%)' }}
                                    />
                                </Col>

                                <Col xs={24}>
                                    <O2OCustomPaging
                                        list={this.props.permissionGroups}
                                        onChangePageSize={(e: any) => {
                                            this.setState({
                                                criteria: Object.assign(this.state.criteria, { page: 0, pageSize: e })
                                            }, () => {
                                                this.load()
                                            })
                                        }}
                                        onChange={(e: any) => {
                                            this.setState({
                                                criteria: Object.assign(this.state.criteria, { page: e - 1 })
                                            }, () => {
                                                this.load()
                                            })
                                        }}
                                    />
                                </Col>

                            </Row>

                            <DrawerPermissionGroupInfo
                                onClose={() => {
                                    this.setState({
                                        visiblePermissionGroupInfo: false
                                    })
                                }}
                                visible={this.state.visiblePermissionGroupInfo}
                                permission={this.props.permissions}
                                item={this.state.selected}
                            />

                            <DrawerCreatePermissionGroup
                                onClose={() => {
                                    this.setState({
                                        visible: false
                                    })
                                }}
                                visible={this.state.visible}
                                permission={this.props.permissions}
                                item={this.state.selected}
                                updatePermissionGroup={this.props.updatePermissionGroup}
                                onSuccess={() => {
                                    setTimeout(() => {
                                        this.load()
                                    }, 1000);
                                }}
                                createPermissionGroup={this.props.createPermissionGroup}
                                commonActionFromDALV2={this.props.commonActionFromDALV2}
                            />

                            <DrawerAssignUser
                                onClose={() => {
                                    this.setState({
                                        visibleAssign: false
                                    })
                                }}
                                visible={this.state.visibleAssign}
                                item={this.state.selected}
                                onSuccess={() => {
                                    setTimeout(() => {
                                        this.load()
                                    }, 1000);
                                }}
                                commonActionFromDALV2={this.props.commonActionFromDALV2}
                                userList={this.props.permissionGroups.users}
                            />



                            <DrawerViewJSON
                                data={this.state.selected}
                                visible={this.state.visibleViewJSON}
                                onClose={() => {
                                    this.setState({
                                        visibleViewJSON: false,
                                        selected: {}
                                    })
                                }}
                            />

                        </div>
                    </div>

                </div>
            </DashboardPageLayout>
        )
    }

    render() {
        return (
            this.renderTheme()
        );
    }
}
