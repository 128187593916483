import { BIGDATA_CONSTANT } from "../../../../common/constant";
import RestClient from "../../../../libs/api/RestClient";
import { Methods } from "../../../../libs/api/Methods";
import { toQuery } from "../../../../common/helper";
import _ from "lodash";
import { TypeCriteriaPermission } from "../model/permissions";

export interface IPermissionGroupCriteria {
    page: number,
    pageSize: number,
    freeText?: string
    permission: string[]
    userId: string[]
}

class DAL {

    searchPermission() {
        let url = BIGDATA_CONSTANT.url.api + `permission/meta/all`;

        var restClient = new RestClient(Methods.GET, url);

        var headers = {};

        return new Promise((resolve, reject) => {
            restClient.execute(headers, {}).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }
    searchPermissionGroup(criteria: IPermissionGroupCriteria) {
        const objectQuery = {
            page: criteria.page,
            pageSize: criteria.pageSize,

        }
        const payload = {
            permission: criteria.permission,
            userId: criteria.userId,
            query: criteria.freeText,
        }
        const query = toQuery(_.omitBy(objectQuery, _.isNil))
        let url = BIGDATA_CONSTANT.url.api + `mngmnt-client/permission/group/search?${query}`;

        var restClient = new RestClient(Methods.POST, url);

        var headers = {};
        var body = payload

        return new Promise((resolve, reject) => {
            restClient.execute(headers, body ? body : {}).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }
    searchAllPermissionGroup() {
        let url = BIGDATA_CONSTANT.url.api + `mngmnt-client/permission/group/get/_all`;
        var restClient = new RestClient(Methods.GET, url);
        var headers = {};
        return new Promise((resolve, reject) => {
            restClient.execute(headers, {}).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }
    searchPermissionGroupContext(q: string) {
        let url = BIGDATA_CONSTANT.url.api + `permission/group/all?query=${q}`;

        var restClient = new RestClient(Methods.GET, url);

        var headers = {};

        return new Promise((resolve, reject) => {
            restClient.execute(headers, {}).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }


    createPermissionGroup(criteria: any) {
        let url = BIGDATA_CONSTANT.url.api + `mngmnt-client/permission/group/add`;
        var restClient = new RestClient(Methods.POST, url);

        var headers = {
        };

        return new Promise((resolve, reject) => {
            restClient.execute(headers, criteria).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });

    }
    updatePermissionGroup(id: string, criteria: any) {
        let url = BIGDATA_CONSTANT.url.api + `mngmnt-client/permission/group/${id}/update`;
        var restClient = new RestClient(Methods.POST, url);
        var headers = {
        };
        return new Promise((resolve, reject) => {
            restClient.execute(headers, criteria).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });

    }
    deletePermissionGroups(ids: string) {
        let url = BIGDATA_CONSTANT.url.api + `mngmnt-client/permission/group/delete?ids=${ids}`;
        var restClient = new RestClient(Methods.POST, url);
        var headers = {
        };
        return new Promise((resolve, reject) => {
            restClient.execute(headers, {}).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });

    }

    assignUsersToPermissionGroups(userIds: string[], permissionGroupIds: string[]) {
        let url = BIGDATA_CONSTANT.url.api + `mngmnt-client/permission/group/user/assign`;
        var restClient = new RestClient(Methods.POST, url);
        var headers = {
        };
        return new Promise((resolve, reject) => {
            restClient.execute(headers, {
                "groupIds": permissionGroupIds,
                "userIds": userIds

            }).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }

    saveUsersToPermissionGroups(userIds: string[], permissionGroupIds: string[]) {
        let url = BIGDATA_CONSTANT.url.api + `mngmnt-client/permission/group/user/save`;
        var restClient = new RestClient(Methods.POST, url);
        var headers = {
        };
        return new Promise((resolve, reject) => {
            restClient.execute(headers, {
                "groupIds": permissionGroupIds,
                "userIds": userIds

            }).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }

    getAccounts(query?: string) {
        let url = BIGDATA_CONSTANT.url.api + `accounts/listing/_simple?query=${query}`;
        var restClient = new RestClient(Methods.GET, url);
        ;
        return new Promise((resolve, reject) => {
            restClient.execute({}, {}).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }
    getPermissions(query?: string) {
        let url = BIGDATA_CONSTANT.url.api + `mngmnt-client/permissions/suggest?query=${query ? encodeURIComponent(query) : "" }`;
        var restClient = new RestClient(Methods.GET, url);
        ;
        return new Promise((resolve, reject) => {
            restClient.execute({}, {}).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }

    searchPermissionTable(criterial: TypeCriteriaPermission) {
        const { pageSize = 10 } = criterial ? criterial : { pageSize: 10 };
        let url = BIGDATA_CONSTANT.url.api + `mngmnt-client/permissions/search?pageSize=${pageSize}`;

        let paramsUrl = new URLSearchParams();
        if (criterial && criterial.page) {
            const p = criterial.page - 1;
            paramsUrl.append("page", "" + p);
        }
        if (criterial && criterial.page) {
            url = url + "&" + paramsUrl.toString();
        }
        var restClient = new RestClient(Methods.POST, url);
        return new Promise((resolve, reject) => {
            restClient.execute({}, criterial ? criterial : {}).then(
                (response: any) => {
                    const user: any = response.data;
                    resolve(user);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    }

    saveUsersGrant(groupId: string, userIds: string[]) {
        let url = BIGDATA_CONSTANT.url.api + `mngmnt-client/permission/groups/${groupId}/users/grant`;
        var restClient = new RestClient(Methods.POST, url);
        var headers = {
        };
        return new Promise((resolve, reject) => {
            restClient.execute(headers, {
                "userIds": userIds
            }).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }
    getDetailPermission(id: string, loadPermissionGroup: boolean) {
        let url = BIGDATA_CONSTANT.url.api + `mngmnt-client/accounts/detail?id=${id}&loadPermissionGroup=${loadPermissionGroup}`;
        var restClient = new RestClient(Methods.GET, url);
        var headers = {
        };
        return new Promise((resolve, reject) => {
            restClient.execute(headers, {}).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }

    grantPermission() {
        let url = BIGDATA_CONSTANT.url.api + `mngmnt-client/permissions/has_grant_permission`;
        var restClient = new RestClient(Methods.GET, url);
        return new Promise((resolve, reject) => {
            restClient.execute({}, {}).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }
    grantPermissionSA(dto: any) {
        let url = BIGDATA_CONSTANT.url.api + `mngmnt/permission/system-groups/has_grant_permission`;
        var restClient = new RestClient(Methods.POST, url);
        return new Promise((resolve, reject) => {
            restClient.execute({}, dto ? dto : {}).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }

    assignGrantPermissionSA(orgId: string, dto: any) {
        let url = BIGDATA_CONSTANT.url.api + `mngmnt/permission/system-groups/${orgId}/assign_grant_permission`;
        var restClient = new RestClient(Methods.POST, url);
        return new Promise((resolve, reject) => {
            restClient.execute({}, dto ? dto : {}).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }

    unassignGrantPermissionSA(orgId: string, dto: any) {
        let url = BIGDATA_CONSTANT.url.api + `mngmnt/permission/system-groups/${orgId}/unassigned_grant_permission`;
        var restClient = new RestClient(Methods.POST, url);
        return new Promise((resolve, reject) => {
            restClient.execute({}, dto ? dto : {}).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }
    hasAccountPermissionSA(dto: any) {
        let url = BIGDATA_CONSTANT.url.api + `mngmnt/permission/system-groups/has_account_permission`;
        var restClient = new RestClient(Methods.POST, url);
        return new Promise((resolve, reject) => {
            restClient.execute({}, dto ? dto : {}).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }

    assignAccountPermissionSA(orgId: string, dto: any) {
        let url = BIGDATA_CONSTANT.url.api + `mngmnt/permission/system-groups/${orgId}/assign_account_permission`;
        var restClient = new RestClient(Methods.POST, url);
        return new Promise((resolve, reject) => {
            restClient.execute({}, dto ? dto : {}).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }

    unassignAccountPermissionSA(orgId: string, dto: any) {
        let url = BIGDATA_CONSTANT.url.api + `mngmnt/permission/system-groups/${orgId}/unassigned_account_permission`;
        var restClient = new RestClient(Methods.POST, url);
        return new Promise((resolve, reject) => {
            restClient.execute({}, dto ? dto : {}).then((response: any) => {
                const user: any = response.data;
                resolve(user)
            }, error => {
                reject(error);
            });
        });
    }
}

export const PermissionDAL = new DAL()
