import React from 'react'
import { DashboardPageLayout } from '../../../layout/dashboard/DashboardPageLayout'
import CommonTypeProps, { ICommonPayload } from '../../../common/CommonTypeProps'
import { MetaHeader } from '../../../shares/MetaHeader'
import { Breadcrumb, Button, Col, Icon, Modal, Row, Table, Tooltip, Typography, notification } from 'antd'
import { Link } from 'react-router-dom'
import { CanAccess, InfoList, LongTextTd, O2OCustomPaging } from 'o2o_layout'
import { useAsyncRetry } from 'react-use'
import { InternalPermissions, TypeCriteriaInternalPermission, TypeInternalPermissionItem } from './models/model'
import { InternalPermissionDAL } from './DAL/InternalPermissionDAL'
import _ from 'lodash'
import { ColumnProps } from 'antd/lib/table'
import FilterInternalPermission from './components/FilterInternalPermission'
import DrawerGrant from './drawer/DrawerGrant'
import { BIGDATA_CONSTANT } from '../../../common/constant'
import { getCurrentURL, getPrimaryColor } from '../../../common/helper'
import { useDispatch } from '../../../redux/store'
import { IActionUI, SHOW_COMMON_ERROR } from '../../../redux/reducer/uiReducer'

interface IProps extends CommonTypeProps {
}

const initCriteria: ICommonPayload<TypeCriteriaInternalPermission> = {
    loading: false,
    type: "",
    payload: { page: 0, pageSize: 10 } as TypeCriteriaInternalPermission,
}

const SEARCH_INTERNAL_PERMISSION = "SEARCH_INTERNAL_PERMISSION"

const reducerCriteriaWebhook = (state: ICommonPayload<TypeCriteriaInternalPermission>, action: ICommonPayload<TypeCriteriaInternalPermission>) => {
    switch (action.type) {
        case SEARCH_INTERNAL_PERMISSION:
            return { ...state, ...action }
        default:
            throw new Error()
    }
}
const InternalPermissionComponent = (props: IProps) => {
    const [criteria, dispatchCriteria]: [ICommonPayload<TypeCriteriaInternalPermission>, (action: ICommonPayload<TypeCriteriaInternalPermission>) => void] = React.useReducer(reducerCriteriaWebhook, initCriteria)
    const [visibleGrant, setVisibleGrant] = React.useState<boolean>(false)
    const [hasOwnerPermission, setHasOwnerPermission] = React.useState<boolean>(false)

    const dispatch = useDispatch()

    const loadData = async () => {
        const result: Promise<any> = props.commonActionFromDALV2(InternalPermissionDAL.search, criteria.payload)
        try {
            const response = await result
            if (response.success) {
                return response
            } else {
            }
        } catch (error) {
        }
    }

    const stateInternalPermission = useAsyncRetry(async () => {
        if (criteria.payload) {
            const data = await loadData()
            return data
        }
        return new InternalPermissions()
    }, [criteria.payload])



    const internalPermissionRes = stateInternalPermission.value ? stateInternalPermission.value : new InternalPermissions()

    const loadOwnerHasPermission = async () => {
        const result = props.commonActionFromDALV2(InternalPermissionDAL.hasOwnerPermission)
        try {
            const response = await result
            setHasOwnerPermission(response.success)
        } catch (error) {
            setHasOwnerPermission(false)
        }
    }


    const renderBreadCrumb = () => {
        return (
            <Row type="flex" justify="space-between" align="middle" style={{ marginBottom: "7px" }}>
                <Col>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Link to="/">Home</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>Super Admin</Breadcrumb.Item>
                        <Breadcrumb.Item>Internal Permission</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>

                <Col>
                    <CanAccess permission={["SUPER_ADMIN"]} userRoles={props.user.orgCtx ? props.user.orgCtx.roles : []} >
                        <Tooltip title={!hasOwnerPermission && "Missing Permission: Owner"}>
                            <Button disabled={!hasOwnerPermission} type="primary" icon="plus" onClick={() => {
                                setVisibleGrant(true)
                            }}>Grant</Button>
                        </Tooltip>
                    </CanAccess>
                </Col>

            </Row>

        )
    }

    const columns: ColumnProps<TypeInternalPermissionItem>[] = [
        {
            title: "#",
            key: "index",
            sorter: false,
            width: 45,
            render: (text: any, record: TypeInternalPermissionItem, index: number) => {
                return index + 1
            }
        }, {
            title: "User Id",
            key: "user_id",
            sorter: false,
            render: (text: any, record: TypeInternalPermissionItem, index: number) => {
                return <LongTextTd text={record.userId} />
            }
        }, {
            title: "Organization",
            key: "organization",
            sorter: false,
            render: (text: any, record: TypeInternalPermissionItem, index: number) => {
                return <LongTextTd text={record.orgId} />
            }
        }, {
            title: "Permission",
            key: "organization",
            sorter: false,
            render: (text: any, record: TypeInternalPermissionItem, index: number) => {
                return <LongTextTd text={record.permission} />
            }
        }, {
            title: "",
            key: "action",
            sorter: false,
            fixed: "right",
            render: (text: any, record: TypeInternalPermissionItem, index: number) => {
                const renderContent = () => {
                    return (
                        <>
                            <p>You are about to revoke the permission:</p>
                            <span>- ORG:  <strong>{record.orgId}</strong> </span> <br />
                            <span> - USER: {<strong>{record.userId}</strong>}</span> <br />
                            <span> - PERMISSION: {<strong>{record.permission}</strong>}</span>
                            <p>Are you sure to continue?</p>
                        </>
                    )
                }
                return (
                    <Tooltip placement='leftTop' title={!hasOwnerPermission ? "Missing Permission: Owner" : "Revoke Permission"}>
                        <Button disabled={!hasOwnerPermission} size='small' type='link' icon='close-circle' onClick={() => {
                            Modal.confirm({
                                title: "Revoke Permission",
                                content: renderContent(),
                                okText: "Revoke",
                                cancelText: "Cancel",
                                onOk: async () => {
                                    await onRevokePermission(record)
                                },
                            })
                        }} />
                    </Tooltip>
                )
            }
        },
    ]

    const onRevokePermission = async (record: TypeInternalPermissionItem) => {
        const payload = {
            orgId: record.orgId,
            userIds: [record.userId],
            permissions: [record.permission]
        }
        const result = props.commonActionFromDALV2(InternalPermissionDAL.revokePermissions, payload)
        try {
            const response = await result
            if (response.success) {
                notification.success({
                    message: BIGDATA_CONSTANT.message.default.title.success,
                    description: "Revoke permission successfully",
                    icon: <Icon type="check-circle" style={{ color: getPrimaryColor(props.uiSetting) }} />
                })
                setTimeout(() => {
                    stateInternalPermission.retry()
                }, 1000);

            } else {
                dispatch({
                    type: SHOW_COMMON_ERROR,
                    commonError: response,
                } as IActionUI);
            }
        } catch (error) {
            dispatch({
                type: SHOW_COMMON_ERROR,
                commonError: error,
            } as IActionUI);
        }
    }

    const renderDrawerGrant = () => {
        return (
            <DrawerGrant
                visible={visibleGrant}
                onClose={() => {
                    setVisibleGrant(false)
                }}
                commonActionFromDALV2={props.commonActionFromDALV2}
                onSuccess={() => {
                    setTimeout(() => {
                        stateInternalPermission.retry()
                    }, 1000);
                }}
            />
        )
    }

    React.useEffect(() => {
        loadOwnerHasPermission()
        props.updateMetaTitle({ title: BIGDATA_CONSTANT.trackTitle.SPInternalPermission })
        const timer = setTimeout(() => {
            props.updateMeta({
                name: BIGDATA_CONSTANT.trackTitle.SPInternalPermission,
                title: BIGDATA_CONSTANT.trackTitle.SPInternalPermission,
                app: "ACCOUNT",
                url: getCurrentURL(props.location)
            })
        }, 5000)

        return () => {
            clearTimeout(timer)
        }
    }, [])

    return (
        <DashboardPageLayout  {...props} selectedLeftNav="internal_permission" selectedSubMenu="console-super">
            <div className="org-page">
                <MetaHeader title={props.ui.meta.title} />
                <div className="contentLayout">
                    {renderBreadCrumb()}
                    <div className="containerInner">
                        <Row gutter={[5, 5]} type="flex" justify="space-between" align="middle" style={{ marginTop: -10 }}>
                            <Col xs={24}>
                                <Typography.Title level={4}>
                                    Internal Permission
                                </Typography.Title>
                            </Col>
                            <Col xs={24}>
                                <FilterInternalPermission
                                    loading={stateInternalPermission.loading}
                                    onSearch={(values: any) => {
                                        dispatchCriteria({
                                            loading: false,
                                            payload: {
                                                ...criteria.payload,
                                                ...{ page: 0, ...values },
                                            },
                                            type: SEARCH_INTERNAL_PERMISSION,
                                        });
                                    }}
                                    commonActionFromDALV2={props.commonActionFromDALV2}
                                />
                            </Col>

                            <Col xs={24}>
                                <InfoList
                                    loading={stateInternalPermission.loading}
                                    list={internalPermissionRes}
                                    refresh={stateInternalPermission.retry}
                                />
                            </Col>
                            <Col xs={24}>
                                <Table
                                    loading={stateInternalPermission.loading}
                                    columns={columns}
                                    dataSource={internalPermissionRes.results.content}
                                    rowKey="id"
                                    size="small"
                                    pagination={false}
                                />
                            </Col>

                            <Col xs={24} style={{ marginLeft: 8, paddingBottom: 16 }}>
                                <O2OCustomPaging
                                    list={internalPermissionRes}
                                    onChange={(e: number) => {
                                        dispatchCriteria({
                                            loading: false,
                                            payload: { ...criteria.payload, ...({ page: e - 1 } as any) },
                                            type: SEARCH_INTERNAL_PERMISSION,
                                        });
                                    }}
                                    onChangePageSize={(e: number) => {
                                        dispatchCriteria({
                                            loading: false,
                                            payload: { ...criteria.payload, ...({ page: 0, pageSize: e } as any) },
                                            type: SEARCH_INTERNAL_PERMISSION,
                                        });
                                    }}
                                />
                            </Col>

                            {renderDrawerGrant()}
                        </Row>
                    </div>
                </div>
            </div>
        </DashboardPageLayout>
    )
}

export default InternalPermissionComponent