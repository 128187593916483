import { ConsoleUser, ConsoleUsers, Organizations, UIDataSetting, UISetting } from 'o2o_layout/lib/common/model';
import { routerReducer } from 'react-router-redux';
import { combineReducers } from 'redux';
import { organizationReducer } from '../../modules/orgs/orgsReducer';
import { consoleUserDetailReducer, consoleUserReducer } from '../../modules/users/consoleUserReducer';
import { Authen, UI } from '../model';
import { CommonResponse } from '../model/commonResponse';
import { authenReducer } from './authenReducer';
import { commonResponseReducer } from './commonResponseReducer';
import { localeReducer } from './localeReducer';
import { uiReducer } from './uiReducer';
import { userReducer } from './userReducer';

import { linkOrgsNoContentReducer, linkOrgsReducer } from '../../modules/orgs/link-orgs/linkOrgReducer';
import { LinkOrgs, LinkOrgsNoContent } from '../../modules/orgs/link-orgs/model/linkOrgs';
import { uiDataSettingReducer, uiSettingReducer } from '../../modules/setting/reducer/uiSettingReducer';
import { UserTokens } from '../../modules/users/info/model/usertoken';
import { userTokenReducer } from '../../modules/users/info/reducer/userTokenReducer';
import { AllPermissionGroups, PermissionGroups, PermissionGroupsContext, Permissions } from '../../modules/users/permisions/model/permissions';
import { allPermissionGroupsReducer, permissionGroupsContextReducer, permissionGroupsReducer, permissionsReducer } from '../../modules/users/permisions/reducer/permissionReducer';
import { TypeEDMSendInBlue, edmSendInBlueReducer } from './edmSendInBlueReducer';
import { currentUserOrgsReducer, typeCurrentUserOrgs } from './selectCurrentOrgReducer';
import { ObjectModels, customFieldObjectsReducer } from './customFieldsReducer';
export interface IState {
  ui: UI,
  routing: any,
  locale: any,
  user: any,
  authen: Authen,
  commonResponse: CommonResponse,
  organizations: Organizations,
  consoleUsers: ConsoleUsers,
  consoleUser: ConsoleUser,
  currentUserOrgs: typeCurrentUserOrgs,
  edmSendInBlue: TypeEDMSendInBlue,
  linkOrgs: LinkOrgs,
  linkOrgsNoContent: LinkOrgsNoContent,
  userTokens: UserTokens,
  permissions: Permissions,
  permissionGroups: PermissionGroups,
  allPermissionGroups: AllPermissionGroups,
  permissionGroupsContext: PermissionGroupsContext,
  uiSetting: UISetting,
  uiDataSetting: UIDataSetting,
  customFieldObjects: ObjectModels,

};
export const state = combineReducers<IState>({
  ui: uiReducer,
  routing: routerReducer,
  locale: localeReducer,
  user: userReducer,
  authen: authenReducer,
  commonResponse: commonResponseReducer,
  organizations: organizationReducer,
  consoleUsers: consoleUserReducer,
  consoleUser: consoleUserDetailReducer,
  edmSendInBlue: edmSendInBlueReducer,
  currentUserOrgs: currentUserOrgsReducer,
  linkOrgs: linkOrgsReducer,
  linkOrgsNoContent: linkOrgsNoContentReducer,
  userTokens: userTokenReducer,
  permissions: permissionsReducer,
  permissionGroups: permissionGroupsReducer,
  allPermissionGroups: allPermissionGroupsReducer,
  permissionGroupsContext: permissionGroupsContextReducer,
  uiSetting: uiSettingReducer,
  uiDataSetting: uiDataSettingReducer,
  customFieldObjects: customFieldObjectsReducer,
});