import { IResponseBigDataVN, TypeResultsBigData } from "o2o_layout/lib/common/interface";

export type TypeExportsHistory = {
    id?: string,
    orgId?: string,
    category?: string,
    source?: string,
    fileName?: string,
    criteria?: string,
    totalRecords?: number,
    status?: string,
    message?: string,
    exportedBy?: string,
    startTime?: number,
    endTime?: number,
    timeTaken?: number,
    action?: string,
    actionValue?: string
    reason?: string
}

export class ExportsHistory implements IResponseBigDataVN<TypeExportsHistory>  {
    success: boolean;
    results: TypeResultsBigData<TypeExportsHistory>;
    constructor() {
        this.success = false
        this.results = {
            content: [],
            timeTaken: 0,
            currentTime: 0,
            page: {
                pageSize: 0,
                currentPage: 0,
                totalRecords: 0,
                totalPages: 0
            },
            extras: null
        }
    }
}

export class DistinctFields {
    result: {
        fields: string[],
        results: {
            [key: string]: {}
        }
    };
    success: boolean
}