export interface IResponse{
    status: number,
    data: any,
    error: any,
    message: any
}

export class Response implements IResponse {
    public status: number;
    public data: any;
    public error: any;
    public message: any;

    constructor({ status = 200, data = null, error = null, message = null }) {
        this.status = status;
        if (data){
            this.data = data;
        }
        if (error){
            this.error = error;
        }
        if (message){
            this.message = message;
        }
    }

}