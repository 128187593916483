import { IResponseBigDataVN, TypeResultsBigData } from "o2o_layout/lib/common/interface"

export type TypeLoginActivity = {
    id?: string
    userId?: string
    orgId?: string
    action?: string
    oldValue?: string
    newValue?: string
    extraValue?: string
    status?: string
    actionAt?: number
    message?: number
    actionBy?: string
    source?: string
    ipAddress?: string
    userAgent?: string
    deviceCtx?: any
    osNameCtx?: any
    browserTypeCtx?: any
    browserNameCtx?: any
}

export class LoginActivities implements IResponseBigDataVN<TypeLoginActivity> {
    success: boolean
    results: TypeResultsBigData<TypeLoginActivity>
    constructor() {
        this.results = {
            content: [],
            timeTaken: 0,
            currentTime: 0,
            page: {
                pageSize: 0,
                currentPage: 0,
                totalRecords: 0,
                totalPages: 0
            },
            extras: null
        }
        this.success = false
    }

}