import { Button, Card, Col, Drawer, Form, Input, Popconfirm, Row, Select, Switch, Tooltip, Typography } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import { get, map, omit } from 'lodash'
import moment from "moment"
import { DrawerSetTaskTimer, RenderScheduleTimes } from 'o2o_layout'
import { TypeOrganization } from 'o2o_layout/lib/common/model'
import React, { useEffect, useState } from 'react'
import { getUTCGMTOffset } from '../../../common/helper'
import { IActionUI, SHOW_COMMON_ERROR } from '../../../redux/reducer/uiReducer'
import { useDispatch } from '../../../redux/store'
import ButtonBackDrawer from '../../../shares/ButtonBackDrawer'
import { CommonNotificationAddSuccessfully, CommonNotificationSaveError, CommonNotificationUpdateSuccessfully } from '../../../shares/CommonNotification'
import { O2OFormItem } from '../../../shares/O2OFormItem'
import SuggestionOrgs from '../../users/drawer/SuggestionOrgs'
import { CronjobDAL } from './DAL'
import { TypeCronjob } from './model'

interface IDrawerAddEditCronjobProps extends FormComponentProps {
    onClose: () => void
    onSuccess: () => void
    visible: boolean
    commonActionFromDAL2: any
    selectedRecord?: TypeCronjob
}

const DrawerAddEditCronjob = ({ onClose, visible, form, commonActionFromDAL2, onSuccess, selectedRecord }: IDrawerAddEditCronjobProps) => {
    const [visibleTask, setVisibleTask] = React.useState(false)
    const [taskTimer, setTaskTimer] = useState(undefined as any)
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()

    useEffect(() => {
        if (!visible) {
            form.resetFields()
        }
    }, [visible])

    useEffect(() => {
        if (visible && selectedRecord) {
            const days = selectedRecord && selectedRecord.days ? map(selectedRecord.days, (value: number) => `${value}`) : []
            const hours = selectedRecord && selectedRecord.hours ? map(selectedRecord.hours, (value: number) => `${value}`) : []
            const months = selectedRecord && selectedRecord.months ? map(selectedRecord.months, (value: number) => `${value}`) : []
            const minutes = selectedRecord && selectedRecord.minutes ? map(selectedRecord.minutes, (value: number) => `${value}`) : []
            const initTimer = {
                days: days,
                hours: hours,
                months: months,
                minutes: minutes,
                gmt: selectedRecord && selectedRecord.gmt ? selectedRecord.gmt : '',
                startTime: selectedRecord && selectedRecord.validFromTime ? selectedRecord.validFromTime : 0,
                endTime: selectedRecord && selectedRecord.validToTime ? selectedRecord.validToTime : 0,
            } as { days: string[], hours: string[], months: string[], minutes: string[], gmt: string, startTime: number, endTime: number }
            setTaskTimer(initTimer)
        } else {
            setTaskTimer(undefined)
        }
    }, [visible, selectedRecord])


    const renderMonth = () => {
        if (!taskTimer) {
            return null
        }
        let data: string[] | undefined = taskTimer.months
        if (data) {
            if (data.length == 1 && data[0] == "-1") {
                return "every month"
            } else if (data.length <= 3) {
                return `every ${data.map((i: string) => moment(i, "MM").format("MMM")).join(", ")}`
            } else {
                return <Tooltip title={`${data.map((i: string) => moment(i, "MM").format("MMM")).join(", ")}`}>
                    every {data.slice(2).map((i: string) => moment(i, "MM").format("MMM")).join(", ")}...
                </Tooltip>
            }
        }
        return "every month"
    }
    const renderDay = () => {
        if (!taskTimer) {
            return null
        }
        let data: string[] | undefined = taskTimer.days
        if (data) {
            if (data.length == 1 && data[0] == "-1") {
                return "every day"
            } else {
                return <Tooltip title={`${data.join(", ")}`}>
                    on days: {data.join(", ")}
                </Tooltip>
            }
        }
        return "every day"
    }
    const renderGMT = () => {
        if (!taskTimer) {
            return null
        }
        let gmt: string | undefined = taskTimer.gmt
        if (gmt) {
            return getUTCGMTOffset(+gmt) + " GMT"
        }
        return "--/--"
    }

    const onSave = () => {
        form.validateFields(async (err: any, values: any) => {
            if (!err) {
                setLoading(true)
                const timer = { ...omit(taskTimer, ['startTime', "endTime"]), ... { validFromTime: get(taskTimer, 'startTime'), validToTime: get(taskTimer, 'endTime') } }
                const payload = {
                    endpointMethod: values.endpointMethod,
                    endpointUrl: values.endpointUrl,
                    name: values.name,
                    orgId: values.orgId,
                    extParam: {
                        orgName: values.orgName
                    },
                    status: values.status ? 'Active' : 'Inactive',
                    ...timer
                }
                if (selectedRecord) {
                    updateUpdate(payload)
                    return
                }
                const result = commonActionFromDAL2(CronjobDAL.add, payload)
                try {
                    const response = await result
                    if (response.success) {
                        onSuccess()
                        onClose()
                        CommonNotificationAddSuccessfully()
                    } else {
                        CommonNotificationSaveError(response.message)
                    }
                    setLoading(false)
                } catch (error) {
                    setLoading(false)
                    dispatch({
                        type: SHOW_COMMON_ERROR,
                        commonError: err
                    } as IActionUI)
                }
            }
        })
    }

    const updateUpdate = async (payload: any) => {
        const result = commonActionFromDAL2(CronjobDAL.update, get(selectedRecord, 'id'), payload)
        try {
            const response = await result
            if (response.success) {
                onSuccess()
                onClose()
                CommonNotificationUpdateSuccessfully()
            } else {
                CommonNotificationSaveError(response.message)
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            dispatch({
                type: SHOW_COMMON_ERROR,
                commonError: error
            } as IActionUI)
        }
    }

    return (
        <Drawer
            bodyStyle={{ marginBottom: 100 }}
            title={<span>
                <ButtonBackDrawer onClick={onClose} />
                {selectedRecord ? <>{selectedRecord.name} / update</> : 'Add a job'}
            </span>}
            visible={visible}
            onClose={() => {
                onClose()
            }}
            maskClosable={false}
            width={600}
        >
            <Form hideRequiredMark colon={false}>
                <Row type='flex' gutter={[5, 5]}>
                    <Col xs={24}>
                        <O2OFormItem className={'console--o2oFormItem__hideMessage'} label={'Name'}>
                            {form.getFieldDecorator("name", {
                                initialValue: get(selectedRecord, 'name'),
                                rules: [{
                                    required: true
                                }]
                            })(<Input placeholder='Enter' allowClear />)}
                        </O2OFormItem>
                    </Col>
                    <Col xs={24}>
                        {form.getFieldDecorator("orgName", {
                            initialValue: get(selectedRecord, 'extParam.orgName'),
                        })}
                        <SuggestionOrgs
                            onSelect={(orgnization: TypeOrganization) => {
                                form.setFieldsValue({
                                    orgName: orgnization.name
                                })
                            }}
                            initData={get(selectedRecord, 'orgId')}
                            required={false}
                            form={form}
                            keyField={'orgId'}
                            commonActionFromDAL2={commonActionFromDAL2}
                            visible={true}
                            lable={<span>
                                Organization
                            </span>}
                        />
                    </Col>
                    <Col xs={24}>
                        <O2OFormItem label={'Timer'}>
                            <Card size='small'>

                                {
                                    taskTimer && <Typography.Text style={{ marginBottom: 20, marginTop: 0 }}>
                                        Occur on <strong> {renderMonth()} <Button size="small" type="link" icon="edit" onClick={() => {

                                            setVisibleTask(true)

                                        }} /></strong> <strong> {renderDay()} <Button size="small" type="link" icon="edit" onClick={() => {

                                            setVisibleTask(true)

                                        }} /> </strong>  <strong> <RenderScheduleTimes taskTimer={taskTimer} /> <Button size="small" type="link" icon="edit" onClick={() => {

                                            setVisibleTask(true)

                                        }} /></strong>
                                        <strong>{renderGMT()}</strong>

                                        {taskTimer.startTime && taskTimer.endTime ? <> <br />Valid FromTime - Valid ToTime: <strong>{moment(taskTimer.startTime).format("YYYY-MM-DD HH:mm:ss")}</strong> - <strong>{moment(taskTimer.endTime).format("YYYY-MM-DD HH:mm:ss")}</strong>   <br /></> : ""}
                                        <Popconfirm title="Are you sure to remove the timer?" onConfirm={() => {
                                            setTaskTimer(undefined)
                                        }}>
                                            <Button type="link" icon="delete">Remove the timer</Button>
                                        </Popconfirm>

                                    </Typography.Text>
                                }

                                {
                                    !taskTimer &&
                                    <Button size='small' icon="clock-circle" type="link" onClick={() => {
                                        setVisibleTask(true)
                                    }}>Config Schedule</Button>
                                }
                            </Card>
                        </O2OFormItem>
                    </Col>


                    <Col xs={24}>
                        <O2OFormItem label={<span>
                            Endpoint
                        </span>}>
                            <Row type='flex' gutter={[5, 5]}>
                                <Col xs={4}>
                                    {
                                        form.getFieldDecorator("endpointMethod", {
                                            initialValue: get(selectedRecord, 'endpointMethod', 'POST'),
                                        })(
                                            <Select showArrow placeholder="Select">
                                                <Select.Option value={"POST"}>POST</Select.Option>
                                                <Select.Option value={"GET"}>GET</Select.Option>
                                            </Select>
                                        )
                                    }
                                </Col>
                                <Col xs={20}>
                                    <O2OFormItem className={'console--o2oFormItem__hideMessage'}>
                                        {
                                            form.getFieldDecorator("endpointUrl", {
                                                initialValue: get(selectedRecord, 'endpointUrl'),
                                                rules: [{
                                                    required: true
                                                }]
                                            })(
                                                <Input placeholder='Enter' allowClear />
                                            )
                                        }
                                    </O2OFormItem>
                                </Col>
                            </Row>
                        </O2OFormItem>
                    </Col>

                    <Col xs={24}>
                        <O2OFormItem className={'console--o2oFormItem__hideMessage'} label={'Status'}>
                            {form.getFieldDecorator("status", {
                                initialValue: get(selectedRecord, 'status') === "Active" ? true : false,
                                valuePropName: 'checked',
                                rules: [{
                                    required: true
                                }]
                            })(<Switch unCheckedChildren="Inactive" checkedChildren="Active" />)}
                        </O2OFormItem>
                    </Col>

                </Row>
            </Form>

            <DrawerSetTaskTimer
                defaultOrgGMT={"7"}
                visible={visibleTask}
                onClose={() => {
                    setVisibleTask(false)
                }}
                onSumit={(values: { days: string[], hours: string[], months: string[], minutes: string[], gmt: string, scheduleTime: [moment.Moment, moment.Moment] }) => {

                    const data: any = omit(values, ["scheduleTime"])
                    data["startTime"] = values.scheduleTime[0].toDate().getTime();
                    data["endTime"] = values.scheduleTime[1].toDate().getTime();
                    setTaskTimer(data)

                }}
                taskTimer={taskTimer}
            />


            <div
                style={{
                    position: 'absolute',
                    right: 0,
                    bottom: 0,
                    width: '100%',
                    borderTop: '1px solid #e9e9e9',
                    padding: '10px 16px',
                    background: '#fff',
                    textAlign: 'right',
                }}
            >
                <Button onClick={onClose} style={{ marginRight: 5 }}>
                    Cancel
                </Button>

                <Button loading={loading} onClick={onSave} type="primary">
                    {selectedRecord ? 'Update' : 'Add'}
                </Button>

            </div>
        </Drawer >
    )
}

export const DrawerFormAddEditCronjob = Form.create<IDrawerAddEditCronjobProps>()(DrawerAddEditCronjob)