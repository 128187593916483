import React from 'react';
import { Col, Row, Spin, Typography } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { CommonResponse } from '../../../../../redux/model';
import { SettingDAL } from '../../../../orgs/quota-setting/SettingDAL';
import { CompanyDAL } from '../../DAL';
import { IResponseHaveNotContent } from 'o2o_layout/lib/common/interface';
import { FormUpdatePersonalize } from './FormUpdatePersonalize';

interface Props {
    commonActionFromDALV2: any
}

export type TypeLocalizationItem = {
    id: string
    zipCode: string
    countryName: string
    countryCodeAlpha2: string
    countryCodeAlpha3: string
    countryPhoneCode: string
    requiredCountryPhoneCode: boolean
    gmt: number
    currency: string
    updatedAt: number
    updatedBy: string
}

export type TypeCountry = {
    "name": string,
    "code": string,
    "alpha2": string,
    "alpha3": string
}

export class Countries implements IResponseHaveNotContent<TypeCountry>{
    success: boolean
    results: TypeCountry[]
    constructor() {
        this.success = false
        this.results = []
    }
}

const Localization = ({ commonActionFromDALV2 }: Props) => {
    const [personalize, setPersonalize] = useState<TypeLocalizationItem>({} as TypeLocalizationItem)
    const [countries, setCountries] = useState<TypeCountry[]>([])
    const [loading, setLoading] = useState<boolean>(false)

    const loadConfigPersonalize = async () => {
        try {
            setLoading(true)
            const result = commonActionFromDALV2(CompanyDAL.getConfigPersonalize)
            const response: CommonResponse = await result
            if (response.success) {
                setPersonalize(response.result)
                setLoading(false)
            } else {
                setPersonalize({} as TypeLocalizationItem)
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            setPersonalize({} as TypeLocalizationItem)
        }
    }

    const loadCountries = async () => {
        try {
            const result = commonActionFromDALV2(SettingDAL.getCountries)
            const response = await result
            if (response.success) {
                setCountries(response.results)
            } else {
                setCountries([])
            }
        } catch (error) {
            setCountries([])
        }
    }

    useEffect(() => {
        loadConfigPersonalize()
        loadCountries()
    }, [])
    return (
        <Spin spinning={loading}>

            <Row gutter={[5, 5]} type='flex'>
                <Col xs={24}>
                    <Typography.Title level={4}>Localization</Typography.Title>
                    <Typography.Text type="secondary">
                        {personalize.updatedAt ? (
                            `Updated at ${moment(personalize.updatedAt).fromNow()} by ${personalize.updatedBy}`
                        ) : null}
                    </Typography.Text>
                </Col>
                <Col xs={24} lg={10} >
                    <FormUpdatePersonalize
                        commonActionDAL={commonActionFromDALV2}
                        personalize={personalize}
                        coutries={countries}
                        callback={() => {
                            setTimeout(() => {
                                loadConfigPersonalize()
                            }, 1000);
                        }}
                    />
                </Col>
            </Row>
        </Spin>
    )
}

export default Localization