import { RestClient } from "o2o_layout/lib/common/rest/RestClient";
import { BIGDATA_CONSTANT } from "../../../common/constant";
import { Methods } from "o2o_layout/lib/common/rest";
import { toQuery } from "../../../common/helper";
import _ from "lodash";

export type TypeCriteriaExportsHistory = {
    page: number;
    pageSize: number;
    freeText?: string;
}

class DAL {
    search(dto: TypeCriteriaExportsHistory) {
        const q = toQuery(_.omitBy({
            page: dto.page,
            pageSize: dto.pageSize,
        }, _.isNil),)
        let url = BIGDATA_CONSTANT.url.console + `orgs/logs/export/search/_by_user?${q}`;
        var restClient = new RestClient(Methods.POST, url);
        return new Promise((resolve, reject) => {
            restClient.execute({}, dto).then(
                (response: any) => {
                    const user: any = response.data;
                    resolve(user);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    }
    getLinkDowload(id: string) {
        let url = BIGDATA_CONSTANT.url.console + `orgs/logs/export/${id}/_by_user/detail`;
        var restClient = new RestClient(Methods.GET, url);
        return new Promise((resolve, reject) => {
            restClient.execute({}, {}).then(
                (response: any) => {
                    const user: any = response.data;
                    resolve(user);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    }
    distinct(field: string) {
        let url = BIGDATA_CONSTANT.url.console + `orgs/logs/export/${field}/distinct`;
        var restClient = new RestClient(Methods.GET, url);
        return new Promise((resolve, reject) => {
            restClient.execute({}, {}).then(
                (response: any) => {
                    const user: any = response.data;
                    resolve(user);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    }
}

export const ExportsHistoryDAL = new DAL()