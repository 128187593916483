import { Select } from 'antd'
import { WrappedFormUtils } from 'antd/lib/form/Form'
import _ from 'lodash'
import React, { useState } from 'react'
import { TypeZMALiveVersionItem } from '../model'
import { IResponseBigDataVN, TypeResultsBigData } from 'o2o_layout/lib/common/interface'
import { ZMALiveVersionDAL } from '../DAL'

interface ISuggestionZMAInfoProps {
    commonActionFromDALV2: any
    visible: boolean
    form: WrappedFormUtils<any>
    required: boolean
    keyField: string
    selectedRecord?: TypeZMALiveVersionItem
    initData?: string
}

export type TypeEventMappingFields = {
    eventName: string
    mappingFields: string[]
}

export type TypeMiniAppAvailableItem = {
    developedBy: string,
    actionRequired: string,
    appId: string
    appName: string
    description: string
    backgroundUrl: string
    category: string
    coverUrl: string
    eventMappingFields: TypeEventMappingFields[]
    id: string
    info: string
    publishedAt: number
    publishedUrl: string
    scope: "Public" | "Private"
    mediaUrls: string[]
}
export class MiniAppAvailable implements IResponseBigDataVN<TypeMiniAppAvailableItem>{
    versions: {
        [key: string]: {
            appId: string
            appName: string
            createdAt: number
            createdBy: string
            id: string
            status: string
            updatedAt: number
            updatedBy: string
            version: number
        }
    }
    success: boolean
    results: TypeResultsBigData<TypeMiniAppAvailableItem>
    constructor() {
        this.success = false
        this.results = {
            content: [],
            timeTaken: 0,
            currentTime: 0,
            page: {
                pageSize: 0,
                currentPage: 0,
                totalRecords: 0,
                totalPages: 0
            },
            extras: null
        }
        this.versions = {}
    }
}


const SuggestionZMAInfo = (props: ISuggestionZMAInfoProps) => {

    const [query, setQuery] = useState("")
    const [res, setRes] = useState(new MiniAppAvailable())
    const [loading, setLoading] = useState(false)


    React.useEffect(() => {
        if (props.visible) {
            load()
        }
    }, [query, props.visible])

    const onSearch = (e: string) => {
        setQuery(e)
    }

    const debounceOnSearch = React.useCallback(_.debounce(onSearch, 500), [])

    const load = async () => {
        setLoading(true)
        const payload = {
            page: 0,
            pageSize: 10,
            freeText: query
        }
        const result: Promise<any> = props.commonActionFromDALV2(ZMALiveVersionDAL.getAllZaloMiniAppAvailable, payload);
        try {
            const response = await result;
            if (response.success) {
                setLoading(false)
                setRes(response)
            } else {
                setLoading(false)
                setRes(new MiniAppAvailable())
            }
        } catch (error) {
            setLoading(false)
            setRes(new MiniAppAvailable())
        }
    }

    return (
        <>
            {props.form.getFieldDecorator(props.keyField, {
                initialValue: props.initData,
                rules: [{
                    required: props.required,
                    message: "Required"
                }]
            })(
                <Select
                    onSelect={() => {
                        onSearch("")
                    }}
                    onSearch={(e) => {
                        debounceOnSearch(e)
                    }}
                    filterOption={false}
                    loading={loading}
                    showArrow
                    showSearch
                    allowClear
                    placeholder="Select"
                >
                    {res.success && res.results && res.results.content.map((item: TypeMiniAppAvailableItem) => {
                        return <Select.Option value={`${item.appId}-${item.appName}`}>
                            {item.appName}
                        </Select.Option>
                    })}
                </Select>
            )}
        </>
    )
}

export default SuggestionZMAInfo