
import * as React from "react"
import { Button, Card, Form, Input, Popconfirm, Upload } from "antd"
import { FormComponentProps } from "antd/lib/form"

interface IProps extends FormComponentProps {
    title: any
    label: any
    isDeleteSupportConnection?: boolean
    isMoMField?: boolean
    isMoM?: boolean
    onSubmit: any
    labelButton?: string
}

export const FormImportSystemSystemSetting = Form.create<IProps>()(
    (props: IProps) => {
        const [loading, setLoading] = React.useState(false)
        return <Card headStyle={{ fontSize: 18 }} title={props.title} size="small">
            <Form layout="inline" colon={false} hideRequiredMark={true}>
                {props.isDeleteSupportConnection || props.isMoMField ? (
                    <React.Fragment>
                        {props.isDeleteSupportConnection ? (<Form.Item label={props.label}>
                            {props.form.getFieldDecorator("id", {
                                rules: [{
                                    required: true,
                                    message: "Please enter"
                                }]
                            })(
                                <Input placeholder="Enter" style={{ width: 400 }} />
                            )}
                        </Form.Item>) : null}
                        {
                            props.isMoMField ? (
                                <React.Fragment>

                                    <div style={{ marginBottom: 10, paddingLeft: 16 }}>
                                        <Form.Item label={"Object Model"}>
                                            {props.form.getFieldDecorator("objectModel", {
                                                rules: [{
                                                    required: true,
                                                    message: "Please enter"
                                                }]
                                            })(
                                                <Input placeholder="Enter" style={{ width: 400 }} />
                                            )}
                                        </Form.Item>
                                    </div>
                                    <Form.Item label={props.label}>
                                        {props.form.getFieldDecorator("file", {
                                            rules: [{
                                                required: true,
                                                message: "Please input file!"
                                            }]
                                        })(
                                            <Upload beforeUpload={() => {
                                                return false
                                            }} multiple={false}>
                                                <Button type="link" icon="upload">Select file (*.csv)</Button>
                                            </Upload>
                                        )}
                                    </Form.Item>
                                </React.Fragment>) : null
                        }
                    </React.Fragment>

                ) : (<Form.Item label={props.label}>
                    {props.form.getFieldDecorator("file", {
                        rules: [{
                            required: true,
                            message: "Please input file!"
                        }]
                    })(
                        <Upload beforeUpload={() => {
                            return false
                        }} multiple={false}>
                            <Button type="link" icon="upload">Select file {props.isMoM ? `(*.xlsx)` : null }</Button>
                        </Upload>
                    )}
                </Form.Item>)}


                <Form.Item>
                    <Popconfirm title="Are you sure?" onConfirm={() => {
                        props.form.validateFields((err, values) => {
                            if (!err) {
                                setLoading(true)
                                props.onSubmit(values, () => {
                                    setLoading(false)
                                })
                            }
                        })
                    }}>
                        <Button loading={loading} type="primary" >{props.labelButton ? props.labelButton : "Import"}</Button>
                    </Popconfirm>

                </Form.Item>
            </Form>
        </Card>
    }
)