import { Button, Card, Checkbox, Col, Divider, Form, Icon, Input, Layout, Menu, Modal, notification, Popover, Row, Select, Spin, Switch, Tooltip, Typography } from 'antd';
import { ClickParam } from "antd/lib/menu";
import _, { get } from 'lodash';
import { CommontDrawerWithForm, ICommPropsDrawerWithForm } from "o2o_layout";
import { TypeOrganization } from 'o2o_layout/lib/common/model';
import * as React from "react";
import ReactJson from 'react-json-view';
import { useAsyncRetry } from 'react-use';
import { IState } from '../../../redux/reducer';
import { IActionUI, SHOW_COMMON_ERROR } from '../../../redux/reducer/uiReducer';
import { useDispatch, useSelector } from '../../../redux/store';
import { CommonNotificationSaveError, CommonNotificationUpdateSuccessfully } from '../../../shares/CommonNotification';
import { O2OFormItem } from '../../../shares/O2OFormItem';
import { OrgsDAL } from '../OrgsDAL';
import { QuotaSettingComponent } from '../quota-setting/QuotaSettingComponent';
import { BIGDATA_CONSTANT } from '../../../common/constant';
import { getPrimaryColor } from '../../../common/helper';

interface IProps {
    onClose: any
    commonActionFromDAL: any
    company?: TypeOrganization
    commonActionFromDALV2: any
}

const getAttributeCompany = (field: string) => (com?: TypeOrganization) => com && com[field] ? com[field] : "--/--";

class WebtagingOrg {
    success: false
    result: {
        "internalUIdField"?: string,
        "phoneCountryCodeDefault"?: string,
        "trackingKey"?: string,
        "usePhoneCountryCode"?: boolean,
        "webTaggingObject"?: any
    }
    constructor() {
        this.success = false
        this.result = {
            "internalUIdField": undefined,
            "phoneCountryCodeDefault": undefined,
            "trackingKey": undefined,
            "usePhoneCountryCode": false,
            "webTaggingObject": undefined
        }
    }
}
class CronJobOrg {
    success: false
    result: {
        cronjobConfigs: any,
        enabledDDE: boolean

    }
    constructor() {
        this.success = false
        this.result = {
            cronjobConfigs: {},
            enabledDDE: false
        }
    }
}

class ModelStorage {
    success: false
    result: {
        enabledCustomModelStorage: boolean
        enabledJourneyEventStorage: boolean
        storageByTypes: any
    }
    constructor() {
        this.success = false
        this.result = {
            enabledCustomModelStorage: false,
            enabledJourneyEventStorage: false,
            storageByTypes: {}
        }
    }
}

export const DrawerSetupOrg = (props: IProps) => {

    const [selectedTab, setSelectedTab] = React.useState("tab3");
    const [loading, setLoading] = React.useState(false)
    const { user } = useSelector((state: IState) => state)

    const dispatch = useDispatch()

    React.useEffect(() => {
        if (!props.company) {
            setSelectedTab("tab3")
        }
    }, [props.company]);

    const loadWebTagging = async () => {
        setLoading(true)
        const result: Promise<WebtagingOrg> = props.commonActionFromDAL(OrgsDAL, "getWebtagging", getAttributeCompany("id")(props.company))
        try {
            const payload: WebtagingOrg = await result;
            setLoading(false)
            return payload
        } catch (error) {
            setLoading(false)
            return new WebtagingOrg()
        }
    }

    const loadModelStorage = async () => {
        setLoading(true)
        const result: Promise<ModelStorage> = props.commonActionFromDAL(OrgsDAL, "getModelStorage", getAttributeCompany("id")(props.company))
        try {
            const payload: ModelStorage = await result;
            setLoading(false)
            return payload
        } catch (error) {
            setLoading(false)
            return new ModelStorage()
        }
    }

    const loadCronjobOrg = async () => {
        setLoading(true)
        const result: Promise<CronJobOrg> = props.commonActionFromDAL(OrgsDAL, "getCronJob", getAttributeCompany("id")(props.company))
        try {
            const payload: CronJobOrg = await result;
            setLoading(false)
            return payload
        } catch (error) {
            setLoading(false)
            return new CronJobOrg()
        }
    }

    const loadAcceptedDomain = async () => {
        setLoading(true)
        const result: Promise<CronJobOrg> = props.commonActionFromDAL(OrgsDAL, 'getAcceptedDomain', get(props.company, 'id', ''))
        try {
            const payload: CronJobOrg = await result;
            setLoading(false)
            return payload
        } catch (error) {
            setLoading(false)
            return new CronJobOrg()
        }
    }

    const stateWebTagging = useAsyncRetry(async () => {
        if (props.company) {
            const result: WebtagingOrg = await loadWebTagging()
            return result
        }
        return new WebtagingOrg()
    }, [props.company])

    const stateCronJobOrg = useAsyncRetry(async () => {
        if (props.company) {
            const result: CronJobOrg = await loadCronjobOrg()
            return result
        }
        return new CronJobOrg()
    }, [props.company])

    const stateModelStorage = useAsyncRetry(async () => {
        if (props.company) {
            const result: ModelStorage = await loadModelStorage()
            return result
        }
        return new ModelStorage()
    }, [props.company])

    const stateAcceptedDomain = useAsyncRetry(async () => {
        if (props.company) {
            const result: CronJobOrg = await loadAcceptedDomain()
            return result
        }
        return new ModelStorage()
    }, [props.company])

    const webTagging = stateWebTagging.value ? stateWebTagging.value : new WebtagingOrg()
    const modelStorage = stateModelStorage.value ? stateModelStorage.value : new ModelStorage()
    const cronjob = stateCronJobOrg.value ? stateCronJobOrg.value : new CronJobOrg()

    const uiSetting = useSelector((state: IState) => state.uiSetting)

    const onInit = async (groupType: string) => {
        setLoading(true)
        const result = props.commonActionFromDAL(OrgsDAL, "initGroupPermission", get(props.company, "id"), { groupType: groupType })
        try {
            const response = await result;

            if (response.success) {
                notification.success({
                    message: BIGDATA_CONSTANT.message.default.title.success,
                    description: "Created successfully",
                    icon: <Icon type='check-circle' style={{ color: getPrimaryColor(uiSetting) }} />
                })
                setLoading(false)
            } else {
                setLoading(false)
                CommonNotificationSaveError(_.get(response, "message", "Something went wrong!"))
            }
        } catch (error) {
            setLoading(false)
            CommonNotificationSaveError(_.get(error, "data.message", "Something went wrong!"))
        }
    }


    const renderForm = (propsF: ICommPropsDrawerWithForm) => {
        if (selectedTab == "tab0") {
            return (renderFormWebTagging(propsF))
        } else if (selectedTab === "tab1") {
            return renderFormModelStorage(propsF)
        } else if (selectedTab === "tab2") {
            return <QuotaSettingComponent
                id={props.company ? props.company.id : undefined}
                commonActionFromDALV2={props.commonActionFromDALV2}
                company={props.company}
            />
        } else if (selectedTab === 'tab3') {
            return renderFormGeneral(propsF)
        } else if (selectedTab === "tab4") {
            return renderFormPermissionGroup()
        } else {
            return null
        }
    }


    const renderFormGeneral = (propsF: ICommPropsDrawerWithForm) => {
        return <Row type='flex' gutter={[5, 5]}>
            <Col xs={24}>
                <Typography.Title level={4}>
                    General Setting
                </Typography.Title>
            </Col>
            <Col xs={24}>
                <Card size='small'>
                    <O2OFormItem className='console--o2oFormItem__hideMessage' label="Accept Account Domain">
                        {propsF.form.getFieldDecorator("domains", {
                            rules: [{
                                required: true
                            }],
                            initialValue: get(stateAcceptedDomain, 'value.result.allowedDomains', [])
                        })(<Select showSearch showArrow placeholder="Enter" mode={"tags"} />)}
                    </O2OFormItem>

                    <O2OFormItem label={<span>
                        Invite Users to Join Organization <Tooltip title={'Turn on User Invitation to Join Organization'}>
                            <Icon type='question-circle' />
                        </Tooltip>
                    </span>}>
                        {propsF.form.getFieldDecorator("invitationUsersToJoin", {
                            valuePropName: 'checked',
                            initialValue: get(stateAcceptedDomain, 'value.result.invitationUsersToJoin', true)
                        })(<Switch checkedChildren="Enabled" unCheckedChildren="Disabled" />)}
                    </O2OFormItem>

                </Card>
            </Col>
        </Row>
    }

    const renderFormPermissionGroup = () => {
        return (
            <Spin spinning={loading}>
                <Row type='flex' gutter={[5, 5]}>
                    <Col xs={24}>
                        <Typography.Title level={4}>
                            Permission Group
                        </Typography.Title>
                    </Col>
                    <Col xs={24}>
                        <Card size='small'>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <Typography.Text >#1. Create groups following user's roles</Typography.Text>
                                <Divider type="vertical" />
                                <a target="_blank" href="https://storage.googleapis.com/pango-docs/public/pango-permission-by-roles.pdf">View Detail</a>
                            </div>
                            <Typography.Paragraph>
                                <ul>
                                    <li>Root</li>
                                    <li>Power User</li>
                                    <li>User</li>
                                    <li>Guess</li>
                                </ul>
                            </Typography.Paragraph>
                            <Divider type="horizontal" />
                            <Button type='primary' style={{ marginTop: -5 }} onClick={() => {
                                Modal.confirm({
                                    title: "You cannot delete these groups after creating them",
                                    content: "Are you sure to continue?",
                                    onOk: async () => await onInit("ByRole")
                                })
                            }}
                            >Create</Button>
                        </Card>
                    </Col>
                    <Col xs={24}>
                        <Card size='small'>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <Typography.Text >#2. Create groups by functionalities</Typography.Text>
                                <Divider type="vertical" />
                                <a target="_blank" href="https://storage.googleapis.com/pango-docs/public/pango-permission-by-functions.pdf">View Detail</a>
                            </div>
                            <Typography.Paragraph>
                                <ul>
                                    <li>[PangoACT] Grant Privileges</li>
                                    <li>[Pango] Module Management</li>
                                    <li>[Pango] ZMA Management</li>
                                    <li>[Pango] Account Management</li>
                                    <li>...</li>
                                </ul>
                            </Typography.Paragraph>
                            <Divider type="horizontal" />
                            <Button type='primary' style={{ marginTop: -5 }} onClick={() => {
                                Modal.confirm({
                                    title: "You cannot delete these groups after creating them",
                                    content: "Are you sure to continue?",
                                    onOk: async () => await onInit("ByFunction")
                                })
                            }}>Create</Button>
                        </Card>
                    </Col>
                </Row>
            </Spin>
        )

    }

    const renderFormWebTagging = (propsF: ICommPropsDrawerWithForm) => {

        return (
            <React.Fragment>
                <Row type="flex" gutter={[10, 10]}>
                    <Col xs={14}>
                        <Form.Item style={{ marginBottom: 0 }} label={<span>
                            Tracking Key <Tooltip title="Using Organization Id">
                                <Icon type='question-circle' />
                            </Tooltip>
                        </span>} colon={false}>
                            {propsF.form.getFieldDecorator("trackingKey", {
                                rules: [{
                                    required: true,
                                    message: "Required"
                                }],
                                initialValue: webTagging.result.trackingKey || get(props.company, "id"),
                            })(
                                <Input placeholder="Enter" />
                            )}
                        </Form.Item>
                    </Col>
                    <Col xs={14}>
                        <Form.Item style={{ marginBottom: 0 }} label="Internal UId Field" colon={false}>
                            {propsF.form.getFieldDecorator("internalUIdField", {
                                rules: [{
                                    required: false,
                                    message: "Required"
                                }],
                                initialValue: webTagging.result.internalUIdField
                            })(
                                <Select placeholder="Select one" allowClear>
                                    <Select.Option value="Email">Email</Select.Option>
                                    <Select.Option value="Phone">Phone</Select.Option>
                                    <Select.Option value="CustomerId">Customer Id</Select.Option>
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                    {propsF.form.getFieldValue("internalUIdField") == "Phone" ? (
                        <Col xs={14}>
                            <Form.Item style={{ marginBottom: 0 }} label="" colon={false}>
                                {propsF.form.getFieldDecorator("usePhoneCountryCode", {
                                    valuePropName: "checked",
                                    initialValue: webTagging.result.usePhoneCountryCode,
                                    rules: [{
                                        required: false,
                                        message: "Required"
                                    },
                                    ]
                                })(
                                    <Checkbox>Use country code as a prefix</Checkbox>
                                )}
                            </Form.Item>
                        </Col>
                    ) : null}
                    {propsF.form.getFieldValue("usePhoneCountryCode") == true ? (
                        <Col xs={14}>
                            <Form.Item style={{ marginBottom: 0 }} label="" colon={false}>
                                {propsF.form.getFieldDecorator("phoneCountryCodeDefault", {
                                    initialValue: webTagging.result.phoneCountryCodeDefault,
                                    rules: [{
                                        required: false,
                                        message: "Required"
                                    },
                                    ]
                                })(
                                    <Input placeholder="Enter" />
                                )}
                            </Form.Item>
                        </Col>
                    ) : null}
                </Row>

            </React.Fragment>
        )
    }
    const renderFormModelStorage = (propsF: ICommPropsDrawerWithForm) => {

        return (
            <React.Fragment>
                <Row type="flex" gutter={[10, 10]}>
                    <Col xs={24}>
                        <Form.Item style={{ marginBottom: 0 }} label="" colon={false}>
                            {propsF.form.getFieldDecorator("enabledCustomModelStorage", {
                                rules: [{
                                    required: false,
                                    message: "Required"
                                }],
                                initialValue: modelStorage.result.enabledCustomModelStorage,
                                valuePropName: "checked"
                            })(
                                <Switch />
                            )}
                            <span style={{ marginLeft: 10 }}>Enable Custom model storage
                                <Popover placement="bottomLeft" trigger="click" title="Detail" content={
                                    <ReactJson src={modelStorage.result.storageByTypes.hasOwnProperty("MOM_CustomModel") ? modelStorage.result.storageByTypes["MOM_CustomModel"] : {}} />
                                }>
                                    <Button type="link" icon="select" size="small"></Button>
                                </Popover>

                            </span>
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item style={{ marginBottom: 0 }} label="" colon={false}>
                            {propsF.form.getFieldDecorator("enabledJourneyEventStorage", {
                                rules: [{
                                    required: false,
                                    message: "Required"
                                }],
                                initialValue: modelStorage.result.enabledJourneyEventStorage,
                                valuePropName: "checked"
                            })(
                                <Switch />
                            )}
                            <span style={{ marginLeft: 10 }}>Enable Journey event storage
                                <Popover placement="bottomLeft" trigger="click" title="Detail" content={
                                    <ReactJson src={modelStorage.result.storageByTypes.hasOwnProperty("Event_Model") ? modelStorage.result.storageByTypes["Event_Model"] : {}} />
                                }>
                                    <Button type="link" icon="select" size="small"></Button>
                                </Popover>
                            </span>
                        </Form.Item>
                    </Col>
                    <Divider />
                    <Col xs={24}>
                        <Form.Item style={{ marginBottom: 0 }} label="Cronjob" colon={false}>
                            {propsF.form.getFieldDecorator("enabledDDE", {
                                rules: [{
                                    required: false,
                                    message: "Required"
                                }],
                                initialValue: cronjob.result.enabledDDE,
                                valuePropName: "checked"
                            })(
                                <Switch />
                            )}
                            <span style={{ marginLeft: 10 }}>Delta Data Engine
                            </span>
                        </Form.Item>
                    </Col>
                </Row>

            </React.Fragment>
        )
    }

    const submitForm = (propsF: ICommPropsDrawerWithForm) => {

        propsF.form.validateFieldsAndScroll(async (err: any, values: any) => {
            if (err) {
                return
            }
            setLoading(true)
            if (selectedTab == "tab0") {
                props.commonActionFromDAL(OrgsDAL, "saveWebtagging", getAttributeCompany("id")(props.company), values)
                    .then((e: any) => {
                        setLoading(false)
                        if (e.success) {
                            notification.success({
                                message: "Success",
                                description: "Update web tagging successfully."
                            })
                            setTimeout(() => {
                                stateWebTagging.retry();
                            }, 1000);
                        } else {
                            notification.error({
                                message: "Error",
                                description: _.get(e, "message", "Something was wrong.")
                            })
                        }
                    })
                    .catch((e: any) => {
                        setLoading(false)
                        notification.error({
                            message: "Error",
                            description: _.get(e, "message", "Something was wrong.")
                        })
                    })
            } else if (selectedTab === 'tab3') {
                setLoading(true)
                const payload = {
                    actionBy: user.id,
                    allowedDomains: values.domains,
                    invitationUsersToJoin: values.invitationUsersToJoin
                }
                const result = props.commonActionFromDALV2(OrgsDAL.saveAcceptedDomain, get(props.company, 'id'), payload)
                try {
                    const response = await result
                    if (response.success) {
                        setTimeout(() => {
                            stateAcceptedDomain.retry()
                        }, 1000);
                        CommonNotificationUpdateSuccessfully()
                    } else {
                        CommonNotificationSaveError(response.message)
                    }
                    setLoading(false)
                } catch (error) {
                    setLoading(false)
                    dispatch({
                        type: SHOW_COMMON_ERROR,
                        commonError: error
                    } as IActionUI)
                }
            } else {
                Promise.all([
                    props.commonActionFromDAL(OrgsDAL, "saveModelStorage", getAttributeCompany("id")(props.company), {
                        "enabledCustomModelStorage": values.enabledCustomModelStorage,
                        "enabledJourneyEventStorage": values.enabledJourneyEventStorage
                    }),
                    props.commonActionFromDAL(OrgsDAL, "saveCronjob", getAttributeCompany("id")(props.company), {
                        "enabledDDE": values.enabledDDE,
                    })
                ])
                    .then((e: any[]) => {
                        setLoading(false)
                        if (e[0].success && e[1].success) {
                            notification.success({
                                message: "Success",
                                description: "Save successfully."
                            })
                            setTimeout(() => {
                                stateCronJobOrg.retry();
                                stateModelStorage.retry()
                            }, 1000);
                        } else {
                            notification.error({
                                message: "Error",
                                description: _.get(e[0], "message", "Something was wrong.")
                            })
                        }
                    })
                    .catch((e: any) => {
                        setLoading(false)
                        notification.error({
                            message: "Error",
                            description: 'Something was wrong.'
                        })
                        setTimeout(() => {
                            stateCronJobOrg.retry();
                            stateModelStorage.retry()
                        }, 1000);
                    })
            }

        })

    }


    return (
        <CommontDrawerWithForm
            width={"85%"}
            title={
                <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
                    Setup: {getAttributeCompany("name")(props.company)}
                    <Typography.Text copyable={{ text: get(props.company, "id", "") }}>{`<${get(props.company, "id", "")}>`}</Typography.Text>
                </div>
            }
            onClose={props.onClose}
            maskClosable={false}
            keyboard={false}
            visible={props.company ? true : false}
            content={(propsF: ICommPropsDrawerWithForm) => {
                return (
                    <div style={{ marginBottom: 100 }}>
                        <Layout>
                            <Layout.Sider style={{ background: "#fff", marginRight: 12 }}>
                                <Menu style={{ height: "inherit" }} onSelect={(e: ClickParam) => {
                                    setSelectedTab(e.key)
                                }} selectedKeys={[selectedTab]}>
                                    <Menu.Item key="tab3">
                                        General
                                    </Menu.Item>
                                    <Menu.Item key="tab4">
                                        Permission Group
                                    </Menu.Item>
                                    <Menu.Item key="tab0">
                                        Web Tagging
                                    </Menu.Item>
                                    <Menu.Item key="tab1">
                                        Model Storage
                                    </Menu.Item>
                                    <Menu.Item key="tab2">
                                        Quota Setting
                                    </Menu.Item>
                                </Menu>
                            </Layout.Sider>

                            <Layout.Content style={{ overflow: "hidden" }}>
                                {renderForm(propsF)}
                            </Layout.Content>

                        </Layout>
                    </div>

                )
            }}
            contentButton={(propsF: ICommPropsDrawerWithForm) => {
                return (
                    <>
                        {selectedTab !== "tab4" && selectedTab !== "tab2" && (
                            <Button loading={loading} type="primary" onClick={() => {
                                submitForm(propsF)
                            }}>Save</Button>
                        )}
                    </>

                )
            }}
        />
    )
}