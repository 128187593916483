
import { Button, Col, DatePicker, Form, Icon, Input, Row, Select, Tooltip, notification } from "antd";
import _ from "lodash";
import moment from 'moment';
import { CommontDrawerWithForm, ICommPropsDrawerWithForm } from 'o2o_layout';
import * as React from "react";
import { BIGDATA_CONSTANT } from "../../../common/constant";
import { O2OFormItem } from "../../../shares/O2OFormItem";
import { InvationUserDAL } from './InvationUserDAL';

interface IProps {
    onSuccess: any
    commonActionFromDAL: any
    visible: boolean
    onClose: any
}


export const InvatationUserForm = (props: IProps) => {
    const [loading, setLoading] = React.useState(false)

    function disabledDate(current: any) {
        return current < moment().subtract('d', 1)
    }

    return (
        <CommontDrawerWithForm
            width={600}
            visible={props.visible}
            onClose={() => {
                props.onClose()
            }}
            title="Invite user to join Company"
            content={(propsF: ICommPropsDrawerWithForm) => {
                return (
                    <Row type="flex" gutter={[5, 5]} justify={"start"}>
                        <Col xs={24}>
                            <Form.Item
                                className="console--o2oFormItem__hideMessage"
                                label={<span>
                                    Pango User Email <Tooltip title="Only invite an user who is a Pango Account">
                                        <Icon type="question-circle" />
                                    </Tooltip>
                                </span>}
                                style={{ marginBottom: 0 }}
                                colon={false}>
                                {propsF.form.getFieldDecorator("userId", {
                                    rules: [{ required: true, message: "Please input your email!" }, {
                                        type: "email",
                                        message: "Please input a valid email address."
                                    }],
                                })(
                                    <Input placeholder="user@domain" />
                                )}
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item label="Roles" style={{ marginBottom: 0 }} colon={false}>
                                {propsF.form.getFieldDecorator("roles")(
                                    <Select mode="multiple" style={{ width: '100%' }} placeholder="Select(s)">
                                        <Select.Option key="ADMIN">Admin</Select.Option>
                                        <Select.Option key="API">Api</Select.Option>
                                        <Select.Option key="USER">User</Select.Option>
                                    </Select>
                                )}
                            </Form.Item>

                        </Col>

                        <Col xs={24}>
                            <O2OFormItem className="console--o2oFormItem__hideMessage" label={<span>
                                Expiration Time <Tooltip title="Users cannot login to the organization after this time.">
                                    <Icon type="question-circle" />
                                </Tooltip>
                            </span>} colon={false}>
                                {propsF.form.getFieldDecorator("joinedOrgExpiredAt", {

                                })(
                                    <DatePicker
                                        disabledDate={disabledDate}
                                        showTime style={{ width: '100%' }}
                                    />
                                )}
                            </O2OFormItem>

                        </Col>

                        <Col xs={24}>
                            <O2OFormItem className="console--o2oFormItem__hideMessage" label={<span>
                                Reason <Tooltip title="Describe the reason you invite the user to join your organiaztion.">
                                    <Icon type="question-circle" />
                                </Tooltip>
                            </span>} colon={false}>
                                {propsF.form.getFieldDecorator("reason", {
                                    rules: [{
                                        required: true
                                    }]
                                })(
                                    <Input placeholder="Enter" allowClear />
                                )}
                            </O2OFormItem>

                        </Col>
                    </Row>
                )
            }}
            contentButton={(propsF: ICommPropsDrawerWithForm) => {
                return <Button loading={loading} type="primary" onClick={() => {
                    propsF.form.validateFieldsAndScroll((err, values) => {
                        if (!err) {
                            setLoading(true)
                            values['expiredAt'] = 0
                            if (values.joinedOrgExpiredAt) {
                                values['joinedOrgExpiredAt'] = moment(values.joinedOrgExpiredAt).toDate().getTime()
                            }
                            props.commonActionFromDAL(InvationUserDAL, "adminAddUserToOrg", values)
                                .then((e: any) => {
                                    if (e.success) {
                                        setLoading(false)
                                        props.onClose()
                                        propsF.form.resetFields()
                                        props.onSuccess()
                                    } else {
                                        setLoading(false)
                                        notification.error({
                                            message: BIGDATA_CONSTANT.message.default.title.error,
                                            description: _.get(e, "message", "Something went wrong"),
                                        })
                                    }

                                })
                                .catch((e: any) => {
                                    setLoading(false)
                                    notification.error({
                                        message: BIGDATA_CONSTANT.message.default.title.error,
                                        description: _.get(e.data, "message", "Something went wrong"),
                                    })
                                })
                        }
                    })
                }}>Invite</Button>
            }}
        />
    )
}