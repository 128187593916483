
import { UI } from '../model'
import { LOADING_SHOW_SCREEN, LOADING_HIDE_SCREEN, SET_GLOBAL_MESSAGE, SHOW_SIDER_MAIN_LAYOUT, UPDATE_ERROR } from '../../common/actionType/actionType';
import logo from '../../static/icon/x_acc_logo.svg'
import logo_second from '../../static/icon/x_acc_logo.svg'
import { UserRefColumn } from 'o2o_layout';

export const UPDATE_BREAKPOINT = "UPDATE_BREAKPOINT"
export const UPDATE_FILTER_WEBLOG = "UPDATE_FILTER_WEBLOG"
export const UPDATE_COLUMNS = "UPDATE_COLUMNS"
export const UPDATE_META = "UPDATE_META"
export const SHOW_COMMON_ERROR = "SHOW_COMMON_ERROR"
export const OWNER_PERMISSION = "OWNER_PERMISSION"

const createEmptyUI = (): UI => ({
  isLoading: false,
  message: '',
  showSider: true,
  logo: logo,
  logoSecond: logo_second,
  breakpoint: '',
  innerHeight: window.innerHeight,
  filterWeblogFromDate: undefined,
  filterWeblogToDate: undefined,
  columns: undefined,
  meta: {
    title: undefined
  },
  commonError: undefined,
  ownerPermission: false
});

export interface IActionUI {
  type: string,
  isLoading?: boolean,
  message?: string,
  showSider?: boolean,
  errorStatus?: number,
  errorResponse?: any,
  breakpoint?: string,
  innerHeight?: number,
  filterWeblogFromDate?: number | undefined
  filterWeblogToDate?: number | undefined,
  columns: UserRefColumn | undefined,
  meta: {
    title: string
  }
  commonError?: any,
  ownerPermission?: boolean
};

export const uiReducer = (state = createEmptyUI(), action: IActionUI) => {
  switch (action.type) {
    case LOADING_SHOW_SCREEN:
      return { ...state, isLoading: true };
    case LOADING_HIDE_SCREEN:
      return { ...state, isLoading: false };
    case SHOW_SIDER_MAIN_LAYOUT:
      return { ...state, showSider: action.showSider }
    case SET_GLOBAL_MESSAGE:
      return { ...state, message: action.message };
    case UPDATE_ERROR:
      return { ...state, errorStatus: action.errorStatus, errorResponse: action.errorResponse }
    case UPDATE_FILTER_WEBLOG:
      return { ...state, filterWeblogFromDate: action.filterWeblogFromDate, filterWeblogToDate: action.filterWeblogToDate }
    case UPDATE_BREAKPOINT:
      return { ...state, breakpoint: action.breakpoint, innerHeight: action.innerHeight }
    case UPDATE_COLUMNS:
      return { ...state, columns: action.columns }
    case UPDATE_META:

      return {
        ...state, meta: {
          title: action.meta
        }
      }
    case SHOW_COMMON_ERROR:
      return { ...state, commonError: action.commonError }
    case OWNER_PERMISSION:
      return { ...state, ownerPermission: action.ownerPermission }
    default:
      return state;
  }
};