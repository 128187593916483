
import { Switch, Popconfirm, notification, Icon } from 'antd';
import _ from 'lodash';
import * as React from "react"
import { ConsoleUsersDAL } from '../ConsoleUserDAL';
import { BIGDATA_CONSTANT } from '../../../common/constant';
import { getPrimaryColor } from '../../../common/helper';
import { IState } from '../../../redux/reducer';
import { useSelector } from '../../../redux/store';

interface IProps {
    status: boolean,
    term: string | undefined
    commonActionFromDAL: any
    success: any
}

export const UpdateStatusTerm = (props: IProps) => {

    const [status, setStatus] = React.useState(undefined as boolean | undefined)
    const [loading, setLoading]   = React.useState(false)

    const uiSetting = useSelector((state: IState) => state.uiSetting)


    const active = async (active: boolean) => {
        try {
            const result: Promise<any> = await props.commonActionFromDAL(ConsoleUsersDAL, "saveTerm", {
                active: active,
                term: props.term || ""
            })
            const payload = await result
            return payload
        } catch (error) {
            return {success: false, message: "Something was wrong."}
        }
    }

    React.useEffect(() => {
        setStatus(props.status)
    }, [props.status]);
    return (

        <Popconfirm onCancel={() => {
            setStatus(props.status)
        }} onConfirm={async (e: any) => {
            setLoading(true)
            const result = await active(!status)
            setLoading(false)
            if(result && result.success){
                // notification.success({
                //     message: "Success",
                //     description: "Update successfully."
                // })

                notification.success({
                    message: BIGDATA_CONSTANT.message.default.title.success,
                    description: BIGDATA_CONSTANT.message.notification.success.update,
                    icon: <Icon type="check-circle" style={{ color: getPrimaryColor(uiSetting) }} />
                })
                setTimeout(() => {
                    props.success()
                }, 1000);
            }else{
                setLoading(false)
                notification.error({
                    message: BIGDATA_CONSTANT.message.default.title.error,
                    description: _.get(result, "message", "Something was wrong.")
                })
            }

        }} title={`Are your sure to want ${props.status ? "Inactive" : "Active"} term of current Company?`}>
            <Switch loading={loading} defaultChecked={status} checked={status} checkedChildren="Active" unCheckedChildren="Active" />
        </Popconfirm>

    )
}