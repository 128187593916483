import * as React from "react"
import 'react-quill/dist/quill.snow.css';
import { Button, Form, Row, Col, InputNumber, Divider, notification, Icon } from 'antd';
import { ConsoleUsersDAL } from "../ConsoleUserDAL";
import _ from "lodash";
import { FormComponentProps } from 'antd/lib/form';
import { useAsyncRetry } from 'react-use';
import { CommonResponse } from '../../../redux/model/commonResponse';
import { BIGDATA_CONSTANT } from "../../../common/constant";
import { getPrimaryColor } from "../../../common/helper";
import { useSelector } from "../../../redux/store";
import { IState } from "../../../redux/reducer";

interface IProps extends FormComponentProps {
    visible: boolean
    onClose: any
    commonActionFromDAL: any,
    success: any
}

export const FormPasswordPolicy = Form.create<IProps>()((props: IProps) => {
    const [loading, setLoading] = React.useState(false);
    const uiSetting = useSelector((state: IState) => state.uiSetting)

    React.useEffect(() => {

    }, [props.visible]);

    const load = async () => {
        setLoading(true)
        const result: Promise<CommonResponse> = await props.commonActionFromDAL(ConsoleUsersDAL, "getPassPolicy")
        try {
            const payload = await result
            setLoading(false)
            return payload
        } catch (error) {
            setLoading(false)
            return new CommonResponse()
        }
    }

    const stateGetPasswordPolicy = useAsyncRetry(async () => {
        if (props.visible) {
            const data: CommonResponse = await load();
            return data
        }
        return new CommonResponse()
    }, [props.visible])

    const dataPassword = stateGetPasswordPolicy.value && stateGetPasswordPolicy.value.success ? stateGetPasswordPolicy.value : new CommonResponse()
    const passwordLength = dataPassword.result && dataPassword.result.passwordLength ? dataPassword.result.passwordLength : undefined
    const passwordLengthSplit = passwordLength ? passwordLength.split("-") : undefined;
    const passwordLengthFrom = passwordLengthSplit ? Number(passwordLengthSplit[0]) : undefined
    const passwordLengthTo = passwordLengthSplit ? Number(passwordLengthSplit[1]) : undefined
    return (
        <Form layout="horizontal" colon={false} hideRequiredMark={true}>
            <Row type="flex" gutter={[10, 15]}>
                <Col xs={24}>
                    <Row type="flex" gutter={[3, 3]} justify="start">
                        <Col xs={24}>
                            <Form.Item style={{ marginBottom: 0 }} label="Password length"></Form.Item>
                        </Col>
                        <Col>
                            <Form.Item style={{ marginBottom: 0 }}>
                                {props.form.getFieldDecorator("passwordLengthFrom", {
                                    rules: [{ message: "Required", required: true }],
                                    initialValue: passwordLengthFrom ? passwordLengthFrom : 8
                                })(
                                    <InputNumber placeholder="Form" min={0} />
                                )}
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item style={{ marginBottom: 0 }}>
                                {props.form.getFieldDecorator("passwordLengthTo", {
                                    rules: [{ message: "Required", required: true }],
                                    initialValue: passwordLengthTo ? passwordLengthTo : 30
                                })(
                                    <InputNumber placeholder="To" min={0} />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>

                </Col>
                <Col xs={24}>
                    <Row type="flex" gutter={[3, 3]} justify="start" align={"middle"}>
                        <Col>
                            At least
                        </Col>
                        <Col>
                            <Form.Item style={{ marginBottom: 0 }}>
                                {props.form.getFieldDecorator("passwordMinDigit", {
                                    rules: [{ message: "Required", required: true }],
                                    initialValue: dataPassword.result ? dataPassword.result.passwordMinDigit : 1
                                })(
                                    <InputNumber placeholder="0" min={0} />
                                )}
                            </Form.Item>
                        </Col>
                        <Col>
                            number of digists
                        </Col>
                    </Row>

                </Col>
                <Col xs={24}>
                    <Row type="flex" gutter={[3, 3]} justify="start" align={"middle"}>
                        <Col>
                            At least
                        </Col>
                        <Col>
                            <Form.Item style={{ marginBottom: 0 }}>
                                {props.form.getFieldDecorator("passwordMinLetterUpperCase", {
                                    rules: [{ message: "Required", required: true }],
                                    initialValue: dataPassword.result ? dataPassword.result.passwordMinLetterUpperCase : 1
                                })(
                                    <InputNumber placeholder="0" min={0} />
                                )}
                            </Form.Item>
                        </Col>
                        <Col>
                            number of uppercase letters
                        </Col>
                    </Row>

                </Col>
                <Col xs={24}>
                    <Row type="flex" gutter={[3, 3]} justify="start" align={"middle"}>
                        <Col>
                            At least
                        </Col>
                        <Col>
                            <Form.Item style={{ marginBottom: 0 }}>
                                {props.form.getFieldDecorator("passwordMinLetterLowerCase", {
                                    rules: [{ message: "Required", required: true }],
                                    initialValue: dataPassword.result ? dataPassword.result.passwordMinLetterLowerCase : 1
                                })(
                                    <InputNumber placeholder="0" min={0} />
                                )}
                            </Form.Item>
                        </Col>
                        <Col>
                            number of lowercase letters
                        </Col>
                    </Row>

                </Col>
                <Col xs={24}>
                    <Row type="flex" gutter={[3, 3]} justify="start" align={"middle"}>
                        <Col>
                            At least
                        </Col>
                        <Col>
                            <Form.Item style={{ marginBottom: 0 }}>
                                {props.form.getFieldDecorator("passwordMinSpecialCharacter", {
                                    rules: [{ message: "Required", required: true }],
                                    initialValue: dataPassword.result ? dataPassword.result.passwordMinSpecialCharacter : 1
                                })(
                                    <InputNumber placeholder="0" min={0} />
                                )}
                            </Form.Item>
                        </Col>
                        <Col>
                            number of special letters
                        </Col>
                    </Row>

                </Col>
                <Divider style={{ margin: "10px 0" }} />
                <Col xs={24}>

                    <Form.Item style={{ marginBottom: 0 }} label="Max password age">
                        {props.form.getFieldDecorator("maxDaysRequiredPasswordChanged", {
                            rules: [{ message: "Required", required: true }],
                            initialValue: dataPassword.result ? dataPassword.result.maxDaysRequiredPasswordChanged : 180
                        })(
                            <InputNumber placeholder="0" min={0} />
                        )}
                        <span style={{ marginLeft: 10 }}>days</span>
                    </Form.Item>

                </Col>

                <Col xs={24}>
                    <Form.Item>
                        <Button loading={loading} type="primary" onClick={() => {
                            props.form.validateFieldsAndScroll((err: any, values: any) => {
                                if (err) {
                                    return
                                }
                                setLoading(true)
                                let data = {
                                    "maxDaysRequiredPasswordChanged": values.maxDaysRequiredPasswordChanged,
                                    "passwordLength": `${values.passwordLengthFrom}-${values.passwordLengthTo}`,
                                    "passwordMinDigit": values.passwordMinDigit,
                                    "passwordMinLetterLowerCase": values.passwordMinLetterLowerCase,
                                    "passwordMinLetterUpperCase": values.passwordMinLetterUpperCase,
                                    "passwordMinSpecialCharacter": values.passwordMinSpecialCharacter
                                }
                                props.commonActionFromDAL(ConsoleUsersDAL, "savePassPolicy", data)
                                    .then((result: any) => {
                                        setLoading(false)
                                        if (result && result.success) {
                                            // notification.success({
                                            //     message: "Success",
                                            //     description: "Update successfully."
                                            // })
                                            notification.success({
                                                message: BIGDATA_CONSTANT.message.default.title.success,
                                               description: BIGDATA_CONSTANT.message.notification.success.update,
                                                icon: <Icon type="check-circle" style={{ color: getPrimaryColor(uiSetting) }} />
                                            })
                                            setTimeout(() => {
                                                props.onClose()
                                                props.success()
                                                stateGetPasswordPolicy.retry()
                                            }, 1000);
                                        } else {
                                            notification.error({
                                                message: "Error",
                                                description: _.get(result, "message", "Something was wrong.")
                                            })
                                        }
                                    })
                            })

                        }}>Save</Button>
                    </Form.Item>
                </Col>
            </Row>



        </Form>

    )
})