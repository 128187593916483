import { Organization, OrganizationsNotContent } from "o2o_layout/lib/common/model/Organizations";

export const GET_CURRENT_ORGS = "GET_CURRENT_ORGS"
export type typeCurrentUserOrgs = {
    current: Organization,
    orgs: OrganizationsNotContent

}
const empty = (): typeCurrentUserOrgs => {
  return {
      current: new Organization(),
      orgs: new OrganizationsNotContent()
  };
}


export interface IActionCurrentUserOrgs {
    type: string,
    payload: typeCurrentUserOrgs
}


export const currentUserOrgsReducer = (state = empty(), action: IActionCurrentUserOrgs) => {
  switch (action.type) {
    case GET_CURRENT_ORGS:
    // return {...state, ...{orgs: {
    //     success: true,
    //     results: []
    // }}}
      return {...state, ...{orgs: action.payload}}
    default:
      return state;
  }
};