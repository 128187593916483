import * as React from "react"
import { Row, Col, Button, Typography } from "antd"
import { FormattedNumber } from "react-intl"
import { IResponseBigDataVN } from '../common/IResponseBigData';
interface Props {
    list: IResponseBigDataVN<any>,
    refresh: any,
    right?: React.ReactNode,
    actions?: React.ReactNode
}
export const InfoList = (props: Props) => {
    return (
        <Row gutter={[16, 16]} style={{ marginTop: 0 }} type="flex" justify="space-between" align="middle">
            <Col>
                <Button type="primary" size="small" icon="sync" shape="circle" onClick={props.refresh}></Button>
                <Typography.Text style={{ marginLeft: 10 }}><a> <strong style={{ marginLeft: 0 }}>Found <FormattedNumber value={props.list.results.page.totalRecords} /> record(s)</strong></a>
                    <Typography.Text type="secondary" style={{ marginLeft: 3 }}>about <FormattedNumber value={props.list.results.timeTaken} /> ms</Typography.Text>
                </Typography.Text>
                {props.actions ? (props.actions) : null}
            </Col>
            {props.right ? (
                <Col>
                    {props.right}
                </Col>
            ) : (null)}

        </Row>
    )
}