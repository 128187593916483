import { PermissionGroups, PermissionGroupsContext, AllPermissionGroups, Permissions } from '../model/permissions';
export const GET_PERMISSION = "GET_PERMISSION";
export const GET_PERMISSION_GROUP = "GET_PERMISSION_GROUP";
export const GET_ALL_PERMISSION_GROUP = "GET_ALL_PERMISSION_GROUP";
export const GET_PERMISSION_GROUP_CONTEXT = "GET_PERMISSION_GROUP_CONTEXT";

const empty = (): Permissions => {
    return new Permissions;
}

export interface IActionPermission {
    type: string,
    payload: Permissions
}

export const permissionsReducer = (state = empty(), action: IActionPermission) => {
    switch (action.type) {
        case GET_PERMISSION: 
            return {...state, ...action.payload}
        default:
            return state;
    }
};

const emptyPermissionGroup = (): PermissionGroups => {
    return new PermissionGroups;
}

export interface IActionPermissionGroup {
    type: string,
    payload: PermissionGroups
}

export const permissionGroupsReducer = (state = emptyPermissionGroup(), action: IActionPermissionGroup) => {
    switch (action.type) {
        case GET_PERMISSION_GROUP: 
            return {...state, ...action.payload}
        default:
            return state;
    }
};



const emptyPermissionGroupContext = (): PermissionGroupsContext => {
    return new PermissionGroupsContext;
}

export interface IActionPermissionGroupContext {
    type: string,
    payload: PermissionGroupsContext
}

export const permissionGroupsContextReducer = (state = emptyPermissionGroupContext(), action: IActionPermissionGroupContext) => {
    switch (action.type) {
        case GET_PERMISSION_GROUP_CONTEXT: 
            return {...state, ...action.payload}
        default:
            return state;
    }
};

const emptyAllPermissionGroup = (): AllPermissionGroups => {
    return new AllPermissionGroups();
}

export interface IActionAllPermissionGroup {
    type: string,
    payload: AllPermissionGroups
}

export const allPermissionGroupsReducer = (state = emptyAllPermissionGroup(), action: IActionAllPermissionGroup)=>{
    switch (action.type) {
        case GET_ALL_PERMISSION_GROUP: 
            return {...state, ...action.payload}
        default:
            return state;
    }
}
