import { bindActionCreators } from 'redux';
import { getMe } from '../../../common/actions/CommonAction';
import { O2OCommonContainer } from '../../../common/container/O2OContainer';
import { IState } from '../../../redux/reducer/index';
import { updateOrgByAdminClient } from '../../orgs/OrgsAction';
import { updateAccount, updateEDMSendInBlue } from '../ConsoleUsersAction';
import { searchPermissionGroupContext } from '../permisions/action/PermissionAction';
import { UserInfoComponent } from './UserInfoComponent';

function mapStateToProps(rootState: IState) {
    return {  
        edmSendInBlue: rootState.edmSendInBlue,
        permissionGroups: rootState.permissionGroupsContext,
        permission: rootState.authen
    };
}


function mapDispatchToProps(dispatch: any) {
    return {
        updateAccount: bindActionCreators(updateAccount, dispatch),
        updateOrgByAdminClient: bindActionCreators(updateOrgByAdminClient, dispatch),
        updateEDMSendInBlue: bindActionCreators(updateEDMSendInBlue, dispatch),
        getMe: bindActionCreators(getMe, dispatch),
        searchPermissionGroup: bindActionCreators(searchPermissionGroupContext, dispatch),
    };
}

export const UserInfoContainer = O2OCommonContainer(UserInfoComponent, mapStateToProps, mapDispatchToProps)