import React from 'react';
import { Location } from 'history';
import queryString from 'query-string';
import { Link, LinkProps } from 'react-router-dom';

interface props extends LinkProps{
   location: Location,
   children: React.ReactNode | string | any
}

export class LinkO2O extends React.Component<props, any>{
    
    render(){
        
        const { to, location, children } = this.props;
        const qString = location.search;
        let parseQuery = queryString.parse(qString)
        let lang = parseQuery.lang;
        
        if(to.search){
            let toParse  = queryString.parse(to.search.toString());
            parseQuery = Object.assign(parseQuery, toParse)
        }else{
            parseQuery = Object.assign(parseQuery, { lang: lang })
        }
        const toOverride = Object.assign(to, {
            search: queryString.stringify(parseQuery)
        })
        
        return (
            <Link to={toOverride}>
                { children }
            </Link>
        )
    }
 
}

