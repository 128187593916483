import { WrappedFormUtils } from 'antd/lib/form/Form'
import React from 'react'
import { ListAccounts, TypeAccount } from '../model/permissions'
import { PermissionDAL } from '../dal/PermissionDAL'
import _, { get } from 'lodash'
import { Icon, Select, Spin } from 'antd'
import { O2OFormItem } from '../../../../shares/O2OFormItem'

interface IProps {
    commonActionFromDALV2: any
    form: WrappedFormUtils
    keyField: string
    onSelect: (e: any) => void
    label?: any
    required?: boolean
    initialValue?: string
    placeholder?: string
    mode?: "default" | "multiple" | "tags" | undefined
    visible: boolean
    userIds?: string[]
    maxTagTextLength?: number
}
const SuggestionAccount = (props: IProps) => {
    const [query, setQuery] = React.useState("")
    const [res, setRes] = React.useState(new ListAccounts())
    const [loading, setLoading] = React.useState(false as boolean)

    const load = async () => {
        setLoading(true)
        const result: Promise<ListAccounts> = props.commonActionFromDALV2(PermissionDAL.getAccounts, query);
        try {
            const payload: ListAccounts = await result;
            if (payload.success) {
                setLoading(false)
                setRes(payload)
                return payload
            } else {
                setLoading(false)
                return new ListAccounts()
            }
        } catch (error) {
            setLoading(false)
            return new ListAccounts()
        }
    }

    const onSearch = (e: string) => {
        setQuery(e)
    }

    const debounceSearch = React.useCallback(_.debounce(onSearch, 500), [])

    React.useEffect(() => {
        if (props.visible) {
            load();
        }
    }, [query, props.visible])

    const checkSelected = (id: string) => {
        if (id) {
            const findItemByID = _.find(props.userIds || [], (item: string) => item === id)
            if (findItemByID) {
                return <Icon type="check" />
            }
            return null
        }
        return null
    }
    return (
        <Spin spinning={loading}>
            <O2OFormItem label={props.label}>
                {props.form.getFieldDecorator(props.keyField, {
                    initialValue: props.initialValue,
                    rules: [
                        {
                            required: props.required ? props.required : false,
                            message: 'Required'
                        }
                    ],
                })(<Select
                    maxTagCount={1}
                    maxTagTextLength={get(props, "maxTagTextLength")}
                    filterOption={false}
                    mode={props.mode}
                    placeholder={props.placeholder}
                    loading={loading}
                    showSearch={true}
                    showArrow={true}
                    onSearch={(e: string) => {
                        debounceSearch(e)
                    }}
                    onSelect={(e: string) => {
                        props.onSelect(e)
                        onSearch("")
                    }}
                    allowClear
                >
                    {(res && res.success && res.results.content && res.results.content.map((item: TypeAccount) => {
                        return (
                            <Select.Option key={item.id} value={item.id}>{item.name} {`<${item.id}>`} {checkSelected(item.id)}</Select.Option>
                        )
                    }))}
                </Select>)}
            </O2OFormItem>
        </Spin>
    )
}

export default SuggestionAccount