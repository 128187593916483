import { Button, Col, Divider, Drawer, Form, Input, notification, Popconfirm, Row, Spin, Typography } from 'antd'
import React from 'react'
import { O2OFormItem } from '../O2OFormItem'
import { FormComponentProps } from 'antd/lib/form'
import TextArea from 'antd/lib/input/TextArea'
import { CommonDAL } from '../dal/CommonDAL'
import { BIGDATA_CONSTANT } from '../../common/constant'
import { Methods } from 'o2o_layout/lib/common/rest'
import { useAsyncRetry } from 'react-use'
import _ from 'lodash'
import { CommonNotificationSaveError } from '../CommonNotification'
import { ManageTicketDAL } from './dal/syncTicketDAL'
import SuggestTopic from './SuggestTopic'
import DrawerViewTicket from './DrawerViewTicket'

interface IFormSPProps extends FormComponentProps {
    commonActionFromDALV2: any
    visible: boolean
    onClose: () => void
}

const FormSupport = (props: IFormSPProps) => {
    const [loading, setLoading] = React.useState<boolean>(false)
    const [visibleViewTicket, setVisibleViewTicket] = React.useState<boolean>(false)
    const [visibilePopConfirm, setVisiblePopconfirm] = React.useState<boolean>(false)
    const { getFieldDecorator } = props.form

    const loadInfoUser = async () => {
        const data = await props.commonActionFromDALV2(CommonDAL.buildURL, `${BIGDATA_CONSTANT.url.api}accounts/users/current`, {}, Methods.GET)
        const result = await data
        return result
    }

    const stateInfoUser = useAsyncRetry(async () => {
        if (props.visible) {
            const data = await loadInfoUser()
            return data;
        }
        return null
    }, [props.visible])

    const email = _.get(stateInfoUser.value, 'result.id', '--/--')
    const organization = _.get(stateInfoUser.value, 'result.orgCtx.organization.code', '--/--')

    React.useEffect(() => {
        if (!props.visible) {
            props.form.resetFields()
            setVisiblePopconfirm(false)
        }
    }, [props.visible])

    React.useEffect(() => {
        if (visibleViewTicket) {
            setVisiblePopconfirm(false)
        }
    }, [visibleViewTicket])


    const onSubmit = () => {
        setLoading(true)
        props.form.validateFields(async (err: any, values: any) => {
            if (err) {
                setLoading(false)
                return
            }
            const currentUrl = window.location.href

            const dto = {
                ticketSubject: values.ticketSubject,
                topic: values.topic,
                description: values.description,
                utmSource: currentUrl,
            }

            const result = await props.commonActionFromDALV2(ManageTicketDAL.generatePangoTicket, dto)
            const respone = await result
            try {
                if (respone.success) {
                    setLoading(false)
                    props.onClose()
                    notification.success({
                        message: 'Success',
                        description: "Your request has been sent successfully"
                    })
                } else {
                    setLoading(false)
                    CommonNotificationSaveError(_.get(respone, "message", "Something went wrong!"))
                }
            } catch (error) {
                setLoading(false)
                CommonNotificationSaveError('Somethings went wrong!')
            }
        })
    }

    return (
        <Drawer
            title={<>
                <Button onClick={() => {
                    props.onClose()
                }} type='link' size='small' icon='left-circle'
                />
                SEND SUPPORT REQUEST TO OUR TEAM
            </>}
            width={600}
            visible={props.visible}
            onClose={() => {
                props.onClose()
            }}
            maskClosable={false}
        >
            <Form hideRequiredMark={true} colon={false} layout='vertical'>
                <Row type='flex' gutter={[10, 10]}>
                    <Col xs={24} style={{ display: 'flex', alignItems: 'center' }}>
                        <Spin spinning={stateInfoUser.loading} >
                            <Typography style={{ marginRight: 4 }}>
                                Your email: {email} {`<${organization}>`}
                            </Typography>
                        </Spin>

                        <Divider type='vertical' />

                        <Button style={{ paddingLeft: 3 }} type='link' size='small' onClick={() => { setVisibleViewTicket(true) }}>
                            Your Tickets
                        </Button>
                    </Col>

                    <Col xs={24}>
                        <Divider style={{ margin: '2px 0 5px 0' }} type='horizontal' />
                    </Col>

                    <Col xs={24}>
                        <SuggestTopic
                            visible={props.visible}
                            form={props.form}
                            lable={<>
                                Select a top relates to your question, or you can search our
                                <Button style={{ paddingLeft: 3 }} type='link' size='small' onClick={() => {
                                    const url = 'https://help.pangocdp.com/'
                                    window.open(url, '_blank')
                                }}>
                                    product document
                                </Button>
                            </>}
                            keyField={'topic'}
                            required={true}
                            initialValue={undefined}
                            placeholder={'Select one'}
                            commonActionFromDALV2={props.commonActionFromDALV2}
                        />
                    </Col>

                    {props.form.getFieldValue('topic') ? (
                        <>
                            <Col xs={24}>
                                <O2OFormItem help=''>
                                    {getFieldDecorator('ticketSubject', {
                                        rules: [{
                                            required: true,
                                        }],
                                        initialValue: undefined
                                    })(
                                        <Input allowClear placeholder='Subject' />
                                    )}
                                </O2OFormItem>
                            </Col>

                            <Col xs={24}>
                                <O2OFormItem help=''>
                                    {getFieldDecorator('description', {
                                        rules: [{
                                            required: true
                                        }],
                                        initialValue: undefined
                                    })(
                                        <TextArea placeholder='Describe the problem in detail' rows={12} maxLength={1000} />
                                    )}
                                </O2OFormItem>
                            </Col>
                        </>
                    ) : null}

                    <DrawerViewTicket
                        commonActionFromDALV2={props.commonActionFromDALV2}
                        visible={visibleViewTicket}
                        onClose={() => {
                            setVisibleViewTicket(false)
                        }}
                    />
                </Row>

                <div
                    style={{
                        zIndex: 999999,
                        position: "absolute",
                        right: 0,
                        bottom: 0,
                        width: "100%",
                        borderTop: "1px solid #e9e9e9",
                        padding: "10px 16px",
                        background: "#fff",
                        textAlign: "right",
                    }}>
                    <Button style={{ marginRight: "8px" }} onClick={props.onClose}>
                        Discard
                    </Button>

                    <Popconfirm
                        visible={visibilePopConfirm}
                        title={<>Your request will be responded to by Pango Team<br />as soon as possible.</>}
                        placement="leftTop"
                        onConfirm={onSubmit}
                        onCancel={() => { setVisiblePopconfirm(false) }}
                        okText='Continue'
                    >
                        <Button type="primary" loading={loading} onClick={() => {
                            props.form.validateFields(async (err: any, values: any) => {
                                if (err) return

                                setVisiblePopconfirm(true)
                            })
                        }}>
                            Submit Ticket
                        </Button>
                    </Popconfirm>
                </div>
            </Form>
        </Drawer>
    )
}

export const DrawerSupport = Form.create<IFormSPProps>()(FormSupport)
