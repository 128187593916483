import { Button, Col, Divider, Drawer, Empty, Form, Row, Spin, Timeline, Tooltip, Typography } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import { get, isArray, isEmpty } from 'lodash'
import moment from "moment"
import { O2OCustomPaging } from 'o2o_layout'
import { TypeConsoleUser } from 'o2o_layout/lib/common/model'
import React, { useEffect, useState } from 'react'
import { useAsyncRetry } from 'react-use'
import { ICommonPayload } from '../../common/CommonTypeProps'
import ButtonBackDrawer from '../../shares/ButtonBackDrawer'
import { DistinctByField } from '../../shares/DistinctByFiled'
import DrawerViewJSON from '../../shares/DrawerViewJSON'
import { AccountLogsDAL } from './activity/account-logs/DAL'
import { AccountLogs, TypeAccountLogs } from './activity/account-logs/model'

interface IDrawerActionsLogProps extends FormComponentProps {
    commonActionFromDALV2: any
    onClose: () => void
    visible: boolean
    selectedRecord?: TypeConsoleUser
    distinctByField: any
    isSpAdmin?: boolean
}

const TYPE_DISPATCH_CRITERIA = "TYPE_DISPATCH_CRITERIA"

const initCriteria: ICommonPayload<any> = {
    loading: false,
    type: TYPE_DISPATCH_CRITERIA,
    payload: {} as any,
}

const reducerCriteria = (state: ICommonPayload<any>, action: ICommonPayload<any>) => {
    switch (action.type) {
        case TYPE_DISPATCH_CRITERIA:
            return { ...state, ...action }
        default:
            throw new Error()
    }
}

export const DrawerActionsLog = Form.create<IDrawerActionsLogProps>()(
    ({ commonActionFromDALV2, onClose, visible, selectedRecord, form, distinctByField, isSpAdmin }: IDrawerActionsLogProps) => {
        const [criteria, dispatchCriteria] = React.useReducer(reducerCriteria, initCriteria)

        const [visibleViewJSON, setVisibleViewJSON] = useState(false)
        const [dataJSON, setDataJSON] = useState(undefined as any)
        const load = async () => {
            const result = commonActionFromDALV2(AccountLogsDAL.search, criteria.payload, isSpAdmin)
            try {
                const response: AccountLogs = await result
                if (response.success) {
                    return response
                } else {
                    return new AccountLogs()
                }
            } catch (error) {
                return new AccountLogs()
            }
        }

        useEffect(() => {
            if (visible) {
                dispatchCriteria({
                    loading: false,
                    type: TYPE_DISPATCH_CRITERIA,
                    payload: { ...criteria.payload, ...{ page: 0, pageSize: 10, userIds: [get(selectedRecord, "id", '')] } }
                })
            } else {
                dispatchCriteria({
                    loading: false,
                    type: TYPE_DISPATCH_CRITERIA,
                    payload: {}
                })
                form.resetFields()
            }
        }, [visible])


        const stateAccountLogs = useAsyncRetry(async () => {
            if (!isEmpty(criteria.payload)) {
                const data = await load()
                return data
            }
            return new AccountLogs()
        }, [criteria])

        const accountLogs: AccountLogs = stateAccountLogs.value || new AccountLogs()

        const actions = form.getFieldValue("actions")

        useEffect(() => {
            if (isArray(actions)) {
                dispatchCriteria({
                    loading: false,
                    type: TYPE_DISPATCH_CRITERIA,
                    payload: { ...criteria.payload, ...{ actions: actions } }
                })
            }
        }, [actions])


        return (
            <Drawer
                bodyStyle={{ marginBottom: 100 }}
                title={<span>
                    <ButtonBackDrawer onClick={onClose} />
                    Account Logs
                </span>}
                visible={visible}
                onClose={() => {
                    onClose()
                }}
                width={500}
            >
                <Spin spinning={stateAccountLogs.loading}>

                    <Typography.Title level={4}>
                        User:  <b>{get(selectedRecord, "id", '--/--')}</b>
                    </Typography.Title>

                    <Form hideRequiredMark colon={false}>
                        <Row type='flex' gutter={[10, 10]}>

                            <Col xs={24}>
                                <DistinctByField
                                    maxTagTextLength={50}
                                    api={distinctByField}
                                    domain={'account-logs'}
                                    getFieldDecorator={form.getFieldDecorator}
                                    field={'action'}
                                    placeholder={'Select(s)'}
                                    formLabel={<span>
                                        Actions
                                    </span>}
                                    formKey={'actions'}
                                />
                            </Col>

                            <Divider />

                            {
                                isEmpty(accountLogs.results.content) ?
                                    <Col xs={24}>
                                        <div style={{ textAlign: "center" }}>
                                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                        </div>
                                    </Col>
                                    : null
                            }
                            {
                                !isEmpty(accountLogs.results.content) ?
                                    <Col xs={24}>
                                        <Timeline>
                                            {
                                                accountLogs.results.content.map((log: TypeAccountLogs) => {
                                                    return <Timeline.Item style={{ padding: 0 }}>
                                                        <p>
                                                            <Button size='small' type='link' onClick={() => {
                                                                setDataJSON(log.ctxObject)
                                                                setVisibleViewJSON(true)
                                                            }}>{log.action} {
                                                                    isSpAdmin && <>
                                                                        <Divider type='vertical' />
                                                                        {get(accountLogs.orgs, `${log.orgId}.name`)}
                                                                    </>
                                                                }</Button>
                                                        </p>
                                                        <p>
                                                            <Tooltip title={moment(log.actionAt).format("LLLL")}>
                                                                {moment(log.actionAt).fromNow()}
                                                            </Tooltip> by {log.actionBy}
                                                        </p>
                                                    </Timeline.Item>
                                                })
                                            }
                                        </Timeline>
                                        <O2OCustomPaging
                                            isSmallSimple
                                            list={accountLogs}
                                            onChange={(e: any) => {
                                                dispatchCriteria({
                                                    loading: false,
                                                    type: TYPE_DISPATCH_CRITERIA,
                                                    payload: { ...criteria.payload, ...{ page: e - 1 } } as any,
                                                })
                                            }}
                                            onChangePageSize={(e: any) => {
                                                dispatchCriteria({
                                                    loading: false,
                                                    type: TYPE_DISPATCH_CRITERIA,
                                                    payload: { ...criteria.payload, ...{ page: 0, pageSize: e } } as any,
                                                })
                                            }}
                                        />
                                    </Col>

                                    : null
                            }

                        </Row>
                    </Form>


                </Spin>

                <div
                    style={{
                        position: 'absolute',
                        right: 0,
                        bottom: 0,
                        width: '100%',
                        borderTop: '1px solid #e9e9e9',
                        padding: '10px 16px',
                        background: '#fff',
                        textAlign: 'right',
                    }}
                >
                    <Button onClick={onClose} >
                        Close
                    </Button>


                    <DrawerViewJSON
                        data={dataJSON}
                        visible={visibleViewJSON}
                        onClose={() => {
                            setVisibleViewJSON(false)
                            setDataJSON(undefined)
                        }}

                    />


                </div>

            </Drawer>
        )
    }
)




