import { Response } from '../../../../libs/api/Response';
import { LinkOrg, LinkOrgs, LinkOrgsNoContent } from '../model/linkOrgs';
import { LinkOrgsDAL, typeCriteriaSearchLinkOrgs } from '../dal/LinkOrgsDal';
import { CommonResponse } from '../../../../redux/model/commonResponse';
import { UPDATE_COMMON_RESPONSE, IActionCommonResponse } from '../../../../redux/reducer/commonResponseReducer';
import { UPDATE_ERROR } from '../../../../common/actionType/actionType';
import { IActionUI } from '../../../../redux/reducer/uiReducer';
import { GET_LINK_ORGS, IActionLinkOrgs, GET_LINK_ORGS_NO_CONTENT, IActionLinkOrgsNoContent } from '../linkOrgReducer';

export type typeLinkOrg = (criterial: LinkOrg) => any
export function linkOrg<typeLinkOrg>(criterial: LinkOrg) {
    return (dispatch: any) => {


        return new Promise((resolve, reject) => {

            LinkOrgsDAL.create(criterial)
                .then(orgs => {
                    dispatch({
                        type: UPDATE_COMMON_RESPONSE,
                        payload: orgs as CommonResponse
                    } as IActionCommonResponse)
                    
                    resolve(orgs);
                }, (error: Response) => {
                    if (error.status == 401 || error.status == 403) {
                        dispatch({
                            type: UPDATE_ERROR,
                            errorStatus: error.status,
                            errorResponse: error.data
                        } as IActionUI)
                    }
                    reject(error);
                });
        });

    }
}

export type typeSearchLinkOrgs = (criteria: typeCriteriaSearchLinkOrgs) => any
export function searchLinkOrgs<typeSearchLinkOrgs>(criteria: typeCriteriaSearchLinkOrgs) {
    return (dispatch: any) => {


        return new Promise((resolve, reject) => {

            LinkOrgsDAL.list(criteria)
                .then((orgs: LinkOrgs) => {
                    
                    dispatch({
                        type: GET_LINK_ORGS,
                        payload: orgs
                    } as IActionLinkOrgs)
                    
                    resolve(orgs);
                }, (error: Response) => {
                    if (error.status == 401 || error.status == 403) {
                        dispatch({
                            type: UPDATE_ERROR,
                            errorStatus: error.status,
                            errorResponse: error.data
                        } as IActionUI)
                    }
                    reject(error);
                });
        });

    }
}

export type typeSearchTestLog = () => any
export function searchTestLog<typeSearchTestLog>() {
    return (dispatch: any) => {


        return new Promise((resolve, reject) => {

            LinkOrgsDAL.testSearchLogs()
                .then((orgs: any) => {
                    
                    dispatch({
                        type: UPDATE_COMMON_RESPONSE,
                        payload: orgs as CommonResponse
                    } as IActionCommonResponse)
                    
                    resolve(orgs);
                }, (error: Response) => {
                    if (error.status == 401 || error.status == 403) {
                        dispatch({
                            type: UPDATE_ERROR,
                            errorStatus: error.status,
                            errorResponse: error.data
                        } as IActionUI)
                    }
                    reject(error);
                });
        });

    }
}

export function getLinkOrg(orgId: string) {
    return (dispatch: any) => {


        return new Promise((resolve, reject) => {

            LinkOrgsDAL.get(orgId)
                .then((orgs: any) => {
                    
                    dispatch({
                        type: GET_LINK_ORGS_NO_CONTENT,
                        payload: orgs as LinkOrgsNoContent
                    } as IActionLinkOrgsNoContent)
                    
                    resolve(orgs);
                }, (error: Response) => {
                    if (error.status == 401 || error.status == 403) {
                        dispatch({
                            type: UPDATE_ERROR,
                            errorStatus: error.status,
                            errorResponse: error.data
                        } as IActionUI)
                    }
                    reject(error);
                });
        });

    }
}


export function deleteLinkOrgs(id: string) {
    return (dispatch: any) => {


        return new Promise((resolve, reject) => {

            LinkOrgsDAL.delete(id)
                .then(orgs => {
                    dispatch({
                        type: UPDATE_COMMON_RESPONSE,
                        payload: orgs as CommonResponse
                    } as IActionCommonResponse)
                    
                    resolve(orgs);
                }, (error: Response) => {
                    if (error.status == 401 || error.status == 403) {
                        dispatch({
                            type: UPDATE_ERROR,
                            errorStatus: error.status,
                            errorResponse: error.data
                        } as IActionUI)
                    }
                    reject(error);
                });
        });

    }
}
