import { IResponseBigDataVN, TypePageBigData, TypeResultsBigData } from './../../../common/IResponseBigData';
export type TypeZMALiveVersionItem = {
    appId?: string
    appName?: string
    createdAt?: number
    createdBy?: string
    id?: string
    status?: string
    updatedAt?: number
    updatedBy?: string
    version?: number
}

export class ZMALiveVersions implements IResponseBigDataVN<TypeZMALiveVersionItem>{
    success: boolean;
    results: TypeResultsBigData<TypeZMALiveVersionItem>;
    constructor() {
        this.results = {
            content: [],
            timeTaken: 0,
            currentTime: 0,
            page: {
                pageSize: 0,
                currentPage: 0,
                totalRecords: 0,
                totalPages: 0
            } as TypePageBigData,
            extras: null
        }
        this.success = false
    }
}