import { Button, Col, Drawer, Form, Input, Popconfirm, Row, Spin, Table } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { ColumnProps } from 'antd/lib/table';
import { get, isFunction, map, values } from 'lodash';
import { LongTextTd } from 'o2o_layout';
import { IResponseSingleModel } from 'o2o_layout/lib/common/interface';
import { TypeConsoleUser } from 'o2o_layout/lib/common/model';
import React, { useEffect } from 'react';
import update from 'react-addons-update';
import { useAsyncRetry } from 'react-use';
import { IActionUI, SHOW_COMMON_ERROR } from '../../redux/reducer/uiReducer';
import { useDispatch } from '../../redux/store';
import { CommonNotificationUpdateSuccessfully } from '../../shares/CommonNotification';
import { O2OFormItem } from '../../shares/O2OFormItem';
import { PermissionDAL } from './permisions/dal/PermissionDAL';
import { AllPermissionGroups, TypePermissionGroup } from './permisions/model/permissions';
import SuggestionPermisstionGroup from './share/SuggestionPermisstionGroup';

export class PermissionDetail implements IResponseSingleModel<TypeConsoleUser> {
    success: boolean;
    result?: TypeConsoleUser
    permissionGroups: { [key: string]: TypePermissionGroup }
    constructor() {
        this.success = false
        this.permissionGroups = {}
    }
}

interface Props extends FormComponentProps {
    visible: boolean,
    onClose: any,
    onSubmit: any,
    loading: boolean,
    permissionGroup: AllPermissionGroups,
    userPermissions?: string[],
    users: TypeConsoleUser[]
    selectedUser?: TypeConsoleUser
    commonActionFromDALV2: any
    onSuccess?: () => void
}


export const DrawerAssignUserToPermissionGroup = Form.create<Props>()(
    ({ visible, onClose, form, form: { getFieldDecorator }, commonActionFromDALV2, selectedUser, onSuccess }: Props) => {
        const [permissionGroups, setPermissionGroups] = React.useState([] as TypePermissionGroup[])
        const [loading, setLoading] = React.useState(false)

        const dispatch = useDispatch()

        const onSave = async () => {
            setLoading(true)
            const groupIds = [get(selectedUser, "id")]
            const userIds = map(permissionGroups, (p: TypePermissionGroup) => p.id)
            const result = commonActionFromDALV2(PermissionDAL.saveUsersToPermissionGroups, groupIds, userIds)
            try {
                const response = await result
                if (response.success) {
                    if (isFunction(onSuccess)) {
                        onSuccess()
                    }
                    onClose()
                    setLoading(false)
                    CommonNotificationUpdateSuccessfully()
                } else {
                    setLoading(false)
                    dispatch({
                        type: SHOW_COMMON_ERROR,
                        commonError: response
                    } as IActionUI)
                }
            } catch (error) {
                setLoading(false)
                dispatch({
                    type: SHOW_COMMON_ERROR,
                    commonError: error
                } as IActionUI)
            }
        }

        const getDetail = async () => {
            const result = commonActionFromDALV2(PermissionDAL.getDetailPermission, get(selectedUser, "id"), true)
            try {
                const response: PermissionDetail = await result
                if (response.success) {
                    return response
                } else {
                    return new PermissionDetail()
                }
            } catch (error) {
                return new PermissionDetail()
            }
        }

        const stateDetail = useAsyncRetry(async () => {
            if (visible && selectedUser) {
                const data = await getDetail()
                return data
            } return new PermissionDetail()
        }, [visible, JSON.stringify(selectedUser)])

        const detail = stateDetail.value || new PermissionDetail()

        useEffect(() => {
            if (detail.success && detail.result) {
                const defaultPermissionGroups = values(detail.permissionGroups)
                setPermissionGroups(defaultPermissionGroups)
            }
        }, [JSON.stringify(detail)])


        return <Drawer
            title={<span>
                <Button onClick={() => {
                    form.resetFields()
                    onClose()
                }} type='link' size='small' icon='left-circle' />
                GRANT PERMISSION
            </span>}
            width={600}
            onClose={() => {
                form.resetFields()
                onClose()
            }}
            visible={visible}
            bodyStyle={{ paddingBottom: 80 }}
        >
            <Spin spinning={stateDetail.loading}>
                <Form layout="vertical">

                    <Row gutter={[5, 5]}>
                        <Col span={24}>
                            <O2OFormItem label="User">
                                <Input value={detail.result ? detail.result.id : undefined} readOnly />
                            </O2OFormItem>
                        </Col>
                        <Col xs={24}>
                            <SuggestionPermisstionGroup
                                mode={"multiple"}
                                disabledPermisions={map(permissionGroups, (p: TypePermissionGroup) => p.id)}
                                onSelect={(permision: TypePermissionGroup) => {
                                    const newPermissionGroups = update(permissionGroups, {
                                        $push: [permision]
                                    })
                                    setPermissionGroups(newPermissionGroups)
                                    form.setFieldsValue({
                                        permissionGroup: []
                                    })
                                }}
                                keyField='permissionGroup'
                                form={form}
                                commonActionFromDALV2={commonActionFromDALV2}
                            />
                        </Col>
                        <Col xs={24}>
                            <span>Selected {permissionGroups.length} record(s)</span>
                        </Col>
                        <Col xs={24}>
                            <Table
                                size='small'
                                key={"id"}
                                pagination={false}
                                dataSource={permissionGroups}
                                columns={[{
                                    title: '#',
                                    key: "index",
                                    width: 40,
                                    align: "center",
                                    ellipsis: true,
                                    render: (text: any, record: TypePermissionGroup, index: number) => {
                                        return index + 1
                                    }
                                }, {
                                    title: 'Name',
                                    key: "name",
                                    ellipsis: true,
                                    render: (text: any, record: TypePermissionGroup, index: number) => {
                                        return <LongTextTd text={record.name} />
                                    }
                                }, {
                                    title: 'Action',
                                    key: "action",
                                    fixed: "right",
                                    ellipsis: true,
                                    width: 70,
                                    render: (text: any, record: TypePermissionGroup, index: number) => {
                                        return <Button onClick={() => {
                                            const newPermissionGroups = update(permissionGroups, {
                                                $splice: [[index, 1]]
                                            })
                                            setPermissionGroups(newPermissionGroups)
                                        }} size='small' icon='delete' type='link' />
                                    }
                                }] as ColumnProps<TypePermissionGroup>[]} />
                        </Col>
                    </Row>
                </Form>
            </Spin>
            <div
                style={{
                    position: 'absolute',
                    right: 0,
                    bottom: 0,
                    width: '100%',
                    borderTop: '1px solid #e9e9e9',
                    padding: '10px 16px',
                    background: '#fff',
                    textAlign: 'right',
                    zIndex: 999999,
                }}
            >
                <Button onClick={onClose} style={{ marginRight: 8 }}>
                    Cancel
                </Button>
                <Popconfirm
                    placement="leftTop"
                    title="Are you sure to continue?"
                    onConfirm={onSave}
                >
                    <Button loading={loading} type="primary"  >
                        Save
                    </Button>
                </Popconfirm>

            </div>
        </Drawer>
    }
);