import { Badge, Divider, Drawer, Empty, Icon } from 'antd'
import { get, lowerCase } from 'lodash'
import moment from 'moment'
import { TypeOrganization } from 'o2o_layout/lib/common/model'
import ButtonBackDrawer from '../../shares/ButtonBackDrawer'
import { CrownIcon } from '../../shares/DMPIcon'
import React from 'react'

interface IDrawerOrgInfoProps {
    onClose: () => void
    visible: boolean
    selectedRecord?: TypeOrganization
}


const DrawerOrgInfo = (props: IDrawerOrgInfoProps) => {
    const { visible, onClose, selectedRecord } = props

    const colorStatusOrg = () => {
        if (lowerCase(get(selectedRecord, 'status')) === 'active') {
            return '#04ad29'
        } else {
            return 'gray'
        }
    }

    const renderIconByTypeOrg = () => {
        if (lowerCase(get(selectedRecord, 'accountType')) === 'enterprise') {
            return <Badge offset={[-3, 10]} color={colorStatusOrg()}>
                <span style={{ color: '#04ad29', fontSize: 19 }}>
                    <CrownIcon />
                </span>
            </Badge>
        } else if (lowerCase(get(selectedRecord, 'accountType')) === 'lite') {
            return <Badge offset={[-2, 6]} color={colorStatusOrg()}>
                <Icon style={{ color: '#04ad29' }} type="star" />
            </Badge>
        } else {
            return null
        }
    }

    if (!selectedRecord) {
        return <Empty />
    }

    return (
        <Drawer
            bodyStyle={{ marginBottom: 100 }}
            title={<span>
                <ButtonBackDrawer onClick={onClose} />
                Organization Information
            </span>}
            visible={visible}
            onClose={onClose}
            width={600}
        >

            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 14 }}>
                <span style={{
                    color: '#000',
                    fontWeight: 700,
                    fontSize: 19,
                    marginRight: 8
                }}>
                    {selectedRecord.code || '-/-'}
                </span> {renderIconByTypeOrg()}
            </div>

            <div>
                <span style={{
                    padding: "10px 16px",
                    border: "1px solid #000",
                    borderRadius: "20px",
                    color: "#000",
                    fontSize: "12px",
                    marginRight: 6
                }}>{selectedRecord.industry || '-/-'}</span >
                <span style={{
                    padding: "10px 16px",
                    border: "1px solid #000",
                    borderRadius: "20px",
                    color: "#000",
                    fontSize: "12px"
                }}>{selectedRecord.accountType || '-/-'}</span >
            </div>

            <div style={{ marginTop: 24 }}>
                <div style={{ color: "#000", fontWeight: 700, fontSize: 16 }}>{selectedRecord.name}</div>

                <div>
                    {selectedRecord.address || '-/-'}
                </div>

                <div>
                    <a target='_blank' href={selectedRecord.website}>
                        {selectedRecord.website || '-/-'}
                    </a>
                </div>

                <div>
                    {selectedRecord.phone || '-/-'} <Divider type='vertical' /> {selectedRecord.email || '-/-'}
                </div>
            </div>

            <Divider />

            <div style={{ color: "#000", fontWeight: 700, fontSize: 16 }}>
                {selectedRecord.phase || '-/-'} Phase
            </div>

            <Divider />

            <div style={{ color: "#000", fontWeight: 700, fontSize: 16, marginBottom: 8 }}>
                System Information
            </div>

            <div style={{ color: '#797979', fontSize: 12 }}>
                Created {moment(selectedRecord.createdAt).fromNow()} by {selectedRecord.createdBy}
            </div>


        </Drawer>
    )
}

export default DrawerOrgInfo