import { Avatar, Badge, Breadcrumb, Button, Col, Descriptions, Divider, Drawer, Dropdown, Form, Icon, Input, Menu, message, Modal, notification, Popconfirm, Row, Select, Spin, Table, Tag, Tooltip, Typography } from 'antd';
import * as React from 'react';
import { Link } from 'react-router-dom';
import CommonTypeProps from '../../common/CommonTypeProps';
import { DashboardPageLayout } from '../../layout/dashboard/DashboardPageLayout';

import { ColumnProps } from 'antd/lib/table';

import { PaginationProps } from 'antd/lib/pagination';
import { CanAccess } from '../../shares/CanAccess';

import { FormComponentProps } from 'antd/lib/form';
import { ClickParam } from 'antd/lib/menu';
import _, { debounce, get } from 'lodash';
import moment from 'moment';
import { BIGDATA_USER_REF, canAccess, LongTextTd, O2OCustomPaging } from 'o2o_layout';
import { ConsoleUser, ConsoleUsers, Organizations, TypeConsoleUser, TypeOrganization, User } from 'o2o_layout/lib/common/model';
import { BIGDATA_CONSTANT } from '../../common/constant';
import { getCurrentURL, getPrimaryColor } from '../../common/helper';
import { SelectColumns } from '../../common/SelectColumns';
import { CommonResponse } from '../../redux/model/commonResponse';
import { CommonNotificationSaveError, CommonNotificationUpdateSuccessfully } from '../../shares/CommonNotification';
import { DrawerExportDataWithout2FA } from '../../shares/DrawerExportData';
import DrawerViewJSON from '../../shares/DrawerViewJSON';
import { InfoList } from '../../shares/InfoList';
import { MetaHeader } from '../../shares/MetaHeader';
import { O2OFormItem } from '../../shares/O2OFormItem';
import { SwitchButtonConfirm } from '../../shares/SwitchButtonConfirm';
import DrawerConfirmDelete from '../orgs/components/DrawerConfirmDelete';
import { typeGetOrgs } from '../orgs/OrgsAction';
import { typeCriteriaSearchOrgs } from '../orgs/OrgsDAL';
import { ConsoleUsersDAL, typeCriteriaSearchAccount } from './ConsoleUserDAL';
import { typeAddUsers, typeDeleteAccount, typeDetailAcount, typeGetAccounts, typeResetPasswordAccount, typeUpdateAcount } from './ConsoleUsersAction';
import { DrawerSPUserAssignToCompany } from './drawer/DrawerSPUserAssignToCompany';
import { DrawerActionsLog } from './DrawerActionsLog';
import { DrawerAssignUserToPermissionGroup } from './DrawerAssignUserToPermissionGroup';
import { DrawerFormResetPassword } from './DrawerResetPassword';
import { ModalConsoleUser } from './ModalConsoleUser';
import { AllPermissionGroups, Permissions, TypePermission, TypePermissionByUser, TypePermissionGroup } from './permisions/model/permissions';
import { CommonDAL } from '../../shares/dal/CommonDAL';
import { Methods } from 'o2o_layout/lib/common/rest';

interface IFormFilterProps extends FormComponentProps {
    onSearch: any,
    user: User,
    searchOrg: any,
    organizations: Organizations,
    isLoading: boolean
    onExport: () => void
}
const FormFilterComponent = Form.create<IFormFilterProps>({
    name: 'filterform',

})(
    class FormFilterClass extends React.Component<IFormFilterProps, any> {
        componentDidMount = () => {
            this.searchOrg = _.debounce(this.searchOrg, 100)
        }
        onSearch = (e: any) => {
            e.preventDefault();

            this.props.form.validateFieldsAndScroll((err: any, values: any) => {
                if (!err) {
                    this.props.onSearch(values)
                }
            });
        }

        searchOrg = (v: string) => {
            this.props.searchOrg(v)
        }

        render() {
            const { getFieldDecorator } = this.props.form

            return (
                <Form colon={false} hideRequiredMark={true} layout="horizontal" title="Search">
                    <Row gutter={[5, 5]} type="flex" justify="start" align="bottom">
                        <Col xs={24} lg={5} style={{ flexGrow: 1 }}>
                            <O2OFormItem label="Account Info">
                                {getFieldDecorator("freeText")(
                                    <Input placeholder="UserId, Name, Phone" onPressEnter={this.onSearch} />
                                )}
                            </O2OFormItem>
                        </Col>
                        <CanAccess permission={["SUPER_ADMIN"]} userRoles={this.props.user.orgCtx ? this.props.user.orgCtx.roles : []}>
                            <Col xs={24} lg={7} style={{ flexGrow: 1 }}>
                                <O2OFormItem label="Organizations">
                                    {getFieldDecorator("orgIds")(
                                        <Select
                                            mode="multiple"
                                            style={{ width: '100%' }}
                                            placeholder="Select(s)"
                                            showArrow
                                            onSearch={(value: string) => {

                                                this.searchOrg(value)
                                            }}
                                            filterOption={false}
                                            loading={this.props.isLoading}
                                            notFoundContent={this.props.isLoading ? <Spin size="small" /> : null}
                                            maxTagCount={2}
                                        >
                                            {

                                                this.props.organizations.results.content.map((item: TypeOrganization) => {

                                                    return (
                                                        <Select.Option value={item.id}>{item.name}</Select.Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    )}
                                </O2OFormItem>

                            </Col>
                        </CanAccess>
                        <Col span={10} xs={24} sm={5} style={{ flexGrow: 1 }}>
                            <O2OFormItem label="Roles">
                                {getFieldDecorator("roles")(
                                    <Select mode="multiple" style={{ width: '100%' }} placeholder="Select(s)">
                                        <Select.Option key="ADMIN">Admin</Select.Option>
                                        <Select.Option key="API">Api</Select.Option>
                                        <Select.Option key="USER">User</Select.Option>
                                    </Select>
                                )}
                            </O2OFormItem>

                        </Col>
                        <Col xs={24} lg={4} style={{ flexGrow: 1 }}>
                            <O2OFormItem label="Status">
                                {getFieldDecorator("status")(
                                    <Select mode="multiple" style={{ width: '100%' }} placeholder="Select(s)">
                                        <Select.Option key="active">Active</Select.Option>
                                        <Select.Option key="inactive">Inactive</Select.Option>
                                    </Select>
                                )}
                            </O2OFormItem>
                        </Col>
                        <Col>
                            <O2OFormItem>
                                <Row type='flex' align={"middle"}>

                                    <Button style={{ marginRight: 5 }} icon="search" onClick={this.onSearch} type="primary">Search</Button>

                                    <Dropdown overlay={<Menu onClick={(e: ClickParam) => {
                                        if (e.key === 'export') {
                                            this.props.onExport()
                                        }
                                    }}>
                                        <Menu.Item key={"export"}>Export Data</Menu.Item>
                                    </Menu>}>

                                        <Button icon="more"></Button>
                                    </Dropdown>

                                </Row>

                            </O2OFormItem>
                        </Col>
                    </Row>

                </Form>
            )
        }
    }
)


interface Props extends CommonTypeProps {
    user: User,
    commonResponse: CommonResponse,
    consoleUsers: ConsoleUsers,
    getAccount: typeGetAccounts,
    organizations: Organizations,
    getOrgs: typeGetOrgs,
    addUser: typeAddUsers,
    updateAccount: typeUpdateAcount,
    consoleUser: ConsoleUser,
    detailAccount: typeDetailAcount,
    resetPasswordAccount: typeResetPasswordAccount,
    deleteAccount: typeDeleteAccount,
    updateRoleAccount: any,
    deleteUserFromOrg: any,
    updateUserOrg: any,
    deleteUserFromOrgSPADMIN: any,
    assignUserToOrg: any,

    searchPermissionGroup: any,
    permissionGroups: AllPermissionGroups,
    assignUsersToPermissionGroups: any,
    searchPermission: any,
    permissions: Permissions,
    getColumns: any,
    saveColumns: any,
    saveUsersToPermissionGroups: any
    distinctByField: any
}

export class ConsoleSPUsersComponent extends React.Component<Props, any> {

    formRef: any
    searchInput: any

    constructor(props: Props) {
        super(props);
        this.loadOrg = debounce(this.loadOrg, 1000);
    }




    state = {
        visibleExportData: false,
        pagination: {

        } as PaginationProps,
        criteria: {
            order: "createdAt:DESC"
        } as typeCriteriaSearchAccount,
        criteriaOrg: {
            order: "createdAt:DESC"
        } as typeCriteriaSearchOrgs,
        isVisibleDrawer: false,
        isVisibleModal: false,
        isVisibleOrganizationInfo: false,
        visible_assign_permission_group: false,
        selectedConsoleUser: {} as TypeConsoleUser,
        selectedOrgIdAsign: undefined,
        selectedRoleAsign: undefined,
        currentUser: {} as User,
        currentUserOrg: [],
        listPermissionByUser: [] as TypePermissionByUser[],
        selectedUser: {} as TypeConsoleUser,
        selectedRowKeys: [],
        selectedUsers: [] as TypeConsoleUser[],
        selectedPermissionGroup: {} as TypePermissionGroup,
        userPermissions: [],
        visibleActionsLog: false as boolean,
        columns: [
            {
                title: "#",
                key: "index",
                render: (text: any, record: TypeConsoleUser, index: number) => {
                    return (
                        index + 1
                    )
                },
                width: 40,
            },
            {
                title: "",
                key: "a",
                width: 50,

                render: (text: any, record: TypeConsoleUser, index: number) => {
                    let { id = "" } = record
                    let initials: any = id.match(/\b\w/g) || [];
                    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
                    return (
                        <span>
                            <Avatar style={{ background: this.state.top10Color[index] }} size={40}>{initials}</Avatar>
                        </span>
                    )
                },
            },
            {
                title: 'User id',
                dataIndex: 'id',
                key: 'id',

                sorter: true,

            },
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                render: (text: any, record: TypeConsoleUser, index: number) => {
                    return (
                        record.name
                    )
                },
                sorter: true,

            },

            {
                title: 'Phone',
                dataIndex: 'phone',
                key: 'phone',
                render: (text: any, record: TypeConsoleUser, index: number) => {
                    return (
                        record.phone || "--/--"
                    )
                },
                sorter: true,

            },
            {
                title: 'Address',
                dataIndex: 'address',
                key: 'address',
                render: (text: any, record: TypeConsoleUser, index: number) => {
                    return (
                        <Tooltip title={record.address}>
                            <Typography.Paragraph style={{ paddingBottom: 0, marginBottom: 0 }} ellipsis={{ rows: 2 }}>{record.address || "--/--"}</Typography.Paragraph>
                        </Tooltip>
                    )
                },
                sorter: true,

            },
            {
                title: 'Status', dataIndex: 'status',
                key: 'status', render: (text: any, record: TypeConsoleUser, index: number) => {
                    return (
                        record.status == "active" ? <Badge color="green" text="Active" /> : <Badge color="red" text="Inactive" />
                    )
                },
                sorter: true,
            },
            {
                title: 'Roles',
                key: 'roles', render: (text: any, record: TypeConsoleUser, index: number) => {
                    if (this.props.consoleUsers.orgCtxes) {
                        let roles = this.props.consoleUsers.orgCtxes[record.id].roles;
                        return (
                            <React.Fragment>
                                {
                                    roles.map((e: string) => {
                                        return <Tag>{this.convertRoleToString(e)}</Tag>
                                    })
                                }
                            </React.Fragment>
                        )
                    }
                    return ""
                },
                sorter: true,
            },
            {
                title: 'Permission Groups',
                width: 250,
                key: 'permision_groups', render: (text: any, record: TypeConsoleUser, index: number) => {
                    if (!_.isEmpty(this.props.consoleUsers.permissionGroups)) {
                        let groups = this.props.consoleUsers.permissionGroups;
                        let userPermisionGroups: any;
                        for (const key in groups) {
                            if (groups.hasOwnProperty(key)) {
                                const permisionGroup = groups[key];
                                if (permisionGroup.userId == record.id) {
                                    userPermisionGroups = permisionGroup;
                                }

                            }
                        }
                        if (userPermisionGroups) {
                            let g: any[] = userPermisionGroups.groupIds.map((item: string) => {
                                let p: TypePermissionGroup | undefined = _.find(this.props.permissionGroups.results, (p: TypePermissionGroup) => p.id == item)
                                if (p) {
                                    return {
                                        id: item,
                                        name: p.name
                                    }
                                }
                                return {
                                    id: item,
                                    name: item
                                }
                            })
                            return g.length ? g.map((gitem: any) => {
                                return <Tooltip trigger="click" title={() => {
                                    return <Spin spinning={this.props.ui.isLoading}>
                                        Permission: {this.state.selectedPermissionGroup && this.state.selectedPermissionGroup.permissions && this.state.selectedPermissionGroup.permissions.map((item: string) => {
                                            let pf: TypePermission | undefined = _.find(this.props.permissions.results, (d: TypePermission) => d.id == item)
                                            return pf ? pf.name : item
                                        }).join(", ")}
                                    </Spin>
                                }}>
                                    <Tag style={{
                                        cursor: "pointer"
                                    }} color="green" onClick={(it: any) => {
                                        this.showPermissionDetal(gitem)
                                    }}>
                                        {gitem.name}
                                    </Tag>
                                </Tooltip>
                            }) : "--/--"
                        }
                    }
                    return "--/--"
                },
            },
            {
                title: 'Company',

                key: 'orgId',

                sorter: false,
                render: (text: any, record: TypeConsoleUser, index: number) => {
                    return (
                        this.props.user ? (this.props.user.orgCtx ? this.props.user.orgCtx.organization.name : 'N/A') : 'N/A'
                    )
                }

            },
            {
                title: 'Created At', dataIndex: 'createdAt', key: 'createdAt', sorter: true,
                render: (text: any, record: TypeConsoleUser, index: number) => {
                    return (
                        <Tooltip title={`Created by ${record.createdBy}`}>
                            {moment(record.createdAt).fromNow()}
                        </Tooltip>

                    )
                },
            },
            {
                title: 'Updated At', dataIndex: 'updatedAt', key: 'updatedAt', sorter: true,
                render: (text: any, record: TypeConsoleUser, index: number) => {
                    return (
                        record.updatedAt ? (
                            <Tooltip title={`Updated by ${record.updatedBy}`}>
                                {moment(record.updatedAt).fromNow()}
                            </Tooltip>
                        ) : "--/--"
                    )
                },
            },

            {
                title: 'Action',
                key: "action",
                width: 250,
                fixed: "right",
                render: (text: any, record: TypeConsoleUser, index: number) => {
                    return (
                        <span>

                            <CanAccess permission={['ADMIN', 'SUPER_ADMIN']} userRoles={this.props.user ? this.props.user.orgCtx ? this.props.user.orgCtx.roles : [] : []}>

                                <Tooltip title="Edit">
                                    <Button size="small" icon="edit" type="link" onClick={() => {
                                        this.openModal(record, undefined)
                                    }}></Button>
                                </Tooltip>

                            </CanAccess>

                            <CanAccess permission={['ADMIN', 'SUPER_ADMIN']} userRoles={this.props.user ? this.props.user.orgCtx ? this.props.user.orgCtx.roles : [] : []}>
                                <Popconfirm title={`Are you sure you want to reset password account: ${record.name} ?`} onConfirm={() => this.resetPass(record)}>
                                    <Tooltip title="Reset Password">
                                        <Button size="small" icon="redo" type="link" ></Button>
                                    </Tooltip>

                                </Popconfirm>

                            </CanAccess>
                            <CanAccess permission={['ADMIN']} userRoles={this.props.user ? this.props.user.orgCtx ? this.props.user.orgCtx.roles : [] : []}>
                                <Popconfirm title={`Are you sure you want to remove account${record.name} from ${this.props.user.orgCtx ? this.props.user.orgCtx.organization.name : ''} company?`} onConfirm={() => this.deleteAccountFromOrg(record)}>
                                    <Tooltip title="Remove from organization">
                                        <Button size="small" icon="close" type="link" ></Button>
                                    </Tooltip>

                                </Popconfirm>

                            </CanAccess>



                            <Divider type="vertical" />
                            <CanAccess permission={['ADMIN']} userRoles={this.props.user ? this.props.user.orgCtx ? this.props.user.orgCtx.roles : [] : []}>
                                <Tooltip title="Assign to Permission Group">
                                    <Button size="small" type="link" onClick={() => {

                                        if (!_.isEmpty(this.props.consoleUsers.permissionGroups)) {
                                            let groups = this.props.consoleUsers.permissionGroups;
                                            let userPermisionGroups: any;
                                            for (const key in groups) {
                                                if (groups.hasOwnProperty(key)) {
                                                    const permisionGroup = groups[key];
                                                    if (permisionGroup.userId == record.id) {
                                                        userPermisionGroups = permisionGroup;
                                                    }

                                                }
                                            }
                                            if (userPermisionGroups) {
                                                this.setState({
                                                    userPermissions: userPermisionGroups.groupIds,
                                                    visible_assign_permission_group: true,
                                                    selectedUsers: [record]
                                                })
                                            } else {
                                                this.setState({
                                                    userPermissions: [],
                                                    visible_assign_permission_group: true,
                                                    selectedUsers: [record]
                                                })
                                            }
                                        } else {
                                            this.setState({
                                                userPermissions: [],
                                                visible_assign_permission_group: true,
                                                selectedUsers: [record]
                                            })
                                        }
                                    }}>Assign to Group</Button>
                                </Tooltip>


                            </CanAccess>


                        </span>


                    )
                }
            },
        ] as ColumnProps<{}>[],
        columnsSPAdmin: [
            {
                title: "#",
                key: "index",
                align: "center",
                width: 40,
                render: (text: any, record: TypeConsoleUser, index: number) => {
                    return (
                        <span> {index + 1}</span>
                    )
                },
            },
            {
                title: 'Status',
                dataIndex: 'status',
                width: 90,
                ellipsis: true,
                key: 'status', render: (text: any, record: TypeConsoleUser, index: number) => {
                    return (
                        <SwitchButtonConfirm
                            type="customText"
                            showText
                            status={record.status}
                            callApi={async (sl: any) => {
                                let isSPAdmin = this.checkCurrentUserIsSuperAdmin()
                                const payload = {
                                    status: record.status === "active" ? "inactive" : "active",
                                    address: record.address || "",
                                    email: record.id || "",
                                    name: record.name || "",
                                    passwordExpired: record.passwordExpired || "",
                                    phone: record.phone || "",
                                    id: record.id || ""
                                }
                                const result = this.props.commonActionFromDALV2(ConsoleUsersDAL.update, payload, isSPAdmin)
                                try {
                                    const response = await result
                                    if (response.success) {
                                        setTimeout(() => {
                                            this.load()
                                        }, 1000);
                                        CommonNotificationUpdateSuccessfully()
                                    } else {
                                        notification.error({
                                            message: BIGDATA_CONSTANT.message.default.title.error,
                                            description: get(response, "message", 'Something went wrong')
                                        })
                                    }
                                } catch (error) {
                                    notification.error({
                                        message: BIGDATA_CONSTANT.message.default.title.error,
                                        description: get(error, "message", 'Something went wrong')
                                    })
                                }
                            }}
                            valueChecked="active"
                            valueUnchecked="inactive"
                        />
                    )
                },
            },
            {
                title: 'Email',
                dataIndex: 'email',
                width: 250,
                key: 'email',
                ellipsis: true,
                render: (text: any, record: TypeConsoleUser, index: number) => {
                    return <LongTextTd text={record.id} />
                },
            },
            {
                title: 'Name',
                ellipsis: true,
                dataIndex: 'name',
                key: 'name',
                width: 200,
                render: (text: any, record: TypeConsoleUser, index: number) => {
                    return (
                        record.name
                    )
                },
            },
            {
                title: '2FA',
                ellipsis: true,
                dataIndex: '2fa',
                key: '2fa',
                align: 'center',
                width: 120,
                render: (text: any, record: TypeConsoleUser, index: number) => {
                    const isDisable2fa: boolean = this.state.listPermissionByUser.some(item => {
                        return item.permission === "Disable2faAccount" || item.permission === "OWNER"
                    });

                    return (
                        record.totpEnabled ? (
                            <Button type='link' disabled={!isDisable2fa} onClick={() => {
                                Modal.confirm({
                                    title: 'Disable 2FA',
                                    content: `Do you want to disable 2FA of ${record.id}`,
                                    okText: "Disable",
                                    onOk: async () => { this.reset2FA(record.id) }
                                })
                            }} size='small' icon='check-circle' />
                        ) : '--/--'
                    )
                },
            },
            {
                title: 'Organizations',
                ellipsis: true,
                width: 150,
                render: (text: any, record: TypeConsoleUser, index: number) => {
                    return (
                        this.renderOrganizationSAdmin(record)

                    )
                },
                sorter: false,
            },
            {
                title: 'Phone',
                ellipsis: true,
                width: 150,
                dataIndex: 'phone',
                key: 'phone',
                render: (text: any, record: TypeConsoleUser, index: number) => {
                    return (
                        record.phone || "--/--"
                    )
                },
            },
            {
                title: 'Created At', dataIndex: 'createdAt', key: 'createdAt',
                ellipsis: true,
                width: 150,
                render: (text: any, record: TypeConsoleUser, index: number) => {
                    return (
                        record.createdAt ? <Tooltip title={<>
                            Created by {record.createdBy} at {moment(record.createdAt).format("LLLL")}
                        </>}>
                            {moment(record.createdAt).fromNow()}
                        </Tooltip> : "--/--"
                    )
                },
            },
            {
                title: 'Modified Time',
                dataIndex: 'updatedAt',
                key: 'updatedAt',
                ellipsis: true,
                width: 150,
                render: (text: any, record: TypeConsoleUser, index: number) => {
                    return (
                        record.updatedAt ? <Tooltip title={<>
                            Updated by {record.updatedBy} at {moment(record.updatedAt).format("LLLL")}
                        </>}>
                            {moment(record.updatedAt).fromNow()}
                        </Tooltip> : "--/--"
                    )
                },
            },

            {
                title: 'Action',
                width: 180,
                fixed: "right",
                render: (text: any, record: TypeConsoleUser, index: number) => {
                    return (
                        <span>

                            <Tooltip title="Activities on the user">
                                <Button onClick={() => {
                                    this.setState({
                                        selectedConsoleUser: record,
                                        visibleActionsLog: true
                                    })
                                }} type='link' icon='history' size='small'></Button>
                            </Tooltip>

                            <Divider type='vertical' />

                            <CanAccess permission={['ADMIN', 'SUPER_ADMIN']} userRoles={this.props.user ? this.props.user.orgCtx ? this.props.user.orgCtx.roles : [] : []}>

                                <Tooltip title="Edit">
                                    <Button size="small" icon="edit" type="link" onClick={() => {
                                        this.openModal(record, undefined)
                                    }}></Button>
                                </Tooltip>

                            </CanAccess>

                            <Divider type="vertical" />

                            <CanAccess permission={['SUPER_ADMIN']} userRoles={this.props.user ? this.props.user.orgCtx ? this.props.user.orgCtx.roles : [] : []}>

                                <Tooltip title="Assign Role">
                                    <Button size="small" icon="usergroup-add" type="link" onClick={() => {

                                        this.openAssignRole(record)
                                    }}></Button>
                                </Tooltip>

                            </CanAccess>

                            <Divider type="vertical" />

                            <Dropdown overlay={
                                <Menu onClick={(e: any) => {
                                    if (e.key === "reset-password") {
                                        this.setState({
                                            visibleDrawerResetPw: true,
                                            selectedConsoleUser: record
                                        })
                                    }
                                    else if (e.key === "remove") {
                                        this.setState({
                                            visibleRemoveAccount: true,
                                            selectedConsoleUser: record
                                        })
                                    } else if (e.key === "delete") {
                                        this.setState({
                                            visibleConfirmDelete: true,
                                            selectedConsoleUser: record
                                        })
                                    } else if (e.key === "json") {
                                        this.setState({
                                            visibleViewJSON: true,
                                            selectedConsoleUser: record
                                        })
                                    }
                                }}
                                >
                                    {canAccess(['ADMIN', 'SUPER_ADMIN'], this.props.user ? this.props.user.orgCtx ? this.props.user.orgCtx.roles : [] : []) ? (
                                        <Menu.Item key="reset-password">
                                            <Tooltip title="Reset Password">
                                                <Button icon="redo" type="link" size='small'>Reset Password</Button>
                                            </Tooltip>
                                        </Menu.Item>
                                    ) : null}

                                    <Menu.Item key={"json"}>
                                        <Button icon='select' size="small" type="link" >
                                            View JSON
                                        </Button>
                                    </Menu.Item>

                                    {canAccess(['ADMIN', 'SUPER_ADMIN'], this.props.user ? this.props.user.orgCtx ? this.props.user.orgCtx.roles : [] : []) ? (
                                        <Menu.Item key="delete">
                                            {/* <Popconfirm title={`Are you sure you want to delete account: ${record.name} ?`} onConfirm={() => this.deleteAccounts([record.id])}>
                                                <Tooltip title="Delete account">
                                                    <Button icon="delete" type="link" >Delete Account</Button>
                                                </Tooltip>

                                            </Popconfirm> */}
                                            <Tooltip title="Delete account">
                                                <Button icon="delete" type="link" size='small'>Delete</Button>
                                            </Tooltip>
                                        </Menu.Item>
                                    ) : null}

                                </Menu>
                            }>
                                <Button type='link' icon='more' size='small'></Button>
                            </Dropdown>
                        </span>


                    )
                }
            },
        ] as ColumnProps<{}>[],
        columnsAdminFilter: [] as ColumnProps<{}>[],
        defaultColumnAttrs: [],
        top10Color: [],
        visibleDrawerResetPw: false,
        visibleConfirmDelete: false,
        visibleViewJSON: false
    }


    getRandomColor = () => {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    convertRoleToString = (role: string) => {
        switch (role) {
            case 'USER':
                return 'User'
                break;
            case 'API':
                return 'Api'
                break;
            case 'ADMIN':
                return 'Admin'
                break;
            case 'SUPER_ADMIN':
                return 'Super admin'
                break;
            case 'OPER_ADMIN':
                return 'Operator Admin'
                break;
            default:
                return ""
                break;
        }
        return ""
    }

    showPermissionDetal = (item: any) => {
        this.setState({
            selectedPermissionGroup: {}
        })
        this.props.setGlobalLoading(true)
        this.props.searchPermission()
            .then(() => {
                this.props.setGlobalLoading(false)
                let permissionGroup: TypePermissionGroup | undefined = _.find(this.props.permissionGroups.results, (it: TypePermissionGroup) => it.id == item.id)
                if (permissionGroup) {
                    this.setState({
                        selectedPermissionGroup: permissionGroup
                    })
                } else {
                    this.setState({
                        selectedPermissionGroup: {}
                    })
                }

            })
    }

    openAssignRole = (record: TypeConsoleUser) => {
        // let orgs = this.props.consoleUsers.orgs;

        let userOrgs = this.props.consoleUsers.userOrgs;
        let currentUserOrg = userOrgs ? userOrgs[record.id] : [];

        this.setState({ isVisibleOrganizationInfo: true, selectedConsoleUser: record, currentUserOrg: currentUserOrg })
    }

    openCompanyRoles = (record: TypeConsoleUser) => {
        let userOrgs = this.props.consoleUsers.userOrgs;
        let currentUserOrg = userOrgs ? userOrgs[record.id] : [];
        this.setState({ isVisibleOrganizationInfo: true, selectedConsoleUser: record, currentUserOrg: currentUserOrg })
    }

    renderOrganizationSAdmin = (record: TypeConsoleUser) => {
        let userOrgs = this.props.consoleUsers.userOrgs;
        let currentUserOrg = userOrgs ? userOrgs[record.id] : [];

        if (currentUserOrg && currentUserOrg.length) {
            return <Button style={{ marginLeft: -6 }} type='link' size='small' onClick={() => this.openAssignRole(record)}>
                {currentUserOrg.length + ` Organization${currentUserOrg.length > 1 ? "s" : ""}`}
            </Button>
        } else {
            return <Tooltip title="Assign to Orgnizations ">
                <Button size="small" icon="plus-circle" type="link" onClick={() => {
                    this.openAssignRole(record)
                }}></Button>
            </Tooltip>
        }
    }

    checkUserHasOrg = (id: string) => {
        // this.state.currentUserOrg
        if (this.state.currentUserOrg && this.state.currentUserOrg.length) {
            // orgId
            let og = _.find(this.state.currentUserOrg, (item: any) => item.orgId == id)
            if (og) {
                return <Icon type="check" style={{ color: getPrimaryColor(this.props.uiSetting) }} />
            }
            return null
        }
        return null
    }

    getListPermissionByUser = async () => {
        if (!this.state.currentUser) return
        const currentUser = this.state.currentUser
        const result = this.props.commonActionFromDALV2(CommonDAL.buildURL,
            BIGDATA_CONSTANT.url.api + `mngmnt/internal-permissions/search/by_user?userId=${currentUser && currentUser.orgCtx ? currentUser.orgCtx.userId : ''}&orgId=${currentUser && currentUser.orgCtx ? currentUser.orgCtx.orgId : ''}`,
            {}, Methods.GET
        )
        try {
            const response = await result
            if (response.success) {
                this.setState({
                    listPermissionByUser: response.results as TypePermissionByUser
                })
            } else {
                this.setState({
                    listPermissionByUser: []
                })
            }
        } catch (error) {
            CommonNotificationSaveError(_.get(error, 'message', 'Somethings went wrong'))
        }
    }

    refSelectOrg: null
    refSelectRole: null
    renderDrawerOrgInfo = () => {
        return null
        let uo = this.state.selectedConsoleUser && this.props.consoleUsers.userOrgs ? this.props.consoleUsers.userOrgs[this.state.selectedConsoleUser.id] : [];
        let isSAdmin = this.checkCurrentUserIsSuperAdmin();
        return <Drawer
            width={"80%"}
            title={`Company(s)`}
            placement="right"
            closable={false}
            onClose={() => this.setState({ isVisibleOrganizationInfo: false })}
            visible={this.state.isVisibleOrganizationInfo}

        >
            <p>User <b>{this.state.selectedConsoleUser.name}</b> belongs to bellow company</p>
            <Row gutter={[10, 10]} type="flex" justify="start">
                <Col>
                    <Select
                        ref={(e: any) => this.refSelectOrg = e}
                        showSearch
                        style={{ width: "500px" }}
                        placeholder={`Assign ${this.state.selectedConsoleUser.name} to Company`}
                        onSearch={this.searchOrg}
                        filterOption={false}
                        value={this.state.selectedOrgIdAsign}
                        onChange={(e: any) => {
                            this.setState({ selectedOrgIdAsign: e })
                        }}
                        notFoundContent={this.props.ui.isLoading ? <Spin size="small" /> : null}
                    >
                        {
                            this.props.organizations.results.content.map((item: TypeOrganization) => {
                                return (
                                    <Select.Option key={item.id}>{item.name} {this.checkUserHasOrg(item.id)}</Select.Option>
                                )
                            })
                        }
                    </Select>
                </Col>
                <Col>
                    <Select ref={(e: any) => this.refSelectRole = e} mode="multiple" style={{ width: '400px' }} placeholder="Select Roles" value={this.state.selectedRoleAsign} onChange={(e: any) => {
                        this.setState({ selectedRoleAsign: e })
                    }}>
                        <Select.Option key="ADMIN">Admin</Select.Option>
                        <Select.Option key="API">Api</Select.Option>
                        <Select.Option key="USER">User</Select.Option>
                        <Select.Option key="OPER_ADMIN">Operator Admin</Select.Option>
                    </Select>
                </Col>
                <Col>
                    <Button icon="plus" loading={this.props.ui.isLoading} disabled={this.props.ui.isLoading} onClick={() => {
                        if (this.state.selectedOrgIdAsign) {
                            this.props.setGlobalLoading(true)
                            message.info("Assigning...")
                            this.props.assignUserToOrg(this.state.selectedConsoleUser.id, this.state.selectedOrgIdAsign, this.state.selectedRoleAsign)
                                .then(() => {
                                    this.props.setGlobalLoading(false)
                                    message.info("Assigning success")

                                    this.setState({ isVisibleOrganizationInfo: false, selectedOrgIdAsign: undefined, selectedRoleAsign: undefined })
                                    if (this.refSelectRole) {

                                    }

                                    setTimeout(() => {
                                        this.load(() => {
                                            this.openCompanyRoles(this.state.selectedConsoleUser)
                                        })
                                    }, 1000)
                                })
                        } else {
                            message.warning("Please select an company")
                        }
                    }}>Assign</Button>
                </Col>



            </Row>
            <br />
            <p>
                <React.Fragment>
                    {_.orderBy((this.state.currentUserOrg || []), "createdAt", "desc").map((e: any) => {
                        let o = this.props.consoleUsers.orgs[e.orgId]
                        let uSelect = _.find(uo, (item: any) => {
                            return item.orgId == e.orgId
                        });


                        return (
                            o ? (
                                <p>
                                    <Descriptions title={o.name} bordered column={{ xxl: 2, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }} layout="vertical">
                                        <Descriptions.Item label="Company name">
                                            {o.name}
                                            <Popconfirm title="Are you sure to remove user from this company?" onConfirm={() => {
                                                this.deleteUserFromOrgSPADMIN(this.state.selectedConsoleUser.id, o.id)
                                            }}>
                                                <Button type="link" icon="delete">Remove user form company</Button>
                                            </Popconfirm>

                                        </Descriptions.Item>
                                        <Descriptions.Item label="Company Id">{o.id}</Descriptions.Item>
                                        <Descriptions.Item label="Company email">{o.email || "empty"}</Descriptions.Item>
                                        <Descriptions.Item label="Roles">
                                            <React.Fragment>
                                                <p>
                                                    <Select onChange={(e: any) => {
                                                        this.props.setGlobalLoading(true)
                                                        this.props.updateRoleAccount(this.state.selectedConsoleUser.id, e, isSAdmin, o.id)
                                                            .then(() => {
                                                                this.props.setGlobalLoading(false)
                                                                setTimeout(() => {
                                                                    this.load()
                                                                }, 1000)
                                                                message.success(`Roles updated`)
                                                            })
                                                    }} value={uSelect ? uSelect.roles : ""} defaultValue={uSelect ? uSelect.roles : ""} mode="multiple" style={{ width: '100%' }} placeholder="Update roles">
                                                        <Select.Option key="ADMIN">Admin</Select.Option>
                                                        <Select.Option key="API">Api</Select.Option>
                                                        <Select.Option key="USER">User</Select.Option>
                                                        <Select.Option key="OPER_ADMIN">Operator Admin</Select.Option>
                                                    </Select>
                                                </p>
                                            </React.Fragment>

                                        </Descriptions.Item>

                                    </Descriptions>

                                </p>
                            ) : null
                        )
                    })}
                </React.Fragment>
            </p>
        </Drawer >
    }

    resetPass = (item: TypeConsoleUser, sl?: any) => {
        this.props.setGlobalLoading(true)
        sl(true)
        let isSad = this.checkCurrentUserIsSuperAdmin();
        this.props.resetPasswordAccount(item.id, isSad)
            .then(() => {
                this.props.setGlobalLoading(false)
                sl(false)
                if (this.props.commonResponse.success) {
                    this.setState({
                        visibleDrawerResetPw: false,
                        selectedConsoleUser: {}
                    })
                    notification.success({
                        message: BIGDATA_CONSTANT.message.default.title.success,
                        description: "Reset Password for " + item.name + " successfully",
                        icon: <Icon type="check-circle" style={{ color: getPrimaryColor(this.props.uiSetting) }} />
                    })
                } else {
                    notification.error({
                        message: BIGDATA_CONSTANT.message.default.title.error,
                        description: this.props.commonResponse.message || "error"
                    })
                }
            })
            .catch(() => {
                sl(false)
                this.props.setGlobalLoading(false)
            })
    }

    reset2FA = async (userId: string) => {
        const result = this.props.commonActionFromDALV2(CommonDAL.buildURL, BIGDATA_CONSTANT.url.api + `mngmnt/accounts/reset-2fa?id=${userId}`, {}, Methods.POST)
        const response = await result
        try {
            if (response.success) {
                setTimeout(() => {
                    this.load()
                }, 1000);
                notification.success({
                    message: BIGDATA_CONSTANT.message.default.title.success,
                    description: "Disable 2FA for " + userId + " successfully",
                    icon: <Icon type="check-circle" style={{ color: getPrimaryColor(this.props.uiSetting) }} />
                })
            } else {
                CommonNotificationSaveError(_.get(response, 'message', 'Somethings went wrong'))
            }
        } catch (error) {
            CommonNotificationSaveError(_.get(error, 'message', 'Somethings went wrong'))
        }
    }

    deleteAccounts = (ids: string[]) => {
        this.props.setGlobalLoading(true)
        let isSAdmin = this.checkCurrentUserIsSuperAdmin()
        this.props.deleteAccount(ids, isSAdmin)
            .then(() => {
                this.props.setGlobalLoading(false)
                if (this.props.commonResponse.success) {
                    notification.success({
                        message: BIGDATA_CONSTANT.message.default.title.success,
                        description: BIGDATA_CONSTANT.message.notification.success.delete,
                        icon: <Icon type="check-circle" style={{ color: getPrimaryColor(this.props.uiSetting) }} />
                    })

                    setTimeout(() => {
                        this.load()
                    }, 1000)
                    this.setState({
                        selectedRowKeys: []
                    })

                } else {
                    notification.error({
                        message: BIGDATA_CONSTANT.message.default.title.error,
                        description: this.props.commonResponse.message,
                    })
                }
            })
            .catch(() => {
                notification.error({
                    message: BIGDATA_CONSTANT.message.default.title.error,
                    description: "Something was wrong, please try later"
                })
                this.props.setGlobalLoading(false)
            })
    }
    deleteUserFromOrgSPADMIN = (id: string, oid: string) => {
        this.props.setGlobalLoading(true)
        this.props.deleteUserFromOrgSPADMIN(id, oid)
            .then(() => {
                this.props.setGlobalLoading(false)
                if (this.props.commonResponse.success) {
                    notification.success({
                        message: BIGDATA_CONSTANT.message.default.title.success,
                        description: "Remove account from company success",
                        icon: <Icon type="check-circle" style={{ color: getPrimaryColor(this.props.uiSetting) }} />
                    })
                    setTimeout(() => {
                        this.load(() => {
                            this.openCompanyRoles(this.state.selectedConsoleUser)
                        })
                    }, 1000)
                } else {
                    notification.error({
                        message: BIGDATA_CONSTANT.message.default.title.error,
                        description: this.props.commonResponse.message || "error"
                    })
                }
            })
            .catch(() => {
                notification.error({
                    message: BIGDATA_CONSTANT.message.default.title.error,
                    description: "Something was wrong, please try later"
                })
                this.props.setGlobalLoading(false)
            })
    }
    deleteAccountFromOrg = (item: TypeConsoleUser) => {
        this.props.setGlobalLoading(true)
        this.props.deleteUserFromOrg(item.id)
            .then(() => {
                this.props.setGlobalLoading(false)
                if (this.props.commonResponse.success) {
                    notification.success({
                        message: BIGDATA_CONSTANT.message.default.title.success,
                        description: "Removed successfully",
                        icon: <Icon type="check-circle" style={{ color: getPrimaryColor(this.props.uiSetting) }} />
                    })
                    setTimeout(() => {
                        this.load()
                    }, 1000)
                } else {
                    notification.error({
                        message: BIGDATA_CONSTANT.message.default.title.error,
                        description: this.props.commonResponse.message || "error"
                    })
                }
            })
            .catch(() => {
                notification.error({
                    message: BIGDATA_CONSTANT.message.default.title.error,
                    description: "Something was wrong, please try later"
                })
                this.props.setGlobalLoading(false)
            })
    }

    showDetail = (item: TypeOrganization) => {
        this.setState({ isVisibleDrawer: true, selectedOrg: item })
    }

    componentDidMount = () => {
        this.getCurrentUser().then(() => {
            this.load()
            this.getListPermissionByUser()
        })
        this.changeFreeText = _.debounce(this.changeFreeText, 500)

        // this.props.searchPermissionGroup()

        this.setState({
            columnsAdminFilter: this.state.columns
        })

        let letTop10 = []
        for (let i = 0; i < 10; i++) {
            letTop10[i] = this.getRandomColor()
        }

        this.setState({
            top10Color: letTop10
        })

        this.props.getColumns("acc_console_user")
            .then(() => {
                if (this.props.ui.columns && this.props.ui.columns.result && this.props.ui.columns.result.columns.length) {
                    this.setState({
                        defaultColumnAttrs: this.props.ui.columns.result.columns
                    })
                } else {
                    this.setState({
                        defaultColumnAttrs: BIGDATA_USER_REF.ACCOUNT.console_user.columns
                    })
                }
                this.initColumns(this.state.defaultColumnAttrs)
            })

        this.props.updateMetaTitle({ title: BIGDATA_CONSTANT.trackTitle.SPConsoleUser })
        this.timer = setTimeout(() => {
            this.props.updateMeta({
                name: BIGDATA_CONSTANT.trackTitle.SPConsoleUser,
                title: BIGDATA_CONSTANT.trackTitle.SPConsoleUser,
                app: "ACCOUNT",
                url: getCurrentURL(this.props.location)
            })
            clearTimeout(this.timer)
        }, 5000)

    }

    timer: any

    componentWillUnmount() {
        clearTimeout(this.timer)
    }


    changeColumnsAttribute = (e: string[]) => {

        message.loading("Hệ thống đang lưu")

        this.props.saveColumns({
            columns: e,
            objectType: "acc_console_user"
        })
            .then(() => {
                this.initColumns(e)
            })


    }

    initColumns = (e: string[]) => {
        if (e && e.length > 0) {
            let newColumns: any[] = _.filter(this.state.columns, (item: any) => {
                return _.indexOf(e, item.key) > -1
            })
            this.setState({
                columnsAdminFilter: newColumns
            })
        } else {
            this.setState({
                columnsAdminFilter: this.state.columns
            })
        }
    }


    renderColumnAttr = () => {
        return (

            <SelectColumns
                objectType="console_user"
                loading={this.props.ui.isLoading}
                columns={this.state.defaultColumnAttrs}
                onChangeForm={(e: any) => {
                    if (e.columns) {
                        this.changeColumnsAttribute(e.columns.value)
                    }
                }}
            />
        )
    }


    loadOrg = () => {

        this.props.getOrgs(this.state.criteriaOrg)
            .then(() => {
                // console.log("search orgs: ", this.props.organizations)
                this.props.setGlobalLoading(false)
            })
            .catch(() => this.props.setGlobalLoading(false))


    }

    getCurrentUser = async () => {
        const result = this.props.commonActionFromDALV2(CommonDAL.buildURL,
            BIGDATA_CONSTANT.url.api + 'accounts/users/current',
            {}, Methods.GET
        )
        const response = await result
        if (response.success) {
            this.setState({
                currentUser: response.result
            })
        } else {
            this.setState({
                currentUser: {}
            })
        }
        return response.result
    }

    checkCurrentUserIsSuperAdmin = (): boolean => {
        if (this.state.currentUser) {
            if (this.state.currentUser.orgCtx) {
                let roles = this.state.currentUser.orgCtx.roles;
                let isSP = _.find(roles, (e) => {
                    return e == "SUPER_ADMIN"
                });

                if (isSP) {
                    return true
                }
                return false
            }
            return false
        }
        return false;
    }

    load = (callback?: any) => {

        let isSAdmin = this.checkCurrentUserIsSuperAdmin();
        if (isSAdmin) {
            this.loadOrg()
        }

        this.props.setGlobalLoading(true)
            .then(() =>
                this.props.getAccount(this.state.criteria, isSAdmin)
                    .then(() => {
                        this.props.setGlobalLoading(false)

                        let pagination = { ...this.props.consoleUsers.results.page }
                        let convertPaginationTable: PaginationProps = {
                            current: pagination.currentPage + 1,
                            pageSize: pagination.pageSize,
                            total: pagination.totalRecords,
                        }
                        this.setState({
                            pagination: Object.assign(this.state.pagination, convertPaginationTable),
                            criteria: Object.assign(this.state.criteria, { page: convertPaginationTable.current })
                        })

                        if (callback && typeof (callback) == "function") {
                            callback()
                        }

                    })
                    .catch(() => this.props.setGlobalLoading(false))
            )

    }

    handleTableChange = (pagination: any, filters: any, sorter: any) => {
        const pager = { ...this.state.pagination };
        pager.current = pagination.current;
        const order = sorter.field ? `${sorter.field}:${sorter.order == 'descend' ? 'DECS' : 'ASC'}` : 'createdAt:DESC'

        this.setState({
            pagination: pager,
            criteria: Object.assign(this.state.criteria, { order: order, page: pager.current })
        });


        this.load();
    };

    renderBreadCrumb = () => {
        return (
            <Row type="flex" justify="space-between" align="middle" style={{ marginBottom: "7px" }}>
                <Col>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Link to="/">Home</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>Super Admin</Breadcrumb.Item>
                        <Breadcrumb.Item>Accounts</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>

                <Col>
                    <CanAccess permission={["ADMIN", "SUPER_ADMIN"]} userRoles={this.props.user.orgCtx ? this.props.user.orgCtx.roles : []} >
                        <Button type="primary" icon="plus" onClick={() => this.openModal({} as TypeConsoleUser, undefined)}>Add User</Button>
                    </CanAccess>
                </Col>

            </Row>

        )
    }

    changeSearchRole = (e: string[]) => {
        this.setState({ s: Object.assign(this.state.criteria, { roles: e, page: 0 }) })
        this.load()
    }
    changeFreeText = (e: any) => {
        this.setState({ criteria: Object.assign(this.state.criteria, { freeText: e, page: 0 }) });
        this.load()

    }
    changeSearchStatus = (e: string[]) => {
        this.setState({ s: Object.assign(this.state.criteria, { status: e, page: 0 }) })
        this.load()
    }

    handleSearch = (selectedKeys: any, confirm: any) => {
        confirm();
        this.setState({ criteria: Object.assign(this.state.criteria, { freeText: selectedKeys[0], page: 0 }) });
        this.load()
    };

    handleReset = (clearFilters: any) => {
        clearFilters();
        this.setState({ criteria: Object.assign(this.state.criteria, { freeText: '', page: 0 }) });
    };

    onSelectChangeTable = (selectedRowKeys: string[]) => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);

        let selectedUsers: TypeConsoleUser[] = _.filter(this.props.consoleUsers.results.content, (item: TypeConsoleUser) => {
            let find: number = _.indexOf(selectedRowKeys, item.id);
            return find > -1
        })


        this.setState({
            selectedRowKeys: selectedRowKeys,
            selectedUsers: selectedUsers
        });

    };

    showPropsConfirmDeltesAccount = () => {

        Modal.confirm({
            title: 'Are you sure delete?',
            content: `${this.state.selectedRowKeys.length} account(s)`,
            okText: 'Yes',
            okType: 'danger',

            cancelText: 'No',
            onOk: () => {
                this.deleteAccounts(this.state.selectedRowKeys)
            },
            onCancel() {
            },
        });
    }


    renderTheme() {
        const { consoleUsers } = this.props

        return (
            <DashboardPageLayout  {...this.props} selectedLeftNav="super-admin-console-users" selectedSubMenu="console-super">
                <div className="org-page">

                    <MetaHeader title={this.props.ui.meta.title} />

                    <div className="contentLayout">
                        {this.renderBreadCrumb()}
                        <div className="containerInner">
                            <Row gutter={[10, 10]} type="flex" justify="space-between" align="middle" style={{ marginTop: -10 }}>

                                <Col xs={24}>
                                    <Typography.Title level={4}>
                                        Accounts
                                    </Typography.Title>
                                </Col>
                                <Col xs={24}>
                                    <FormFilterComponent
                                        onExport={() => this.setState({
                                            visibleExportData: true
                                        })}
                                        onSearch={(values: any) => {
                                            this.setState({ criteria: Object.assign(this.state.criteria, { ...values, page: 0 }) });
                                            this.load()
                                        }}
                                        isLoading={this.props.ui.isLoading}
                                        organizations={this.props.organizations}
                                        searchOrg={this.searchOrg}
                                        user={this.props.user}
                                    />
                                </Col>
                                <Col xs={24}>
                                    <InfoList
                                        list={this.props.consoleUsers}
                                        refresh={this.load}
                                    />
                                </Col>
                                <Col xs={24}>
                                    <Table
                                        size="small"
                                        bordered={false}
                                        columns={this.state.columnsSPAdmin}
                                        dataSource={consoleUsers.results.content}
                                        pagination={false}
                                        scroll={{ x: 'calc(500px + 50%)' }}
                                        rowKey="id"
                                    />
                                </Col>
                                <Col xs={24}>
                                    <O2OCustomPaging
                                        list={this.props.consoleUsers}
                                        onChange={this.pagi}
                                        onChangePageSize={(e: any) => {
                                            this.setState({
                                                criteria: Object.assign(this.state.criteria, { pageSize: !e ? 10 : +e })
                                            }, () => {
                                                this.load()
                                            })
                                        }}
                                    />
                                </Col>
                            </Row>

                            {this.renderModalUser()}

                            {this.renderAssignToPermissionGroup()}
                            {this.renderDrawerResetPassword()}
                            {this.renderDrawerConfirmDelete()}
                            {this.renderDrawerViewJSON()}
                            {this.renderDrawerActionsLog()}
                            {this.renderExportData()}
                            {this.renderDrawerOrgInfo()}

                            <DrawerSPUserAssignToCompany
                                assignSuccess={() => {
                                    setTimeout(() => {
                                        this.load(() => {
                                            this.openCompanyRoles(this.state.selectedConsoleUser)
                                        })
                                    }, 1000)
                                }}
                                commonActionFromDAL2={this.props.commonActionFromDALV2}
                                onClose={() => {
                                    this.setState({ isVisibleOrganizationInfo: false, selectedConsoleUser: {}, currentUserOrg: [] })
                                }}
                                visible={this.state.isVisibleOrganizationInfo}
                                user={this.state.selectedConsoleUser}
                                currentUserOrg={this.state.currentUserOrg}
                                consoleUsers={this.props.consoleUsers}
                                deleteUserFromOrgSPADMIN={this.deleteUserFromOrgSPADMIN}
                                updateRoleAccount={this.props.updateRoleAccount}
                                loading={this.props.ui.isLoading}
                            />
                        </div>
                    </div>

                </div>
            </DashboardPageLayout >
        )
    }

    pagi = (n: number) => {

        let sC: typeCriteriaSearchAccount = Object.assign(this.state.criteria, { page: n })
        this.setState({ criteria: sC })
        this.load()

    }


    formAssginPermissionGroup: any
    renderAssignToPermissionGroup(): React.ReactNode {
        return (
            <DrawerAssignUserToPermissionGroup
                commonActionFromDALV2={this.props.commonActionFromDALV2}
                loading={this.props.ui.isLoading}
                onClose={() => {
                    this.setState({
                        visible_assign_permission_group: false
                    }, () => {
                        this.formAssginPermissionGroup.props.form.resetFields()
                    })
                }}
                userPermissions={this.state.userPermissions}
                onSubmit={() => {

                    this.formAssginPermissionGroup.props.form.validateFields((err: any, values: any) => {
                        if (err) {
                            return;
                        }
                        this.props.setGlobalLoading(true)
                        if (this.state.selectedUsers.length == 1) {
                            this.props.saveUsersToPermissionGroups(this.state.selectedUsers.map((item: TypeConsoleUser) => item.id), values.pg)
                                .then(() => {
                                    this.props.setGlobalLoading(false)
                                    notification.success({
                                        message: BIGDATA_CONSTANT.message.default.title.success,
                                        description: "Assgin to permission group success",
                                        icon: <Icon type="check-circle" style={{ color: getPrimaryColor(this.props.uiSetting) }} />
                                    })
                                    this.setState({
                                        visible_assign_permission_group: false
                                    })
                                    setTimeout(() => {
                                        this.load()
                                        this.formAssginPermissionGroup.props.form.resetFields()
                                    }, 1000);
                                })
                                .catch(() => {
                                    this.props.setGlobalLoading(false)
                                    notification.error({
                                        message: BIGDATA_CONSTANT.message.default.title.error,
                                        description: "Something was wrong"
                                    })
                                })
                        } else {
                            this.props.assignUsersToPermissionGroups(this.state.selectedUsers.map((item: TypeConsoleUser) => item.id), values.pg)
                                .then(() => {
                                    this.props.setGlobalLoading(false)
                                    notification.success({
                                        message: BIGDATA_CONSTANT.message.default.title.success,
                                        description: "Assgin to permission group success",
                                        icon: <Icon type="check-circle" style={{ color: getPrimaryColor(this.props.uiSetting) }} />
                                    })
                                    this.setState({
                                        visible_assign_permission_group: false
                                    })
                                    setTimeout(() => {
                                        this.load()
                                        this.formAssginPermissionGroup.props.form.resetFields()
                                    }, 1000);
                                })
                                .catch(() => {
                                    this.props.setGlobalLoading(false)
                                    notification.error({
                                        message: BIGDATA_CONSTANT.message.default.title.error,
                                        description: "Something was wrong"
                                    })
                                })
                        }



                    })
                }}
                visible={this.state.visible_assign_permission_group}
                permissionGroup={this.props.permissionGroups}
                users={this.state.selectedUsers}
                wrappedComponentRef={(f: any) => this.formAssginPermissionGroup = f}
            />
        )
    }

    handleSubmit = () => {
        const form = this.formRef.props.form;

        form.validateFields((err: any, values: any) => {
            if (err) {
                return;
            }

            this.props.setGlobalLoading(true)

            values.status = values.active == true ? 'active' : 'inactive'
            if (!values.password) {
                values.password = this.state.selectedUser.password
                values.id = this.state.selectedUser.id
            }
            let isSPAdmin = this.checkCurrentUserIsSuperAdmin()
            let promise: any = this.state.selectedUser && this.state.selectedUser.id ? this.props.updateAccount(values, isSPAdmin) : this.props.addUser(values, isSPAdmin)

            promise
                .then(() => {

                    if (this.props.commonResponse.success) {


                        if (this.state.selectedUser && this.state.selectedUser.id) {
                            notification.success({
                                message: BIGDATA_CONSTANT.message.default.title.success,
                                description: BIGDATA_CONSTANT.message.notification.success.update,
                                icon: <Icon type="check-circle" style={{ color: getPrimaryColor(this.props.uiSetting) }} />
                            })

                            if (isSPAdmin) {
                            } else {
                                this.props.updateRoleAccount(this.state.selectedUser.id, values.roles, isSPAdmin)
                                    .then(() => {
                                        notification.success({
                                            message: BIGDATA_CONSTANT.message.default.title.success,
                                            description: `Roles updated`,
                                            icon: <Icon type="check-circle" style={{ color: getPrimaryColor(this.props.uiSetting) }} />
                                        })
                                    })
                            }

                        } else {
                            notification.success({
                                message: BIGDATA_CONSTANT.message.default.title.success,
                                description: BIGDATA_CONSTANT.message.notification.success.add,
                                icon: <Icon type="check-circle" style={{ color: getPrimaryColor(this.props.uiSetting) }} />
                            })
                        }

                        setTimeout(() => {
                            this.load()
                        }, 1000)

                        form.resetFields()
                        this.props.setGlobalLoading(false)
                        this.setState({ isVisibleModal: false })

                    } else {
                        notification.error({
                            message: BIGDATA_CONSTANT.message.default.title.error,
                            description: this.props.commonResponse.message
                        })
                        this.props.setGlobalLoading(false)
                    }

                })
                .catch(() => {

                    this.props.setGlobalLoading(false)
                })

        })
    }

    openModal = (user: TypeConsoleUser, roles: undefined | string[]) => {
        let isSAdmin = this.checkCurrentUserIsSuperAdmin();
        if (user && user.id) {
            // this.setState({  })

            this.props.detailAccount(user.id, isSAdmin)
                .then(() => {
                    // debugger
                    // this.setState({ isVisibleModal: true, selectedUser: Object.assign(this.state.selectedUser, this.props.consoleUser.result) })
                    this.setState({ isVisibleModal: true, selectedUser: this.props.consoleUser.result })
                })
        } else {
            this.setState({ isVisibleModal: true, selectedUser: null })
        }


        if (isSAdmin) {
            this.loadOrg()
        }

    }

    renderModalUser = () => {
        let isSAdmin = this.checkCurrentUserIsSuperAdmin();
        return (
            <ModalConsoleUser
                wrappedComponentRef={(propsRef: any) => this.formRef = propsRef}
                visible={this.state.isVisibleModal}
                commonActionFromDAL={this.props.commonActionFromDAL}
                onCancel={() => {
                    this.setState({
                        isVisibleModal: false,
                        selectedUser: {},
                        criteriaOrg: Object.assign(this.state.criteriaOrg, { freeText: '' })
                    })
                    this.formRef.props.form.resetFields()
                    let isSAdmin = this.checkCurrentUserIsSuperAdmin();
                    if (isSAdmin) {
                        this.loadOrg();
                    }

                }}
                isSAdmin={isSAdmin}
                currentUser={this.props.user}
                user={this.state.selectedUser}
                orgs={this.props.organizations}
                onSearchOrg={this.searchOrg}
                onCreate={this.handleSubmit}
                loading={this.props.ui.isLoading}
                roles={undefined}
            />
        )
    }

    searchOrg = (e: any) => {

        this.setState({
            criteriaOrg: Object.assign(this.state.criteriaOrg, { freeText: e, page: 0 })
        })

        this.loadOrg();
    }

    renderDrawerResetPassword = () => {
        return <DrawerFormResetPassword
            selectedUser={this.state.selectedConsoleUser}
            onReset={(sl) => {
                this.resetPass(this.state.selectedConsoleUser, sl)
            }}
            visible={this.state.visibleDrawerResetPw}
            onClose={() => {
                this.setState({
                    visibleDrawerResetPw: false,
                    selectedConsoleUser: {}
                })
            }} />
    }

    renderDrawerConfirmDelete = () => {
        return (
            <DrawerConfirmDelete
                visible={this.state.visibleConfirmDelete}
                onClose={() => {
                    this.setState({
                        visibleConfirmDelete: false,
                        selectedConsoleUser: {}
                    })
                }}
                item={this.state.selectedConsoleUser}
                onDelete={(record: TypeConsoleUser) => {
                    this.deleteAccounts([record.id])
                    this.setState({
                        visibleConfirmDelete: false,
                        selectedConsoleUser: {}
                    })
                }}
                type="super-admin-user"
                user={this.props.user}
            />
        )
    }

    renderDrawerViewJSON = () => {
        return <DrawerViewJSON
            visible={this.state.visibleViewJSON}
            onClose={() => {
                this.setState({
                    visibleViewJSON: false,
                    selectedConsoleUser: {}
                })
            }}
            data={this.state.selectedConsoleUser}
        />
    }

    renderDrawerActionsLog = () => {
        return <DrawerActionsLog
            isSpAdmin
            distinctByField={this.props.distinctByField}
            visible={this.state.visibleActionsLog}
            onClose={() => {
                this.setState({
                    visibleActionsLog: false,
                    selectedConsoleUser: {}
                })
            }}
            commonActionFromDALV2={this.props.commonActionFromDALV2}
            selectedRecord={this.state.selectedConsoleUser}
        />
    }

    renderExportData = () => {
        return <DrawerExportDataWithout2FA
            visible={this.state.visibleExportData}
            onClose={() => {
                this.setState({
                    visibleExportData: false
                })
            }}
            totalRecords={this.props.consoleUsers.results.page.totalRecords}
            commonActionDALV2={this.props.commonActionFromDALV2}
            criteria={this.state.criteria}
            disableButton={this.props.consoleUsers.results.page.totalRecords >= 1000000}
            type={'account_SpAdmin'}
        />
    }

    render() {
        return (
            this.renderTheme()
        );
    }
}

