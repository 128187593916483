import { Form, Input } from 'antd';
import React from "react";
import { FormComponentProps } from 'antd/lib/form';
import { User } from 'o2o_layout/lib/common/model/user';
import { TypeOrgCtx } from 'o2o_layout/lib/common/model';
import { O2OFormItem } from '../../../shares/O2OFormItem';



interface Props extends FormComponentProps {
    loading?: boolean,
    locale: any,
    submit: any,
    orgCtx: TypeOrgCtx | undefined,
    user: User
}

export const FormUpdateUserInfo = Form.create<Props>({ name: 'form_in_modal' })(
    // eslint-disable-next-line
    class extends React.Component<Props, any> {

        renderTheme = () => {
            const { getFieldDecorator } = this.props.form
            const {  submit } = this.props

            return (
                <Form colon={false} hideRequiredMark={true} onSubmit={submit}>
                     <O2OFormItem label="Name">
                        {getFieldDecorator('name', {
                            initialValue: this.props.user ? this.props.user.name : ''
                        })(<Input />)}
                    </O2OFormItem>
                    <O2OFormItem label="Email">
                        {getFieldDecorator('id', {
                            initialValue: this.props.user ? this.props.user.id : '',
                        })(<Input disabled />)}
                    </O2OFormItem>
                   
                    <O2OFormItem label="Phone Number">
                        {getFieldDecorator('phone', {
                            rules: [{ required: false }],
                            initialValue: this.props.user.phone
                        })(<Input  style={{ width: '100%' }} />)}
                    </O2OFormItem>
                    <O2OFormItem label="Address">
                        {getFieldDecorator('address', {
                            
                            initialValue: this.props.user.address
                        })(<Input placeholder="Address" style={{ width: '100%' }} />)}
                    </O2OFormItem>

                </Form>
            )
        }

        render() {


            return (



                this.renderTheme()

            );
        }
    },
);
