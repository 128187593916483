import { Button, Col, Divider, Dropdown, Icon, Menu, Modal, notification, Row, Tooltip, Typography } from 'antd'
import { ClickParam } from 'antd/lib/menu'
import Table, { ColumnProps } from 'antd/lib/table'
import _ from 'lodash'
import moment from 'moment'
import { InfoList, LongTextTd, O2OCustomPaging } from 'o2o_layout'
import React, { useEffect, useState } from 'react'
import { CSVLink } from "react-csv"
import CommonTypeProps, { ICommonPayload } from '../../../common/CommonTypeProps'
import { BIGDATA_CONSTANT } from '../../../common/constant'
import { getPrimaryColor } from '../../../common/helper'
import { IState } from '../../../redux/reducer'
import { IActionUI, SHOW_COMMON_ERROR } from '../../../redux/reducer/uiReducer'
import { useDispatch, useSelector } from '../../../redux/store'
import DrawerViewJSON from '../../../shares/DrawerViewJSON'
import { SwitchButtonConfirm } from '../../../shares/SwitchButtonConfirm'
import { TypeDTOZMALiveVersion, ZMALiveVersionDAL } from './DAL'
import { FormFilterZMALiveVersionComponent } from './FilterZMALiveVersionComponent'
import { FormAddEditZMALiveVersion } from './FormAddEditZMALiveVersion'
import { TypeZMALiveVersionItem, ZMALiveVersions } from './model'

interface IZMALiveVersionProps extends CommonTypeProps {

}

const initCriteria: ICommonPayload<TypeDTOZMALiveVersion> = {
    loading: false,
    type: '',
    payload: {} as TypeDTOZMALiveVersion
}

const reducerCriteria = (state: ICommonPayload<TypeDTOZMALiveVersion>, action: ICommonPayload<TypeDTOZMALiveVersion>) => {
    switch (action.type) {
        case UPDATE_CRTIERIA_ZMA_LIVE_VERSION:
            return { ...state, ...action };
        default:
            throw new Error();
    }
}

const UPDATE_CRTIERIA_ZMA_LIVE_VERSION = "UPDATE_CRTIERIA_ZMA_LIVE_VERSION"

const ZMALiveVersionComponent = (props: IZMALiveVersionProps) => {
    const [criteria, dispatchCriteria] = React.useReducer(reducerCriteria, initCriteria)
    const [zmaLiveVersion, setZmaLiveVersion] = useState(new ZMALiveVersions())
    const [loading, setLoading] = useState(false as boolean)
    const [selectedRecord, setSelectedRecord] = useState(undefined as undefined | TypeZMALiveVersionItem)
    const [visibleViewJSON, setVisibleViewJSON] = useState(false as boolean)

    const [visibleAddEdit, setVisibleAddEdit] = useState(false as boolean)
    const uiSetting = useSelector((state: IState) => state.uiSetting)

    const dispatch = useDispatch()

    useEffect(() => {
        if (!_.isEmpty(criteria.payload)) {
            load()
        }
    }, [criteria.payload])

    useEffect(() => {
        dispatchCriteria({
            loading: false,
            type: UPDATE_CRTIERIA_ZMA_LIVE_VERSION,
            payload: { page: 0, pageSize: 10 } as TypeDTOZMALiveVersion
        })
        return () => {
            dispatchCriteria({
                loading: false,
                type: UPDATE_CRTIERIA_ZMA_LIVE_VERSION,
                payload: {} as TypeDTOZMALiveVersion
            })
        }
    }, [])



    const load = async () => {
        setLoading(true)
        const result: Promise<any> = props.commonActionFromDALV2(ZMALiveVersionDAL.search, criteria.payload)
        try {
            const response: ZMALiveVersions = await result
            if (response.success) {
                setLoading(false)
                setZmaLiveVersion(response)
            } else {
                setLoading(false)
                setZmaLiveVersion(new ZMALiveVersions())
            }
        } catch (error) {
            setLoading(false)
            setZmaLiveVersion(new ZMALiveVersions())
        }
    }

    const onDelete = async (id?: string) => {
        const result: Promise<any> = props.commonActionFromDALV2(ZMALiveVersionDAL.delete, id || "")
        try {
            const response: ZMALiveVersions = await result
            if (response.success) {
                setTimeout(() => {
                    load()
                }, 1000);
                notification.success({
                    message: BIGDATA_CONSTANT.message.default.title.success,
                    description: BIGDATA_CONSTANT.message.notification.success.delete,
                    icon: <Icon type="check-circle" style={{ color: getPrimaryColor(uiSetting) }} />
                })
            } else {
                dispatch({
                    type: SHOW_COMMON_ERROR,
                    commonError: response,
                } as IActionUI);
            }
        } catch (error) {
            dispatch({
                type: SHOW_COMMON_ERROR,
                commonError: error,
            } as IActionUI);
        }
    }

    const onEditRecord = async (item: TypeZMALiveVersionItem, sl: any) => {
        sl(true)
        const payload = {
            status: item.status === "Enabled" ? "Disabled" : "Enabled",
            version: item.version
        }
        const result: Promise<any> = props.commonActionFromDALV2(ZMALiveVersionDAL.edit, payload, item.id)
        try {
            const response = await result
            if (response.success) {
                sl(false)
                setTimeout(() => {
                    load()
                }, 1000);
                notification.success({
                    message: BIGDATA_CONSTANT.message.default.title.success,
                    description: BIGDATA_CONSTANT.message.notification.success.update,
                    icon: <Icon type="check-circle" style={{ color: getPrimaryColor(uiSetting) }} />
                })
            } else {
                sl(false)
                dispatch({
                    type: SHOW_COMMON_ERROR,
                    commonError: response,
                } as IActionUI);
            }
        } catch (error) {
            sl(false)
            dispatch({
                type: SHOW_COMMON_ERROR,
                commonError: error,
            } as IActionUI);
        }
    }

    const columns: ColumnProps<TypeZMALiveVersionItem>[] = [
        {
            title: "#",
            key: "index",
            align: 'center',
            ellipsis: true,
            sorter: false,
            width: 40,
            render: (text: any, record: TypeZMALiveVersionItem, index: number) => {
                return index + 1
            }
        },
        {
            title: "Status",
            ellipsis: true,
            key: "status",
            width: 120,
            sorter: false,
            render: (text: any, record: TypeZMALiveVersionItem, index: number) => {
                return <SwitchButtonConfirm
                    showText
                    status={record.status || ""}
                    callApi={(sl: any) => {
                        onEditRecord(record, sl)
                    }}
                />
            }
        },
        {
            title: "App Id",
            ellipsis: true,
            key: "appId",
            sorter: false,
            render: (text: any, record: TypeZMALiveVersionItem, index: number) => {
                return <LongTextTd text={record.appId} />
            }
        },
        {
            title: "App Name",
            ellipsis: true,
            key: "appName",
            sorter: false,
            render: (text: any, record: TypeZMALiveVersionItem, index: number) => {
                return <LongTextTd text={record.appName} />
            }
        },
        {
            title: "Live Version",
            ellipsis: true,
            key: "liveVersion",
            width: 120,
            sorter: false,
            render: (text: any, record: TypeZMALiveVersionItem, index: number) => {
                return record.version
            }
        },
        {
            title: "Last Update",
            ellipsis: true,
            key: "lastUpdate",
            width: 150,
            sorter: false,
            render: (text: any, record: TypeZMALiveVersionItem, index: number) => {
                return record.updatedAt ?
                    <Tooltip title={`${moment(record.updatedAt).format("LLLL")}`}>
                        {moment(record.updatedAt).fromNow()}
                    </Tooltip>
                    : "--/--"
            }
        },
        {
            title: "Update By",
            ellipsis: true,
            key: "updateBy",
            sorter: false,
            render: (text: any, record: TypeZMALiveVersionItem, index: number) => {
                return <LongTextTd text={record.updatedBy} />
            }
        },
        {
            title: "Action",
            key: "action",
            fixed: "right",
            width: 90,
            sorter: false,
            render: (text: any, record: TypeZMALiveVersionItem, index: number) => {
                return <>
                    <Button onClick={() => {
                        setVisibleAddEdit(true)
                        setSelectedRecord(record)
                    }} type='link' size='small' icon='edit' />
                    <Divider type="vertical" />
                    <Dropdown overlay={
                        <Menu onClick={(e: ClickParam) => {
                            if (e.key === "delete") {
                                Modal.confirm({
                                    title: BIGDATA_CONSTANT.message.default.confirmDelete,
                                    onOk: () => onDelete(record.id)
                                })
                            } else if (e.key === "viewJSON") {
                                setVisibleViewJSON(true)
                                setSelectedRecord(record)
                            }
                        }}>
                            <Menu.Item key={"delete"}>
                                <Button type='link' size='small' icon='delete'>Delete</Button>
                            </Menu.Item>
                            <Menu.Item key={"viewJSON"}>
                                <Button type='link' size='small' icon='select'>View JSON</Button>
                            </Menu.Item>
                        </Menu>
                    }>
                        <Button type='link' size='small' icon='more' />
                    </Dropdown>
                </>
            }
        },
    ]


    const dataExport = zmaLiveVersion.results.content.map((item: TypeZMALiveVersionItem) => {
        return {
            "AppId": item.appId + "", "AppName": item.appName, "Version": item.version, "Last Update": item.updatedAt ? moment(item.updatedAt).format("llll") : "", "Updated By": item.updatedBy
        }
    })

    const headers = [
        { "label": "AppId", "key": "AppId" },
        { "label": "AppName", "key": "AppName" },
        { "label": "Version", "key": "Version" },
        { "label": "Last Update", "key": "Last Update" },
        { "label": "Updated By", "key": "Updated By" }
    ]

    const renderInfoList = () => {
        return <InfoList
            loading={loading}
            list={zmaLiveVersion}
            refresh={load}
            right={<>
                <Button style={{ marginRight: 8 }} onClick={() => {
                    setVisibleAddEdit(true)
                }} type='primary' icon='plus-circle'>
                    Add
                </Button>
                <Dropdown overlay={<Menu onClick={(e: ClickParam) => {
                    if (e.key == "export") {

                    }
                }}>
                    <Menu.Item key="export">
                        <CSVLink data={dataExport} headers={headers} filename="zma-live-version.csv">
                            Export
                        </CSVLink>
                    </Menu.Item>
                </Menu>}>
                    <Button icon="more" type='default' />
                </Dropdown>
            </>}
        />
    }

    const renderTable = () => {
        return <Table
            columns={columns}
            loading={loading}
            dataSource={zmaLiveVersion.results.content}
            rowKey="id"
            size="small"
            scroll={{ x: 'calc(700px + 50%)' }}
            pagination={false}
        />
    }

    const renderDrawerAddEditHeader = () => {
        return <FormAddEditZMALiveVersion
            onSucess={() => {
                setVisibleAddEdit(false)
                setSelectedRecord(undefined)
                setTimeout(() => {
                    load()
                }, 1000);
            }}
            commonActionFromDALV2={props.commonActionFromDALV2}
            selectedRecord={selectedRecord}
            onClose={() => {
                setVisibleAddEdit(false)
                setSelectedRecord(undefined)
            }}
            visible={visibleAddEdit}
        />
    }


    const renderPagination = () => {
        return <O2OCustomPaging
            list={zmaLiveVersion}
            onChange={(e: any) => {
                dispatchCriteria({
                    loading: false,
                    type: UPDATE_CRTIERIA_ZMA_LIVE_VERSION,
                    payload: { ...criteria.payload, ...{ page: e - 1 } } as TypeDTOZMALiveVersion
                })
            }}
            onChangePageSize={(e: any) => {
                dispatchCriteria({
                    loading: false,
                    type: UPDATE_CRTIERIA_ZMA_LIVE_VERSION,
                    payload: { ...criteria.payload, ...{ page: 0, pageSize: e } } as TypeDTOZMALiveVersion
                })
            }}
        />
    }

    const renderDrawerViewJSON = () => {
        return <DrawerViewJSON
            visible={visibleViewJSON}
            onClose={() => {
                setVisibleViewJSON(false)
                setSelectedRecord(undefined)
            }}
            data={selectedRecord}
        />
    }


    const renderTitle = () => {
        return <Row type='flex' justify="space-between">
            <Col>
                <Typography.Title level={4}>ZMA Live Version</Typography.Title>
            </Col>
            <Col>
                <a target={'_blank'} href='https://docs.google.com/spreadsheets/d/1keRr7nGIJNuSZHtybIoSQMSiah-jj935BRTvYTUnuSc/edit#gid=0'>Active Apps </a>
                <Divider type='vertical' />
                <a target={'_blank'} href='https://docs.google.com/spreadsheets/d/1g1IL_3koaUzKlwXLdk4B3U5Si3X91q3xaeZ-YAZkvms/edit#gid=0' >Released Notes </a>
            </Col>
        </Row>
    }

    const renderFilter = () => {
        return <FormFilterZMALiveVersionComponent
            loading={loading}
            onSearch={(values: any) => {
                dispatchCriteria({
                    loading: false,
                    type: UPDATE_CRTIERIA_ZMA_LIVE_VERSION,
                    payload: { ...criteria.payload, ...values } as TypeDTOZMALiveVersion
                })
            }}
        />
    }

    return (

        <Row type='flex' gutter={[10, 10]}>
            <Col xs={24}>
                {renderTitle()}
            </Col>
            <Col xs={24}>
                {renderFilter()}
            </Col>
            <Col xs={24}>
                {renderInfoList()}
            </Col>
            <Col xs={24}>
                {renderTable()}
            </Col>
            <Col xs={24}>
                {renderPagination()}
            </Col>
            {renderDrawerAddEditHeader()}
            {renderDrawerViewJSON()}
        </Row>
    )
}

export default ZMALiveVersionComponent