import { CommonResponse } from "../model/commonResponse";

export const UPDATE_COMMON_RESPONSE = "UPDATE_COMMON_RESPONSE"
const empty = (): CommonResponse => {
    return new CommonResponse();
  }

export interface IActionCommonResponse {
    type: string,
    payload: CommonResponse
}

export const commonResponseReducer = (state = empty(), action: IActionCommonResponse) => {
    switch (action.type) {
        case UPDATE_COMMON_RESPONSE: 
            return {...state, ...action.payload}
        default:
            return state;
    }
};