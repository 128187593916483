import { Button, Col, Divider, Popover, Row, Table, Tooltip } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import { get, isEmpty } from 'lodash'
import { LongTextTd, O2OCustomPaging } from 'o2o_layout'
import React, { useEffect } from 'react'
import { useAsyncRetry } from 'react-use'
import { ICommonPayload } from '../../../common/CommonTypeProps'
import { CommonNotificationSaveError, CommonNotificationSaveSuccessFully } from '../../../shares/CommonNotification'
import { CompanyDAL } from './DAL'
import { ICriteria, IInviteUserItem, InvateUsers } from './models'
import moment from "moment"

interface Iprops {
    commonActionFromDALV2: any
    onSuccess: () => void
    totalInviteUser: number
}

const SEARCH_INVATE_USER = "SEARCH_INVATE_USER"

const initCriteria: ICommonPayload<any> = {
    loading: false,
    type: "",
    payload: {} as any,
};

const reducerCriteria = (state: ICommonPayload<any>, action: ICommonPayload<any>) => {
    switch (action.type) {
        case SEARCH_INVATE_USER:
            return { ...state, ...action };
        default:
            throw new Error();
    }
}
const PopoverInviteUser = ({ commonActionFromDALV2, onSuccess, totalInviteUser }: Iprops) => {
    const [visibleViewDetail, setVisibleViewDetail] = React.useState<boolean>(false)


    const [criteria, dispatchCriteria]: [ICommonPayload<any>, (action: ICommonPayload<any>) => void] = React.useReducer(reducerCriteria, initCriteria)

    const loadApi = async () => {
        const data = await commonActionFromDALV2(CompanyDAL.searchInviteUser, criteria.payload)
        const result = await data
        return result
    }

    const state = useAsyncRetry(async () => {
        if (visibleViewDetail && !isEmpty(criteria.payload)) {
            const detail = await loadApi()
            return detail;
        }
        return null
    }, [visibleViewDetail, criteria.payload])

    const inviteUsers: InvateUsers = state.value || new InvateUsers();

    const onAcceptInvite = async (invitationId: string) => {
        try {
            const result = await commonActionFromDALV2(CompanyDAL.acceptInviteUser, invitationId)
            if (result.success) {
                CommonNotificationSaveSuccessFully("Accepted Invite Successfully")
                state.retry()
            } else {
                CommonNotificationSaveError(get(result, "message", "Something went wrong!"))
            }
        } catch (error) {
            CommonNotificationSaveError(get(error, 'message', 'Somethings went wrong'))
        }
    }

    const onRejectInvite = async (invitationId: string) => {
        try {
            const result = await commonActionFromDALV2(CompanyDAL.rejectInviteUser, invitationId)
            if (result.success) {
                CommonNotificationSaveSuccessFully("Rejected Invite Successfully")
                state.retry()
            } else {
                CommonNotificationSaveError(get(result, "message", "Something went wrong!"))
            }
        } catch (error) {
            CommonNotificationSaveError(get(error, 'message', 'Somethings went wrong'))
        }
    }



    useEffect(() => {
        if (visibleViewDetail) {
            dispatchCriteria({
                loading: false,
                type: SEARCH_INVATE_USER,
                payload: {
                    page: 0, pageSize: 10, status: "Pending"
                } as ICriteria,
            })

        } else {
            dispatchCriteria({
                loading: false,
                type: SEARCH_INVATE_USER,
                payload: {} as ICriteria,
            })
        }
    }, [visibleViewDetail])

    const columns: ColumnProps<IInviteUserItem>[] = [
        {
            title: '#',
            key: 'index',
            sorter: false,
            width: 45,
            render: (text: any, record: IInviteUserItem, index: number) => {
                return index + 1;
            },
        },
        {
            title: 'Invited by',
            key: 'invited_by',
            sorter: false,
            ellipsis: true,
            width: 200,
            render: (text: any, record: IInviteUserItem, index: number) => {
                return <LongTextTd text={record.invitedBy} />;
            },
        },
        {
            title: 'Time',
            key: 'time',
            sorter: false,
            ellipsis: true,
            width: 150,
            render: (text: any, record: IInviteUserItem, index: number) => {
                return (
                    <Tooltip title={`Created at ${moment(record.createdAt).format("LLLL")}`}>
                        {moment(record.createdAt).fromNow()}
                    </Tooltip>
                )
            },
        },
        {
            title: 'Organization',
            key: 'organization',
            sorter: false,
            ellipsis: true,
            width: 150,
            render: (text: any, record: IInviteUserItem, index: number) => {
                const organization = get(inviteUsers.organizations, `${record.orgId}`);
                return <LongTextTd text={organization.fullName} />;
            },
        },
        {
            title: 'Action',
            key: 'action',
            // width: 130,
            fixed: 'right',
            align: "center",
            render(text: any, record: IInviteUserItem, index: number) {
                return (
                    <>
                        <Tooltip title="Accept">
                            <Button
                                size="small"
                                icon="check-circle"
                                type="link"
                                onClick={async () => {
                                    await onAcceptInvite(record.id)
                                }}
                            />
                        </Tooltip>

                        <Divider type="vertical" />

                        <Tooltip title="Reject">
                            <Button
                                size="small"
                                icon="close-circle"
                                type="link"
                                onClick={async () => {
                                    await onRejectInvite(record.id)
                                }}
                            />
                        </Tooltip>
                    </>
                );
            },
        },
    ];


    return (
        <Popover placement="bottom" trigger="click" visible={visibleViewDetail}
            title={<>
                <Row type="flex" gutter={[5, 5]} justify="space-between" align="middle">
                    <Col>
                        <span>You have {totalInviteUser} {totalInviteUser === 1 ? "Invitation" : "Invitations"}</span>
                    </Col>
                    <Col>
                        <Button icon="close" type="link" onClick={() => {
                            setVisibleViewDetail(false)
                            onSuccess()
                        }}></Button>
                    </Col>
                </Row>
            </>}
            content={
                <Row style={{ maxWidth: 700 }} type='flex' gutter={[10, 10]}>
                    <Col xs={24}>
                        <Table
                            loading={state.loading}
                            columns={columns}
                            dataSource={inviteUsers.results.content}
                            rowKey="id"
                            size="small"
                            // scroll={{ x: 'calc(500px + 50%)' }}
                            pagination={false}
                        />
                    </Col>

                    <Col xs={24} >
                        <O2OCustomPaging
                            list={inviteUsers}
                            onChange={(e: number) => {
                                dispatchCriteria({
                                    loading: false,
                                    payload: { ...criteria.payload, ...{ page: e } },
                                    type: SEARCH_INVATE_USER,
                                });
                            }}
                            onChangePageSize={(e: number) => {
                                dispatchCriteria({
                                    loading: false,
                                    payload: {
                                        ...criteria.payload,
                                        ...{ page: 0, pageSize: e },
                                    },
                                    type: SEARCH_INVATE_USER,
                                });
                            }}
                        />
                    </Col>

                    <Col xs={24} style={{ textAlign: "end" }}>
                        <Button onClick={() => {
                            onSuccess()
                            setVisibleViewDetail(false)
                        }}>Close</Button>
                    </Col>
                </Row>
            } >
            <Button style={{ paddingLeft: 0 }} type="link" size="small" onClick={() => setVisibleViewDetail(true)}>
                {totalInviteUser} {totalInviteUser === 1 ? "Invitation" : "Invitations"}
            </Button>
        </Popover>
    )
}

export default PopoverInviteUser