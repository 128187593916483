import { Methods, RestClient } from "o2o_layout/lib/common/rest";
import { BIGDATA_CONSTANT } from "../../../../common/constant";
import { TypeCriteriaExport } from "./models";

class DAL {
    search(criterial: TypeCriteriaExport) {
        const { pageSize = 10 } = criterial ? criterial : { pageSize: 10 };
        let url = BIGDATA_CONSTANT.url.console + `mngmnt-client/orgs/logs/export/search?pageSize=${pageSize}`;
        let paramsUrl = new URLSearchParams();
        if (criterial && criterial.page) {
            const p = criterial.page - 1;
            paramsUrl.append("page", "" + p);
        }
        if (criterial && criterial.page) {
            url = url + "&" + paramsUrl.toString();
        }
        var restClient = new RestClient(Methods.POST, url);
        return new Promise((resolve, reject) => {
            restClient.execute({}, criterial ? criterial : {}).then(
                (response: any) => {
                    const user: any = response.data;
                    resolve(user);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    }

    getDistinctByField(field: string, query: string) {
        let url = BIGDATA_CONSTANT.url.console + `mngmnt-client/orgs/logs/export/${field}/distinct`;
        var restClient = new RestClient(Methods.GET, url);
        return new Promise((resolve, reject) => {
            restClient.execute({}, {}).then(
                (response: any) => {
                    const user: any = response.data;
                    resolve(user);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    }
}

export const ExportActivityDAL = new DAL();