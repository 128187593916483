import { Select, Form, Icon } from 'antd'
import { WrappedFormUtils } from 'antd/lib/form/Form'
import _, { find, isBoolean, isFunction } from 'lodash'
import { Organizations, TypeOrganization } from 'o2o_layout/lib/common/model'
import React, { useState } from 'react'
import { OrgsDAL } from '../../orgs/OrgsDAL'

interface ISuggestionUrboxAppProps {
    form: WrappedFormUtils<any>
    keyField: string
    commonActionFromDAL2: any
    visible: boolean
    lable: any
    required?: boolean
    initData?: string
    onSelect?: (orgSelected?: TypeOrganization) => void,
    ids?: string[]
}

const SuggestionOrgs = (props: ISuggestionUrboxAppProps) => {
    const [query, setQuery] = React.useState("" as string)
    const [orgs, setOrgs] = React.useState(new Organizations())
    const [loading, setLoading] = useState(false as boolean)

    React.useEffect(() => {
        if (props.visible) {
            load()
        }
    }, [query])

    const onChange = (e: string) => {
        setQuery(e)
    }

    const debouceOnchange = React.useCallback(_.debounce(onChange, 500), [])

    const load = async () => {
        setLoading(true)
        const result: Promise<any> = props.commonActionFromDAL2(OrgsDAL.list, {
            page: 0,
            pageSize: 10,
            freeText: query
        })
        try {
            const response: Organizations = await result
            if (response.success) {
                setLoading(false)
                setOrgs(response)
            } else {
                setLoading(false)
                setOrgs(new Organizations())
            }
        } catch (error) {
            setLoading(false)
            setOrgs(new Organizations())
        }
    }

    const findExsit = (id: string) => {
        const mapOrg = props.ids || []
        const idex =  _.indexOf(mapOrg, id);
        return idex > -1 ? true: false
    }

    return (<Form.Item colon={false} label={props.lable} style={{ marginBottom: 0 }}>
        {props.form.getFieldDecorator(props.keyField, {
            initialValue: props.initData,
            rules: [{
                required: isBoolean(props.required) ? props.required : true,
                message: "Required"
            }]
        })(<Select
            mode='default'
            loading={loading}
            allowClear
            placeholder="Select"
            filterOption={false}
            showSearch
            maxTagTextLength={4}
            maxTagCount={1}
            showArrow
            onSearch={(e) => {
                debouceOnchange(e)
            }}
            onSelect={(id) => {
                onChange("")
                const orgSelected = find(orgs.results.content, (o: TypeOrganization) => o.id === id)
                if (isFunction(props.onSelect)) {
                    props.onSelect(orgSelected)
                }
            }}
        >
            {orgs.success && orgs.results.content.map((item: TypeOrganization) => {
                return <Select.Option disabled={props.ids && props.ids.length ? (
                    findExsit(item.id)
                ) : false} value={item.id}>
                    {item.name} {props.ids && props.ids.length ? (
                    findExsit(item.id) ? <Icon type='check' /> : null
                ) : null}
                </Select.Option>
            })}
        </Select>)}
    </Form.Item>
    )
}

export default SuggestionOrgs 
