import { Select, Spin } from 'antd'
import { WrappedFormUtils } from 'antd/lib/form/Form'
import { debounce } from 'lodash'
import React, { useState } from 'react'
import { O2OFormItem } from '../../../shares/O2OFormItem'
import { CronjobDAL } from './DAL'
import { Cronjobs, TypeCronjob } from './model'

interface ISuggestionCronjobConfigProps {
    form: WrappedFormUtils<any>
    keyField: string
    commonActionFromDAL2: any
    lable: any
    required?: boolean
    initData?: string[]
}

const SuggestionCronjobConfig = (props: ISuggestionCronjobConfigProps) => {
    const [query, setQuery] = React.useState("" as string)
    const [orgs, setOrgs] = React.useState(new Cronjobs())
    const [loading, setLoading] = useState(false as boolean)

    React.useEffect(() => {
        load()
    }, [query])

    const onChange = (e: string) => {
        setQuery(e)
    }

    const debouceOnchange = React.useCallback(debounce(onChange, 300), [])

    const load = async () => {
        setLoading(true)
        const result: Promise<any> = props.commonActionFromDAL2(CronjobDAL.search, {
            page: 0,
            pageSize: 10,
            freeText: query
        })
        try {
            const response: Cronjobs = await result
            if (response.success) {
                setLoading(false)
                setOrgs(response)
            } else {
                setLoading(false)
                setOrgs(new Cronjobs())
            }
        } catch (error) {
            setLoading(false)
            setOrgs(new Cronjobs())
        }
    }

    return <Spin spinning={loading}>
        <O2OFormItem colon={false} label={props.lable}>
            {props.form.getFieldDecorator(props.keyField, {
                initialValue: props.initData,
            })(<Select
                mode='multiple'
                loading={loading}
                allowClear
                placeholder="Select(s)"
                filterOption={false}
                showSearch
                maxTagTextLength={6}
                maxTagCount={1}
                showArrow
                onSearch={(e) => {
                    debouceOnchange(e)
                }}
                onSelect={(id) => {
                    onChange("")
                }}
            >
                {orgs.success && orgs.results.content.map((item: TypeCronjob) => {
                    return <Select.Option value={item.id}>
                        {item.name}
                    </Select.Option>
                })}
            </Select>)}
        </O2OFormItem>
    </Spin>
}

export default SuggestionCronjobConfig
