import { Button, Col, Input, Modal, Row, Table, Tooltip, Typography } from 'antd'
import Form, { FormComponentProps, WrappedFormUtils } from 'antd/lib/form/Form'
import { ColumnProps } from 'antd/lib/table'
import { get, isEmpty } from 'lodash'
import moment from "moment"
import { InfoList, LongTextTd, O2OCustomPaging } from 'o2o_layout'
import React, { useEffect, useState } from 'react'
import { useAsyncRetry } from 'react-use'
import CommonTypeProps, { ICommonPayload } from '../../../common/CommonTypeProps'
import { IActionUI, SHOW_COMMON_ERROR } from '../../../redux/reducer/uiReducer'
import { useDispatch } from '../../../redux/store'
import { CommonNotificationSaveError } from '../../../shares/CommonNotification'
import { Confirm2FA } from '../../../shares/Confirm2FA'
import { SystemConfigurationDAL } from './DAL'
import { DrawerAddUpdateConfig } from './DrawerAddUpdateConfig'
import { DetailSystemVariable, SystemVariables, TypeSystemVariable } from './model'
import { O2OFormItem } from '../../../shares/O2OFormItem'


interface IProps extends FormComponentProps {
    onSearch: (payload: Object) => void
}


const FilterSystemConfigurationComponent = Form.create<IProps>()(
    ({ form, onSearch }: IProps) => {

        const onSubmit = () => {
            form.validateFields((err: any, values: any) => {
                if (!err) {
                    onSearch(values)
                }
            })
        }
        return <Row type='flex' gutter={[5, 5]}>
            <Col sm={24} lg={12} xl={8} >
                <O2OFormItem label="Info">
                    {form.getFieldDecorator("freeText", {

                    })(<Input.Search onPressEnter={onSubmit} allowClear placeholder='Enter' />)}
                </O2OFormItem>
            </Col>
            <Col>
                <O2OFormItem label=" ">
                    <Button icon='search' type='primary' onClick={onSubmit}>Search</Button>
                </O2OFormItem>
            </Col>
        </Row>
    }
)


interface ISystemConfigurationProps extends CommonTypeProps {

}

const TYPE_DISPATCH_CRITERIA = "TYPE_DISPATCH_CRITERIA"

const initCriteria: ICommonPayload<any> = {
    loading: false,
    type: TYPE_DISPATCH_CRITERIA,
    payload: {} as any,
}

const reducerCriteria = (state: ICommonPayload<any>, action: ICommonPayload<any>) => {
    switch (action.type) {
        case TYPE_DISPATCH_CRITERIA:
            return { ...state, ...action }
        default:
            throw new Error()
    }
}


const SystemConfigurationComponent = ({ commonActionFromDALV2 }: ISystemConfigurationProps) => {
    const [criteria, dispatchCriteria] = React.useReducer(reducerCriteria, initCriteria)
    const [visibleAddUpdate, setVisibleAddUpdate] = useState(false)
    const [visible2FA, setVisible2FA] = useState({
        visible: false,
        isUpdateRecord: false
    } as {
        visible: boolean,
        isUpdateRecord?: boolean
    })
    const [selectedRecord, setSelectedRecord] = useState(undefined as undefined | TypeSystemVariable)
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)

    const load = async () => {
        const result = commonActionFromDALV2(SystemConfigurationDAL.search, criteria.payload)
        try {
            const response = await result
            if (response.success) {
                return response
            } else {
                return new SystemVariables()
            }
        } catch (error) {
            return new SystemVariables()
        }
    }

    useEffect(() => {
        dispatchCriteria({
            loading: false,
            type: TYPE_DISPATCH_CRITERIA,
            payload: { ...criteria.payload, ...{ page: 0, pageSize: 10 } }
        })
        return () => {
            dispatchCriteria({
                loading: false,
                type: TYPE_DISPATCH_CRITERIA,
                payload: {}
            })
        }
    }, [])


    const stateSystemVariable = useAsyncRetry(async () => {
        if (!isEmpty(criteria.payload)) {
            const data = await load()
            return data
        }
        return new SystemVariables()
    }, [criteria])

    const systemVariable: SystemVariables = stateSystemVariable.value || new SystemVariables()

    const columns: ColumnProps<TypeSystemVariable>[] = [
        {
            title: "#",
            ellipsis: true,
            key: "index",
            width: 40,
            align: "center",
            render: (text: any, record: TypeSystemVariable, index: number) => {
                return index + 1
            }
        },
        {
            title: "Key",
            ellipsis: true,
            key: "key",
            width: 400,
            render: (text: any, record: TypeSystemVariable, index: number) => {
                return <LongTextTd text={record.id} />
            }
        },
        {
            title: "Value",
            ellipsis: true,
            key: "value",
            width: 90,
            render: (text: any, record: TypeSystemVariable, index: number) => {
                return <Button style={{ padding: 0 }} size='small' type='link' onClick={() => {
                    setVisible2FA({
                        visible: true
                    })
                    setSelectedRecord(record)
                }}>
                    {record.value}
                </Button>
            }
        },
        {
            title: "Created At",
            ellipsis: true,
            key: "createdAt",
            width: 120,
            render: (text: any, record: TypeSystemVariable, index: number) => {
                return record.createdAt ?
                    <Tooltip title={<>Created by {record.createdBy} at {moment(record.createdAt).format("LLLL")}</>}>
                        {moment(record.createdAt).fromNow()}
                    </Tooltip>
                    : '--/--'
            }
        },
        {
            title: "Updated At",
            ellipsis: true,
            key: "updatedAt",
            render: (text: any, record: TypeSystemVariable, index: number) => {
                return record.updatedAt ?
                    <Tooltip title={<>Updated by {record.createdBy} at {moment(record.updatedAt).format("LLLL")}</>}>
                        {moment(record.updatedAt).fromNow()}
                    </Tooltip>
                    : '--/--'
            }
        },
        {
            title: "Action",
            ellipsis: true,
            width: 70,
            key: "action",
            fixed: "right",
            render: (text: any, record: TypeSystemVariable, index: number) => {
                return <Button onClick={() => {
                    setVisible2FA({
                        visible: true,
                        isUpdateRecord: true
                    })
                    setSelectedRecord(record)
                }} size='small' icon='edit' type='link' />
            }
        }
    ]

    const showModal = (response: DetailSystemVariable) => {
        Modal.success({
            title: `${get(selectedRecord, "id")}`,
            content: <>
                Value: <Typography.Text strong copyable>{get(response, "results.value")}</Typography.Text>
            </>,
            onOk: () => setSelectedRecord(undefined)
        })
    }

    const getDetail = async (codeOTP: string) => {
        setLoading(true)
        const result = commonActionFromDALV2(SystemConfigurationDAL.getDetail, codeOTP, get(selectedRecord, "id"))
        try {
            const response: DetailSystemVariable = await result
            if (response.success) {
                if (visible2FA.isUpdateRecord) {
                    setSelectedRecord(response.results)
                    setVisibleAddUpdate(true)
                } else {
                    showModal(response)
                }
                setVisible2FA({
                    visible: false,
                    isUpdateRecord: false
                })
            } else {
                if (response["message"] === "Invalid PIN, please try again!") {
                    CommonNotificationSaveError(response["message"])
                } else {
                    CommonNotificationSaveError(response["message"])
                    setVisible2FA({
                        visible: false,
                        isUpdateRecord: false
                    })
                }
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            setVisible2FA({
                visible: false,
                isUpdateRecord: false
            })
            dispatch({
                type: SHOW_COMMON_ERROR,
                commonError: error
            } as IActionUI)
        }
    }

    return (
        <Row type='flex' gutter={[10, 10]} >
            <Col xs={24}>
                <Typography.Title level={4}>
                    System Configuration
                </Typography.Title>
            </Col>
            <Col xs={24}>
                <FilterSystemConfigurationComponent
                    onSearch={(payload: Object) => {
                        dispatchCriteria({
                            loading: false,
                            type: TYPE_DISPATCH_CRITERIA,
                            payload: { ...criteria.payload, ...payload, ...{ page: 0} }
                        })
                    }}
                />
            </Col>
            <Col xs={24}>
                <InfoList
                    loading={stateSystemVariable.loading}
                    refresh={stateSystemVariable.retry}
                    list={systemVariable}
                    right={<>
                        <Button onClick={() => setVisibleAddUpdate(true)} type='primary' icon='plus-circle' >Add</Button>
                    </>}
                />
            </Col>
            <Col xs={24}>
                <Table
                    size="small"
                    loading={stateSystemVariable.loading}
                    bordered={false}
                    columns={columns}
                    dataSource={systemVariable.results.content}
                    pagination={false}
                    scroll={{ x: 'calc(500px + 50%)' }}
                    rowKey="id"

                />
            </Col>
            <Col xs={24}>
                <O2OCustomPaging
                    list={systemVariable}
                    onChange={(e: any) => {
                        dispatchCriteria({
                            loading: false,
                            type: TYPE_DISPATCH_CRITERIA,
                            payload: { ...criteria.payload, ...{ page: e - 1 } }
                        })
                    }}
                    onChangePageSize={(e: any) => {
                        dispatchCriteria({
                            loading: false,
                            type: TYPE_DISPATCH_CRITERIA,
                            payload: { ...criteria.payload, ...{ page: 0, pageSize: e } }
                        })
                    }}
                />
            </Col>

            <DrawerAddUpdateConfig
                selected={selectedRecord}
                onSuccess={() => {
                    setTimeout(() => {
                        stateSystemVariable.retry()
                    }, 1000);
                }}
                commonActionFromDALV2={commonActionFromDALV2}
                onClose={() => {
                    setSelectedRecord(undefined)
                    setVisibleAddUpdate(false)
                }}
                visible={visibleAddUpdate}
            />

            <Confirm2FA
                visible={visible2FA.visible}
                onClose={() => {
                    setVisible2FA({
                        visible: false,
                        isUpdateRecord: false
                    })
                }}
                okModal={async (e: any, f: WrappedFormUtils<any>) => {
                    const codeOTP = f.getFieldValue("codeOTP")
                    getDetail(codeOTP)
                }}
                loading={loading}
                okText={"Add"}
                type={"add-key"}
            />

        </Row>
    )
}

export default SystemConfigurationComponent
