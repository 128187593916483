import { IResponseBigDataVN, TypeResultsBigData, TypePageBigData } from "o2o_layout/lib/common/interface";

export interface ICriteria {
    pageSize: number;
    page: number
}

export interface IInviteUserItem {
    id: string
    userId: string
    orgId: string
    userIdOrgIdKey: string
    roles: string[]
    status: string
    createdAt: number
    expiredAt: number
    joinedOrgExpiredAt: number
    actionAt: number
    invitedBy: string
    reason: string
}

export interface IOrganizationItem {
    id: string
    md5: string
    name: string
    code: string
    fullName: string
    accountType: string
    industry: string
    phase: string
    address: string
    email: string
    phone: string
    website: string
    imageLink: string
    accountManager: string
    contractEffectiveFrom: number
    contractEffectiveTo: number
    contractType: string
    note: any
    createdAt: number
    createdBy: string
    updatedAt: number
    updatedBy: string
    status: string
    tags: string[]
    environment: string
    scope: any
    ctxValues: any
}


export class InvateUsers implements IResponseBigDataVN<IInviteUserItem> {
    results: TypeResultsBigData<IInviteUserItem>;
    success: boolean;
    organizations: {
        [key: string]: IOrganizationItem
    }
    constructor() {
        this.success = false;
        this.results = {
            content: [],
            currentTime: 0,
            extras: null,
            page: {
                pageSize: 0,
                currentPage: 0,
                totalRecords: 0,
                totalPages: 0,
            } as TypePageBigData,
            timeTaken: 0,
        };
        this.organizations = {}
    }
}